import {
  getAboveNode,
  getNode,
  getParentNode,
  getPluginType,
  isCollapsed,
  PlateEditor,
  TElement,
  TElementEntry,
  Value,
} from '@udecode/plate-common';
import { Location, Path, Range } from 'slate';

import { ELEMENT_LI } from '../createListPlugin';

/**
 * Returns the nearest li and ul / ol wrapping node entries for a given path (default = selection)
 */
export const getListItemEntry = (
  editor,
  { at = editor.selection } = {}
) => {
  const liType = getPluginType(editor, ELEMENT_LI);

  let _at;

  if (Range.isRange(at) && !isCollapsed(at)) {
    _at = at.focus.path;
  } else if (Range.isRange(at)) {
    _at = at.anchor.path;
  } else {
    _at = at ;
  }

  if (_at) {
    const node = getNode(editor, _at);
    if (node) {
      const listItem = getAboveNode(editor, {
        at: _at,
        match: { type: liType },
      });

      if (listItem) {
        const list = getParentNode(editor, listItem[1]);

        return { list, listItem };
      }
    }
  }
};
