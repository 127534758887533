export const validateVariable = (variableJson) => {
  var valid = true;
  var variables = "";
  if (variableJson && variableJson !== null && variableJson.length !== 0) {
    Object.keys(variableJson).forEach((element) => {
      // if (element === "nonTemplateVar") {
      //   let nontempValidation = validateVariable(variableJson[element]);
      //   valid = nontempValidation[0];
      //   variables = variables + nontempValidation[1] + ",";
      // } else
      if (variableJson[element].elementType === null) {
        valid = false;
        variables = variables + element + ",";
      } else if (
        variableJson[element].elementConfig.placeholder === null ||
        variableJson[element].elementConfig.placeholder === ""
      ) {
        valid = false;
        variables = variables + element + ",";
      } else if (variableJson[element].validation.rejex) {
        var isValid = true;
        try {
          new RegExp(variableJson[element].validation.rejex);
        } catch (e) {
          isValid = false;
        }
        if (variableJson[element].validation.rejex !== "" && isValid) {
        } else {
          valid = false;
          variables = variables + element + ",";
        }
      } else if (variableJson[element].validation.conditions) {
        var isValid = true;
        if (variableJson[element]?.validation?.conditions?.conditions) {
          variableJson[element]?.validation?.conditions?.conditions.forEach(
            (element) => {
              if (
                element.id === "" ||
                element.id === null ||
                element.value === "" ||
                element.value === null ||
                element.operator === "" ||
                element.operator === null
              ) {
                isValid = false;
              }
            }
          );
          if (
            variableJson[element]?.validation?.conditions?.conditions?.length ===
              0 ||
            isValid === false
          ) {
            valid = false;
            variables = variables + element + ",";
          }
        } else {
          valid = false;
          variables = variables + element + ",";
        }
      } else if (
        variableJson[element].elementType === "select" ||
        variableJson[element].elementType === "radio" ||
        variableJson[element].elementType === "checkbox"
      ) {
        if (
          variableJson[element]?.elementConfig?.options?.length === 1 &&
          (variableJson[element]?.elementConfig?.options[0]?.value === "" ||
            variableJson[element]?.elementConfig?.options[0]?.displayValue === "")
        ) {
          valid = false;
          variables = variables + element + ",";
        }
      }
      // else if ((variableJson[element].elementType === "input" && variableJson[element].elementConfig.type === "text")|| variableJson[element].elementType === "textarea") {

      // }
      else if (variableJson[element].elementType === "searchBar") {
        if (
          variableJson[element]?.elementConfig?.lookupConfig?.populateFields
            ?.length === 0 ||
          variableJson[element]?.elementConfig?.lookupConfig?.dataColumn?.length ===
            0 ||
          variableJson[element]?.elementConfig?.lookupConfig?.dataColumn?.length !==
            variableJson[element]?.elementConfig?.lookupConfig?.populateFields
              ?.length
        ) {
          valid = false;
          variables = variables + element + ",";
        }
      }
    });
    return [valid, variables];
  } else {
    return [true, []];
  }
};

export const CapitalizeFirstChar = (str) => {
  let lStr = str.toLowerCase();
  return lStr.charAt(0).toUpperCase() + lStr.slice(1);
};

export function toTitleCase(str) {
  const titleCase = str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");

  return titleCase;
}
