import React, { useState, useEffect } from "react";
import "./CompanySettings.css";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import Loading from "../../../Loading/Loading";

export default function CompanySettings() {
  const [status, setStatus] = useState();
  const [passwordStatus, setpasswordStatus] = useState();
  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allSettings, setAllSettings] = useState([]);
  const [passSetJson, setPassSetJson] = useState({});
  useEffect(() => {
    getAllSettings();
  }, []);

  const getAllSettings = async () => {
    setIsLoading(true);
    await getCrudApi("companySettings/all", {}).then((all) => {
      setAllSettings(all);

      let notificationSetting = all?.filter(
        (setting) => setting?.companySettingType === "Notification"
      );
      if (notificationSetting?.length === 1) {
        setStatus(notificationSetting[0]?.status);
      }

      let passSetting = all?.filter(
        (setting) => setting.companySettingType === "Password"
      );
      if (passSetting?.length === 1) {
        setpasswordStatus(passSetting[0]?.status);
        if (passSetting[0]?.valueData !== null) {
          setPassSetJson(passSetting[0]?.valueData);
        }
      }
      // set the pass word settings to state variable passSetJson
    });
    setIsLoading(false);
  };

  const StatusChange = async () => {
    setStatus(status === 1 ? 2 : 1);
  };
  const StatusChangePass = async () => {
    setpasswordStatus(passwordStatus === 1 ? 2 : 1);
  };
  const submitSettings = async () => {
    setIsLoading(true);
    let settingJson = {};
    let isValid = true;
    if (passwordStatus === 1) {
      if (passSetJson) {
        let nullMsgs = Object.keys(passSetJson)?.filter(
          (data) =>
            passSetJson[data]?.msg === null ||
            passSetJson[data]?.msg === "" ||
            !passSetJson[data]?.msg
        );
        let nullVals = Object.keys(passSetJson)?.filter((data) => {
          if (data === "minLength" || data === "maxLength") {
            return (
              passSetJson[data]?.val === null ||
              passSetJson[data]?.val === "" ||
              !passSetJson[data]?.val
            );
          }
        });

        if (nullMsgs?.length > 0 || nullVals?.length > 0) {
          isValid = false;
        }
      }

      settingJson = {
        status: status === 1 ? 1 : 2,
        passSettingStatus: passwordStatus,
        passSetJson: passSetJson,
      };
    } else {
      settingJson = {
        status: status === 1 ? 1 : 2,
        passSettingStatus: passwordStatus,
      };
    }
    if (isValid) {
      await postCrudApi(`companySettings/notpas`, settingJson).then((data) => {
        if (data) {
          toast.success("Updated Successfully");
        } else {
          toast.error("Operation was not performed");
        }
      });
    } else {
      toast.error("Please fill the mandatory fields");
    }
    setIsLoading(false);
  };

  const handleCheckBox = (e) => {
    let settings = { ...passSetJson };
    if (e.target.checked) {
      settings[e.target.value] = {};
    } else {
      delete settings[e.target.value];
    }
    setPassSetJson(settings);
  };

  const handleChangePass = (key, e) => {
    let settings = { ...passSetJson };
    settings[key][e.target.name] = e.target.value;
    setPassSetJson(settings);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              className="companySettings_submit_btn"
              onClick={submitSettings}
            >
              {" "}
              Submit
            </button>
          </div>
          <div className="companySettings_main_container">
            <div className="companySettingsStatusCheckbox">
              <label className="companySettingsLabel">
                Notification : &nbsp;
              </label>
              <label class="switch">
                <input
                  type="checkbox"
                  checked={status === 1}
                  onChange={StatusChange}
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div className="companySettings_main_container">
            <div className="companySettingsStatusCheckbox">
              <label className="companySettingsLabel">Password : &nbsp;</label>
              <label class="switch">
                <input
                  type="checkbox"
                  checked={passwordStatus === 1}
                  onChange={StatusChangePass}
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          {passwordStatus === 1 ? (
            <div>
              <div>
                <div className="company-settings-div-password">
                  <div className="comapany-settings-lable-input-div">
                    <label className="comapany-settings-lable-keys companySettingsLabel">
                      Min Length :
                    </label>
                    <input
                      value="minLength"
                      type="checkbox"
                      onChange={handleCheckBox}
                      checked={Object?.keys(passSetJson)?.includes("minLength")}
                    />
                  </div>
                  {Object?.keys(passSetJson)?.includes("minLength") ? (
                    <>
                      <div>
                        <div className="comapany-settings-lable-margin">
                          <label className="companySettingsLabel">
                            Message{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i> :
                          </label>
                        </div>
                        <input
                          name="msg"
                          className="textbox-input_1"
                          placeholder="Message"
                          value={passSetJson?.minLength?.msg}
                          onChange={(e) => handleChangePass("minLength", e)}
                        />
                      </div>
                      <div>
                        <div className="comapany-settings-lable-margin">
                          {" "}
                          <label className="companySettingsLabel">
                            Value{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i> :
                          </label>
                        </div>
                        <input
                          name="val"
                          className="textbox-input_1"
                          placeholder="Value"
                          value={passSetJson?.minLength?.val}
                          onChange={(e) => handleChangePass("minLength", e)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="company-settings-div-password">
                  <div className="comapany-settings-lable-input-div">
                    <label className="comapany-settings-lable-keys companySettingsLabel">
                      Max Length :
                    </label>
                    <input
                      value="maxLength"
                      type="checkbox"
                      onChange={handleCheckBox}
                      checked={Object?.keys(passSetJson)?.includes("maxLength")}
                    />
                  </div>
                  {Object?.keys(passSetJson)?.includes("maxLength") ? (
                    <>
                      <div>
                        <div className="comapany-settings-lable-margin">
                          {" "}
                          <div className="comapany-settings-lable-margin">
                            <label className="companySettingsLabel">
                              Message{" "}
                              <i className="fa fa-asterisk aster-risk-Icon"></i>{" "}
                              :
                            </label>
                          </div>
                        </div>
                        <input
                          name="msg"
                          className="textbox-input_1"
                          placeholder="Message"
                          value={passSetJson?.maxLength?.msg}
                          onChange={(e) => handleChangePass("maxLength", e)}
                        />
                      </div>
                      <div>
                        <div className="comapany-settings-lable-margin">
                          {" "}
                          <label className="companySettingsLabel">
                            Value{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i> :
                          </label>
                        </div>
                        <input
                          name="val"
                          className="textbox-input_1"
                          placeholder="Value"
                          value={passSetJson?.maxLength?.val}
                          onChange={(e) => handleChangePass("maxLength", e)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="company-settings-div-password">
                  <div className="comapany-settings-lable-input-div">
                    <label className="comapany-settings-lable-keys companySettingsLabel">
                      Special Char :
                    </label>
                    <input
                      value="specialChar"
                      type="checkbox"
                      onChange={handleCheckBox}
                      checked={Object?.keys(passSetJson)?.includes(
                        "specialChar"
                      )}
                    />
                  </div>
                  {Object?.keys(passSetJson)?.includes("specialChar") ? (
                    <>
                      <div>
                        <div className="comapany-settings-lable-margin">
                          <label className="companySettingsLabel">
                            Message{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i> :
                          </label>
                        </div>
                        <input
                          name="msg"
                          className="textbox-input_1"
                          placeholder="Message"
                          value={passSetJson?.specialChar?.msg}
                          onChange={(e) => handleChangePass("specialChar", e)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="company-settings-div-password">
                  <div className="comapany-settings-lable-input-div">
                    <label className="comapany-settings-lable-keys companySettingsLabel">
                      Number :
                    </label>
                    <input
                      value="number"
                      type="checkbox"
                      onChange={handleCheckBox}
                      checked={Object?.keys(passSetJson)?.includes("number")}
                    />
                  </div>
                  {Object?.keys(passSetJson)?.includes("number") ? (
                    <>
                      <div>
                        <div className="comapany-settings-lable-margin">
                          <label className="companySettingsLabel">
                            Message{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i> :
                          </label>
                        </div>
                        <input
                          className="textbox-input_1"
                          name="msg"
                          placeholder="Message"
                          value={passSetJson?.number?.msg}
                          onChange={(e) => handleChangePass("number", e)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="company-settings-div-password">
                  <div className="comapany-settings-lable-input-div">
                    <label className="comapany-settings-lable-keys companySettingsLabel">
                      Capital :
                    </label>
                    <input
                      value="capital"
                      type="checkbox"
                      onChange={handleCheckBox}
                      checked={Object?.keys(passSetJson)?.includes("capital")}
                    />
                  </div>

                  {Object?.keys(passSetJson)?.includes("capital") ? (
                    <>
                      <div>
                        <div className="comapany-settings-lable-margin">
                          <label className="companySettingsLabel">
                            Message{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i> :
                          </label>
                        </div>
                        <input
                          className="textbox-input_1"
                          name="msg"
                          placeholder="Message"
                          value={passSetJson?.capital?.msg}
                          onChange={(e) => handleChangePass("capital", e)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
