import axios from "axios";

import { getDecodedToken, logout } from "../Helpers/validateToken";
var CryptoJS = require("crypto-js");

//const API_URL = "http://localhost:5000/api/";
// const API_URL =
//  "http://Contract-env.eba-is3azxbb.ap-southeast-1.elasticbeanstalk.com/api/";
//const API_URL = "https://contractapi.cipheracetech.com/api/";
const API_URL = process.env.REACT_APP_CONTRACE_API_URL;
//const API_URL = "http://192.168.0.115:5000/api/";
// "http://Contract-env.eba-is3azxbb.ap-southeast-1.elasticbeanstalk.com/api/";

export const AuthHeader = () => {
  // const user = useSelector((state) => state.categoryState.value.categoryData);
  const user = sessionStorage.getItem("user");
  if (user) {
    return { Authorization: "Bearer " + user };
  } else {
    return {};
  }
};
export const Relogin = async () => {
  var decToken = getDecodedToken();
  if (decToken !== null) {
    if (decToken.exp * 1000 - Date.now() < 900000) {
      // 15 mins
      const loginDetails = sessionStorage.getItem("ehju");
      const decr = CryptoJS.AES.decrypt(loginDetails, "cipherAce").toString(
        CryptoJS.enc.Utf8
      );
      const decoded = JSON.parse(decr);
      let result = Login(decoded, "auth/login");
      let dataResult = null;
      if (result != null) {
        await result.then(function (res) {
          dataResult = res;
        });
        if (dataResult) {
          console.log("Success");
        }
      }
    }
  }
};
export const Login = (body, path) => {
  return axios
    .post(API_URL + path, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.token) {
        sessionStorage.setItem("user", response.data.token);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("There was an error!", error.message);
      // if (error.response.status === 400) alert("Invalid Credentials");
      return null;
    });
};

export const getCrudApi = async (path, body) => {
  await Relogin();
  const headers = AuthHeader();
  return axios
    .get(API_URL + path, { headers }, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 500) {
        if (error?.response?.data?.message?.includes("401 UNAUTHORIZED")) {
          logout();
        }
      } else if (error?.response?.status === 401) {
        logout();
      } else {
        console.error("There was an error!", error?.message);
        return null;
      }
    });
};
export const postCrudApi = async (path, body) => {
  await Relogin();
  const headers = AuthHeader();
  return axios
    .post(API_URL + path, body, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 500) {
        if (error?.response?.data?.message?.includes("401 UNAUTHORIZED")) {
          logout();
        }
      } else if (error?.response?.status === 401) {
        logout();
      } else if (error?.response?.status === 400) {
        return 400;
      } else {
        console.error("There was an error!", error?.message);
        return null;
      }
    });
};
export const putCrudApi = async (path, body) => {
  await Relogin();
  const headers = AuthHeader();
  return axios
    .put(API_URL + path, body, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 500) {
        if (error?.response?.data?.message?.includes("401 UNAUTHORIZED")) {
          logout();
        }
      } else if (error?.response?.status === 401) {
        logout();
      } else if (error?.response?.status === 400) {
        return 400;
      } else {
        console.error("There was an error!", error?.message);
        return null;
      }
    });
};
export const deleteCrudApi = async (path) => {
  await Relogin();
  const headers = AuthHeader();
  return axios
    .delete(API_URL + path, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 500) {
        if (error?.response?.data?.message?.includes("401 UNAUTHORIZED")) {
          logout();
        }
      } else if (error?.response?.status === 401) {
        logout();
      } else {
        console.error("There was an error!", error?.message);
        return null;
      }
    });
};
export const getCrudApiNego = (path, body) => {
  return axios
    .get(API_URL + path)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("There was an error!", error?.message);
      return null;
    });
};
export const putCrudApiNego = (path, body) => {
  return axios
    .put(API_URL + path, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("There was an error!", error?.message);
      return null;
    });
};
