import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { deptData: {} };
export const deptSlice = createSlice({
  name: "deptState",
  initialState: { value: initialStateValue },
  reducers: {
    setDeptData: (state, action) => {
      var deptresult = null;
      deptresult = action.payload;
      state.value.deptData = deptresult;
    },
  },
});

export const { setDeptData } = deptSlice.actions;

export default deptSlice.reducer;
