import React, { useState, useEffect } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { Link } from "react-router-dom";
import OptimisedMUIDataTable from "../manageContracts/OptimisedMUIDataTable";
import { getDecodedToken, validateToken } from "../../Helpers/validateToken";
import { getCrudApi } from "../../webServiceCalls/webServiceCall";
import "./ManageReviewers.css";
import Loading from "../../Loading/Loading";

const ManageReviewer = (props) => {
  const [contractTableData, setTableDataContract] = useState([]);
  const [page, setPage] = useState(0);
  const [ContractOpencnt, setContractOpenCnt] = useState(0);
  const [ContractClosecnt, setContractCloseCnt] = useState(0);
  const [contractTableDataforcnt, setContractTableDataforcnt] = useState([]);
  const [AllCnt, setAllCnt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "VIEW",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                style={{ textDecoration: "none" }}
                to="/reviewer/submit"
                state={{
                  contract_id: tableMeta.rowData[1],
                  contractData: contractTableData.filter(
                    (element) => element.contractId === tableMeta.rowData[1]
                  )[0],
                }}
              >
                <button
                  className="view-icon"
                  onClick={() => onApprovalEdit(tableMeta, value)}
                >
                  <i className="fa fa-eye custom-iconformodal" />
                </button>
              </Link>
            </>
          );
        },
      },
    };

    columnsArray.push(firstCol);
    columnsArray.push({
      name: "contractId",
      label: "Contract ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractName",
      label: "Contract Name",
    });
    columnsArray.push({
      name: "catogeryType",
      label: "CATEGORY TYPE",
    });
    columnsArray.push({
      name: "contractType",
      label: "CONTRACT TYPE",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "CONTRACT STATUS",
    });
    columnsArray.push({
      name: "contractReviewerStatus",
      label: "REVIEW STATUS",
      options: {
        customBodyRender: (dataIndex, tableMeta) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  tableMeta.rowData[7] === 16
                    ? "table-active-cell-review"
                    : tableMeta.rowData[7] === 17
                    ? "table-inactive-cell-review"
                    : ""
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    columnsArray.push({
      name: "contractReviewerStatusId",
      label: "CONTRACT STATUS",
      options: {
        display: false,
      },
    });

    return columnsArray;
  };

  useEffect(() => {
    //TODO - api call to get initial request info table
    setReviewerData();
    const interval = setInterval(
      setReviewerData,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
    // }
  }, []);
  const setReviewerData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let user = getDecodedToken();
    let result = getCrudApi("contract/contractReviewer/" + user.userId, {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      setTableDataContract(dataResult);
      setContractTableDataforcnt(dataResult);
    }
    setIsLoading(false);
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };
  const onApprovalEdit = (tableMeta, value) => {};

  useEffect(() => {
    let open = contractTableDataforcnt.filter(function (iterator) {
      return iterator.contractReviewerStatus
        ? iterator.contractReviewerStatus.toLowerCase() === "open"
        : null;
    });
    setContractOpenCnt(open.length);

    let close = contractTableDataforcnt.filter(function (iterator) {
      return iterator.contractReviewerStatus
        ? iterator.contractReviewerStatus.toLowerCase() === "close"
        : null;
    });
    setContractCloseCnt(close.length);

    let allCnt = contractTableDataforcnt.length;
    setAllCnt(allCnt);
  });

  const handleCloseContract = (e) => {
    let close = contractTableDataforcnt.filter(function (iterator) {
      return iterator.contractReviewerStatus.toLowerCase() === "close";
    });

    setTableDataContract(close);
  };

  const handleOpenContract = (e) => {
    let open = contractTableDataforcnt.filter(function (iterator) {
      return iterator.contractReviewerStatus.toLowerCase() === "open";
    });
    setTableDataContract(open);
  };

  const handle_all = (e) => {
    setTableDataContract(contractTableDataforcnt);
  };

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="approval-status-div">
            <button
              type="button"
              className="status-btn-reviwers  submitted-reviwers "
              onClick={handle_all}
            >
              <div className="status-text">All</div>
              <div className="count-div">
                <label className="all-reviewers-label"> {AllCnt}</label>
              </div>
            </button>
            <button
              type="button"
              className="status-btn-reviwers  Declined-reviwers "
              onClick={handleCloseContract}
              btn-lg
            >
              <div className="status-text">Closed</div>
              <div className="count-div">
                <label className="closed-reviewers-label">
                  {" "}
                  {ContractClosecnt}
                </label>
              </div>
            </button>
            <button
              type="button"
              className="status-btn-reviwers  approved-reviwers "
              onClick={handleOpenContract}
            >
              <div className="status-text">Open</div>
              <div className="count-div">
                <label className="open-reviewers-label">
                  {ContractOpencnt}
                </label>
              </div>
            </button>
          </div>
          <div className="Table-body">
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  title={"Manage Contracts"}
                  data={contractTableData}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageReviewer;
