import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { getCrudApi } from "../../../webServiceCalls/webServiceCall";
import { validateToken } from "../../../Helpers/validateToken";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import "./Notification.css";
import Loading from "../../../Loading/Loading";

export default function TableForNotification(props) {
  const [tableJSON, setTableJSON] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractTemplateId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {},
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  const GetTemplateData = async () => {
    setIsLoading(true);
    let dataResult;
    let result = getCrudApi("globalTemplateNotification/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      const newData = generateJSONForTable(dataResult);
      setTableJSON(newData);
    }
    setIsLoading(false);
  };

  const generateJSONForTable = (templateData) => {
    let arrayOfJSON = [];
    templateData.forEach((template) => {
      let newJSON = {};
      Object.keys(template).forEach((element) => {
        newJSON[element] = template[element];
      });
      arrayOfJSON.push(newJSON);
    });

    return arrayOfJSON;
  };

  const generateColumns = () => {
    if (tableJSON && tableJSON.length > 0) {
      let columnsArray = [];
      let firstCol = {
        name: "EDIT",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                <button onClick={() => onTemplateEditorOpen(tableMeta)}>
                  <i className="fa fa-edit custom-iconformodal" />
                </button>
              </>
            );
          },
        },
      };
      columnsArray.push(firstCol);
      columnsArray.push({
        name: "globalTemplateNotificationId",
        label: "Global Template Notification ID",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "notificationName",
        label: "NOTIFICATION TEMPLATE NAME",
      });
      columnsArray.push({
        name: "notificationDescription",
        label: "NOTIFICATION DESCRIPTION",
      });
      return columnsArray;
    }
    return [];
  };

  const onTemplateEditorOpen = async (tableMeta) => {
    setIsLoading(true);
    if (tableMeta.rowData[1] != null) {
      navigate("/Admin/Notification/Edit", {
        state: { id: tableMeta.rowData[1] },
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    GetTemplateData();

    const interval = setInterval(
      GetTemplateData,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div></div>
          <div className="Table-body">
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={tableJSON}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
}
