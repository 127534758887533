import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = {
  loginData: { userName: "", password: "", tenantOrClientId: "" },
};

export const loginSlice = createSlice({
  name: "loginState",
  initialState: { value: initialStateValue },
  reducers: {
    setLoginData: (state, action) => {
      // state.value.deptData = action.payload;
      state.value.loginData = action.payload;
    },
  },
});

export const { setLoginData } = loginSlice.actions;

export default loginSlice.reducer;
