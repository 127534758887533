import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLocationData } from "../../../redux/Reducers/locationReducer";
import { useSelector } from "react-redux";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import { validateToken } from "../../../Helpers/validateToken";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { convertHierarchy } from "../../../Helpers/hierarchyConverter";
import { mapParent, mapParentTemp } from "../../../Helpers/hierarchyHelper";
import SyfedTreeSelect from "syfed-tree-select";
import "../location/Location.css";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import Loading from "../../../Loading/Loading";
export default function Location() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "location_id",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };
  const [NewOrUpdate, setNewOrUpdate] = useState("");
  const [addClicked, setAddClicked] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");
  const [locDesc, setLocDesc] = useState("");
  const [selectedParent, setselectedParent] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(1);
  const [SelectedRow, setSelectedRow] = useState({});
  const [dis, setdis] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const displayUpdatedMessage = () => {
    toast.success("Updated Successfully!");
  }; // location etc...
  const displayAddedMessage = () => {
    toast.success("Added Successfully!");
  };

  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };

  const StatusData = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "InActive",
      value: 2,
    },
  ];
  const treeData = useSelector(
    (state) => state.locationState.value.locationData
  );
  async function OnAddNodeClose() {
    setIsLoading(true);
    setdis(true);
    let locJson = {
      location_name: nodeTitle,
      description: locDesc,
      parent_location_id: selectedParent,
      status_id: selectedStatus,
    };
    validateToken();
    await postCrudApi("locations/locations", locJson);
    let dataResult;
    validateToken();
    let result = getCrudApi("locations/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      dispatch(
        setLocationData(
          mapParentTemp(
            dataResult,
            "parent_location_id",
            "location_id",
            "location_name"
          )
        )
      );
    }
    setdis(false);
    setAddClicked(false);
    displayAddedMessage();
    setIsLoading(false);
  }
  async function OnUpdateNodeClose() {
    setIsLoading(true);
    setdis(true);
    let locJson = {
      location_name: nodeTitle,
      description: locDesc,
      parent_location_id:
        selectedParent === SelectedRow.rowData[1]
          ? SelectedRow.rowData[5]
          : selectedParent,
      status_id: selectedStatus,
    };
    validateToken();
    await putCrudApi("locations/" + SelectedRow.rowData[1], locJson);
    let dataResult;
    validateToken();
    let result = getCrudApi("locations/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      dispatch(
        setLocationData(
          mapParentTemp(
            dataResult,
            "parent_location_id",
            "location_id",
            "location_name"
          )
        )
      );
    }
    setdis(false);
    setAddClicked(false);
    displayUpdatedMessage();
    setIsLoading(false);
  }
  const treeMapping = () => {
    if (treeData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        treeData.filter((element) => element.status === "Active"),
        "location_id",
        "parent_location_id"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"location_id"/g,
        '"value"'
      );
      old = old.replace(/"location_name"/g, '"label"');
      var newArray = JSON.parse(old);
      return newArray;
    } else return [];
  };

  const onTemplateEditorOpen = (tableMeta) => {
    setSelectedRow(tableMeta);
    setNewOrUpdate("Update");
    setNodeTitle(tableMeta.rowData[2]);
    setLocDesc(tableMeta.rowData[3]);
    setselectedParent(tableMeta.rowData[5]);
    setselectedStatus(tableMeta.rowData[7]);
    setAddClicked(true);
  };
  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button
                onClick={() =>
                  tableMeta !== null ? onTemplateEditorOpen(tableMeta) : null
                }
              >
                <i className="fa fa-edit custom-iconformodal" />
              </button>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);

    columnsArray.push({
      name: "location_id",
      label: "Group ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "location_name",
      label: "NAME",
    });
    columnsArray.push({
      name: "description",
      label: "DESCRIPTION",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "parent_name",
      label: "PARENT GROUP",
    });
    columnsArray.push({
      name: "parent_location_id",
      label: "Parent Group ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  state === "Active"
                    ? "table-active-cell"
                    : "table-inactive-cell"
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    columnsArray.push({
      name: "status_id",
      label: "Status ID",
      options: {
        display: false,
      },
    });
    return columnsArray;
  };
  const onChangeOfTreeSelect = (value) => {
    setselectedParent(value);
  };
  const onChangeOfStatus = (value) => {
    setselectedStatus(value);
  };

  const onCloseClick = () => {
    setAddClicked(false);
  };

  const onAddNewClick = () => {
    setdis(true);
    setNewOrUpdate("New");
    setNodeTitle("");
    setLocDesc("");
    setselectedParent(null);
    setAddClicked(true);
  };
  useEffect(() => {
    const regx = /^[A-Za-z][A-Za-z0-9_ ]*$/;
    if (nodeTitle?.trim() !== "" && !regx?.test(nodeTitle)) {
      setTitleError(
        "Name must begin with an alphabet, rest should be alphanumeric or contain only _ as its special character."
      );
      setdis(true);
    } else if (
      nodeTitle?.trim() === "" ||
      nodeTitle === undefined ||
      nodeTitle === null
    ) {
      setTitleError("Name Required");
      setdis(true);
    } else {
      setTitleError("");
      setdis(false);
    }
  }, [nodeTitle]);
  const handleError = (e) => {
    setNodeTitle(e.target.value);
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const onRemoveParent = () => {
    if (selectedParent !== null) {
      setselectedParent(null);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={onAddNewClick}
            >
              Add
            </button>
          </div>
          <div>
            <div>
              <div>
                {addClicked ? (
                  <div className="modal">
                    <div className="modal-content">
                      <div style={{ height: "56%" }} className="header-modal">
                        <h5 className="header-title">Group</h5>
                      </div>
                      <div className="div-display">
                        <label className="label-class">
                          Group Name{" "}
                          <i className="fa fa-asterisk aster-risk-Icon"></i>
                        </label>

                        <input
                          type="text"
                          placeholder="Group Name"
                          onChange={handleError}
                          value={nodeTitle}
                          className="textbox-input_1"
                        />
                        {titleError !== "" ? (
                          <div className="alert-danger">{titleError}</div>
                        ) : null}
                      </div>
                      <div className="div-display">
                        <label className="label-class">Group Description</label>

                        <input
                          type="text"
                          placeholder="Group Description"
                          onChange={(e) => {
                            setLocDesc(e.target.value);
                          }}
                          value={locDesc}
                          className="textbox-input_1"
                        />
                      </div>
                      <div className="div-display">
                        <label className="label-class">Parent Group</label>
                        <div className="parentSelectDiv">
                          <SyfedTreeSelect
                            style={treeSelectStyle}
                            size="small"
                            treeCheckedStrategy="all"
                            dataSource={treeMapping()}
                            onChange={onChangeOfTreeSelect}
                            placeholder="Select"
                            value={selectedParent}
                          />
                          <button
                            onClick={() => onRemoveParent()}
                            className="removeParent"
                          >
                            <i className="fa fa-close"></i>
                          </button>
                        </div>
                      </div>
                      <div className="div-display">
                        <label className="label-class">Status</label>
                        <SyfedTreeSelect
                          style={treeSelectStyle}
                          size="small"
                          treeCheckedStrategy="all"
                          dataSource={StatusData}
                          onChange={onChangeOfStatus}
                          placeholder="Select Status"
                          value={selectedStatus}
                        />
                      </div>

                      <div className="footer-modal">
                        <button
                          type="button"
                          className="close-btn"
                          onClick={onCloseClick}
                        >
                          Close
                        </button>
                        {NewOrUpdate === "New" ? (
                          <button
                            type="button"
                            className="Modal-btn"
                            onClick={OnAddNodeClose}
                            disabled={dis}
                          >
                            Add
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="Modal-btn"
                            onClick={OnUpdateNodeClose}
                            disabled={dis}
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Table-body" style={{ overflow: "hidden" }}>
              <div className="table-scroll">
                <MuiThemeProvider theme={customTheme}>
                  <OptimisedMUIDataTable
                    //title={"Departments"}
                    data={treeData}
                    columns={generateColumns()}
                    options={options}
                  />
                </MuiThemeProvider>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
