import "./ConditionThreadPopover.css";

//import { ReactEditor, useEditor } from "slate-react";
import {
  activeConditionThreadIDAtom,
  // prevSelectedConditionThreadIDAtom,
} from "../utils/CommentState";
import { useCallback, useEffect, useState } from "react";
import {
  // useRecoilStateLoadable,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import ConditionPopover from "./ConditionPopover";
//import { Text } from "slate";
//import { getCommentThreadsOnTextNode } from "../utils/EditorCommentUtils";
import { getFirstTextNodeAtSelection } from "../utils/EditorUtils";
//import { usePlateEditorRef } from "@udecode/plate";
import Loading from "../../Loading/Loading";
//import moment from "moment";

export default function ConditionThreadPopover({
  editorOffsets,
  selection,
  threadID,
  conditionJson,
  ...props
}) {
  const activeConditionThreadID = useRecoilValue(activeConditionThreadIDAtom);
  const editor = props.getEditorRef();
  const setActiveConditionThreadID = useSetRecoilState(
    activeConditionThreadIDAtom
  );
  // const setPrevSelectedConditionThreadID = useSetRecoilState(
  //   prevSelectedConditionThreadIDAtom
  // );
  const [threadDataLoadable, setConditionThreadData] = useState([]);
  const [textNode, setTextNode] = useState();

  useEffect(() => {
    if (activeConditionThreadID) {
      setTextNode(getFirstTextNodeAtSelection(editor, selection));
    }
  }, [activeConditionThreadID, editor, selection]);
  useEffect(() => {
    let condArray = [];
    // Object.keys(conditionJson).map((con) => {
    //   condArray.push(conditionJson[con]);
    // });
    Object.keys(conditionJson).forEach((con) => {
      condArray.push(conditionJson[con]);
    });
    setConditionThreadData(condArray);
  }, [conditionJson]);

  const onClickOutside = useCallback(
    (event) => {
      // const slateDOMNode = event.target.hasAttribute("toolbutton")
      //   ? event.target
      //   : null; //event.target.closest(`[data-slate-node]`);
      // // The click event was somewhere outside the Slate hierarchy
      // if (slateDOMNode == null) {
      //   setActiveConditionThreadID(null);
      //   return;
      // }
      setActiveConditionThreadID(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, setActiveConditionThreadID]
  );
  const getSecondVarName = (secondAttribute) => {
    const varName = secondAttribute.split(".");
    return `${varName[varName.length - 1]}`;
  };

  const getConditionString = () => {
    let conditionJSON = null;
    let conditionString = ``;

    if (
      threadDataLoadable &&
      threadDataLoadable.length !== 0 &&
      activeConditionThreadID != null
    ) {
      conditionJSON = threadDataLoadable.filter(
        (ele) => ele.uuid === activeConditionThreadID
      );
      if (conditionJSON && conditionJSON[0]) {
        let conditionlogic = ``;

        conditionJSON[0].logic.forEach((ele, ind) => {
          let secondAttribute =
            ele.secondvariabletype === true
              ? `${getSecondVarName(ele.secondAttribute)}`
              : `"${ele.secondAttribute}"`;
          let firstvariable = ele.firstvariable.split(".");
          firstvariable = firstvariable[firstvariable.length - 1];
          conditionlogic =
            conditionlogic +
            `(${firstvariable} ${ele.operator} ${secondAttribute}) `;
          if (
            conditionJSON[0].logic.length > 0 &&
            ind < conditionJSON[0].logic.length - 1 &&
            conditionJSON[0].multiplelogic === "Multi"
          ) {
            conditionlogic = conditionlogic + `${conditionJSON[0].AndOr} `;
          }
        });
        conditionString = `if ${conditionlogic}`;
      }
    }
    return conditionString;
  };

  // useEffect(() => {
  //   //inputRef.current?.focus();
  //   return () => {
  //     setActiveConditionThreadID(null);
  //   };
  // }, [setActiveConditionThreadID]);

  return (
    <ConditionPopover
      editorOffsets={editorOffsets}
      getEditorRef={props.getEditorRef}
      isBodyFullWidth={true}
      node={textNode}
      className="comment-thread-popover"
      setActiveConditionID={(val) => {
        props.setActiveConditionID(val);
      }}
      onClickOutside={onClickOutside}
      deleteCondition={(uuid) => props.deleteCondition(uuid)}
      deleteDisabled={props.deleteDisabled}
    >
      {threadDataLoadable ? (
        <>
          <div className={"conditiont-list"}>
            <span>{getConditionString()}</span>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </ConditionPopover>
  );
}
