import { setIndent } from './setIndent';

/**
 * Increase the indentation of the selected blocks.
 */
export const indent = (
  editor,
  options
) => {
  setIndent(editor, { offset: 1, ...options });
};
