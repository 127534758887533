import React, { useState, useEffect } from "react";
import "./EsignatureIntegration.css";
import { useLocation } from "react-router-dom";
import Adobe from "../../../assets/images/adobe_login.png";
import AdobeLoginMain from "../../../assets/images/adobe_login_main.png";
import AdobeAccess from "../../../assets/images/adobe_permission_access.png";
import { postCrudApi } from "../../../webServiceCalls/webServiceCall";
import { getDecodedToken } from "../../../Helpers/validateToken";
import { LazyLoadImage } from "react-lazy-load-image-component";
var CryptoJS = require("crypto-js");
export default function AdobeSignIntergration() {
  // const [integrationType, setIntegrationType] = useState("");
  // const [signatureData, setSignatureData] = useState(null);
  const location = useLocation();
  async function SignatureConnect() {
    const token = getDecodedToken(sessionStorage.getItem("user"));

    let encryptedState = CryptoJS.AES.encrypt(
      JSON.stringify({ state: token.tId + token.userId }),
      "cipherAce"
    );
    var newwindow = await window.open(
      process.env.REACT_APP_ADOBESIGN_INTEGRATION_URL +
        "&state=" +
        encryptedState,
      "_self"
    );
  }
  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    let stateVal = query.get("state");
    if (stateVal !== null) {
      stateVal = stateVal.replace(" ", "+");
      let decryptedState = CryptoJS.AES.decrypt(stateVal, "cipherAce");
      const token = getDecodedToken(sessionStorage.getItem("user"));
      const validateState = token.tId + token.userId;
      let originalState = JSON.parse(
        decryptedState.toString(CryptoJS.enc.Utf8)
      );
      if (validateState === originalState?.state) {
        const EsigCode = query.get("code");
        if (EsigCode !== null) {
          let esignCodeJson = {
            code: EsigCode,
          };

          (async () => {
            let result;

            result = postCrudApi(
              "integration/integration/adobe",
              esignCodeJson
            );

            let dataResult;
            await result.then(function (res) {
              dataResult = res;
            });
            // setSignatureData(dataResult);
            //setIntegrationType(dataResult.integrationType);

            window.history.replaceState(
              null,
              "",
              process.env.REACT_APP_ADOBESIGN_REDIRECT_URL
            );
          })();
        }
      }
    }
  }, []);

  return (
    <>
      <div>
        <div className="signature-header">
          <h3>AdobeSign integration</h3>
        </div>
        <div className="formCard">
          <div className="signature-container-grid">
            <div className="formrow">
              <div className="signature-grid-column ">
                <label
                  className="signature-label StepStyle"
                  style={{ marginTop: "12px" }}
                >
                  Step 1
                </label>
              </div>
              <div className="signature-grid-column ">
                <p className="signature-label">
                  Click on Connect Button to Login into AdobeSign which will
                  redirect to Adobe Login Page
                </p>
                <div className="formbutton">
                  <button
                    className="contracts-btn"
                    onClick={() => SignatureConnect()}
                  >
                    Connect
                  </button>
                </div>
              </div>
            </div>
            <div className="formrow">
              <div className="signature-grid-column ">
                <label
                  className="signature-label StepStyle"
                  style={{ marginTop: "12px" }}
                >
                  Step 2
                </label>
              </div>
              <div className="signature-grid-column ">
                {/* <p className="signature-label">Click on reconnect Button to login</p> */}
                {/* <div className="formbutton">
                  <button
                    className="contracts-btn"
                    // onClick={() => SignatureConnect()}
                    // disabled={!enableConnect}
                  >
                    Reconnect
                  </button>
                </div> */}
                <p className="signature-label">
                  In login Page enter Username and please Dont enter Password
                  wait until it rediredts to the adobe Login to enter Password
                </p>
                <div className="stepImage">
                  <LazyLoadImage
                    effect="blur"
                    src={Adobe}
                    className="adobeImg"
                    alt="AdobeLogin"
                  />
                </div>
                <p className="signature-label">
                  Now Enter the password And it will be landed in Signature
                  Intergrations
                </p>
                <LazyLoadImage
                  effect="blur"
                  src={AdobeLoginMain}
                  className="adobeImg"
                  alt="AdobeLoginMain"
                />
              </div>
            </div>
            <div className="formrow">
              <div className="signature-grid-column ">
                <label
                  className="signature-label StepStyle"
                  style={{ marginTop: "12px" }}
                >
                  Step 3
                </label>
              </div>
              <div className="signature-grid-column ">
                <p className="signature-label">
                  Click on Allow Access for permission for Signature which will
                  redirect to Adobe Page
                </p>
                <LazyLoadImage
                  effect="blur"
                  src={AdobeAccess}
                  className="adobeImg"
                  alt="AdobeAccess"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
