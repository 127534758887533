export function numberToWords(num) {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    if (num === 0) return 'zero';

    let result = '';

    if (num >= 1000) {
        result += units[Math.floor(num / 1000)] + ' Thousand ';
        num %= 1000;
    }

    if (num >= 100) {
        result += units[Math.floor(num / 100)] + ' Hundred ';
        num %= 100;
    }

    if (num >= 20) {
        result += tens[Math.floor(num / 10)] + ' ';
        num %= 10;
    } else if (num >= 10) {
        result += teens[num - 10];
        num = 0;
    }

    if (num > 0) {
        result += units[num];
    }

    return result.trim();
}

export function formatDateToWords(date, format) {
    const months = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];

    const days = [
        "First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh",
        "Eighth", "Ninth", "Tenth", "Eleventh", "Twelfth", "Thirteenth",
        "Fourteenth", "Fifteenth", "Sixteenth", "Seventeenth", "Eighteenth",
        "Nineteenth", "Twentieth", "Twenty First", "Twenty Second",
        "Twenty Third", "Twenty Fourth", "Twenty Fifth", "Twenty Sixth",
        "Twenty Seventh", "Twenty Eighth", "Twenty Ninth", "Thirtieth", "Thirty First"
    ];

    const [year, month, day] = [date.getFullYear(), date.getMonth(), date.getDate()];

    switch (format) {
        case "yearFirst":
            return `${numberToWords(year)} ${days[day - 1]} of ${months[month]}`;
        case "monthFirst":
            return `${months[month]} ${days[day - 1]}, ${numberToWords(year)}`;
        case "dateFirst":
            return `${days[day - 1]} ${months[month]} ${numberToWords(year)}`;
        default:
            return "Invalid format";
    }
}

// Example usage:
const date = new Date(2023, 7, 17); // August 17, 2023

console.log(formatDateToWords(date, "yearFirst")); // Output: seventeenth of August twenty twenty three
console.log(formatDateToWords(date, "monthFirst")); // Output: August seventeenth, twenty twenty three
console.log(formatDateToWords(date, "dateFirst")); // Output: seventeenth August twenty twenty three
