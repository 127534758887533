import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import SearchField from "../SearchField/App";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
//import { format } from "date-fns";
const commonInputStyle = css`
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  background: #fff;
  border: 1px solid #48336e;
  padding: 8px;
  color: #555;
  border-radius: 5px;
  backgroundcolor: white;

  &:focus {
    ${"" /* box-shadow: 0 0 5px */}
    ${(props) =>
      props.formBorderColorOnFocus ? props.formBorderColorOnFocus : "#43d1af"};
    border: 1px solid
      ${(props) =>
        props.formBorderColorOnFocus
          ? props.formBorderColorOnFocus
          : "#43d1af"};
  }
  ${(props) => {
    return (
      props.invalid &&
      props.shouldValidate &&
      props.touched &&
      css`
        border: 1px solid
          ${(props) => (props.errorTextColor ? props.errorTextColor : "red")} !important;
        outline: none !important;
        box-shadow: none !important;
      `
    );
  }}
`;

const ErrorBlock = styled.span`
  color: ${(props) => (props.errorTextColor ? props.errorTextColor : "red")};
`;

const input = (props) => {
  let inputElement = null;

  switch (props.elementType) {
    case "input":
      inputElement = (
        <input
          className="Dynamic-Form-Input"
          {...props}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
      case "money":
        inputElement = (
          <input
            className="Dynamic-Form-Input"
            {...props}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
          />
        );
        break;
    case "textarea":
      inputElement = (
        <textarea
          className="Dynamic-Form-TextArea"
          {...props}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          // style={{
          //   border: "2px solid #48336e",
          //   borderRadius: "5px",
          //   height: "43px",
          // }}
          className="Dynamic-Form-Input"
          // Select
          {...props}
          value={props.value}
          onChange={props.changed}
        >
          <option value="">{"Select"}</option>
          {props.elementConfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case "radio":
      inputElement = (
        <React.Fragment>
          {props.elementConfig.options.map((option) => (
            <>
              <div>
                <input
                  style={{
                    height: "5px",
                    border: "2px solid #48336e",
                    borderRadius: "10px",
                    padding: "2px",
                  }}
                  type="radio"
                  checked={props.value === option.value}
                  onChange={props.changed}
                  value={option.value}
                  {...props}
                  //disabled={props.elementConfig.disabled}
                  id={option.value}
                  variant="standard"
                />
                <label for={option.value}> {option.displayValue}</label>{" "}
              </div>
            </>
          ))}
        </React.Fragment>
      );
      break;

    case "checkbox":
      inputElement = (
        <React.Fragment>
          {props.elementConfig.options.map((option) => (
            <div key={option.value}>
              <input
                style={{
                  height: "34px",
                  border: "2px solid #48336e",
                  borderRadius: "10px",

                  padding: "10px",
                  width: "300px",
                }}
                type="checkbox"
                defaultChecked={option.checked}
                onChange={props.changed}
                value={option.value}
                {...props}
                //disabled={props.elementConfig.disabled}
              />
              {option.displayValue}
            </div>
          ))}
        </React.Fragment>
      );
      break;
    case "searchBar":
      inputElement = (
        <div>
          <SearchField
            className="Dynamic-Form-Input"
            {...props}
            //disabled={props.elementConfig.disabled}
            value={props.value}
            columns={props.elementConfig.lookupConfig.dataColumn}
            data={props.elementConfig.lookupConfig.data}
            onChange={props.changed}
            variant="standard"
            id="standard-basic"
            type="search"
            label="Name"
          />
        </div>
      );
      break;
    case "date":
      inputElement = (
        <div>
          {/* <DatePicker
            className="Dynamic-Form-Input"
            props={props}
            {...props.elementConfig}
            {...props}
            value={props.value}
            onChange={props.changed}
            dateFormat="MM/dd/yyyy" 
          /> */}
          <DatePicker
            className="Dynamic-Form-Input"
            dateFormat={props.dateFormat}
            props={props}
            //showIcon={true}
            showMonthDropdown={true}
            showYearDropdown={true}
            scrollableYearDropdown={true}
            excludeScrollbar={false}
            {...props.elementConfig}
            {...props}
            selected={
              props.value ? new Date(props.value) : props.value
              //props.value && props.value !== "" ? new Date(props.value) : ""
            }
            value={props.value ? new Date(props.value) : props.value}
            onChange={(date) => {
              props.changed({
                target: { value: date.toLocaleDateString("en-us") },
              });
            }}
            //dateFormat="yyyy-dd-MM"
          />
        </div>
      );
      break;
    case "currency":
      inputElement = (
        <CurrencyFormat
          thousandSeparator={true}
          className="Dynamic-Form-Input"
          //prefix={props.currencyType}
          {...props}
          //{...props.elementConfig}
          onValueChange={(val) => {
            props.changed({
              target: { value: val.formattedValue },
            });
          }}
          value={props.value}
          prefix={props.currencyType}
        />
      );
      break;
    default:
      inputElement = (
        <input
          className="Dynamic-Form-Input"
          props={props}
          {...props.elementConfig}
          {...props}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return (
    <React.Fragment>
      <div className="Dynamic-Form-elements-column">
        <label className="Dynamic-form-Label">
          {props.elementConfig ? props.elementConfig.placeholder : ""}{" "}
          {props.shouldValidate.required || props.shouldValidate.minLength ? (
            <i className="fa fa-asterisk aster-risk-Icon"></i>
          ) : null}
        </label>

        {/* <div className="Dynamic-form-Label"
          >
            {props.elementConfig.placeholder}
          </div> */}
        <div className="Dynamic-Form-Element">
          {inputElement}
          {props.invalid && props.shouldValidate && props.touched ? (
            <ErrorBlock className="Dynamic-Form-Error-Block" {...props}>
              {props.shouldValidate.error}
            </ErrorBlock>
          ) : (
            <ErrorBlock
              className="Dynamic-Form-Error-Block"
              {...props}
              style={{ visibility: "hidden" }}
            >
              Error
            </ErrorBlock>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default input;
