import React, { useEffect, useState } from "react";
import { getCrudApi } from "../../../webServiceCalls/webServiceCall";
import "./UserManagement.css";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import Loading from "../../../Loading/Loading";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function User() {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const deptid = useSelector((state) => state.deptState.value.deptData);
  const locid = useSelector((state) => state.locationState.value.locationData);

  const navigate = useNavigate();

  useEffect(() => {
    getAllData();
    const interval = setInterval(
      getAllData,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, []);

  const getAllData = () => {
    (async () => {
      setIsLoading(true);
      await getAllUsers();
      await getAllRoles();
      await getAllDepartment();
      await getAllLocation();
      setIsLoading(false);
    })();
  };

  const mapParent = (array) => {
    var map = [];
    var b = {};

    if (array) {
      for (var i = 0; i < array.length; i++) {
        var obj = JSON.parse(JSON.stringify(array[i]));
        b = { ...obj };
        b["isactive"] =
          obj.is_active === 1 ? "Active" : (b["isactive"] = "Inactive");

        for (var j = 0; j < deptid.length; j++) {
          if (obj.dept_id === deptid[j].dept_id) {
            b["department"] = deptid[j].dept_name;
            break;
          } else {
            b["department"] = null;
          }
        }
        for (var n = 0; n < locid.length; n++) {
          if (obj.location_id === locid[n].location_id) {
            b["location"] = locid[n].location_name;
            break;
          } else {
            b["location"] = null;
          }
        }
        for (var j = 0; j < array.length; j++) {
          if (obj.supervisor_id === array[j].userId) {
            b["supervisor"] = array[j].first_name;
            break;
          } else {
            b["supervisor"] = null;
          }
        }

        map.push(JSON.parse(JSON.stringify(b)));
      }
    }

    // setsupid(obj.supervisor_id);

    return map;
  };

  const getAllUsers = async () => {
    await getCrudApi("users/all", {}).then((data) => {
      if (data) {
        const updatedData = mapParent(data);
        setUserData(updatedData);
      }
    });
  };

  const getAllLocation = async () => {
    await getCrudApi("locations/all", {}).then((data) => {
      setLocationData(data);
    });
  };
  const getAllRoles = async () => {
    await getCrudApi("roles/all", {}).then((data) => {
      setRolesData(data);
    });
  };

  const onAddNewClick = () => {
    navigate("/Admin/userManagement/UserForm");
  };

  const getAllDepartment = async () => {
    await getCrudApi("departments/all", {}).then((data) => {
      setDepartmentData(data);
    });
  };

  const onEditClick = (userId) => {
    navigate("/Admin/userManagement/UserForm", {
      state: {
        userId: userId,
      },
    });
  };

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <button onClick={() => onEditClick(tableMeta.rowData[1])}>
              <i className="fa fa-edit custom-iconformodal" />
            </button>
          );
        },
      },
    };
    columnsArray.push(firstCol);
    columnsArray.push({
      name: "userId",
      label: "User ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "userName",
      label: "USER NAME",
    });
    columnsArray.push({
      name: "first_name",
      label: "FIRST NAME",
    });
    columnsArray.push({
      name: "last_name",
      label: "LAST NAME",
    });
    columnsArray.push({
      name: "phone_number",
      label: "Phone Number",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "address_line1",
      label: "Address Line-1",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "address_line2",
      label: "Address Line-2",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "city",
      label: "City",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "u_state",
      label: "State",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "country",
      label: "Country",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "zipcode",
      label: "Zipcode",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "supervisor_id",
      label: "Supervisor_ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "supervisor",
      label: "Supervisor",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "location",
      label: "LOCATION",
    });
    columnsArray.push({
      name: "department",
      label: "DEPARTMENT",
    });
    columnsArray.push({
      name: "role_id",
      label: "ROLE",
      options: {
        customBodyRender: (value, tableMeta) => {
          const userRoleName = rolesData.find(
            (item) => item.roleId === value
          )?.roleName;
          return userRoleName || null;
        },
      },
    });
    // columnsArray.push({
    //   name: "supervisor_id",
    //   label: "SUPERVISOR",
    // });
    columnsArray.push({
      name: "isactive",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  state === "Active"
                    ? "table-active-cell"
                    : "table-inactive-cell"
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });

    return columnsArray;
  };

  const options = {
    filterType: "checkbox",
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    rowsPerPage: 5,
    viewColumns: false,
    search: true,
    sortOrder: {
      name: "userId",
      direction: "desc",
    },
  };

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn "
              onClick={onAddNewClick}
            >
              Add
            </button>
          </div>
          <div className="Table-body">
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={userData}
                  title="User"
                  columns={generateColumns()}
                  options={options}
                  className="muitable"
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
}