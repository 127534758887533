import { atom, atomFamily, selectorFamily } from "recoil";

import React from "react";

export const SetActiveCommentThreadIDContext = React.createContext(null);

export const commentThreadsState = atomFamily({
  key: "commentThreads",
  default: null,
});

export const commentThreadIDsState = atom({
  key: "commentThreadIDs",
  default: new Set([]),
});

export const activeCommentThreadIDAtom = atom({
  key: "activeCommentThreadID",
  default: null,
});

export const commentSelector = selectorFamily({
  key: "commentSelector",
  get:
    (commentThreadIDs) =>
    ({ get }) => {
      const allcomments = [];
      for (let commentid in commentThreadIDs) {
        const comment = get(commentThreadsState(commentThreadIDs[commentid]));
        let eachComment = {
          comment: comment,
          id: commentThreadIDs[commentid],
        };
        allcomments.push(eachComment);
      }
      return allcomments;
    },
  set:
    (commentThreadIDs) =>
    ({ set }) => {
      for (let commentid in commentThreadIDs) {
        set(commentThreadsState(commentThreadIDs[commentid]), null);
      }
      //set(commentThreadsState(),null);
      // set(commentThreadIDsState, new Set([]));
    },
});
export const activeConditionThreadIDAtom = atom({
  key: "activeConditionThreadID",
  default: null,
});
export const prevSelectedConditionThreadIDAtom = atom({
  key: "prevSelectedConditionThreadID",
  default: null,
});
