import {
  setElements,
  unsetNodes,
} from "@udecode/plate";

/**
 * - `node.indent` can not exceed `indentMax`
 * - `node.indent` is unset if `node.type` is not in `types`
 */
export const withIndent = (
  editor,
  {
    inject: { props: { validTypes } = {} },
    inject: { props },
    options: { indentMax },
  }
) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    const element = node;
    const { type } = element;

    if (type) {
      if (validTypes.includes(type)) {
        if (indentMax && element.indent && element.indent > indentMax) {
          setElements(editor, { indent: indentMax }, { at: path });
          return;
        }
      } else if (element.indent) {
        unsetNodes(editor, 'indent', { at: path });
        return;
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

export const withRightIndent = (
  editor,
  {
    inject: { props: { validTypes } = {} },
    inject: { props },
    options: { indentMax },
  }
) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    const element = node;
    const { type } = element;

    if (type) {
      if (validTypes.includes(type)) {
        if (indentMax && element.rightindent && element.rightindent > indentMax) {
          setElements(editor, { rightindent: indentMax }, { at: path });
          return;
        }
      } else if (element.rightindent) {
        unsetNodes(editor, 'rightindent', { at: path });
        return;
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

