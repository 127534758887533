import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../assets/images/Contrace_New.svg";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
function Navbar(props) {
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  const Logout = () => {
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("persist:root");
    sessionStorage.removeItem("userNAme");
    navigate("/");
  };
  return (
    <div>
      <nav className="nav-header">
        <div className="logo-div">
          <LazyLoadImage
            effect="blur"
            className="applogo"
            src={logo}
            alt="logo"
          />
        </div>
        <div className={props.sidebarOpen ? " nav-toggle" : " nav-header1"}>
          <div
            className={props.sidebarOpen ? " userName-div1" : " userName-div"}
          >
            <label className="userName">
              {sessionStorage.getItem("userNAme")}
            </label>
          </div>

          <div>
            <button className="icon-background" onClick={Logout} title="Logout">
              <i className="fas fa-sign-out-alt  logoutBtn"></i>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
