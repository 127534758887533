import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { terminateTemplateData: {} };

export const terminateTemplateSlice = createSlice({
  name: "terminateTemplateState",
  initialState: { value: initialStateValue },
  reducers: {
    setTerminateTemplateData: (state, action) => {
      state.value.terminateTemplateData = action.payload;
    },
  },
});

export const { setTerminateTemplateData } = terminateTemplateSlice.actions;

export default terminateTemplateSlice.reducer;
