import React, { useState, useEffect } from "react";
import "./SimpleEditor.css";
import Checkbox from "react-simple-checkbox";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import { validateToken } from "../../../Helpers/validateToken";
import { useLocation, useNavigate } from "react-router";
import { RecoilRoot } from "recoil";
import PlateEditor from "../../../PlateEditor/PlateEditor";
import { validateVariable } from "../../../Helpers/validation";
import Loading from "../../../Loading/Loading";

export default function SimpleEditor(props) {
  const [rawText, setRawText] = useState("");
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [clauseName, setClauseName] = useState("");
  const [checked, setChecked] = useState(true);
  const [clauseData, setclauseData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [clauseVariables, setClauseVariables] = useState();
  const [loaded, setloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [masterLibrary, setMasterLibrary] = useState({});
  const [pageSize, setPageSize] = useState("A4");
  const plateRef = React.createRef();
  const location = useLocation();
  const GetMasterLibrary = async () => {
    setIsLoading(true);
    let result, dataResult, clauseDataResult;
    validateToken();
    result = getCrudApi("library/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      setMasterLibrary(dataResult);
    }
    setIsLoading(false);
  };

  const handleActiveClick = () => {
    setChecked(!checked);
  };

  const EditClauseDetails = () => {
    setEditModalOpen(true);
  };

  const OnEditClauseClose = () => {
    setEditModalOpen(false);
    setClauseName(clauseName);
    setChecked(checked);
  };

  const displaySubmittedMessage = () => {
    toast.success("Submitted Successfully!");
  };

  const onClickSubmit = async () => {
    setIsLoading(true);
    const serializedData = plateRef.current.serailizedData();
    const pageType = plateRef.current.getPageSize();
    setPageSize(pageType);
    setRawText(serializedData);

    const value = validateVariable(clauseVariables);
    if (value !== null) {
      let valid = value[0];
      let variables = value[1];
      if (valid === false) {
        toast.error(variables + "variables are not configured");
      } else {
        setOpen(true);

        let clauseJson = {
          clauseHtml: serializedData,
          pageSize:pageType
        };
        let var_json = {
          var: clauseVariables,
        };
        let newClause = {
          clauseName: clauseName,
          statusId: checked === true ? 1 : 2,
          clauseHtml: clauseJson,
          clauseVariables: var_json,
        };
        let result;
        if (clauseData == null) {
          validateToken();
          result = postCrudApi("clauses/clauses", newClause);
        } else {
          validateToken();
          result = putCrudApi("clauses/" + clauseData.clauseId, newClause);
        }
        let dataResult;
        await result.then(function (res) {
          dataResult = res;
        });
        if (dataResult) {
          displaySubmittedMessage();
          setclauseData(dataResult);
          let cluase_id = dataResult.clauseId;
          let cluaseHistory = {
            clauseHistory: clauseJson,
            clauseId: cluase_id,
            clauseHistoryVariables: var_json,
          };
          validateToken();
          result = postCrudApi("clausesHistory/clausesHistory", cluaseHistory);
          await result.then(function (res) {
            dataResult = res;
          });
        }
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    UpdateEditor();
    setClauseName(location.state.selectedClause);
    setclauseData(location.state.clauseData);
    //
    setChecked(
      location.state.selectedRowData
        ? location.state.selectedRowData[4] === 1
          ? true
          : false
        : true
    );
    GetMasterLibrary();
  }, []);

  const UpdateEditor = async () => {
    setIsLoading(true);
    setloaded(false);
    if (location.state.selectedRowData != null) {
      validateToken();
      let result = getCrudApi(
        "clauses/" + location.state.selectedRowData[1],
        {}
      );
      let dataResult;
      await result.then(function (res) {
        dataResult = res;
      });
      setPageSize(dataResult?.clauseHtml?.pageSize ? dataResult?.clauseHtml?.pageSize : "A4")
      setRawText(dataResult.clauseHtml.clauseHtml);
      setClauseVariables(dataResult.clauseVariables.var);
    } else {
      setRawText(null);
      setClauseVariables([]);
    }
    setloaded(true);
    setIsLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <div className="clause-header">
              <div className="ClauseformHeader">
                <label>{clauseName}</label>
              </div>
              <div className="formbutton">
                <button
                  className="editor-clause-edit"
                  onClick={EditClauseDetails}
                >
                  Edit
                </button>
                <button className="editor-clause-edit" onClick={onClickSubmit}>
                  Submit
                </button>
              </div>
            </div>
            {loaded === true ? (
              <RecoilRoot>
                <PlateEditor
                  id={"clauseEditor"}
                  ref={plateRef}
                  parentGroup="Clause"
                  isNegotiationDisabled={true}
                  isVariableDisabled={false}
                  isPartiesDisabled={true}
                  isConditionDisabled={true}
                  isClauseDisabled={true}
                  canAddComments={false}
                  isEditable={true}
                  isConditionToggleDisabled={true}
                  isCommentToggleDisabled={true}
                  // isEditorWithoutVariableSec={false}  //its for old comments
                  getClauseData={(clauseID) => this.returnClauseData(clauseID)}
                  finalJSON={clauseVariables ? clauseVariables : null}
                  setFinalJSON={(finJSON) => {
                    setClauseVariables(finJSON);
                  }}
                  masterLibrary={masterLibrary}
                  contractTemplateJsonData={rawText ? rawText : null}
                  setContractTemplateJsonData={(val) => {
                    setRawText(val);
                  }}
                  currentUser="user"
                  //  masterLibrary={this.state.masterLibrary}
                  // commentJSON={this.state.commentJSON}
                  // setCommentsJson={(coms) => {
                  //
                  //   this.setState({
                  //     commentJSON: coms,
                  //   });
                  // }}
                  serializeCall={"serializeTemplate"}
                  allowNontemp={false}
                  allowSignature={false}
                  dataPath="clauseVariables"
                  tabForVarId="clauseVarTab"
                  pageSize={pageSize}
                />
              </RecoilRoot>
            ) : (
              <Loading />
            )}
          </div>
        </>
      )}

      {EditModalOpen ? (
        <div className="modal ">
          <div className="modal-content ">
            <div className="header-modal">
              <h5 className="header-title"> Edit Details</h5>
            </div>
            <div className="div-display">
              <label className="label-class">Clause Name</label>

              <input
                type="text"
                value={clauseName}
                placeholder="Clause Name"
                onChange={(e) => setClauseName(e.target.value)}
                className="textbox-input_1"
              />
            </div>

            <div style={{ display: "flex" }} className="div-display">
              <label className="Active-label">Active</label>
              <div>
                <Checkbox
                  size={3}
                  borderThickness={1}
                  backAnimationDuration={10}
                  tickAnimationDuration={100}
                  className="Active-checkbox"
                  color="#48336e"
                  checked={checked}
                  onChange={() => {
                    handleActiveClick();
                  }}
                ></Checkbox>
              </div>
            </div>

            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setEditModalOpen(false);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={() => OnEditClauseClose()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
