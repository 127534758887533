import React, { useState, useEffect } from "react";
import "./EsignatureIntegration.css";
import logo from "../../../assets/images/step2DocuSign.png";
import { useLocation } from "react-router-dom";
//import { createTheme, MuiThemeProvider } from "@material-ui/core";
import {
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function DocuSignIntergration() {
  let code_auth =
    "eyJ0eXAiOiJNVCIsImFsZyI6IlJTMjU2Iiwia2lkIjoiNjgxODVmZjEtNGU1MS00Y2U5LWFmMWMtNjg5ODEyMjAzMzE3In0.AQoAAAABAAYABwAAEsa2tQXaSAgAAJ5M_rUF2kgCANlrMCisaZBJuERVExZZUOUVAAEAAAAYAAIAAAAFAAAACgAAAA0AJAAAAGYyMmUwMWY4LTJiM2YtNDhiNS1iMDZmLWVlMTYwNDRhYmQ3ZSIAJAAAAGYyMmUwMWY4LTJiM2YtNDhiNS1iMDZmLWVlMTYwNDRhYmQ3ZTcAVEysd0CleEiPD5UiRCTeqDAAABosZ6YF2kg.p_qktdqMScqHN1daYF2EqI2Ry0FNEXS2wwYuWfJDRJlLdOxFryUpAcDy7Nm8iRyzKsw9Y6Yt2G_cqH5oYu7JTkJC80NdQfmlDxOho5itTvR2SC7bgvx0EAYMF5i5Z2Q39nlY2l13bpKjgLk4q8Q6hRXbzFpx_dpiZFBGqdJQAuJyxJwd7bJPo2IHDYQ2_bZG_OA0g-okOImlzASUX203ahQUPy4OeVTgCmlTI-fyNJ3cTE9dS-wWOki6ywYBECgQ2CU2OaCaVMt4mc6szCrMuy4VBoxc9nCfiA6GMO9ny8KLAwb0z_JmnGc7iCiWnJsDMNYlTv5R0fIIsBmQLrZ_IQ";
  const [userID, setuserID] = useState("");
  const [enableConnect, setenableConnect] = useState(false);
  // const [integrationType, setIntegrationType] = useState("");
  // const [signatureData, setSignatureData] = useState(null);
  const location = useLocation();
  async function SignatureConnect() {
    var newwindow = await window.open(
      process.env.REACT_APP_DOCUSIGN_INTEGRATION_URL,
      "_self"
    );
    // if (newwindow) {
    // let query = new URLSearchParams(window.location.search);
    //
    // let code = query.get("code");
    //
    // var newwindow2 = window.open("http://localhost:3000/dashboard", "_self");
    //  }

    var formBody = [];
    formBody.push(
      "grant_type" + "=" + encodeURIComponent("authorization_code")
    );
    formBody.push("code" + "=" + encodeURIComponent(code_auth));
    formBody = formBody.join("&");
    // let formBody = new URLSearchParams({
    //   grant_type: "authorization_code",
    //   code: code_auth,
    // });
    // const params = new URLSearchParams();
    // params.append("grant_type", "authorization_code");
    // params.append("code", code);
    // params.append("redirect_uri", "http://localhost:3000/dashboard");
    // let formBody = querystring.stringify({
    //   grant_type: "authorization_code",
    //   code: code,
    //   redirect_uri: "http://localhost:3000/dashboard",
    // });
    let dataResult;

    // let result = await postCrudApiESignature(formBody);
    // await result.then(function (res) {
    //   dataResult = res;
    // });
    // sessionStorage.setItem("accesstoken", dataResult);
    //
  }

  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    const EsigCode = query.get("code");
    if (EsigCode !== null) {
      let esignCodeJson = {
        integrationUserId: sessionStorage.getItem("EUserID"),
        integrationType: sessionStorage.getItem("IType"),
      };
      (async () => {
        let result;
        let signatureDataSet = sessionStorage.getItem("signatureData");

        if (signatureDataSet === "null") {
          result = postCrudApi("integration/integration/first", esignCodeJson);
        } else {
          result = putCrudApi(
            "integration/" + JSON.parse(signatureDataSet).integrationId,
            esignCodeJson
          );
        }
        let dataResult;
        await result.then(function (res) {
          dataResult = res;
        });
        // setSignatureData(dataResult);
        setuserID(dataResult.integrationUserId);
        //setIntegrationType(dataResult.integrationType);
        sessionStorage.setItem("IType", dataResult.integrationType);
        sessionStorage.setItem("EUserID", dataResult.integrationUserId);
        sessionStorage.setItem("signatureData", JSON.stringify(dataResult));

        window.history.replaceState(
          null,
          "",
          process.env.REACT_APP_DOCUSIGN_REDIRECT_URL
        );
      })();
    }
  }, []);
  useEffect(() => {
    if (location && location.state) {
      if (location.state.selectedData) {
        // setSignatureData(location.state.selectedData);
        setuserID(location.state.selectedData.integrationUserId);
        sessionStorage.setItem(
          "IType",
          location.state.selectedData.integrationType
        );
        sessionStorage.setItem(
          "EUserID",
          location.state.selectedData.integrationUserId
        );

        sessionStorage.setItem(
          "signatureData",
          JSON.stringify(location.state.selectedData)
        );
        setenableConnect(true);
      } else {
        sessionStorage.setItem("IType", location.state.type);
        sessionStorage.setItem("signatureData", null);
        setenableConnect(false);
      }
    }
  }, [location.state]);

  const onChangeOfUserID = (value) => {
    setuserID(value);
    sessionStorage.setItem("EUserID", value);
    if (value.length !== 0 && value !== null) {
      setenableConnect(true);
    } else {
      setenableConnect(false);
    }
  };
  return (
    <>
      <div>
        <div className="signature-header">
          <h3>DocuSign integration</h3>
        </div>
        <div className="formCard">
          <div className="signature-container-grid">
            <div className="signature-formrow">
              <div className="signature-grid-column ">
                <label
                  className="signature-label"
                  style={{ marginTop: "12px" }}
                >
                  Impersonated User ID
                </label>
              </div>

              <div className="signature-grid-column ">
                <input
                  type="text"
                  className="text-form-esignature"
                  value={userID}
                  onChange={(e) => onChangeOfUserID(e.target.value)}
                />
              </div>
              <i
                class="fa fa-info-circle sign-icon"
                title="Here are steps to Follow"
              ></i>
            </div>
            <div className="signature-form-columns">
              <h2>How to get Impersonated User ID?</h2>
            </div>
            <div className="formrow">
              <div className="signature-grid-column ">
                <label
                  className="signature-label StepStyle"
                  style={{ marginTop: "12px" }}
                >
                  Step 1
                </label>
              </div>
              <div className="signature-grid-column">
                <p className="signature-label" style={{ marginTop: "12px" }}>
                  Impersonated User ID: This is a GUID identifying the DocuSign
                  user that you will be impersonating with the access token.
                  Your own User ID can be found at the top of the{" "}
                  <a
                    href="https://admindemo.docusign.com/authenticate?goTo=appsAndKeys"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apps and Keys
                  </a>{" "}
                  page.
                </p>
                <div className="stepImage">
                  <LazyLoadImage
                    effect="blur"
                    src={logo}
                    className="docusignImg"
                    alt="Step 2"
                  />
                </div>
              </div>
            </div>
            <div className="formrow">
              <div className="signature-grid-column ">
                <label
                  className="signature-label StepStyle"
                  style={{ marginTop: "12px" }}
                >
                  Step 2
                </label>
              </div>
              <div className="signature-grid-column ">
                <p className="signature-label">
                  Click the Connect button to connect for Intergration
                </p>
                <div className="formbutton">
                  <button
                    className="contracts-btn"
                    onClick={() => SignatureConnect()}
                    disabled={!enableConnect}
                  >
                    Connect
                  </button>
                </div>
              </div>
            </div>
            <div className="formrow">
              <div className="signature-grid-column">
                <label
                  className="signature-label StepStyle"
                  style={{ marginTop: "12px" }}
                >
                  Step 3
                </label>
              </div>
              <div className="signature-grid-column ">
                <p className="signature-label">
                  Please Click on Connect Button to re-enter credentials and
                  Redirect{" "}
                </p>
                {/* <div className="formbutton">
                                    <button
                                        className="contracts-btn"
                                    // onClick={() => SignatureConnect()}
                                    // disabled={!enableConnect}
                                    >
                                        Connect
                                    </button>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
