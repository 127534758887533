import React, { useEffect, useState, useRef } from "react";
import "./login/loginNew.css";
import LaptopImage from "../assets/images/laptop.png";
import applogo from "../assets/images/Contrace_New.svg";
import Loading from "../Loading/Loading";
import { toast } from "react-toastify";
import logo from "../assets/images/New_Logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useLocation } from "react-router-dom";
import loginBackground from "../assets/images/background.png";
import {
  putCrudApi,
  postCrudApi,
  getCrudApi,
} from "../webServiceCalls/webServiceCall";
import showPwdImg from "../assets/icons/closedeye.png";
import hidePwdImg from "../assets/icons/openeye.png";
import { ToastHelper } from "../app/ToastHelpher";
import PasswordChecklist from "react-password-checklist";
import { checkNotNull } from "../Helpers/InputValidationsHelpher";
import Required from "../Helpers/RequiredMessage";

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [checkPassword, setCheckPassword] = useState({
    forgotpassword: "",
    retrypassword: "",
  });
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isConfirmedPwd, setIsConfirmedPwd] = useState(false);
  const [forgotTenant, setForgotTenant] = useState(location.state.schema);
  const [forgotUserName, setForgotUserName] = useState(location.state.userName);
  const [forgotpasswordOtp, setForgotpasswordOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showInputs, setShowInput] = useState(false);
  const [hideInputs, setHideInputs] = useState(true);
  const [userNameError, setUserNameError] = useState(true);
  const [tenantError, setTenantError] = useState(true);
  const [passwordProperties, setPasswordProperties] = useState({});
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [timerValue, setTimerValue] = useState(600);
  const [isActive, setIsActive] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [comfirmValid, setComfirmValid] = useState(true);
  const [newValid, setNewValid] = useState(true);

  const getAllSettings = async () => {
    if (forgotTenant) {
      await getCrudApi("auth/companySettings/" + forgotTenant, {}).then(
        (all) => {
          if (all) {
            let passSetting = all?.filter(
              (setting) => setting.companySettingType === "Password"
            );
            if (passSetting.length === 1) {
              if (passSetting[0]?.valueData !== null)
                setPasswordProperties(passSetting[0]?.valueData);
            }
          }
          // set the pass word settings to state variable passSetJson
        }
      );
    }
  };
  useEffect(() => {
    getAllSettings();
  }, [showInputs]);

  const generateOtp = (event) => {
    event.preventDefault();
    getOtpData();
    setIsActive(true);
  };
  const getOtpData = async () => {
    setIsLoading(true);
    if (validateInputBfAU()) {
      let result;
      let otpJson = {
        tenant: forgotTenant,
        userName: forgotUserName,
      };
      result = postCrudApi("auth/otp", otpJson);
      let dataResult;
      await result.then(function (res) {
        dataResult = res;
      });
      if (
        dataResult === 400 ||
        dataResult === null ||
        dataResult === 500 ||
        dataResult === 502
      ) {
        toast.error("Please Enter valid Details");
      } else {
        setShowInput(true);
        setHideInputs(false);
        toast.success("OTP will be Sent to your Email");
      }
    }
    // else {
    //   toast.error("Please Enter valid Details");
    // }
    setIsLoading(false);
  };

  const validateInputBfAU = () => {
    let isValid = true;
    if (!checkNotNull(forgotUserName)) {
      isValid = false;
      setUserNameError(false);
    }
    if (!checkNotNull(forgotTenant)) {
      isValid = false;
      setTenantError(false);
    }

    return isValid;
  };

  const validateInputforOtp = () => {
    let isValid = true;
    if (!checkNotNull(checkPassword?.retrypassword)) {
      isValid = false;
      setComfirmValid(false);
    }
    if (!checkNotNull(checkPassword?.forgotpassword)) {
      isValid = false;
      setNewValid(false);
    }

    return isValid;
  };

  useEffect(() => {
    let timer = null;
    if (isActive && timerValue > 0) {
      // Set up a timer that counts down
      timer = setInterval(() => {
        setTimerValue((prevtimerValue) => prevtimerValue - 1);
      }, 1000);
    } else if (timerValue <= 0) {
      // setIsActive(false);
      setShowBtn(true);
    }
    return () => clearInterval(timer);
  }, [isActive, timerValue]);

  const formatTime = (totaltimerValue) => {
    const minutes = Math.floor(totaltimerValue / 60);
    const timerValue = totaltimerValue % 60;
    return `${minutes.toString().padStart(2, "0")}:${timerValue
      .toString()
      .padStart(2, "0")}`;
  };

  const updatePassword = async () => {
    setIsLoading(true);
    let result;
    if (validateInputforOtp()) {
      let passwordJson = {
        tenant: forgotTenant,
        userName: forgotUserName,
        password: checkPassword?.forgotpassword,
      };
      let dataResult;
      result = putCrudApi("auth/validate/" + forgotpasswordOtp, passwordJson);
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        toast.success("Password Updated Successfully!");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Please Enter Valid OTP!");
      }
    } else {
      toast.error("Please Enter valid Details!");
    }
    setIsLoading(false);
  };
  const onSubmitPassword = async () => {
    await updatePassword();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setComfirmValid(name === " retrypassword " ? value : comfirmValid);
    setNewValid(name === "forgotpassword" ? value : newValid);
    setCheckPassword({
      ...checkPassword,
      [name]: value,
    });
  };

  const resetTimer = () => {
    setIsActive(true);
    setTimerValue(600); // Reset the timer to 10 minutes
  };
  const onResend = () => {
    setShowBtn(false);
    resetTimer();
    getOtpData();
  };
  const messagePasswordJson = () => {
    let message = {};
    Object?.keys(passwordProperties)?.map(
      (data) => (message[data] = passwordProperties[data]?.msg)
    );
    return message;
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="login-background-div">
          {" "}
          <div className="login-sub-Main1">
            <div className="login-sub-main-img">
              <img
                width={"100%"}
                loading="lazy"
                src={loginBackground}
                alt="background"
              />
              <div className="login-sub-main-imgdiv">
                <LazyLoadImage
                  effect="blur"
                  src={LaptopImage}
                  height={380}
                  alt="laptop"
                />
              </div>
            </div>
            <div className="login-content-text">
              ContrACE is a comprehensive contract management tool that is
              specifically designed to handle the entire contract lifecycle,
              encompassing everything from initial creation to execution and
              renewal. By streamlining your contract management processes,
              ContrACE empowers your organization to efficiently create, track,
              and manage contracts. With its user-friendly interface and
              powerful features, ContrACE ensures that you have full control and
              visibility over your contracts, enabling you to optimize your
              operations and achieve better contract outcomes.
            </div>
          </div>
          <div className="login-sub-Main">
            <div className="login-screen-2">
              <div className="login-logo-div">
                <LazyLoadImage
                  effect="blur"
                  className="login-logo"
                  src={applogo}
                  alt="Contrace"
                />
              </div>
              {hideInputs && (
                <div className="screen__content">
                  <div className="login">
                    <div className="login__field">
                      <input
                        class="login__input"
                        type="type"
                        placeholder="Tenant Name"
                        value={forgotTenant}
                        onChange={(e) => {
                          setForgotTenant(e.target.value);
                        }}
                      />
                    </div>
                    <div className="login__field">
                      <input
                        class="login__input"
                        type="type"
                        placeholder="Username"
                        value={forgotUserName}
                        onChange={(e) => {
                          setForgotUserName(e.target.value);
                          setUserNameError(checkNotNull(e.target.value));
                        }}
                      />
                    </div>
                    <button class="login__submit" onClick={generateOtp}>
                      Generate OTP
                    </button>
                  </div>
                </div>
              )}

              {showInputs && (
                <>
                  <div className="login__field">
                    <input
                      class="login__input"
                      type={isRevealPwd ? "text" : "password"}
                      name="forgotpassword"
                      value={checkPassword?.forgotpassword}
                      placeholder="New Password"
                      onChange={handleOnChange}
                    />

                    <img
                      loading="lazy"
                      className="pwd-container"
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? showPwdImg : hidePwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                      alt="Eye"
                    />

                    {/* {!newValid && <Required message={"*required"} />} */}
                  </div>
                  <div className="login__field">
                    <input
                      class="login__input"
                      name="retrypassword"
                      type={isConfirmedPwd ? "text" : "password"}
                      onPaste={(e) => preventCopyPaste(e)}
                      value={checkPassword?.retrypassword}
                      placeholder="Confirm New Password"
                      onChange={handleOnChange}
                    />
                    {/* {!comfirmValid && <Required message={"*required"} />} */}

                    <img
                      loading="lazy"
                      className="pwd-container"
                      title={isConfirmedPwd ? "Hide password" : "Show password"}
                      src={isConfirmedPwd ? showPwdImg : hidePwdImg}
                      onClick={() =>
                        setIsConfirmedPwd((prevState) => !prevState)
                      }
                      alt="Eye"
                    />
                  </div>
                  <div className="forget-password-div">
                    <PasswordChecklist
                      rules={
                        passwordProperties
                          ? [...Object?.keys(passwordProperties), "match"]
                          : ["match"]
                      }
                      minLength={
                        passwordProperties
                          ? passwordProperties["minLength"]?.val
                          : 0
                      }
                      maxLength={
                        passwordProperties
                          ? passwordProperties["maxLength"]?.val
                          : 0
                      }
                      value={checkPassword?.forgotpassword}
                      valueAgain={checkPassword?.retrypassword}
                      iconSize={12}
                      validColor={"#655c8a"}
                      invalidColor={"#ca0000"}
                      onChange={(isValid) => {
                        setIsValidPassword(isValid);
                      }}
                      messages={
                        messagePasswordJson()
                          ? messagePasswordJson()
                          : { match: "Match the password" }
                      }
                    />
                  </div>
                  <div className="login__field">
                    <input
                      class="login__input"
                      type="type"
                      value={forgotpasswordOtp}
                      placeholder="OTP"
                      onChange={(e) => setForgotpasswordOtp(e.target.value)}
                    />
                  </div>
                  <button className="login__submit" onClick={onSubmitPassword}>
                    Submit
                  </button>
                  <div className="forgotPassword-timer">
                    Otp will Expire in: {formatTime(timerValue)}
                  </div>
                  {showBtn && (
                    <button className="login__submit" onClick={onResend}>
                      Resent Otp
                    </button>
                  )}
                </>
              )}

              <div>
                <img
                  loading="lazy"
                  className="login-company-logo"
                  src={logo}
                  alt="Contrace"
                ></img>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastHelper />
    </>
  );
};

export default ForgotPassword;
