import React, { memo } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

function OptimisedMUIDataTable(props) {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            backgroundColor: "red !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FF0000",
          },
        },
        MUIDataTablePagination: {
          root: {
            backgroundColor: "#000",
            color: "#fff",
          },
        },
      },
    });

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        data={props.data}
        columns={props.columns}
        options={props.options}
        components={props.components}
      />
    </MuiThemeProvider>
  );
}

export default memo(
  OptimisedMUIDataTable,
  (prevProps, nextProps) => prevProps === nextProps
);
