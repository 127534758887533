import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";

import storageSession from "redux-persist/lib/storage/session";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import deptReducer from "./redux/Reducers/deptReducer";
import categoryReducer from "./redux/Reducers/categoryReducer";
import locationReducer from "./redux/Reducers/locationReducer";
import contractTypeReducer from "./redux/Reducers/contractTypeReducer";
import loginReducer from "./redux/Reducers/loginReducer";
import tokenReducer from "./redux/Reducers/tokenReducer";
import renewalTypeReducer from "./redux/Reducers/renewalTypeReducer";
import contractTemplateReducer from "./redux/Reducers/contractTemplateReducer";
import statusReducer from "./redux/Reducers/statusReducer";
import amendmentTemplateReducer from "./redux/Reducers/amendmentTemplateReducer";
import terminateTemplateReducer from "./redux/Reducers/terminationTemplateReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  tokenState: tokenReducer,
  loginState: loginReducer,
  deptState: deptReducer,
  locationState: locationReducer,
  categoryState: categoryReducer,
  contractTypeState: contractTypeReducer,
  contractTemplateState: contractTemplateReducer,
  renewalTypeState: renewalTypeReducer,
  statusState: statusReducer,
  amendmentTemplateState: amendmentTemplateReducer,
  terminateTemplateState: terminateTemplateReducer,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  version: "1",
  devTools: false,
});
let persistor = persistStore(store);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
          {/* <MainPageNegotiation /> */}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter basename="/">
//       <Provider store={store}>
//         <MainPage />
//       </Provider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
