import React, { useState, useEffect } from "react";
import PasswordChecklist from "react-password-checklist";
import { validateToken } from "../../../Helpers/validateToken";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  checkNotNull,
  isEmail,
  isAlpha,
} from "../../../Helpers/InputValidationsHelpher";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import Select from "react-select";
import selectOptionsMap from "../../../Helpers/selectOptionsMap";
import Required from "../../../Helpers/RequiredMessage";
import "./UserManagement.css";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Loading/Loading";

export default function UserForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [city, setCity] = useState("");
  const [uState, setUState] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [superviserId, setSuperviserId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [roleIds, setRoleIds] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [usersId, setUsersId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [disUserName, setDisUserName] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  //validation
  const [fNameError, setfNameError] = useState(true);
  const [userNameError, setUserNameError] = useState(true);
  const [emailError, setfEmailError] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(true);
  const [passwordError, setfPasswordError] = useState(true);
  const [roleError, setRoleError] = useState(true);
  const [locationData, setLocationData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [passwordProperties, setPasswordProperties] = useState({});

  const messagePasswordJson = () => {
    let message = {};
    if (passwordProperties) {
      Object?.keys(passwordProperties)?.map(
        (data) => (message[data] = passwordProperties[data]?.msg)
      );
      return message;
    }
  };
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "1px",
      display: "flex",
      borderRadius: "5px",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };

  const validateInputBfAU = (e) => {
    let isValid = true;
    isValid =
      (!fNameError &&
        !userNameError &&
        !emailError &&
        !roleError &&
        !passwordError) ||
      !passwordCheck;
    return isValid;
  };

  const getAllSettings = async () => {
    await getCrudApi("companySettings/all", {}).then((all) => {
      let passSetting = all.filter(
        (setting) => setting.companySettingType === "Password"
      );
      if (passSetting.length === 1) {
        if (passSetting[0]?.valueData !== null) {
          setPasswordProperties(passSetting[0]?.valueData);
        }
      }
      // set the pass word settings to state variable passSetJson
    });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    (async () => {
      setIsLoading(true);
      await getAllUsers();
      await getAllRoles();
      await getAllDepartment();
      await getAllLocation();
      await onTemplateEditorOpen();
      await getAllSettings();
      setIsLoading(false);
    })();
  };

  const getAllUsers = async () => {
    await getCrudApi("users/all", {}).then((data) => {
      setUserData(data);
    });
  };

  const OnAddUser = async () => {
    setIsLoading(true);
    if (validateInputBfAU()) {
      let result;
      let userJson = {
        userName: userName,
        password: userPassword,
        first_name: firstName,
        last_name: lastName,
        userEmail: userEmail,
        phone_number: phoneNumber,
        address_line1: addressLineOne,
        address_line2: addressLineTwo,
        city: city,
        u_state: uState,
        is_active: isActive,
        zipcode: zipcode,
        country: country,
        supervisor_id: superviserId,
        location_id: locationId,
        dept_id: departmentId,
        role_id: roleIds,
      };
      validateToken();
      result = postCrudApi("users/user", userJson);
      let dataResult;
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        if (dataResult.is_active === 2) {
          displayStatusMessage();
        } else if (dataResult === 400) {
          displayUserMessage();
        } else {
          displayAddedMessage();
        }
      }
      navigate("/Admin/userManagement");
    }

    setIsLoading(false);
  };

  const ActiveStatus = [
    { value: 1, label: "Active" },
    { value: 2, label: "InActive" },
  ];

  const onTemplateEditorOpen = async () => {
    setIsLoading(true);

    if (location?.state?.userId) {
      await getCrudApi("users/" + location?.state?.userId, {}).then((data) => {
        // const data = res[0];
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setUserName(data.userName);
        setAddressLineOne(data.address_line1);
        setAddressLineTwo(data.address_line2);
        setPhoneNumber(data.phone_number);
        setCity(data.city);
        setUserEmail(data.userEmail);
        setCountry(data.country);
        setZipcode(data.zipcode);
        setSuperviserId(data.supervisor_id);
        setUState(data.u_state);
        setLocationId(data.location_id);
        setDepartmentId(data.dept_id);
        setRoleIds(data.role_id);
        setIsActive(data.is_active);
        setUsersId(data.userId);
        setDisUserName(true);
        setfNameError(
          !checkNotNull(data.first_name) || !isAlpha(data.first_name)
        );
        setfEmailError(
          !checkNotNull(data.userEmail) || !isEmail(data.userEmail)
        );
        setRoleError(!checkNotNull(data.roleIds));
        setfPasswordError(true);
        setUserNameError(!checkNotNull(data.userName));
      });
    }
    setIsLoading(false);
  };

  const getAllLocation = async () => {
    await getCrudApi("locations/all", {}).then((data) => {
      setLocationData(data);
    });
  };
  const getAllRoles = async () => {
    await getCrudApi("roles/all", {}).then((data) => {
      setRolesData(data);
    });
  };
  const getAllDepartment = async () => {
    await getCrudApi("departments/all", {}).then((data) => {
      setDepartmentData(data);
    });
  };

  const displayUpdatedMessage = () => {
    toast.success("Updated Successfully!");
  };
  const displayStatusMessage = () => {
    toast.success("Added User with InActive!");
  };
  const displayAddedMessage = () => {
    toast.success("Added Successfully!");
  };
  const displayUserMessage = () => {
    toast.error("UserName already Exists!");
  };
  const OnUpdateUser = async () => {
    setIsLoading(true);
    if (validateInputBfAU()) {
      let userJson = {
        userId: usersId,
        userName: userName,
        password: userPassword,
        first_name: firstName,
        last_name: lastName,
        userEmail: userEmail,
        phone_number: phoneNumber,
        address_line1: addressLineOne,
        address_line2: addressLineTwo,
        city: city,
        u_state: uState,
        is_active: isActive,
        zipcode: zipcode,
        country: country,
        supervisor_id: superviserId,
        location_id: locationId,
        dept_id: departmentId,
        role_id: roleIds,
      };
      validateToken();
      await putCrudApi(`users/${userJson.userId}`, userJson);
      displayUpdatedMessage();
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {location?.state?.userId &&
          location?.state?.userId !== null &&
          location?.state?.userId !== "" ? (
            <div className="user-add-update-btn">
              <button className="Modal-btn" onClick={OnUpdateUser}>
                Update
              </button>
            </div>
          ) : (
            <div className="user-add-update-btn">
              <button className="Modal-btn" onClick={OnAddUser}>
                Add
              </button>
            </div>
          )}
          <div className="modalFlexDiv-User">
            <div>
              <div className="Modal-Subheading">User Details</div>
              <div>
                <div className="div-display">
                  <label className="label-class">
                    First Name
                    <i className="fa fa-asterisk aster-risk-Icon" />
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setfNameError(
                        !checkNotNull(e.target.value) ||
                          !isAlpha(e.target.value)
                      );
                    }}
                    placeholder="First Name"
                    className="textbox-input_1"
                    value={firstName}
                  />
                  {fNameError && <Required message={"*required"} />}
                </div>
                <div className="div-display">
                  <label className="label-class">Last Name</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    placeholder="Last Name"
                    className="textbox-input_1"
                    value={lastName}
                  />
                </div>
                <div className="div-display">
                  <label className="label-class">
                    User Email
                    <i className="fa fa-asterisk aster-risk-Icon" />
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                      setfEmailError(
                        !checkNotNull(e.target.value) ||
                          !isEmail(e.target.value)
                      );
                    }}
                    placeholder="User Email"
                    className="textbox-input_1"
                    value={userEmail}
                  />
                  {emailError && <Required message={"*required"} />}
                </div>
                <div className="div-display">
                  <label className="label-class">
                    User Name
                    <i className="fa fa-asterisk aster-risk-Icon" />
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setUserName(e.target.value);
                      setUserNameError(!checkNotNull(e.target.value));
                    }}
                    disabled={disUserName}
                    placeholder="User Name"
                    className="textbox-input_1"
                    value={userName}
                  />
                  {userNameError && <Required message={"*required"} />}
                </div>
                <div className="div-display">
                  <label className="label-class">
                    Password
                    <i className="fa fa-asterisk aster-risk-Icon" />
                  </label>
                  <div className="InputTooltip">
                    {/* <span class="InputTooltiptext">
                  Password must contain capital letter, small letter, special
                  charector, number and minimum of 8 charectors
                </span> */}
                    <input
                      type="password"
                      onChange={(e) => {
                        setUserPassword(e.target.value);
                        setfPasswordError(!checkNotNull(e.target.value));
                      }}
                      placeholder="Password"
                      className="textbox-input_1"
                      value={userPassword}
                    />
                    <PasswordChecklist
                      rules={Object.keys(passwordProperties)}
                      minLength={passwordProperties["minLength"]?.val || 0}
                      maxLength={passwordProperties["maxLength"]?.val || 0}
                      value={userPassword}
                      iconSize={12}
                      validColor={"#655c8a"}
                      invalidColor={"#ca0000"}
                      onChange={(isValid) => {
                        setPasswordCheck(isValid);
                      }}
                      messages={messagePasswordJson() || {}}
                    />
                  </div>
                  {passwordError && <Required message={"*required"} />}
                </div>
                <div className="div-display">
                  <label className="label-class">Phone Number</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    placeholder="Phone Number"
                    className="textbox-input_1"
                    value={phoneNumber}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="Modal-Subheading">User Role</div>
              <div className="div-display">
                <label className="label-class">Supervisor</label>
                <Select
                  className="User-dropdown-input "
                  placeholder="Supervisor"
                  maxMenuHeight={120}
                  menuPlacement="bottom"
                  styles={selectStyles}
                  options={userData
                    ?.filter(
                      (item) =>
                        item.userId !== superviserId && item.is_active === 1
                    )
                    .map((e) => ({
                      label: e.first_name + " " + e.last_name,
                      value: e.userId,
                    }))}
                  value={userData
                    ?.filter((e) => e.userId === superviserId)
                    .map((e) => ({
                      label: e.first_name,
                      value: e.userId,
                    }))}
                  onChange={(e) => setSuperviserId(e.value)}
                />
              </div>
              <div className="div-display">
                <label className="label-class">Location</label>
                <Select
                  className="User-dropdown-input "
                  placeholder="Location"
                  maxMenuHeight={120}
                  menuPlacement="bottom"
                  styles={selectStyles}
                  options={locationData
                    ?.filter(
                      (item) =>
                        item?.location_id !== locationId && item.status_id === 1
                    )
                    .map((e) => ({
                      label: e.location_name,
                      value: e.location_id,
                    }))}
                  value={locationData
                    ?.filter((e) => e.location_id === locationId)
                    .map((e) => ({
                      label: e.location_name,
                      value: e.location_id,
                    }))}
                  onChange={(e) => setLocationId(e.value)}
                />
              </div>
              <div className="div-display">
                <label className="label-class">Department</label>
                <Select
                  className="User-dropdown-input"
                  placeholder="Department"
                  maxMenuHeight={120}
                  menuPlacement="bottom"
                  styles={selectStyles}
                  options={selectOptionsMap(
                    departmentData,
                    "dept_id",
                    "dept_name"
                  )}
                  value={departmentData
                    ?.filter((e) => e.dept_id === departmentId)
                    .map((e) => ({
                      label: e.dept_name,
                      value: e.dept_id,
                    }))}
                  onChange={(e) => setDepartmentId(e.value)}
                />
              </div>
              <div className="div-display">
                <label className="label-class">
                  Role
                  <i className="fa fa-asterisk aster-risk-Icon" />
                </label>

                <Select
                  className="User-dropdown-input "
                  placeholder="Role"
                  maxMenuHeight={200}
                  menuPlacement="bottom"
                  styles={selectStyles}
                  options={selectOptionsMap(rolesData, "roleId", "roleName")}
                  value={rolesData
                    ?.filter((e) => e.roleId === roleIds)
                    .map((e) => ({
                      label: e.roleName,
                      value: e.roleId,
                    }))}
                  onChange={(e) => {
                    setRoleIds(e.value);
                    setRoleError(!checkNotNull(e.value));
                  }}
                />
                {roleError && <Required message={"*required"} />}
              </div>
              <div className="div-display">
                <label className="label-class">User Status</label>
                <Select
                  className="User-dropdown-input "
                  placeholder="Status"
                  // maxMenuHeight={120}
                  menuPlacement="bottom"
                  styles={selectStyles}
                  options={ActiveStatus}
                  value={ActiveStatus?.filter((e) => e.value === isActive).map(
                    (e) => ({
                      label: e.label,
                      value: e.value,
                    })
                  )}
                  onChange={(e) => setIsActive(e.value)}
                />
              </div>
            </div>
            <div>
              <div className="Modal-Subheading">User Address</div>
              <div className="div-display">
                <label className="label-class">Address Line 1</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setAddressLineOne(e.target.value);
                  }}
                  placeholder="Address Line 1"
                  className="textbox-input_1"
                  value={addressLineOne}
                />
              </div>
              <div className="div-display">
                <label className="label-class">Address Line 2</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setAddressLineTwo(e.target.value);
                  }}
                  placeholder="Address Line 2"
                  className="textbox-input_1"
                  value={addressLineTwo}
                />
              </div>
              <div className="div-display">
                <label className="label-class">City</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  placeholder="City"
                  className="textbox-input_1"
                  value={city}
                />
              </div>
              <div className="div-display">
                <label className="label-class">State</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setUState(e.target.value);
                  }}
                  placeholder="State"
                  className="textbox-input_1"
                  value={uState}
                />
              </div>

              <div className="div-display">
                <label className="label-class">Country</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                  placeholder="Country"
                  className="textbox-input_1"
                  value={country}
                />
              </div>
              <div className="div-display">
                <label className="label-class">Zipcode</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setZipcode(e.target.value);
                  }}
                  placeholder="Zipcode"
                  className="textbox-input_1"
                  value={zipcode}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
