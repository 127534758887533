import React, { Component } from "react";
import logo from "../../assets/images/New_Logo.png";
import "./Footer.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
class Footer extends Component {
  render() {
    return (
      <footer className="Footer">
        <div className="footer-div">
          <a
            href="https://www.cipheracetech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyLoadImage
              effect="blur"
              className="container-logo"
              src={logo}
              alt="logo"
            />
          </a>
        </div>
        <div className="footer-text">
          Copyright © {new Date().getFullYear()}
        </div>
      </footer>
    );
  }
}

export default Footer;
