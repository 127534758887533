import React, { useState, useEffect } from "react";
import Select from "react-select";
import Checkbox from "react-simple-checkbox";
import {
  generateUUID,
  generateUUIDAmendment,
} from "../../config/utils/SlateUtilityFunctions";
import { toast } from "react-toastify";
import "./ConditionModal.css";

export default function ConditionModel(props) {
  const [isConditionOpen, setIsConditionOpen] = useState(false);

  const [selectedSingleOrMulti, setSelectedSingleOrMulti] = useState(null);
  const [selectedAndOr, setSelectedAndOr] = useState(null);

  const [selectedCondition, setSelectedCondition] = useState(null);
  const conditionArray = [{ value: "cusif", label: "IF" }];
  const [conditionJson, setconditionJson] = useState({});
  const [logicArr, setlogicArr] = useState([
    {
      firstvariable: null,
      operator: null,
      secondvariabletype: false,
      secondAttribute: null,
    },
  ]);
  const [variableJSON, setvariableJSON] = useState({});

  useEffect(() => {
    setIsConditionOpen(props.isConditionOpen);
  }, [props.isConditionOpen]);
  useEffect(() => {
    setconditionJson(props.conditionJson);
  }, [props.conditionJson]);
  useEffect(() => {
    let variables = props.finalJSON;
    let parties = props.partiesJson;
    let combinedVariables = { ...variables };
    Object.keys(parties).map((party) => {
      parties[party].map((singleParty) => {
        combinedVariables = {
          ...combinedVariables,
          ...singleParty,
        };
      });
    });

    // variables = { ...variables, ...props.finalJSON.nonTemplateVar };
    // delete variables["nonTemplateVar"];
    setvariableJSON(combinedVariables);
  }, [props.updateUI, props.finalJSON, props.partiesJson]);

  const singleMulti = [
    { label: "Single", value: "Single" },
    { label: "Multi", value: "Multi" },
  ];
  const andOr = [
    { label: "AND", value: "and" },
    { label: "OR", value: "or" },
  ];
  const getOperationOptions = (selectedVar, callFrom) => {
    let finJSON = variableJSON !== null ? variableJSON : {};
    if (
      finJSON[selectedVar] &&
      finJSON[selectedVar].validation.isNumeric &&
      finJSON[selectedVar].validation.isNumeric === true &&
      selectedVar !== "" &&
      selectedVar !== undefined
    ) {
      return [
        { label: "Greater Than", value: "gt" },
        { label: "Lesser Than", value: "lt" },
        { label: "Greater Than or Equal", value: "gte" },
        { label: "Lesser Than or Equal", value: "lte" },
        { label: "Not Equal", value: "ne" },
        { label: "Equal", value: "eq" },
      ];
    } else if (callFrom === 1) {
      return [
        { label: "Contains", value: "contains" },
        { label: "Not Contains", value: "notcontains" },
        { label: "Not Equal", value: "ne" },
        { label: "Equal", value: "eq" },
      ];
    } else {
      return [
        { label: "Contains", value: "contains" },
        { label: "Not Contains", value: "notcontains" },
        { label: "Not Equal", value: "ne" },
        { label: "Equal", value: "eq" },
      ];
    }
  };
  const displayValidMessage = () => {
    toast.error("Please Enter Mandatory Fields!", {
      className: "toaststyle",
    });
  };
  const displayMultiInvalidMessage = () => {
    toast.error("Please Enter Multi Condition", {
      className: "toaststyle",
    });
  };
  // const displayAddedMessage=()=>{
  //   toast.success(" Condtions Added Successfully!");
  // };

  // const saveSelection = () => {
  //   if (window.getSelection) {
  //     var sel = window.getSelection();
  //     if (sel.getRangeAt && sel.rangeCount) {
  //       return sel.getRangeAt(0);
  //     }
  //   } else if (document.selection && document.selection.createRange) {
  //     return document.selection.createRange();
  //   }
  //   return null;
  // };

  // const onConditionClick = () => {
  //   // let selection = window.getSelection().toString();
  //   // setSelectionCondition(selection);

  //   // if (selection.toString().length > 0) {
  //   //   setRange(saveSelection());
  //   setIsConditionOpen(true);
  //   // }
  // };

  const onConditionClose = () => {
    setSelectedAndOr(null);
    setSelectedSingleOrMulti(null);
    setSelectedCondition(null);
    setlogicArr([
      {
        firstvariable: null,
        operator: null,
        secondvariabletype: false,
        secondAttribute: null,
      },
    ]);
  };

  const onConditionOK = () => {
    let uuid;
    if (props.dataPath === "AmendmentVariables") {
      uuid = generateUUIDAmendment(conditionJson);
    } else {
      uuid = generateUUID(conditionJson);
    }
    let isValid = true;
    logicArr.forEach((element) => {
      if (
        element.firstvariable === null ||
        element.operator === null ||
        element.secondAttribute === null
      ) {
        isValid = false;
      }
    });
    if (
      logicArr.length === 0 ||
      (logicArr.length < 2 && selectedSingleOrMulti?.value === "Multi")
    ) {
      isValid = false;
    }
    if (
      selectedCondition !== null &&
      selectedSingleOrMulti !== null &&
      isValid === true
    ) {
      // setSpecialID(specialID + 1);
      if (selectedSingleOrMulti.value === "Single") {
        let newconditionJSON = {
          uuid: uuid,
          conditiontype: selectedCondition.value.toLowerCase(),
          multiplelogic: selectedSingleOrMulti.value,
          logic: logicArr,
          AndOr: selectedAndOr ? selectedAndOr.value : selectedAndOr,
        };

        let conditionArray = { ...conditionJson };
        conditionArray[Object.keys(conditionJson).length + 1] =
          newconditionJSON;
        props.setconditionJson(conditionArray);
        setSelectedAndOr(null);
        setSelectedSingleOrMulti(null);
        setSelectedCondition(null);
        setlogicArr([
          {
            firstvariable: null,
            operator: null,
            secondvariabletype: false,
            secondAttribute: null,
          },
        ]);
        setIsConditionOpen(false);
        props.setConditionOpen(false);
        props.conditionClick(uuid);
      } else if (
        selectedSingleOrMulti.value === "Multi" &&
        selectedAndOr !== null
      ) {
        let newconditionJSON = {
          uuid: uuid,
          conditiontype: selectedCondition.value.toLowerCase(),
          multiplelogic: selectedSingleOrMulti.value,
          logic: logicArr,
          AndOr: selectedAndOr ? selectedAndOr.value : selectedAndOr,
        };

        let conditionArray = { ...conditionJson };
        conditionArray[Object.keys(conditionJson).length + 1] =
          newconditionJSON;
        props.setconditionJson(conditionArray);
        setSelectedAndOr(null);
        setSelectedSingleOrMulti(null);
        setSelectedCondition(null);
        setlogicArr([
          {
            firstvariable: null,
            operator: null,
            secondvariabletype: false,
            secondAttribute: null,
          },
        ]);
        setIsConditionOpen(false);
        props.setConditionOpen(false);
        props.conditionClick(uuid);
      } else {
        // displayAddedMessage();
      }
    } else {
      if (selectedSingleOrMulti?.value === "Multi") {
        displayMultiInvalidMessage();
      } else {
        displayValidMessage();
      }
    }
  };

  const handleActiveClick = (idx) => {
    const logicAJ = [...logicArr];
    logicAJ[idx].secondvariabletype = !logicAJ[idx].secondvariabletype;
    logicAJ[idx].secondAttribute = "";
    setlogicArr(logicAJ);
  };

  const setSelectedAttributeOne = (value, idx) => {
    let dataPath;
    // if (
    //   props.finalJSON.nonTemplateVar &&
    //   Object.keys(props.finalJSON.nonTemplateVar).includes(value.label)
    // )
    //   dataPath = "nonTemplateVariables" + "." + value.label;
    // else
    dataPath = value.value;

    const logicAJ = [...logicArr];
    logicAJ[idx].firstvariable = dataPath;
    setlogicArr(logicAJ);
  };

  const setSelectedOperator = (e, idx) => {
    const logicAJ = [...logicArr];
    logicAJ[idx].operator = e.value;
    setlogicArr(logicAJ);
  };

  const setSelectedAttributeTwo = (value, idx) => {
    let dataPath;
    // if (logicArr[idx].secondvariabletype === true) {
    //   if (
    //     props.finalJSON.nonTemplateVar &&
    //     Object.keys(props.finalJSON.nonTemplateVar).includes(value.label)
    //   )
    //     dataPath = "nonTemplateVariables" + "." + value.label;
    //   else
    //    dataPath = value.value;
    // } else
    dataPath = value.value;

    const logicAJ = [...logicArr];
    logicAJ[idx].secondAttribute = dataPath;
    setlogicArr(logicAJ);
  };
  const OnAddLogicArray = (e) => {
    e.preventDefault();
    const logicAJ = [...logicArr];
    let logicA = {
      firstvariable: null,
      operator: null,
      secondvariabletype: false,
      secondAttribute: null,
    };
    logicAJ.push(logicA);
    setlogicArr(logicAJ);
  };
  const removeRow = (e, idx) => {
    const logicAJ = [...logicArr];
    logicAJ.splice(idx, 1);
    setlogicArr(logicAJ);
  };

  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "2px",
      display: "flex",
      borderRadius: "5px",
      margin: "5px 0 10px 0",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };

  // const SelectCondition = (selectedOption) => {
  //   setSelectedCondition(selectedOption);
  // };

  const initializeCond = (e) => {
    if (e.value === "Single") {
      let logicAJ = [...logicArr];
      if (logicAJ.length === 0 || logicAJ.length > 1) {
        let logicA = {
          firstvariable: null,
          operator: null,
          secondvariabletype: false,
          secondAttribute: null,
        };
        logicAJ = [logicA];
        setlogicArr(logicAJ);
      }
    }
  };
  return (
    <div>
      {isConditionOpen ? (
        <div className="modal">
          <div className="modal-condtion">
            <div className="header-modal">
              <h5 className="header-title">Conditions</h5>
            </div>
            <div className="div-padding">
              <label className="condition-Main-label">
                Select Condition{" "}
                <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <div>
                <Select
                  maxMenuHeight={120}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: " #E5E5E5",
                      primary: "#B2D4FD ",
                    },
                  })}
                  styles={selectStyles}
                  v-model="selectedCondition"
                  value={selectedCondition}
                  onChange={(e) => {
                    setSelectedCondition(e);
                  }}
                  options={conditionArray}
                  label="text"
                  // getOptionLabel={(option) => option.label}
                ></Select>
              </div>
              <div>
                <label className="condition-Main-label">
                  Logic <i className="fa fa-asterisk aster-risk-Icon"></i>
                </label>
                <div>
                  <Select
                    maxMenuHeight={120}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: " #E5E5E5",
                        primary: "#B2D4FD ",
                      },
                    })}
                    styles={selectStyles}
                    value={selectedSingleOrMulti}
                    // getOptionLabel={(option) => option.label}
                    onChange={(e) => {
                      setSelectedSingleOrMulti(e);
                      initializeCond(e);
                    }}
                    options={singleMulti}
                  ></Select>
                </div>
              </div>
              {selectedSingleOrMulti ? (
                selectedSingleOrMulti.value === "Multi" ? (
                  <div>
                    <label className="condition-Main-label">
                      Logic Operator{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>
                    <div>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: " #E5E5E5",
                            primary: "#B2D4FD ",
                          },
                        })}
                        maxMenuHeight={120}
                        styles={selectStyles}
                        value={selectedAndOr}
                        // getOptionLabel={(option) => option.label}
                        onChange={(e) => setSelectedAndOr(e)}
                        options={andOr}
                      ></Select>
                    </div>
                  </div>
                ) : null
              ) : null}
            </div>
            {logicArr.map((obj, idx) => {
              return (
                <div className="Condition-div">
                  <div>
                    {selectedSingleOrMulti &&
                    selectedSingleOrMulti.value === "Multi" ? (
                      <button
                        type="button"
                        className="Condtion-Close"
                        onClick={(e) => removeRow(obj, idx)}
                      >
                        <i className="fa fa-close" style={{ color: "white" }} />
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="condition-grid-row">
                    <div style={{ width: "100%", margin: "0 10px" }}>
                      <label className="condition-label">
                        Select intelli field{" "}
                        <i className="fa fa-asterisk aster-risk-Icon"></i>
                      </label>
                      <div>
                        <Select
                          key={idx + 1}
                          maxMenuHeight={120}
                          styles={selectStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: " #E5E5E5",
                              primary: "#B2D4FD ",
                            },
                          })}
                          options={Object.keys(variableJSON).map((e) => ({
                            label: e,
                            value:
                              variableJSON[e] && variableJSON[e].parentGroup
                                ? variableJSON[e].parentGroup.includes(
                                    "promiser"
                                  ) ||
                                  variableJSON[e].parentGroup.includes(
                                    "promisee"
                                  )
                                  ? variableJSON[e].parentGroup + "." + e
                                  : props.dataPath + "." + e
                                : props.dataPath + "." + e,
                          })).filter((a)=>a.value !== logicArr[idx].secondAttribute)}
                          onChange={(e) => {
                            setSelectedAttributeOne(e, idx);
                          }}
                          value={{
                            label: logicArr[idx].firstvariable
                              ? logicArr[idx].firstvariable.split(".")[1]
                              : logicArr[idx].firstvariable,
                            value: logicArr[idx].firstvariable,
                          }}
                        ></Select>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        margin: "0 10px",
                      }}
                    >
                      <label className="condition-label">
                        Operator{" "}
                        <i className="fa fa-asterisk aster-risk-Icon"></i>
                      </label>
                      <div>
                        <Select
                          maxMenuHeight={120}
                          styles={selectStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: " #E5E5E5",
                              primary: "#B2D4FD ",
                            },
                          })}
                          value={{
                            label:
                              logicArr[idx].operator === "gt"
                                ? "Greater Than"
                                : logicArr[idx].operator === "lt"
                                ? "Lesser Than"
                                : logicArr[idx].operator === "gte"
                                ? "Greater Than or Equal"
                                : logicArr[idx].operator === "lte"
                                ? "Lesser Than or Equal"
                                : logicArr[idx].operator === "eq"
                                ? "Equal"
                                : logicArr[idx].operator === "ne"
                                ? "Not Equal"
                                : logicArr[idx].operator === "contains"
                                ? "Contains"
                                : logicArr[idx].operator === "notcontains"
                                ? "Not Contains"
                                : null,
                            value: logicArr[idx].operator,
                          }}
                          //getOptionLabel={(option) => option.label}
                          onChange={(e) => setSelectedOperator(e, idx)}
                          options={
                            logicArr[idx].firstvariable !== null
                              ? getOperationOptions(
                                  logicArr[idx].firstvariable
                                    ? logicArr[idx].firstvariable.split(".")[1]
                                    : "",
                                  1
                                )
                              : []
                          }
                        ></Select>
                      </div>
                    </div>
                  </div>

                  <div className="condition-grid-row">
                    <div style={{ width: "100%", margin: "0 10px" }}>
                      <label className="condition-label">Select Variable</label>
                      <Checkbox
                        size={3}
                        borderThickness={1}
                        backAnimationDuration={10}
                        tickAnimationDuration={100}
                        color="#48336e"
                        className="ActiveCheckbox"
                        checked={logicArr[idx].secondvariabletype}
                        onChange={() => handleActiveClick(idx)}
                      ></Checkbox>
                    </div>
                    {logicArr[idx].secondvariabletype ? (
                      <div style={{ width: "100%", margin: "0,10px" }}>
                        <label className="condition-label">
                          Select intelli field{" "}
                          <i className="fa fa-asterisk aster-risk-Icon"></i>
                        </label>
                        <div>
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: " #E5E5E5",
                                primary: "#B2D4FD ",
                              },
                            })}
                            styles={selectStyles}
                            maxMenuHeight={120}
                            options={Object.keys(variableJSON)
                              .map((e) => ({
                                label: e,
                                value:
                                  variableJSON[e].parentGroup &&
                                  (variableJSON[e].parentGroup.includes(
                                    "promiser"
                                  ) ||
                                    variableJSON[e].parentGroup.includes(
                                      "promisee"
                                    ))
                                    ? variableJSON[e].parentGroup + "." + e
                                    : props.dataPath + "." + e,
                              }))
                              .filter(
                                (a) => a.value !== logicArr[idx].firstvariable
                              )}
                            onChange={(e) => {
                              setSelectedAttributeTwo(e, idx);
                            }}
                            value={{
                              label: logicArr[idx].secondAttribute
                                ? logicArr[idx].secondAttribute.split(".")[1]
                                : logicArr[idx].secondAttribute,
                              value: logicArr[idx].secondAttribute,
                            }}
                          ></Select>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          margin: " 0px 10px",
                          display: "grid",
                        }}
                      >
                        <label className="condition-label">
                          Enter Value{" "}
                          <i className="fa fa-asterisk aster-risk-Icon"></i>
                        </label>
                        <input
                          className="Textbox-condition"
                          value={logicArr[idx].secondAttribute}
                          onChange={(e) =>
                            setSelectedAttributeTwo(e.target, idx)
                          }
                          placeholder="Enter value"
                          type="text"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {selectedSingleOrMulti !== null &&
            selectedSingleOrMulti.value === "Multi" ? (
              <div className="OnArrAdd">
                <button
                  type="button"
                  className="onConditionAdd"
                  onClick={(e) => OnAddLogicArray(e)}
                >
                  Add
                </button>
              </div>
            ) : null}

            <div className="footer-modal">
              <button
                className="close-btn"
                type="button"
                onClick={() => {
                  setIsConditionOpen(false);
                  props.setConditionOpen(false);
                  onConditionClose();
                }}
              >
                Close
              </button>
              <button
                className="Modal-btn"
                type="button"
                onClick={() => onConditionOK()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

// **********commented code area

//   let conditionString =
//     "{{#" +
//     selectedCondition.value.toLowerCase() +
//     " " +
//     "'" +
//     selectedOperation.value +
//     "'" +
//     " " +
//     selectedVariable +
//     " " +
//     "'" +
//     selectedValue +
//     "'" +
//     "}}" +
//     //selection.toString() +
//     "{{/" +
//     selectedCondition.value.toLowerCase() +
//     "}}";
//TODO - insert appropriate content to draft js editor with the conditionString
//insertText(conditionString, specialID + 1);
