import React from "react";
import "./Loading.css";
import "../app/App.css";
import loading from "./loading.gif";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Loading() {
  return (
    <div className="loading-outer">
      <div className="loading-content">
        <LazyLoadImage src={loading} alt="Computer man" />
      </div>
    </div>
  );
}
