import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = {
  tokenData: "",
};

export const tokenSlice = createSlice({
  name: "tokenState",
  initialState: { value: initialStateValue },
  reducers: {
    setTokenData: (state, action) => {
      // let result = Login(action.payload, "auth/login");
      // let tokenresult = "";
      // result.then(function (res) {
      //   tokenresult = res.token;
      //    state.value.tokenData = tokenresult;
      // });
      state.value.tokenData = action.payload.token;
    },
  },
});

// function setStateval(state, res) {
//   state.value.tokenData = res;
// }
export const { setTokenData } = tokenSlice.actions;

export default tokenSlice.reducer;
