import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCrudApi } from "../../../webServiceCalls/webServiceCall";
import { validateToken } from "../../../Helpers/validateToken";
import { convertHierarchy } from "../../../Helpers/hierarchyConverter";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import SyfedTreeSelect from "syfed-tree-select";
import { toast } from "react-toastify";
import "./Amendment.css";
import Loading from "../../../Loading/Loading";
import { useNavigate } from "react-router-dom";

export default function Amendment(props) {
  const [AddModalOpen, setAddModalOpen] = useState(false);

  const [amendmentTemplateName, setAmendmentTemplateName] = useState("");
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const [tableJSON, setTableJSON] = useState([]);
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [CategoryTypeSelect, setCategoryTypeSelect] = useState([]);
  const [page, setPage] = useState(0);
  const [contractTypeSelected, setcontractTypeSelected] = useState(null);
  const [categoryTypeSelected, setcategoryTypeSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "amendmentTemplateId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  const contractTypeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );
  const amendmentTemplateData = useSelector(
    (state) => state.amendmentTemplateState.value.amendmentTemplateData
  );

  const onChangeOfContractTreeSelect = (value) => {
    setcontractTypeSelected(value);
  };
  const onChangeOfCategoryTreeSelect = (value) => {
    setcategoryTypeSelected(value);
  };
  const GetTemplateData = async () => {
    setIsLoading(true);
    const newData = generateJSONForTable(amendmentTemplateData);
    setTableJSON(newData);
    setIsLoading(false);
  };

  const generateJSONForTable = (templateData) => {
    let arrayOfJSON = [];
    if (templateData != null) {
      templateData.forEach((template) => {
        let newJSON = {};
        Object.keys(template).forEach((element) => {
          newJSON[element] = template[element];
        });

        categoryData.some((category) => {
          if (category.catogeryTypeId === template.catogeryTypeId) {
            newJSON.catogeryType = category.catogeryType;
            return true;
          }
          return false;
        });

        contractTypeData.some((contractType) => {
          if (contractType.contractTypeId === template.contractTypeId) {
            newJSON.contractType = contractType.contractType;
            return true;
          }
          return false;
        });
        arrayOfJSON.push(newJSON);
      });
    }

    return arrayOfJSON;
  };

  const OnAddTemplateOpen = (e) => {
    setAddModalOpen(true);
  };
  const OnAddTemplateClose = (e) => {
    setAddModalOpen(false);
    if (amendmentTemplateName.length > 0) {
      sessionStorage.removeItem("adraftId");
      sessionStorage.removeItem("averId");
      sessionStorage.removeItem("tempId");
      navigate("/Admin/Amendment/Edit", {
        state: {
          tableData: null,
          templateName: amendmentTemplateName,
          categorySelected: categoryTypeSelected,
          contractTypeSelected: contractTypeSelected,
          categoryType: CategoryTypeSelect,
          contractType: filterContractTypeSelect,

          publish_enable: false,
          templateData: null,
        },
      });
    } else {
      toast.error("Please Enter the mandatory fields");
    }
  };

  const SelectMappingForCategory = () => {
    if (categoryData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        categoryData.filter((element) => element.status === "Active"),
        "catogeryTypeId",
        "parentCatogeryTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"catogeryTypeId"/g,
        '"value"'
      );
      old = old.replace(/"catogeryType"/g, '"label"');
      var newArray = JSON.parse(old);
      setCategoryTypeSelect(newArray);
    } else setCategoryTypeSelect([]);
  };

  const SelectMappingForContractType = () => {
    if (contractTypeData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        contractTypeData.filter((element) => element.status === "Active"),
        "contractTypeId",
        "parentContractTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"contractTypeId"/g,
        '"value"'
      );
      old = old.replace(/"contractType"/g, '"label"');
      var newArray = JSON.parse(old);
      setContractTypeSelect([]);
      setFilterContractTypeSelect(newArray);
    } else {
      setContractTypeSelect([]);
      setFilterContractTypeSelect([]);
    }
  };
  function filterDataCategoryContractType() {
    let category = null;
    var newArray = [...filterContractTypeSelect];
    if (categoryTypeSelected !== null) {
      category = newArray?.filter((con) => {
        if (con.catogeryTypeId === categoryTypeSelected) return true;
        else return false;
      });
      setContractTypeSelect(category);
    }
  }
  useEffect(() => {
    SelectMappingForCategory();
    SelectMappingForContractType();
  }, [contractTypeData]);
  useEffect(() => {
    filterDataCategoryContractType();
  }, [categoryTypeSelected]);
  const generateColumns = () => {
    if (tableJSON && tableJSON.length > 0) {
      let columnsArray = [];
      let firstCol = {
        name: "EDIT",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                <button onClick={() => onTemplateEditorOpen(tableMeta)}>
                  <i className="fa fa-edit custom-iconformodal" />
                </button>
              </>
            );
          },
        },
      };
      columnsArray.push(firstCol);
      columnsArray.push({
        name: "amendmentTemplateId",
        label: "Amendment Template ID",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "amendmentTemplateName",
        label: "AMENDMENT TEMPLATE NAME",
      });
      columnsArray.push({
        name: "catogeryType",
        label: "CATEGORY TYPE",
      });
      columnsArray.push({
        name: "contractTypeId",
        label: "Contract Type Id",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "contractType",
        label: "CONTRACT TYPE",
      });
      columnsArray.push({
        name: "catogeryTypeId",
        label: "Catogery Type Id",
        options: {
          display: false,
        },
      });

      columnsArray.push({
        name: "draftAmendmentTemplateId",
        label: "Draft Template ID",
        options: {
          display: false,
        },
      });

      columnsArray.push({
        name: "publishedAmendmentTemplateVersionId",
        label: "Template Version ID",
        options: {
          display: false,
        },
      });

      return columnsArray;
    }
    return [];
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  const onTemplateEditorOpen = async (tableMeta) => {
    setIsLoading(true);
    sessionStorage.removeItem("adraftId");
    sessionStorage.removeItem("averId");
    sessionStorage.removeItem("tempId");
    if (tableMeta.rowData[7])
      sessionStorage.setItem("adraftId", tableMeta.rowData[7]);
    if (tableMeta.rowData[8])
      sessionStorage.setItem("averId", tableMeta.rowData[8]);
    sessionStorage.setItem("tempId", tableMeta.rowData[1]);
    navigate("/Admin/Amendment/Edit", {
      state: {
        tableData: tableMeta.rowData,
        templateName: tableMeta.rowData[2],
        categorySelected: tableMeta.rowData[6],
        contractTypeSelected: tableMeta.rowData[4],
        categoryType: CategoryTypeSelect,
        contractType: filterContractTypeSelect,
        publish_enable: true,
        templateData: amendmentTemplateData.filter(
          (element) => element.amendmentTemplateId === tableMeta.rowData[1]
        )[0],
      },
    });
    setIsLoading(false);
  };

  useEffect(() => {
    GetTemplateData();
  }, []);
  const onCloseModal = () => {
    setAddModalOpen(false);
    setAmendmentTemplateName("");
    setcontractTypeSelected("");
    setcategoryTypeSelected("");
  };
  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={(e) => OnAddTemplateOpen(e)}
            >
              Add
            </button>
          </div>
          <div className="Table-body">
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  title={"Amendment Templates"}
                  data={tableJSON}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}

      {AddModalOpen ? (
        <div className="modal ">
          <div className="modal-content ">
            <div className="header-modal">
              <h5 className="header-title">Amendment</h5>
            </div>
            <div className="div-display">
              <label className="label-class">
                Amendment Template Name{" "}
                <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <input
                type="text"
                placeholder="Amendment Template Name"
                value={amendmentTemplateName}
                onChange={(e) => setAmendmentTemplateName(e.target.value)}
                className="textbox-input_1"
              />
            </div>
            <div className="div-display">
              <label className="label-class">Category Type</label>
              <SyfedTreeSelect
                style={treeSelectStyle}
                size="small"
                treeCheckedStrategy="all"
                dataSource={CategoryTypeSelect}
                onChange={onChangeOfCategoryTreeSelect}
                placeholder="Select Category Type"
                value={categoryTypeSelected}
              />
            </div>
            <div className="div-display">
              <label className="label-class">Contract Type</label>
              <SyfedTreeSelect
                style={treeSelectStyle}
                size="small"
                treeCheckedStrategy="all"
                dataSource={ContractTypeSelect}
                onChange={onChangeOfContractTreeSelect}
                placeholder="Select Contract Type"
                value={contractTypeSelected}
              />
            </div>

            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={onCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={OnAddTemplateClose}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
