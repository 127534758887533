import "../../editor/editor.css";
import React, { useEffect, useState } from "react";
import SyfedTreeSelect from "syfed-tree-select";
import { setamendmentTemplateData } from "../../../redux/Reducers/amendmentTemplateReducer";
import {
  postCrudApi,
  getCrudApi,
  putCrudApi,
  deleteCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { validateToken } from "../../../Helpers/validateToken";
import "./Amendment.css";
import { toast } from "react-toastify";
import { validateVariable } from "../../../Helpers/validation";
import { connect } from "react-redux";
import { RecoilRoot } from "recoil";
import PlateEditor from "../../../PlateEditor/PlateEditor";
import Loading from "../../../Loading/Loading";
import { useLocation } from "react-router";

function AmendmentEditor(props) {
  const [isMasterLoaded, setIsMasterLoaded] = useState(false);
  const [value, setValue] = useState(0);
  const [conditions, setConditions] = useState({});
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [CategoryTypeSelect, setCategoryTypeSelect] = useState([]);
  const [nonTemplateVariableArray, setNonTemplateVariableArray] = useState([]);
  const [contractTypeSelected, setContractTypeSelected] = useState(null);
  const [categoryTypeSelected, setCategoryTypeSelected] = useState(null);
  const [contractTemplateJsonData, setContractTemplateJsonData] =
    useState(null);
  const [contractVariableJsonData, setContractVariableJsonData] =
    useState(null);
  const [selectedtemplateData, setSelectedtemplateData] = useState(null);
  const [publish_enable, setPublish_enable] = useState(true);
  const [amendmentTemplateIdState, setAmendmentTemplateIdState] = useState("");
  const [draftTemplate_id, setDraftTemplate_id] = useState(0);
  const [masterLibrary, setMasterLibrary] = useState([]);
  const [clauseLibrary, setClauseLibrary] = useState([]);
  const [amendmentTemplateName, setAmendmentTemplateName] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const [validSignature, setValidSig] = useState(true);
  const [pageSize, setPageSize] = useState("A4");


  const plateRef = React.useRef();
  const location = useLocation();

  const onChangeOfContractTreeSelect = (value) => {
    setContractTypeSelected(value);
  };
  const onChangeOfCategoryTreeSelect = (value) => {
    setCategoryTypeSelected(value);
  };

  const displayPublishMessage = () => {
    if (!toast.isActive(1))
      toast.success("Published Successfully!", {
        className: "toaststyle",
      });
  };

  const displayDraftedMessage = () => {
    if (!toast.isActive(1))
      toast.success("Drafted Successfully!", {
        className: "toaststyle",
      });
  };
  const GetMasterLibrary = async () => {
    setIsLoading(true);
    let result, dataResult, clauseDataResult;
    validateToken();
    result = getCrudApi("library/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    validateToken();
    result = getCrudApi("clauses/all", {});
    await result.then(function (res) {
      clauseDataResult = res;
    });
    setMasterLibrary(dataResult);
    setClauseLibrary(clauseDataResult);
    setIsMasterLoaded(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (location.state) {
      (async () => {
        let result, dataResult;
        let templateJson_contract = null;
        let template_varjson = null;
        let conditions = {};

        const draftId = sessionStorage.getItem("adraftId");
        const verId = sessionStorage.getItem("averId");
        const tempId = sessionStorage.getItem("tempId");
        let publishEnb = false;
        if (draftId) {
          validateToken();
          result = getCrudApi("amendmentDraftTemplate/" + draftId, {});
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            publishEnb = true;
            setPageSize(dataResult?.draftAmendmentTemplate?.pageSize ? dataResult?.draftAmendmentTemplate?.pageSize : "A4")
            templateJson_contract = JSON.parse(
              dataResult.draftAmendmentTemplate.template
            );
            template_varjson = dataResult.draftAmendmentTemplateVariable.var;
            conditions = dataResult.draftAmendmentTemplateConditions
              ? dataResult.draftAmendmentTemplateConditions.condition
              : {};
          }
        } else if (verId) {
          validateToken();
          result = getCrudApi("amendmentTemplateVersion/" + verId, {});
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            setPageSize(dataResult?.amendmentTemplateVer?.pageSize ? dataResult?.amendmentTemplateVer?.pageSize : "A4")
            templateJson_contract = JSON.parse(
              dataResult.amendmentTemplateVer.template
            );
            template_varjson = dataResult.amendmentTemplateVerVariable.var;
            conditions = dataResult.amendmentTemplateVerConditions
              ? dataResult.amendmentTemplateVerConditions.condition
              : {};
            publishEnb = true;
          }
        }

        setContractTypeSelected(location.state.contractTypeSelected);
        setCategoryTypeSelected(location.state.categorySelected);
        setamendmentTemplateName(location.state.templateName);
        setContractTypeSelect([]);
        setCategoryTypeSelect(location.state.categoryType);
        // setSelectedtemplateData(location.state.templateData);
        setContractTemplateJsonData(templateJson_contract);
        setContractVariableJsonData(template_varjson);
        setPublish_enable(publishEnb);
        setConditions(conditions);
        setFilterContractTypeSelect(location.state.contractType);
        GetMasterLibrary();
        if (tempId) {
          await getTemplateDetailsForReduxUpdate(tempId);
        }
      })();
    }
    setIsLoading(false);
  }, []);

  const setamendmentTemplateName = (value) => {
    setAmendmentTemplateName(value);
  };

  const HandlePublishTemplate = async (obj) => {
    setIsLoading(true);
    if (
      amendmentTemplateName !== null &&
      amendmentTemplateName?.trim() !== ""
    ) {
      const serializedData =
        plateRef.current !== null
          ? plateRef.current.serailizedData()
          : contractTemplateJsonData;
      const conditionsloc =
        plateRef.current !== null
          ? plateRef.current.getConditions()
          : conditions;
      const validSig =
        plateRef.current !== null
          ? plateRef.current.getSigValid()
          : validSignature;
      const pageType = 
      plateRef.current !== null
      ? plateRef.current.getPageSize()
      : pageSize;
      setPageSize(pageType);
      setValidSig(validSig);
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsloc);

      const value = validateVariable(contractVariableJsonData);
      if (validSig === false) {
        toast.error("Remove unused signature blocks", {
          className: "toaststyle",
        });
      } else if (value !== null) {
        let valid = value[0];
        let variables = value[1];
        if (valid === false) {
          toast.error(variables + "variables are not configured", {
            className: "toaststyle",
          });
        } else {
          let template_json = {
            template: JSON.stringify(serializedData),
            pageSize:pageType
          };
          let var_json = {
            var: contractVariableJsonData,
          };
          let conditionJson = {
            condition: conditionsloc,
          };
          let templateVersionJson = {
            amendmentTemplateVer: template_json,
            amendmentTemplateVerVariable: var_json,
            ispublished: 1,
            versionStauts: 1,
            amendmentTemplateId: selectedtemplateData
              ? selectedtemplateData.amendmentTemplateId
              : amendmentTemplateIdState,
            amendmentTemplateVerConditions: conditionJson,
          };
          let result;
          validateToken();
          result = postCrudApi(
            "amendmentTemplateVersion/amendmentTemplateVersion",
            templateVersionJson
          );
          let dataResult;
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            setMessage(" Published Successfully  !");
            displayPublishMessage();
            sessionStorage.setItem(
              "averId",
              dataResult.amendmentTemplateVersionId
            );
            sessionStorage.removeItem("adraftId");
            let template_version_id = dataResult.amendmentTemplateVersionId;
            let amendmentTemplate = {
              publishedAmendmentTemplateVersionId: template_version_id,
              amendmentTemplateName: amendmentTemplateName,
              contractTypeId: contractTypeSelected,
              catogeryTypeId: categoryTypeSelected,
              draftAmendmentTemplateId: null,
            };
            validateToken();

            result = putCrudApi(
              "amendmentTemplate/" + templateVersionJson.amendmentTemplateId,
              amendmentTemplate
            );
            await result.then(function (res) {
              dataResult = res;
            });
            getTemplateDetailsForReduxUpdate(dataResult.amendmentTemplateId);

            setSelectedtemplateData(dataResult);

            const path2 =
              selectedtemplateData !== null
                ? selectedtemplateData.draftAmendmentTemplateId !== null
                  ? selectedtemplateData.draftAmendmentTemplateId
                  : draftTemplate_id !== 0
                  ? draftTemplate_id
                  : null
                : draftTemplate_id !== 0
                ? draftTemplate_id
                : null;
            if (path2 != null) {
              let path = "amendmentDraftTemplate/" + path2;
              validateToken();
              await deleteCrudApi(path);
            }
          }
        }
      }
    } else {
      toast.error("Please enter a valid Template Name");
    }
    setIsLoading(false);
  };

  const HandleSaveTemplate = async (obj) => {
    setIsLoading(true);
    if (
      amendmentTemplateName?.trim() !== "" &&
      amendmentTemplateName !== null
    ) {
      const serializedData =
        plateRef.current !== null
          ? plateRef.current.serailizedData()
          : contractTemplateJsonData;
      const conditionsloc =
        plateRef.current !== null
          ? plateRef.current.getConditions()
          : conditions;
          const pageType = 
          plateRef.current !== null
          ? plateRef.current.getPageSize()
          : pageSize;
          setPageSize(pageType);
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsloc);

      const value = validateVariable(contractVariableJsonData);
      if (value !== null) {
        let valid = value[0];
        let variables = value[1];
        if (valid === false) {
          toast.error(variables + "variables are not configured", {
            className: "toaststyle",
          });
        } else {
          let template_json = {
            template: JSON.stringify(serializedData),
            pageSize: pageType
          };
          let var_json = {
            var: contractVariableJsonData,
          };
          let conditionJson = {
            condition: conditionsloc,
          };
          let templateJson = {
            draftAmendmentTemplate: template_json,
            draftAmendmentTemplateVariable: var_json,
            draftAmendmentTemplateConditions: conditionJson,
          };

          let result;
          if (
            selectedtemplateData === null ||
            selectedtemplateData.draftAmendmentTemplateId === null
          ) {
            validateToken();
            result = postCrudApi(
              "amendmentDraftTemplate/amendmentDraftTemplate",
              templateJson
            );
          } else {
            validateToken();
            result = putCrudApi(
              "amendmentDraftTemplate/" +
                selectedtemplateData.draftAmendmentTemplateId,
              templateJson
            );
          }
          let dataResult;
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            setMessage(" Drafted Successfully !");
            displayDraftedMessage();
            sessionStorage.setItem(
              "adraftId",
              dataResult.draftAmendmentTemplateId
            );
            sessionStorage.removeItem("averId");
            let draft_id = dataResult.draftAmendmentTemplateId;
            setDraftTemplate_id(draft_id);
            let amendmentTemplate = {
              amendmentTemplateName: amendmentTemplateName,
              contractTypeId: contractTypeSelected,
              catogeryTypeId: categoryTypeSelected,
              draftAmendmentTemplateId: draft_id,
            };
            let contractTemplateId;

            if (selectedtemplateData == null) {
              validateToken();
              result = postCrudApi(
                "amendmentTemplate/amendmentTemplate",
                amendmentTemplate
              );
              await result.then(function (res) {
                contractTemplateId = res;
              });
              if (contractTemplateId) {
                setAmendmentTemplateIdState(
                  contractTemplateId.amendmentTemplateId
                );
              }
              setPublish_enable(true);
            } else {
              validateToken();
              result = putCrudApi(
                "amendmentTemplate/" + selectedtemplateData.amendmentTemplateId,
                amendmentTemplate
              );
              await result.then(function (res) {
                contractTemplateId = res;
              });
            }

            await getTemplateDetailsForReduxUpdate(
              contractTemplateId.amendmentTemplateId
            );
            setSelectedtemplateData(contractTemplateId);
          }
        }
      }
    } else {
      toast.error("Please enter a valid Template Name");
    }
    setIsLoading(false);
  };

  const getTemplateDetailsForReduxUpdate = async (templateID) => {
    setIsLoading(true);
    sessionStorage.setItem("tempId", templateID);
    let templateData;
    let result;
    validateToken();
    result = getCrudApi("amendmentTemplate/all", {});
    await result.then(function (res) {
      templateData = res;
    });
    props.setamendmentTemplateData(templateData);

    setSelectedtemplateData(
      templateData.filter(
        (element) => element.amendmentTemplateId === parseInt(templateID, 10)
      )[0]
    );

    // this.props.history.push("/Admin/Amendment");
    setIsLoading(false);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  //14.27  46%
  const handleTabChange = (newValue) => {
    setIsLoading(true);
    if (plateRef.current !== null) {
      const serializedData = plateRef.current.serailizedData();
      const conditionsloc = plateRef.current.getConditions();
      const validSig = plateRef.current.getSigValid();
      const pageType = plateRef.current.getPageSize();
      setPageSize(pageType);
      setValidSig(validSig);
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsloc);
    }
    setValue(newValue);
    setIsLoading(false);
  };

  const treeselectStyles = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
    margin: "10px 0 0px 0",
  };

  function filterDataCategoryContractType() {
    let category = null;
    var newArray = filterContractTypeSelect;
    if (categoryTypeSelected !== null) {
      category = newArray?.filter((con) => {
        if (con.catogeryTypeId === categoryTypeSelected) return true;
        else return false;
      });
      setContractTypeSelect(category);
    }
  }

  useEffect(() => {
    filterDataCategoryContractType();
  }, [categoryTypeSelected]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div>
            <div className="headerHolder">
              <div className="Tab-nav-editor ">
                <div
                  className={value === 0 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(0)}
                >
                  Editor
                </div>
                <div
                  className={value === 1 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(1)}
                >
                  Template Settings
                </div>
              </div>
              <div className="formHeader">
                <div className="formheader85">
                  <label className="editor-header-label">
                    {amendmentTemplateName !== undefined
                      ? amendmentTemplateName
                      : "NAME"}
                  </label>
                </div>
                <div className="formheader20">
                  <button
                    className="contracts-btn"
                    onClick={() => HandleSaveTemplate()}
                  >
                    Draft
                  </button>
                  <button
                    className="contracts-btn"
                    onClick={() => HandlePublishTemplate()}
                    disabled={!publish_enable}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
            {value === 1 ? (
              <div className="settingTab">
                <div className="container-grid">
                  <div className="formrow">
                    <div className="form-columns ">
                      <label className="setting-label">
                        Amendment Template
                      </label>
                    </div>
                    <div className="form-columns ">
                      <input
                        placeholder="  Amendment Template Name"
                        type="text"
                        className="text-form-editor"
                        value={amendmentTemplateName}
                        onChange={(e) =>
                          setamendmentTemplateName(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="formrow">
                    <div className="form-columns ">
                      <label className="setting-label">Category Type</label>
                    </div>
                    <div className="form-columns ">
                      <SyfedTreeSelect
                        style={treeselectStyles}
                        id="treeselector"
                        size="small"
                        treeCheckedStrategy="all"
                        dataSource={CategoryTypeSelect}
                        onChange={onChangeOfCategoryTreeSelect}
                        placeholder="Select Category Type"
                        value={categoryTypeSelected}
                      />
                    </div>
                  </div>
                  <div className="formrow">
                    <div className="form-columns ">
                      <label className="setting-label">Contract Type</label>
                    </div>
                    <div className="form-columns ">
                      <SyfedTreeSelect
                        style={treeselectStyles}
                        size="small"
                        treeCheckedStrategy="all"
                        dataSource={ContractTypeSelect}
                        onChange={onChangeOfContractTreeSelect}
                        placeholder="Select Contract Type"
                        value={contractTypeSelected}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : value === 0 ? (
              <div>
                {isMasterLoaded ? (
                  <RecoilRoot>
                    <PlateEditor
                      id={"amendmentEditor"}
                      ref={plateRef}
                      parentGroup="Amendment"
                      isNegotiationDisabled={true}
                      isVariableDisabled={false}
                      isPartiesDisabled={true}
                      isConditionDisabled={false}
                      isClauseDisabled={true}
                      canAddComments={false}
                      isConditionToggleDisabled={false}
                      isCommentToggleDisabled={true}
                      //  templateTypeSelected={templateTypeSelected} // for templatetype on hold
                      nonTemplateVariableArray={nonTemplateVariableArray}
                      setNonTemplateVariableArray={(nonTempVarArr) => {
                        setNonTemplateVariableArray(nonTempVarArr);
                      }}
                      finalJSON={
                        contractVariableJsonData
                          ? contractVariableJsonData
                          : null
                      }
                      setFinalJSON={(finJSON) => {
                        setContractVariableJsonData(finJSON);
                      }}
                      setContractTemplateJsonData={(val) => {
                        setContractTemplateJsonData(val);
                      }}
                      contractTemplateJsonData={
                        contractTemplateJsonData
                          ? contractTemplateJsonData
                          : null
                      }
                      currentUser="user"
                      clauseLibrary={clauseLibrary}
                      masterLibrary={masterLibrary}
                      isEditable={true}
                      conditions={conditions}
                      setConditions={(val) => {
                        setConditions(val);
                      }}
                      // commentJSON={commentJSON}
                      // setCommentsJson={(coms) => {
                      //
                      //   this.setState({
                      //     commentJSON: coms,
                      //   });
                      // }}
                      serializeCall={"serializeTemplate"}
                      allowNontemp={false}
                      allowSignature={false}
                      dataPath="AmendmentVariables"
                      conditiondataPath="AmendmentConditions"
                      tabForVarId="amendmentVarTab"
                      pageSize={pageSize}
                    />
                  </RecoilRoot>
                ) : (
                  <Loading />
                )}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
export default connect(null, { setamendmentTemplateData })(AmendmentEditor);
