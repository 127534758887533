import escapeHtml from "escape-html";
import { Text } from "slate";
import { getCommentThreadIDFromMark } from "../../config/utils/EditorCommentUtils";

export const serialize = (node, nextNode) => {
  let style = '';
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    // if(string === ""){
    //   string = `<span data-type="non-breakingspace"><br></span>`;
    // }
    if(string.includes("  "))
    {
      const regexPattern = /[ ]{2,}/g;
      const replacedText = string.replace(regexPattern, (match) => {
        return `<span style="white-space: pre;" data-type="whitespace">${match}</span>`
      });
      string = replacedText;
    }
    if(string.includes("\t"))
    {
      const regexPattern = /[\t]{1,}/g;
      const replacedText = string.replace(regexPattern, (match) => {
        return `<span style="white-space: pre;" data-type="whitespace">${match}</span>`
      });
      string = replacedText;
    }
    if (node.variable) {
      string = `<mark data-type=${node.variable["data-type"]} uuid=${node.variable["data-variable-id"]} data-path=${node.variable["data-path"]} currency-in-words=${node.variable["currency-in-words"]} date-in-words=${node.variable["date-in-words"]} style={{ backgroundColor: "#fcc935" }}>${string}</mark>`;
    }
    if (node.signature) {
      string = `<mark data-type="signature" uuid=${node.signature["id"]} data-label="${node.signature["label"]}" >${string}</mark>`;
    }
    let iscomment;
    for (let key in node) {
      iscomment = key.startsWith("commentThread_");
      if (iscomment) {
        string = `<mark data-type="comments" uuid=${getCommentThreadIDFromMark(
          key
        )} style={{ backgroundColor: "#5eb1e0" }}>${string}</mark>`;
      }
    }
    if (node.condition) {
      string = `<mark data-type=${node.condition["data-type"]} uuid=${node.condition["data-condition-id"]} data-path=${node.condition["data-path"]} style={{ backgroundColor: "black" }}>${string}</mark>`;
    }

    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.superscript) {
      string = `<sup>${string}</sup>`;
    }
    if (node.subscript) {
      string = `<sub>${string}</sub>`;
    }
    if (node.color||node.backgroundColor||node.fontSize||node.fontFamily||node.lineHeight) {
      let spanstyle="";
      if (node.color) {
        spanstyle = spanstyle+`color: ${node.color};`;
      }
      if (node.backgroundColor) {
        spanstyle = spanstyle+`background-color: ${node.backgroundColor};`;
      }
      if (node.fontSize) {
        spanstyle = spanstyle+`font-size:  ${node.fontSize};`;
      }
      if (node.fontFamily) {
        spanstyle = spanstyle+`font-family: ${node.fontFamily};`;
      }
      if (node.lineHeight) {
        spanstyle = spanstyle+`line-height: ${node.lineHeight};`;
      }
      string = `<span style="${spanstyle}">${string}</span>`;
    }
    // if (node.color) {
    //   string = `<span style="color: ${node.color}" data-type="color">${string}</span>`;
    // }
    // if (node.backgroundColor) {
    //   string = `<span style="background-color: ${node.backgroundColor}" data-type="backgroundColor">${string}</span>`;
    // }
    // if (node.fontSize) {
    //   string = `<span style="font-size: ${node.fontSize}" data-type="fontSize">${string}</span>`;
    // }
    // if (node.fontFamily) {
    //   string = `<span style='font-family: ${node.fontFamily}' data-type="fontFamily">${string}</span>`;
    // }
    // if (node.lineHeight) {
    //   string = `<span style="line-height: ${node.lineHeight}" data-type="lineHeight">${string}</span>`;
    // }
    return string;
  }
  else {
    if (node.fontSize) {
      style = style + `font-size: ${node.fontSize};`;
    }
    if (node.fontFamily) {
      style = style + `font-family: ${node.fontFamily};`;
    }
  }
  const children = node.children
    .map((n, i) =>
      i < node.children.length - 1
        ? serialize(n, node.children[i + 1])
        : serialize(n, null)
    )
    .join("");

  
  if (node.indent) {
    style = style + `margin-left:${node.indent * 36}pt;`;
  }
  if (node.rightindent) {
    style = style + `margin-right:${node.rightindent * 36}pt;`;
  }
  if (node.lineHeight) {
    style = style + `line-height:${node.lineHeight};`;
  }
  if (node.align) {
    style = style + `text-align:${node.align};`;
  }
  if (node.listStyleType) {
    style = style + `list-style-type:${node.listStyleType};`;
  }
  if (node.listStyleType) {
    if (node.listStyleType === "disc") {
      if (node.listStart) {
        let list =
          nextNode?.indent < node?.indent || !nextNode?.indent ? `</ul>` : ``;
        list =
          `<li style="list-style-type:${node.listStyleType};" data-indent=${node.indent}>${children}</li>` +
          list;
        return list;
      } else {
        let list =
          nextNode?.indent < node?.indent || !nextNode?.indent ? `</ul>` : ``;
        list =
          `<ul style="list-style-type:${node.listStyleType};" data-indent=${node.indent}><li style="list-style-type:${node.listStyleType};">${children}</li>` +
          list;
        return list;
      }
    } else if (node.listStyleType === "decimal") {
      if (node.listStart) {
        let list =
          nextNode?.indent < node?.indent || !nextNode?.indent ? `</ol>` : ``;
        list =
          `<li style="list-style-type:${node.listStyleType};" data-indent=${node.indent}>${children}</li>` +
          list;
        return list;
      } else {
        let list =
          nextNode?.indent < node?.indent || !nextNode?.indent ? `</ol>` : ``;
        list =
          `<ol style="list-style-type:${node.listStyleType};" data-indent=${node.indent}><li style="list-style-type:${node.listStyleType};">${children}</li>` +
          list;
        return list;
      }
    }
  }
  if (node.listStart) {
    style = style + `list-start:${node.listStart};`;
  }
  switch (node.type) {
    case "blockquote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "p":
      if (style === "") {
        return `<p style="padding-bottom:0px;padding-top:0px;margin-bottom:0px;margin-top:0px;">${children}</p>`;
      }
      //  else if (node.listStyleType) {
      //   if (node.listStyleType === "disc") {
      //     return `<p style="${style}"><ul><li>${children}</li></ul></p>`;
      //   } else if (node.listStyleType === "decimal") {
      //     return `<p style="${style}"><ol><li>${children}</li></ol></p>`;
      //   }
      // }
      else {
        return `<p style="${style}padding-bottom:0px;padding-top:0px;margin-bottom:0px;margin-top:0px;">${children}</p>`;
      }
    case "table":
      return `<table>${children}</table>`;
    case "tr":
      return `<tr>${children}</tr>`;
    case "td":
      return `<td>${children}</td>`;
    case "link":
      if (style === "") {
        return `<a href="${escapeHtml(node.url)}">${children}</a>`;
      } else {
        return `<a href="${escapeHtml(
          node.url
        )}" style="${style}">${children}</a>`;
      }
    case "clause":
      let conditionVar = node?.children[0]?.children ? node?.children[0]?.children[0]?.condition : null;
      if (conditionVar) {
        return `<clause data-type="clause" clauseId=${node.data.clause.clauseID} style="color:#B74878" cond-data-type ="${conditionVar["data-type"]}" cond-uuid="${conditionVar["data-condition-id"]}" cond-data-path="${conditionVar["data-path"]}" ></clause>`;
      }
      return `<clause data-type="clause" clauseId=${node.data.clause.clauseID} style="color:#B74878"></clause>`;
    case "h1":
      return `<h1 style="${style}">${children}</h1>`;
    case "h2":
      return `<h2 style="${style}">${children}</h2>`;
    case "h3":
      return `<h3 style="${style}">${children}</h3>`;
    case "h4":
      return `<h4 style="${style}">${children}</h4>`;
    case "h5":
      return `<h5 style="${style}">${children}</h5>`;
    case "h6":
      return `<h6 style="${style}">${children}</h6>`;
    case "ol":
      return `<ol style="${style}">${children}</ol>`;
    case "ul":
      return `<ul style="${style}">${children}</ul>`;
    case "li":
      return `<li style="${style}">${children}</li>`;
    case "lic":
      return `<lic style="${style}">${children}</lic>`;
    default:
      return `${children}`;
  }
};
export const serializeContract = (node) => {
  let style = "";

  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    // if(string === ""){
    //   string = `<span data-type="non-breakingspace">&nbsp;</span>`;
    // }
    if(string.includes("  "))
    {
      const regexPattern = /[ ]{2,}/g;
      const replacedText = string.replace(regexPattern, (match) => {
        return `<span style="white-space: pre;" data-type="whitespace">${match}</span>`
      });
      string = replacedText;
    }
    if(string.includes("\t"))
    {
      const regexPattern = /[\t]{1,}/g;
      const replacedText = string.replace(regexPattern, (match) => {
        return `<span style="white-space: pre;" data-type="whitespace">${match}</span>`
      });
      string = replacedText;
    }
    if (node.variable) {
      string = `<mark data-type=${node.variable["data-type"]} uuid=${node.variable["data-variable-id"]} data-path=${node.variable["data-path"]} currency-in-words=${node.variable["currency-in-words"]} date-in-words=${node.variable["date-in-words"]} style={{ backgroundColor: "#fcc935" }}>${string}</mark>`;
    }
    if (node.signature) {
      string = `<mark data-type="signature" uuid=${node.signature["id"]} data-label="${node.signature["label"]}">${string}</mark>`;
    }
    let iscomment;
    for (let key in node) {
      iscomment = key.startsWith("commentThread_");
      if (iscomment) {
        string = `<mark data-type="comments" uuid=${getCommentThreadIDFromMark(
          key
        )} style={{ backgroundColor: "#5eb1e0" }}>${string}</mark>`;
      }
    }
    if (node.condition) {
      string = `<mark data-type=${node.condition["data-type"]} uuid=${node.condition["data-condition-id"]} data-path=${node.condition["data-path"]} style={{ backgroundColor: "black" }}>${string}</mark>`;
    }
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.superscript) {
      string = `<sup>${string}</sup>`;
    }
    if (node.subscript) {
      string = `<sub>${string}</sub>`;
    }
    if (node.color||node.backgroundColor||node.fontSize||node.fontFamily||node.lineHeight) {
      let spanstyle="";
      if (node.color) {
        spanstyle = spanstyle+`color: ${node.color};`;
      }
      if (node.backgroundColor) {
        spanstyle = spanstyle+`background-color: ${node.backgroundColor};`;
      }
      if (node.fontSize) {
        spanstyle = spanstyle+`font-size:  ${node.fontSize};`;
      }
      if (node.fontFamily) {
        spanstyle = spanstyle+`font-family: ${node.fontFamily};`;
      }
      if (node.lineHeight) {
        spanstyle = spanstyle+`line-height: ${node.lineHeight};`;
      }
      string = `<span style="${spanstyle}">${string}</span>`;
    }
    // if (node.color) {
    //   string = `<span style="color: ${node.color}" data-type="color">${string}</span>`;
    // }
    // if (node.backgroundColor) {
    //   string = `<span style="background-color: ${node.backgroundColor}" data-type="backgroundColor">${string}</span>`;
    // }
    // if (node.fontSize) {
    //   string = `<span style="font-size:  ${node.fontSize}" data-type="fontSize">${string}</span>`;
    // }
    // if (node.fontFamily) {
    //   string = `<span style="font-family: ${node.fontFamily}" data-type="fontFamily">${string}</span>`;
    // }
    // if (node.lineHeight) {
    //   string = `<span style="line-height: ${node.lineHeight}" data-type="lineHeight">${string}</span>`;
    // }
    return string;
  }
  else {
    if (node.fontSize) {
      style = style + `font-size: ${node.fontSize};`;
    }
    if (node.fontFamily) {
      style = style + `font-family: ${node.fontFamily};`;
    }
  }

  const children = node.children.map((n) => serializeContract(n)).join("");

  if (node.indent) {
    style = style + `margin-left:${node.indent * 36}pt;`;
  }
  if (node.rightindent) {
    style = style + `margin-right:${node.rightindent * 36}pt;`;
  }
  if (node.lineHeight) {
    style = style + `line-height:${node.lineHeight};`;
  }
  if (node.align) {
    style = style + `text-align:${node.align};`;
  }
  if (node.listStyleType) {
    style = style + `list-style-type:${node.listStyleType};`;
  }
  if (node.listStyleType) {
    if (node.listStyleType === "disc") {
      return `<ul style="" data-indent=${node.indent}><li>${children}</li></ul>`;
    } else if (node.listStyleType === "decimal") {
      return `<ol style="" data-indent=${node.indent}><li>${children}</li></ol>`;
    }
  }
  switch (node.type) {
    case "blockquote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "p":
      if (style === "") {
        return `<p style="padding-bottom:0px;padding-top:0px;margin-bottom:0px;margin-top:0px;">${children}</p>`;
      }
      // else if (node.listStyleType) {
      //   if (node.listStyleType === "disc") {
      //     return `<p style="${style}"><ul><li>${children}</li></ul></p>`;
      //   } else if (node.listStyleType === "decimal") {
      //     return `<p style="${style}"><ol><li>${children}</li></ol></p>`;
      //   }
      // }
      else {
        return `<p style="${style}padding-bottom:0px;padding-top:0px;margin-bottom:0px;margin-top:0px;">${children}</p>`;
      }
    case "table":
      return `<table>${children}</table>`;
    case "tr":
      return `<tr>${children}</tr>`;
    case "td":
      return `<td>${children}</td>`;
    case "link":
      if (style === "") {
        return `<a href="${escapeHtml(node.url)}">${children}</a>`;
      } else {
        return `<a href="${escapeHtml(
          node.url
        )}" style="${style}">${children}</a>`;
      }
    case "clause":
      return `<clause data-type="clause" clauseId=${node.data.clause.clauseID} style={{ color: "#B74878" }}>${children}</clause>`;
    case "h1":
      return `<h1 style="${style}">${children}</h1>`;
    case "h2":
      return `<h2 style="${style}">${children}</h2>`;
    case "h3":
      return `<h3 style="${style}">${children}</h3>`;
    case "h4":
      return `<h4 style="${style}">${children}</h4>`;
    case "h5":
      return `<h5 style="${style}">${children}</h5>`;
    case "h6":
      return `<h6 style="${style}">${children}</h6>`;
      case "ol":
        return `<ol style="${style}">${children}</ol>`;
      case "ul":
        return `<ul style="${style}">${children}</ul>`;
      case "li":
        return `<li style="${style}">${children}</li>`;
      case "lic":
        return `<lic style="${style}">${children}</lic>`;
    default:
      return `${children}`;
  }
};
