import React, { useState, useEffect } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Select from "react-select";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import "./RoleManagement.css";
import {
  getCrudApi,
  postCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import Loading from "../../../Loading/Loading";

export default function Rolemanegments() {
  const [tableData, setTableData] = useState([]);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [RolesForSelection, setRolesForSelection] = useState([]);
  const [roleDescrip, setRoleDescrip] = useState("");
  const [NewOrUpdate, setNewOrUpdate] = useState("");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [updateUi, setUpdateUi] = useState(false);
  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button
                onClick={() =>
                  tableMeta !== null ? onTemplateEditorOpen(tableMeta) : null
                }
              >
                <i className="fa fa-edit custom-iconformodal" />
              </button>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);

    columnsArray.push({
      name: "roleName",
      label: "NAME",
    });
    columnsArray.push({
      name: "roleDescription",
      label: "DESCRIPTION",
    });
    columnsArray.push({
      name: "permissionArray",
      label: "Permission",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "roleId",
      label: "Role ID",
      options: {
        display: false,
      },
    });

    return columnsArray;
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const OnAddRoleOpen = (e) => {
    setNewOrUpdate("New");
    setAddModalOpen(true);
  };

  const onTemplateEditorOpen = (tableMeta) => {
    setAddModalOpen(true);
    setNewOrUpdate("Update");
    setRoleName(tableMeta.rowData[1]);
    setRoleDescrip(tableMeta.rowData[2]);
  };

  const handleNameChange = (e) => {
    setRoleName(e.target.value);
  };
  const handleDescrip = (e) => {
    setRoleDescrip(e.target.value);
  };

  const onCloseClick = () => {
    setAddModalOpen(false);
    setRoleName("");
    setRoleDescrip("");
    setSelectedRoles("");
  };

  const OnAddNodeClose = async () => {
    setIsLoading(true);
    if (
      roleName !== null &&
      roleName?.trim() !== "" &&
      selectedRoles.length > 0
    ) {
      let permissions = [];
      selectedRoles.forEach((element) => {
        permissions.push(element.value);
      });
      let roleTypes = {
        roleName: roleName,
        roleDescription: roleDescrip,
        permissions: permissions,
      };
      await postCrudApi("roles/roles", roleTypes);
      setUpdateUi(!updateUi);
      setAddModalOpen(false);
      toast.success("Added successfully");
    } else {
      toast.error("Please Enter the mandatory fields");
    }
    setIsLoading(false);
  };

  const OnUpdateNodeClose = () => {};

  const handleSelectRoles = (event) => {
    setSelectedRoles(event);
  };

  const getAllRoles = async (rolesMapping) => {
    setIsLoading(true);
    let dataResult;
    let result = getCrudApi("roles/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    let allRoles = [...dataResult];
    dataResult.forEach((element) => {
      if (element.permissions) {
        element.permissions.forEach((permission) => {
          if (permission) {
            let arrayOfPermissions = [];
            rolesMapping.forEach((eachRole) => {
              if (permission === eachRole.value) {
                arrayOfPermissions.push(eachRole.label);
                return;
              }
            });
            allRoles.permissionArray = arrayOfPermissions;
          }
        });
      }
    });
    setTableData(allRoles);
    setIsLoading(false);
  };

  const getRolesForSelect = async () => {
    let SRoles = [];
    let dataResult;
    let result = getCrudApi("roleType/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    dataResult.map((Cvar) => {
      let role = {
        value: Cvar.roleTypeId,
        label: Cvar.roleType,
      };
      SRoles.push(role);
    });
    setRolesForSelection(SRoles);
    return SRoles;
  };

  useEffect(() => {
    let rolesMapping = getRolesForSelect();
    getAllRoles(rolesMapping);

    const interval = setInterval(
      getAllRoles,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, [updateUi]);

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "1px",
      display: "flex",
      borderRadius: "5px",
      textAlign: "left",
      width: "95%",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={(e) => OnAddRoleOpen(e)}
            >
              Add
            </button>
          </div>
          <div className="Table-body">
            <div className=" table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  title={"Roles and Permissions"}
                  data={tableData}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>

          {AddModalOpen ? (
            <div className="modal">
              <div className="modal-content">
                <div className="header-modal">
                  <h5 className="header-title">Role</h5>
                </div>
                <div className="div-display">
                  <label className="label-class">
                    Role Name <i className="fa fa-asterisk aster-risk-Icon"></i>
                  </label>
                  <input
                    type="text"
                    placeholder="Role Name"
                    onChange={(e) => handleNameChange(e)}
                    value={roleName}
                    className="textbox-input_1"
                  />
                </div>
                <div className="div-display">
                  <label className="label-class">Role Description</label>

                  <input
                    type="text"
                    placeholder="Role Description"
                    onChange={(e) => handleDescrip(e)}
                    value={roleDescrip}
                    className="textbox-input_1"
                  />
                </div>
                <div className="div-display">
                  <label className="label-class">
                    Roles <i className="fa fa-asterisk aster-risk-Icon"></i>
                  </label>
                  <Select
                    value={selectedRoles}
                    isMulti
                    onChange={handleSelectRoles}
                    options={RolesForSelection}
                    styles={selectStyles}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: " #E5E5E5",
                        primary: "#B2D4FD ",
                      },
                    })}
                  ></Select>
                </div>
                <div className="footer-modal">
                  <button
                    type="button"
                    className="close-btn"
                    onClick={onCloseClick}
                  >
                    Close
                  </button>
                  {NewOrUpdate === "New" ? (
                    <button
                      type="button"
                      className="Modal-btn"
                      onClick={OnAddNodeClose}
                    >
                      Add
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="Modal-btn"
                      onClick={OnUpdateNodeClose}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
