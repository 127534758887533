import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { contractTemplateData: {} };

export const contractTemplateSlice = createSlice({
  name: "contractTemplateState",
  initialState: { value: initialStateValue },
  reducers: {
    setcontractTemplateData: (state, action) => {
      state.value.contractTemplateData = action.payload;
    },
  },
});

export const { setcontractTemplateData } = contractTemplateSlice.actions;

export default contractTemplateSlice.reducer;
