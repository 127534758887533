const selectOptionsMapName = (data, val, label1, label12) => {
  let options = [];
  if (data)
    data?.map((obj) => {
      options.push({
        value: obj[val],
        label: obj[label1] + " " + obj[label12],
      });
    });
  return options;
};
export default selectOptionsMapName;
