import "./index.css";
import React, { forwardRef, useMemo } from "react";
import { useState, useCallback, useRef, useEffect } from "react";
import {
  createPlateUI,
  // HeadingToolbar,
  Plate,
  createAlignPlugin,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  // createCodeBlockPlugin,
  createCodePlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createKbdPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  // createDndPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  createComboboxPlugin,
  createMentionPlugin,
  createIndentPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createDeserializeMdPlugin,
  createDeserializeCsvPlugin,
  createNormalizeTypesPlugin,
  createFontSizePlugin,
  createHorizontalRulePlugin,
  createPlugins,
  createFontFamilyPlugin,
  createFontWeightPlugin,
  createDeserializeDocxPlugin,
  createDeserializeHtmlPlugin,
  //createJuicePlugin,
  createLineHeightPlugin,
  // useEditorRef,
  // usePlateEditorRef,
  createPlateEditor,
  // createPluginFactory,
  // PlateProvider,
  ELEMENT_PARAGRAPH,
  createIndentListPlugin,
  ELEMENT_H1,
  SearchHighlightToolbar,
  createFindReplacePlugin,
} from "@udecode/plate";
import { createJuicePlugin } from "@udecode/plate-juice";
// import CommentedText from "../config/commentsComponent/CommentedText";
import ConditionText from "../config/conditionComponent/ConditionText";
import SignatureBlock from "../config/Signatureblock";
// import { serialize, serializeContract } from "./slateHelper/serializeHtml";
import {
  deserialize,
  // ,deserializeContract
} from "./slateHelper/deserialize";
import { CONFIG } from "../config/config";
import { ReactEditor } from "slate-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createCustomPlugin } from "./CustomPlugins/CreateCustomPlugin";
import { createRightIndentCustomPlugin } from "./CustomPlugins/CreateRightIndentCustomPlugin";
import { createDeserializeHtmlPluginCustom } from "./CustomDocxSerializer/deserializer/createDeserializeHtmlPluginCustom";

//import { createDndPlugin } from "@udecode/plate-dnd";
let components = createPlateUI({
  // customize your components by plugin key
});

const ClauseEditor = forwardRef((props, ref) => {
  const [search, setSearch] = useState("");
  const [pageWidth, setPageWidth] = useState(21);
  const [pageHeight, setPageHeight] = useState("297mm");
  const plugins = useMemo(
    () =>
    createPlugins(
      [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        createTodoListPlugin(),
         createHeadingPlugin(),
        createImagePlugin(),
        createHorizontalRulePlugin(),
        createLinkPlugin(),
        createListPlugin(),
        createTablePlugin(),
        createMediaEmbedPlugin(),
        // createCodeBlockPlugin(),
        createAlignPlugin(CONFIG.align),
        createBoldPlugin(),
        createCodePlugin(),
        createItalicPlugin(),
        createHighlightPlugin(),
        createUnderlinePlugin(),
        createStrikethroughPlugin(),
        createSubscriptPlugin(),
        createSuperscriptPlugin(),
        createFontColorPlugin(),
        createFontBackgroundColorPlugin(),
        createFontSizePlugin(),
        createFontFamilyPlugin(),
        createFontWeightPlugin(),
        createKbdPlugin(),
        createNodeIdPlugin(),
        //createDndPlugin(),
        createIndentListPlugin(CONFIG.indent),
        // createIndentPlugin(CONFIG.indent),
        createCustomPlugin(CONFIG.indent),
        createRightIndentCustomPlugin(CONFIG.rightindent),
        createAutoformatPlugin(CONFIG.autoformat),
        createResetNodePlugin(CONFIG.resetBlockType),
        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),
        createTrailingBlockPlugin(CONFIG.trailingBlock),
        createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
        createComboboxPlugin(),
        createMentionPlugin(),
        createDeserializeMdPlugin(),
        createDeserializeCsvPlugin(),
        createDeserializeHtmlPluginCustom(),
        createDeserializeDocxPlugin(),
        createDeserializeHtmlPlugin(),
        createJuicePlugin(),
        createFindReplacePlugin({ options: { search } }),
        createLineHeightPlugin({
          inject: {
            props: {
              defaultNodeValue: 1,
              validNodeValues: [1, 1.2, 1.5, 2, 3],
              validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1],
            },
          },
        }),
        // createTabbablePlugin({
        //   options: {
        //     query: (editor) => {
        //       const inList = findNode(editor, {
        //         match: { type: ELEMENT_LI },
        //       });
        //       const inCodeBlock = findNode(editor, {
        //         match: { type: ELEMENT_PARAGRAPH },
        //       });
        //       return !inList && !inCodeBlock;
        //     },
        //   },
        // }),
        //createSignatureplugin(),
        // SlateEntityPlugin(),PlateEditor
      ],
      {
        components,
      }
    ),
    [search]
  );
  const slateeditorRef = useRef();
  if (!slateeditorRef.current)
    // slateeditorRef.current = withReact(createEditor());
    slateeditorRef.current = createPlateEditor({
      plugins: plugins,
    });

  const editor = slateeditorRef.current;

  const initialValue = [
    {
      type: "p",
      // indent: 5,
      // rightindent: 5,
      children: [
        {
          text: "Create Template",
          fontFamily: "sans-serif",
          fontSize: "11pt",
          lineHeight: "1",
          //backgroundColor: "#FF00FF",
        },
      ],
    },
  ];
  const [debugValue, setDebugValue] = useState(
    props.clauseData === null
      ? initialValue
      : deserialize(
        new DOMParser().parseFromString(props.clauseData, "text/html").body,
        {},
        []
      )
  );

  useEffect(() => {
    setDebugValue(null);
    let clauseEditorVal =
      props.clauseData === null
        ? initialValue
        : deserialize(
          new DOMParser().parseFromString(props.clauseData, "text/html").body,
          {},
          []
        );
    setDebugValue(clauseEditorVal);
  }, [props.clauseData]);

  const onChangeHandler = useCallback(
    (newValue) => {
      setDebugValue(newValue);
      ReactEditor.focus(editor);
    },
    [editor]
  );

  return (
    <>
      <div className="editorContent" id={props.id}>
        <div className="editor-comment" >
          <DndProvider backend={HTML5Backend}>
            <div className="editor-wrapper">
              {/* <SearchHighlightToolbar icon={"Search"} setSearch={setSearch} /> */}
              <div className="editor" style={{ width: pageWidth + "cm" }}>
                <Plate
                  id={props.id}
                  editor={editor}
                  //editableProps={CONFIG.editableProps}
                  initialValue={debugValue}
                  value={debugValue}
                  onChange={(newValue) => {
                    onChangeHandler(newValue);
                  }}
                  // renderEditable={renderEditable}
                  // value={editorState}
                  plugins={plugins}
                  //renderLeaf={renderLeaf}
                  //renderElement={renderElement}
                  editableProps={{
                    renderElement: ({ attributes, children, element }) => {
                      switch (element.type) {
                        case "clause":
                          return (
                            <clause
                              data-type="clause"
                              contentEditable="false"
                              clauseId={element.data.clause.clauseID}
                              readOnly={true}
                              style={{ color: "#B74878" }}
                            >
                            <p>{children}</p>
                            </clause>
                          );
                        case "div":
                          return <div {...attributes}>{children}</div>;
                        case "table":
                          return (
                            <table
                              {...attributes}
                              // style={{ border: "2px solid black" }}
                            >
                              {children}
                            </table>
                          );
                        case "tr":
                          return (
                            <tr
                              {...attributes}
                              // style={{ border: "2px solid black" }}
                            >
                              {children}
                            </tr>
                          );
                        case "td":
                          return (
                            <td
                              {...attributes}
                              // style={{ border: "2px solid black" }}
                            >
                              {children}
                            </td>
                          );
                        case "h1":
                          return <h1 {...attributes}>{children}</h1>;
                        case "h2":
                          return <h2 {...attributes}>{children}</h2>;
                        case "h3":
                          return <h3 {...attributes}>{children}</h3>;
                        case "h4":
                          return <h4 {...attributes}>{children}</h4>;
                        case "h5":
                          return <h5 {...attributes}>{children}</h5>;
                        case "h6":
                          return <h6 {...attributes}>{children}</h6>;
                        default:
                          // Get existing style or initialize an empty object
                          return <p {...attributes}>{children}</p>;
                      }
                    },
                    renderLeaf: ({ attributes, children, leaf }) => {
                      if (leaf.variable) {
                        children = (
                          <mark
                            data-type={leaf.variable["data-type"]}
                            contentEditable="false"
                            uuid={leaf.variable["data-variable-id"]}
                            data-path={leaf.variable["data-path"]}
                            style={{ backgroundColor: "#fcc935" }}
                          >
                            {children}
                          </mark>
                        );
                      }
                      if (leaf.condition) {
                        children = (
                          <ConditionText
                            condition={leaf.condition}
                            textNode={leaf}
                          >
                            {children}
                          </ConditionText>
                        );
                      }
                      if (leaf.signature) {
                        children = (
                          <SignatureBlock
                            children={children}
                            signature={leaf.signature}
                            label={leaf.label}
                          />
                          // </span>
                        );
                      }
                      if (leaf.ins) {
                        children = <ins className="diffmod">{children}</ins>;
                      }
                      if (leaf.del) {
                        children = <del className="diffmod">{children}</del>;
                      }
                      
                      if (
                        leaf.fontSize ||
                        leaf.fontFamily ||
                        leaf.lineHeight ||
                        leaf.backgroundColor ||
                        leaf.color
                      ) {
                        let lStyle = {};
                        if (leaf.fontSize) {
                          lStyle = {fontSize: leaf.fontSize}
                        }
                        if (leaf.fontFamily) {
                          lStyle = {...lStyle,fontFamily: leaf.fontFamily}
                        }
                        if (leaf.lineHeight) {
                          lStyle = {...lStyle,lineHeight: leaf.lineHeight}
                        }
                        if (leaf.backgroundColor) {
                          lStyle = {...lStyle,backgroundColor: leaf.backgroundColor}
                        }
                        if (leaf.color) {
                          lStyle = {...lStyle,color: leaf.color}
                        }
                        children = (
                          <span style={lStyle}>
                            {children}
                          </span>
                        );
                      }
                      if (leaf.li) {
                        children = <li {...attributes}>{children}</li>;
                      }
                     
                      return <span {...attributes}>{children}</span>;
                    },
                    autoCapitalize: true,
                    autoFocus: true,
                    style: {
                      position: "relative",
                      outline: "none",
                      // whiteSpace: "pre-wrap",
                      overflowWrap: "breakWord",
                      background: "white",
                      //width: "21cm",
                      minWidth: pageWidth - 5.08 + "cm",
                      maxWidth: pageWidth - 5.08 + "cm",
                      minHeight: pageHeight,
                      margin: "2.54cm 2.54cm",
                    },
                    className: "page",
                    readOnly: !props.isEditable,
                  }}
                ></Plate>
              </div>
            </div>
          </DndProvider>
        </div>
      </div>
    </>
  );
});

export default ClauseEditor;
