import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createTheme,
  MuiThemeProvider,
  // withStyles,
} from "@material-ui/core/styles";
import OptimisedMUIDataTable from "./OptimisedMUIDataTable";
// import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ModalContract from "./Modalcontract";
import { convertHierarchy } from "../../Helpers/hierarchyConverter";
import { getCrudApi } from "../../webServiceCalls/webServiceCall";
import { validateToken } from "../../Helpers/validateToken";
import Loading from "../../Loading/Loading";
import { toast } from "react-toastify";

export default function ManageContracts(props) {
  const [tableData, setTableData] = useState([]);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  // const [selectedCatgry, setSelectedCatgry] = useState("");
  const [page, setPage] = useState(0);
  // const [modifiedData, setModifiedData] = useState(RequestData);
  // const [disable, setdisable] = useState(true);
  // const [CheckStatus, setCheckStatus] = useState(false);
  // const [CheckSubmit, setCheckSubmit] = useState(false);

  const [Approvalcnt, setApprovalCnt] = useState(0);
  const [Submitcnt, setSubmitCnt] = useState(0);
  const [Draftedcnt, setDraftedCnt] = useState(0);
  const [amendedCnt, setAmendedCnt] = useState(0);
  const [terminatedCnt, setTerminatedCnt] = useState(0);
  const [executedCnt, setExecutedCnt] = useState(0);
  const [tableDataforcnt, setTableDataforcnt] = useState([]);
  const [AllCnt, setAllCnt] = useState(0);
  // const [templateType, setTemplateType] = useState(9);
  // const [contractName, setContractName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  const contractTypeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );

  const StatusData = useSelector((state) => state.statusState.value.statusData);
  const contractTemplateData = useSelector(
    (state) => state.contractTemplateState.value.contractTemplateData
  );

  const getContractData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi("contract/all/table", {});
    await result.then(function (res) {
      dataResult = res;
    });

    setTableData(
      location?.state?.filterName !== "" &&
        location?.state?.filterName !== null &&
        location?.state?.filterName !== undefined
        ? location?.state?.filterName === "All"
          ? generateJSONForTable(
              dataResult
                ? dataResult.filter(
                    (element) =>
                      element.contractRequestFlag === 2 ||
                      element.formStateFlag === 2 ||
                      (element.contractRequestFlag === 1 &&
                        element.contractStatus === 7)
                  )
                : null
            )
          : filterDataByFilterName(
              location?.state?.filterName,
              generateJSONForTable(
                dataResult
                  ? dataResult.filter(
                      (element) =>
                        element.contractRequestFlag === 2 ||
                        element.formStateFlag === 2 ||
                        (element.contractRequestFlag === 1 &&
                          element.contractStatus === 7)
                    )
                  : null
              )
            )
        : generateJSONForTable(
            dataResult
              ? dataResult.filter(
                  (element) =>
                    element.contractRequestFlag === 2 ||
                    element.formStateFlag === 2 ||
                    (element.contractRequestFlag === 1 &&
                      element.contractStatus === 7)
                )
              : null
          )
    );
    setTableDataforcnt(
      generateJSONForTable(
        dataResult
          ? dataResult.filter(
              (element) =>
                element.contractRequestFlag === 2 ||
                element.formStateFlag === 2 ||
                (element.contractRequestFlag === 1 &&
                  element.contractStatus === 7)
            )
          : null
      )
    );
    setIsLoading(false);
  };

  const SelectMappingForContractTemplate = () => {
    let treeHierarchy = contractTemplateData?.filter((ct)=> ct.isActive === 1);
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"contractTemplateId"/g,
      '"value"'
    );
    old = old.replace(/"contractTemplateName"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };

  const SelectMappingForContractType = () => {
    let treeHierarchy = convertHierarchy(
      contractTypeData.filter((element) => element.status === "Active"),
      "contractTypeId",
      "parentContractTypeId"
    );
    // let treeHierarchy = convertHierarchy(
    //   contractTypeData,
    //   "contract_type_id",
    //   "parent_contract_type_id"
    // );
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"contractTypeId"/g,
      '"value"'
    );
    old = old.replace(/"contractType"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };

  const SelectMappingForCategory = () => {
    let treeHierarchy = convertHierarchy(
      categoryData?.filter((element) => element.status === "Active"),
      "catogeryTypeId",
      "parentCatogeryTypeId"
    );
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"catogeryTypeId"/g,
      '"value"'
    );
    old = old.replace(/"catogeryType"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "VIEW",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                style={{ textDecoration: "none" }}
                to="/Contracts/View"
                state={{
                  templateSelected: null,
                  template_version: tableMeta.rowData[2],
                  tableData: tableMeta.rowData,
                  templateType: null,
                  contractData: tableData.filter(
                    (element) => element.contractId === tableMeta.rowData[1]
                  )[0],
                  contractName: tableMeta.rowData[6],
                }}
              >
                <button
                  className="view-icon"
                  onClick={() => onDisplayDynamicForm(tableMeta, value)}
                >
                  <i className="fa fa-eye custom-iconformodal" />
                </button>
              </Link>
            </>
          );
        },
      },
    };

    columnsArray.push(firstCol);
    columnsArray.push({
      name: "contractId",
      label: "Contract ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractTemplateVersionId",
      label: "Version ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractTypeId",
      label: "Contract Type ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "catogeryTypeId",
      label: "Category Type ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractFormData",
      label: "Contract Form Data",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractName",
      label: "CONTRACT NAME",
    });
    columnsArray.push({
      name: "categoryType",
      label: "CATEGORY TYPE",
    });
    columnsArray.push({
      name: "contractType",
      label: "CONTRACT TYPE",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "Status ID",
      options: {
        display: false,
      },
    });

    columnsArray.push({
      name: "isApprovalRequired",
      label: "Approval Required",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractTemplate",
      label: "Contract Template",
      options: {
        display: false,
      },
    });

    columnsArray.push({
      name: "templateTypeFlag",
      label: "Template Type",
      options: {
        display: false,
      },
    });
    //13
    columnsArray.push({
      name: "amendmentFormData",
      label: "Amendment Data",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "amendmentTemplate",
      label: "Amendment Template",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "terminationFormData",
      label: "Termination Data",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "terminationTemplate",
      label: "Termination Template",
      options: {
        display: false,
      },
    });

    columnsArray.push({
      name: "contractRequestFlagName",
      label: "SOURCE",
    });
    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          let color;
          const setStatus = (dataIndex) => {
            switch (dataIndex) {
              case "Drafted":
                color = "#1D907Fff";
                break;
              case "Submitted":
                color = "#3F66B0ff";
                break;
              case "Approved":
                color = "#4F96D5ff";
                break;
              case "Reopened":
                color = "#FBCD08ff";
                break;
              case "Termination InProgress":
                color = "#8FC742ff";
                break;
              case "Amended":
                color = "#F05623ff";

                break;
              case "Negotiation":
                color = "#9F4098ff";
                break;
              case "Executed":
                color = "#F7901Eff";
                break;
              case "Terminated":
                color = "#76ccba";

                break;
            }
            return color;
          };
          const state = dataIndex;
          return (
            <>
              <p
                className={"table-contract-request-status"}
                style={{ background: setStatus(state) }}
              >
                {state}
              </p>
            </>
          );
        },
      },
    });

    return columnsArray;
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    //selectableRowsOnClick: false,
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 4,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},

    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      allRowsSelected.forEach((element, index) => {
        // setdisable(false);

        if (
          tableData[element.index].status === "Approved" ||
          tableData[element.index].status === "Cancelled"
        ) {
          // setCheckStatus(true);
        }
        if (tableData[element.index].status === "Drafted") {
          // setCheckSubmit(true);
        } else {
          // setCheckStatus(false);
          // setCheckSubmit(false);
        }
      });
    },
  };

  const generateJSONForTable = (templateData) => {
    let arrayOfJSON = [];
    if (templateData != null) {
      templateData.forEach((template) => {
        let newJSON = {};
        Object.keys(template).forEach((element) => {
          newJSON[element] = template[element];
        });

        categoryData.some((category) => {
          if (category.catogeryTypeId === template.catogeryTypeId) {
            newJSON.categoryType = category.catogeryType;
            return true;
          }
          return false;
        });
        contractTypeData.some((contractType) => {
          if (contractType.contractTypeId === template.contractTypeId) {
            newJSON.contractType = contractType.contractType;
            return true;
          }
          return false;
        });

        StatusData.some((status) => {
          if (status.status_id == template.contractStatus) {
            newJSON.status = status.status;
            return true;
          }
          return false;
        });
        arrayOfJSON.push(newJSON);
      });
    }
    return arrayOfJSON;
  };

  const onDisplayDynamicForm = (tableMeta, value) => {};

  const OnAddContractClose = (contractDetails, templateType, contractName) => {
    setAddModalOpen(false);

    navigate("/Contracts/View", {
      state: {
        templateSelected: contractDetails,
        template_version: null,
        tableData: null,
        templateType: templateType,
        contractData: null,
        contractName: contractName,
      },
    });
  };

  function filterDataByFilterName(name, data) {
    data = data.filter(function (iterator) {
      if (iterator)
        return iterator.contractStatusName.toLowerCase() === name.toLowerCase();
    });
    return data;
  }

  useEffect(() => {
    let Approval = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "approved";
    });
    setApprovalCnt(Approval.length);

    let Drafted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "drafted";
    });
    setDraftedCnt(Drafted.length);

    let submitted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "submitted";
    });
    setSubmitCnt(submitted.length);
    let amended = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "amended";
    });
    setAmendedCnt(amended.length);
    let terminated = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "terminated";
    });
    setTerminatedCnt(terminated.length);
    let executed = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "executed";
    });
    setExecutedCnt(executed.length);
    let allCnt = tableDataforcnt.length;
    setAllCnt(allCnt);
  });

  const handleapproval = (e) => {
    let Approval = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "approved";
    });

    setTableData(Approval);
  };

  const handlesubmit = (e) => {
    let submitted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "submitted";
    });
    setTableData(submitted);
  };

  const handleAmended = (e) => {
    let amended = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "amended";
    });
    setTableData(amended);
  };
  const handleExecuted = (e) => {
    let executed = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "executed";
    });
    setTableData(executed);
  };

  const handleTerminated = (e) => {
    let terminated = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "terminated";
    });
    setTableData(terminated);
  };

  const handle_drafted = (e) => {
    let Drafted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "drafted";
    });
    setTableData(Drafted);
  };
  const handle_all = (e) => {
    setTableData(tableDataforcnt);
  };

  //Buttons
  const OnAddRequestOpen = async (e) => {
    let result = getCrudApi("contract/check", {});
    await result.then(function (res) {
      if (res) {
        setAddModalOpen(true);
      } else {
        toast.error(
          "Attention: Your subscription contract limit has been reached. Please contact our service provider for further assistance."
        );
      }
    });
  };

  useEffect(() => {
    // if (location.state) {
    //   setTableData(location.state.tableData);
    //   setTableDataforcnt(location.state.tableData)
    // } else {
    // }
    getContractData();
    const interval = setInterval(
      getContractData,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, []);

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="status-main-contract">
            <div className="status-div">
              <button
                type="button"
                className="status-btn-contract   all-contract "
                onClick={handle_all}
              >
                <div className="status-text">All</div>
                <div className="count-div">
                  <label className="all-contract-label"> {AllCnt}</label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-contract  drafted-contract"
                onClick={handle_drafted}
              >
                <div className="status-text"> Drafted</div>
                <div className="count-div">
                  <label className="drafted-contract-label">{Draftedcnt}</label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-contract  submitted-contract "
                onClick={handlesubmit}
              >
                <div className="status-text">Submitted</div>
                <div className="count-div">
                  <label className="submitted-contract-label">
                    {" "}
                    {Submitcnt}
                  </label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-contract approved-contract"
                onClick={handleapproval}
              >
                <div className="status-text">Approved </div>
                <div className="count-div">
                  <label className="approve-contract-label">
                    {" "}
                    {Approvalcnt}
                  </label>
                </div>
              </button>
              {/* </div> */}
              {/* <div> */}
              <button
                type="button"
                className="status-btn-contract  executed-contract "
                onClick={handleExecuted}
              >
                <div className="status-text">Executed</div>
                <div className="count-div">
                  <label className="excuted-contract-label">
                    {" "}
                    {executedCnt}
                  </label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-contract  amended-contract "
                onClick={handleAmended}
              >
                <div className="status-text">Amended</div>
                <div className="count-div">
                  <label className="amended-contract-label">
                    {" "}
                    {amendedCnt}
                  </label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-contract  terminated-contract "
                onClick={handleTerminated}
              >
                <div className="status-text">Terminated</div>
                <div className="count-div">
                  <label className="terminate-contract-label">
                    {" "}
                    {terminatedCnt}
                  </label>
                </div>
              </button>
              {/* </div> */}
            </div>
            <div className="contract-add-Btn">
              <button
                type="button"
                className="add-contracts-btn "
                onClick={(e) => OnAddRequestOpen(e)}
              >
                Add
              </button>
            </div>
          </div>

          <div className="Table-body">
            <div className=" table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={tableData}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}

      <ModalContract
        open={AddModalOpen}
        RequestCategory={SelectMappingForCategory()}
        contractTemplateData={contractTemplateData}
        contractTypeOptions={SelectMappingForContractType()}
        contractTemplateName={SelectMappingForContractTemplate()}
        ModalOpen={setAddModalOpen}
        ButtonTitle="Add"
        ContractClosed={(contractTemplateName, templateType, contractName) =>
          OnAddContractClose(contractTemplateName, templateType, contractName)
        }
      />
    </>
  );
}
