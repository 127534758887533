import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./TabsForVariables.css";
import {
  convertBasicCSVToArrOfObject,
  containsObject,
  getCorrespondingValueForOperators,
} from "../../PlateEditor/helper/Helper";
import {
  generateUUID,
  generateUUIDSig,
} from "../../config/utils/SlateUtilityFunctions";
import { quickSort } from "../dynamicForm/shared/helper";

const selectStyles = {
  menu: (styles) => ({ ...styles, zIndex: 999 }),
  control: () => ({
    borderColor: "#D9DFF2",
    borderStyle: "solid",
    borderWidth: "1px",
    display: "flex",
    borderRadius: "5px",
    textAlign: "left",
    width: "98%",
    margin: "5px 0 10px 0",
    "&:hover": {
      border: "2px solid",
      borderColor: "#48336E",
    },
  }),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function TabForVariables(props) {
  let fJson = props.finalJSON;
  const [value, setValue] = useState(0);
  const [propertiesDisabled, setpropertiesDisabled] = useState(true);
  const [lookupId, setLookupId] = useState("");
  const [selectedSubGroup, setSelectedSubGroup] = useState("");
  const [subGroups, setSubGroups] = useState([]);
  const [isRequestChecked, setIsRequestChecked] = useState(true);
  const [totalColumns, setTotalColumns] = useState([]);
  const [lookupTableNames, setLookupTableNames] = useState([]);
  const [selectedLookupTableName, setSelectedLookupTableName] = useState(null);
  const [dataColumns, setDataColumns] = useState([]);
  const [finalJSON, setFinalJSON] = useState({});
  const [checkedVars, setCheckedVars] = useState([]);
  const [nonTemplateVarName, setNonTemplateVarName] = useState("");
  const [nonTemplateVarArray, setNonTemplateVarArray] = useState([]);
  const [varType, setVarType] = useState({ value: "text", text: "Text" });
  const [invalidRegex, setinvalidRegex] = useState(false);
  const [currencyInWrds, setCurrencyInWrds] = useState(false);
  const [currencyPopup, setCurrencyPopup] = useState(false);
  const [currencyVar, setCurrencyVar] = useState(null);
  const [dateVar, setDateVar] = useState(null);
  const [dateInWords,setDateInWords] = useState(false);
  const [datePopUp, setDatePopUp] = useState(false);
  const [dateFormat, setDateFormat] = useState({
    value: "MM-dd-yyyy",
    text: "MM-dd-yyyy",
  });
  const varTypeOptions = [
    { value: "text", text: "Text" },
    { value: "searchBar", text: "SearchBar" },
    // { value: "file", text: "File" },
    { value: "email", text: "Email" },
    { value: "textarea", text: "Textarea" },
    { value: "select", text: "Select" },
    // { value: "radio", text: "Radio" },
    // { value: "checkbox", text: "Checkbox" },
    { value: "date", text: "Date" },
    { value: "money", text: "Currency" },
    // { value: "password", text: "Password" },
  ];
  const [validationType, setValidationType] = useState({
    value: "none",
    text: "None",
  });
  const [currencyFormatType, setCurrencyFormatType] = useState({
    value: "thousand",
    text: "Thousand Separator",
  });
  const validationTypeOptions = [
    { value: "text", text: "Text" },
    { value: "number", text: "Number" },
    { value: "regex", text: "Regex" },
    { value: "none", text: "None" },
  ];
  const CurnFormatTypeOptions = [
    { value: "thousand", text: "Thousand Separator" },
    { value: "hundred", text: "Hundred Separator" },
  ];
  const validDateFormats = [
    { value: "MM-dd-yyyy", text: "MM-dd-yyyy" },
    { value: "dd-MM-yyyy", text: "dd-MM-yyyy" },
    { value: "dd-yyyy-M", text: "dd-yyyy-M" },
    { value: "yyyy-MM-dd", text: "yyyy-MM-dd" },
    // {value:}
  ];
  const [mandatory, setMandatory] = useState({ value: "true", text: "True" });
  const [mandatoryOptions, setMandatoryOptions] = useState([
    { value: "true", text: "True" },
    { value: "false", text: "False" },
  ]);
  const [operatorForVariables, setOperatorForVariables] = useState([]);
  const [valuesForVariables, setValuesForVariables] = useState([]);
  const [varsToPopulate, setVarsToPopulate] = useState([]);
  const [dependentVars, setDependentVars] = useState([]);
  const [selectedVars, setSelectedVars] = useState([]);
  const [conditionType, setConditionType] = useState("");
  const [placeholders, setPlaceholders] = useState({});
  const [changedIds, setChangedIds] = useState({});
  const [elementType, setElementType] = useState("");
  const [configType, setConfigType] = useState("");
  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [csvForSelect, setCsvForSelect] = useState("");
  const [date, setDate] = useState(undefined);
  const [regex, setRegex] = useState("");
  const [isSignOpen, setIsSignOpen] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [variablesForCondition, setvariablesForCondition] = useState([]);
  const [variablesArray, setVariablesArray] = useState([]);
  const [sigLabel, setSigLabel] = useState("");
  const [renameVar, setRenameVar] = useState("");
  const [editVarNameOpen, setEditVarNameOpen] = useState(false);
  const [selecRenameVar, setSelecRenameVar] = useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setSelectedVars([]);
    } else {
      setCsvForSelect("");
      setLookupId("");
    }
  };

  // on mount
  useEffect(() => {
    setElementAndConfigType();
    setValue(0);
    setNonTemplateVarArray(props.nonTemplateVariableArray);
    let finJSON = props.finalJSON;

    if (props.masterLibrary) {
      let masterLibraryActive = [];
      for (var i = 0; i < props.masterLibrary.length; i++) {
        if (props.masterLibrary[i].status_id === 1) {
          masterLibraryActive.push(props.masterLibrary[i]);
        }
      }
      setLookupTableNames(masterLibraryActive);
    }

    //prepopulate nonTemplatevariables on mount of the component
    let depVars = [];
    let varArr = [];
    let nonTempVarArr = [];
    let varsToPoplt = [];
    if (finJSON) {
      let sortedVariables = quickSort(Object.keys(finJSON), finJSON);
      setVariablesArray(sortedVariables);
      Object.keys(finJSON).forEach((e) => {
        //do not consider signature variables
        if (
          e.startsWith("signer") &&
          !isNaN(parseInt(e.replace("signer", "")))
        ) {
          //do nothing
        } else {
          //if parentgroup is non-template then update non-template variable array to display in the UI

          // if (e === "nonTemplateVar") {
          //   if (
          //     finJSON.nonTemplateVar &&
          //     Object.keys(finJSON.nonTemplateVar).length !== 0
          //   ) {
          //     Object.keys(finJSON.nonTemplateVar).forEach((e) => {
          //       nonTempVarArr.push(e);
          //       setNonTemplateVarArray(nonTempVarArr);
          //       props.setNonTemplateVariableArray(nonTempVarArr);
          //     });
          //   }
          // } else

          {
            //else update varsToPopulate and dependentVars and the variableArray to record the template variables from incoming json

            if (!depVars.includes(e) && !checkedVars.includes(e)) {
              depVars.push(e);
            }

            if (!varsToPoplt.includes(e)) {
              if (finJSON[e].elementType !== null) {
                varsToPoplt.push(e);
              }
            }
          }
        }
      });
      //delete finJSON["nonTemplateVar"];
      setFinalJSON(finJSON);
    } else setFinalJSON({});
    setDependentVars(depVars);
    setVarsToPopulate(varsToPoplt);
    var conditional = {
      value: "conditional",
      text: "Conditional",
    };
    if (varsToPoplt.length >= 1) {
      if (!containsObject(mandatoryOptions)) {
        let mandOptions = [...mandatoryOptions];
        mandOptions.push(conditional);
        setMandatoryOptions(mandOptions);
      }
    } else {
      setMandatoryOptions(
        mandatoryOptions.filter((item) => {
          return item.value !== "conditional";
        })
      );
    }
    setMandatory({
      value: "true",
      text: "True",
    });
  }, []);

  useEffect(() => {
    props.setVarsToPopulate(varsToPopulate);
  }, [varsToPopulate]);

  useEffect(() => {
    if (checkedVars.length > 0) setpropertiesDisabled(false);
    else setpropertiesDisabled(true);
  }, [checkedVars]);

  useEffect(() => {
    if (props.finalJSON) {
      if (Object.keys(props.finalJSON).length === 0) {
        setValue(0);
        setpropertiesDisabled(true);
        setCheckedVars([]);
      } else {
        let sortedVariables = quickSort(
          Object.keys(props.finalJSON),
          props.finalJSON
        );
        setVariablesArray(sortedVariables);
        setFinalJSON(props.finalJSON);
      }
    }
  }, [fJson]);

  useEffect(() => {
    setElementAndConfigType();
  }, [varType]);

  useEffect(() => {
    let varsToPoplt = [];
    let depVars = [];
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    Object.keys(finJSON).forEach((e) => {
      if (!depVars.includes(e) && !checkedVars.includes(e)) {
        depVars.push(e);
      }
      if (!varsToPoplt.includes(e)) {
        if (finJSON[e].elementType !== null) {
          varsToPoplt.push(e);
        }
      }
    });

    setDependentVars(depVars);
    setVarsToPopulate(varsToPoplt);

    var conditional = {
      value: "conditional",
      text: "Conditional",
    };
    if (varsToPoplt.length >= 1) {
      if (!containsObject(mandatoryOptions)) {
        let mandOptions = [...mandatoryOptions];
        mandOptions.push(conditional);
        setMandatoryOptions(mandOptions);
      }
    } else {
      setMandatoryOptions(
        mandatoryOptions.filter((item) => {
          return item.value !== "conditional";
        })
      );
    }
    //prepopulate the properties section when the activetab changes to properties
    if (value === 1) {
      //initialise all UI to initial values
      setVarType({ value: "text", text: "Text" });
      setMinLength("");
      setMaxLength("");
      setPlaceholders({});
      setSelectedLookupTableName("");
      setTotalColumns([]);
      setIsRequestChecked(true);
      setMandatory({ value: "false", text: "False" });
      setValidationType({ value: "none", text: "None" });
      setDateFormat({
        value: "MM-dd-yyyy",
        text: "MM-dd-yyyy",
      });
      if (Object.keys(finJSON).length > 0) {
        let fLJ = finJSON;
        //if checked variables array is only 1, then directly prepopulate
        if (
          checkedVars.length === 1 &&
          checkedVars[0] in fLJ &&
          "elementType" in fLJ[checkedVars[0]] &&
          "elementConfig" in fLJ[checkedVars[0]]
        ) {
          //prepopulate that variable's configuration
          if (Object.keys(finJSON).includes(checkedVars[0])) {
            let checkedVar = checkedVars[0];
            let elType = fLJ[checkedVar].elementType;
            let elConfType = fLJ[checkedVar].elementConfig.type;
            setValidationType({ value: "none", text: "None" });
            if (elType === "input" || elType === "money") {
              if (elConfType === "text") {
                if (elType === "money") {
                  
                  setVarType({ value: "money", text: "Currency" });
                  if (fLJ[checkedVar].currencyFormat === "hundred") {
                    setCurrencyFormatType({
                      value: "hundred",
                      text: "Hundred Separator",
                    });
                  } else {
                    setCurrencyFormatType({
                      value: "thousand",
                      text: "Thousand Separator",
                    });
                  }
                } else setVarType({ value: "text", text: "Text" });
                if (
                  fLJ[checkedVar].validation.maxLength &&
                  fLJ[checkedVar].validation.minLength
                ) {
                  setMinLength(fLJ[checkedVar].validation.minLength);
                  setMaxLength(fLJ[checkedVar].validation.maxLength);
                }
                if (fLJ[checkedVar].validation.isNumeric === true) {
                  setValidationType({
                    value: "number",
                    text: "Number",
                  });
                } else if (fLJ[checkedVar].validation.isNumeric === false) {
                  setValidationType({ value: "text", text: "Text" });
                } else if (fLJ[checkedVar].validation.rejex) {
                  setValidationType({ value: "regex", text: "Regex" });
                  setRegex(fLJ[checkedVar].validation.rejex);
                } else {
                  setValidationType({ value: "none", text: "None" });
                }
              } else if (elConfType === "file") {
                setVarType({ value: "file", text: "File" });
              } else if (elConfType === "email") {
                setVarType({ value: "email", text: "Email" });
              }
            } else if (elType === "searchBar") {
              setVarType({ value: "searchBar", text: "SearchBar" });

              if (fLJ[checkedVar].elementConfig.lookupConfig) {
                lookupTableNames.some((e) => {
                  if (
                    fLJ[checkedVar].elementConfig.lookupConfig.lookupTableID ===
                    e.library_table_id
                  ) {
                    setSelectedLookupTableName(e);
                    return true;
                  }
                  return false;
                });

                //prepopulate lookupId
                setLookupId({
                  label: fLJ[checkedVar].elementConfig.lookupConfig.lookupId,
                  value: fLJ[checkedVar].elementConfig.lookupConfig.lookupId,
                });

                let totalCols = [];

                if (
                  "populateFields" in fLJ[checkedVar].elementConfig.lookupConfig
                ) {
                  fLJ[
                    checkedVar
                  ].elementConfig.lookupConfig.populateFields.forEach((e) => {
                    let obj = {};
                    let varName = Object.prototype.hasOwnProperty.call(
                      e,
                      "varName"
                    )
                      ? e.varName !== null
                        ? e.varName
                        : null
                      : null;
                    obj.varName = varName;
                    let colName = Object.prototype.hasOwnProperty.call(
                      e,
                      "masterDataCol"
                    )
                      ? e.masterDataCol
                      : null;
                    obj.colName = colName;
                    totalCols.push(obj);
                  });
                  setTotalColumns(totalCols);
                }
              }
            } else if (elType === "textarea") {
              setVarType({ value: "textarea", text: "Textarea" });
              if (
                fLJ[checkedVar].validation.maxLength &&
                fLJ[checkedVar].validation.minLength
              ) {
                setMinLength(fLJ[checkedVar].validation.minLength);
                setMaxLength(fLJ[checkedVar].validation.maxLength);
              }
              if (fLJ[checkedVar].validation.isNumeric === true) {
                setValidationType({
                  value: "number",
                  text: "Number",
                });
              } else if (fLJ[checkedVar].validation.isNumeric === false) {
                setValidationType({ value: "text", text: "Text" });
              } else if (fLJ[checkedVar].validation.rejex) {
                setValidationType({ value: "regex", text: "Regex" });
                setRegex(fLJ[checkedVar].validation.rejex);
              } else {
                setValidationType({ value: "none", text: "None" });
              }
            } else if (elType === "select") {
              setVarType({ value: "select", text: "Select" });
              setCsvForSelect(
                fLJ[checkedVar].elementConfig.options
                  .map((e) => e.value)
                  .join(",")
              );
            } else if (elType === "radio") {
              setVarType({ value: "radio", text: "Radio" });
              setCsvForSelect(
                fLJ[checkedVar].elementConfig.options
                  .map((e) => e.value)
                  .join(",")
              );
            } else if (elType === "checkbox") {
              setVarType({ value: "checkbox", text: "Checkbox" });
              setCsvForSelect(
                fLJ[checkedVar].elementConfig.options
                  .map((e) => e.value)
                  .join(",")
              );
            } else if (elType === "date") {
              setVarType({ value: "date", text: "Date" });
              setDateFormat({
                value: fLJ[checkedVar].format,
                text: fLJ[checkedVar].format,
              });
            } else if (elType === "password") {
              setVarType({ value: "password", text: "Password" });
            } else {
              setVarType({ value: "text", text: "Text" });
            }
            if (fLJ[checkedVar].validation.required === true) {
              setMandatory({ value: "true", text: "True" });
            } else if (fLJ[checkedVar].validation.required === false) {
              setMandatory({ value: "false", text: "False" });
            }
            if (fLJ[checkedVar].validation.isConditional) {
              setMandatory({
                value: "conditional",
                text: "Conditional",
              });

              let selVars = [];
              let opForVars = [];
              let valForVars = [];
              "conditions" in fLJ[checkedVar].validation.conditions &&
                fLJ[checkedVar].validation.conditions.conditions.forEach(
                  (e) => {
                    let obj = {};
                    obj.text = getCorrespondingValueForOperators(e.operator);
                    obj.value = e.operator;
                    selVars.push({ label: e.id, value: e.id });
                    opForVars.push(obj);
                    valForVars.push(e.value);
                  }
                );

              setSelectedVars(selVars);
              setOperatorForVariables(opForVars);
              setValuesForVariables(valForVars);

              setConditionType({
                value: fLJ[checkedVar].validation.conditions.conditionType,
                label: fLJ[checkedVar].validation.conditions.conditionType,
              });
            }
            let plcHldrs = { ...placeholders };
            plcHldrs[checkedVar] = fLJ[checkedVar].elementConfig.placeholder;
            setPlaceholders(plcHldrs);

            if (
              Object.prototype.hasOwnProperty.call(fLJ[checkedVar], "isRequest")
            ) {
              setIsRequestChecked(fLJ[checkedVar].isRequest);
            }
          }
        }
        // else check if checked variables array length is greater than 1,
        // prepopulate only properties which are different for the variables if same config for all variables , i.e., placeholders
        else if (checkedVars.length > 1) {
          let plcHldrs = {};
          checkedVars.forEach((e, i) => {
            plcHldrs[e] = fLJ[e]?.elementConfig?.placeholder;
            setPlaceholders(plcHldrs);
          });
        }
      }
    } else if (value === 0) {
      setVarType({ value: "text", text: "Text" });
      setMinLength("");
      setMaxLength("");
      setPlaceholders({});
      setSelectedLookupTableName("");
      setTotalColumns([]);
      setIsRequestChecked(true);
      setMandatory({ value: "false", text: "False" });
      setValidationType({ value: "none", text: "None" });
      setDateFormat({
        value: "MM-dd-yyyy",
        text: "MM-dd-yyyy",
      });
    }
  }, [value]);

  useEffect(() => {
    getDependentVariables();
  }, [dependentVars]);
  // useEffect(() => {
  //   if (nonTemplateVarArray.length == 0) {
  //     setCheckedVars([]);
  //   }
  //   props.setNonTemplateVariableArray(nonTemplateVarArray);
  // }, [nonTemplateVarArray]);

  useEffect(() => {
    let dataCols = [];
    if (
      selectedLookupTableName !== null &&
      selectedLookupTableName.library_table &&
      selectedLookupTableName.library_table.lib_table
    ) {
      Object.keys(selectedLookupTableName.library_table.lib_table[0]).forEach(
        (e) => {
          dataCols.push(e);
        }
      );
    }
    setDataColumns(dataCols);
  }, [selectedLookupTableName]);

  // useEffect(() => {
  //   props.setFinalJSON(finalJSON);
  // }, [finalJSON]);

  const displaySubmittedMessage = () => {
    if (!toast.isActive(1))
      toast.success("Configured Successfully!", {
        className: "toaststyle",
      });
  };

  const getDependentVariables = () => {
    let Svariables = [];
    dependentVars.map((Cvar) => {
      // if (Cvar !== "nonTemplateVar") {
      if (finalJSON[Cvar]?.elementType !== "searchBar") {
        let variable = {
          value: Cvar,
          label: Cvar,
        };
        Svariables.push(variable);
        // } else {
        // }
      }
    });
    setvariablesForCondition(Svariables);
    //return Svariables;
  };

  const onSignatureRemoveClick = (sign) => {
    let signatures = [...props.allSignatures];
    for (let i = 0; i < signatures.length; i++) {
      if (signatures[i].id === sign.id) {
        signatures.splice(i, 1);
        props.removeSignature(sign.id);
        // for (let j = i; j < signatures.length; j++) {
        //   signatures[j].id = signatures[j].id - 1;
        //   props.updateSignature(sign.id);
        // }
        break;
      }
    }
    props.setSignatureVariables(signatures);
  };
  const onCreateSignatureBlockClick = () => {
    //TODO - create signature block
    setIsExternal(false);
    setIsSignOpen(true);
  };
  const displayColmunMessage = () => {
    toast.error("No data columns in the library", {
      className: "toaststyle",
    });
  };
  const onSignatureClick = (sign) => {
    props.insertSignatureOnClick(sign);
  };
  const onRemoveColumnForSearchbarClick = (selCol, idx) => {
    let totalCols = [...totalColumns];
    if (totalColumns.includes(selCol)) {
      totalCols.splice(totalCols.indexOf(selCol), 1);
      setTotalColumns(totalCols);
    }
  };
  const onAddColumnForSearchbarClick = () => {
    if (totalColumns.length <= dataColumns.length - 1) {
      let totalCols = [...totalColumns];
      totalCols.push({ varName: null, colName: null });
      setTotalColumns(totalCols);
    } else {
      displayColmunMessage();
    }
  };

  const displayVariablesMessage = () => {
    toast.error("Variable already exists", {
      className: "toaststyle",
    });
  };

  const displayVariablesexisitMessage = () => {
    toast.error("Another variable exists with the same name", {
      className: "toaststyle",
    });
  };
  const onNonTemplateVariableAddClick = () => {
    if (
      !Object.keys(props.finalJSON).includes(nonTemplateVarName) &&
      nonTemplateVarName !== ""
    ) {
      let finJSON = { ...(props.finalJSON !== null ? props.finalJSON : {}) };
      let variableID = generateUUID(finJSON);
      finJSON[nonTemplateVarName] = {
        uuid: variableID,
        elementType: null,
        elementConfig: {
          type: null,
          id: null,
          placeholder: null,
        },
        validation: {
          required: null,
          isNumeric: null,
          error: null,
        },
        value: null,
        sequenceNo: Object.keys(finJSON).length + 1,
      };
      setFinalJSON(finJSON);
      props.setFinalJSON(finJSON);
    } else {
      if (Object.keys(props.finalJSON).includes(nonTemplateVarName))
        // alert("Variable already exists");
        displayVariablesMessage();
    }
    setNonTemplateVarName("");
  };
  const onNonTemplateVariableRemoveClick = (vrbl) => {
    let nonTempArr = [...props.nonTemplateVariableArray];
    nonTempArr.splice(props.nonTemplateVariableArray.indexOf(vrbl), 1);
    setNonTemplateVarArray(nonTempArr);
    props.setNonTemplateVariableArray(nonTempArr);

    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    if (Object.keys(finJSON).includes(vrbl)) {
      const varUUID = finJSON[vrbl].uuid ? finJSON[vrbl].uuid : null;
      if (varUUID !== null) {
        props.removeVariable(varUUID);
      }
      delete finJSON[vrbl];
    }
    props.setFinalJSON(finJSON);
    if (checkedVars.includes(vrbl)) {
      let checkVars = [...checkedVars];
      checkVars.splice(checkedVars.indexOf(vrbl), 1);
      setCheckedVars(checkVars);
    }
  };
  const removeVariable = (vrbl) => {
    let codeBlocks = document.getElementsByTagName("code");
    for (let i = codeBlocks.length - 1; i >= 0; i--) {
      let content = codeBlocks[i].getAttribute("data-content");
      let type = codeBlocks[i].getAttribute("data-type");
      if (type === "variable" && content === `{{${vrbl}}}`) {
        codeBlocks[i].remove();
      }
    }
  };
  const onRemoveVariableClick = (vrbl) => {
    //removeVariable(vrbl);
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    const varUUID = finJSON[vrbl].uuid;
    props.removeVariable(varUUID);
    //TODO - call function to remove variable the draft js way
    if (Object.keys(finJSON).includes(vrbl)) {
      delete finJSON[vrbl];
    }
    let sortedVariables = quickSort(Object.keys(finJSON), finJSON);
    setFinalJSON(finJSON);
    setVariablesArray(sortedVariables);
    props.setFinalJSON(finJSON);
    if (checkedVars.includes(vrbl)) {
      let checkVars = [...checkedVars];
      checkVars.splice(checkedVars.indexOf(vrbl), 1);
      setCheckedVars(checkVars);
    }

    if (varsToPopulate.includes(vrbl)) {
      let varsToPoplt = [...varsToPopulate];
      varsToPoplt.splice(varsToPopulate.indexOf(vrbl), 1);
      setVarsToPopulate(varsToPoplt);
    }
  };

  //onvarSubmit
  const onVariablePropertiesFormSubmit = () => {
    let finJSON = { ...(props.finalJSON !== null ? props.finalJSON : {}) };
    // finJSON["nonTemplateVar"] = {
    //   ...props.finalJSON["nonTemplateVar"],
    // };
    checkedVars.forEach((oldVar) => {
      let vrbl = oldVar;
      //#region handle variable name change
      let shouldSubmit = true;
      if (
        Object.prototype.hasOwnProperty.call(changedIds, oldVar) &&
        changedIds[vrbl] !== ""
      ) {
        if (Object.keys(props.finalJSON).includes(changedIds[vrbl])) {
          shouldSubmit = false;
        } else {
          let checkVars = [...checkedVars];
          checkVars[checkedVars.indexOf(oldVar)] = changedIds[oldVar];
          setCheckedVars(checkVars);

          //TODO - do the replacing the draft js way
          let allVars = document.getElementsByTagName("code");
          //replace all occurance of that variable name to the new variable name
          for (let i = allVars.length - 1; i >= 0; i--) {
            let content = allVars[i].getAttribute("data-content");
            let type = allVars[i].getAttribute("data-type");
            if (type === "variable" && content === `{{${oldVar}}}`) {
              allVars[i].innerHTML = changedIds[oldVar];
              allVars[i].setAttribute(
                "data-content",
                `{{${changedIds[oldVar]}}}`
              );
            }
          }
        }
      }
      //#endregion

      if (shouldSubmit) {
        let variableProperty = {};
        let conditions = handleConditions();
        let selectOptions = convertBasicCSVToArrOfObject(csvForSelect);
        variableProperty.elementType = elementType;
        variableProperty.uuid = finJSON[vrbl]
          ? finJSON[vrbl].uuid
          : generateUUID(finJSON);
        if (elementType === "money") {
          variableProperty.currencyFormat = currencyFormatType.value;
        }
        if (elementType !== "searchBar") {
          variableProperty.elementConfig = {
            ...(elementType !== "select" &&
            elementType !== "checkbox" &&
            elementType !== "radio"
              ? { type: configType }
              : { options: selectOptions }),
            id: vrbl,
            placeholder: placeholders[vrbl] ? placeholders[vrbl] : "",
          };
        } else {
          variableProperty.elementConfig = {
            lookupConfig: handleLookupConfigForSearchBar(vrbl),
            placeholder: placeholders[vrbl] ? placeholders[vrbl] : "",
          };
        }

        if (elementType === "file") {
          variableProperty.selectedFileObj = {};
        }
        if (elementType === "date") {
          variableProperty.format = dateFormat.value;
        }

        variableProperty.validation = {
          required: mandatory.value === "false" ? false : true,
          ...(mandatory.value === "conditional" && {
            ...(Object.keys(conditions).length > 0 && {
              isConditional: true,
              conditions: conditions,
            }),
          }),
          ...(configType === "text" &&
            (validationType.value === "number" ||
              validationType.value === "text" ||
              validationType.value === "none") && {
              isNumeric: validationType.value === "number" ? true : false,
              minLength: minLength !== "" ? parseInt(minLength) : null,
              maxLength: maxLength !== "" ? parseInt(maxLength) : null,
            }),
          ...(varType.value === "email"
            ? { isEmail: true }
            : {
                ...(validationType.value === "regex" &&
                  (configType === "text" || configType === "textarea") && {
                    rejex: regex,
                  }),
              }),
          error: "",
        };

        variableProperty.value = "";
        variableProperty.valid =
          minLength !== "" && parseInt(minLength) > 0
            ? false
            : mandatory.value === "false"
            ? true
            : false;
        variableProperty.touched = false;
        variableProperty.isRequest = isRequestChecked;
        variableProperty.subGroup = selectedSubGroup;

        if (props.nonTemplateVariableArray) {
          // if (props.nonTemplateVariableArray.includes(vrbl)) {
          //   variableProperty.parentGroup = "Non-Template";

          //   finJSON["nonTemplateVar"][vrbl] = variableProperty;
          // } else
          {
            variableProperty.parentGroup = props.parentGroup;
            finJSON[vrbl] = variableProperty;
          }
        } else {
          variableProperty.parentGroup = props.parentGroup;
          finJSON[vrbl] = variableProperty;
        }
      } else {
        // alert("another variable exists with the same name");
        displayVariablesexisitMessage();
      }

      //if old name and new name are not equal, then rename the key in the final JSON
      if (vrbl !== oldVar) {
        Object.defineProperty(
          finJSON,
          vrbl,
          Object.getOwnPropertyDescriptor(finJSON, vrbl)
        );
        delete finJSON[oldVar];
      }
    });

    setFinalJSON(finJSON);

    let depVars = [];
    let varsToPoplt = [];
    Object.keys(finJSON).forEach((e) => {
      if (!depVars.includes(e) && !checkedVars.includes(e)) {
        depVars.push(e);
      }
      if (!varsToPoplt.includes(e)) {
        if (finJSON[e].elementType !== null) {
          varsToPoplt.push(e);
        }
      }
    });

    setDependentVars(depVars);
    setVarsToPopulate(varsToPoplt);

    var conditional = {
      value: "conditional",
      text: "Conditional",
    };
    if (varsToPoplt.length >= 1) {
      if (!containsObject(mandatoryOptions)) {
        let mandOptions = [...mandatoryOptions];
        mandOptions.push(conditional);
        setMandatoryOptions(mandOptions);
      }
    } else {
      setMandatoryOptions(
        mandatoryOptions.filter((item) => {
          return item.value !== "conditional";
        })
      );
    }

    //on submit of every variable, emit the updated json to parent

    props.setFinalJSON(finJSON);
    displaySubmittedMessage();
  };

  const getOperationOptions = (selectedVar, callFrom) => {
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    if (
      finJSON[selectedVar] &&
      finJSON[selectedVar].validation.isNumeric &&
      finJSON[selectedVar].validation.isNumeric === true &&
      selectedVar !== "" &&
      selectedVar !== undefined
    ) {
      return [
        { value: "greaterthan", text: "Greater Than" },
        { value: "lesserthan", text: "Lesser Than" },
        { value: "greaterthanorequal", text: "Greater Than or Equal" },
        { value: "lessthanorequal", text: "Lesser Than or Equal" },
        { value: "notequal", text: "Not Equal" },
        { value: "equal", text: "Equal" },
      ];
    } else if (callFrom === 1) {
      return [
        { value: "and", text: "AND" },
        { value: "or", text: "OR" },
        { value: "contains", text: "Contains" },
        { value: "notcontains", text: "Not Contains" },
        { value: "notequal", text: "Not Equal" },
        { value: "equal", text: "Equal" },
      ];
    } else {
      return [
        { value: "contains", text: "Contains" },
        { value: "notcontains", text: "Not Contains" },
        { value: "notequal", text: "Not Equal" },
        { value: "equal", text: "Equal" },
      ];
    }
  };
  const handleLookupConfigForSearchBar = (vrbl) => {
    let lookupConfig = {};
    if (
      selectedLookupTableName !== undefined &&
      selectedLookupTableName !== null &&
      selectedLookupTableName !== ""
    ) {
      lookupConfig.lookupId = lookupId.value;
      let populateFields = [];
      totalColumns.forEach((e, i) => {
        let populateField = {};
        let obj = {
          varName: e.varName,
          masterDataCol: e.colName,
        };
        populateField = obj;
        populateFields.push(populateField);
      });
      lookupConfig.populateFields = populateFields;
      lookupConfig.data = selectedLookupTableName.library_table.lib_table;
      lookupConfig.updatedData = [];
      lookupConfig.dataColumn = totalColumns.map((e) => e.colName);
      lookupConfig.lookupTableName = selectedLookupTableName.library_table_name;
      lookupConfig.lookupTableID = selectedLookupTableName.library_table_id;
    }
    return lookupConfig;
  };
  const onOperatorsChange = (value, idx) => {
    let operators = [...operatorForVariables];
    operators[idx] = value;

    setOperatorForVariables(operators);
  };
  const onValuesChange = (ev, idx) => {
    let values = [...valuesForVariables];
    values[idx] = ev.target.value;
    setValuesForVariables(values);
  };
  const onPlaceholdersChange = (ev, vrbl) => {
    let plcHldrs = { ...placeholders };
    plcHldrs[vrbl] = ev.target.value;
    setPlaceholders(plcHldrs);
  };
  const onNewNameChange = (ev, vrbl) => {
    let changIds = changedIds;
    changIds[vrbl] = ev.target.value;
    setChangedIds(changIds);
  };
  const handleConditions = () => {
    let conditions = {};
    let conditionArray = [];
    if (Object.keys(props.finalJSON).length > 1) {
      if (conditionType != null) conditions.conditionType = conditionType.value;

      selectedVars.forEach((e, i) => {
        let conditionObject = {};
        conditionObject.id = e.value;
        let finJson = props.finalJSON !== null ? props.finalJSON : {};
        if (
          Object.keys(finJson).length > 0 &&
          !!Object.getOwnPropertyDescriptor(finJson, e.value) &&
          finJson[e.value].validation &&
          finJson[e.value].validation.isNumeric === true
        ) {
          conditionObject.value = parseInt(valuesForVariables[i]);
        } else {
          conditionObject.value = valuesForVariables[i];
        }
        conditionObject.enableField = false;
        if (
          operatorForVariables.length > 0 &&
          operatorForVariables[i] !== undefined
        ) {
          conditionObject.operator = operatorForVariables[i].value;
          conditionArray.push(conditionObject);
          conditions.conditions = conditionArray;
        }
      });
    }
    return conditions;
  };

  const onNonTemplateVariableClick = (variable) => {
    var mesg = window.confirm(
      "Do you want to add this variable into the template"
    );
    if (mesg) {
      if (!Object.keys(props.finalJSON).includes(variable)) {
        let nonTempVarArr = [...props.nonTemplateVariableArray];
        nonTempVarArr.splice(
          props.nonTemplateVariableArray.indexOf(variable),
          1
        );
        setNonTemplateVarArray(nonTempVarArr);
        props.setNonTemplateVariableArray(nonTempVarArr);
      }
      // insertVariable(variable);
      let varJson = props.finalJSON !== null ? props.finalJSON : {};
      const varUUID = generateUUID(varJson);
      props.variableClick(varUUID, variable);
      //TODO - insert variable the draft js way

      // delete varJson["nonTemplateVar"][variable];
      if (!(variable in varJson)) {
        varJson[variable] = {
          uuid: varUUID,
          elementType: null,
          elementConfig: {
            type: null,
            id: null,
            placeholder: null,
          },
          validation: {
            required: null,
            isNumeric: null,
            error: null,
          },
          value: null,
        };
        setFinalJSON(varJson);
        props.setFinalJSON(varJson);
      }
    }
  };

  const onVariableClick = (variable) => {
    // insertVariable(variable);
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    const varUUID = finJSON[variable].uuid;
    if (finJSON[variable]?.elementType === "money") {
      setCurrencyVar(variable);
      setCurrencyPopup(true);
    } else if(finJSON[variable]?.elementType === "date"){
      setDateVar(variable);
      setDatePopUp(true);
    }
    else {
      props.variableClick(varUUID, variable);
    }
    //TODO - insert variable the draft js way
  };
  const onCurrencyInsert = () => {
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    const varUUID = finJSON[currencyVar].uuid;
    props.variableClick(varUUID, currencyVar, null, currencyInWrds);
    setCurrencyVar(null);
    setCurrencyInWrds(false);
    setCurrencyPopup(false);
  };
  const onDateInsert = () => {
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    const varUUID = finJSON[dateVar].uuid;
    props.variableClick(varUUID, dateVar, null, null, dateInWords);
    setDateVar(null);
    setDateInWords(false);
    setDatePopUp(false);
  }
  const onEditVariableName = (variable) => {
    setSelecRenameVar(variable);
    setEditVarNameOpen(true);
  };
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(variablesArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setVariablesArray(items);
    updateVariableJsonOrder(items);
  };
  const updateVariableJsonOrder = (items) => {
    if (items) {
      let sortedVariableJson = {};
      items.map((vrbl, index) => {
        sortedVariableJson[vrbl] = { ...props.finalJSON[vrbl] };
        sortedVariableJson[vrbl].sequenceNo = index + 1;
      });
      setFinalJSON(sortedVariableJson);
      props.setFinalJSON(sortedVariableJson);
    }
  };
  const setElementAndConfigType = () => {
    switch (varType.value) {
      case "text":
        setElementType("input");
        setConfigType("text");
        break;
      case "searchBar":
        setElementType("searchBar");
        setConfigType("");
        break;
      case "file":
        setElementType("input");
        setConfigType("file");
        break;
      case "email":
        setElementType("input");
        setConfigType("email");
        break;
      case "textarea":
        setElementType("textarea");
        setConfigType("text");
        break;
      case "select":
        setElementType("select");
        setConfigType("select");
        break;
      case "radio":
        setElementType("radio");
        setConfigType("radio");
        break;
      case "checkbox":
        setElementType("checkbox");
        setConfigType("checkbox");
        break;
      case "date":
        setElementType("date");
        setConfigType("date");
        break;
      case "password":
        setElementType("password");
        setConfigType("password");
        break;
      default:
        setElementType("text");
        setConfigType("");
        break;
      case "money":
        setElementType("money");
        setConfigType("text");
        setValidationType({
          value: "number",
          text: "Number",
        });
        break;
    }
  };

  const onSignOK = () => {
    let allSigns = [...props.allSignatures];

    let checkedSign = {};
    checkedSign.id = props.allSignatures.length + 1;
    checkedSign.email = "";
    checkedSign.name = "";
    checkedSign.isExternal = isExternal;
    allSigns.push(checkedSign);

    props.setSignatureVariables(allSigns);

    setIsSignOpen(false);
  };
  const onSignOKSlate = () => {
    let allSigns = [...props.allSignatures];
    let checkedSign = {};
    checkedSign.id = generateUUIDSig(allSigns);
    checkedSign.email = "";
    checkedSign.name = "";
    checkedSign.isExternal = isExternal;
    checkedSign.label = sigLabel;
    allSigns.push(checkedSign);

    props.setSignatureVariables(allSigns);
    setIsSignOpen(false);
    setIsExternal(false);
    setSigLabel("");
  };

  const handleChangeRegex = (val) => {
    var isValid = true;
    try {
      new RegExp(val);
    } catch (e) {
      isValid = false;
    }
    if (isValid === false) {
      setinvalidRegex(true);
    } else {
      setinvalidRegex(false);
    }
    setRegex(val);
  };

  const handleRequestFlagChange = () => {
    let totalCols = [...totalColumns];
    for (let i = 0; i < totalCols.length; i++) {
      totalCols[i].varName = "";
    }
    setTotalColumns(totalCols);
  };

  const onCloseRename = () => {
    setRenameVar("");
    setSelecRenameVar("");
    setEditVarNameOpen(false);
  };

  const onRenameSubmit = () => {
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    const varUUID = finJSON[selecRenameVar].uuid;

    let renamedVariables = {};
    Object.keys(finJSON).map((a) => {
      if (a === selecRenameVar) {
        renamedVariables = {
          ...renamedVariables,
          [renameVar.toLowerCase()]: { ...finJSON[a] },
        };
      } else {
        renamedVariables = {
          ...renamedVariables,
          [a]: { ...finJSON[a] },
        };
      }
    });
    let success = false;
    var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!format.test(renameVar)) {
      if (!renameVar.includes(" ")) {
        if (
          !Object.keys(props.finalJSON)
            .map((ek) => {
              return ek.toLowerCase();
            })
            .includes(renameVar)
        ) {
          props.variableRename(varUUID, renameVar);
          success = true;
        } else toast.error("Variables Exists");
      } else toast.error("Cannot Contain Special Character");
    } else toast.error("Cannot Contain Special Character");
    if (success) {
      let sortedVariables = quickSort(
        Object.keys(renamedVariables),
        renamedVariables
      );
      setVariablesArray(sortedVariables);

      setFinalJSON(renamedVariables);
      props.setFinalJSON(renamedVariables);

      setRenameVar("");
      setSelecRenameVar("");
      setEditVarNameOpen(false);
    }
  };

  return (
    <div
      style={{ margin: "5px" }}
      //  className={classes.root}
    >
      {/* <Paper square> */}
      {/* <Tabs
          id={value}
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              display:"none",
              // height: "3px",
            },
          }}
          className="Tabs-margin"
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <StyledTab className="Tabs-background" label="intelli-field" {...a11yProps(0)} />
          <StyledTab
          className="Tabs-background" 
            disabled={propertiesDisabled}
            label="Config"
            {...a11yProps(1)}
          />
        </Ta
        bs> */}
      <div className="Tab-nav-variable">
        <div
          className={value === 0 ? "tabs-active-variable " : "tab-div-variable"}
          onClick={() => handleChange(0)}
        >
          Intelli-field
        </div>
        <div
          className={value === 1 ? "tabs-active-variable " : "tab-div-variable"}
          disabled={propertiesDisabled}
          onClick={
            !propertiesDisabled
              ? () => handleChange(1)
              : () => {
                  toast.error("Select a variable to configure");
                }
          }
        >
          Config
        </div>
      </div>
      {/* </Paper> */}

      {value === 0 ? (
        <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="main-div">
              <Droppable droppableId="variables">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {/* {props.finalJSON
                      ? Object.entries(props.finalJSON).map(([vrbl], idx) => { */}
                    {variablesArray
                      ? variablesArray.map((vrbl, idx) => {
                          // if (vrbl !== "nonTemplateVar")
                          return (
                            <Draggable
                              key={props.finalJSON[vrbl].uuid}
                              draggableId={props.finalJSON[vrbl].uuid}
                              index={idx}
                            >
                              {(provided) => (
                                <div
                                  key={idx}
                                  className="box"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {props.disableVariableEdit === true ? (
                                    <></>
                                  ) : (
                                    <input
                                      type="checkbox"
                                      id="vrbl"
                                      className="Variable-Checkbox"
                                      checked={checkedVars.includes(vrbl)}
                                      value={vrbl}
                                      style={{ margin: "auto 3px auto 3px" }}
                                      onChange={(e) => {
                                        let checkVars = [...checkedVars];
                                        if (!checkVars.includes(vrbl)) {
                                          checkVars.push(vrbl);
                                        } else {
                                          checkVars.splice(
                                            checkVars.indexOf(vrbl),
                                            1
                                          );
                                        }
                                        setCheckedVars(checkVars);
                                      }}
                                    />
                                  )}
                                  <button
                                    className="editor-add"
                                    onClick={() => onVariableClick(vrbl)}
                                    style={{ margin: "3px", float: "left" }}
                                  >
                                    {vrbl}
                                  </button>
                                  {props.disableVariableEdit === true ? (
                                    <></>
                                  ) : (
                                    <>
                                      <button
                                        onClick={() => onEditVariableName(vrbl)}
                                        className="variableEditBtnEditor"
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                      <button
                                        onClick={() =>
                                          onRemoveVariableClick(vrbl)
                                        }
                                        className="variablecloseBtnEditor"
                                      >
                                        <i className="fa fa-close"></i>
                                      </button>
                                    </>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {
                /* <div>
              {props.nonTemplateVariableArray
                ? props.nonTemplateVariableArray.map((vrbl, idx) => (
                    <div key={idx} className="box">
                      <input
                        type="checkbox"
                        id="vrbl"
                        checked={checkedVars.includes(vrbl)}
                        value={vrbl}
                        className="Variable-Checkbox"
                        onChange={(e) => {
                          //see what to do
                          let checkVars = [...checkedVars];
                          if (!checkVars.includes(vrbl)) {
                            checkVars.push(vrbl);
                          } else {
                            checkVars.splice(checkVars.indexOf(vrbl), 1);
                          }
                          setCheckedVars(checkVars);
                        }}
                      />
                      <button
                        className="editor-add"
                        variant="outline"
                        onClick={() => onNonTemplateVariableClick(vrbl)}
                        style={{ margin: "3px", float: "left", color: "white" }}
                      >
                        {vrbl}
                      </button>
                      <button
                        variant="outline-danger"
                        onClick={() => onNonTemplateVariableRemoveClick(vrbl)}
                        className="closeBtnEditor"
                      >
                        <i className="fa fa-close"></i>
                      </button>
                    </div>
                  ))
                : null}
                */

                <div className="variable-div">
                  {props.allowNontemp === true ? (
                    props.disableVariableEdit === true ? (
                      <></>
                    ) : (
                      <>
                        <div className="variables-label-div">
                          <label className="variables-label">
                            Non template variables
                          </label>
                        </div>
                        <input
                          placeholder="Non template variables"
                          onChange={(e) =>
                            setNonTemplateVarName(
                              e.target.value
                                .trim()
                                .replace(/ /g, "")
                                .toLowerCase()
                            )
                          }
                          value={nonTemplateVarName}
                          className="bottom-div"
                        />
                        <button
                          className="add-Variable-btn margin-add-btn"
                          onClick={onNonTemplateVariableAddClick}
                        >
                          Variable
                        </button>
                      </>
                    )
                  ) : null}
                </div>
              }
              {props.allowSignature === true ? (
                <div>
                  <hr />
                  <label className="variables-label">Signatures Block</label>
                  {props.allSignatures
                    ? props.allSignatures.map((sign, idx) => (
                        <div key={idx} className="box">
                          <button
                            onClick={() => onSignatureClick(sign)}
                            className="SignBlockBtn"
                          >
                            Signature {sign.id} {sign.label}
                            {sign.isExternal ? " (External)" : ""}
                          </button>
                          <button
                            onClick={() => onSignatureRemoveClick(sign)}
                            className="closeBtnEditor"
                          >
                            <i className="fa fa-close"></i>
                          </button>
                        </div>
                      ))
                    : null}

                  <hr />
                  <button
                    // style={{ marginRight: "10%" }}
                    className="add-Variable-btn"
                    onClick={() => onCreateSignatureBlockClick()}
                  >
                    Signature
                  </button>
                </div>
              ) : null}
            </div>
          </DragDropContext>
        </>
      ) : value === 1 ? (
        <>
          <div style={{ height: "300px" }}>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <label className="config-label">Configuration for</label>
              {checkedVars.map((vrbl, idx) => (
                <span key={idx}>
                  <label className="label-variable"> '{vrbl}'</label>
                </span>
              ))}
            </div>
            <div className="textbox-align">
              <label className="variables-label">
                Variable Type <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <Select
                maxMenuHeight={120}
                styles={selectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: " #E5E5E5",
                    primary: "#B2D4FD ",
                  },
                })}
                value={varType}
                getOptionLabel={(option) => option.text}
                onChange={(e) => {
                  setVarType(e);
                }}
                options={varTypeOptions}
              ></Select>
            </div>

            {varType.value !== null &&
            (varType.value === "text" ||
              varType.value === "textarea" ||
              varType.value === "money") ? (
              <div>
                <div className="textbox-align">
                  <label className="variables-label">Min Length</label>
                  <input
                    className="Textbox-editor"
                    value={minLength}
                    type="number"
                    placeholder="Min Length"
                    onChange={(e) => setMinLength(e.target.value)}
                  />
                </div>
                <div className="textbox-align">
                  <label className="variables-label">Max Length</label>
                  <input
                    value={maxLength}
                    placeholder="Max Length"
                    type="number"
                    onChange={(e) => setMaxLength(e.target.value)}
                    className="Textbox-editor"
                  />
                </div>
                {varType.value !== "money" ? (
                  <div className="textbox-align">
                    <label className="variables-label">
                      Validation Type{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>
                    <Select
                      maxMenuHeight={120}
                      value={validationType}
                      styles={selectStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: " #E5E5E5",
                          primary: "#B2D4FD ",
                        },
                      })}
                      onChange={(e) => {
                        setValidationType(e);
                      }}
                      getOptionLabel={(option) => option.text}
                      options={validationTypeOptions}
                    ></Select>
                  </div>
                ) : (
                  <div className="textbox-align">
                    <label className="variables-label">
                      Format Type{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>
                    <Select
                      maxMenuHeight={120}
                      value={currencyFormatType}
                      styles={selectStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: " #E5E5E5",
                          primary: "#B2D4FD ",
                        },
                      })}
                      onChange={(e) => {
                        setCurrencyFormatType(e);
                      }}
                      getOptionLabel={(option) => option.text}
                      options={CurnFormatTypeOptions}
                    ></Select>
                  </div>
                )}
              </div>
            ) : null}

            {varType.value !== null && varType.value === "date" ? (
              <div className="textbox-align">
                <label className="variables-label">
                  Date Format <i className="fa fa-asterisk aster-risk-Icon"></i>
                </label>
                <Select
                  maxMenuHeight={120}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: " #E5E5E5",
                      primary: "#B2D4FD ",
                    },
                  })}
                  value={dateFormat}
                  styles={selectStyles}
                  onChange={(e) => {
                    setDateFormat(e);
                  }}
                  getOptionLabel={(option) => option.text}
                  options={validDateFormats}
                ></Select>
              </div>
            ) : null}

            {validationType != null &&
            validationType.value === "regex" &&
            varType.value !== "email" ? (
              <div className="textbox-align">
                <label className="variables-label">
                  RegEx <i className="fa fa-asterisk aster-risk-Icon"></i>
                </label>
                <input
                  className="Textbox-editor"
                  value={regex}
                  placeholder="RegEx"
                  onChange={(e) => handleChangeRegex(e.target.value)}
                />
                {invalidRegex ? (
                  <label className="invalid_regex">Invalid Regex</label>
                ) : null}
              </div>
            ) : null}
            {varType.value === "select" ||
            varType.value === "radio" ||
            varType.value === "checkbox" ? (
              <div className="textbox-align">
                <label className="variables-label">
                  Options as comma seperated values{" "}
                  <i className="fa fa-asterisk aster-risk-Icon"></i>
                </label>
                <input
                  className="Textbox-editor"
                  value={csvForSelect}
                  placeholder="Comma seperated values"
                  onChange={(e) => setCsvForSelect(e.target.value)}
                />
              </div>
            ) : null}

            {/* {varType.value === "date" ? (
            <div className="textbox-align">
            
              <label className="variables-label">Date</label>
              <input
                className="Textbox-editor"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          ) : null} */}
            {varType.value !== "searchBar" ? (
              <div className="textbox-align">
                <label className="variables-label">
                  Is Mandatory?{" "}
                  <i className="fa fa-asterisk aster-risk-Icon"></i>
                </label>
                <Select
                  maxMenuHeight={120}
                  value={mandatory}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: " #E5E5E5",
                      primary: "#B2D4FD ",
                    },
                  })}
                  styles={selectStyles}
                  getOptionLabel={(option) => option.text}
                  onChange={(e) => {
                    setMandatory(e);
                  }}
                  options={mandatoryOptions}
                />
              </div>
            ) : null}

            {mandatory != null && mandatory.value === "conditional" ? (
              <div className="textbox-align">
                <label className="variables-label">
                  Dependent Variables{" "}
                  <i className="fa fa-asterisk aster-risk-Icon"></i>
                </label>
                <Select
                  maxMenuHeight={120}
                  styles={selectStyles}
                  isClearable={true}
                  isMulti
                  value={selectedVars}
                  // getOptionLabel={(option) => option.text}
                  //getOptionValue={(option) => option}
                  onChange={(e) => {
                    setSelectedVars(e);
                  }}
                  options={variablesForCondition}
                />
                {selectedVars.length > 1 ? (
                  <div className="textbox-align">
                    <label className="variables-label">Condition</label>
                    <Select
                      maxMenuHeight={120}
                      value={conditionType}
                      styles={selectStyles}
                      onChange={(e) => {
                        setConditionType(e);
                      }}
                      options={[
                        { value: "AND", label: "AND" },
                        { value: "OR", label: "OR" },
                      ]}
                    />
                  </div>
                ) : null}

                {selectedVars.map((selectedVar, idx) => {
                  return (
                    <div className="textbox-align" key={idx}>
                      <label className="variables-label">
                        Operator for "{selectedVar.value}"
                      </label>
                      <Select
                        maxMenuHeight={120}
                        getOptionLabel={(option) => option.text}
                        getOptionValue={(option) => option.value}
                        styles={selectStyles}
                        options={getOperationOptions(selectedVar.value, 2)}
                        //TODO
                        onChange={(e) => {
                          onOperatorsChange(e, idx);
                        }}
                        value={operatorForVariables[idx]}
                      />

                      <label className="variables-label">
                        Value for "{selectedVar.value}"
                      </label>
                      <input
                        className="Textbox-editor"
                        onChange={(e) => onValuesChange(e, idx)}
                        value={valuesForVariables[idx]}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
            {checkedVars.map((vrbl, idx) => (
              <div className="textbox-align" key={idx}>
                <label className="variables-label">
                  Placeholder for '{vrbl}'
                  <i className="fa fa-asterisk aster-risk-Icon"></i>
                </label>
                <input
                  placeholder="Placeholder"
                  className="Textbox-editor"
                  onChange={(e) => onPlaceholdersChange(e, vrbl)}
                  value={placeholders[vrbl]}
                />
                {/* <div className="textbox-align">
            
              <label className="variables-label"
              >
                Rename '{vrbl}'
              </label>
              <input
                className="Textbox-editor"
                onChange={(e) => onNewNameChange(e, vrbl)}
                value={changedIds[vrbl]}
              /> 
            </div> */}
              </div>
            ))}
            {props.dataPath === "templateVariables" ||
            props.dataPath === "clauseVariables" ? (
              <div className="Isrequest-label">
                Request is required?
                <input
                  className="InputVariable"
                  type="checkbox"
                  id="checkbox"
                  color="#48336e"
                  checked={isRequestChecked}
                  onChange={(e) => {
                    setIsRequestChecked(!isRequestChecked);
                    handleRequestFlagChange();
                  }}
                />
                <label className="inputLable">
                  {isRequestChecked.toString()}
                </label>
              </div>
            ) : null}

            {/* <div className="textbox-align">
            
              <label className="variables-label">
                Select or create Sub Group
              </label>
              <Select
                maxMenuHeight={120}
                styles={selectStyles}
                taggable
                push-tags
                onChange={(e) => {
                  setSelectedSubGroup(e.value);
                }}
                value={selectedSubGroup}
                options={subGroups}
                defaultValue={subGroups.length > 0 ? subGroups[0] : null}
              />
            </div> */}
            {varType.value === "searchBar" ? (
              <div>
                <div className="textbox-align">
                  <label className="variables-label">
                    Select table name to populate data for search bar{" "}
                    <i className="fa fa-asterisk aster-risk-Icon"></i>
                  </label>
                  <Select
                    maxMenuHeight={120}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: " #E5E5E5",
                        primary: "#B2D4FD ",
                      },
                    })}
                    styles={selectStyles}
                    onChange={(e) => {
                      setSelectedLookupTableName(e);
                    }}
                    getOptionLabel={(option) => option.library_table_name}
                    getOptionValue={(option) => option.library_table_name}
                    options={lookupTableNames}
                    value={selectedLookupTableName}
                  />
                </div>

                {selectedLookupTableName !== null ? (
                  <>
                    <div className="textbox-align">
                      <label className="variables-label">
                        Select column name for searching{" "}
                        <i className="fa fa-asterisk aster-risk-Icon"></i>
                      </label>
                      <Select
                        maxMenuHeight={120}
                        styles={selectStyles}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: " #E5E5E5",
                            primary: "#B2D4FD ",
                          },
                        })}
                        options={dataColumns.map((e) => ({
                          label: e,
                          value: e,
                        }))}
                        onChange={(e) => {
                          setLookupId(e);
                        }}
                        value={lookupId}
                      />
                    </div>

                    {totalColumns.map((selCol, idx) => (
                      <div key={idx} className="searchBar-div">
                        <div>
                          <div style={{ textAlign: "right", margin: "10px" }}>
                            <button
                              className="TPsearchCloseBtnEditor"
                              onClick={(e) =>
                                onRemoveColumnForSearchbarClick(selCol)
                              }
                            >
                              <i className="fa fa-close"></i>
                            </button>
                          </div>
                          <div style={{ padding: "5px" }}>
                            <label className="search-select">
                              Select master col{" "}
                              <i className="fa fa-asterisk aster-risk-Icon"></i>
                            </label>
                            <Select
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: " #E5E5E5",
                                  primary: "#B2D4FD ",
                                },
                              })}
                              maxMenuHeight={120}
                              placeholder={"select"}
                              styles={selectStyles}
                              options={dataColumns.map((e) => ({
                                label: e,
                                value: e,
                              }))}
                              value={{
                                label: selCol.colName,
                                value: selCol.colName,
                              }}
                              onChange={(e) => {
                                let totalCols = [...totalColumns];
                                totalCols[idx].colName = e.value;
                                setTotalColumns(totalCols);
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ padding: "5px" }}>
                          <label className="search-select">
                            Select variable{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i>
                          </label>

                          <Select
                            maxMenuHeight={120}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: " #E5E5E5",
                                primary: "#B2D4FD ",
                              },
                            })}
                            placeholder="select"
                            styles={selectStyles}
                            className="variable-textbox"
                            options={Object.keys(props.finalJSON)
                              .filter((jObj) => {
                                if (!isRequestChecked) {
                                  if (
                                    props.finalJSON[jObj].isRequest === false
                                  ) {
                                    return true;
                                  }
                                } else if (
                                  props.finalJSON[jObj].isRequest === true
                                ) {
                                  return true;
                                }
                              })
                              .map((e) => ({
                                label: e,
                                value: e,
                              }))}
                            // defaultValue={selCol.varName}
                            value={{
                              label: selCol.varName,
                              value: selCol.varName,
                            }}
                            onChange={(e) => {
                              let totalCols = [...totalColumns];
                              totalCols[idx].varName = e.value;
                              setTotalColumns(totalCols);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <div style={{ textAlign: "center" }}>
                      <button
                        className="variableAddColumm"
                        onClick={(e) => onAddColumnForSearchbarClick()}
                      >
                        Add Column
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}

            <div style={{ textAlign: "center" }}>
              <button
                className="submitbtneditor"
                onClick={onVariablePropertiesFormSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      ) : null}

      {isSignOpen ? (
        <div className="modal">
          <div className="modal-content">
            {props.allSignatures !== undefined &&
            props.allSignatures !== null ? (
              <>
                <div className="header-modal">
                  <h5 className="header-title">
                    Signer {props.allSignatures.length + 1}
                  </h5>
                </div>
                <div className="div-display">
                  <label className="label-class">Label</label>
                  <input
                    type="input"
                    id="external"
                    className="textbox-input_1"
                    color="#48336e"
                    placeholder="Label"
                    value={sigLabel}
                    onChange={(e) => {
                      setSigLabel(e.target.value);
                    }}
                  />
                </div>
                <div className="signature-container">
                  <input
                    type="checkbox"
                    id="external"
                    className="signatureCheckBox"
                    color="#48336e"
                    checked={isExternal}
                    value={isExternal}
                    onChange={() => {
                      setIsExternal(!isExternal);
                    }}
                  />
                  <label className="Sign-div">Is External</label>
                </div>
              </>
            ) : null}

            <div className="footer-modal">
              <button
                className="close-btn"
                type="button"
                onClick={() => setIsSignOpen(false)}
              >
                Close
              </button>
              <button
                className="Modal-btn"
                type="button"
                onClick={() => onSignOKSlate()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {editVarNameOpen ? (
        <div className="modal">
          <div className="modal-content">
            <>
              <div className="header-modal">
                <h5 className="header-title">Rename {selecRenameVar}</h5>
              </div>
              <div className="div-display">
                <label className="label-class">Rename</label>
                <input
                  type="input"
                  id="external"
                  className="textbox-input_1"
                  color="#48336e"
                  placeholder="Rename"
                  value={renameVar}
                  onChange={(e) => {
                    setRenameVar(e.target.value?.toLowerCase());
                  }}
                />
              </div>
            </>

            <div className="footer-modal">
              <button
                className="close-btn"
                type="button"
                onClick={() => onCloseRename()}
              >
                Close
              </button>
              <button
                className="Modal-btn"
                type="button"
                onClick={() => onRenameSubmit()}
                disabled={renameVar && renameVar.trim() !== "" ? false : true}
              >
                Rename
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {currencyPopup ? (
        <div className="modal">
          <div className="modal-content">
            <>
              <div className="header-modal">
                <h5 className="header-title">Currency in words</h5>
              </div>
              <div className="currency-popup-div">
                <label className="label-class">Convert to words ?</label>
                <input
                  type="checkbox"
                  id="currency"
                  className="currency-popup-input"
                  color="#48336e"
                  checked={currencyInWrds}
                  onChange={(e) => {
                    setCurrencyInWrds(e.target.checked);
                  }}
                />
              </div>
            </>

            <div className="footer-modal">
              <button
                className="close-btn"
                type="button"
                onClick={() => onCurrencyInsert()}
              >
                Insert
              </button>
            </div>
          </div>
        </div>
      ) : null}
        {datePopUp ? (
        <div className="modal">
          <div className="modal-content">
            <>
              <div className="header-modal">
                <h5 className="header-title">Date in words</h5>
              </div>
              <div className="currency-popup-div">
                <label className="label-class">Convert to words ?</label>
                <input
                  type="checkbox"
                  id="date"
                  className="currency-popup-input"
                  color="#48336e"
                  checked={dateInWords}
                  onChange={(e) => {
                    setDateInWords(e.target.checked);
                  }}
                />
              </div>
            </>

            <div className="footer-modal">
              <button
                className="close-btn"
                type="button"
                onClick={() => onDateInsert()}
              >
                Insert
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
