//import "tippy.js/dist/tippy.css";
import "./index.css";
import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import { useState, useCallback, useRef, useEffect } from "react";
import {
  createPlateUI,
  HeadingToolbar,
  Plate,
  createAlignPlugin,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  // createCodeBlockPlugin,
  createCodePlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createKbdPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  //createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  //createDndPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  createComboboxPlugin,
  createMentionPlugin,
  createIndentPlugin,
  createIndentListPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createDeserializeMdPlugin,
  createDeserializeCsvPlugin,
  createNormalizeTypesPlugin,
  createFontSizePlugin,
  createHorizontalRulePlugin,
  createPlugins,
  //createDeserializeDocxPlugin,
  //createJuicePlugin,
  createLineHeightPlugin,
  createTabbablePlugin,
  // useEditorRef,
  usePlateEditorRef,
  createPlateEditor,
  createPluginFactory,
  PlateProvider,
  ELEMENT_PARAGRAPH,
  ELEMENT_H1,
  SearchHighlightToolbar,
  createFindReplacePlugin,
  createDeserializeHtmlPlugin,
  usePlateEditorState,
  findNode,
  ELEMENT_LI,
  ELEMENT_CODE_BLOCK,
  createFontFamilyPlugin,
  createFontWeightPlugin,
  StyledElement,
  withProps,
  setMarks,
  createCommentsPlugin,
  findDescendant,
  replaceNodeChildren,
  removeNodes,
  removeNodeChildren,
  findNodePath,
  cleanDocx,
  ELEMENT_OL,
  ELEMENT_UL,
  ELEMENT_LIC,
} from "@udecode/plate";
import {
  // BasicElementToolbarButtons,
  // MarkBallonToolbar,
  ToolbarButtons,
} from "../config/components/Toolbars";
import { withStyledPlaceHolders } from "../config/components/withStyledPlaceHolders";
//import { withStyledDraggables } from "../config/components/withStyledDraggables";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CONFIG } from "../config/config";
import CommentedText from "../config/commentsComponent/CommentedText";
import ConditionText from "../config/conditionComponent/ConditionText";
import { getCommentThreadsOnTextNode } from "../config/utils/EditorCommentUtils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import CommentThreadPopover from "../config/commentsComponent/CommentThreadPopover";
import {
  activeCommentThreadIDAtom,
  activeConditionThreadIDAtom,
  commentSelector,
} from "../config/utils/CommentState";
// import CommentsSidebar from "../config/commentsComponent/CommentsSidebar";
import { initializeStateWithAllCommentThreads } from "../config/utils/EditorCommentUtils";
import useSelection from "../config/hooks/useSelection";
import useAddCommentThreadCallback from "../config/hooks/useAddCommentThreadCallback";
import { commentThreadIDsState } from "../config/utils/CommentState";
import SignatureBlock from "../config/Signatureblock";
import { serialize, serializeContract } from "./slateHelper/serializeHtml";
import { deserialize, deserializeContract } from "./slateHelper/deserialize";
import ConditionModel from "../app/conditions/ConditionModel";
import ClausesModel from "../app/tempclauses/ClauseModel";
import TabForVariables from "../app/variables/TabForVariables";
import TabForParties from "../app/variables/TabForParties";
import {
  // createEditor,
  Editor,
  Transforms,
} from "slate";
import { toggleMarkCustom } from "../config/utils/SlateUtilityFunctions";
import {
  // withReact,
  ReactEditor,
} from "slate-react";
import ConditionThreadPopover from "../config/conditionComponent/ConditionThreadPopover";
import { deleteFromObject } from "./slateHelper/deleleHelper";
//import { createDndPlugin } from "@udecode/plate-dnd";
import { createJuicePlugin } from "@udecode/plate-juice";
import { createCustomPlugin } from "./CustomPlugins/CreateCustomPlugin";
import { createRightIndentCustomPlugin } from "./CustomPlugins/CreateRightIndentCustomPlugin";
import { toast } from "react-toastify";
import { createDeserializeDocxPlugin } from "./CustomDocxSerializer/deserializer/createDeserializeDocxPlugin";
import { createDeserializeHtmlPluginCustom } from "./CustomDocxSerializer/deserializer/createDeserializeHtmlPluginCustom";
import { createListPlugin } from "./CustomList/createListPlugin";
let components = createPlateUI({
  // customize your components by plugin key
  // [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
  //   as: "p",
  //   style: {
  //     // style: {
  //     marginBottom: "0px",
  //     marginTop: "0px",
  //     // fontSize: '1.875em',
  //     // fontWeight: '500',
  //     // lineHeight: '1.3',
  //     // },
  //   },
  // }),
});
//components = withStyledPlaceHolders(components);
//components = withStyledDraggables(components);

const Element = (props) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "clause":
      return (
        <clause
          data-type="clause"
          contentEditable="false"
          clauseId={element.data.clause.clauseID}
          readOnly={true}
          style={{ color: "#B74878" }}
        >
          <p>{children}</p>
        </clause>
      );
    // case "signature":
    //   return (
    //     <>
    //       <SignatureBlock />
    //       {children}
    //     </>
    //   );
    case "div":
      return <div {...attributes}>{children}</div>;
    case "table":
      return (
        <table {...attributes} style={{ border: "2px solid black" }}>
          {children}
        </table>
      );
    case "tr":
      return (
        <tr {...attributes} style={{ border: "2px solid black" }}>
          {children}
        </tr>
      );
    case "td":
      return (
        <td {...attributes} style={{ border: "2px solid black" }}>
          {children}
        </td>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.variable) {
    children = (
      <mark
        data-type={leaf.variable["data-type"]}
        contentEditable="false"
        uuid={leaf.variable["data-variable-id"]}
        data-path={leaf.variable["data-path"]}
        style={{ backgroundColor: "#fcc935" }}
      >
        {children}
      </mark>
    );
  }
  if (leaf.condition) {
    children = (
      <mark
        data-type={leaf.condition["data-type"]}
        //contentEditable="false"
        uuid={leaf.condition["data-condition-id"]}
        readOnly={true}
        style={{ color: "black" }}
        data-path={leaf.condition["data-path"]}
      >
        {/* {leaf.variable["dataContent"]} */}
        {children}
      </mark>
    );
  }
  if (leaf.signature) {
    children = (
      // <span contentEditable={false} //style={{ userSelect: "none" }}
      // >
      <SignatureBlock children={children} signature={leaf.signature} />
      // </span>
    );
  }

  const commentThreads = getCommentThreadsOnTextNode(leaf);

  if (commentThreads.size > 0) {
    children = (
      <CommentedText
        {...attributes}
        commentThreads={commentThreads}
        textNode={leaf}
      >
        {children}
      </CommentedText>
    );
  }
  return <span {...attributes}>{children}</span>;
};

// const createSignatureplugin = createPluginFactory({
//   key: "signature",
//   component: SignatureBlock,
//   isElement: true,
//   isVoid: true,
//   isInline: true,
// });

const TYPE = "signature";
// const SlateEntityPlugin = createPluginFactory({
//   renderElement: ({ element, attributes, children }) => {
//     if (element.type === TYPE) {
//       return <SignatureBlock>{children}</SignatureBlock>;
//     }
//     return undefined;
//   },
//   inlineTypes: [TYPE],
//   voidTypes: [TYPE],
// });

const PlateEditor = forwardRef((props, ref) => {
  const [search, setSearch] = useState("");
  const plugins = useMemo(
    () =>
      createPlugins(
        [
          createParagraphPlugin(),
          createBlockquotePlugin(),
          createTodoListPlugin(),
          createHeadingPlugin(),
          createImagePlugin(),
          createHorizontalRulePlugin(),
          createLinkPlugin(),
          createTablePlugin(),
          createMediaEmbedPlugin(),
          // createCodeBlockPlugin(),
          createAlignPlugin(CONFIG.align),
          createBoldPlugin(),
          createCodePlugin(),
          createItalicPlugin(),
          createHighlightPlugin(),
          createUnderlinePlugin(),
          createStrikethroughPlugin(),
          createSubscriptPlugin(),
          createSuperscriptPlugin(),
          createFontColorPlugin(),
          createFontBackgroundColorPlugin(),
          createFontSizePlugin(),
          createFontFamilyPlugin(),
          createFontWeightPlugin(),
          createKbdPlugin(),
          createNodeIdPlugin(),
          //createDndPlugin(),
          createListPlugin(),
          createIndentListPlugin(CONFIG.indent),
          // createIndentPlugin(CONFIG.indent),
          createCustomPlugin(CONFIG.indent),
          createRightIndentCustomPlugin(CONFIG.rightindent),
          createAutoformatPlugin(CONFIG.autoformat),
          createResetNodePlugin(CONFIG.resetBlockType),
          createSoftBreakPlugin(CONFIG.softBreak),
          createExitBreakPlugin(CONFIG.exitBreak),
          createNormalizeTypesPlugin(CONFIG.forceLayout),
          createTrailingBlockPlugin(CONFIG.trailingBlock),
          createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
          createComboboxPlugin(),
          createMentionPlugin(),
          createDeserializeMdPlugin(),
          createDeserializeCsvPlugin(),
          createDeserializeHtmlPluginCustom(),

          createDeserializeDocxPlugin(),

          //createDeserializeHtmlPlugin(),
          createJuicePlugin(),
          createFindReplacePlugin({ options: { search } }),
          createLineHeightPlugin({
            inject: {
              props: {
                defaultNodeValue: 1,
                validNodeValues: [1, 1.2, 1.5, 2, 3],
                validTypes: [
                  ELEMENT_PARAGRAPH,
                  ELEMENT_H1,
                  ELEMENT_LI,
                  ELEMENT_UL,
                  ELEMENT_OL,
                ],
              },
            },
          }),
          createCommentsPlugin(),
          // createTabbablePlugin({
          //   options: {
          //     query: (editor) => {
          //       const inList = findNode(editor, {
          //         match: { type: ELEMENT_LI },
          //       });
          //       const inCodeBlock = findNode(editor, {
          //         match: { type: ELEMENT_PARAGRAPH },
          //       });
          //       return !inList && !inCodeBlock;
          //     },
          //   },
          // }),
          //createSignatureplugin(),
          // SlateEntityPlugin(),PlateEditor
        ],
        {
          components,
        }
      ),
    [search]
  );
  const slateeditorRef = useRef();
  if (!slateeditorRef.current)
    // slateeditorRef.current = withReact(createEditor());
    slateeditorRef.current = createPlateEditor({
      plugins: plugins,
    });

  const editor = slateeditorRef.current;
  //const editor = createPlateEditor();

  //const editor = usePlateEditorRef();
  const editorRef = useRef(null);
  const editorOffsets = editorRef.current
    ? {
        x: editorRef.current.getBoundingClientRect().x,
        y: editorRef.current.getBoundingClientRect().y,
      }
    : null;

  const activeCommentThreadID = useRecoilValue(activeCommentThreadIDAtom);
  const activeConditionThreadID = useRecoilValue(activeConditionThreadIDAtom);
  const [previousSelection, selection, setSelection] = useSelection(editor);
  const allCommentThreadIDs = useRecoilValue(commentThreadIDsState);
  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);
  const setActiveConditionThreadID = useSetRecoilState(
    activeConditionThreadIDAtom
  );

  const allcomments = useRecoilValue(
    commentSelector(Array.from(allCommentThreadIDs))
  );

  const addCommentThread = useAddCommentThreadCallback();
  const initialValue = [
    {
      type: "p",
      // indent: 5,
      // rightindent: 5,
      children: [
        {
          text: "Create Template",
          fontFamily: "sans-serif",
          fontSize: "11pt",
          lineHeight: "1",
          //backgroundColor: "#FF00FF",
        },
      ],
    },
  ];

  // const renderLeaf = useCallback((props) => {
  //   return <Leaf {...props} />;
  // }, []);
  // const renderElement = useCallback((props) => <Element {...props} />, []);
  const [debugValue, setDebugValue] = useState(
    props.contractTemplateJsonData === null
      ? initialValue
      : props.serializeCall === "serializeTemplate"
      ? deserialize(
          new DOMParser().parseFromString(
            props.contractTemplateJsonData,
            "text/html"
          ).body,
          {},
          props.clauseLibrary
        )
      : deserializeContract(
          new DOMParser().parseFromString(
            props.contractTemplateJsonData,
            "text/html"
          ).body,
          {}
        )
  );
  const [finalJSON, setFinalJSON] = useState({});
  const [varsToPopulate, setVarsToPopulate] = useState(null);
  const [isConditionOpen, setIsConditionOpen] = useState(false);
  const [conditionJson, setconditionJson] = useState(props.conditions);
  const [selectedTextCondition, setSelectedTextCondition] = useState("");

  const [selectedClause, setSelectedClause] = useState(null);
  const [isClauseOpen, setisClauseOpen] = useState(false);
  const [prevSelectionSlate, setprevSelectionSlate] = useState();
  const [editorState, setEditorState] = useState(debugValue);
  const [conditionToggle, setconditionToggle] = useState(true);
  const [commentToggle, setcommentToggle] = useState(true);
  const [partiesJSON, setPartiesJSON] = useState({});
  const [activeFont, setActiveFont] = useState("11");
  const [pageSize, setPageSize] = useState(
    props?.pageSize ? props?.pageSize : "A4"
  );
  const [updateUI, setUpdateUI] = useState(false);
  const [activeFontFamily, setActiveFontFamily] = useState("sans-serif");
  const [leftIndent, setLeftIndent] = useState(5);
  const [rightIndent, setRightIndent] = useState(5);
  const [pageWidth, setPageWidth] = useState(21);
  const [pageHeight, setPageHeight] = useState("297mm");
  const [pasted, setPasted] = useState(false);
  useEffect(() => {
    setFinalJSON(props.finalJSON !== null ? props.finalJSON : {});
  }, [props.finalJSON]);
  useEffect(() => {
    setPartiesJSON(props.partiesJson ? props.partiesJson : {});
  }, [props.partiesJson]);
  useEffect(() => {
    if (props.canAddComments === true) {
      (async () => {
        let abc = await initializeStateWithAllCommentThreads(
          editor,
          addCommentThread,
          props.commentJSON
        );
      })();
    }
  }, [editor, addCommentThread]);

  useEffect(() => {
    let temapletData =
      props.contractTemplateJsonData === null
        ? initialValue
        : props.serializeCall === "serializeTemplate"
        ? deserialize(
            new DOMParser().parseFromString(
              props.contractTemplateJsonData,
              "text/html"
            ).body,
            {},
            props.clauseLibrary
          )
        : deserializeContract(
            new DOMParser().parseFromString(
              props.contractTemplateJsonData,
              "text/html"
            ).body,
            {}
          );
    // &&
    //
    //   deserializeContract(
    //     new DOMParser().parseFromString(
    //       props.contractTemplateJsonData,
    //       "text/html"
    //     ).body,
    //     {}
    //   )
    // );
    setDebugValue(temapletData);
  }, [props.contractTemplateJsonData]);

  useEffect(() => {
    switch (pageSize) {
      case "A3":
        setPageHeight("420mm");
        setPageWidth(29.7);
        break;
      case "A4":
        setPageHeight("297mm");
        setPageWidth(21);
        break;
      case "A5":
        setPageHeight("210mm");
        setPageWidth(14.8);
        break;
      case "Legal":
        setPageHeight("356mm");
        setPageWidth(21.6);
        break;
      case "Letter":
        setPageHeight("279mm");
        setPageWidth(21.6);
        break;
    }
  }, [pageSize]);

  useImperativeHandle(ref, () => ({
    serailizedData() {
      let serialised = serialize({ children: debugValue });

      return serialised;
      //props.setContractTemplateJsonData(serialised);
    },
    serailizedContractData() {
      let serialised = serializeContract({ children: debugValue });
      return serialised;
      //props.setContractTemplateJsonData(serialised);
    },
    getComments() {
      return allcomments;
      //props.setCommentsJson(allcomments);
    },
    getConditions() {
      return conditionJson;
      //props.setConditions(conditionJson);
    },
    getSigValid() {
      let valid = true;
      props?.signatureVariables &&
        props?.signatureVariables?.map((a) => {
          const sigvalid = findDescendant(editor, {
            at: [],
            match: (node) =>
              node.signature ? node.signature["id"] === a.id : null,
            mode: "all",
          });
          if (!sigvalid) {
            valid = false;
          }
        });
      return valid;
    },
    setEditorState(val) {
      setDebugValue(
        deserializeContract(
          new DOMParser().parseFromString(val, "text/html").body,
          {}
        )
      );
    },
    getPageSize() {
      return pageSize;
    },
  }));
  const setConditionToggle = (val) => {
    setconditionToggle(val);
  };
  const setCommentToggle = (val) => {
    setcommentToggle(val);
  };
  const setActiveCommentID = (val) => {
    setActiveCommentThreadID(val);
  };
  const onClauseOK = () => {
    const document = new DOMParser().parseFromString(
      selectedClause.clauseHtml.clauseHtml,
      "text/html"
    );
    const deserialized = deserialize(document.body, {}, props.clauseLibrary);
    const { selection } = editor;

    const curnNode = editor.children[selection.anchor.path[0]];
    if(curnNode.type === "ol" || curnNode.type === "ul"){
      setMarks(editor,{clause:{
        clauseID: selectedClause.clauseId,
        "data-type": "clause",
      }}
    )
    }
    Editor.insertNode(editor, {
      type: "clause",
      children: deserialized,
      data: {
        clause: {
          clauseID: selectedClause.clauseId,
          "data-type": "clause",
        },
      },
      at: selection,
    });
    const selectionforclause = editor.selection.anchor.path[0] - 1;
    if (
      editor?.children[selectionforclause]?.children.length === 1 &&
      editor?.children[selectionforclause]?.children[0].text === ""
    ) {
      Transforms.removeNodes(editor, {
        at: [selectionforclause],
      });
    }
    setisClauseOpen(false);
  };
  const deleteCondition = (uuid) => {
    setconditionJson(deleteFromObject(uuid, conditionJson));
  };
  const deleteVariable = (editor, uuid) => {
    Transforms.removeNodes(editor, {
      at: [],
      match: (node) =>
        node.variable ? node.variable["data-variable-id"] === uuid : null,
      mode: "all",
    });
  };
  const updateSignature = (sigID) => {
    Transforms.setNodes(
      editor,
      {
        signature: { id: sigID - 1 },
      },
      {
        at: [],
        match: (node) =>
          node.signature ? node.signature["id"] === sigID : null,
        mode: "all",
      }
    );
  };

  const removeSignature = (sigID) => {
    Transforms.removeNodes(editor, {
      at: [],
      match: (node) => (node.signature ? node.signature["id"] === sigID : null),
      mode: "all",
    });
  };

  const variableClick = (varID, variable, dataPath, curnInWords, dateInWords) => {
    const selection = editor.selection;
    let lastChild = false,
      firstChild = false;
    let nextChild = null;

    Transforms.insertNodes(editor, {
      text: variable,
      fontSize: activeFont + "pt",
      fontFamily: activeFontFamily,
      variable: {
        variable: true,
        "data-variable-id": varID,
        "data-type": "variable",
        dataContent: "{{" + variable + "}}",
        "data-path": dataPath ? dataPath : props.dataPath,
        "currency-in-words": curnInWords && curnInWords !== null ? curnInWords : false,
        "date-in-words": dateInWords && dateInWords !== null ? dateInWords : false
      },
    },
    {
      select: true,
    }
  );

    let lastButOneNode = null;
    for (
      let index = 0;
      index < editor.selection.anchor.path?.length - 1;
      index++
    ) {
      lastButOneNode = lastButOneNode
        ? lastButOneNode.children[editor.selection.anchor.path[index]]
        : editor.children[editor.selection.anchor.path[index]];
    }
    if (
      lastButOneNode?.children?.length ===
      editor.selection.anchor.path[editor.selection.anchor.path.length - 1] + 1
    ) {
      lastChild = true;
    }
    if (editor.selection.anchor.path[1] === 0) {
      firstChild = true;
    }
    if (lastChild === false) {
      nextChild =
        editor.children[editor?.selection?.anchor?.path[0]]?.children[
          editor?.selection?.anchor?.path[1] + 1
        ];
    }

    const curn = editor.children[editor.selection.anchor.path[0]];
    if (curn.type !== "table" && curn.type !== "ol" && curn.type !== "ul") {
      const prevNode =
        editor.children[editor?.selection?.anchor?.path[0]]?.children[
          editor?.selection?.anchor?.path[1] - 1
        ];
      const pNS = prevNode && prevNode?.text?.includes(" ") ? true : false;
      if (selection && !pNS && prevNode) {
        //firstChild
        Transforms.insertNodes(
          editor,
          {
            text: " ",
            fontFamily: activeFontFamily,
            fontSize: activeFont + "pt",
          },
          {
            at: [selection.anchor.path[0], selection.anchor.path[1]],
            select: true,
          }
        );
      }

      if (lastChild || nextChild?.text?.charAt(0) !== " ") {
        //lastChild
        Transforms.insertNodes(
          editor,
          {
            text: " ",
            fontFamily: activeFontFamily,
            fontSize: activeFont + "pt",
          },
          {
            at: [
              editor.selection.anchor.path[0],
              editor.selection.anchor.path[1] + 1,
            ],
            select: true,
          }
        );
      }
    } else if (curn.type === "table") {
      let befPaths = selection?.anchor?.path?.map((selcPath, ind) => {
        if (ind === selection?.anchor?.path?.length - 1) {
          return selcPath + 1;
        } else return selcPath;
      });
      let afPaths = editor?.selection?.anchor?.path?.map((selcPath, ind) => {
        if (ind === editor?.selection?.anchor?.path?.length - 1) {
          return selcPath + 1;
        } else return selcPath;
      });

      Transforms.insertNodes(
        editor,
        {
          text: " ",
          fontFamily: activeFontFamily,
          fontSize: activeFont + "pt",
        },
        {
          at: befPaths,
          //  [
          //   selection.anchor.path[0],
          //   selection.anchor.path[4] + 1,
          // ],
          select: true,
        }
      );
      Transforms.insertNodes(
        editor,
        {
          text: " ",
          fontFamily: activeFontFamily,
          fontSize: activeFont + "pt",
        },
        {
          at: afPaths,
          // [
          //   editor.selection.anchor.path[0],
          //   editor.selection.anchor.path[4] + 1,
          // ],
          select: true,
        }
      );
    } else {
      let atBefore = [];
      let atAfter = [];
      let prevNode = null;
      let pNS = null;

      for (let index = 0; index < selection.anchor.path.length; index++) {
        if (index === selection.anchor.path.length - 1) {
          atBefore.push(selection.anchor.path[index] + 1);
        } else atBefore.push(selection.anchor.path[index]);
      }
      for (
        let index = 0;
        index < editor.selection.anchor.path.length;
        index++
      ) {
        if (index === editor.selection.anchor.path.length - 1) {
          atAfter.push(editor.selection.anchor.path[index] + 1);
          prevNode =
            prevNode.children[editor?.selection?.anchor?.path[index] - 1];
        } else {
          prevNode = prevNode
            ? prevNode.children[editor?.selection?.anchor?.path[index]]
            : editor.children[editor?.selection?.anchor?.path[index]];
          atAfter.push(editor.selection.anchor.path[index]);
        }
      }
      pNS = prevNode && prevNode?.text?.includes(" ") ? true : false;
      if (selection && !pNS && prevNode) {
        Transforms.insertNodes(
          editor,
          {
            text: " ",
            fontFamily: activeFontFamily,
            fontSize: activeFont + "pt",
          },
          {
            at: selection.anchor.path,
            select: true,
          }
        );
      }
    }
  };
  const variableRename = (varID, variable) => {
    const renameVariables = Editor.nodes(editor, {
      at: [],
      match: (node) =>
        node.variable && node.variable["data-variable-id"] === varID,
      mode: "lowest",
    });

    let textNodeEntry = renameVariables.next().value;
    while (textNodeEntry != null) {
      let selc = {
        anchor: {
          path: textNodeEntry[1],
          offset: 0,
        },
        focus: {
          path: textNodeEntry[1],
          offset: textNodeEntry[0]?.text?.length,
        },
      };
      Transforms.insertNodes(
        editor,
        {
          ...textNodeEntry[0],
          text: variable.toLowerCase(),
        },
        {
          at: selc,
          select: true,
        }
      );

      textNodeEntry = renameVariables.next().value;
    }
  };
  const conditionClick = (varID) => {
    let conditionValue = {
      condition: true,
      "data-condition-id": varID,
      "data-type": "condition",
      "data-path": props.conditiondataPath,
    };
    toggleMarkCustom(editor, "condition", conditionValue);
  };
  const restoreSelection = () => {
    Transforms.select(editor, prevSelectionSlate);
  };

  const onChangeHandler = useCallback(
    (newValue) => {
      //       let serialised = serialize({ children: newValue });
      // console.log("serialised",serialised)
      //console.log("newValue", newValue);
      //console.log("selection", editor.selection);
      if (editor.selection) {
        const node = editor.children[editor.selection.anchor.path[0]];
        if (node && editor.selection.anchor.path[1] !== undefined) {
          if (node.type === "ol" || node.type === "ul") {
            const childNode = Editor.node(editor,editor.selection.focus)
            if(childNode?.length > 0 && childNode[0]?.fontSize){
              setActiveFont(childNode[0].fontSize.split("pt")[0]);
            }
            else if (node.fontSize) {
              setActiveFont(node.fontSize.split("pt")[0]);
            }
            if (childNode?.length > 0 && childNode[0]?.fontFamily) {
              let fontfam = childNode[0].fontFamily.split(",")[0];
              fontfam = fontfam.replace(/"/g, "");
              setActiveFontFamily(fontfam.toLowerCase());
            }
            else if (node.fontFamily) {
              let fontfam = node.fontFamily.split(",")[0];
              fontfam = fontfam.replace(/"/g, "");
              setActiveFontFamily(fontfam.toLowerCase());
            }
          } else {
            const childNode = node?.children[editor.selection.anchor.path[1]];
            if (childNode && childNode.fontSize) {
              setActiveFont(childNode.fontSize.split("pt")[0]);
            }
            if (childNode && childNode.fontFamily) {
              let fontfam = childNode.fontFamily.split(",")[0];
              fontfam = fontfam.replace(/"/g, "");
              setActiveFontFamily(fontfam.toLowerCase());
            }
          }
        }
      }
      // if (pasted) {
      //   Transforms.setNodes(
      //     editor,
      //     { indent: leftIndent },
      //     {
      //       at: [],
      //       match: (node) => node.type && !node.indent,
      //       mode: "all",
      //     }
      //   );
      //   Transforms.setNodes(
      //     editor,
      //     { rightindent: rightIndent },
      //     {
      //       at: [],
      //       match: (node) => node.type && !node.rightindent,
      //       mode: "all",
      //     }
      //   );
      //   setPasted(false);
      // }
      
      setEditorState(newValue);
      setDebugValue(newValue);
      setSelection(editor.selection);
      ReactEditor.focus(editor);
      if (editor.selection !== null) {
        setSelectedTextCondition(Editor.string(editor, editor.selection));
      }
    },
    [setSelection, editor, allcomments]
  );
  const getEditorRef = () => {
    return editor;
  };
  const checkIfNodeExists = (uuid) => {
    const textNodesWithVariables = Editor.nodes(editor, {
      at: [],
      mode: "all",
      match: (node) =>
        node.variable && node.variable["data-variable-id"] === uuid,
    });
    let textNodeEntry = textNodesWithVariables.next().value;
    if (textNodeEntry !== undefined && textNodeEntry !== null) {
      return false;
    } else return true;
  };
  const insertSignatureOnClick = (sign) => {
    Transforms.insertFragment(
      editor,
      [
        {
          type: "p",
          // indent: leftIndent,
          // rightindent: rightIndent,
          children: [
            {
              text: " ",
            },
            {
              text: " ",
              signature: sign,
            },
            {
              text: " ",
            },
          ],
        },
      ]
      // { hanging: true, voids: true, select: true }
    );
  };

  return (
    <>
      <div className="editor-full">
        {props.isVariableDisabled ? null : (
          <div className="variable-Style" id={props.tabForVarId}>
            <TabForVariables
              parentGroup={props.parentGroup}
              finalJSON={props.finalJSON}
              setFinalJSON={(finJson) => {
                setFinalJSON(finJson);
                props.setFinalJSON(finJson);
                setUpdateUI(!updateUI);
              }}
              setVarsToPopulate={(varsToPoplt) => {
                setVarsToPopulate(varsToPoplt);
              }}
              nonTemplateVariableArray={props.nonTemplateVariableArray}
              setNonTemplateVariableArray={(nonTempVarArr) => {
                props.setNonTemplateVariableArray(nonTempVarArr);
              }}
              allSignatures={props.signatureVariables}
              setSignatureVariables={(vars) => {
                props.setSignatureVariables(vars);
              }}
              updateSignature={(sigID) => {
                updateSignature(sigID);
              }}
              // getOperationOptions={getOperationOptions}
              masterLibrary={props.masterLibrary}
              // insertPlaceholder={insertPlaceholder}
              // selectedVariable={selectedVariable}
              removeVariable={(uuid) => {
                deleteVariable(editor, uuid);
              }}
              removeSignature={(sigID) => {
                removeSignature(sigID);
              }}
              variableClick={(varID, variable, dataPath, curnInWords, dateInWords) => {
                variableClick(varID, variable, dataPath, curnInWords, dateInWords);
              }}
              variableRename={(varID, variable) => {
                variableRename(varID, variable);
              }}
              insertSignatureOnClick={(signature) => {
                insertSignatureOnClick(signature);
              }}
              allowNontemp={props.allowNontemp}
              allowSignature={props.allowSignature}
              dataPath={props.dataPath}
              disableVariableEdit={props.disableVariableEdit}
            />
          </div>
        )}
        <div className="editorContent" id={props.id}>
          <div className="plateTool">
            <PlateProvider id={props.id} editor={editor}>
              <HeadingToolbar className="editor-toolbar-icon-size">
                <ToolbarButtons
                  id={props.id}
                  selection={editor.selection}
                  previousSelection={previousSelection}
                  finalJSON={finalJSON}
                  setFinalJSON={(finJSON) => {
                    setFinalJSON(finJSON);
                    props.setFinalJSON(finJSON);
                    setUpdateUI(!updateUI);
                  }}
                  setIsConditionOpen={(val) => {
                    setIsConditionOpen(val);
                  }}
                  selectedTextCondition={selectedTextCondition}
                  setisClauseOpen={(val) => {
                    setisClauseOpen(val);
                  }}
                  setprevSelectionSlate={(val) => {
                    setprevSelectionSlate(val);
                  }}
                  onChangeHandler={(val) => {
                    onChangeHandler(val);
                  }}
                  isNegotiationDisabled={props.isNegotiationDisabled}
                  isVariableDisabled={props.isVariableDisabled}
                  isConditionDisabled={props.isConditionDisabled}
                  isClauseDisabled={props.isClauseDisabled}
                  isConditionToggleDisabled={props.isConditionToggleDisabled}
                  isCommentToggleDisabled={props.isCommentToggleDisabled}
                  dataPath={props.dataPath}
                  setConditionToggle={(val) => setConditionToggle(val)}
                  setCommentToggle={(val) => setCommentToggle(val)}
                  conditionToggle={conditionToggle}
                  commentToggle={commentToggle}
                  activeFont={activeFont}
                  activeFontFamily={activeFontFamily}
                  pageSize={pageSize}
                  setActiveFont={setActiveFont}
                  setActiveFontFamily={setActiveFontFamily}
                  setPageSize={setPageSize}
                />
              </HeadingToolbar>
            </PlateProvider>
          </div>
          <div className="editor-comment" id={"_" + props.id}>
            <DndProvider backend={HTML5Backend}>
              <div className="editor-wrapper">
                {/* <SearchHighlightToolbar icon={"Search"} setSearch={setSearch} /> */}
                <div
                  className="editor"
                  style={{ width: pageWidth + "cm" }}
                  ref={editorRef}
                >
                  <Plate
                    id={props.id}
                    editor={editor}
                    //editableProps={CONFIG.editableProps}
                    initialValue={debugValue}
                    onChange={(newValue) => {
                      onChangeHandler(newValue);
                    }}
                    // renderEditable={renderEditable}
                    // value={editorState}
                    plugins={plugins}
                    //renderLeaf={renderLeaf}
                    //renderElement={renderElement}
                    editableProps={{
                      renderElement: ({ attributes, children, element }) => {
                        switch (element.type) {
                          case "clause":
                            return (
                              <clause
                                data-type="clause"
                                contentEditable={
                                  props.clauseEditable ? "true" : "false"
                                }
                                clauseId={element.data.clause.clauseID}
                                readOnly={true}
                                style={{ color: "#B74878" }}
                              >
                                {children}
                              </clause>
                            );
                          case "div":
                            return <div {...attributes}>{children}</div>;
                          case "table":
                            return (
                              <table
                                {...attributes}
                                // style={{ border: "2px solid black" }}
                              >
                                {children}
                              </table>
                            );
                          case "tr":
                            return (
                              <tr
                                {...attributes}
                                // style={{ border: "2px solid black" }}
                              >
                                {children}
                              </tr>
                            );
                          case "td":
                            return (
                              <td
                                {...attributes}
                                // style={{ border: "2px solid black" }}
                              >
                                {children}
                              </td>
                            );
                          case "h1":
                            return <h1 {...attributes}>{children}</h1>;
                          case "h2":
                            return <h2 {...attributes}>{children}</h2>;
                          case "h3":
                            return <h3 {...attributes}>{children}</h3>;
                          case "h4":
                            return <h4 {...attributes}>{children}</h4>;
                          case "h5":
                            return <h5 {...attributes}>{children}</h5>;
                          case "h6":
                            return <h6 {...attributes}>{children}</h6>;
                          default:
                            // Get existing style or initialize an empty object
                            return <p {...attributes}>{children}</p>;
                        }
                      },
                      renderLeaf: ({ attributes, children, leaf }) => {
                        if (leaf.variable) {
                          children = (
                            <mark
                              data-type={leaf.variable["data-type"]}
                              contentEditable="false"
                              uuid={leaf.variable["data-variable-id"]}
                              data-path={leaf.variable["data-path"]}
                              style={{ backgroundColor: "#fcc935", userSelect: 'text'  }}
                            >
                              {children}
                            </mark>
                          );
                        }
                        if (leaf.condition) {
                          children = (
                            <ConditionText
                              condition={leaf.condition}
                              textNode={leaf}
                              isConditionDisabled={
                                props.isConditionToggleDisabled
                              }
                            >
                              {children}
                            </ConditionText>
                          );
                        }
                        if (leaf.signature) {
                          children = (
                            <SignatureBlock
                              children={children}
                              signature={leaf.signature}
                              label={leaf.label}
                            />
                            // </span>
                          );
                        }
                        if (leaf.ins) {
                          children = <ins className="diffmod">{children}</ins>;
                        }
                        if (leaf.del) {
                          children = <del className="diffmod">{children}</del>;
                        }

                        if (
                          leaf.fontSize ||
                          leaf.fontFamily ||
                          leaf.lineHeight ||
                          leaf.backgroundColor ||
                          leaf.color
                        ) {
                          let lStyle = {};
                          if (leaf.fontSize) {
                            lStyle = { fontSize: leaf.fontSize };
                          }
                          if (leaf.fontFamily) {
                            lStyle = { ...lStyle, fontFamily: leaf.fontFamily };
                          }
                          if (leaf.lineHeight) {
                            lStyle = { ...lStyle, lineHeight: leaf.lineHeight };
                          }
                          if (leaf.backgroundColor) {
                            lStyle = {
                              ...lStyle,
                              backgroundColor: leaf.backgroundColor,
                            };
                          }
                          if (leaf.color) {
                            lStyle = { ...lStyle, color: leaf.color };
                          }
                          children = <span style={lStyle}>{children}</span>;
                        }
                        if (leaf.li) {
                          children = <li {...attributes}>{children}</li>;
                        }
                        const commentThreads =
                          getCommentThreadsOnTextNode(leaf);

                        if (commentThreads.size > 0) {
                          children = (
                            <CommentedText
                              {...attributes}
                              commentThreads={commentThreads}
                              textNode={leaf}
                            >
                              {children}
                            </CommentedText>
                          );
                        }
                        return <span {...attributes}>{children}</span>;
                      },
                      autoCapitalize: true,
                      autoFocus: true,
                      style: {
                        position: "relative",
                        outline: "none",
                        // whiteSpace: "pre-wrap",
                        overflowWrap: "breakWord",
                        background: "white",
                        //width: "21cm",
                        minWidth: pageWidth - 5.08 + "cm",
                        maxWidth: pageWidth - 5.08 + "cm",
                        minHeight: pageHeight,
                        margin: "2.54cm 2.54cm",
                      },
                      className: "page",

                      readOnly: !props.isEditable,
                      onKeyDown: (event) => {
                        
                      //  const nodeAtCursor = Editor.node(editor,editor.selection)
                      //  if(nodeAtCursor[0].variable){
                      //   event.preventDefault();
                      //   return
                      //  }
                        if (
                          ((event.keyCode >= 48 && event.keyCode <= 90) ||
                            (event.keyCode >= 96 && event.keyCode <= 111) ||
                            (event.keyCode >= 186 && event.keyCode <= 222) ||
                            event.keyCode === 32) &&
                          ((!event.metaKey && !event.shiftKey) ||
                            !event.metaKey) &&
                          ((!event.ctrlKey && !event.shiftKey) ||
                            !event.ctrlKey) &&
                          event.keyCode !== 9
                        ) {
                          if (
                            selection?.anchor?.path[
                              selection?.anchor?.path.length - 1
                            ] > 0
                          ) {
                            let node = null;
                            let curNode = null;
                            for (
                              let index = 0;
                              index < selection?.anchor?.path?.length;
                              index++
                            ) {
                              if (
                                index ===
                                selection?.anchor?.path?.length - 1
                              ) {
                                node = node
                                  ? node.children[
                                      selection?.anchor?.path[index] - 1
                                    ]
                                  : editor?.children[
                                      selection?.anchor?.path[index] - 1
                                    ];
                              } else
                                node = node
                                  ? node.children[
                                      selection?.anchor?.path[index]
                                    ]
                                  : editor?.children[
                                      selection?.anchor?.path[index]
                                    ];
                              curNode = curNode
                                ? curNode.children[
                                    selection?.anchor?.path[index]
                                  ]
                                : editor?.children[
                                    selection?.anchor?.path[index]
                                  ];
                            }
                            // const node =
                            //   editor?.children[selection?.anchor?.path[0]]
                            //     ?.children[selection?.anchor?.path[1] - 1];
                            if (
                              curNode?.variable &&
                              selection?.anchor?.offset === 0 &&
                              selection?.focus?.offset === 0
                            ) {
                              let cusSelc = { ...selection };
                              cusSelc.focus.offset = curNode?.text?.length;
                              event.preventDefault();
                              Transforms.insertNodes(
                                editor,
                                {
                                  text: event.key,
                                  fontSize: activeFont + "pt",
                                  fontFamily: activeFontFamily,
                                },
                                {
                                  at: cusSelc,
                                  select: true,
                                }
                              );
                              //Transforms.select(editor,editor.prev)
                            } else if (node?.variable) {
                              event.preventDefault();
                              Transforms.insertNodes(editor, {
                                text: event.key,
                                fontSize: activeFont + "pt",
                                fontFamily: activeFontFamily,
                              },
                              {
                                select: true,
                              }
                            );
                            }
                          } else {
                            let node = null;

                            for (
                              let index = 0;
                              index < selection?.anchor?.path?.length;
                              index++
                            ) {
                              node = node
                                ? node.children[selection?.anchor?.path[index]]
                                : editor?.children[
                                    selection?.anchor?.path[index]
                                  ];
                            }
                            if (node.variable) {
                              event.preventDefault();
                              Transforms.insertNodes(editor, {
                                text: event.key,
                                fontSize: activeFont + "pt",
                                fontFamily: activeFontFamily,
                              },
                            {
                              select: true,
                            });
                            }
                          }
                        } else if (event.keyCode === 46) {
                          const currentNode =
                            editor?.children[selection?.anchor?.path[0]];
                          const childNodetextLen =
                            currentNode?.children[selection?.anchor?.path[1]]
                              ?.text?.length;

                          // for variable

                          let nextChildNode = null;
                          let curChildNode = null;
                          for (
                            let index = 1;
                            index < selection?.anchor?.path?.length;
                            index++
                          ) {
                            if (index === selection?.anchor?.path?.length - 1) {
                              if (
                               (nextChildNode && nextChildNode?.children?.length - 1 >
                                selection?.anchor?.path[index] ) || (currentNode?.children?.length - 1 >
                                  selection?.anchor?.path[index])
                              )
                                nextChildNode = nextChildNode
                                  ? nextChildNode.children[
                                      selection?.anchor?.path[index] + 1
                                    ]
                                  : currentNode.children[
                                      selection?.anchor?.path[index] + 1
                                    ];
                              else nextChildNode = null;
                            } else {
                              nextChildNode = nextChildNode
                                ? nextChildNode.children[
                                    selection?.anchor?.path[index]
                                  ]
                                : currentNode.children[
                                    selection?.anchor?.path[index]
                                  ];
                            }
                            curChildNode = curChildNode
                              ? curChildNode.children[
                                  selection?.anchor?.path[index]
                                ]
                              : currentNode.children[
                                  selection?.anchor?.path[index]
                                ];
                          }
                          
                          if (nextChildNode && nextChildNode.variable) {
                            let cpySelc = selection.anchor.path?.map(
                              (selc, ind) => {
                                if (
                                  ind ===
                                  selection.anchor.path.length - 1
                                ) {
                                  return selc + 1;
                                } else return selc;
                              }
                            );
                            event.preventDefault();
                            Transforms.removeNodes(editor, {
                              at: cpySelc,
                              // [
                              //   selection.anchor.path[0],
                              //   selection.anchor.path[1] - 1,
                              // ],
                              match: (node) =>
                                node.variable
                                  ? node.variable["data-variable-id"] ===
                                  nextChildNode.variable["data-variable-id"]
                                  : null,
                              mode: "all",
                            });
                          }

                          if (
                            editor?.children.length >
                              selection?.anchor?.path[0] + 1 &&
                            currentNode?.children?.length ===
                              selection?.anchor?.path[1] + 1 &&
                            childNodetextLen === selection?.anchor?.offset
                          ) {
                            const nextnode =
                              editor?.children[selection?.anchor?.path[0] + 1];
                            if (nextnode.type === "clause") {
                              Transforms.removeNodes(editor, {
                                at: [selection.anchor.path[0] + 1, 0],
                                match: (node) =>
                                  node?.data?.clause
                                    ? node.data.clause?.clauseID ===
                                    nextnode.data.clause?.clauseID
                                    : null,
                                mode: "all",
                              });
                            }
                          }
                        } else if (event.key === "Backspace") {
                          const { selection } = editor;
                          if (
                            editor &&
                            selection &&
                            selection.anchor !== null &&
                            selection.focus.offset === 0 &&
                            selection.anchor.offset === 0
                          ) {
                            const node =
                              editor.children[selection.anchor.path[0]];
                            const prevNode =
                              editor.children[selection.anchor.path[0] - 1];
                            if (node && prevNode) {
                              if (
                                node.type === "clause" &&
                                prevNode.type === "p"
                              ) {
                                event.preventDefault();
                              } else if (
                                node.type === "p" &&
                                prevNode.type === "clause"
                              ) {
                                Transforms.removeNodes(editor, {
                                  at: [selection.anchor.path[0] - 1, 0],
                                  match: (node) =>
                                  node?.data?.clause
                                    ? node.data.clause?.clauseID ===
                                    prevNode.data.clause?.clauseID
                                    : null,
                                mode: "all",
                                });
                              }
                            }
                            if (prevNode && prevNode.children) {
                              const prevSigNode =
                                prevNode.children[prevNode.children.length - 1];
                              if (prevSigNode.siganture) {
                                Transforms.removeNodes(editor, {
                                  at: [
                                    selection.anchor.path[0] - 1,
                                    prevNode.children.length - 1,
                                  ],
                                });
                              }
                            }
                            if (node) {
                              let childNode = null;
                              for (
                                let index = 1;
                                index < selection.anchor.path.length;
                                index++
                              ) {
                                if (index === selection.anchor.path.length - 1)
                                  childNode = childNode
                                    ? childNode.children[
                                        selection.anchor.path[index] - 1
                                      ]
                                    : node.children[
                                        selection.anchor.path[index] - 1
                                      ];
                                else
                                  childNode = childNode
                                    ? childNode.children[
                                        selection.anchor.path[index]
                                      ]
                                    : node.children[
                                        selection.anchor.path[index]
                                      ];
                              }
                              let cpySelc = selection.anchor.path?.map(
                                (selc, ind) => {
                                  if (
                                    ind ===
                                    selection.anchor.path.length - 1
                                  ) {
                                    return selc - 1;
                                  } else return selc;
                                }
                              );

                              if (childNode && childNode.variable) {
                                event.preventDefault();
                                Transforms.removeNodes(editor, {
                                  at: cpySelc,
                                  // [
                                  //   selection.anchor.path[0],
                                  //   selection.anchor.path[1] - 1,
                                  // ],
                                  match: (node) =>
                                    node.variable
                                      ? node.variable["data-variable-id"] ===
                                        childNode.variable["data-variable-id"]
                                      : null,
                                  mode: "all",
                                });
                                //ReactEditor.focus(editor);
                              }
                            }
                          }
                          if (
                            editor &&
                            selection &&
                            selection.focus.offset === 1 &&
                            selection.anchor.offset === 1
                          ) {
                            const node =
                              editor.children[selection.anchor.path[0]];
                            const curNode =
                              node.children[selection.anchor.path[1]];
                            const childNode =
                              node.children[selection.anchor.path[1] - 1];
                            const varNode =
                              node.children[selection.anchor.path[1] - 2];

                            if (
                              curNode.text &&
                              curNode.text.trim().length === 1
                            ) {
                              Transforms.insertNodes(
                                editor,
                                {
                                  text: "",
                                  fontSize: activeFont + "pt",
                                  fontFamily: activeFontFamily,
                                },
                                {
                                  at: [
                                    selection.anchor.path[0],
                                    selection.anchor.path[1],
                                  ], // +1 wasnt there
                                 // select: true,
                                }
                              );
                            }
                            if (
                              childNode &&
                              childNode.variable &&
                              curNode.text === " "
                            ) {
                              event.preventDefault();
                              Transforms.removeNodes(editor, {
                                at: [
                                  selection.anchor.path[0],
                                  selection.anchor.path[1] - 1,
                                ],
                                match: (node) =>
                                  node.variable
                                    ? node.variable["data-variable-id"] ===
                                      childNode.variable["data-variable-id"]
                                    : null,
                                mode: "all",
                              });
                            }
                            // else if (varNode && varNode.variable && childNode.text === " ") {
                            //   Transforms.removeNodes(editor, {
                            //     at: [
                            //       selection.anchor.path[0],
                            //       selection.anchor.path[1] - 2,
                            //     ],
                            //     match: (node) =>
                            //       node.variable ? node.variable["data-variable-id"] === varNode.variable["data-variable-id"] : null,
                            //     mode: "all",
                            //   });
                            // }
                            if (childNode && childNode.signature) {
                              Transforms.removeNodes(editor, {
                                at: [
                                  selection.anchor.path[0],
                                  selection.anchor.path[1] - 1,
                                ],
                              });
                            }
                          }
                        }
                        if (
                          editor &&
                          editor.selection &&
                          editor.selection.anchor
                        ) {
                          const node =
                            editor.children[editor.selection.anchor.path[0]];
                          if (
                            node &&
                            editor.selection.anchor.path[1] !== undefined
                          ) {
                            if (node.type === "ol" || node.type === "ul") {
                              Transforms.setNodes(
                                editor,
                                {
                                  fontSize: `${activeFont}pt`,
                                  fontFamily: `${activeFontFamily}`,
                                },
                                {
                                  at: editor.selection,
                                  //at:[],
                                  match: (lnode) =>
                                    lnode.type === "ol" || lnode.type === "ul",
                                  mode: "all",
                                  //[selection.anchor.path[0], selection.anchor.path[1]],
                                }
                              );
                            }
                            else {
                           if(editor?.selection?.anchor?.path === 0 && editor?.selection?.focus?.path === 0){
                            Editor.addMark(
                              editor,
                              "fontSize",
                              `${activeFont.replace("pt", "")}pt`
                            );
                            Editor.addMark(
                              editor,
                              "fontFamily",
                              `${activeFontFamily}`
                            );
                          }
                          }
                          }
                          // if (!node.indent) {
                          //   Transforms.setNodes(editor, { indent: leftIndent });
                          // }
                          // if (!node.rightindent) {
                          //   Transforms.setNodes(editor, {
                          //     rightindent: rightIndent,
                          //   });
                          // }
                        }
                      },
                      onPaste: (event, editor, next) => {
                        // setPasted(!pasted);
                        // console.log(
                        //   "PastEvent",
                        //   event.clipboardData.getData("text/html")
                        // );
                        // console.log("pasted editor", editor);
                        //  const rtf = event.clipboardData.getData('text/rtf');
                        //  const data = event.clipboardData.getData("text/html");
                        // console.log(cleanDocx(event.clipboardData.getData("text/html"), rtf))
                      },
                      onKeyUp: (event) => {
                        // if (event.altKey && event.key === "t") {
                        //   Transforms.insertNodes(editor, {
                        //     text: "\u0009".toString(),
                        //   });
                        // }
                        if (
                          event.ctrlKey &&
                          event.key === "Enter" &&
                          editor.selection
                        ) {
                          const node =
                            editor.children[editor.selection?.anchor?.path[0]];
                          // if (!node.indent) {
                          //   Transforms.setNodes(editor, {
                          //     indent: leftIndent,
                          //   });
                          // }
                          // if (!node.rightindent) {
                          //   Transforms.setNodes(editor, {
                          //     indent: rightIndent,
                          //   });
                          // }
                        }
                      },

                      // onClick: () => {
                      //   const { selection } = editor;
                      //   if (selection) {
                      //     const node =
                      //       editor.children[selection.anchor.path[0]];
                      //     const currNode =
                      //       node.children[selection.anchor.path[1]];
                      //     const commentThreads =
                      //       getCommentThreadsOnTextNode(currNode);
                      //     if (commentThreads.size === 0) {
                      //       setActiveCommentThreadID(null);
                      //     }
                      //   }
                      // },
                    }}
                  >
                    {/* <MarkBallonToolbar id={props.id} /> */}
                  </Plate>
                  {props.canAddComments === true && commentToggle === true ? (
                    <>
                      {activeCommentThreadID != null ? (
                        // <div className={"sidebar-wrapper"}>
                        <CommentThreadPopover
                          getEditorRef={getEditorRef}
                          editorOffsets={editorOffsets}
                          threadID={activeCommentThreadID}
                          selection={selection ?? previousSelection}
                          setActiveCommentID={(val) => setActiveCommentID(val)}
                          canComment={props.canComment}
                        />
                      ) : // </div>
                      null}
                    </>
                  ) : (
                    setActiveCommentID(null)
                  )}
                  {conditionToggle === true ? (
                    <>
                      {activeConditionThreadID != null ? (
                        <ConditionThreadPopover
                          getEditorRef={getEditorRef}
                          editorOffsets={editorOffsets}
                          threadID={activeConditionThreadID}
                          selection={selection ?? previousSelection}
                          conditionJson={conditionJson}
                          deleteCondition={(uuid) => deleteCondition(uuid)}
                          deleteDisabled={props.isConditionDisabled}
                        />
                      ) : // </div>
                      null}
                    </>
                  ) : (
                    setActiveConditionThreadID(null)
                  )}
                </div>
              </div>
            </DndProvider>

            {/* {props.canAddComments === true ? (
              <div className={"sidebar-wrapper"}>
                <CommentsSidebar
                  getEditorRef={getEditorRef}
                  onChangeHandler={(val) => {
                    onChangeHandler(val);
                  }}
                  setCommentsJson={(val) => {
                    props.setCommentsJson(val);
                  }}
                />
              </div>
            ) : null} */}
          </div>
        </div>
        {props.isPartiesDisabled ? null : (
          <div className="parties-style">
            <TabForParties
              parentGroup={props.parentGroup}
              //finalJSON={props.finalJSON}
              setPartiesJSON={(partiesJson) => {
                setPartiesJSON(partiesJson);
                props.setPartiesJSON(partiesJson);
              }}
              setVarsToPopulate={(varsToPoplt) => {
                setVarsToPopulate(varsToPoplt);
              }}
              removeVariable={(uuid) => {
                deleteVariable(editor, uuid);
              }}
              variableClick={(varID, variable, dataPath) => {
                variableClick(varID, variable, dataPath);
              }}
              //New Params
              partiesJson={partiesJSON}
              disablePartyConfigure={props.disablePartyConfigure}
              basicDetailsJson={props.basicDetailsJson}
              masterLibrary={props.masterLibrary}
              dataPath={props.dataPath}
              checkIfNodeExists={checkIfNodeExists}
            />
          </div>
        )}
        {props.isConditionDisabled === false ? (
          <ConditionModel
            isConditionOpen={isConditionOpen}
            varsToPopulate={varsToPopulate}
            setConditionOpen={(val) => {
              setIsConditionOpen(val);
            }}
            updateUI={updateUI}
            finalJSON={finalJSON}
            partiesJson={partiesJSON}
            conditionJson={conditionJson}
            setconditionJson={(cJson) => {
              setconditionJson(cJson);
              onChangeHandler(editor.children);
            }}
            conditionClick={(varId) => {
              conditionClick(varId);
              restoreSelection();
            }}
            dataPath={props.dataPath}
          />
        ) : null}
        {props.isClauseDisabled === false ? (
          <ClausesModel
            clauseLibrary={props.clauseLibrary}
            selectedClause={selectedClause}
            setSelectedClause={(val) => {
              setSelectedClause(val);
            }}
            setisClauseOpen={(val) => {
              setisClauseOpen(val);
            }}
            isClauseOpen={isClauseOpen}
            onClauseOK={() => {
              onClauseOK();
              restoreSelection();
            }}
          />
        ) : null}
      </div>
    </>
  );
});

export default PlateEditor;
