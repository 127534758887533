import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { locationData: {} };

export const locationSlice = createSlice({
  name: "locationState",
  initialState: { value: initialStateValue },
  reducers: {
    setLocationData: (state, action) => {
      state.value.locationData = action.payload;
    },
  },
});

export const { setLocationData } = locationSlice.actions;

export default locationSlice.reducer;
