import {
  getNodeEntries,
  getPluginInjectProps,
  setElements,
  unsetNodes,
  withoutNormalizing,
} from "@udecode/plate";
import { KEY_INDENT, RIGHT_KEY_INDENT } from "./CreateCustomPlugin";

/**
 * Add offset to the indentation of the selected blocks.
 */
export const setIndent = (
  editor,
  {
    offset = 1,
    getNodesOptions,
    setNodesProps,
    unsetNodesProps = [],
    margintype,
  }
) => {
  const indentname = margintype === "left" ? KEY_INDENT : RIGHT_KEY_INDENT;

  const { nodeKey } = getPluginInjectProps(editor, indentname);
  //const nodeKey = margintype === "left" ? KEY_INDENT : RIGHT_KEY_INDENT;

  const _nodes = getNodeEntries(editor, {
    block: true,
    mode: "lowest",
    ...getNodesOptions,
  });
  const nodes = Array.from(_nodes);

  withoutNormalizing(editor, () => {
    nodes.forEach(([node, path]) => {
      const blockIndent = node[nodeKey] ?? 0;
      const newIndent = blockIndent + offset;

      const props = setNodesProps?.({ indent: newIndent }) ?? {};

      if (newIndent <= 0) {
        unsetNodes(editor, [nodeKey, ...unsetNodesProps], {
          at: path,
        });
      } else {
        setElements(editor, { [nodeKey]: newIndent, ...props }, { at: path });
      }
    });
  });
};
