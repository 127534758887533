import React from "react";
export default function SignatureBlock(props) {
  return (
    <>
      <span contentEditable="false" style={{ display: "inline-table" }}>
        <span style={{ display: "block", margin: "auto", textAlign:"center", fontWeight: "Bold"}}>
        {props?.signature?.label}
        </span>
        <span style={{ color: "white" }}>{`\{{Sig_es_:signer${props?.signature?.id? props?.signature?.id : ""}:signature}}`}</span>
        <span style={{ display: "block", border: "1px solid",margin: "10px 0px 0px 0px" }}></span>
        <span style={{ display: "block", margin: "10px 3px 20px 0px" }}>
          Signature
        </span>
        <span style={{ color: "white" }}>{`\{{N_es_:signer${props?.signature?.id? props?.signature?.id : ""}:fullname}}`}</span>
        <span style={{ display: "block", border: "1px solid",margin: "10px 0px 0px 0px" }}></span>
        <span style={{ display: "block", margin: "10px 3px 20px 0px" }}>
          Full Name
        </span>
        <span style={{ color: "white" }}>{`\{{Dte_es_:signer${props?.signature?.id? props?.signature?.id : ""}:date}}`}</span>
        <span style={{ display: "block", border: "1px solid",margin: "10px 0px 0px 0px" }}></span>
        <span style={{ display: "block", margin: "10px 3px 20px 0px" }}>
          Date Signed
        </span>
        {props.children}
        {/* {null} */}
      </span>
    </>
  );
}