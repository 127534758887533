import {
  createPluginFactory,
} from '@udecode/plate-common';
import { wordDeserialize } from './deserializerCustom';
//import { createPluginFactory } from '../../utils/createPluginFactory';
// import { deserializeHtml } from './utils/deserializeHtml';
//import { parseHtmlDocument } from './utils/parseHtmlDocument';

export const KEY_DESERIALIZE_HTML = 'deserializeHtmlCustom';

/**
 * Enables support for deserializing inserted content from HTML format to Slate format.
 */
export const createDeserializeHtmlPluginCustom = createPluginFactory({
  key: KEY_DESERIALIZE_HTML,
  then: (editor) => ({
    editor: {
      insertData: {
        format: 'text/html',
        getFragment: ({ data }) => {
          const document =  new DOMParser().parseFromString(data,'text/html');
          return wordDeserialize(
             document.body, {}
          );
        },
      },
    },
  }),
});