import { ReactEditor } from "slate-react";
import { useCallback, useEffect, useRef, useState } from "react";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeConditionThreadIDAtom,
  prevSelectedConditionThreadIDAtom,
} from "../utils/CommentState";
import {
  // initializeStateWithAllCommentThreads,
  initializeStateWithAllConditionThreads,
} from "../utils/EditorCommentUtils";
//import useAddCommentThreadCallback from "../hooks/useAddCommentThreadCallback";
import { Editor, Text, Transforms } from "slate";
import "./ConditionPopover.css";

export default function CoditionPopover({
  node,
  className,
  children,
  isBodyFullWidth,
  editorOffsets,
  onClickOutside,
  ...props
}) {
  const popoverRef = useRef(null);
  const editor = props.getEditorRef();
  const [conditionNode, setConditionNode] = useState(node);
  const [allConditionThreads, setallConditionThreads] = useState(null);
  const setActiveConditionThreadID = useSetRecoilState(
    activeConditionThreadIDAtom
  );
  const setPrevSelectedConditionThreadID = useSetRecoilState(
    prevSelectedConditionThreadIDAtom
  );

  const activeConditionThreadID = useRecoilValue(activeConditionThreadIDAtom);
  // const prevSelectedConditionThreadID = useRecoilValue(
  //   prevSelectedConditionThreadIDAtom
  // );

  useEffect(() => {
    setConditionNode(node);
  }, [node]);

  useEffect(() => {
    const editorEl = popoverRef.current;
    if (editorEl == null) {
      return;
    }
    if (editor && conditionNode) {
      const domNode = ReactEditor.toDOMNode(editor, conditionNode);
      const {
        x: nodeX,
        height: nodeHeight,
        y: nodeY,
      } = domNode.getBoundingClientRect();
      editorEl.style.position = "absolute";
      editorEl.style.display = "block";
      editorEl.style.top = `${nodeY + nodeHeight - editorOffsets.y}px`;
      editorEl.style.left = `${nodeX - editorOffsets.x}px`;
      editorEl.scrollIntoView(false);
    }
    (async () => {
      let abc = await initializeStateWithAllConditionThreads(editor);
      setallConditionThreads(Array.from(abc));
    })();
  }, [editor, editorOffsets.x, editorOffsets.y, conditionNode]);

  const onMouseDown = useCallback(
    (event) => {
      if (
        popoverRef.current != null &&
        !popoverRef.current.contains(event.target) &&
        onClickOutside != null
      ) {
        onClickOutside(event);
      }
    },
    [onClickOutside]
  );
  const isCurenID = (element) => element.uuid === activeConditionThreadID;

  const onDeleteClick = () => {
    const textNodesWithComments = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) =>
        Text.isText(n) &&
        n.condition &&
        n.condition["data-condition-id"] === activeConditionThreadID,
    });
    let textNodeEntry = textNodesWithComments.next().value;
    while (textNodeEntry !== undefined && textNodeEntry !== null) {
      const [node, path] = textNodeEntry;
      //const [path] = textNodeEntry;
      const keys = ["condition"]; //Object.keys(node);
      //keys.filter((e) => e !== "text" && e!== "variable" && e!== );
      Transforms.unsetNodes(editor, keys, { at: path });
      //Transforms.removeNodes(editor, { at: path });
      textNodeEntry = textNodesWithComments.next().value;
    }

    let commentIdActive = activeConditionThreadID;
    setActiveConditionThreadID(null);
    setPrevSelectedConditionThreadID(null);
    props.deleteCondition(commentIdActive);
  };

  const onPrevClick = () => {
    // props.setActiveConditionID(null);
    let currentID = allConditionThreads.findIndex(isCurenID);
    let nextID = 0;
    if (currentID === 0) {
      nextID = allConditionThreads.length - 1;
    } else nextID = currentID - 1;

    const textNodeEntry = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) =>
        Text.isText(n) &&
        n.condition &&
        n.condition["data-condition-id"] === allConditionThreads[nextID].uuid,
    }).next().value;
    setConditionNode(textNodeEntry[0]);
    setActiveConditionThreadID(allConditionThreads[nextID].uuid);
    setPrevSelectedConditionThreadID(allConditionThreads[nextID].uuid);
  };
  const onNextClick = () => {
    let currentID = allConditionThreads.findIndex(isCurenID);
    let nextID = 0;
    if (currentID === allConditionThreads.length - 1) {
      nextID = 0;
    } else nextID = currentID + 1;
    const textNodeEntry = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) =>
        Text.isText(n) &&
        n.condition &&
        n.condition["data-condition-id"] === allConditionThreads[nextID].uuid,
    }).next().value;
    setConditionNode(textNodeEntry[0]);
    setActiveConditionThreadID(allConditionThreads[nextID].uuid);
    setPrevSelectedConditionThreadID(allConditionThreads[nextID].uuid);
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
    };
  }, [onMouseDown]);

  if (editorOffsets == null) {
    return null;
  }

  return (
    <>
    <div className="condition-card" ref={popoverRef}
    //  className={className}
     >
     <div className="condition-header">
      <div className={"condition-thread-popover-header"}>
        <span className="conditionLabel">Condition</span>{" "}
     <button
         onClick={() => onDeleteClick()}
         className="deleteCondition"
        style={{ margin: "auto" }}
           disabled={props.deleteDisabled}
        >
            Delete
         </button>
      </div>{" "}
      </div>
     <div className ="condition-body"style={isBodyFullWidth ? { padding: 0 } : undefined}>
       {children}
      </div>
     <div className="condition-footer">
     <button onClick={() => onPrevClick()}>
       <i className="fa fa-chevron-circle-left prevnextCondition" />
       </button>
       <button onClick={() => onNextClick()} style={{ marginLeft: "5%" }}>
          <i className="fa fa-chevron-circle-right prevnextCondition" />
        </button>
      </div>
    </div>
    </>
  );
}
