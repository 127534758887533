import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";

import { convertHierarchy } from "../../../Helpers/hierarchyConverter";
import SyfedTreeSelect from "syfed-tree-select";
import "./ContractTemplate.css";
import Loading from "../../../Loading/Loading";
import { getCrudApi } from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";

export default function TableForContractTemplate(props) {
  const [AddModalOpen, setAddModalOpen] = useState(false);
  // const [EditTemplateOpen, setEditTemplateOpen] = useState(false);
  const [contractTemplateName, setContractTemplateName] = useState("");
  // const [selectedContractType, setSelectedContractType] = useState("");
  // const [selectedRenewalType, setSelectedRenewalType] = useState("");
  const [tableJSON, setTableJSON] = useState([]);
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const [CategoryTypeSelect, setCategoryTypeSelect] = useState([]);
  const [RenewalTypeSelect, setRenewalTypeSelect] = useState([]);
  const [TemplateTypeSelect, setTemplateTypeSelect] = useState([
    { label: "With Template", value: 9 },
    { label: "Without Template", value: 10, isDisabled: true },
    { label: "Both", value: 11, isDisabled: true },
  ]);
  const [page, setPage] = useState(0);
  const [contractTypeSelected, setcontractTypeSelected] = useState("");
  const [categoryTypeSelected, setcategoryTypeSelected] = useState("");
  const [renewalTypeSelected, setrenewalTypeSelected] = useState(1);
  //flag - with template - 9, without template - 10, both - 11
  const [templateTypeSelected, settemplateTypeSelected] = useState(9);
  const [isLoading, setIsLoading] = useState(false);
  const [dis, setdis] = useState(true);
  const navigate = useNavigate();
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractTemplateId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };
  const ContractTemplateName = (val) => {
    setContractTemplateName(val);
    if (contractTypeSelected !== "" && val !== "") {
      setdis(false);
    } else {
      setdis(true);
    }
  };
  const selectStyles = {
    // menuPortalTarget={document.body}
    // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    // options: (styles) => ({
    //   ...styles,
    //   fontFamily: "Roboto",
    //   fontWeight: 400,
    //   fontSize: "12px",
    // }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "2px",
      display: "flex",
      borderRadius: "5px",
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };

  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  const contractTypeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );
  const contractTemplateData = useSelector(
    (state) => state.contractTemplateState.value.contractTemplateData
  );
  const RenewalTypesData = useSelector(
    (state) => state.renewalTypeState.value.renewalTypeData
  );
  //const StatusData = useSelector((state) => state.statusState.value.statusData);

  const onChangeOfContractTreeSelect = (selectedOption) => {
    setcontractTypeSelected(selectedOption);
    if (contractTemplateName !== "" && selectedOption && categoryTypeSelected) {
      setdis(false);
    } else {
      setdis(true);
    }
  };
  const onChangeOfCategoryTreeSelect = (selectedOption) => {
    setcategoryTypeSelected(selectedOption);
    if (contractTemplateName !== "" && selectedOption && contractTypeSelected) {
      setdis(false);
    } else {
      setdis(true);
    }
  };
  const onChangeOfRenewalTreeSelect = (value) => {
    setrenewalTypeSelected(value);
  };
  const onChangeOfTemplateSelect = (value) => {
    settemplateTypeSelected(value.value);
  };
  const GetTemplateData = async () => {
    setIsLoading(true);
    const newData = generateJSONForTable(contractTemplateData);
    setTableJSON(newData);
    setIsLoading(false);
  };

  const generateJSONForTable = (templateData) => {
    let arrayOfJSON = [];
    templateData.forEach((template) => {
      let newJSON = {};
      Object.keys(template).forEach((element) => {
        newJSON[element] = template[element];
      });

      categoryData.some((category) => {
        if (category.catogeryTypeId === template.catogeryTypeId) {
          newJSON.catogery_type = category.catogeryType;
          return true;
        }
        return false;
      });

      contractTypeData.some((contractType) => {
        if (contractType.contractTypeId === template.contractTypeId) {
          newJSON.contract_type = contractType.contractType;
          return true;
        }
        return false;
      });

      if (template.templateTypeFlag && template.templateTypeFlag !== null) {
        var ttype;
        switch (template.templateTypeFlag) {
          case 9:
            ttype = "With Template";
            break;
          case 10:
            ttype = "Without Template";
            break;
          case 11:
            ttype = "Both";
            break;
        }
        newJSON.templateTypeFlagName = ttype;
      }
      arrayOfJSON.push(newJSON);
    });

    return arrayOfJSON;
  };

  const OnAddTemplateOpen = async (e) => {
    let result = getCrudApi("contractTemplate/check", {});
    await result.then(function (res) {
      if (res) {
        setAddModalOpen(true);
      } else {
        toast.error(
          "Attention: Your subscription contract limit has been reached. Please contact our service provider for further assistance."
        );
      }
    });
  };
  const OnAddTemplateClose = (e) => {
    setAddModalOpen(false);
    sessionStorage.removeItem("draftId");
    sessionStorage.removeItem("verId");
    sessionStorage.removeItem("tempId");
    navigate(
      "/Admin/contractTemplates/editor",
      // pathname: "/DraftJSEditor",
      {
        state: {
          tableData: null,
          templateName: contractTemplateName,
          categorySelected: categoryTypeSelected,
          contractTypeSelected: contractTypeSelected,
          templateTypeSelected: templateTypeSelected,
          categoryType: CategoryTypeSelect,
          contractType: filterContractTypeSelect,
          renewalType: RenewalTypeSelect,
          renewalTypeSelected: renewalTypeSelected,
          publish_enable: false,
          templateData: null,
        },
      }
    );
  };

  const SelectMappingForCategory = () => {
    if (categoryData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        categoryData.filter((element) => element.status === "Active"),
        "catogeryTypeId",
        "parentCatogeryTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"catogeryTypeId"/g,
        '"value"'
      );
      old = old.replace(/"catogeryType"/g, '"label"');
      var newArray = JSON.parse(old);
      setCategoryTypeSelect(newArray);
      // setCategoryTypeSelect(
      //   TreeSelectMappingWithHierarchy(
      //     "catogeryTypeId",
      //     "parent_catogery_type_id",
      //     "catogeryType",
      //     categoryData
      //   )
      // );
    } else setCategoryTypeSelect([]);
  };
  const SelectMappingForRenewalType = () => {
    let treeHierarchy = RenewalTypesData;
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(/"renewal_id"/g, '"value"');
    old = old.replace(/"renewal_type"/g, '"label"');
    var newArray = JSON.parse(old);
    setRenewalTypeSelect(newArray);
    // setRenewalTypeSelect(
    //   TreeSelectMapping("renewal_id", "renewal_type", RenewalTypesData)
    // );
  };
  const SelectMappingForContractType = () => {
    if (contractTypeData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        contractTypeData.filter((element) => element.status === "Active"),
        "contractTypeId",
        "parentContractTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"contractTypeId"/g,
        '"value"'
      );
      old = old.replace(/"contractType"/g, '"label"');
      var newArray = JSON.parse(old);

      setContractTypeSelect(newArray);
      setFilterContractTypeSelect(newArray);
    } else {
      setContractTypeSelect([]);
      setFilterContractTypeSelect([]);
    }
  };

  function filterDataCategoryContractType() {
    let category = null;
    var newArray = filterContractTypeSelect;
    if (categoryTypeSelected !== null) {
      category = newArray?.filter((con) => {
        if (con.catogeryTypeId === categoryTypeSelected) return true;
        else return false;
      });
      setContractTypeSelect(category);
    }
  }

  useEffect(() => {
    SelectMappingForCategory();
    SelectMappingForContractType();
    SelectMappingForRenewalType();
  }, [RenewalTypesData]);
  useEffect(() => {
    filterDataCategoryContractType();
  }, [categoryTypeSelected]);
  const generateColumns = () => {
    if (tableJSON && tableJSON.length > 0) {
      let columnsArray = [];
      let firstCol = {
        name: "EDIT",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                <button onClick={() => onTemplateEditorOpen(tableMeta)}>
                  <i className="fa fa-edit custom-iconformodal" />
                </button>
              </>
            );
          },
        },
      };
      columnsArray.push(firstCol);
      columnsArray.push({
        name: "contractTemplateId",
        label: "Contract Template ID",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "contractTemplateName",
        label: "CONTRACT TEMPLATE NAME",
      });
      columnsArray.push({
        name: "catogery_type",
        label: "CATEGORY TYPE",
      });
      columnsArray.push({
        name: "catogeryTypeId",
        label: "Catogery Type Id",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "contract_type",
        label: "CONTRACT TYPE",
      });
      columnsArray.push({
        name: "contractTypeId",
        label: "Contract Type Id",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "renewal_type",
        label: "Renewal Type",
        options: {
          display: false,
        },
      });

      columnsArray.push({
        name: "renewalId",
        label: "Renewal ID",
        options: {
          display: false,
        },
      });
      //TODO: Have to map status name from api
      columnsArray.push({
        name: "contractStatus",
        label: "Status ID",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "draftTemplateId",
        label: "Draft Template ID",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "contractSettingsId",
        label: "Template Settings ID",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "publishedTemplateVersionId",
        label: "Template Version ID",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "templateTypeFlag",
        label: "Template Type",
        options: {
          display: false,
        },
      });
      columnsArray.push({
        name: "templateTypeFlagName",
        label: "TEMPLATE TYPE",
      });
      return columnsArray;
    }
    return [];
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  const onTemplateEditorOpen = async (tableMeta) => {
    setIsLoading(true);
    sessionStorage.removeItem("draftId");
    sessionStorage.removeItem("verId");
    sessionStorage.removeItem("tempId");
    if (tableMeta.rowData[10])
      sessionStorage.setItem("draftId", tableMeta.rowData[10]);
    if (tableMeta.rowData[12])
      sessionStorage.setItem("verId", tableMeta.rowData[12]);
    sessionStorage.setItem("tempId", tableMeta.rowData[1]);
    navigate(
      "/Admin/contractTemplates/editor",
      // pathname: "/DraftJSEditor",
      {
        state: {
          tableData: tableMeta.rowData,
          templateName: tableMeta.rowData[2],
          categorySelected: tableMeta.rowData[4],
          contractTypeSelected: tableMeta.rowData[6],
          templateTypeSelected: tableMeta.rowData[13],
          categoryType: CategoryTypeSelect,
          contractType: filterContractTypeSelect,
          renewalType: RenewalTypeSelect,
          renewalTypeSelected: renewalTypeSelected,
          publish_enable: true,
          templateData: contractTemplateData.filter(
            (element) => element.contractTemplateId === tableMeta.rowData[1]
          )[0],
          draftId: tableMeta.rowData[10],
          verId: tableMeta.rowData[12],
        },
      }
    );
    setIsLoading(false);
  };

  useEffect(() => {
    GetTemplateData();
  }, []);
  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };

  const onCloseModal = () => {
    setAddModalOpen(false);
    setcontractTypeSelected("");
    setcategoryTypeSelected("");
    setContractTemplateName("");
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={(e) => OnAddTemplateOpen(e)}
            >
              Add
            </button>
          </div>
          <div className="Table-body">
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={tableJSON}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}

      {AddModalOpen ? (
        <div className="modal">
          <div className="modal-content ">
            <div className="header-modal">
              <h5 className="header-title">Contract Template</h5>
            </div>
            <div className="div-display">
              <label className="label-class">
                Template Name <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>

              <input
                type="text"
                placeholder="Template Name"
                value={contractTemplateName}
                className="textbox-input_1"
                onChange={(e) => ContractTemplateName(e.target.value)}
              />
            </div>
            <div className="div-display">
              <label className="label-class">
                Category Type <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>

              <SyfedTreeSelect
                style={treeSelectStyle}
                size="small"
                treeCheckedStrategy="all"
                dataSource={CategoryTypeSelect}
                onChange={onChangeOfCategoryTreeSelect}
                placeholder="Select Category Type"
                value={categoryTypeSelected}
              />
            </div>
            <div className="div-display">
              <label className="label-class">
                Contract Type <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <SyfedTreeSelect
                style={treeSelectStyle}
                size="small"
                treeCheckedStrategy="all"
                dataSource={ContractTypeSelect}
                onChange={onChangeOfContractTreeSelect}
                placeholder="Select Contract Type"
                value={contractTypeSelected}
              />
            </div>

            <div className="div-display">
              <label className="label-class">Expiry Frequency</label>
              <SyfedTreeSelect
                style={treeSelectStyle}
                size="small"
                treeCheckedStrategy="all"
                dataSource={RenewalTypeSelect}
                onChange={onChangeOfRenewalTreeSelect}
                placeholder="Select Renewal Type"
                value={renewalTypeSelected}
              />
            </div>

            <div className="div-display">
              <label className="label-class">Template Type</label>

              {/* <SyfedTreeSelect
                className="treeSelectStyle"
                size="large"
                treeCheckedStrategy="all"
                dataSource={TemplateTypeSelect}
                onChange={onChangeOfTemplateSelect}
                placeholder="Select Template Type"
                value={templateTypeSelected}
              /> */}
              <Select
                styles={selectStyles}
                menuPlacement="top"
                value={{
                  value: templateTypeSelected,
                  label:
                    templateTypeSelected === 9
                      ? "With Template"
                      : templateTypeSelected === 10
                      ? "Without Template"
                      : templateTypeSelected === 11
                      ? "Both"
                      : null,
                }}
                onChange={(value) => {
                  onChangeOfTemplateSelect(value);
                }}
                options={TemplateTypeSelect}
                isMulti={false}
              />
            </div>

            <div style={{ padding: "85px,0px" }} className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={onCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={OnAddTemplateClose}
                disabled={dis}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
