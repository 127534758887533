import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import OptimisedMUIDataTable from "../manageContracts/OptimisedMUIDataTable";
import { Form, TextArea } from "semantic-ui-react";
import { RecoilRoot } from "recoil";
import PlateEditor from "../../PlateEditor/PlateEditor";
import Loading from "../../Loading/Loading";
import "./SubmitReviewe.css";
import { useLocation } from "react-router-dom";

const SubmitReviewe = (props) => {
  const [templateData, setTemplateData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [contractData, setcontractData] = useState();
  const [CloseEnable, setCloseEnable] = useState(true);
  const [comments, setComments] = useState("");
  const [commentsJson, setcommentsJson] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contractName, setContractName] = useState("");
  const [reviewerStatus, setReviewerStatus] = useState();
  const [pageSize, setPageSize] = useState("A4")
  const location = useLocation();
  const plateRef = React.createRef();
  useEffect(() => {
    //TODO - api call to get initial request info table
    setcontractdata();
  }, []);
  const onCommentsChange = (name, value) => {
    setComments(value);
  };
  const setcontractdata = async () => {
    setIsLoading(true);
    if (location.state && location.state.contractData) {
      let dataResult;
      let result = getCrudApi(
        "contract/" + location.state.contractData.contractId,
        {}
      );
      await result.then(function (res) {
        dataResult = res;
      });
      setcontractData(dataResult);
      setContractName(dataResult.contractName);
      setPageSize(dataResult?.contractTemplate?.pageSize ? dataResult?.contractTemplate?.pageSize : "A4")
      setTemplateData(dataResult.finalDocument.finalDocument);
      setFormData(dataResult.contractFormData.var);
      if (dataResult.contractReviewerStatus === 17) {
        setCloseEnable(false);
      } else setCloseEnable(true);

      result = getCrudApi(
        "contractHistoryComment/contract/" +
          location.state.contractData.contractId,
        {}
      );
      await result.then(function (res) {
        dataResult = res;
      });

      dataResult.sort(GetSortOrder("contractHistoryCommentId"));
      setcommentsJson(dataResult);
      setComments("");
    }
    setIsLoaded(true);
    setIsLoading(false);
  };

  const displayReviewMessage = () => {
    toast.success("Reviewed Successfully!");
  };
  const CloseClick = async () => {
    setIsLoading(true);
    let RevieweStatus = {
      contractReviewerStatus: 17,
    };
    setReviewerStatus(RevieweStatus);
    let dataResult;
    let result = putCrudApi(
      "contract/" + contractData.contractId,
      RevieweStatus
    );
    await result.then(function (res) {
      dataResult = res;
    });
    setcontractData(dataResult);
    if (comments.trim() !== "") {
      let commentHistory = {
        contractHistoryComment: comments,
        contractId: dataResult.contractId,
        contractStatus: dataResult.contractStatus,
        commentHistoryFlag: 2,
      };
      await postCrudApi(
        "contractHistoryComment/contractHistoryComment",
        commentHistory
      );
    }
    //getcomments
    result = getCrudApi(
      "contractHistoryComment/contract/" + contractData.contractId,
      {}
    );
    await result.then(function (res) {
      dataResult = res;
    });

    dataResult.sort(GetSortOrder("contractHistoryCommentId"));
    setcommentsJson(dataResult);
    setComments("");
    setCloseEnable(false);
    setIsLoading(false);
    displayReviewMessage();
  };

  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    filterType: "dropdown",
    search: false,
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 30],
    sortOrder: {
      name: "lastUpdatedDateTime",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},

    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {},
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const generateColumns = () => {
    let columnsArray = [];

    columnsArray.push({
      name: "contractHistoryComment",
      label: "Comment",
    });
    columnsArray.push({
      name: "lastUpdatedBy",
      label: "Commented By",
    });
    columnsArray.push({
      name: "lastUpdatedDateTime",
      label: "Commented On",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "Contract Status",
    });

    return columnsArray;
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <label className="review-header">
              {contractName ? contractName : null}
            </label>

            {CloseEnable || (reviewerStatus && reviewerStatus === 17) ? (
              <div>
                <button
                  className="reviewButton"
                  onClick={() => CloseClick()}
                  disabled={!CloseEnable}
                >
                  Close
                </button>
              </div>
            ) : null}
          </div>
          {isLoaded ? (
            <>
              <RecoilRoot>
                <div className="review-editor">
                  <PlateEditor
                    id={"reviewerEditor"}
                    ref={plateRef}
                    //commentJSON={negotiationComments}
                    isNegotiationDisabled={true}
                    isVariableDisabled={true}
                    isPartiesDisabled={true}
                    isConditionDisabled={true}
                    isConditionToggleDisabled={true}
                    isCommentToggleDisabled={true}
                    isClauseDisabled={true}
                    contractTemplateJsonData={
                      templateData ? templateData : null
                    }
                    isEditable={false}
                    serializeCall={"serializeContract"}
                    pageSize={pageSize}
                  />
                </div>
              </RecoilRoot>
              <div className="commentbar">
                <label className=" commentLabel">Comment</label>
                <div>
                  <TextArea
                    className="TextAreaStyle"
                    value={comments}
                    onChange={(e) =>
                      onCommentsChange(e.target.name, e.target.value)
                    }
                  ></TextArea>
                </div>
              </div>
              <div>
                <div className="Table-comment">
                  <MuiThemeProvider theme={customTheme}>
                    <OptimisedMUIDataTable
                      data={commentsJson}
                      columns={generateColumns()}
                      options={options}
                    />
                  </MuiThemeProvider>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};
export default SubmitReviewe;
