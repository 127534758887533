import React, { useEffect, useState } from "react";
import Loading from "../../Loading/Loading";
import { getDecodedToken, validateToken } from "../../Helpers/validateToken";
import { getCrudApi } from "../../webServiceCalls/webServiceCall";
import { useSelector } from "react-redux";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import BarChartComp from "./Charts/BarChart";
import clock from "../../assets/images/clock.gif";
import welcomeBack from "../../assets/images/welcomeBck.png";
import PieChartComp from "./Charts/PieChart.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [barChartDataForContract, setBarChartDataForContract] = useState([]);
  const [barChartDataForRequest, setBarChartDataForRequest] = useState([]);
  const [funnelChartDataForContract, setPieChartDataForContract] = useState([]);
  const [funnelChartDataForRequest, setPieChartDataForRequest] = useState([]);
  const [name, setName] = useState("");
  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  const contractTypeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );
  const navigate = useNavigate();
  const StatusData = useSelector((state) => state.statusState.value.statusData);

  useEffect(() => {
    getContractData();
  }, []);

  const generateJSONForTable = (templateData) => {
    let arrayOfJSON = [];
    if (templateData != null) {
      templateData.forEach((template) => {
        let newJSON = {};
        Object.keys(template).forEach((element) => {
          newJSON[element] = template[element];
        });

        categoryData.some((category) => {
          if (category.catogeryTypeId === template.catogeryTypeId) {
            newJSON.categoryType = category.catogeryType;
            return true;
          }
          return false;
        });
        contractTypeData.some((contractType) => {
          if (contractType.contractTypeId === template.contractTypeId) {
            newJSON.contractType = contractType.contractType;
            return true;
          }
          return false;
        });

        StatusData.some((status) => {
          if (status.status_id === template.contractStatus) {
            newJSON.status = status.status;
            return true;
          }
          return false;
        });
        arrayOfJSON.push(newJSON);
      });
    }
    return arrayOfJSON;
  };

  function dataForChartContractPie(data) {
    let dataArray = [];
    Object.keys(data).map((ele) => {
      if (ele === "contractTotal") {
        dataArray.push({
          name: "All",
          value: data[ele],
        });
      } else if (ele === "contractDrafted") {
        dataArray.push({
          name: "Draft",
          value: data[ele],
        });
      } else if (ele === "contractSubmitted") {
        dataArray.push({
          name: "Submitted",
          value: data[ele],
        });
      } else if (ele === "contractAmended") {
        dataArray.push({
          name: "Amended",
          value: data[ele],
        });
      } else if (ele === "contractTerminated") {
        dataArray.push({
          name: "Terminated",
          value: data[ele],
        });
      } else if (ele === "contractApproved") {
        dataArray.push({
          name: "Approved",
          value: data[ele],
        });
      } else if (ele === "contractExecuted") {
        dataArray.push({
          name: "Executed",
          value: data[ele],
        });
      }
    });
    setPieChartDataForContract(dataArray);
  }

  function dataForChartRequestPie(data) {
    let dataArray = [];
    Object.keys(data).map((ele) => {
      if (ele === "requestTotal") {
        dataArray.push({
          name: "All",
          value: data[ele],
          id: 2,
        });
      } else if (ele === "requestDrafted") {
        dataArray.push({
          name: "Drafted",
          value: data[ele],
          id: 2,
          //  value: data[ele]
        });
      } else if (ele === "requestSubmitted") {
        dataArray.push({
          name: "Submitted",
          value: data[ele],
          id: 2,
        });
      } else if (ele === "requestApproved") {
        dataArray.push({
          name: "Approved",
          value: data[ele],
          id: 2,
        });
      }
    });
    setPieChartDataForRequest(dataArray);
  }

  function dataForChartContract(data) {
    let dataArray = [];
    Object.keys(data).map((ele) => {
      if (ele === "contractTotalAcv") {
        dataArray.push({
          name: "All",
          value: data[ele],
        });
      } else if (ele === "contractDraftedAcv") {
        dataArray.push({
          name: "Draft",
          value: data[ele],
        });
      } else if (ele === "contractSubmittedAcv") {
        dataArray.push({
          name: "Submit",
          value: data[ele],
        });
      } else if (ele === "contractAmendedAcv") {
        dataArray.push({
          name: "Amend",
          value: data[ele],
        });
      } else if (ele === "contractTerminatedAcv") {
        dataArray.push({
          name: "Terminate",
          value: data[ele],
        });
      } else if (ele === "contractApprovedAcv") {
        dataArray.push({
          name: "Approve",
          value: data[ele],
        });
      } else if (ele === "contractExecutedAcv") {
        dataArray.push({
          name: "Execute",
          value: data[ele],
        });
      }
    });
    setBarChartDataForContract(dataArray);
  }

  function dataForChartRequest(data) {
    let dataArray = [];
    Object.keys(data).map((ele) => {
      if (ele === "requestTotalAcv") {
        dataArray.push({
          name: "All",
          value: data[ele],
        });
      } else if (ele === "requestDraftedAcv") {
        dataArray.push({
          name: "Draft",
          value: data[ele],
        });
      } else if (ele === "requestSubmittedAcv") {
        dataArray.push({
          name: "Submit",
          value: data[ele],
        });
      } else if (ele === "requestApprovedAcv") {
        dataArray.push({
          name: "Approve",
          value: data[ele],
        });
      }
    });
    setBarChartDataForRequest(dataArray);
  }

  const getContractData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi("contract/dashboard", {});
    await result.then(function (res) {
      dataResult = res;
    });
    setContractData(dataResult);
    dataForChartContract(dataResult);
    dataForChartRequest(dataResult);
    dataForChartContractPie(dataResult);
    dataForChartRequestPie(dataResult);
    setIsLoading(false);
  };
  const clickLegend = (e, name, id) => {
    let legendName = name;
    if (id === 2) {
      navigate("/Request", {
        state: {
          filterName: legendName,
        },
      });
    } else {
      navigate("/Contracts", {
        state: {
          filterName: legendName,
        },
      });
    }
  };
  useEffect(() => {
    firstNameAndLastName();
  }, []);
  const firstNameAndLastName = async () => {
    setIsLoading(true);
    if (
      sessionStorage.getItem("user") != null ||
      sessionStorage.getItem("user") != ""
    ) {
      let decoded = getDecodedToken(sessionStorage.getItem("user"));
      if (decoded != null) {
        let result = getCrudApi("users/" + decoded?.userId, {});
        await result.then(function (res) {
          let dataResult = res;
          let name = dataResult?.first_name + dataResult?.last_name;
          setName(name);
        });
      }
    }
    setIsLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <div>
          <div className="dashboard-main-grid">
            <div className="dashboard-contract-status-div">
              <p className="dashboard-label">Contract</p>
              <div
                className="dashboard-status-div border-1"
                onClick={(e) => {
                  clickLegend(e, "All", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/windows/32/000000/save--v1.png"
                  alt="save--v1"
                />
                <label className="dashboard-label">All</label>
                <label className="dashboard-label">
                  {contractData?.contractTotal}
                </label>
              </div>
              <div
                className="dashboard-status-div border-2"
                onClick={(e) => {
                  clickLegend(e, "Drafted", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/external-solid-adri-ansyah/64/external-file-file-and-folder-part-2-solid-adri-ansyah-17.png"
                  alt="external-file-file-and-folder-part-2-solid-adri-ansyah-17"
                />
                <label className="dashboard-label">Drafted</label>
                <label className="dashboard-label">
                  {contractData?.contractDrafted}
                </label>
              </div>
              <div
                className="dashboard-status-div border-3"
                onClick={(e) => {
                  clickLegend(e, "Submitted", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/glyph-neue/64/submit-document.png"
                  alt="submit-document"
                />
                <label className="dashboard-label">Submitted</label>
                <label className="dashboard-label">
                  {contractData?.contractSubmitted}
                </label>
              </div>
              <div
                className="dashboard-status-div border-4"
                onClick={(e) => {
                  clickLegend(e, "Approved", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/external-line-adri-ansyah/64/external-file-file-and-folder-part-1-line-adri-ansyah-2.png"
                  alt="external-file-file-and-folder-part-1-line-adri-ansyah-2"
                />
                <label className="dashboard-label">Approved</label>
                <label className="dashboard-label">
                  {contractData?.contractApproved}
                </label>
              </div>
              <div
                className="dashboard-status-div border-5"
                onClick={(e) => {
                  clickLegend(e, "Reopened", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/fluency-systems-regular/48/important-file.png"
                  alt="important-file"
                />
                <label className="dashboard-label">Reopen</label>
                <label className="dashboard-label">
                  {contractData?.contractReOpened}
                </label>
              </div>
              <div
                className="dashboard-status-div border-6"
                onClick={(e) => {
                  clickLegend(e, "Negotiation", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/material-outlined/24/update-file.png"
                  alt="update-file"
                />
                <label className="dashboard-label">Negotiation</label>
                <label className="dashboard-label">
                  {contractData?.contractNegotiation}
                </label>
              </div>
              <div
                className="dashboard-status-div border-7"
                onClick={(e) => {
                  clickLegend(e, "Executed", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/windows/32/file-download.png"
                  alt="file-download"
                />
                <label className="dashboard-label">Executed</label>
                <label className="dashboard-label">
                  {contractData?.contractExecuted}
                </label>
              </div>
              <div
                className="dashboard-status-div border-8"
                onClick={(e) => {
                  clickLegend(e, "Amended", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/windows/32/file-arrow.png"
                  alt="file-arrow"
                />
                <label className="dashboard-label">Amended</label>
                <label className="dashboard-label">
                  {contractData?.contractAmended}
                </label>
              </div>
              <div
                className="dashboard-status-div border-9"
                onClick={(e) => {
                  clickLegend(e, "Termination InProgress", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/fluency-systems-regular/48/secured-file.png"
                  alt="secured-file"
                />
                <label className="dashboard-label">
                  Termination In progress
                </label>
                <label className="dashboard-label">
                  {contractData?.contractTerminationInProgress}
                </label>
              </div>
              <div
                className="dashboard-status-div border-10"
                onClick={(e) => {
                  clickLegend(e, "Terminated", 1);
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/external-solid-adri-ansyah/64/external-file-file-and-folder-part-1-solid-adri-ansyah-18.png"
                  alt="external-file-file-and-folder-part-1-solid-adri-ansyah-18"
                />
                <label className="dashboard-label">Terminated</label>
                <label className="dashboard-label">
                  {contractData?.contractTerminated}
                </label>
              </div>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <div className="dashboard-welcome-back-div">
                  <div style={{ display: "grid" }}>
                    <label className="dashboard-welcome-back-div-label-1">
                      Hey {name}
                    </label>
                    <label className="dashboard-welcome-back-div-label-2">
                      Have a nice day at work!
                    </label>
                  </div>
                  <LazyLoadImage
                    effect="blur"
                    style={{ marginLeft: "auto" }}
                    height="100"
                    src={welcomeBack}
                    alt="save--v1"
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="dashboard-acv-contract-div">
                  <label className="dashboard-label">ACV Contract</label>
                  <div style={{ textAlign: "center" }}>
                    <BarChartComp data={barChartDataForContract} />
                    <div className="dashboard-label-acv-contract">
                      <label className="dashboard-label">
                        ACV of Contracts in USD($){" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-acv-expiring-renewing">
                <div className="dashboard-acv-expiring">
                  <label className="dashboard-label">
                    ACV Expiring Contracts
                  </label>
                  <div>
                    <div className="dashboard-acv-expiring-renewing-div dashboard-request-status-inner-div-all">
                      <div>
                        <LazyLoadImage
                          effect="blur"
                          width="24"
                          height="24"
                          src="https://img.icons8.com/material-outlined/48/profit-report.png"
                          alt="profit-report"
                        />
                      </div>
                      <label className="dashboard-label">15 days</label>
                      <label className="dashboard-label">
                        {contractData?.expiryInFifteenDaysAcv}
                      </label>
                    </div>
                    <div className="dashboard-acv-expiring-renewing-div dashboard-request-status-inner-div-drafted">
                      <LazyLoadImage
                        effect="blur"
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-outlined/48/profit-report.png"
                        alt="profit-report"
                      />
                      <label className="dashboard-label">30 days</label>
                      <label className="dashboard-label">
                        {contractData?.expiryInThirtyDaysAcv}
                      </label>
                    </div>
                    <div className="dashboard-acv-expiring-renewing-div dashboard-request-status-inner-div-submitted">
                      <LazyLoadImage
                        effect="blur"
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-outlined/48/profit-report.png"
                        alt="profit-report"
                      />
                      <label className="dashboard-label">90 days</label>
                      <label className="dashboard-label">
                        {contractData?.expiryInNinetyDaysAcv}
                      </label>
                    </div>
                  </div>
                  <div className="dashboard-label-acv-contract">
                    <label className="dashboard-label">
                      ACV of Contracts in USD($){" "}
                    </label>
                  </div>
                </div>
                <div className="dashboard-acv-expiring">
                  <label className="dashboard-label">
                    ACV Renewing Contracts
                  </label>
                  <div>
                    <div className="dashboard-acv-expiring-renewing-div dashboard-request-status-inner-div-all">
                      <LazyLoadImage
                        effect="blur"
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-outlined/48/profit-report.png"
                        alt="profit-report"
                      />
                      <label className="dashboard-label">15 days</label>
                      <label className="dashboard-label">
                        {contractData?.renewalInFifteenDaysAcv}
                      </label>
                    </div>
                    <div className="dashboard-acv-expiring-renewing-div dashboard-request-status-inner-div-drafted">
                      <LazyLoadImage
                        effect="blur"
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-outlined/48/profit-report.png"
                        alt="profit-report"
                      />
                      <label className="dashboard-label">30 days</label>
                      <label className="dashboard-label">
                        {contractData?.renewalInThirtyDaysAcv}
                      </label>
                    </div>
                    <div className="dashboard-acv-expiring-renewing-div dashboard-request-status-inner-div-submitted">
                      <LazyLoadImage
                        effect="blur"
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-outlined/48/profit-report.png"
                        alt="profit-report"
                      />
                      <label className="dashboard-label">90 days</label>
                      <label className="dashboard-label">
                        {contractData?.renewalInNinetyDaysAcv}
                      </label>
                    </div>
                  </div>
                  <div className="dashboard-label-acv-contract">
                    <label className="dashboard-label">
                      ACV of Contracts in USD($){" "}
                    </label>
                  </div>
                </div>
                <div className="dashboard-acv-expiring dashboard-acv-request-chart">
                  <label className="dashboard-label">ACV Requests</label>
                  <div style={{ textAlign: "center" }}>
                    <BarChartComp data={barChartDataForRequest} />
                    <label className="dashboard-label">
                      ACV of Request in USD($){" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="dashboard-request-status-div"
                style={{ marginTop: "10px" }}
              >
                <div className="dashboard-label">Request</div>
                <div className="dashboard-request-status-sub-div">
                  <div
                    className="dashboard-request-status-inner-div dashboard-request-status-inner-div-all"
                    onClick={(e) => {
                      clickLegend(e, "All", 2);
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      width="24"
                      height="24"
                      src="https://img.icons8.com/windows/32/000000/save--v1.png"
                      alt="external-file-file-and-folder-part-2-solid-adri-ansyah-17"
                    />
                    <label className="dashboard-label">All</label>
                    <label className="dashboard-label">
                      {contractData?.requestTotal}
                    </label>
                  </div>
                  <div
                    className="dashboard-request-status-inner-div dashboard-request-status-inner-div-drafted"
                    onClick={(e) => {
                      clickLegend(e, "Drafted", 2);
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      width="24"
                      height="24"
                      src="https://img.icons8.com/external-solid-adri-ansyah/64/external-file-file-and-folder-part-2-solid-adri-ansyah-17.png"
                      alt="external-file-file-and-folder-part-2-solid-adri-ansyah-17"
                    />
                    <label className="dashboard-label">Drafted</label>
                    <label className="dashboard-label">
                      {contractData?.requestDrafted}
                    </label>
                  </div>
                </div>
                <div className="dashboard-request-status-sub-div">
                  <div
                    className="dashboard-request-status-inner-div dashboard-request-status-inner-div-submitted"
                    onClick={(e) => {
                      clickLegend(e, "Submitted", 2);
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      width="24"
                      height="24"
                      src="https://img.icons8.com/glyph-neue/64/submit-document.png"
                      alt="external-file-file-and-folder-part-2-solid-adri-ansyah-17"
                    />
                    <label className="dashboard-label">Submitted</label>
                    <label className="dashboard-label">
                      {contractData?.requestSubmitted}
                    </label>
                  </div>
                  <div
                    className="dashboard-request-status-inner-div dashboard-request-status-inner-div-approved"
                    onClick={(e) => {
                      clickLegend(e, "Approved", 2);
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      width="24"
                      height="24"
                      src="https://img.icons8.com/external-line-adri-ansyah/64/external-file-file-and-folder-part-1-line-adri-ansyah-2.png"
                      alt="external-file-file-and-folder-part-2-solid-adri-ansyah-17"
                    />
                    <label className="dashboard-label">Approved</label>
                    <label className="dashboard-label">
                      {contractData?.requestApproved}
                    </label>
                  </div>
                </div>
              </div>
              <div className="dashboard-expiry-contracts-main-div">
                <label className="dashboard-label">Expiring contracts</label>
                <div className="dashboard-expiry-contracts-div">
                  <div className="dashboard-expiry-contracts-sub-div">
                    <div className="dashboard-expiry-contracts-number background8">
                      {contractData?.expiryInFifteenDays}
                    </div>
                    <div className="dashboard-expiry-contracts-label">
                      15 days
                    </div>
                  </div>
                  <div className="dashboard-expiry-contracts-sub-div">
                    <div className="dashboard-expiry-contracts-number background2">
                      {contractData?.expiryInThirtyDays}
                    </div>
                    <div className="dashboard-expiry-contracts-label">
                      30 days
                    </div>
                  </div>
                  <div className="dashboard-expiry-contracts-sub-div">
                    <div className="dashboard-expiry-contracts-number background6">
                      {contractData?.expiryInNinetyDays}
                    </div>
                    <div className="dashboard-expiry-contracts-label">
                      90 days
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="dashboard-expiry-contracts-main-div"
                style={{ marginTop: "0px" }}
              >
                <label className="dashboard-label">Renewing Contracts</label>
                <div className="dashboard-expiry-contracts-div">
                  <div className="dashboard-expiry-contracts-sub-div">
                    <div className="dashboard-expiry-contracts-number background1">
                      {contractData?.renewalInFifteenDays}
                    </div>
                    <div className="dashboard-expiry-contracts-label">
                      15 days
                    </div>
                  </div>
                  <div className="dashboard-expiry-contracts-sub-div">
                    <div className="dashboard-expiry-contracts-number background4">
                      {contractData?.renewalInThirtyDays}
                    </div>
                    <div className="dashboard-expiry-contracts-label">
                      30 days
                    </div>
                  </div>
                  <div className="dashboard-expiry-contracts-sub-div">
                    <div className="dashboard-expiry-contracts-number background8">
                      {contractData?.renewalInNinetyDays}
                    </div>
                    <div className="dashboard-expiry-contracts-label">
                      90 days
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
