import React from "react";

const Button = (props) => {
  const { children, format, active, ...rest } = props;
  return (
    <button
      className={active ? "btnActive" : "lol"}
      title={format}
      {...rest}
      style={{
        width: "35px",
        height: "30px",
        // margin: "0 2px",
        backgroundColor: "transparent",
        fontSize: "20px",
  
      }}
      disabled={!props.enabled}
    >
      {children}
    </button>
  );
};

export default Button;
