import {
  isElement,
  PlateEditor,
  TDescendant,
  Value,
} from '@udecode/plate-common';

import { getListTypes } from './getListTypes';

export const isListRoot = (
  editor,
  node
) => isElement(node) && getListTypes(editor).includes(node.type);
