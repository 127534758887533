import React, { useState, useEffect } from "react";
import DynamicForm from "../dynamicForm/index";
import { json, useLocation, useNavigate } from "react-router-dom";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validateToken } from "../../Helpers/validateToken";
import { TextArea } from "semantic-ui-react";
import "./DynamicFormForRequest.css";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import OptimisedMUIDataTable from "../manageContracts/OptimisedMUIDataTable";
import { FileUploader } from "react-drag-drop-files";
import Checkbox from "react-simple-checkbox";
import AllPagesPDFViewer from "../manageContracts/all-pages";
import {
  deserialize,
  deserializeClause,
  deserializeContract,
} from "../../PlateEditor/slateHelper/deserialize";
import { serializeContract } from "../../PlateEditor/slateHelper/serializeHtml";
import {
  convertToHandlebarFormat,
  executeHandlebar,
} from "../../Helpers/Handlebar/HandlebarHelper";
import Loading from "../../Loading/Loading";
import { checkValidity } from "../dynamicForm/shared/helper";

const hbTemplate2 =
  "<div>Hello {{Divya}} Hii hi hih hi {{haiii}}&nbsp;hi hi divya hi</div><div>H{{#cusif 'equal' Divya 'intel'}}divya is intelligent! eyy {{Babe}}awd {{adw}} {{awyy}}&nbsp;adaw{{/cusif}}da mv \"abcd\" \"rghj\" awd wad aw gghhjjkk</div>";
export default function DynamicFormForRequest(props) {
  const [open, setOpen] = React.useState(false);
  const [templateSelected, settemplateSelected] = useState();
  const [template_version, settemplate_version] = useState();
  const [createdFrom, setcreatedFrom] = useState();
  const [contractData, setContractData] = useState(null);
  const [templateType, setTemplateType] = useState(0);
  const [JSONforVariables, setJSON] = useState();
  const [disableForm, setdisableForm] = useState(false);
  const [FinalJsonVariables, setFinalJsonVariables] = useState();
  const navigate = useNavigate();
  const [handlebarTemplate, setHandlebarTemplate] = useState(hbTemplate2);
  const [enableDraft, setenableDraft] = useState(true);
  const [enableSubmit, setenableSubmit] = useState(false);
  const [comments, setComments] = useState("");
  const [commentsJson, setcommentsJson] = useState([]);
  const [page, setPage] = useState(0);
  const fileTypes = ["PDF"];
  const [additionalFile, setAdditionalFiles] = useState(null);
  const [isMerged, setIsMerged] = useState(false);
  const [isMergedRequired, setIsMergedRequired] = useState(false);
  const [fileJson, setFileJson] = useState([]);
  const [additionalFilePreview, setAdditionalFilePreview] = useState(false);
  const [clauseLibrary, setClauseLibrary] = useState([]);
  const [signatureVariables, setsignatureVariables] = useState([]);
  const [serializeCall, setSerializeCall] = useState("serializeTemplate");
  const [contractConditions, setContractConditions] = useState({});
  const [additionalFilePreviewUrl, setAdditionalFilePreviewUrl] =
    useState(null);
  const [additionalFileApi, setAdditionalFilesApi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reOpen, setReOpen] = useState(false);
  const [firstCall, setfirstCall] = useState(false);
  const [publishedTemplate, setpublishedTemplate] = useState(null);
  const [requestName, setRequestName] = useState("");
  const [isNameEditingReadonly, setIsNameEditingReadonly] = useState(true);
  const [disableContractVariable, setDisableContractVariable] = useState(false);
  const [contractStatus, setContractStatus] = useState(null);
  const [statusColor, setStatusColor] = useState("");

  const location = useLocation();
  const [statusHistory, setStatusHistory] = useState([]);
  const statusHistoryCall = async (id) => {
    setIsLoading(true);
    if (id) {
      let dataResult;
      let result = getCrudApi("contractStatusHistory/con/" + id, {});
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        if (dataResult.length > 1) {
          const maxObj = dataResult.reduce((accumulator, current) => {
            return accumulator.contractStatusHistoryId >
              current.contractStatusHistoryId
              ? accumulator
              : current;
          });
          const summary_array = dataResult?.reduce((acc, obj) => {
            const { contractStatus } = obj;
            acc[contractStatus] = (acc[contractStatus] || 0) + 1;
            return acc;
          }, {});
          let res = [];
          Object.keys(summary_array).map((status) => {
            if (maxObj.contractStatus === status) {
              res.push({
                [status]: summary_array[status],
                active: true,
              });
            } else {
              res.push({
                [status]: summary_array[status],
              });
            }
          });
          setStatusHistory(res);
        } else {
          const result = [
            {
              [dataResult[0].contractStatus]: 1,
              active: true,
            },
          ];
          setStatusHistory(result);
        }
      }
    }
    setIsLoading(false);
  };
  function getStepClasses(step, activeStep, active) {
    let cls = "step";
    if (active === true) {
      cls += " step-active";
    }
    // else if (activeStep === step) {
    //   cls += " step-done";
    // }
    else {
      cls += " step-done";
    }
    return cls;
  }
  const OnButtonClickAdditionalFile = async (id) => {
    setAdditionalFilePreview(true);
    let dataResult;
    let result = getCrudApi("ContractAdditionalFiles/getFile/" + id, {});
    await result.then(function (res) {
      dataResult = res;
    });
    setAdditionalFilePreviewUrl("data:application/pdf;base64," + dataResult);
  };
  const OnAdditionalFilePreviewClose = () => {
    setAdditionalFilePreview(false);
  };

  const handleMergedRequiredClick = () => {
    setIsMergedRequired(true);
  };

  const displayDraftedMessage = () => {
    toast.success("Drafted Successfully!");
  };

  const displaySubmittedMessage = () => {
    toast.success("Submitted Successfully!");
  };

  const displayReopenMessage = () => {
    toast.success("Reopend Successfully!");
  };

  const getAllFileNames = () => {
    let fName = "";
    Object.keys(additionalFile).map((eachfile) => {
      if (Number(eachfile) + 1 === Object.keys(additionalFile).length) {
        fName = fName + additionalFile[eachfile].name;
      } else {
        fName = fName + additionalFile[eachfile].name + ",";
      }
    });

    return fName;
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const setStatus = (status) => {
    switch (status) {
      case 5:
        setContractStatus("Drafted");
        setStatusColor("#7a5bb1");
        break;
      case 6:
        setContractStatus("Submitted");
        setStatusColor("#4A6C9D");
        break;
      case 7:
        setContractStatus("Approved");
        setStatusColor("#0C7BA7");
        break;
      case 8:
        setContractStatus("Reopened");
        setStatusColor("#3093b5");

        break;
      case 9:
        setContractStatus("Cancelled");
        break;
      case 10:
        setContractStatus("Expired");
        break;
      case 11:
        setContractStatus("Termination InProgress");
        setStatusColor("#C5857E");

        break;
      case 12:
        setContractStatus("Renewed");
        break;
      case 13:
        setContractStatus("Amended");
        setStatusColor("#009E82");

        break;
      case 14:
        setContractStatus("Negotiation");
        setStatusColor("#0C7BA7");

        break;
      case 15:
        setContractStatus("Negotiation Completed");
        break;
      case 18:
        setContractStatus("Executed");
        setStatusColor("#0C7BA7");

        break;
      case 19:
        setContractStatus("Terminated");
        setStatusColor("#C5857E");

        break;
    }
  };
  const handleChangeAdditionalFiles = (file) => {
    setAdditionalFiles(file);
    if (file.length > 1) {
      setIsMerged(false);
    } else {
      setIsMerged(true);
    }
    let FileArray = [];
    let temp;
    Object.keys(file).map(async (eachfile) => {
      await getBase64(file[eachfile]).then((data) => {
        temp = data;
      });
      let Fjson = {
        fileName: file[eachfile].name,
        fileContent: temp,
        fileExtension: file[eachfile].type,
      };
      FileArray.push(Fjson);
    });
    setFileJson(FileArray);
  };

  const RequestSubmission = async (stateFlag) => {
    setIsLoading(true);
    let array = {};
    let RequestVariables = {};

    array = JSONforVariables;
    let finajsonlocalvar = FinalJsonVariables;
    if (finajsonlocalvar !== undefined && finajsonlocalvar !== null) {
      Object.keys(finajsonlocalvar).forEach((groupkey) => {
        let Requests = {};
        if (groupkey && finajsonlocalvar[groupkey]) {
          Object.keys(finajsonlocalvar[groupkey]).forEach((e) => {
            if (array[groupkey] && groupkey) {
              Object.keys(array[groupkey]).some((fe) => {
                if (fe === e) {
                  finajsonlocalvar[groupkey][e] = array[groupkey][fe];
                  return true;
                } else {
                  return false;
                }
              });
            }
            let req = {};
            req = finajsonlocalvar[groupkey][e];
            Requests[e] = req;
          });
          RequestVariables[groupkey] = Requests;
        }
      });
      let serializedData = "";

      // if (serializeCall === "serializeTemplate") {
      //   const deserialized = deserialize(
      //     new DOMParser().parseFromString(handlebarTemplate, "text/html").body,
      //     {},
      //     clauseLibrary
      //   );

      //   serializedData = serializeContract({ children: deserialized });
      //   setHandlebarTemplate(serializedData);
      // } else {
      serializedData = handlebarTemplate;
      // }
      const deserializedDoc = deserializeContract(
        new DOMParser().parseFromString(handlebarTemplate, "text/html").body,
        {}
      );

      let htmlforHandlebar = convertToHandlebarFormat(
        { children: deserializedDoc },
        JSONforVariables,
        contractConditions
      );
      const executedDoc = executeHandlebar(htmlforHandlebar, JSONforVariables);
      let compiledContentJson = {
        finalDocument: executedDoc,
      };
      let contractCondition = {
        condition: contractConditions,
      };
      let template_json = {
        template: JSON.stringify(serializedData),
      };
      // let groupedRequestVar = {
      //   templateVariables: Requests,
      //   clauseVariables: Clauserequesr,
      // };
      let varaible_json = {
        var: RequestVariables, //groupedRequestVar,
      };
      let template_version_id;
      template_version_id =
        templateSelected !== null
          ? templateSelected.publishedTemplateVersionId
          : template_version !== null
          ? template_version
          : null;
      validateToken();
      let dataResult, template_settings;
      let result = getCrudApi("templateVersion/" + template_version_id, {});
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult !== null) {
        result = getCrudApi(
          "contractSettings/conTemp/" + dataResult.contractTemplateId,
          {}
        );
        await result.then(function (res) {
          template_settings = res;
        });
      }
      let RequestFormDraft = {
        contractName: requestName,
        signatureVariable: { signatureList: signatureVariables },
        contractConditions: contractCondition,
        contractFormData: varaible_json,
        contractTemplate: template_json,
        contractRequestFlag: 1,
        contractTemplateVersionId: templateSelected
          ? templateSelected.publishedTemplateVersionId
          : template_version,
        contractStatus: stateFlag === "Draft" ? 5 : 6,
        contractTypeId: templateSelected
          ? templateSelected.contractTypeId
          : contractData.contractTypeId,
        catogeryTypeId: templateSelected
          ? templateSelected.catogeryTypeId
          : contractData.catogeryTypeId,
        isApprovalRequired: template_settings
          ? template_settings.requestRequired
          : 4,
        templateTypeFlag: templateSelected
          ? templateSelected.templateTypeFlag
            ? templateSelected.templateTypeFlag
            : 9
          : contractData.templateTypeFlag,
        formStateFlag: 1,
        amendmentFormData: null,
        amendmentTemplate: null,
        amendedContractId: null,
        finalDocument: compiledContentJson,
        publishedTemplate: firstCall
          ? { finalDocument: publishedTemplate }
          : null,
        addtionalFilesFlag: fileJson.length > 0 ? 3 : 4,
      };

      if (contractData === null) {
        validateToken();
        result = postCrudApi("contract/contract", RequestFormDraft);
      } else {
        validateToken();
        result = putCrudApi(
          "contract/" + contractData.contractId,
          RequestFormDraft
        );
      }
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        setStatus(dataResult.contractStatus);
        statusHistoryCall(dataResult.contractId);
        setfirstCall(false);
        setSerializeCall("serializeContract");
        if (fileJson.length > 0) {
          let additionfileJson = {
            contractId: dataResult.contractId,
            files: fileJson,
            isMerged: fileJson.length > 1 ? (isMerged === true ? 1 : 2) : null,
          };

          await postCrudApi(
            "ContractAdditionalFiles/contractAdditionalFiles",
            additionfileJson
          );
        }
        if (stateFlag === "Draft") {
          displayDraftedMessage();
        } else if (stateFlag === "Submit") {
          setDisableContractVariable(true);
          displaySubmittedMessage();
        }
        setContractData(dataResult);
        //setJSON(dataResult.contractFormData.var);
        if (comments.trim() !== "") {
          let commentHistory = {
            contractHistoryComment: comments,
            contractId: dataResult.contractId,
            contractStatus: dataResult.contractStatus,
            commentHistoryFlag: 1,
          };
          if (commentHistory !== "") {
            await postCrudApi(
              "contractHistoryComment/contractHistoryComment",
              commentHistory
            );
          }
        }
        let CommentDataResult;
        let Commentresult = getCrudApi(
          "contractHistoryComment/contract/" + dataResult.contractId,
          {}
        );
        await Commentresult.then(function (res) {
          CommentDataResult = res;
        });
        CommentDataResult.sort(GetSortOrder("contractHistoryCommentId"));
        setcommentsJson(CommentDataResult);
        setComments("");
      } else {
        toast.error("Error Updating");
      }

      if (stateFlag === "Draft") {
        //let variableJson = updateDisabledKey(
        //   dataResult.contractFormData.var,
        //   "Main Template",
        //   false
        // );
        //  setJSON(filterRequests(variableJson))
      } else if (stateFlag === "Submit") {
        //let variableJson = updateDisabledKey(
        //   dataResult.contractFormData.var,
        //   "Main Template",
        //   true
        // );
        //  setJSON(filterRequests(variableJson))
        setenableSubmit(false);
        setenableDraft(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRequestFormDetails();
    statusHistoryCall(location?.state?.contractData?.contractId);
  }, []);

  const filterRequests = (data) => {
    let array = {};
    let Requests = {};
    array = data;
    if (array && array !== null) {
      Object.keys(array).forEach((e) => {
        let req = {};
        if (array[e].isRequest === true) {
          req = array[e];
          Requests[e] = req;
        }
      });
    }
    return Requests;
  };
  const onCommentsChange = (name, value) => {
    setComments(value);
  };
  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    filterType: "dropdown",
    search: false,
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "lastUpdatedDateTime",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},

    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {},
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const generateColumns = () => {
    let columnsArray = [];

    columnsArray.push({
      name: "contractHistoryComment",
      label: "Comment",
    });
    columnsArray.push({
      name: "lastUpdatedBy",
      label: "Commented By",
    });
    columnsArray.push({
      name: "lastUpdatedDateTime",
      label: "Commented On",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "Contract Status",
      options: {
        customBodyRender: (dataIndex) => {
          let color;
          const setStatus = (dataIndex) => {
            switch (dataIndex) {
              case "Drafted":
                color = "#1D907Fff";
                break;
              case "Submitted":
                color = "#3F66B0ff";
                break;
              case "Approved":
                color = "#4F96D5ff";
                break;
              case "Reopened":
                color = "#FBCD08ff";
                break;
              case "Termination InProgress":
                color = "#8FC742ff";
                break;
              case "Amended":
                color = "#F05623ff";

                break;
              case "Negotiation":
                color = "#9F4098ff";
                break;
              case "Executed":
                color = "#F7901Eff";
                break;
              case "Terminated":
                color = "#76ccba";

                break;
            }
            return color;
          };
          const state = dataIndex;
          return (
            <>
              <p
                className={"table-contract-request-status"}
                style={{ background: setStatus(state) }}
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    return columnsArray;
  };
  const showMessage = (message) => {
    // <Alert onClose={handleClose} severity="success">
    //   {message}
    // </Alert>;
  };

  const draftFullForm = () => {
    setOpen(true);
    RequestSubmission("Draft");
  };

  const submitFullForm = () => {
    setOpen(true);
    RequestSubmission("Submit");
  };

  const getClauseVariables = async (template) => {
    let result, clauseDataResult;
    validateToken();
    result = getCrudApi("clauses/all", {});
    await result.then(function (res) {
      clauseDataResult = res;
    });
    setClauseLibrary(clauseDataResult);
    const document = new DOMParser().parseFromString(template, "text/html");
    const clauseVariableIDs = deserializeClause(document.body, []);
    let clauseVariables = {};
    for (let id in clauseVariableIDs) {
      let selectedClause = clauseDataResult.filter(
        (item) => item["clauseId"] === clauseVariableIDs[id]
      );
      let newclauseVar = selectedClause[0].clauseVariables.var;
      clauseVariables = { ...clauseVariables, ...newclauseVar };
    }
    return [clauseVariables, clauseDataResult];
  };

  // const getStatus = async (contractId) => {
  //   let dataResult;
  //   let result = getCrudApi("contractStatusHistory/" + contractId);
  //   await result.then(function (res) {
  //     dataResult = res;
  //   });
  // };

  const getRequestFormDetails = async () => {
    setIsLoading(true);
    let template_version_id;
    let dataResult;
    setRequestName(location.state.requestName);
    settemplateSelected(location.state.templateSelected);
    settemplate_version(location.state.template_version);
    setTemplateType(location.state.templateType);

    if (location.state.tableData === null) {
      setfirstCall(true);
      template_version_id = location.state.templateSelected
        ? location.state.templateSelected.publishedTemplateVersionId
        : null;
      validateToken();
      let result = getCrudApi("templateVersion/" + template_version_id, {});
      await result.then(function (res) {
        dataResult = res;
      });
      let combinedRequestVar;
      if (dataResult !== null) {
        setpublishedTemplate(JSON.parse(dataResult.templateVer.template));
        let variables = dataResult.templateVerVariable.var;
        let partiesVariables = dataResult.templateVerBasicContractTemplate.var;
        let template = JSON.parse(dataResult.templateVer.template);
        let [clauseVariables, clauseData] = await getClauseVariables(template);

        if (dataResult.templateVerConditions) {
          let combinedConditions = {
            ContractConditions: dataResult.templateVerConditions.condition,
          };
          setContractConditions(combinedConditions);
        }
        let nontempvariable = { ...variables };
        // delete variables["nonTemplateVar"];
        // delete clauseVariables["nonTemplateVar"];

        const deserialized = deserialize(
          new DOMParser().parseFromString(template, "text/html").body,
          {},
          clauseData
        );
        const serializedData = serializeContract({ children: deserialized });
        setHandlebarTemplate(serializedData);
        // variables = updateDisabledKey(
        //   variables,
        //   "Main Template",
        //   false
        // );

        combinedRequestVar = {
          templateVariables: filterRequests(variables),
          clauseVariables: filterRequests(clauseVariables),
          // promisers: combinedPromiserVar,
          // promisees: combinedPromiseeVar,
          // nonTemplateVariables: nontempvariable.nonTemplateVar
          //   ? filterRequests(nontempvariable.nonTemplateVar)
          //   : {},
        };
        let combinedVariables = {
          templateVariables: variables,
          clauseVariables: clauseVariables,
          // nonTemplateVariables: nontempvariable.nonTemplateVar
          //   ? nontempvariable.nonTemplateVar
          //   : {},
        };

        Object.keys(partiesVariables).map((parties, idx) => {
          if (parties !== "basicDetails") {
            partiesVariables[parties].map((obj, id) => {
              //Object.keys(obj).map((objvar) => {
              if (parties === "promisers") {
                combinedRequestVar = {
                  ...combinedRequestVar,
                  ...{ ["promiser" + (id + 1)]: partiesVariables[parties][id] },
                };
                combinedVariables = {
                  ...combinedVariables,
                  ...{ ["promiser" + (id + 1)]: partiesVariables[parties][id] },
                };
              } else if (parties === "promisees") {
                combinedRequestVar = {
                  ...combinedRequestVar,
                  ...{ ["promisee" + (id + 1)]: partiesVariables[parties][id] },
                };
                combinedVariables = {
                  ...combinedVariables,
                  ...{ ["promisee" + (id + 1)]: partiesVariables[parties][id] },
                };
              }

              // });
            });
          } else if (parties === "basicDetails") {
            combinedRequestVar = {
              ...combinedRequestVar,
              ...{ basicDetails: partiesVariables[parties] },
            };
            combinedVariables = {
              ...combinedVariables,
              ...{ basicDetails: partiesVariables[parties] },
            };
          }
        });

        setJSON(combinedRequestVar);
        setFinalJsonVariables(combinedVariables);

        setsignatureVariables(dataResult.signatureVariable.signatureList);
      }
      setenableDraft(true);
      let formIsValid = true;
      for (let inputIdentifier in combinedRequestVar) {
        if (combinedRequestVar[inputIdentifier]) {
          Object.keys(combinedRequestVar[inputIdentifier]).map((fields) => {
            let localValid = checkValidity(
              combinedRequestVar[inputIdentifier][fields].elementConfig
                .placeholder,
              combinedRequestVar[inputIdentifier][fields].value,
              combinedRequestVar[inputIdentifier][fields].validation,
              combinedRequestVar[inputIdentifier]
            );
            formIsValid = localValid && formIsValid;
            // formIsValid =
            //   combinedRequestVar[inputIdentifier][fields].valid && formIsValid;
          });
        }
      }

      setenableSubmit(formIsValid);
      setContractData(null);
      setSerializeCall("serializeContract");
      setDisableContractVariable(false);
    } else {
      setStatus(location.state.contractData.contractStatus);
      statusHistoryCall(location?.state?.contractData?.contractId);
      let enableSubmit = true;
      setfirstCall(false);
      setSerializeCall("serializeContract");

      let contractResult = getCrudApi(
        "contract/" + location.state.contractData.contractId,
        {}
      );
      //getStatus(location.state.contractData.contractId);
      let contractDataResult;
      await contractResult.then(function (res) {
        contractDataResult = res;
      });
      if (contractDataResult) {
        settemplate_version(contractDataResult.contractTemplateVersionId);
      }
      if (
        contractDataResult.addtionalFilesFlag &&
        contractDataResult.addtionalFilesFlag === 3
      ) {
        let dataResultAdditionalFile;
        let resultAdditionalFile = getCrudApi(
          "ContractAdditionalFiles/contractId/" +
            location.state.contractData.contractId,
          {}
        );

        await resultAdditionalFile.then(function (res) {
          dataResultAdditionalFile = res;
        });
        setAdditionalFilesApi(dataResultAdditionalFile);
      }

      let template = JSON.parse(contractDataResult.contractTemplate.template);
      let variableJson = contractDataResult.contractFormData.var;
      if (contractDataResult.contractConditions) {
        setContractConditions(contractDataResult.contractConditions.condition);
      }
      setFinalJsonVariables(variableJson);
      let combinedRequestVar = {};
      Object.keys(variableJson).map((varGrp, id) => {
        if (varGrp === "templateVariables" || "clauseVariables") {
          combinedRequestVar = {
            ...combinedRequestVar,
            ...{ [varGrp]: filterRequests(variableJson[varGrp]) },
          };
        } else {
          combinedRequestVar = {
            ...combinedRequestVar,
            ...{ [varGrp]: variableJson[varGrp] },
          };
        }
      });
      setJSON(combinedRequestVar);
      setHandlebarTemplate(template);
      setContractData(contractDataResult);
      setsignatureVariables(contractDataResult.signatureVariable.signatureList);

      if (
        contractDataResult.contractStatus !== 5 &&
        contractDataResult.contractStatus !== 8
      ) {
        // variableJson = updateDisabledKey(
        //   variableJson,
        //   "Main Template",
        //   true
        // );
        //setJSON(filterRequests(variableJson))
        setenableDraft(false);
        setenableSubmit(false);
        enableSubmit = false;
        setDisableContractVariable(true);
      } else {
        setDisableContractVariable(false);
        setenableDraft(true);
        setenableSubmit(true);
        enableSubmit = true;
      }
      //getcomments
      let result = getCrudApi(
        "contractHistoryComment/contract/" + contractDataResult.contractId,
        {}
      );
      await result.then(function (res) {
        dataResult = res;
      });
      dataResult.sort(GetSortOrder("contractHistoryCommentId"));
      setcommentsJson(dataResult);
      setComments("");
      let formIsValid = true;
      for (let inputIdentifier in combinedRequestVar) {
        if (combinedRequestVar[inputIdentifier]) {
          Object.keys(combinedRequestVar[inputIdentifier]).map((fields) => {
            let localValid = checkValidity(
              combinedRequestVar[inputIdentifier][fields].elementConfig
                .placeholder,
              combinedRequestVar[inputIdentifier][fields].value,
              combinedRequestVar[inputIdentifier][fields].validation,
              combinedRequestVar[inputIdentifier]
            );
            formIsValid = localValid && formIsValid;
            // formIsValid =
            //   combinedRequestVar[inputIdentifier][fields].valid && formIsValid;
          });
        }
      }
      setenableSubmit(enableSubmit && formIsValid);
    }
    setIsLoading(false);
  };
  const handleReOpen = async () => {
    setIsLoading(true);
    let contractStatus = {
      contractStatus: 8,
    };
    let dataResult;
    let result = putCrudApi(
      "contract/contractStatus/" + contractData.contractId,
      contractStatus
    );
    await result.then(function (res) {
      dataResult = res;
    });
    setContractData(dataResult);
    setenableDraft(true);
    setenableSubmit(true);
    setDisableContractVariable(false);
    statusHistoryCall(contractData.contractId);
    displayReopenMessage();
    setIsLoading(false);
  };

  const Status = [
    { id: 1, name: "Drafted" },
    { id: 2, name: "Negotiate" },
    { id: 3, name: "Negotiation" },
    { id: 4, name: "submitted" },
    { id: 5, name: "reopeoned" },
    { id: 6, name: "termination" },
    { id: 7, name: "Exceuted" },
  ];
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="contractName">
            <div
            //  className="contract-request-div"
            >
              {contractStatus !== null && contractStatus.trim() !== "" ? (
                <></>
              ) : (
                // <span
                //   // className="add-contract-status"
                //   style={{ background: statusColor }}
                // >
                //   <i class="fa fa-check statusCheck"></i> {contractStatus}
                // </span>
                <></>
              )}
            </div>
            <div style={{ width: "100%" }}>
              <input
                className="header-div"
                value={requestName}
                readOnly={
                  isNameEditingReadonly || (!enableDraft && !enableSubmit)
                }
                onChange={(e) => setRequestName(e.target.value)}
                onDoubleClick={() => {
                  setIsNameEditingReadonly(!isNameEditingReadonly);
                }}
              ></input>
            </div>
            <div className="formbutton">
              {enableDraft ? (
                <button
                  className="contracts-btn"
                  onClick={() => draftFullForm()}
                  disabled={!enableDraft}
                >
                  Draft
                </button>
              ) : null}
              {enableSubmit ? (
                <button
                  className="contracts-btn"
                  onClick={() => submitFullForm()}
                  disabled={!enableSubmit}
                >
                  Submit
                </button>
              ) : null}
              {contractData &&
              (contractData.contractStatus === 6 ||
                contractData.contractStatus === 7) &&
              contractData.formStateFlag === 1 ? (
                <button className="contracts-btn" onClick={handleReOpen}>
                  Re-open
                </button>
              ) : null}
            </div>
          </div>
          <div className="steps-container">
            {statusHistory?.map((status, index) => {
              return (
                // <li className="active" style={{
                //   content: status?.statusCount, counterIncrement: status?.statusCount
                // }}>{status?.contractStatus} {status?.statusCount}</li>
                <div
                  className={getStepClasses(
                    index,
                    statusHistory?.length - 1,
                    status?.active
                  )}
                  key={index}
                >
                  <div>
                    <div className="circle">{`${
                      Object.values(status)[0]
                    }`}</div>
                  </div>
                  <div className="label">{`${Object.keys(status)[0]}`}</div>
                  {index !== statusHistory?.length - 1 ? (
                    <div className="line"></div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div>
            {JSONforVariables ? (
              <DynamicForm
                formFields={JSONforVariables}
                buttonBackgroundColor="#0000ff"
                buttonColor="#fff"
                formBorderColorOnFocus="#0000ff"
                errorTextColor="#dc143c"
                getForm={(form) => {
                  setJSON(form);
                }}
                setSubmitDisabled={(val) => {
                  setenableSubmit(val);
                }}
                disableContractVariable={disableContractVariable}
                disableTerminationVariable={true}
              />
            ) : null}
            <div className="file-uploader">
              <label className="commentLabel">Additional File Upload</label>
              <div className="file-div">
                {enableSubmit === true || enableDraft === true ? (
                  <>
                    <FileUploader
                      className="fileUpload"
                      multiple={true}
                      color="#48336e"
                      fileOrFiles={additionalFile}
                      handleChange={handleChangeAdditionalFiles}
                      name="file"
                      types={fileTypes}
                    />
                    {isMerged ? (
                      <div>
                        <label className="file-upload-label">
                          Add to Contract
                        </label>
                        <Checkbox
                          size={3}
                          borderThickness={1}
                          backAnimationDuration={10}
                          tickAnimationDuration={100}
                          color="#48336e"
                          className="add-to-contract-checkbox"
                          checked={isMergedRequired}
                          onChange={handleMergedRequiredClick}
                        ></Checkbox>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="fileNameDisplay">
                {enableSubmit === true || enableDraft === true ? (
                  <>
                    {" "}
                    <p>
                      {additionalFile ? "File Name:" + getAllFileNames() : null}
                    </p>
                  </>
                ) : (
                  <></>
                )}
                {additionalFileApi ? (
                  <div className="fileDisplay">
                    {Object.keys(additionalFileApi).map((obj) => {
                      return (
                        <div>
                          <button
                            onClick={() => {
                              OnButtonClickAdditionalFile(
                                additionalFileApi[obj].contractAdditionalFileId
                              );
                            }}
                          >
                            <div className="fileDisplay-name">
                              <i
                                className="far fa-file-pdf"
                                style={{ fontSize: "40px", color: "red" }}
                              ></i>
                            </div>
                            <div>
                              <label className="ActiveLabel">
                                {additionalFileApi[obj].fileName}
                              </label>
                            </div>
                          </button>
                        </div>
                      );
                    })}{" "}
                  </div>
                ) : additionalFile !== null ? (
                  <> </>
                ) : (
                  <label className="commentLabel"> No files to show</label>
                )}
              </div>
            </div>
            <div className="comment-table">
              <label className="commentLabel">Comment</label>
              <TextArea
                className="TextAreaStyle"
                value={comments}
                onChange={(e) =>
                  onCommentsChange(e.target.name, e.target.value)
                }
              ></TextArea>
            </div>
            <div className="comment-table">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={commentsJson}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}

      {additionalFilePreview ? (
        <div className="modal customModal">
          <div className="print-preview">
            {additionalFilePreviewUrl !== null ? (
              // <div className="pdfPop">
              //   {/* <div >
              //   <AllPagesPDFViewer pdf={additionalFilePreviewUrl} />
              // </div> */}
              //   <div>
              <iframe
                id="scaled-frame"
                title="pdfviewer"
                style={{ margin: "auto", width: "1024px", height: "950px" }}
                src={additionalFilePreviewUrl}
              />
            ) : (
              //   </div>
              // </div>
              <Loading />
            )}
            <div>
              <button type="button" onClick={OnAdditionalFilePreviewClose}>
                <i
                  className="fa fa-window-close"
                  style={{ fontSize: "48px", color: "red" }}
                ></i>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
