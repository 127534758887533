import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { statusData: {} };

export const statusSlice = createSlice({
  name: "statusState",
  initialState: { value: initialStateValue },
  reducers: {
    setStatusData: (state, action) => {
      state.value.statusData = action.payload;
    },
  },
});

export const { setStatusData } = statusSlice.actions;

export default statusSlice.reducer;
