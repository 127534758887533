import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import TypeChecker from "typeco";
import styled, { css } from "styled-components";
import {
  updateObject,
  checkValidity,
  calculateExpiry,
  quickSort,
} from "./shared/helper";

import Input from "./components/Input/Input";

const GridFormStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border-radius: 18px;
`;

const Form = styled.form`
  ${GridFormStyle}
`;

export default class DynamicFormGenerator extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    text: PropTypes.object,
  };

  state = {
    formFields: this.props.formFields,
    formIsValid: false,
    formDisabled: false,
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.formFields !== this.props.formFields) {
      this.setState({
        formFields: this.props.formFields,
      });
    }
  }
  componentDidMount() {
    const { formFields } = this.props;

    this.setState({
      formFields: formFields,
      formDisabled: this.props.formDisabled,
    });
  }

  handleRowClick = (event, obj, inputIdentifier, parentName) => {
    var searchBar = this.state.formFields[parentName][inputIdentifier];
    event.preventDefault();
    var selectedObj = obj;
    if (selectedObj !== null) {
      var populateField = searchBar.elementConfig.lookupConfig.populateFields;
      this.state.formFields[parentName][inputIdentifier].value =
        selectedObj[searchBar.elementConfig.lookupConfig.lookupId];
      let updatedFormElement = updateObject(
        this.state.formFields[parentName][inputIdentifier],
        {
          value: selectedObj[searchBar.elementConfig.lookupConfig.lookupId],
        }
      );
      let updatedFormFields = updateObject(this.state.formFields[parentName], {
        [inputIdentifier]: updatedFormElement,
      });
      let updatedForm = updateObject(this.state.formFields, {
        [parentName]: updatedFormFields,
      });
      this.setState({ formFields: updatedForm });

      for (let i in populateField) {
        var masterCol = populateField[i].masterDataCol;
        var variableName = populateField[i].varName;

        if (
          this.state.formFields[parentName][variableName].elementType ===
            "input" ||
          this.state.formFields[parentName][variableName].elementType ===
            "textarea"
        )
          this.state.formFields[parentName][variableName].value =
            selectedObj[masterCol];
      }
    }

    // } else {
    //   this.state.formFields[parentName][inputIdentifier].elementConfig.data =
    //     data;
    // }
    this.inputChangedHandler(
      searchBar.elementType,
      searchBar.elementConfig.placeholder,
      event,
      inputIdentifier,
      selectedObj[searchBar.elementConfig.lookupConfig.lookupId],
      NaN,
      parentName
    );
  };

  onSearchFieldChange = (
    event,
    ColumnToFilter,
    inputIdentifier,
    parentName
  ) => {
    var selectSearch = this.state.formFields[parentName][inputIdentifier];
    selectSearch.elementConfig.lookupConfig.updatedData = TypeChecker.isEmpty(
      event.target.value
    )
      ? selectSearch.elementConfig.lookupConfig.data
      : selectSearch.elementConfig.lookupConfig.data.filter((item) =>
          item[ColumnToFilter].toLowerCase().includes(
            event.target.value.toLowerCase()
          )
        );
  };

  inputChangedHandlerCustomState = (
    elementType,
    placeholder,
    event,
    inputIdentifier,
    searchBarValue,
    signNum,
    parentName,
    state
  ) => {
    if (elementType !== "date" && elementType !== "currency") {
      event.persist();
    }
    let value = searchBarValue !== null ? searchBarValue : event.target.value;
    //let { value } = event.target;

    if (event.target && event.target.files) {
      state.formFields[parentName][inputIdentifier].selectedFileObj =
        event.target.files[0];
    }

    let updatedFormElement = updateObject(
      state.formFields[parentName][inputIdentifier],
      {
        value: value,
        valid: checkValidity(
          placeholder,
          value,
          state.formFields[parentName][inputIdentifier].validation,
          state.formFields[parentName],
          elementType
        ),
        touched: true,
      }
    );

    let updatedFormFields = updateObject(state.formFields[parentName], {
      [inputIdentifier]: updatedFormElement,
    });

    let updatedForm = updateObject(state.formFields, {
      [parentName]: updatedFormFields,
    });

    if (elementType === "checkbox") {
      const copyArray = [
        ...state.formFields[parentName][inputIdentifier].elementConfig.options,
      ];
      const modifiedArray = copyArray.map((option) => {
        if (value === option.value) {
          option.checked = !option.checked;
        }
      });
      const updatedFormElement = updateObject(
        state.formFields[parentName][inputIdentifier].elementConfig.options,
        {
          options: modifiedArray,
        }
      );
      const updatedFormFields = updateObject(state.formFields[parentName], {
        [inputIdentifier]: updatedFormElement,
      });
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      if (updatedForm[inputIdentifier]) {
        Object.keys(updatedForm[inputIdentifier]).map((fields) => {
          let localValid = checkValidity(
            updatedForm[inputIdentifier][fields].elementConfig.placeholder,
            updatedForm[inputIdentifier][fields].value,
            updatedForm[inputIdentifier][fields].validation,
            updatedForm[inputIdentifier],
            updatedForm[inputIdentifier][fields].elementType
          );
          formIsValid = localValid && formIsValid;
        });
      }
    }

    // this.setState({
    //   formFields: updatedFormFields,
    //   formIsValid: formIsValid,
    // });
    this.setState({
      formFields: updatedForm,
      formIsValid: formIsValid,
    });

    this.props.getForm(updatedForm);
    this.props.setSubmitDisabled(formIsValid);
  };

  inputChangedHandler = (
    elementType,
    placeholder,
    event,
    inputIdentifier,
    searchBarValue,
    signNum,
    parentName
  ) => {
    if (elementType !== "date" && elementType !== "currency") {
      event.persist();
    }
    let value = searchBarValue !== null ? searchBarValue : event.target.value;
    //let { value } = event.target;

    if (event.target && event.target.files) {
      this.state.formFields[parentName][inputIdentifier].selectedFileObj =
        event.target.files[0];
    }

    let updatedFormElement = updateObject(
      this.state.formFields[parentName][inputIdentifier],
      {
        value: value,
        valid: checkValidity(
          placeholder,
          value,
          this.state.formFields[parentName][inputIdentifier].validation,
          this.state.formFields[parentName],
          elementType
        ),
        touched: true,
        calculation: false,
      }
    );

    let updatedFormFields = updateObject(this.state.formFields[parentName], {
      [inputIdentifier]: updatedFormElement,
    });

    let updatedForm = updateObject(this.state.formFields, {
      [parentName]: updatedFormFields,
    });

    if (elementType === "checkbox") {
      const copyArray = [
        ...this.state.formFields[parentName][inputIdentifier].elementConfig
          .options,
      ];
      const modifiedArray = copyArray.map((option) => {
        if (value === option.value) {
          option.checked = !option.checked;
        }
      });
      const updatedFormElement = updateObject(
        this.state.formFields[parentName][inputIdentifier].elementConfig
          .options,
        {
          options: modifiedArray,
        }
      );
      const updatedFormFields = updateObject(
        this.state.formFields[parentName],
        {
          [inputIdentifier]: updatedFormElement,
        }
      );
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      if (updatedForm[inputIdentifier]) {
        Object.keys(updatedForm[inputIdentifier]).map((fields) => {
          let localValid = checkValidity(
            updatedForm[inputIdentifier][fields].elementConfig.placeholder,
            updatedForm[inputIdentifier][fields].value,
            updatedForm[inputIdentifier][fields].validation,
            updatedForm[inputIdentifier],
            updatedForm[inputIdentifier][fields].elementType
          );
          formIsValid = localValid && formIsValid;
        });
      }
    }

    this.setState({
      formFields: updatedForm,
      formIsValid: formIsValid,
    });

    this.props.getForm(updatedForm);
    this.props.setSubmitDisabled(formIsValid);
    if (parentName === "basicDetails" && inputIdentifier === "startDate") {
      if (this.state.formFields[parentName]["expiryDate"].calculation) {
        let calculatedExpiryDate = calculateExpiry(
          value,
          this.state.formFields[parentName]["expiryDate"].expiryDuration
        );
        this.inputChangedHandlerCustomState(
          "date",
          this.state.formFields[parentName]["expiryDate"].elementConfig
            .placeholder,
          {
            target: { value: calculatedExpiryDate },
          },
          "expiryDate",
          null,
          NaN,
          "basicDetails",
          {
            formFields: updatedForm,
          }
        );
      }
    }
  };

  render() {
    let groups = [];
    for (let key in this.state.formFields) {
      //non signature vars
      groups.push({
        parentName: key,
        variables: this.state.formFields[key],
        disabled:
          key === "templateVariables" ||
          key === "clauseVariables" ||
          key === "AmendmentVariables" ||
          key.includes("promisee") ||
          key.includes("promiser") ||
          key === "basicDetails"
            ? this.props.disableContractVariable
            : this.props.disableTerminationVariable,
        // disabled:
        //   key === "templateVariables" &&
        //     this.state.formFields["AmendmentVariables"] &&
        //     Object.keys(this.state.formFields["AmendmentVariables"]).length !== 0
        //     ? true
        //     : key === "templateVariables" ||
        //       key === "clauseVariables" ||
        //       key === "AmendmentVariables"
        //       ? this.props.disableContractVariable
        //       : this.props.disableTerminationVariable,
      });
    }
    let form = (
      <div className="Dynamic-Form">
        {groups
          ? groups.map((fL, idx) => {
              if (fL.variables) {
                let sortedParties = quickSort(
                  Object.keys(fL.variables),
                  fL.variables
                );
                return (
                  <>
                    {Object.keys(fL.variables).length !== 0 ? (
                      <div
                        className="Dynamic-Form-Element-Group Dynamic-Form-Headings"
                        key={idx}
                      >
                        <div className="ribbon-header">
                        <label className="contract-header">
                          {" "}
                          {fL.parentName === "templateVariables"
                            ? "Contract"
                            : fL.parentName === "clauseVariables"
                            ? "Clause"
                            : fL.parentName === "AmendmentVariables"
                            ? "Amendment"
                            : fL.parentName === "TerminationVariables"
                            ? "Termination"
                            : fL.parentName.includes("promisee")
                            ? "Promisee " + fL.parentName.split("promisee")[1]
                            : fL.parentName.includes("promiser")
                            ? "Promiser " + fL.parentName.split("promiser")[1]
                            : fL.parentName === "basicDetails"
                            ? "Basic Details"
                            : fL.parentName}
                        </label>
                        </div>
                        <div className="Dynamic-Form-elements">
                          {sortedParties.map((formElement, id) => (
                            <Input
                              key={fL.variables[formElement].uuid}
                              dateFormat={fL.variables[formElement]?.format}
                              disabled={
                                fL.disabled === true
                                  ? fL.disabled
                                  : fL.variables[formElement].disabled
                                  ? fL.variables[formElement].disabled
                                  : false
                              }
                              formBorderColorOnFocus={
                                this.props.formBorderColorOnFocus
                              }
                              errorTextColor={this.props.errorTextColor}
                              elementType={
                                fL.variables[formElement].elementType
                              }
                              elementConfig={
                                fL.variables[formElement].elementConfig
                              }
                              value={fL.variables[formElement].value}
                              currencyType={
                                fL.variables[formElement].currencyType
                              }
                              invalid={!fL.variables[formElement].valid}
                              shouldValidate={
                                fL.variables[formElement].validation
                              }
                              rowClicked={(ev, obj) =>
                                this.handleRowClick(
                                  ev,
                                  obj,
                                  formElement,
                                  fL.parentName
                                )
                              }
                              searchChanged={(ev) =>
                                this.onSearchFieldChange(
                                  ev,
                                  fL.variables[formElement].elementConfig
                                    .lookupConfig.lookupId,
                                  formElement,
                                  fL.parentName
                                )
                              }
                              touched={fL.variables[formElement].touched}
                              changed={(event) =>
                                this.inputChangedHandler(
                                  fL.variables[formElement].elementType,
                                  fL.variables[formElement].elementConfig
                                    .placeholder,
                                  event,
                                  formElement,
                                  null,
                                  !isNaN(
                                    parseInt(
                                      fL.variables[
                                        formElement
                                      ].subGroup?.replace("Signer", "")
                                    )
                                  )
                                    ? parseInt(
                                        fL.variables[
                                          formElement
                                        ].subGroup?.replace("Signer", "")
                                      )
                                    : NaN,
                                  fL.parentName
                                )
                              }
                            />
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              }
            })
          : null}
      </div>
    );

    return <div>{form}</div>;
  }
}
