import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Checkbox from "react-simple-checkbox";
import { validateToken } from "../../../Helpers/validateToken";
import {
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "./ChildTable.css";
import MUIDataTable from "mui-datatables";
import Loading from "../../../Loading/Loading";

export default function OldChildTable(props) {
  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [masterTableData, setMasterTableData] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [selectedTableData, setSelectedTableData] = useState(null);
  const [EditTableModalOpen, setEditTableModalOpen] = useState(false);
  const [newRowName, setNewRowName] = useState("");
  const [tableTitle, setTableTitle] = useState("");
  const [rowIndex, setRowIndex] = useState(-1);
  const [editRow, setEditRow] = useState(true);
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [tableColumns, setTableColumns] = useState({});
  const [checked, setChecked] = useState(true);
  const [masterLibraryName, setMasterLibraryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const handleActiveClick = () => {
    setChecked(!checked);
  };

  const displaySubmittedMessage = () => {
    toast.success("Submitted Successfully!");
  };

  const generateColumns = () => {
    let columnsArray = [];
    // let firstCol = {
    //   name: "Edit Row",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     empty: true,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <button onClick={() => onEditClick(tableMeta, value)}>
    //           <i className="fa fa-edit custom-iconformodal" />
    //         </button>
    //       );
    //     },
    //   },
    // };
    // columnsArray.push(firstCol);

    Object.keys(tableColumns).forEach((el) => {
      // location.state.columns.map((el) => {
      let columnNameObj = {
        name: el,
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <tr>
                <td style={{ padding: "0px" }}>
                  <div style={{ display: editRow ? "none" : "block" }}>
                    {value}
                  </div>
                  <input
                    className="Input-box"
                    style={{ display: editRow ? "block" : "none" }}
                    value={value}
                    onChange={(e) => {
                      handleEditRrow(e, el, tableMeta);
                    }}
                  />
                </td>
              </tr>
            );
          },
        },
      };
      // columnNameObj.name = el;

      columnsArray.push(columnNameObj);
    });
    return columnsArray;
  };

  const handleEditRrow = (e, val2, tableMeta) => {
    let dummytab = [...tableData];
    dummytab[tableMeta.rowIndex][val2] = e.target.value;
    setTableData(dummytab);
  };
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const onEditClick = (tableMeta, value) => {
    // setRowIndex(tableMeta.rowIndex);
    // setEditModalOpen(true);
  };

  const OnAddChildClose = (e) => {
    let copy_tableData = [...tableData];
    let newrow = {};
    Object.keys(tableColumns).forEach((lable) => {
      newrow[lable] = "";
    });
    copy_tableData.push(newrow);
    setTableData(copy_tableData);
  };

  const OnEditMasterTableOpen = () => {
    setEditTableModalOpen(true);
  };

  const OnEditMasterTableClose = (e) => {
    setMasterLibraryName(masterLibraryName);

    setEditTableModalOpen(false);
  };

  const OnEditChildClose = () => {
    let editedData = [...tableData];
    editedData[rowIndex] = {
      ...editedData[rowIndex],
      childName: newRowName,
    };
    // setTableData(editedData);
    setEditModalOpen(false);
  };
  const onSubmitClick = async () => {
    setIsLoading(true);
    setOpen(true);
    // let LibraryJson = {};
    // tableData.map((row) => {
    //   // LibraryJson = Object.assign(LibraryJson, row);
    //   // LibraryJson.push(row);
    // });
    //
    const filterTableData = tableData.filter((row) => {
      for (const key in row) {
        if (row[key] !== "") {
          return true;
        }
      }
      return false;
    });
    let lib_json = {
      lib_table: filterTableData,
    };
    setTableData(filterTableData);
    let newTable = {
      library_table_name: masterLibraryName,
      status_id: checked === true ? 1 : 2,
      library_table: lib_json,
    };

    let result;
    if (tableData.length !== 0) {
      if (selectedTableData == null) {
        validateToken();
        result = postCrudApi("library/library", newTable);
      } else {
        validateToken();
        result = putCrudApi(
          "library/" + selectedTableData.library_table_id,
          newTable
        );
      }

      let dataResult;
      await result.then(function (res) {
        dataResult = res;
      });

      if (dataResult) {
        displaySubmittedMessage();
      }
      setSelectedTableData(dataResult);
      if (dataResult != null) {
        let library_id = dataResult.library_table_id;
        let libraryHistory = {
          library_history: lib_json,
          library_id: library_id,
        };
        validateToken();
        await postCrudApi("libraryHistory/libraryHistory", libraryHistory);
        // navigate({
        //   pathname: "/Admin/masterlibrary",
        //   state: {},
        // });
      }
    } else {
      toast.error("Table should have a minimum of one row");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    //TODO - api call to get initial child table info
    setTableTitle(location.state.libraryName);
    setMasterLibraryName(location.state.libraryName);
    setSelectedTableData(location.state.selectedTableData);
    if (location.state.excelData !== null) {
      const excelData = location.state.excelData;
      if (excelData.length !== 0) {
        let colName = {};
        Object.keys(excelData[0]).map((col) => {
          colName[col] = "";
        });
        setTableColumns(colName);
      }
    } else if (location.state.columns != null) {
      let colName = {};
      location.state.columns.map((el) => {
        colName[el.name] = "";
      });
      setTableColumns(colName);
    } else {
      let colName = {};
      if (location.state.tableData != null) {
        if (location.state.tableData[5].lib_table.length !== 0) {
          let row = location.state.tableData[5].lib_table[0];
          Object.keys(row).forEach((el) => {
            colName[el] = "";
          });
        }
      }
      setTableColumns(colName);
    }
    setChecked(
      location.state.tableData != null
        ? location.state.tableData[4] === 1
          ? true
          : false
        : true
    );
    setTableData(
      location.state.excelData !== null
        ? location.state.excelData
        : location.state.tableData != null
        ? location.state.tableData[5].lib_table
        : []
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="Edit-btn"
              onClick={OnEditMasterTableOpen}
            >
              Edit
            </button>
          </div>

          <div className="master-datatable">
            <MuiThemeProvider>
              <MUIDataTable
                title={masterLibraryName}
                data={tableData}
                columns={generateColumns()}
                options={options}
              />
            </MuiThemeProvider>
          </div>
          <div className="master-div">
            <button
              type="button"
              className="add_col"
              onClick={() => OnAddChildClose()}
            >
              Add Row
            </button>
            <button type="button" className="add_col" onClick={onSubmitClick}>
              Submit
            </button>
          </div>

          {EditModalOpen ? (
            <div className="modal">
              <div className="modal-content">
                <div>
                  <label>Update Row Details</label>
                  <input
                    type="text"
                    onChange={(e) => setNewRowName(e.target.value)}
                  />
                </div>

                <div>
                  <button
                    type="button"
                    className="close-btn"
                    onClick={() => setEditModalOpen(false)}
                  >
                    Close
                  </button>
                  {/* <button
                type="button"
                className="btn btn-outline-primary btn-lg mt-3"
                onClick={OnEditChildClose}
              >
                Update Row
              </button> */}
                </div>
              </div>
            </div>
          ) : null}

          {EditTableModalOpen ? (
            <div className="modal">
              <div className="modal-content ">
                <div className="header-modal">
                  <h5 className="header-title"> Edit Details</h5>
                </div>
                <div className="div-display">
                  <label className="label-class">New Table</label>

                  <input
                    type="text"
                    value={masterLibraryName}
                    placeholder="New Table"
                    className="textbox-input_1"
                    onChange={(e) => setMasterLibraryName(e.target.value)}
                  />
                </div>

                <div style={{ display: "flex" }} className="div-display">
                  <label className="label-class">Active</label>
                  <div>
                    <Checkbox
                      size={3}
                      borderThickness={1}
                      backAnimationDuration={10}
                      tickAnimationDuration={100}
                      color="#48336e"
                      className="Active-checkbox"
                      checked={checked}
                      onChange={handleActiveClick}
                    ></Checkbox>
                  </div>
                </div>

                <div className="footer-modal">
                  <button
                    type="button"
                    className="close-btn"
                    onClick={() => setEditTableModalOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="Modal-btn"
                    onClick={OnEditMasterTableClose}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
