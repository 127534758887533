import React, { useState, useEffect, useRef, useMemo } from "react";
import DynamicForm from "../dynamicForm/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
//import Handlebars from "handlebars";
import { FileUploader } from "react-drag-drop-files";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import { validateToken } from "../../Helpers/validateToken";
import { convertHierarchy } from "../../Helpers/hierarchyConverter";
import Checkbox from "react-simple-checkbox";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Select from "react-select";
import SyfedTreeSelect from "syfed-tree-select";
import "./DynamicFormForContract.css";
import { TextArea } from "semantic-ui-react";
import { createTheme } from "@material-ui/core";
import OptimisedMUIDataTable from "./OptimisedMUIDataTable";

import SearchField from "./SearchField/SearchField";
import { RecoilRoot } from "recoil";
import PlateEditor from "../../PlateEditor/PlateEditor";

import {
  deserialize,
  deserializeClause,
  deserializeContract,
} from "../../PlateEditor/slateHelper/deserialize";
import { serializeContract } from "../../PlateEditor/slateHelper/serializeHtml";

import {
  convertToHandlebarFormat,
  executeHandlebar,
} from "../../Helpers/Handlebar/HandlebarHelper";
import Loading from "../../Loading/Loading";
import { checkValidity } from "../dynamicForm/shared/helper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const hbTemplate2 =
  "<p>this is static template and should not appear if the contract is based on a 'with template' option clear and {{#cusif 'notequal' hhh 'rr'}}start{{/cusif}} {{typing}} {{here}}&nbsp; hhh kk {{dddd}}</p>";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function DynamicFormForContract(props) {
  const [siginigMethodsTypes, setSiginigMethodsTypes] = useState([
    { label: "Manual", value: "manual" },
    { label: "DocuSign", value: "DocuSign" },
    { label: "AdobeSign", value: "AdobeSign" },
  ]);

  const [signatureVariables, setSignatureVariables] = useState([]);
  const [terminationSignatureVariables, setTerminationSignatureVariables] =
    useState([]);
  const fileTypes = ["PDF"];
  const [file, setFile] = useState(null);
  const [signingMethod, setsigningMethod] = useState("manual");
  const [negotiationOptions, setNegotiationOptions] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const [negotiationRequired, setnegotiationRequired] = useState(false);
  // const [negotiationRequired, setnegotiationRequired] = useState(false);
  const [negotiationTemplate, setnegotiationTemplate] = useState("");
  const [negotiationComments, setnegotiationComments] = useState([]);
  // const [pageMargins, setPageMargins] = useState("10mm 15mm");
  const [openTemplatePreview, setOpenTemplatePreview] = useState(false);
  const [AmendmentEnabled, setAmendmentEnabled] = useState(true);
  const [TerminationEnabled, setTerminationEnabled] = useState(true);
  const [templateSelected, settemplateSelected] = useState();
  const [template_version, settemplate_version] = useState();
  const [JSONforVariables, setJSON] = useState();
  const [disableForm, setdisableForm] = useState(false);
  const navigate = useNavigate();
  const [handlebarTemplate, setHandlebarTemplate] = useState(hbTemplate2);
  const [handlebarTemplateAmendment, sethandlebarTemplateAmendment] =
    useState(hbTemplate2);
  const [handlebarTemplateTermination, setHandlebarTemplateTermination] =
    useState(hbTemplate2);
  const [value, setValue] = useState(0);
  const [pageSize, setPageSize] = useState("A4");
  const [updatedContent, setUpdatedContent] = useState(handlebarTemplate);
  const [enableDraft, setenableDraft] = useState(true);
  const [enableSubmit, setenableSubmit] = useState(false);
  const [enableAddReviewe, setenableAddReviewe] = useState(false);
  const [enableExecute, setenableExecute] = useState(false);
  const [ReviewerList, setReviewerList] = useState([]);
  const [contractOwnerList, setContractOwnerList] = useState([]);
  const [selectedReviewer, setselectedReviewer] = useState();
  const [selectedOwner, setselectedOwner] = useState(null);
  const [selectedNegotiator, setselectedNegotiator] = useState("");
  const [AddReviewe, setAddReviewe] = useState(false);
  const [addNegotiation, setaddNegotiation] = useState(false);
  const [execution, setexecution] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [comments, setComments] = useState("");
  const [commentsJson, setcommentsJson] = useState([]);
  const [page, setPage] = useState(0);
  // const [numPages, setNumPages] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [emailInputs, setEmailInputs] = useState([]);
  const [nameInputs, setNameInputs] = useState([]);
  const [termEmailInputs, settermEmailInputs] = useState([]);
  const [termNameInputs, settermNameInputs] = useState([]);
  const [contractName, setContractName] = useState("");
  const [clauseLibrary, setClauseLibrary] = useState([]);
  // const [reOpen, setReOpen] = React.useState(false);
  // const inputFile = useRef(null);
  // const modalRef = useRef(null);
  // const canvasRef = useRef(null);
  // const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [templateType, setTemplateType] = useState(0);
  const [disableNegoAdd, setdisableNegoAdd] = useState(false);
  const [AddAmendmentOpen, setAddAmendmentOpen] = useState(false);
  const [AddTerminationOpen, setAddTerminationOpen] = useState(false);
  const [finalDocument, setFinalDocument] = useState("");
  const [TermFinalDocument, setTermFinalDocument] = useState("");
  const [isExecutedPreview, setIsExecutedPreview] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [IsExecutedPreviewTermination, setIsExecutedPreviewTermination] =
    useState(false);
  const [PdfExecuteUrlTermination, setPdfExecuteUrlTermination] =
    useState(null);
  const [pdfExecuteUrl, setPdfExecuteUrl] = useState(null);
  const [serializeCall, setSerializeCall] = useState("serializeTemplate");
  const [contractStatus, setContractStatus] = useState(null);
  const plateRef = React.createRef();
  const terminationPlateRef = React.createRef();
  const negoPlateRef = React.createRef();

  //below component throws error => Error: Invalid parameter object: need either .data, .range or .url
  // const { pdfDocument, pdfPage } = usePdf({
  //   file: pdfBlobUrl,
  //   pageNumber,
  //   canvasRef,
  // });

  //#region amendment variables
  const [isAmendment, setIsAmendment] = useState(false);
  const [isNegotiation, setisNegotiation] = useState(false);
  const [isTermination, setIsTermination] = useState(false);
  const [terminationTemplateName, setterminationTemplateName] = useState("");
  const [amendmentTemplateName, setamendmentTemplateName] = useState("");
  const [isNameEditingReadonly, setIsNameEditingReadonly] = useState(true);
  const [amendmentContractTypeSelected, setamendmentContractTypeSelected] =
    useState(0);
  const [amendmentCategoryTypeSelected, setamendmentCategoryTypeSelected] =
    useState(0);
  const [terminationContractTypeSelected, setterminationContractTypeSelected] =
    useState(0);
  const [terminationCategoryTypeSelected, setterminationCategoryTypeSelected] =
    useState(0);
  const [terminationTemplateNamesList, setterminationTemplateNamesList] =
    useState([]);
  const [amendmentTemplateNamesList, setamendmentTemplateNamesList] = useState(
    []
  );
  const [additionalFileApi, setAdditionalFilesApi] = useState(null);
  const [additionalFile, setAdditionalFiles] = useState([]);
  const [isMerged, setIsMerged] = useState(false);
  const [isMergedRequired, setIsMergedRequired] = useState(false);
  const [fileJson, setFileJson] = useState([]);
  const [additionalFilePreview, setAdditionalFilePreview] = useState(false);
  const [additionalFilePreviewUrl, setAdditionalFilePreviewUrl] =
    useState(null);
  const [contractConditions, setContractConditions] = useState({});
  const [terminationConditions, setTerminationConditions] = useState({});
  const [loadingDynamicForm, setloadingDynamicForm] = useState(false);
  const [pdfDataTermination, setpdfDataTermination] = useState(null);
  // const [pdfHtml, setPdfHtml] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstCall, setfirstCall] = useState(false);
  const [publishedTemplate, setpublishedTemplate] = useState(null);
  const [disableContractVariable, setDisableContractVariable] = useState(false);
  const [disableTerminationVariable, setDisableTerminationVariable] =
    useState(false);
  const [isPlateEditable, setIsplateEditable] = useState(true);
  const [statusColor, setStatusColor] = useState("");
  const location = useLocation();
  const [statusHistory, setStatusHistory] = useState([]);
  const statusHistoryCall = async (id) => {
    // setIsLoading(true);
    let dataResult;
    let result = getCrudApi("contractStatusHistory/con/" + id, {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      if (dataResult.length > 1) {
        const maxObj = dataResult.reduce((accumulator, current) => {
          return accumulator.contractStatusHistoryId >
            current.contractStatusHistoryId
            ? accumulator
            : current;
        });
        const summary_array = dataResult?.reduce((acc, obj) => {
          const { contractStatus } = obj;
          acc[contractStatus] = (acc[contractStatus] || 0) + 1;
          return acc;
        }, {});
        let res = [];
        const result = Object.keys(summary_array).map((status) => {
          if (maxObj.contractStatus === status) {
            res.push({
              [status]: summary_array[status],
              active: true,
            });
          } else {
            res.push({
              [status]: summary_array[status],
            });
          }
        });
        setStatusHistory(res);
      } else {
        const result = [
          {
            [dataResult[0].contractStatus]: 1,
            active: true,
          },
        ];
        setStatusHistory(result);
      }
    }
    // setIsLoading(false);
  };
  function getStepClasses(step, activeStep, active) {
    let cls = "step";
    if (active === true) {
      cls += " step-active";
    }
    // else if (activeStep === step) {
    //   cls += " step-done";
    // }
    else {
      cls += " step-done";
    }
    return cls;
  }
  const showMessage = (message) => {
    // <Alert onClose={handleClose} severity="success">
    //   {message}
    // </Alert>;
  };

  const displayDraftedMessage = () => {
    toast.success("Drafted Successfully!", {
      className: "toaststyle",
    });
  };

  const displaySubmittedMessage = () => {
    toast.success("Submitted Successfully!", {
      className: "toaststyle",
    });
  };

  const displayReopenMessage = () => {
    toast.success("Contract ReOpened!", {
      className: "toaststyle",
    });
  };

  const displayAddReviewMessage = () => {
    toast.success("Sent To Review Successfully!", {
      className: "toaststyle",
    });
  };

  const displayExcuteMessage = () => {
    toast.success(" Executed Successfully !", {
      className: "toaststyle",
    });
  };

  const amendmentTemplateData = useSelector(
    (state) => state.amendmentTemplateState.value.amendmentTemplateData
  );
  const terminationTemplateData = useSelector(
    (state) => state.terminateTemplateState.value.terminateTemplateData
  );
  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  const contractTypeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );

  const handleRequiredNegotiationSelect = (e) => {
    let validemailcheck = true;
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (selectedNegotiator.trim() !== "") {
      validemailcheck = pattern.test(selectedNegotiator);
      setInvalidEmail(!validemailcheck);
    } else {
      setInvalidEmail(!validemailcheck);
    }

    if (
      (e.value === true && selectedNegotiator.trim() === "") ||
      !validemailcheck
    ) {
      setdisableNegoAdd(true);
    } else {
      setdisableNegoAdd(false);
    }
    setnegotiationRequired(e.value);
  };

  const handleMergedRequiredClick = () => {
    setIsMergedRequired(!isMergedRequired);
  };
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "2px",
      display: "flex",
      borderRadius: "5px",
      width: "95%",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };
  const updateAmendmentTemplateNames = () => {
    let templateNames = SelectMappingForAmendmentTemplate()
      ? amendmentContractTypeSelected !== 0
        ? SelectMappingForAmendmentTemplate().filter(
            (element) =>
              element.contractTypeId === amendmentContractTypeSelected
          )
        : SelectMappingForAmendmentTemplate()
      : null;
    templateNames = templateNames
      ? amendmentCategoryTypeSelected !== 0
        ? templateNames.filter(
            (element) =>
              element.catogeryTypeId === amendmentCategoryTypeSelected
          )
        : templateNames
      : null;
    templateNames = templateNames
      ? templateNames.filter(
          (element) => element.publishedAmendmentTemplateVersionId !== null
        )
      : null;
    setamendmentTemplateNamesList(templateNames);
    // return templateNames;
  };
  const updateTerminationTemplateNames = () => {
    let templateNames = SelectMappingForTerminationTemplate()
      ? terminationContractTypeSelected !== 0
        ? SelectMappingForTerminationTemplate().filter(
            (element) =>
              element.contractTypeId === terminationContractTypeSelected
          )
        : SelectMappingForTerminationTemplate()
      : null;
    templateNames = templateNames
      ? terminationCategoryTypeSelected !== 0
        ? templateNames.filter(
            (element) =>
              element.catogeryTypeId === terminationCategoryTypeSelected
          )
        : templateNames
      : null;
    templateNames = templateNames
      ? templateNames.filter(
          (element) => element.publishedTerminationTemplateVersionId !== null
        )
      : null;
    setterminationTemplateNamesList(templateNames);

    // return templateNames;
  };
  const terminationTemplateChange = (selectedOption) => {
    setterminationTemplateName(selectedOption);
  };
  const amendmentTemplateChange = (selectedOption) => {
    setamendmentTemplateName(selectedOption);
  };

  const onChangeOfAmendmentContractTreeSelect = (value) => {
    setamendmentContractTypeSelected(value);
  };
  const onChangeOfAmendmentCategoryTreeSelect = (value) => {
    setamendmentCategoryTypeSelected(value);
  };
  const onChangeOfTerminationContractTreeSelect = (value) => {
    setterminationContractTypeSelected(value);
  };
  const onChangeOfTerminationCategoryTreeSelect = (value) => {
    setterminationCategoryTypeSelected(value);
  };
  useEffect(() => {
    updateAmendmentTemplateNames();
  }, [amendmentContractTypeSelected, amendmentCategoryTypeSelected]);
  useEffect(() => {
    updateTerminationTemplateNames();
  }, [terminationContractTypeSelected, terminationCategoryTypeSelected]);

  useEffect(() => {
    if (
      terminationTemplateNamesList == null ||
      terminationTemplateNamesList.length === 0
    ) {
      setterminationTemplateName("");
    }
  }, [terminationTemplateNamesList]);

  useEffect(() => {
    if (
      amendmentTemplateNamesList == null ||
      amendmentTemplateNamesList.length === 0
    ) {
      setamendmentTemplateName("");
    }
  }, [amendmentTemplateNamesList]);
  //#endregion

  useEffect(() => {
    if (plateRef.current !== null) {
      plateRef.current.setEditorState(handlebarTemplate);
    }
  }, [handlebarTemplate]);
  //#region category, template and contract type population
  const SelectMappingForAmendmentTemplate = () => {
    let treeHierarchy = amendmentTemplateData;
    var old = JSON.stringify(treeHierarchy).replace(
      /"amendmentTemplateId"/g,
      '"value"'
    );
    if (treeHierarchy == null) {
      return [];
    }
    old = old.replace(/"amendmentTemplateName"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };
  const SelectMappingForTerminationTemplate = () => {
    let treeHierarchy = terminationTemplateData;
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"terminationTemplateId"/g,
      '"value"'
    );
    old = old.replace(/"terminationTemplateName"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };

  const SelectMappingForContractType = () => {
    let treeHierarchy = convertHierarchy(
      contractTypeData.filter((element) => element.status === "Active"),
      "contractTypeId",
      "parentContractTypeId"
    );
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"contractTypeId"/g,
      '"value"'
    );
    old = old.replace(/"contractType"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };
  const SelectMappingForCategory = () => {
    let treeHierarchy = convertHierarchy(
      categoryData.filter((element) => element.status === "Active"),
      "catogeryTypeId",
      "parentCatogeryTypeId"
    );
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"catogeryTypeId"/g,
      '"value"'
    );
    old = old.replace(/"catogeryType"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };
  //#endregion

  const ContractSubmission = async (stateFlag, negoCom) => {
    setIsLoading(true);
    let success = true;
    if (contractName !== null && contractName?.trim() !== "") {
      if (
        isTermination &&
        signingMethod !== "manual" &&
        stateFlag !== "Draft"
      ) {
        //doing handlebar compilation to be able to post - use `compiledContent` variable to post to API
        // let HTMLDoc = new DOMParser().parseFromString(updatedContent, "text/html");
        // let stringForHandlebar = await convertToHandlebarCompatibleHTML(HTMLDoc);

        let sigValid = true;
        terminationSignatureVariables.forEach((element) => {
          if (element.name.trim() === "" || element.email.trim() === "") {
            sigValid = false;
          }
        });
        if (sigValid === false) {
          toast.error("Please enter signature fields");
          setIsLoading(false);
          return;
        }
      }

      let serializedData = "";
      let pageType = pageSize;
      if (plateRef.current !== null) {
        serializedData = plateRef.current.serailizedContractData();
        setHandlebarTemplate(serializedData);
        pageType = plateRef.current.getPageSize();
        setPageSize(pageType);
      } else {
        serializedData = handlebarTemplate;
      }
      const deserializedDoc = deserializeContract(
        new DOMParser().parseFromString(serializedData, "text/html").body,
        {}
      );

      let htmlforHandlebar = convertToHandlebarFormat(
        { children: deserializedDoc },
        JSONforVariables,
        contractConditions
      );

      const executedDoc = executeHandlebar(htmlforHandlebar, JSONforVariables);
      let fullHtml = `<html><head><style>.signature-block-styling {height: 70px;display: inline-table;width: 200px;margin: 15px;}hr{border: 2px solid black;}.sig-identifier-container {color: white;margin: 3px 3px 0px 3px;}.sig-heading-container {margin: 0px 3px 0px 3px;;}</style></head><body>${executedDoc}</body></html>`;
      setFinalDocument(fullHtml);

      // let compiledContent = executeHandlebar(
      //   stringForHandlebar,
      //   JSONforVariables
      // );

      // let fullHtml = `<!DOCTYPE html><html><head><style>.signature-block-styling {height: 70px;display: inline-table;width: 200px;margin: 15px;}hr{border: 2px solid black;}.sig-identifier-container {color: white;margin: 3px 3px 0px 3px;}.sig-heading-container {margin: 0px 3px 0px 3px;;}</style></head><body>${compiledContent}</body></html>`;
      // fullHtml = fullHtml.replaceAll("&nbsp;", " ");
      // setFinalDocument(fullHtml);
      //
      let TermSerializedData = "";
      let TexecutedDoc = "";
      let TfullHtml = "";
      if (isTermination) {
        if (terminationPlateRef.current !== null) {
          TermSerializedData =
            terminationPlateRef.current.serailizedContractData();
          setHandlebarTemplateTermination(serializedData);
        } else {
          TermSerializedData = handlebarTemplateTermination;
        }
        const TdeserializedDoc = deserializeContract(
          new DOMParser().parseFromString(TermSerializedData, "text/html").body,
          {}
        );

        let ThtmlforHandlebar = convertToHandlebarFormat(
          { children: TdeserializedDoc },
          JSONforVariables,
          terminationConditions
        );
        TexecutedDoc = executeHandlebar(ThtmlforHandlebar, JSONforVariables);
        TfullHtml = `<html><head><style>.signature-block-styling {height: 70px;display: inline-table;width: 200px;margin: 15px;}.hr{border: 2px solid black;}.sig-identifier-container {color: white;margin: 3px 3px 0px 3px;}.sig-heading-container {margin: 0px 3px 0px 3px;;}</style></head><body>${TexecutedDoc}</body></html>`;
        setTermFinalDocument(TfullHtml);
      }

      let compliedTermination = {
        finalDocument: TfullHtml,
      };
      let compiledContentJson = {
        finalDocument: fullHtml,
      };

      let template_json = {
        template: JSON.stringify(serializedData),
        pageSize: pageType,
      };
      let varaible_json = {
        var: JSONforVariables,
      };
      let terminationData =
        terminationPlateRef.current === null
          ? handlebarTemplateTermination
          : terminationPlateRef.current.serailizedContractData();
      setHandlebarTemplateTermination(terminationData);
      let template_json_Termination = {
        template: JSON.stringify(terminationData),
      };
      let contractCondition = {
        condition: contractConditions,
      };
      let terminationCondition = {
        condition: terminationConditions,
      };
      let ContractFormDraftwithoutStatus = {
        contractName: contractName,
        signatureVariable: { signatureList: signatureVariables },
        //terminationsig
        terminationSignatureVariables: {
          signatureList: terminationSignatureVariables,
        },
        isNegotiationRequired: negotiationRequired ? 13 : null,
        negotiationEmailId: negotiationRequired ? selectedNegotiator : null,
        finalDocument: compiledContentJson,
        contractFormData: varaible_json,
        contractTemplate: template_json,
        // contractRequestFlag: 2,
        contractTemplateVersionId: templateSelected
          ? templateSelected.publishedTemplateVersionId
          : template_version,
        //contractStatus: stateFlag === "Draft" ? 5 : negotiationRequired ? 14 : 6,
        contractTypeId: templateSelected
          ? templateSelected.contractTypeId
          : contractData.contractTypeId,
        catogeryTypeId: templateSelected
          ? templateSelected.catogeryTypeId
          : contractData.catogeryTypeId,
        isApprovalRequired: contractData ? contractData.isApprovalRequired : 4,
        templateTypeFlag: templateSelected
          ? templateSelected.templateTypeFlag
            ? templateSelected.templateTypeFlag
            : 9
          : contractData.templateTypeFlag,
        formStateFlag: 2,
        amendedContractId: isAmendment ? contractData?.amendedContractId : null,
        terminationTemplate: isTermination ? template_json_Termination : null,
        signatureType: signingMethod,
        contractConditions: contractCondition,
        terminationConditions: isTermination ? terminationCondition : {},
        publishedTemplate:
          firstCall || isAmendment
            ? { finalDocument: publishedTemplate }
            : null,
        terminationFinalDocument: isTermination ? compliedTermination : null,
        addtionalFilesFlag: fileJson.length > 0 ? 3 : 4,
      };

      let ContractFormDraft = { ...ContractFormDraftwithoutStatus };
      // contract owner
      if (selectedOwner) {
        ContractFormDraft.contractOwner = selectedOwner;
      }
      if (
        contractData !== null &&
        contractData.contractId &&
        stateFlag === "Draft" &&
        contractData.contractStatus === 8
        //&&
        // isNegotiation === true
      ) {
      } else {
        ContractFormDraft.contractStatus =
          stateFlag === "Draft"
            ? 5
            : isTermination
            ? 11
            : negotiationRequired
            ? 14
            : 6;
      }
      if (
        ContractFormDraft.signatureVariable !== null &&
        ContractFormDraft.signatureVariable !== undefined
      ) {
      }
      let result;
      let dataResult;
      validateToken();

      if (contractData === null) {
        let flag = { contractRequestFlag: 2 };
        ContractFormDraft = {
          ...ContractFormDraft,
          ...flag,
        };
        result = postCrudApi("contract/contract", ContractFormDraft);
        await result.then(function (res) {
          if (res) {
            dataResult = res;
          } else success = false;
        });
        if (dataResult.contractId) {
          setContractData(dataResult);
          setStatus(dataResult.contractStatus);
          statusHistoryCall(dataResult?.contractId);
          setenableAddReviewe(true);
        }
      } else if (isAmendment && contractData.contractId === null) {
        let contractStatus = {
          contractStatus: 13,
        };
        let flag = { contractRequestFlag: 2 };
        ContractFormDraft = {
          ...ContractFormDraft,
          ...flag,
        };
        result = postCrudApi("contract/contract", ContractFormDraft);
        await result.then(function (res) {
          dataResult = res;
        });
        if (dataResult) {
          success = false;
          setContractData(dataResult);
          setStatus(dataResult.contractStatus);
          statusHistoryCall(dataResult?.contractId);
          setJSON(dataResult.contractFormData.var);

          await putCrudApi(
            "contract/contractStatus/" + contractData.amendedContractId,
            contractStatus
          );
          setAmendmentEnabled(false);
          setTerminationEnabled(false);
          if (dataResult.contractStatus === 6) {
            setenableSubmit(false);
            setenableDraft(false);
          } else {
            setenableSubmit(true);
            setenableDraft(true);
          }
          setStatus(dataResult.contractStatus);
          statusHistoryCall(dataResult?.contractId);
          setIsExecutedPreview(false);
          navigate("/Contracts/View", {
            state: {
              templateSelected: null,
              template_version: null,
              tableData: [],
              templateType: null,
              contractData: dataResult,
              contractName: dataResult.contractName,
            },
          });
        }
      } else if (isTermination) {
        result = putCrudApi(
          "contract/" + contractData.contractId,
          ContractFormDraft
        );
        await result.then(function (res) {
          if (res) {
            dataResult = res;
          } else success = false;
        });
        setContractData(dataResult);
        setStatus(dataResult.contractStatus);
        statusHistoryCall(dataResult?.contractId);
        setJSON(dataResult.contractFormData.var);

        // result = putCrudApi(
        //   "contract/contractStatus/" + contractData.contractId,
        //   contractStatus
        // );
        // await result.then(function (res) {
        //   dataResult = res;
        // });
        // setContractData(dataResult);
        setAmendmentEnabled(false);
        setTerminationEnabled(false);
      } else {
        result = putCrudApi(
          "contract/" + contractData.contractId,
          ContractFormDraft
        );
        await result.then(function (res) {
          if (res) {
            dataResult = res;
          } else success = false;
        });
        if (dataResult) {
          setContractData(dataResult);
          setStatus(dataResult?.contractStatus);
          statusHistoryCall(dataResult?.contractId);
          setJSON(dataResult?.contractFormData?.var);
        }
      }
      setfirstCall(false);
      let loccontractData = contractData;
      if (dataResult) {
        loccontractData = dataResult;
        setSerializeCall("serializeContract");
        if (fileJson.length > 0) {
          let additionfileJson = {
            contractId: dataResult.contractId,
            files: fileJson,
            isMerged: fileJson.length > 1 ? (isMerged === true ? 1 : 2) : null,
          };
          await postCrudApi(
            "ContractAdditionalFiles/contractAdditionalFiles",
            additionfileJson
          );
        }
        if (
          dataResult.contractStatus === 7 &&
          dataResult.signatureType === "manual"
        ) {
          setenableExecute(true);
        } else if (dataResult.contractStatus === 18) {
          if (dataResult.amendedContractId === null) {
            setAmendmentEnabled(true);
          } else {
            setAmendmentEnabled(false);
          }
          setTerminationEnabled(true);
        }
      }
      if (stateFlag === "Draft") {
        displayDraftedMessage();
      }
      if (comments.trim() !== "") {
        //Amendmentcomments
        let commentHistory = {
          contractHistoryComment: comments,
          contractId: dataResult.contractId,
          contractStatus: dataResult.contractStatus,
          commentHistoryFlag: 2,
        };
        await postCrudApi(
          "contractHistoryComment/contractHistoryComment",
          commentHistory
        );
      }
      //getcomments
      if (dataResult.contractId) {
        result = getCrudApi(
          "contractHistoryComment/contract/" + dataResult.contractId,
          {}
        );
        await result.then(function (res) {
          if (res) {
            dataResult = res;
          } else success = false;
        });
        if (dataResult) {
          dataResult.sort(GetSortOrder("contractHistoryCommentId"));
          setcommentsJson(dataResult);
          setComments("");
        }
      } else {
        toast.error("Error Updating");
      }

      // negotiation comment
      if (contractData && contractData.negotiationId !== null) {
        let commentData =
          negoPlateRef.current === null
            ? negoCom
            : negoPlateRef.current.getComments();
        let comment = {
          negotiationComments: commentData,
        };
        setnegotiationComments(commentData);
        // has to be changed after execute handlebar
        let negotiationDocument = {
          finalDocument: negotiationTemplate,
        };
        let negoComments = {
          negotiationComments: comment,
          negotiationDoc: negotiationDocument,
        };
        result = putCrudApi(
          "negotiation/negotiationComments/" + contractData.negotiationId,
          negoComments
        );
        await result.then(function (res) {
          if (res) {
            dataResult = res;
          } else success = false;
        });
      }
      if (stateFlag === "Draft") {
        // setdisableForm(false);
        // setdisableFormAmendment(false);
      } else if (stateFlag === "Submit" && isTermination) {
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
        setAmendmentEnabled(false);
        setTerminationEnabled(false);
        setenableDraft(false);
        setenableSubmit(false);
        setenableAddReviewe(false);
        //  setdisableForm(true);
        //  setdisableFormAmendment(true);
      } else if (
        stateFlag === "Submit" &&
        loccontractData &&
        loccontractData.contractStatus === 18
      ) {
        if (loccontractData.amendedContractId === null) {
          setAmendmentEnabled(true);
        } else {
          setAmendmentEnabled(false);
        }
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
        setTerminationEnabled(true);
        setenableDraft(false);
        setenableSubmit(false);
        setenableAddReviewe(false);
        //  setdisableForm(true);
        //  setdisableFormAmendment(true);
      } else if (stateFlag === "Submit") {
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
        setenableDraft(false);
        setenableSubmit(false);
        setenableAddReviewe(false);
      }
    } else {
      success = false;
      toast.error("Contract Name cannot be empty");
    }
    setIsLoading(false);
    return success;
  };
  // const updateDisabledKey = (data, keyname, value) => {
  //   let updatedJson = {};
  //   Object.keys(data).forEach((jsonvar, index) => {
  //     let newJSON = { ...data[jsonvar] };
  //     if (newJSON["parentGroup"] === keyname) {
  //       newJSON["elementConfig"]["disabled"] = value;
  //     }
  //     updatedJson = { ...updatedJson, ...newJSON };
  //   });

  //   return updatedJson;
  // };

  useEffect(() => {
    getContractFormDetails();
    (async () => {
      let users = await getUserData();
      setAllUsers(users);
    })();
  }, []);
  useEffect(() => {}, [
    handlebarTemplateAmendment,
    handlebarTemplateTermination,
  ]);

  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    filterType: "dropdown",
    search: false,
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "lastUpdatedDateTime",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},

    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {},
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  // const theme = createTheme({
  //   overrides: {
  //     MuiTab: {
  //       root: {
  //         "&.Mui-selected": {
  //           background: "red"
  //         }
  //       }
  //     }
  //   }
  // });
  const generateColumns = useMemo(() => {
    let columnsArray = [];

    columnsArray.push({
      name: "contractHistoryComment",
      label: "Comment",
    });
    columnsArray.push({
      name: "lastUpdatedBy",
      label: "Commented By",
    });
    columnsArray.push({
      name: "lastUpdatedDateTime",
      label: "Commented On",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "Contract Status",
      options: {
        customBodyRender: (dataIndex) => {
          let color;
          const setStatus = (dataIndex) => {
            switch (dataIndex) {
              case "Drafted":
                color = "#1D907Fff";
                break;
              case "Submitted":
                color = "#3F66B0ff";
                break;
              case "Approved":
                color = "#4F96D5ff";
                break;
              case "Reopened":
                color = "#FBCD08ff";
                break;
              case "Termination InProgress":
                color = "#8FC742ff";
                break;
              case "Amended":
                color = "#F05623ff";

                break;
              case "Negotiation":
                color = "#9F4098ff";
                break;
              case "Executed":
                color = "#F7901Eff";
                break;
              case "Terminated":
                color = "#76ccba";

                break;
            }
            return color;
          };
          const state = dataIndex;
          return (
            <>
              <p
                className={"table-contract-request-status"}
                style={{ background: setStatus(state) }}
              >
                {state}
              </p>
            </>
          );
        },
      },
    });

    return columnsArray;
  }, []);

  const signaturePrepopulation = (signatureList) => {
    let EmailInputs = [...emailInputs];
    let NameInputs = [...nameInputs];
    if (signatureList !== null && signatureList !== undefined) {
      signatureList.forEach((el, i) => {
        EmailInputs[i] = el.email;
        NameInputs[i] = el.name;
        //
        // let elm_email = document.getElementById(`signer_email-${el.id}`);
        // let elm_userName = document.getElementById(`signer_name-${el.id}`);
        //
        // if (elm_email !== null) {
        //   elm_email.value = el.email;
        // }
        // if (elm_userName !== null) {
        //   elm_userName.value = el.userName;
        // }
      });
    }
    setEmailInputs(EmailInputs);
    setNameInputs(NameInputs);
  };
  const signaturePrepopulationTermination = (signatureList) => {
    setIsLoading(true);
    let EmailInputs = [...termEmailInputs];
    let NameInputs = [...termNameInputs];
    if (signatureList !== null && signatureList !== undefined) {
      signatureList.forEach((el, i) => {
        EmailInputs[i] = el.email;
        NameInputs[i] = el.name;
        //
        // let elm_email = document.getElementById(`signer_email-${el.id}`);
        // let elm_userName = document.getElementById(`signer_name-${el.id}`);
        //
        // if (elm_email !== null) {
        //   elm_email.value = el.email;
        // }
        // if (elm_userName !== null) {
        //   elm_userName.value = el.userName;
        // }
      });
    }
    settermEmailInputs(EmailInputs);
    settermNameInputs(NameInputs);
    setIsLoading(false);
  };
  const getClauseVariables = async (template) => {
    let result, clauseDataResult;
    validateToken();
    result = getCrudApi("clauses/all", {});
    await result.then(function (res) {
      clauseDataResult = res;
    });
    setClauseLibrary(clauseDataResult);
    const document = new DOMParser().parseFromString(template, "text/html");
    const clauseVariableIDs = deserializeClause(document.body, []);
    let clauseVariables = {};
    for (let id in clauseVariableIDs) {
      let selectedClause = clauseDataResult.filter(
        (item) => item["clauseId"] === clauseVariableIDs[id]
      );
      let newclauseVar = selectedClause[0].clauseVariables.var;
      clauseVariables = { ...clauseVariables, ...newclauseVar };
    }
    return [clauseVariables, clauseDataResult];
  };
  const getContractFormDetails = async () => {
    setIsLoading(true);
    let dataResult;
    let template_version_id;
    setContractName(location.state.contractName);
    settemplateSelected(location.state.templateSelected);
    //settemplate_version(location.state.template_version);
    setTemplateType(location.state.templateType);
    let contractDataLoc;
    if (location.state.tableData === null) {
      setfirstCall(true);
      template_version_id = location.state.templateSelected
        ? location.state.templateSelected.publishedTemplateVersionId
        : null;
      validateToken();
      let result = getCrudApi("templateVersion/" + template_version_id, {});
      await result.then(function (res) {
        dataResult = res;
      });
      let combinedVariables;
      if (dataResult !== null) {
        setPageSize(
          dataResult?.templateVer?.pageSize
            ? dataResult?.templateVer?.pageSize
            : "A4"
        );
        setpublishedTemplate(JSON.parse(dataResult.templateVer.template));
        let variables = dataResult.templateVerVariable.var;
        //let nontempvariable = { ...variables };
        let partiesVariables = dataResult.templateVerBasicContractTemplate?.var;
        let template = JSON.parse(dataResult.templateVer.template);
        // getting clause variables
        let [clauseVariables, clauseData] = await getClauseVariables(template);
        // delete variables["nonTemplateVar"];
        // delete clauseVariables["nonTemplateVar"];

        combinedVariables = {
          templateVariables: variables,
          clauseVariables: clauseVariables,
          // promisers: combinedPromiserVar,
          // promisees: combinedPromiseeVar,
          // nonTemplateVariables: nontempvariable.nonTemplateVar
          //   ? nontempvariable.nonTemplateVar
          //   : {},
        };

        // Sorting Json
        if (partiesVariables) {
          Object.keys(partiesVariables).map((parties, idx) => {
            if (parties !== "basicDetails")
              partiesVariables[parties].map((obj, id) => {
                //Object.keys(obj).map((objvar) => {
                if (parties === "promisers") {
                  combinedVariables = {
                    ...combinedVariables,
                    ...{
                      ["promiser" + (id + 1)]: partiesVariables[parties][id],
                    },
                  };
                } else if (parties === "promisees") {
                  combinedVariables = {
                    ...combinedVariables,
                    ...{
                      ["promisee" + (id + 1)]: partiesVariables[parties][id],
                    },
                  };
                }
                // });
              });
            else if (parties === "basicDetails") {
              combinedVariables = {
                ...combinedVariables,
                ...{ basicDetails: partiesVariables[parties] },
              };
            }
          });
        }

        setJSON(combinedVariables);

        const deserialized = deserialize(
          new DOMParser().parseFromString(template, "text/html").body,
          {},
          clauseData
        );
        const serializedData = serializeContract({ children: deserialized });

        setHandlebarTemplate(serializedData);
        setUpdatedContent(serializedData);

        if (dataResult.signatureVariable !== null) {
          setSignatureVariables(dataResult.signatureVariable.signatureList);
          setEmailInputs(
            Array(dataResult.signatureVariable.signatureList.length).fill(null)
          );
          setNameInputs(
            Array(dataResult.signatureVariable.signatureList.length).fill(null)
          );
          //signaturePrepopulation(dataResult.signatureVariable.signatureList);
        }

        //conditions
        if (dataResult.templateVerConditions) {
          let combinedConditions = {
            ContractConditions: dataResult.templateVerConditions.condition,
          };
          setContractConditions(combinedConditions);
        }
        setTerminationConditions({});

        // let updatedJson = updateDisabledKey(variables, "Main Template", false);
        //
      }
      setselectedNegotiator("");
      setisNegotiation(false);
      setenableDraft(true);
      let formIsValid = true;
      // for (let inputIdentifier in combinedVariables) {
      //   if (combinedVariables[inputIdentifier]) {
      //     Object.keys(combinedVariables[inputIdentifier]).map((fields) => {
      //       formIsValid =
      //         combinedVariables[inputIdentifier][fields].valid && formIsValid;
      //     });
      //   }
      // }
      for (let inputIdentifier in combinedVariables) {
        if (combinedVariables[inputIdentifier]) {
          Object.keys(combinedVariables[inputIdentifier]).map((fields) => {
            let localValid = checkValidity(
              combinedVariables[inputIdentifier][fields].elementConfig
                .placeholder,
              combinedVariables[inputIdentifier][fields].value,
              combinedVariables[inputIdentifier][fields].validation,
              combinedVariables[inputIdentifier]
            );
            formIsValid = localValid && formIsValid;
          });
        }
      }
      setenableSubmit(formIsValid);
      setContractData(null);
      contractDataLoc = null;
      sethandlebarTemplateAmendment([]);
      setHandlebarTemplateTermination([]);
      setAmendmentEnabled(false);
      setTerminationEnabled(false);
      setenableExecute(false);
      setenableAddReviewe(false);
      setSerializeCall("serializeContract");
      setloadingDynamicForm(true);
      setDisableContractVariable(false);
      setDisableTerminationVariable(false);
    } else {
      setStatus(location.state.contractData.contractStatus);
      statusHistoryCall(location?.state?.contractData?.contractId);
      let enableSubmit = true;
      setfirstCall(false);
      setSerializeCall("serializeContract");

      let result = getCrudApi(
        "contract/" + location.state.contractData.contractId,
        {}
      );

      await result.then(function (res) {
        dataResult = res;
      });

      let templateVariablejson;

      if (dataResult) {
        settemplate_version(dataResult.contractTemplateVersionId);
        contractDataLoc = dataResult;
        setselectedOwner(contractDataLoc?.contractOwner);
        if (
          dataResult.addtionalFilesFlag &&
          dataResult.addtionalFilesFlag === 3
        ) {
          let dataResultAdditionalFile;
          let resultAdditionalFile = getCrudApi(
            "ContractAdditionalFiles/contractId/" +
              location.state.contractData.contractId,
            {}
          );

          await resultAdditionalFile.then(function (res) {
            dataResultAdditionalFile = res;
          });
          setAdditionalFilesApi(dataResultAdditionalFile);
        }
        setContractData(dataResult);
        setStatus(dataResult.contractStatus);
        statusHistoryCall(dataResult?.contractId);
        if (dataResult.signatureVariable !== null) {
          setSignatureVariables(dataResult.signatureVariable.signatureList);
          signaturePrepopulation(dataResult.signatureVariable.signatureList);
        }
        if (dataResult.terminationSignatureVariables !== null) {
          setTerminationSignatureVariables(
            dataResult.terminationSignatureVariables.signatureList
          );
          signaturePrepopulationTermination(
            dataResult.terminationSignatureVariables.signatureList
          );
        }
        //conditions
        if (dataResult.contractConditions) {
          setContractConditions(dataResult.contractConditions.condition);
        }
        if (dataResult.terminationConditions) {
          setTerminationConditions(dataResult.terminationConditions.condition);
        }
        setselectedNegotiator(
          dataResult.negotiationEmailId !== null
            ? dataResult.negotiationEmailId
            : ""
        );
        let template = JSON.parse(dataResult.contractTemplate.template);
        setPageSize(
          dataResult?.contractTemplate?.pageSize
            ? dataResult?.contractTemplate?.pageSize
            : "A4"
        );

        templateVariablejson = dataResult.contractFormData.var;

        await getClauseVariables(template);
        setJSON(templateVariablejson);
        setpublishedTemplate(template);
        setHandlebarTemplate(template);
        setUpdatedContent(template);
        setsigningMethod(dataResult.signatureType);
        if (
          dataResult.contractStatus === 7 &&
          dataResult.signatureType === "manual" &&
          dataResult.formStateFlag === 2
        ) {
          setenableExecute(true);
          setDisableContractVariable(true);
          setDisableTerminationVariable(true);
        } else {
          setenableExecute(false);
          setDisableContractVariable(false);
          setDisableTerminationVariable(false);
        }
        if (
          //8 -> reopen
          dataResult.contractStatus === 8 &&
          dataResult.negotiationId !== null
        ) {
          // reopened
          setisNegotiation(true);
          result = getCrudApi(
            "negotiation/negotiationUuid/" + dataResult.negotiationId,
            {}
          );
          await result.then(function (res) {
            dataResult = res;
          });
          setnegotiationTemplate(dataResult.negotiationDoc.finalDocument);
          setnegotiationComments(
            dataResult.negotiationComments
              ? dataResult.negotiationComments.negotiationComments
              : []
          );
        } else setisNegotiation(false);
      }
      //testing
      //getcomments
      result = getCrudApi(
        "contractHistoryComment/contract/" +
          location.state.contractData.contractId,
        {}
      );
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        dataResult.sort(GetSortOrder("contractHistoryCommentId"));
        setcommentsJson(dataResult);
        setComments("");
      }
      if (
        location.state.contractData.contractStatus !== 5 &&
        location.state.contractData.contractStatus !== 8 &&
        // location.state.contractData.contractStatus !== 7 &&
        location.state.contractData.formStateFlag !== 1
      ) {
        //  templateVariablejson = updateDisabledKey(
        //   templateVariablejson,
        //   "Main Template",
        //   true
        // );
        // templateVariablejson = updateDisabledKey(
        //   templateVariablejson,
        //   "Amendment Template",
        //   true
        // );
        // setJSON(templateVariablejson);
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
        enableSubmit = false;
        setenableSubmit(false);
        setenableDraft(false);
        setenableAddReviewe(false);
        setIsplateEditable(false);
      } else {
        setDisableContractVariable(false);
        setDisableTerminationVariable(false);
        enableSubmit = true;
        setenableSubmit(true);
        setenableDraft(true);
        setenableAddReviewe(true);
        setIsplateEditable(true);
      }

      if (location.state.contractData.contractStatus === 11) {
        // templateVariablejson = updateDisabledKey(
        //   templateVariablejson,
        //   "Termination Template",
        //   true
        // );
        // setJSON(templateVariablejson);
        setAmendmentEnabled(false);
        setTerminationEnabled(false);
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
      } else if (location.state.contractData.contractStatus === 18) {
        if (contractDataLoc.amendedContractId === null) {
          setAmendmentEnabled(true);
        } else {
          setAmendmentEnabled(false);
        }
        //setAmendmentEnabled(true);
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
        setTerminationEnabled(true);
        setIsExecutedPreview(true);
        let dataResultExecute;
        let result = getCrudApi(
          "file/getContract/" + location.state.contractData.contractId,
          {}
        );
        await result.then(function (res) {
          dataResultExecute = res;
        });

        setPdfExecuteUrl("data:application/pdf;base64," + dataResultExecute);
      } else if (location.state.contractData.contractStatus === 19) {
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
        setIsExecutedPreview(true);
        setIsExecutedPreviewTermination(true);
        let dataResultExecute;
        let result = getCrudApi(
          "file/getTermination/" + location.state.contractData.contractId,
          {}
        );
        await result.then(function (res) {
          dataResultExecute = res;
        });

        setPdfExecuteUrlTermination(
          "data:application/pdf;base64," + dataResultExecute
        );

        result = getCrudApi(
          "file/getContract/" + location.state.contractData.contractId,
          {}
        );
        await result.then(function (res) {
          dataResultExecute = res;
        });
        setPdfExecuteUrl("data:application/pdf;base64," + dataResultExecute);
      } else if (location.state.contractData.contractStatus === 13) {
        setDisableContractVariable(true);
        setDisableTerminationVariable(true);
        setAmendmentEnabled(false);
        setTerminationEnabled(false);
      }
      if (location.state.contractData.contractStatus !== 18) {
        setAmendmentEnabled(false);
        setTerminationEnabled(false);
      }

      setHandlebarTemplateTermination(
        contractDataLoc.terminationTemplate
          ? JSON.parse(contractDataLoc.terminationTemplate.template)
          : []
      );
      if (contractDataLoc.terminationTemplate) {
        setIsTermination(true);
      }
      let formIsValid = true;
      // for (let inputIdentifier in templateVariablejson) {
      //   if (templateVariablejson[inputIdentifier]) {
      //     Object.keys(templateVariablejson[inputIdentifier]).map((fields) => {
      //       formIsValid =
      //         templateVariablejson[inputIdentifier][fields].valid &&
      //         formIsValid;
      //     });
      //   }
      // }
      for (let inputIdentifier in templateVariablejson) {
        if (templateVariablejson[inputIdentifier]) {
          Object.keys(templateVariablejson[inputIdentifier]).map((fields) => {
            let localValid = checkValidity(
              templateVariablejson[inputIdentifier][fields].elementConfig
                .placeholder,
              templateVariablejson[inputIdentifier][fields].value,
              templateVariablejson[inputIdentifier][fields].validation,
              templateVariablejson[inputIdentifier]
            );
            formIsValid = localValid && formIsValid;
          });
        }
      }
      setenableSubmit(enableSubmit && formIsValid);
      setloadingDynamicForm(true);
    }

    let Userresult = await getCrudApi("users/all", {});

    if (Userresult && Userresult !== null) {
      setReviewerList(Userresult);
      setContractOwnerList(Userresult);
    }
    if (contractDataLoc !== null && contractDataLoc.contractReviewer !== null) {
      var old = JSON.stringify(
        Userresult.filter(
          (element) => element.userId === contractDataLoc.contractReviewer
        )[0]
      ).replace(/"userId"/g, '"value"');
      old = old.replace(/"userName"/g, '"label"');
      var newArray = JSON.parse(old);
      setselectedReviewer(newArray);
    }

    setIsLoading(false);
  };
  // const a11yProps = (index) => {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // };
  // const decodeHTMLContent = (htmlText) => {
  //   var txt = document.createElement("span");
  //   txt.innerHTML = htmlText;
  //   return txt.innerHTML;
  // };
  const setStatus = (status) => {
    switch (status) {
      case 5:
        setContractStatus("Drafted");
        setStatusColor("#7a5bb1");
        break;
      case 6:
        setContractStatus("Submitted");
        setStatusColor("#4A6C9D");
        break;
      case 7:
        setContractStatus("Approved");
        setStatusColor("#0C7BA7");
        break;
      case 8:
        setContractStatus("Reopened");
        setStatusColor("#3093b5");

        break;
      case 9:
        setContractStatus("Cancelled");
        break;
      case 10:
        setContractStatus("Expired");
        break;
      case 11:
        setContractStatus("Termination InProgress");
        setStatusColor("#C5857E");

        break;
      case 12:
        setContractStatus("Renewed");
        break;
      case 13:
        setContractStatus("Amended");
        setStatusColor("#009E82");

        break;
      case 14:
        setContractStatus("Negotiation");
        setStatusColor("#0C7BA7");

        break;
      case 15:
        setContractStatus("Negotiation Completed");
        break;
      case 18:
        setContractStatus("Executed");
        setStatusColor("#0C7BA7");

        break;
      case 19:
        setContractStatus("Terminated");
        setStatusColor("#C5857E");

        break;
    }
  };
  const handleTabChange = async (newValue) => {
    setIsLoading(true);
    setValue(newValue);
    let serializedDataTemplate = null;
    let serializedDataTermination = null;
    let pgSize = pageSize;
    if (plateRef.current !== null) {
      serializedDataTemplate = plateRef.current.serailizedContractData();
      setHandlebarTemplate(serializedDataTemplate);
      setPageSize(plateRef.current.getPageSize());
      pgSize = plateRef.current.getPageSize();
    }
    if (terminationPlateRef.current !== null) {
      serializedDataTermination =
        terminationPlateRef.current.serailizedContractData();
      setHandlebarTemplateTermination(serializedDataTermination);
    }
    // has to be updated for finaldoc if required
    if (negoPlateRef.current !== null) {
      let comments = negoPlateRef.current.getComments();
      setnegotiationComments(comments);
    }

    if (newValue === isTermination ? 4 : 2) {
      const deserializedDoc = deserializeContract(
        new DOMParser().parseFromString(
          serializedDataTemplate === null
            ? handlebarTemplate
            : serializedDataTemplate,
          "text/html"
        ).body,
        {}
      );

      let htmlforHandlebar = convertToHandlebarFormat(
        { children: deserializedDoc },
        JSONforVariables,
        contractConditions
      );

      const executedDoc = executeHandlebar(htmlforHandlebar, JSONforVariables);
      let fullHtml = `<html><head><style>@page {
        size: ${pgSize};
        margin: 2.54cm 2.54cm 2.54cm 2.54cm;
      }</style>
     </head><body><div>${executedDoc}</div></body></html>`;

      //setFinalDocument(fullHtml);
      // const renderHTML = React.createElement("div", {
      //   dangerouslySetInnerHTML: { __html: executedDoc },
      // });

      // let htmlstring = `styles of writing, particularly journalistic styles, a paragraph can be just one sentence long. Ultimately, a paragraph is a sentence or group of sentences that support one main idea. In this handout, we will refer to this as the “controlling idea,” because it controls what happens in the rest of the paragraph.&nbsp;`;
      // const renderHTML = decodeHTMLContent(htmlstring);
      //
      validateToken();
      let jsontopost = {
        html: fullHtml,
      };
      let executedDocresult = postCrudApi("contract/html", jsontopost);
      let executedDocdataResult;
      await executedDocresult.then(function (res) {
        executedDocdataResult = res;
      });
      setPdfDataUrl("data:application/pdf;base64," + executedDocdataResult);
    }
    if (newValue === 3) {
      const deserializedDoc = deserializeContract(
        new DOMParser().parseFromString(
          serializedDataTermination === null
            ? handlebarTemplateTermination
            : serializedDataTermination,
          "text/html"
        ).body,
        {}
      );
      let htmlforHandlebar = convertToHandlebarFormat(
        { children: deserializedDoc },
        JSONforVariables,
        terminationConditions
      );

      const executedDoc = executeHandlebar(htmlforHandlebar, JSONforVariables);
      let fullHtml = `<html><head>
      </head><body>${executedDoc}</body></html>`;
      //setFinalDocument(fullHtml);
      validateToken();
      let executedDocresult = postCrudApi("contract/html", {
        html: fullHtml,
      });
      let executedDocdataResult;
      await executedDocresult.then(function (res) {
        executedDocdataResult = res;
      });
      setpdfDataTermination(
        "data:application/pdf;base64," + executedDocdataResult
      );
    }
    setIsLoading(false);
  };

  const OnAmendmentModalOpen = async () => {
    let result = getCrudApi("contract/check", {});
    await result.then(function (res) {
      if (res) {
        setAddAmendmentOpen(true);
      } else {
        toast.error(
          "Attention: Your subscription contract limit has been reached. Please contact our service provider for further assistance."
        );
      }
    });
  };

  const OnTerminationModalOpen = () => {
    setAddTerminationOpen(true);
  };

  const OnAmendmentModalClose = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi(
      "amendmentTemplateVersion/" +
        amendmentTemplateName.publishedAmendmentTemplateVersionId,
      {}
    );
    await result.then(function (res) {
      dataResult = res;
    });

    let variables = dataResult.amendmentTemplateVerVariable.var;
    // delete variables["nonTemplateVar"];

    let mergedAmendVariables = {
      AmendmentVariables: variables,
    };
    let mergedVariableJson = {
      ...JSONforVariables,
      ...mergedAmendVariables,
    };

    //conditions
    if (dataResult.amendmentTemplateVerConditions) {
      let mergedConditions = {
        ...contractConditions.ContractConditions,
      };
      if (dataResult.amendmentTemplateVerConditions.condition) {
        Object.keys(dataResult.amendmentTemplateVerConditions.condition).map(
          (con) => {
            mergedConditions[Object.keys(mergedConditions).length + 1] =
              dataResult.amendmentTemplateVerConditions.condition[con];
          }
        );
        let combinedConditions = {
          ContractConditions: mergedConditions,
          //  contractConditions.ContractConditions,
          // AmendmentConditions:
          //   dataResult.amendmentTemplateVerConditions.condition,
        };
        setContractConditions(combinedConditions);
      }
    }

    //  mergedVariableJson = updateDisabledKey(
    //   mergedVariableJson,
    //   "Main Template",
    //   false
    // );
    // mergedVariableJson = updateDisabledKey(
    //   mergedVariableJson,
    //   "Amendment Template",
    //   false
    // );
    setJSON(mergedVariableJson);

    const deserialized = deserialize(
      new DOMParser().parseFromString(
        JSON.parse(dataResult.amendmentTemplateVer.template),
        "text/html"
      ).body,
      {},
      clauseLibrary
    );

    const serializedData = serializeContract({ children: deserialized });

    var combinedTemplate = serializedData + handlebarTemplate;
    setpublishedTemplate(combinedTemplate);
    setUpdatedContent(combinedTemplate);
    setHandlebarTemplate(combinedTemplate);

    setIsAmendment(true);
    setAddAmendmentOpen(false);
    setAmendmentEnabled(false);
    setTerminationEnabled(false);
    let oldcontractData = { ...contractData };
    oldcontractData.amendedContractId = oldcontractData.contractId;
    oldcontractData.contractId = null;
    setContractData(oldcontractData);
    setenableDraft(true);
    let formIsValid = true;
    // for (let inputIdentifier in mergedVariableJson) {
    //   if (mergedVariableJson[inputIdentifier]) {
    //     Object.keys(mergedVariableJson[inputIdentifier]).map((fields) => {
    //       formIsValid =
    //         mergedVariableJson[inputIdentifier][fields].valid && formIsValid;
    //     });
    //   }
    // }
    for (let inputIdentifier in mergedVariableJson) {
      if (mergedVariableJson[inputIdentifier]) {
        Object.keys(mergedVariableJson[inputIdentifier]).map((fields) => {
          let localValid = checkValidity(
            mergedVariableJson[inputIdentifier][fields].elementConfig
              .placeholder,
            mergedVariableJson[inputIdentifier][fields].value,
            mergedVariableJson[inputIdentifier][fields].validation,
            mergedVariableJson[inputIdentifier]
          );
          formIsValid = localValid && formIsValid;
        });
      }
    }
    setenableSubmit(formIsValid);
    setDisableContractVariable(false);
    setDisableTerminationVariable(true);
    setIsLoading(false);
  };

  const OnTerminationModalClose = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi(
      "terminationTemplateVersion/" +
        terminationTemplateName.publishedTerminationTemplateVersionId,
      {}
    );
    await result.then(function (res) {
      dataResult = res;
    });

    let variables = dataResult?.terminationTemplateVerVariable.var;
    // delete variables["nonTemplateVar"];

    let mergedTermVariables = {
      TerminationVariables: variables,
    };
    let mergedVariableJson = {
      ...JSONforVariables,
      ...mergedTermVariables,
    };

    //conditions
    if (dataResult.terminationTemplateVerConditions) {
      let combinedConditions = {
        TerminationConditions:
          dataResult.terminationTemplateVerConditions.condition,
      };
      setTerminationConditions(combinedConditions);
    }

    //  mergedVariableJson = updateDisabledKey(
    //   mergedVariableJson,
    //   "Main Template",
    //   true
    // );
    // mergedVariableJson = updateDisabledKey(
    //   mergedVariableJson,
    //   "Amendment Template",
    //   true
    // );
    setJSON(mergedVariableJson);

    const deserialized = deserialize(
      new DOMParser().parseFromString(
        JSON.parse(dataResult.terminationTemplateVer.template),
        "text/html"
      ).body,
      {},
      clauseLibrary
    );
    const serializedData = serializeContract({ children: deserialized });

    setHandlebarTemplateTermination(serializedData);

    if (dataResult.terminationTemplateSignatureVariables !== null) {
      setTerminationSignatureVariables(
        dataResult.terminationTemplateSignatureVariables.signatureList
      );
      settermEmailInputs(
        Array(
          dataResult.terminationTemplateSignatureVariables.signatureList.length
        ).fill(null)
      );
      settermNameInputs(
        Array(
          dataResult.terminationTemplateSignatureVariables.signatureList.length
        ).fill(null)
      );
      //signaturePrepopulation(dataResult.terminationSignatureVariables.signatureList);
    }
    setIsTermination(true);
    setAddTerminationOpen(false);
    setAmendmentEnabled(false);
    setTerminationEnabled(false);
    setenableDraft(false);
    let formIsValid = true;
    // for (let inputIdentifier in mergedVariableJson) {
    //   if (mergedVariableJson[inputIdentifier]) {
    //     Object.keys(mergedVariableJson[inputIdentifier]).map((fields) => {
    //       formIsValid =
    //         mergedVariableJson[inputIdentifier][fields].valid && formIsValid;
    //     });
    //   }
    // }
    for (let inputIdentifier in mergedVariableJson) {
      if (mergedVariableJson[inputIdentifier]) {
        Object.keys(mergedVariableJson[inputIdentifier]).map((fields) => {
          let localValid = checkValidity(
            mergedVariableJson[inputIdentifier][fields].elementConfig
              .placeholder,
            mergedVariableJson[inputIdentifier][fields].value,
            mergedVariableJson[inputIdentifier][fields].validation,
            mergedVariableJson[inputIdentifier]
          );
          formIsValid = localValid && formIsValid;
        });
      }
    }
    setenableSubmit(formIsValid);
    setDisableContractVariable(true);
    setDisableTerminationVariable(false);
    setIsLoading(false);
  };
  const onCommentsChange = (name, value) => {
    setComments(value);
  };
  const SelectMappingForReviewers = () => {
    let treeHierarchy = ReviewerList;
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(/"userId"/g, '"value"');
    old = old.replace(/"userName"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };
  const SelectMappingForOwner = () => {
    let treeHierarchy = contractOwnerList;
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(/"userId"/g, '"value"');
    old = old.replace(/"userName"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };
  const OnAddReviewe = async () => {
    setIsLoading(true);
    validateToken();
    let reviewer = {
      contractReviewer: selectedReviewer.value,
      contractReviewerStatus: 16,
    };
    if (contractData) {
      await putCrudApi("contract/" + contractData.contractId, reviewer);
      setAddReviewe(false);
      displayAddReviewMessage();
    }
    setIsLoading(false);
  };

  const OnAddRevieweClose = () => {
    setAddReviewe(false);
  };
  const OnAddNegotiator = async () => {
    setaddNegotiation(false);
    let valid = await ContractSubmission("Submit", negotiationComments);
    if (valid) {
      displaySubmittedMessage();
    }
  };
  const OnAddNegotiatorClose = () => {
    setaddNegotiation(false);
  };
  const OnAddExecutionClose = () => {
    setexecution(false);
  };
  const OnAddExecution = async () => {
    setIsLoading(true);
    setexecution(false);
    let temp = null;
    await getBase64(file).then((data) => {
      temp = data;
    });

    let contractDocUpload = {
      contractID: contractData.contractId,
      file: temp,
    };
    validateToken();
    let dataResult;
    if (contractData.contractStatus === 11) {
      let result = postCrudApi("file/terminationFileUpload", contractDocUpload);
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        setIsExecutedPreviewTermination(true);
        let dataResultExecute;
        let result = getCrudApi(
          "file/getTermination/" + contractData.contractId,
          {}
        );
        await result.then(function (res) {
          dataResultExecute = res;
        });
        setPdfExecuteUrlTermination(
          "data:application/pdf;base64," + dataResultExecute
        );
        let loccontractData = { ...contractData };
        loccontractData.contractStatus = 19;
        setContractData(loccontractData);
        setStatus(loccontractData.contractStatus);
        statusHistoryCall(loccontractData?.contractId);
        displayExcuteMessage();
      } else toast.error("The operation was not performed");
    } else {
      let result = postCrudApi("file/contractFileUpload", contractDocUpload);
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        if (contractData.amendedContractId === null) {
          setAmendmentEnabled(true);
        } else {
          setAmendmentEnabled(false);
        }
        let loccontractData = { ...contractData };
        loccontractData.contractStatus = 18;
        setContractData(loccontractData);
        setStatus(loccontractData.contractStatus);
        statusHistoryCall(loccontractData?.contractId);
        setTerminationEnabled(true);
        setenableExecute(false);
        setIsExecutedPreview(true);
        let dataResultExecute;
        let result = getCrudApi(
          "file/getContract/" + contractData.contractId,
          {}
        );
        await result.then(function (res) {
          dataResultExecute = res;
        });
        setPdfExecuteUrl("data:application/pdf;base64," + dataResultExecute);
        displayExcuteMessage();
      } else toast.error("The operation was not performed");
    }
    setIsLoading(false);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // reader.onload = () => resolve(reader.result);
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const HandleReviewerChange = (selectedOption) => {
    setselectedReviewer(selectedOption);
  };

  const HandleNegotiatorChange = (value) => {
    let validemailcheck = true;

    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value.trim() !== "") {
      validemailcheck = pattern.test(value);
      setInvalidEmail(!validemailcheck);
    } else {
      setInvalidEmail(!validemailcheck);
    }
    if (
      (negotiationRequired === true && value.trim() === "") ||
      !validemailcheck
    ) {
      setdisableNegoAdd(true);
    } else {
      setdisableNegoAdd(false);
    }

    setselectedNegotiator(value);
  };
  const draftFullForm = () => {
    let comments = null;
    if (negoPlateRef.current !== null) {
      comments = negoPlateRef.current.getComments();
      setnegotiationComments(comments);
    } else {
      comments = negotiationComments;
    }
    // setOpen(true);
    ContractSubmission("Draft", comments);
  };

  const submitFullForm = async () => {
    if (plateRef.current !== null) {
      let serializedData = plateRef.current.serailizedContractData();
      setHandlebarTemplate(serializedData);
    }
    if (terminationPlateRef.current !== null) {
      let serializedData = terminationPlateRef.current.serailizedContractData();
      setHandlebarTemplateTermination(serializedData);
    }
    let comments = null;
    // has to be updated for finaldoc if required
    if (negoPlateRef.current !== null) {
      comments = negoPlateRef.current.getComments();
      setnegotiationComments(comments);
    } else {
      comments = negotiationComments;
    }
    if (isTermination === true) {
      ContractSubmission("Submit", comments);
    } else {
      let sigValid = true;
      signatureVariables.forEach((element) => {
        if (element.name.trim() === "" || element.email.trim() === "") {
          sigValid = false;
        }
      });
      if (sigValid === false || signatureVariables.length === 0) {
        setSiginigMethodsTypes([
          { label: "Manual", value: "manual" },
          //{ label: "DocuSign", value: "DocuSign" },
        ]);
      } else {
        let result = getCrudApi("integration/names");
        let dataResult = [];
        await result.then(function (res) {
          dataResult = res;
        });
        let signingMethods = [{ label: "Manual", value: "manual" }];
        dataResult.forEach((element) => {
          if (element.integrationName === "DocuSign") {
            signingMethods.push({ label: "DocuSign", value: "DocuSign" });
          } else if (element.integrationName === "AdobeSign") {
            signingMethods.push({ label: "AdobeSign", value: "AdobeSign" });
          }
        });
        setSiginigMethodsTypes(signingMethods);
      }
      setaddNegotiation(true);
    }
  };

  const returnClauseData = async (clauseID) => {
    let result = getCrudApi(`clauses/${clauseID}`);
    let dataResult;
    await result.then(function (res) {
      dataResult = res;
    });
    let clausehtml = dataResult.clauseHtml;
    return clausehtml.clauseHtml;
  };

  const convertToHandlebarCompatibleHTML = async (doc) => {
    let allCodeSnippets = doc.getElementsByTagName("CODE");

    //HTMLCollection is live so modifying outerHTML shortens the length of the collection, therefore iterate HTMLCollection backwards
    for (let i = allCodeSnippets.length - 1; i >= 0; i--) {
      let type = allCodeSnippets[i].getAttribute("data-type");
      let content = allCodeSnippets[i].getAttribute("data-content");

      // eslint-disable-next-line default-case
      switch (type) {
        case "variable":
          allCodeSnippets[i].outerHTML = content;
          break;
        case "condition":
          var outerHtml;
          var condStartRegex = RegExp(
            `(?<!title=["'\`]){{#[cusif|else|each|for|with]+ +?[A-Za-z0-9"']+ +?[A-Za-z0-9|>=|<=|==|<|>|!=]+ +?[A-Za-z0-9"']+ *?}}`,
            "g"
          );
          var conditionStart;

          while ((conditionStart = condStartRegex.exec(content)) !== null) {
            var condition = conditionStart[0].split(" ")[0].substring(3);
            outerHtml =
              conditionStart[0] +
              allCodeSnippets[i].innerHTML +
              "{{/" +
              condition +
              "}}";
          }
          allCodeSnippets[i].outerHTML = outerHtml;
          break;
        case "clause":
          var clauseHTML = await returnClauseData(content);
          var re = /<p>([\s\S]*)<\/p>/;
          var strippedHTML = re.exec(clauseHTML)[1].trim();

          allCodeSnippets[
            i
          ].outerHTML = `<span id=${i} data-type="clause" data-content="${content}">${strippedHTML}</span>`;
          break;
        case "comments":
          break;
      }
    }

    var comments = document.querySelectorAll('[id^="comment_"]');
    comments.forEach((e) => {
      if (e.tagName === "CODE") {
        e.outerHTML = `<span id='${e.id}'>${e.innerHTML}</span>`;
      }
    });

    return doc.body.innerHTML;
  };

  //below function converts HTML to DOCX
  const Export2Word = (element, filename = "") => {
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + element.innerHTML + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };

  // const OnDownloadWordClick = async (e) => {
  //   let HTMLDoc = new DOMParser().parseFromString(updatedContent, "text/html");
  //   let stringForHandlebar = await convertToHandlebarCompatibleHTML(HTMLDoc);
  //   // let compiledContent = executeHandlebar(
  //   //   stringForHandlebar,
  //   //   JSONforVariables
  //   // );
  //   // let pagedDoc = generatePrintableHtml(compiledContent, pageMargins);
  //   // Export2Word(pagedDoc, "word-content.docx");
  // };

  const handle_signature_method = (event) => {
    setsigningMethod(event.value);
  };

  const handleChange = (file) => {
    setFile(file);
  };
  useEffect(() => {
    onContentScroll();
  });
  const onContentScroll = () => {
    document
      .getElementById("_contractEditor")
      ?.addEventListener("scroll", function (event) {
        var scrollingDiv = document.getElementById("_contractNegoEditor");
        let calScroll = (65 * event.target.scrollTop) / 100;

        scrollingDiv.scrollTop = calScroll;
      });
    document
      .getElementById("_contractNegoEditor")
      ?.addEventListener("scroll", function (event) {
        var scrollingDiv = document.getElementById("_contractEditor");
        let calScroll = (100 * event.target.scrollTop) / 65;
        scrollingDiv.scrollTop = calScroll;
      });
  };
  const handleChangeAdditionalFiles = (file) => {
    if (file.length > 1) {
      setIsMerged(false);
    } else {
      setIsMerged(true);
    }
    let FileArray = [];
    let temp;
    const fileList = [...additionalFile, ...file];
    fileList.map(async (eachfile) => {
      await getBase64(eachfile).then((data) => {
        temp = data;
      });
      let Fjson = {
        fileName: eachfile.name,
        fileContent: temp,
        fileExtension: eachfile.type,
      };
      FileArray.push(Fjson);
    });

    setFileJson(FileArray);
    const cAdditionalFile = [...additionalFile, ...file];
    setAdditionalFiles(cAdditionalFile);
  };

  const handleExecuted = () => {
    setexecution(true);
  };
  const OnTerminationExecution = () => {
    setexecution(true);
  };

  const getUserData = async () => {
    let dataResult;
    validateToken();
    let result = getCrudApi("users/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    setReviewerList(dataResult);
    let superv = convertHierarchy(dataResult, "userId", "supervisor_id");
    var old = JSON.stringify(superv).replace(/"userId"/g, '"value"');
    old = old.replace(/"first_name"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };

  const handleEmailInput = (ev, obj, i) => {
    let tempEmailInputs = [...emailInputs];
    tempEmailInputs[i] = ev.target.value;
    obj.email = ev.target.value;
    signatureVariables[obj.id - 1].email = ev.target.value;
    setEmailInputs(tempEmailInputs);
  };

  const handleNameInputs = (ev, obj, i) => {
    let tempNameInputs = [...nameInputs];
    tempNameInputs[i] = ev.target.value;
    obj.name = ev.target.value;
    signatureVariables[obj.id - 1].name = ev.target.value;
    setNameInputs(tempNameInputs);
  };
  const handleEmailInputTermination = (ev, obj, i) => {
    let tempEmailInputs = [...termEmailInputs];
    tempEmailInputs[i] = ev.target.value;
    obj.email = ev.target.value;
    terminationSignatureVariables[obj.id - 1].email = ev.target.value;
    settermEmailInputs(tempEmailInputs);
  };

  const handleNameInputsTermination = (ev, obj, i) => {
    let tempNameInputs = [...termNameInputs];
    tempNameInputs[i] = ev.target.value;
    obj.name = ev.target.value;
    terminationSignatureVariables[obj.id - 1].name = ev.target.value;
    settermNameInputs(tempNameInputs);
  };

  const handlePopulation = (el, userObj) => {
    let tempEmailInputs = [...emailInputs];
    tempEmailInputs[el.id - 1] = userObj.userEmail;
    setEmailInputs(tempEmailInputs);

    let tempNameInputs = [...nameInputs];
    tempNameInputs[el.id - 1] =
      userObj.userName === "admin"
        ? "admin"
        : userObj.label + " " + userObj.last_name;
    setNameInputs(tempNameInputs);

    el.name = userObj.label + " " + userObj.last_name;
    el.email = userObj.userEmail;
  };

  const handlePopulationTermination = (el, userObj) => {
    let tempEmailInputs = [...termEmailInputs];
    tempEmailInputs[el.id - 1] = userObj.userEmail;
    settermEmailInputs(tempEmailInputs);

    let tempNameInputs = [...termNameInputs];
    tempNameInputs[el.id - 1] =
      userObj.userName === "admin"
        ? "admin"
        : userObj.label + " " + userObj.last_name;
    settermNameInputs(tempNameInputs);

    el.name = userObj.label + " " + userObj.last_name;
    el.email = userObj.userEmail;
  };

  const handleReOpen = async () => {
    let contractStatus = {
      contractStatus: 8,
    };
    let dataResult;
    let result = putCrudApi(
      "contract/contractStatus/" + contractData.contractId,
      contractStatus
    );
    await result.then(function (res) {
      dataResult = res;
    });
    setContractData(dataResult);
    setStatus(dataResult.contractStatus);
    statusHistoryCall(dataResult?.contractId);
    setenableDraft(true);
    setenableSubmit(true);
    setDisableContractVariable(false);
    setenableAddReviewe(true);
    //setDisableTerminationVariable(false);
    setenableExecute(false);
    displayReopenMessage();
  };
  const getAllFileNames = () => {
    let fName = "";
    Object.keys(additionalFile).map((eachfile) => {
      if (Number(eachfile) + 1 === Object.keys(additionalFile).length) {
        fName = fName + additionalFile[eachfile].name;
      } else {
        fName = fName + additionalFile[eachfile].name + ",";
      }
    });
    return fName;
  };

  const OnButtonClickAdditionalFile = async (id) => {
    setAdditionalFilePreview(true);
    let dataResult;
    let result = getCrudApi("ContractAdditionalFiles/getFile/" + id, {});
    await result.then(function (res) {
      dataResult = res;
    });
    setAdditionalFilePreviewUrl("data:application/pdf;base64," + dataResult);
  };
  const OnAdditionalFilePreviewClose = () => {
    setAdditionalFilePreview(false);
  };
  const amendedContract = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    let result = getCrudApi("contract/" + contractData.amendedContractId, {});
    let dataResult;
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      navigate("/Contracts/View", {
        state: {
          templateSelected: null,
          template_version: null,
          tableData: [],
          templateType: null,
          contractData: dataResult,
          contractName: dataResult.contractName,
        },
      });
    }
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {loadingDynamicForm === true ? (
            <div className="contractName">
              {/* <div className="contract--div">
                {contractStatus !== null && contractStatus !== "" ? (
                  <label
                    // className="add-contract-status"
                    style={{ background: statusColor }}
                  >
                    <i class="fa fa-check statusCheck"></i> {contractStatus}
                  </label>
                ) : (
                  <></>
                )}
              </div> */}
              <div style={{ width: "100%" }}>
                <input
                  className="header-div"
                  value={contractName}
                  readOnly={
                    isNameEditingReadonly || (!enableDraft && !enableSubmit)
                  }
                  onChange={(e) => setContractName(e.target.value)}
                  onDoubleClick={() => {
                    setIsNameEditingReadonly(!isNameEditingReadonly);
                  }}
                ></input>
              </div>
              <div className="formbutton">
                {enableDraft === true ? (
                  <button
                    className="contracts-btn"
                    onClick={() => draftFullForm()}
                    disabled={!enableDraft}
                  >
                    Draft
                  </button>
                ) : null}
                {enableSubmit === true ? (
                  <button
                    className="contracts-btn"
                    onClick={() => submitFullForm()}
                    disabled={!enableSubmit}
                  >
                    Submit
                  </button>
                ) : null}
                {contractData ? (
                  contractData.amendedContractId &&
                  contractData.contractId !== null ? (
                    <button
                      className="contracts-btn"
                      onClick={(e) => amendedContract(e)}
                    >
                      Original Contract
                    </button>
                  ) : null
                ) : null}

                {/* <button
                  type="button"
                  className="marginButton"
                  onClick={(e) => OnDownloadWordClick(e)}
                >
                  Download Word
                </button> */}
                {/* <button
                  type="button"
                  className="marginButton"
                  onClick={(e) => OnTemplatePreviewClick(e)}
                >
                  Template Preview
                </button> */}
                {AmendmentEnabled === true ? (
                  <button
                    type="button"
                    className="contracts-btn"
                    onClick={(e) => OnAmendmentModalOpen(e)}
                    disabled={!AmendmentEnabled}
                  >
                    Amendment
                  </button>
                ) : null}
                {TerminationEnabled === true ? (
                  <button
                    type="button"
                    className="contracts-btn"
                    onClick={(e) => OnTerminationModalOpen(e)}
                    disabled={!TerminationEnabled}
                  >
                    Termination
                  </button>
                ) : null}
                {enableAddReviewe === true ? (
                  <button
                    type="button"
                    className="contracts-btn"
                    onClick={(e) => setAddReviewe(true)}
                    disabled={!enableAddReviewe}
                  >
                    Reviewer
                  </button>
                ) : null}

                {enableExecute === true ? (
                  <button
                    type="button"
                    className="contracts-btn"
                    onClick={handleExecuted}
                    disabled={!enableExecute}
                  >
                    Execute
                  </button>
                ) : null}
                {contractData &&
                (contractData.contractStatus === 6 ||
                  contractData.contractStatus === 7 ||
                  contractData.contractStatus === 14) &&
                contractData.formStateFlag === 2 ? (
                  <button
                    type="button"
                    className="contracts-btn"
                    onClick={handleReOpen}
                  >
                    Re-open
                  </button>
                ) : null}
                {contractData &&
                contractData.contractStatus === 11 &&
                contractData.signatureType === "manual" ? (
                  <button
                    type="button"
                    className="ExecuteButton"
                    onClick={(e) => OnTerminationExecution(e)}
                  >
                    Execute Termination
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="steps-container">
            {statusHistory?.map((status, index) => {
              return (
                // <li className="active" style={{
                //   content: status?.statusCount, counterIncrement: status?.statusCount
                // }}>{status?.contractStatus} {status?.statusCount}</li>
                <div
                  className={getStepClasses(
                    index,
                    statusHistory?.length - 1,
                    status?.active
                  )}
                  key={index}
                >
                  <div>
                    <div className={"circle"}>{`${
                      Object.values(status)[0]
                    }`}</div>
                  </div>
                  <div className="label">{`${Object.keys(status)[0]}`}</div>
                  {index !== statusHistory?.length - 1 ? (
                    <div className="line"></div>
                  ) : null}
                </div>
              );
            })}
          </div>

          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <ThemeProvider theme={theme}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              // indicatorColor="black"
              TabIndicatorProps={{
                style: {
                 display:"none"                 
                },
              }}
              // aria-label="basic tabs example"
              className="tabsAlign tab-width"
            >
              <Tab className="Tabs-background" label="intelli Form" {...a11yProps(0)} />

              {templateType === 10 ? null : (
                <Tab className="Tabs-background" label="Contract" {...a11yProps(1)} />
              )}

              {isTermination ? (
                <Tab className="Tabs-background" label="Termination" {...a11yProps(2)} />
              ) : null}
              {isTermination ? (
                <Tab className="Tabs-background" label="Termination Preview" {...a11yProps(3)} />
              ) : null}
              <Tab className="Tabs-background"
                label="Contract Preview"
                {...a11yProps(isTermination ? 4 : 2)}
              />
              {isExecutedPreview ? (
                <Tab className="Tabs-background" 
                  label="Executed Preview"
                  {...a11yProps(isTermination ? 5 : 3)}
                />
              ) : null}
              {IsExecutedPreviewTermination ? (
                <Tab className="Tabs-background" label="Executed Termination Preview" {...a11yProps(6)} />
              ) : null}
            </Tabs>
            </ThemeProvider>
          </Box> */}
          <div>
            <div className="Tab-nav-contract">
              <div
                className={value === 0 ? "tabs-active" : "tab-div"}
                onClick={() => handleTabChange(0)}
              >
                {" "}
                <label> Intelli Form</label>
              </div>

              {templateType === 10 ? null : (
                <div
                  className={value === 1 ? " tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(1)}
                >
                  Contract
                </div>
              )}
              {isTermination ? (
                <div
                  className={value === 2 ? " tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(2)}
                >
                  Termination Editor{" "}
                </div>
              ) : null}
              {!IsExecutedPreviewTermination && isTermination ? (
                <div
                  className={value === 3 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(3)}
                >
                  Termination Preview
                </div>
              ) : null}

              {!isExecutedPreview || isAmendment || enableDraft ? (
                <div
                  className={
                    value === (isTermination ? 4 : 2)
                      ? " tabs-active"
                      : "tab-div"
                  }
                  onClick={() => handleTabChange(isTermination ? 4 : 2)}
                >
                  Contract Preview
                </div>
              ) : null}
              {isExecutedPreview ? (
                <div
                  className={
                    value === (isTermination ? 5 : 3)
                      ? "tabs-active"
                      : "tab-div"
                  }
                  onClick={() => handleTabChange(isTermination ? 5 : 3)}
                >
                  Executed Preview
                </div>
              ) : null}
              {IsExecutedPreviewTermination ? (
                <div
                  className={value === 6 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(6)}
                >
                  Executed Termination Preview
                </div>
              ) : null}
            </div>
          </div>

          {value === 0 ? (
            <>
              <div className="formCard-contract">
                {JSONforVariables ? (
                  <div>
                    <DynamicForm
                      className="main-dynamic-form"
                      formFields={JSONforVariables}
                      buttonBackgroundColor="#0000ff"
                      buttonColor="#fff"
                      formBorderColorOnFocus="#0000ff"
                      errorTextColor="#dc143c"
                      getForm={(form) => {
                        setJSON(form);
                      }}
                      setSubmitDisabled={(val) => {
                        setenableSubmit(val);
                      }}
                      formDisabled={disableForm}
                      draftRequired={true}
                      enableDraft={enableDraft}
                      enablesubmit={enableSubmit}
                      disableContractVariable={disableContractVariable}
                      disableTerminationVariable={disableTerminationVariable}
                    />
                  </div>
                ) : null}

                {signatureVariables !== undefined &&
                signatureVariables !== null ? (
                  signatureVariables.length > 0 ? (
                    <div className="sig_container">
                      {signatureVariables.map((el, i) => {
                        return (
                          <div key={i}>
                            <div className="signature-ribbon-header">
                              <label className=" sign-contract">
                                Signatory {el.id} -{" "}
                                {el.isExternal === true
                                  ? "External"
                                  : "Internal"}
                              </label>
                            </div>
                            <div>
                              <div className="dynamicForm-sign-sub-container">
                                {el.isExternal === true ? (
                                  <>
                                    <div className="dynamicForm-sign-container">
                                      <label className="sign-label">Name</label>
                                      <input
                                        className="Dynamic-Form-Input text-form"
                                        key={i}
                                        type="text"
                                        placeholder="Name"
                                        value={nameInputs[i]}
                                        disabled={disableContractVariable}
                                        onChange={(event) => {
                                          handleNameInputs(event, el, i);
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <label className="sign-label">
                                        Email
                                      </label>
                                      <input
                                        key={i}
                                        id={`signer_email-${el.id}`}
                                        type="text"
                                        placeholder="Email"
                                        className="text-form"
                                        value={emailInputs[i]}
                                        onChange={(event) => {
                                          handleEmailInput(event, el, i);
                                        }}
                                        disabled={disableContractVariable}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <label className="sign-label">Name</label>
                                      <SearchField
                                        key={i}
                                        id={`signer_name-${el.id}`}
                                        data={allUsers}
                                        placeholder="Users"
                                        val={nameInputs[i]}
                                        onChange={(e) => {
                                          el.name = e.target.value;
                                          handleNameInputs(e, el, i);
                                        }}
                                        getSelectedUserObj={(
                                          userObj,
                                          value
                                        ) => {
                                          handlePopulation(el, userObj, value);
                                        }}
                                        disabled={disableContractVariable}
                                      />
                                    </div>
                                    <div className="dynamicForm-sign-container">
                                      <label className="sign-label">
                                        Email
                                      </label>
                                      <input
                                        key={i}
                                        id={`signer_email-${el.id}`}
                                        type="text"
                                        readOnly
                                        placeholder="Email"
                                        className="text-form"
                                        value={emailInputs[i]}
                                        onChange={(event) => {
                                          handleEmailInput(event, el, i);
                                        }}
                                        disabled={disableContractVariable}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null
                ) : null}

                {terminationSignatureVariables !== undefined &&
                terminationSignatureVariables !== null ? (
                  terminationSignatureVariables.length > 0 ? (
                    <div className="sig_container">
                      {terminationSignatureVariables.map((el, i) => {
                        return (
                          <div key={i}>
                            <div className="Termination-Signatory-ribbon-header">
                              <label className="sign-contract">
                                Termination Signatory {el.id} -{" "}
                                {el.isExternal === true
                                  ? "External"
                                  : "Internal"}
                              </label>
                            </div>
                            <div>
                              <div className="dynamicForm-sign-sub-container">
                                {el.isExternal === true ? (
                                  <>
                                    <div>
                                      <label className="sign-label">Name</label>
                                      <input
                                        key={i}
                                        type="text"
                                        value={termNameInputs[i]}
                                        className="text-form"
                                        onChange={(event) => {
                                          handleNameInputsTermination(
                                            event,
                                            el,
                                            i
                                          );
                                        }}
                                        disabled={disableTerminationVariable}
                                      />
                                    </div>
                                    <div>
                                      <label className="sign-label">
                                        Email
                                      </label>

                                      <input
                                        key={i}
                                        id={`signer_email-${el.id}`}
                                        type="text"
                                        className="text-form"
                                        value={termEmailInputs[i]}
                                        onChange={(event) => {
                                          handleEmailInputTermination(
                                            event,
                                            el,
                                            i
                                          );
                                        }}
                                        disabled={disableTerminationVariable}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <label className="sign-label">Name</label>

                                      <SearchField
                                        key={i}
                                        id={`signer_name-${el.id}`}
                                        data={allUsers}
                                        placeholder="users"
                                        val={termNameInputs[i]}
                                        onChange={(e) => {
                                          el.name = e.target.value;
                                          handleNameInputsTermination(e, el, i);
                                        }}
                                        getSelectedUserObj={(
                                          userObj,
                                          value
                                        ) => {
                                          handlePopulationTermination(
                                            el,
                                            userObj,
                                            value
                                          );
                                        }}
                                        disabled={disableTerminationVariable}
                                      />
                                    </div>
                                    <div>
                                      <label className="sign-label">
                                        Email
                                      </label>
                                      <input
                                        key={i}
                                        id={`signer_email-${el.id}`}
                                        type="text"
                                        readOnly
                                        className="text-form"
                                        value={termEmailInputs[i]}
                                        onChange={(event) => {
                                          handleEmailInputTermination(
                                            event,
                                            el,
                                            i
                                          );
                                        }}
                                        disabled={disableTerminationVariable}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              {/* <div
                                style={{
                                  width: "50%",
                                  margin: "5px 5px 5px 20px",
                                }}
                              >
                                <label className="sign-label">Email</label>
                                <input
                                  key={i}
                                  id={`signer_email-${el.id}`}
                                  type="text"
                                  className="text-form"
                                  value={termEmailInputs[i]}
                                  onChange={(event) => {
                                    handleEmailInputTermination(event, el, i);
                                  }}
                                  disabled={disableTerminationVariable}
                                />
                              </div> */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null
                ) : null}
                <div className="file-uploader">
                  <label className="commentLabel">Additional File Upload</label>
                  {enableSubmit === true || enableDraft === true ? (
                    <>
                      <div className="file-div">
                        <FileUploader
                          className="fileUpload"
                          multiple={true}
                          color="#48336e"
                          fileOrFiles={additionalFile}
                          handleChange={handleChangeAdditionalFiles}
                          name="file"
                          types={fileTypes}
                        />
                        {isMerged ? (
                          <div>
                            <label className="file-upload-label">
                              Add to Contract
                            </label>
                            <Checkbox
                              size={3}
                              borderThickness={1}
                              backAnimationDuration={10}
                              tickAnimationDuration={100}
                              color="#48336e"
                              className="add-to-contract-checkbox"
                              checked={isMergedRequired}
                              onChange={handleMergedRequiredClick}
                            ></Checkbox>
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="fileNameDisplay">
                    {enableSubmit === true || enableDraft === true ? (
                      <>
                        <p>
                          {additionalFile
                            ? "File Name:" + getAllFileNames()
                            : null}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                    {additionalFileApi ? (
                      <div className="fileDisplay">
                        {Object.keys(additionalFileApi).map((obj) => {
                          return (
                            <div className="fileDisplay-name ">
                              <button
                                onClick={() => {
                                  OnButtonClickAdditionalFile(
                                    additionalFileApi[obj]
                                      .contractAdditionalFileId
                                  );
                                }}
                              >
                                <div>
                                  <i
                                    className="far fa-file-pdf"
                                    style={{ fontSize: "40px", color: "red" }}
                                  ></i>
                                </div>
                                <div>
                                  <label className="ActiveLabel">
                                    {additionalFileApi[obj].fileName}
                                  </label>
                                </div>
                              </button>
                            </div>
                          );
                        })}{" "}
                      </div>
                    ) : additionalFile.length !== 0 ? (
                      <> </>
                    ) : (
                      <label className="commentLabel"> No files to show</label>
                    )}
                  </div>
                </div>

                <div className="comment-table">
                  <label className="commentLabel">Comment</label>
                  <TextArea
                    className="TextAreaStyle"
                    value={comments}
                    onChange={(e) =>
                      onCommentsChange(e.target.name, e.target.value)
                    }
                  ></TextArea>
                </div>
                <div className="comment-table">
                  <OptimisedMUIDataTable
                    customTheme={customTheme}
                    data={commentsJson}
                    columns={generateColumns}
                    options={options}
                  />
                </div>
              </div>
            </>
          ) : null}
          {templateType === 10 ? null : value === 1 ? (
            <>
              <div className="editor-main-div">
                <div>
                  <RecoilRoot>
                    <PlateEditor
                      id={
                        isNegotiation &&
                        (negotiationComments.length !== 0 ||
                          negotiationComments !== null)
                          ? "contractEditor"
                          : "editorContent "
                      }
                      ref={plateRef}
                      isNegotiationDisabled={true}
                      isVariableDisabled={true}
                      isPartiesDisabled={true}
                      isConditionDisabled={true}
                      isConditionToggleDisabled={false}
                      isCommentToggleDisabled={true}
                      isClauseDisabled={true}
                      setContractTemplateJsonData={(val) => {
                        setHandlebarTemplate(val);
                      }}
                      contractTemplateJsonData={
                        handlebarTemplate ? handlebarTemplate : null
                      }
                      isEditable={isPlateEditable}
                      clauseLibrary={clauseLibrary}
                      canAddComments={false}
                      serializeCall={serializeCall}
                      clauseEditable={true}
                      conditions={contractConditions.ContractConditions}
                      pageSize={pageSize}
                      // commentJSON={[]}
                    />
                  </RecoilRoot>
                </div>
                {isNegotiation &&
                (negotiationComments.length !== 0 ||
                  negotiationComments !== null) ? (
                  <div>
                    <RecoilRoot>
                      <PlateEditor
                        id={"contractNegoEditor"}
                        ref={negoPlateRef}
                        commentJSON={negotiationComments}
                        setCommentsJson={(val) => {
                          setnegotiationComments(val);
                        }}
                        isNegotiationDisabled={true}
                        isVariableDisabled={true}
                        isPartiesDisabled={true}
                        isConditionDisabled={true}
                        isClauseDisabled={true}
                        isConditionToggleDisabled={true}
                        isCommentToggleDisabled={false}
                        // setContractTemplateJsonData={(val) => {
                        //   setnegotiationTemplate(val);
                        // }}
                        contractTemplateJsonData={
                          negotiationTemplate ? negotiationTemplate : null
                        }
                        isEditable={false}
                        clauseLibrary={clauseLibrary}
                        canAddComments={true}
                        serializeCall={"serializeContract"}
                        pageSize={pageSize}
                      />
                    </RecoilRoot>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {isTermination ? (
            value === 2 ? (
              <>
                <RecoilRoot>
                  <PlateEditor
                    id={"contractTerminationEditor"}
                    ref={terminationPlateRef}
                    isNegotiationDisabled={true}
                    isVariableDisabled={true}
                    isPartiesDisabled={true}
                    isConditionDisabled={true}
                    isConditionToggleDisabled={false}
                    isCommentToggleDisabled={true}
                    isClauseDisabled={true}
                    setContractTemplateJsonData={(val) => {
                      setHandlebarTemplateTermination(val);
                    }}
                    contractTemplateJsonData={
                      handlebarTemplateTermination
                        ? handlebarTemplateTermination
                        : null
                    }
                    canAddComments={false}
                    serializeCall={"serializeContract"}
                    conditions={terminationConditions.TerminationConditions}
                  />
                </RecoilRoot>
              </>
            ) : null
          ) : null}
          {isTermination ? (
            value === 3 ? (
              <>
                {/* <div id="pdfRenderer"></div> */}

                {pdfDataTermination !== null ? (
                  <div className="print-preview">
                    {/* <AllPagesPDFViewer pdf={pdfDataUrl} /> */}
                    <iframe
                      id="scaled-frame"
                      title="pdfviewer"
                      style={{
                        margin: "auto auto 5px auto",
                        width: "1200px",
                        height: "950px",
                      }}
                      src={pdfDataTermination}
                    />
                  </div>
                ) : (
                  <Loading />
                )}
              </>
            ) : null
          ) : null}
          {value === (isTermination ? 4 : 2) ? (
            <>
              {/* <div id="pdfRenderer"></div> */}
              {pdfDataUrl !== null ? (
                <div className="print-preview">
                  {/* <AllPagesPDFViewer pdf={pdfDataUrl} /> */}
                  <iframe
                    id="scaled-frame"
                    title="pdfviewer"
                    style={{
                      margin: "auto auto 5px auto",
                      width: "1200px",
                      height: "950px",
                    }}
                    src={pdfDataUrl}
                  />
                </div>
              ) : (
                <Loading />
              )}
            </>
          ) : null}

          {isExecutedPreview ? (
            value === (isTermination ? 5 : 3) ? (
              <>
                {pdfExecuteUrl !== null ? (
                  <div className="print-preview">
                    {/* <AllPagesPDFViewer pdf={pdfExecuteUrl} /> */}
                    <iframe
                      id="scaled-frame"
                      title="pdfviewer"
                      style={{
                        margin: "0px 0px 0px 130px",
                        width: "1200px",
                        height: "950px",
                      }}
                      src={pdfExecuteUrl}
                    />
                  </div>
                ) : (
                  <Loading />
                )}
              </>
            ) : null
          ) : null}

          {IsExecutedPreviewTermination ? (
            value === 6 ? (
              <>
                {PdfExecuteUrlTermination !== null ? (
                  <div className="print-preview">
                    {/* <AllPagesPDFViewer pdf={pdfExecuteUrl} /> */}
                    <iframe
                      id="scaled-frame"
                      title="pdfviewer"
                      style={{
                        margin: "0px 0px 0px 130px",
                        width: "1200px",
                        height: "950px",
                      }}
                      src={PdfExecuteUrlTermination}
                    />
                  </div>
                ) : (
                  <Loading />
                )}
              </>
            ) : null
          ) : null}
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></div>
        </>
      )}

      {openTemplatePreview ? (
        <div className="modal">
          <div className="modal-content">
            {/* {pdfBlobUrl !== null ? (
            <iframe
              id="previewID"
              onLoad={(e) => {
                
                //e.target.body.style.color = "blue";
                
              }}
              ref={modalRef}
              src={pdfBlobUrl}
              style={{ height: "1000px", background: "green" }}
            />
          ) : null} */}
            {/* <div id="pdfRenderer"></div> */}
            {/* {pdfBlobUrl !== null ? (
            <PDFViewer>
              <Document file={window.URL.createObjectURL(pdfBlobUrl)}>
                <Page pageNumber={pageNumber} />
              </Document>
            </PDFViewer>
          ) : null} */}
          </div>
          <div>
            <button
              type="button"
              className="close-btn"
              onClick={() => setOpenTemplatePreview(false)}
            >
              Close
            </button>
          </div>
        </div>
      ) : null}

      {AddAmendmentOpen ? (
        <div className="modal">
          <div className="modal-content">
            <div className="header-modal">
              <h5 className="header-title">Amendments</h5>
            </div>
            <div className="div-display">
              <label className="label-class">Contract Type</label>

              <SyfedTreeSelect
                className="treeSelectStyle"
                size="small"
                treeCheckedStrategy="all"
                dataSource={SelectMappingForContractType()}
                onChange={onChangeOfAmendmentContractTreeSelect}
                placeholder="Select Contract Type"
                value={amendmentContractTypeSelected}
              />
            </div>
            <div className="div-display">
              <label className="label-class">Category Type</label>

              <SyfedTreeSelect
                className="treeSelectStyle"
                size="small"
                treeCheckedStrategy="all"
                dataSource={SelectMappingForCategory()}
                onChange={onChangeOfAmendmentCategoryTreeSelect}
                placeholder="Select Category Type"
                value={amendmentCategoryTypeSelected}
              />
            </div>
            <div className="div-display">
              <label className="label-class">Amendment Template Name</label>
              <i className="fa fa-asterisk aster-risk-Icon-amend-termination"></i>

              <Select
                maxMenuHeight={120}
                menuPlacement="bottom"
                styles={selectStyles}
                value={amendmentTemplateName}
                onChange={amendmentTemplateChange}
                options={amendmentTemplateNamesList}
              />
            </div>

            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setAddAmendmentOpen(false);
                  setamendmentCategoryTypeSelected(0);
                  setamendmentCategoryTypeSelected(0);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={() => OnAmendmentModalClose()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/*----------------------------------termination----------------------------------*/}
      {AddTerminationOpen ? (
        <div className="modal ">
          <div className="modal-content ">
            <div className="header-modal">
              <h5 className="header-title">Terminations</h5>
            </div>

            <div className="div-display">
              <label className="label-class">Category Type</label>

              <SyfedTreeSelect
                className="treeSelectStyle"
                size="small"
                treeCheckedStrategy="all"
                dataSource={SelectMappingForCategory()}
                onChange={onChangeOfTerminationCategoryTreeSelect}
                placeholder="Select Category Type"
                value={terminationCategoryTypeSelected}
              />
            </div>

            <div className="div-display">
              <label className="label-class">Contract Type</label>

              <SyfedTreeSelect
                className="treeSelectStyle"
                size="small"
                treeCheckedStrategy="all"
                dataSource={SelectMappingForContractType()}
                onChange={onChangeOfTerminationContractTreeSelect}
                placeholder="Select Contract Type"
                value={terminationContractTypeSelected}
              />
            </div>

            <div className="div-display">
              <label className="label-class">Termination Template Name</label>
              <i className="fa fa-asterisk aster-risk-Icon-amend-termination"></i>

              <Select
                maxMenuHeight={120}
                menuPlacement="bottom"
                styles={selectStyles}
                value={terminationTemplateName}
                onChange={terminationTemplateChange}
                options={terminationTemplateNamesList}
              />
            </div>

            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setAddTerminationOpen(false);
                  setterminationCategoryTypeSelected(0);
                  setterminationContractTypeSelected(0);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={() => OnTerminationModalClose()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/*----------------------------------reviewer----------------------------------*/}
      {AddReviewe ? (
        <div className="modal">
          <div className="modal-content">
            <div className="header-modal">
              <h5 className="header-title">Reviewers</h5>
            </div>
            <div className="div-display">
              <label className="label-class">Reviewer</label>

              <Select
                value={selectedReviewer}
                styles={selectStyles}
                onChange={HandleReviewerChange}
                options={SelectMappingForReviewers()}
              />
            </div>
            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={OnAddRevieweClose}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={OnAddReviewe}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/*----------------------------------negotiation----------------------------------*/}
      {addNegotiation ? (
        <div className="modal">
          <div className="modal-content">
            <div className="header-modal">
              <h5 className="header-title">Negotiator Details</h5>
            </div>
            <div className="div-display">
              <label className="label-class">Contract Owner</label>

              <Select
                value={contractOwnerList
                  .filter((own) => own.userId === selectedOwner)
                  ?.map((usr) => {
                    return { label: usr.userName, value: usr.userId };
                  })}
                styles={selectStyles}
                onChange={(e) => setselectedOwner(e.value)}
                options={SelectMappingForOwner()}
              />
            </div>
            <div className="div-display">
              <label className="label-class">Negotiation Required</label>
              <Select
                value={{
                  label: negotiationRequired === true ? "Yes" : "No",
                  value: negotiationRequired,
                }}
                onChange={handleRequiredNegotiationSelect}
                options={negotiationOptions}
                styles={selectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: " #E5E5E5",
                    primary: "#B2D4FD ",
                  },
                })}
              ></Select>
            </div>
            <div className="div-display">
              <label className="label-class">
                Email ID{" "}
                {negotiationRequired ? (
                  <i className="fa fa-asterisk aster-risk-Icon"></i>
                ) : null}
              </label>
              <input
                type="text"
                className="textbox-input_1"
                value={selectedNegotiator}
                placeholder="Email ID"
                onChange={(e) => HandleNegotiatorChange(e.target.value)}
              />
              {invalidEmail ? (
                <label className="invalid_regex">Invalid Email</label>
              ) : null}
            </div>
            <div className="div-display">
              <label className="label-class">Signing</label>
              <Select
                value={
                  siginigMethodsTypes?.filter(
                    (data) => data.value === signingMethod
                  )?.length > 0
                    ? siginigMethodsTypes?.filter(
                        (data) => data.value === signingMethod
                      )[0]
                    : {}
                }
                onChange={handle_signature_method}
                options={siginigMethodsTypes}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: " #E5E5E5",
                    primary: "#B2D4FD ",
                  },
                })}
                styles={selectStyles}
              >
                {/* <MenuItem value="manual">Manual</MenuItem>
                <MenuItem value="docusign">DocuSign</MenuItem> */}
              </Select>
            </div>

            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={OnAddNegotiatorClose}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={OnAddNegotiator}
                disabled={disableNegoAdd}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/*----------------------------------fileUpload Execute----------------------------------*/}
      {execution ? (
        <div className="modal">
          <div className=" modal-content">
            <div className="header-modal">
              <h5 className="header-title">
                Drag and Drop or Select Executed Contract
              </h5>
            </div>
            <div className="FileUploader-div">
              <FileUploader
                className="fileUpload"
                multiple={false}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
              <p>{file ? "File name:" + file.name : "No files uploaded"}</p>
            </div>
            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={OnAddExecutionClose}
              >
                Close
              </button>
              {file ? (
                <button
                  type="button"
                  className="Modal-btn"
                  onClick={OnAddExecution}
                >
                  Add
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      {/*-----------------------file execute preview ----------------------------------*/}
      {additionalFilePreview ? (
        <div className="modal customModal">
          <div className="print-preview">
            {additionalFilePreviewUrl !== null ? (
              // <div className="pdfPop">
              //   {/* <div >
              //   <AllPagesPDFViewer pdf={additionalFilePreviewUrl} />
              // </div> */}
              //   <div>
              <iframe
                id="scaled-frame"
                title="pdfviewer"
                style={{
                  margin: "47px 0px 0px 130px",
                  width: "1200px",
                  height: "950px",
                }}
                src={additionalFilePreviewUrl}
              />
            ) : (
              //   </div>
              // </div>
              <Loading />
            )}
            <div className="pdf-preview-close-btn">
              <button type="button" onClick={OnAdditionalFilePreviewClose}>
                <i
                  className="fa fa-window-close"
                  style={{ fontSize: "48px", color: "red" }}
                ></i>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
