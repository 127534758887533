import { setIndent } from './setIndent';

/**
 * Decrease the indentation of the selected blocks.
 */
export const outdent = (
  editor,
  options
) => {
  setIndent(editor, { offset: -1, ...options });
};
