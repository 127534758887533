import {
    createPluginFactory,
    ELEMENT_DEFAULT,
    getPluginType,
} from '@udecode/plate';
import { onKeyDownIndent } from './onKeyDownIndent';
import { withRightIndent } from './withIndent';

export const RIGHT_KEY_INDENT = 'rightindent';

export const createRightIndentCustomPlugin = createPluginFactory({
    key: 'rightindent',
    withOverrides: withRightIndent,
    // handlers: {
    //     onKeyDown: onKeyDownIndent,
    // },
    options: {
        offset: 36,
        unit: 'pt',
    },
    then: (editor, { options: { offset, unit } = {} }) => ({
        inject: {
            props: {
                nodeKey: RIGHT_KEY_INDENT,
                styleKey: 'marginRight',
                validTypes: [getPluginType(editor, ELEMENT_DEFAULT)],
                transformNodeValue: ({ nodeValue }) => nodeValue * offset + unit,
            },
        },
    }),
});
