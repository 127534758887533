import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import "./SimpleEditor.css";
import { useNavigate } from "react-router";
import { getCrudApi } from "../../../webServiceCalls/webServiceCall";
import { mapStatusWithoutUderscore } from "../../../Helpers/hierarchyHelper";
import { validateToken } from "../../../Helpers/validateToken";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import Loading from "../../../Loading/Loading";
export default function ClauseLibrary(props) {
  const [tableData, setTableData] = useState([]);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [clauseName, setClauseName] = useState("");
  const [page, setPage] = useState(0);
  const [dis, setdis] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getClauseData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi("clauses/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    setTableData(dataResult);
    setIsLoading(false);
  };

  const navigate = useNavigate();

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                style={{ textDecoration: "none" }}
                to="/Admin/Clause/Edit"
                state={{
                  selectedRowData:
                    tableMeta.rowData !== null ? tableMeta.rowData : null,
                  selectedClause:
                    tableMeta.rowData[2] !== null ? tableMeta.rowData[2] : null,
                  clauseData: tableData.filter(
                    (element) => element.clauseId === tableMeta.rowData[1]
                  )[0],
                }}
              >
                <button>
                  <i className="fa fa-edit custom-iconformodal" />
                </button>
              </Link>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);
    columnsArray.push({
      name: "clauseId",
      label: "Clause ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "clauseName",
      label: "NAME",
    });
    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  state === "Active"
                    ? "table-active-cell"
                    : "table-inactive-cell"
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    columnsArray.push({
      name: "statusId",
      label: "Status",
      options: {
        display: false,
      },
    });

    return columnsArray;
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "clauseId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
      {
      }
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const OnAddClauseOpen = (e) => {
    setAddModalOpen(true);
  };

  const OnAddClauseClose = async (e) => {
    setAddModalOpen(false);
    navigate("/Admin/Clause/Edit", {
      state: {
        selectedRowData: null,
        selectedClause: clauseName,
        clauseData: null,
      },
    });
  };

  useEffect(() => {
    getClauseData();

    const interval = setInterval(
      getClauseData,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, []);

  const handleError = (e) => {
    setClauseName(e.target.value);
    const regx = /^[A-Za-z][A-Za-z0-9_()\-[\] ]*$/;
    if (e.target.value !== "" && !regx.test(e.target.value)) {
      setTitleError(
        "Name must begin with an alphabet, rest should be alphanumeric or contain only _, -, (), [] as its special character."
      );
      setdis(true);
    } else if (e.target.value === "") {
      setTitleError("Name Required");
      setdis(true);
    } else {
      setTitleError("");
      setdis(false);
    }
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn "
              onClick={(e) => OnAddClauseOpen(e)}
            >
              Add
            </button>
          </div>
          <div className="Table-body">
            <div className="table-scroll ">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={mapStatusWithoutUderscore(tableData)}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}

      {AddModalOpen ? (
        <div className="modal">
          <div className="modal-content">
            <div className="header-modal">
              <h5 className="header-title"> Clause</h5>
            </div>
            <div className="div-display">
              <label className="label-class">
                Clause Name <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>

              <input
                type="text"
                placeholder="Clause Name"
                onChange={handleError}
                className="textbox-input_1"
              />
              {titleError !== "" ? (
                <div className="alert-danger">{titleError}</div>
              ) : null}
            </div>

            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={() => setAddModalOpen(false)}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={OnAddClauseClose}
                disabled={dis}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
