import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { renewalTypeData: {} };

export const renewalTypeSlice = createSlice({
  name: "contractTemplateState",
  initialState: { value: initialStateValue },
  reducers: {
    setrenewalTypeData: (state, action) => {
      state.value.renewalTypeData = action.payload;
    },
  },
});

export const { setrenewalTypeData } = renewalTypeSlice.actions;

export default renewalTypeSlice.reducer;
