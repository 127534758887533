import { Route, Routes, useLocation } from "react-router-dom";
import React from "react";
import LoginNew from "../components/login/loginNew";
import Negotiation from "../app/negotiation/Negotiation";
import PrivateRoute from "../app/PrivateRoute";
import "./App.css";
import GlobalDndContext from "../dnd-context";
import PublicRoutes from "./PublicRoutes";
import PageNotFound from "./PageNotFound";
import UnAuthorized from "./UnAuthorized";
import MainPage from "./MainPage";
import Design from "./shared/admin/admin-submenu";
import Category from "./Admin/category/Category";
import ContractTypes from "./Admin/contractTypes/ContractTypes";
import Editor from "./editor/editor";
import SimpleEditor from "./Admin/clauseLibrary/SimpleEditor";
import ClauseLibrary from "./Admin/clauseLibrary/ClauseLibrary";
import TableForContractTemplate from "./Admin/contractTemplates/ContractTemplates";
import Amendment from "./Admin/Amendment/Amendment";
import AmendmentEditor from "./Admin/Amendment/AmendmentEditor";
import TerminationTemplate from "./Admin/Termination/Termination";
import TerminationEditor from "./Admin/Termination/TerminationEditor";
import Departments from "./Admin/departments/Departments";
import Location from "./Admin/location/Location";
import MasterLibrary from "./Admin/masterLibrary/MasterLibrary";
import Rolemanegments from "./Admin/roleManagement/RoleManagement";
import UserManagement from "./Admin/userManagement/UserManagement";
import EsignatureIntegration from "./Admin/Esignature/EsignatureIntegration";
import ManageIntegrations from "./Admin/Esignature/ManageIntegrations";
import OldChildTable from "./Admin/masterLibrary/ChildTable";
import ManageReviewer from "./manageReviewers/ManageReviewer";
import SubmitReviewe from "./manageReviewers/SubmitReviewe";
import Approvals from "./approvals/Approvals";
import Submit from "./approvals/Submit";
import DynamicFormForContract from "./manageContracts/DynamicFormForContract";
import ManageContracts from "./manageContracts/ManageContracts";
import DynamicFormForRequest from "./manageRequests/DynamicFormForRequest";
import ManageRequests from "./manageRequests/ManageRequests";
import DocuSignIntergration from "./Admin/Esignature/DocuSignIntergration";
import AdobeSignIntergration from "./Admin/Esignature/AdobeSignIntergration";
import SalesForcesIntegration from "./Admin/Esignature/SalesForcesIntegration";
import EmudhraIntegration from "./Admin/Esignature/emudhraIntegration";
import FreshSaleSuitsIntegration from "./Admin/Esignature/FreshSaleSuitIntegration";
import FreshTeamIntegration from "./Admin/Esignature/FreshTeamIntegration";
import PanDocIntegration from "./Admin/Esignature/PanDocIntegration";
import WorkDayIntegration from "./Admin/Esignature/WorkDayIntegration";
import FreshworkIntegration from "./Admin/Esignature/FreshWorksIntegration";
import NotificationSettings from "./editor/notificationSettings";
import Notifications from "./Admin/Notification/Notification";
import NotificationEditor from "./Admin/Notification/NotificationEditor";
import CompanySettings from "./Admin/CompanySettings/CompanySettings";
import ForgotPassword from "../components/ForgotPassword";
import Userform from "./Admin/userManagement/UserForm";
import useIdle from "../Helpers/useIdleTimer";

import { useState } from "react";
import { useEffect } from "react";
import { RouteContext } from "../RouterContext";
import Dashboard from "./dashboard/Dashboard";
import Report from "./Report/Report";
import { Relogin } from "../webServiceCalls/webServiceCall";
import { logout } from "../Helpers/validateToken";
const componentsregistry = {
  Design: <Design />,
  Category: <Category />,
  ContractTypes: <ContractTypes />,
  Editor: <Editor />,
  SimpleEditor: <SimpleEditor />,
  ClauseLibrary: <ClauseLibrary />,
  TableForContractTemplate: <TableForContractTemplate />,
  Amendment: <Amendment />,
  AmendmentEditor: <AmendmentEditor />,
  TerminationTemplate: <TerminationTemplate />,
  TerminationEditor: <TerminationEditor />,
  Departments: <Departments />,
  Location: <Location />,
  MasterLibrary: <MasterLibrary />,
  Rolemanegments: <Rolemanegments />,
  UserManagement: <UserManagement />,
  EsignatureIntegration: <EsignatureIntegration />,
  ManageIntegrations: <ManageIntegrations />,
  OldChildTable: <OldChildTable />,
  ManageReviewer: <ManageReviewer />,
  SubmitReviewe: <SubmitReviewe />,
  Approvals: <Approvals />,
  Submit: <Submit />,
  DynamicFormForContract: <DynamicFormForContract />,
  ManageContracts: <ManageContracts />,
  DynamicFormForRequest: <DynamicFormForRequest />,
  ManageRequests: <ManageRequests />,
  Dashboard: <Dashboard />,
  DocuSignIntergration: <DocuSignIntergration />,
  AdobeSignIntergration: <AdobeSignIntergration />,
  SalesForcesIntegration: <SalesForcesIntegration />,
  EmudhraIntegration: <EmudhraIntegration />,
  FreshSaleSuitsIntegration: <FreshSaleSuitsIntegration />,
  FreshTeamIntegration: <FreshTeamIntegration />,
  PanDocIntegration: <PanDocIntegration />,
  WorkDayIntegration: <WorkDayIntegration />,
  FreshworkIntegration: <FreshworkIntegration />,
  NotificationSettings: <NotificationSettings />,
  CompanySettings: <CompanySettings />,
  Notifications: <Notifications />,
  NotificationEditor: <NotificationEditor />,
  ForgotPassword: <ForgotPassword />,
  Reports: <Report />,
  UserForm: <Userform />,
}; //Update every time you create a component
const routingpaths = [
  {
    path: "/Dashboard",
    element: "Dashboard",
    roleId: -1,
    breadcrumb: "Dashboard",
    icon: <i className="fa fa-pie-chart" />,
  },
  {
    path: "/Request",
    element: "ManageRequests",
    roleId: 4,
    breadcrumb: "Manage Requests",
    icon: <i className="	fa fa-file" />,
  },
  {
    path: "/Contracts",
    element: "ManageContracts",
    roleId: 5,
    breadcrumb: "Manage Contracts",
    icon: <i className="fa fa-file-text" />,
  },
  {
    path: "/reviewer",
    element: "ManageReviewer",
    roleId: 2,
    breadcrumb: "Manage Reviews",
    icon: <i className="fa fa-pencil-square" />,
  },
  {
    path: "/approvals",
    element: "Approvals",
    roleId: 3,
    breadcrumb: "Approvals",
    icon: <i className="fa fa-check-circle" />,
  },
  {
    path: "/Reports",
    element: "Reports",
    roleId: 1,
    breadcrumb: "Reports",
    icon: <i className="fa fa-line-chart" />,
  },
  {
    path: "/Admin",
    element: "Design",
    roleId: 1,
    breadcrumb: "Admin",
    icon: <i className="fas fa-user-shield" />,
  },
  {
    path: "/Admin/category",
    element: "Category",
    roleId: 1,
    breadcrumb: "Category",
  },
  {
    path: "/Admin/contractTypes",
    element: "ContractTypes",
    roleId: 1,
    breadcrumb: "Contract Types",
  },
  {
    path: "/Admin/contractTemplates/editor",
    element: "Editor",
    roleId: 1,
    breadcrumb: "Edit",
  },
  {
    path: "/Admin/Clause/Edit",
    element: "SimpleEditor",
    roleId: 1,
    breadcrumb: "Edit",
  },
  {
    path: "/Admin/Clause",
    element: "ClauseLibrary",
    roleId: 1,
    breadcrumb: "Clause Library",
  },
  {
    path: "/Admin/contractTemplates",
    element: "TableForContractTemplate",
    roleId: 1,
    breadcrumb: "Contract Templates",
  },
  {
    path: "/Admin/contractTypes",
    element: "ContractTypes",
    roleId: 1,
    breadcrumb: "Contract Types",
  },
  {
    path: "/Admin/Amendment",
    element: "Amendment",
    roleId: 1,
    breadcrumb: "Amendment",
  },
  {
    path: "/Admin/Amendment/Edit",
    element: "AmendmentEditor",
    roleId: 1,
    breadcrumb: "Edit",
  },
  {
    path: "/Admin/Termination",
    element: "TerminationTemplate",
    roleId: 1,
    breadcrumb: "Termination",
  },
  {
    path: "/Admin/Termination/Edit",
    element: "TerminationEditor",
    roleId: 1,
    breadcrumb: "Edit",
  },
  {
    path: "/Admin/departments",
    element: "Departments",
    roleId: 1,
    breadcrumb: "Departments",
  },
  {
    path: "/Admin/location",
    element: "Location",
    roleId: 1,
    breadcrumb: "Groups",
  },
  {
    path: "/Admin/masterLibrary",
    element: "MasterLibrary",
    roleId: 1,
    breadcrumb: "Master Library",
  },
  {
    path: "/Admin/roleManagement",
    element: "Rolemanegments",
    roleId: 1,
    breadcrumb: "Role Management",
  },
  {
    path: "/Admin/userManagement",
    element: "UserManagement",
    roleId: 1,
    breadcrumb: "User Management",
  },
  {
    path: "/Admin/Esignature",
    element: "EsignatureIntegration",
    roleId: 1,
    breadcrumb: "Signature Integration",
  },
  {
    path: "/Admin/Integrations",
    element: "ManageIntegrations",
    roleId: 1,
    breadcrumb: "Manage Integrations",
  },
  {
    path: "/Admin/Integrations/DocuSignIntergration",
    element: "DocuSignIntergration",
    roleId: 1,
    breadcrumb: "DocuSign Integration",
  },
  {
    path: "/Admin/Integrations/AdobeSignIntergration",
    element: "AdobeSignIntergration",
    roleId: 1,
    breadcrumb: "Adobe Integration",
  },
  {
    path: "/Admin/Integrations/SalesForcesIntegration",
    element: "SalesForcesIntegration",
    roleId: 1,
    breadcrumb: "SalesForces Integration",
  },
  {
    path: "/Admin/Integrations/emudhraIntegration",
    element: "EmudhraIntegration",
    roleId: 1,
    breadcrumb: "Emudhra Integration",
  },
  {
    path: "/Admin/Integrations/FreshSaleSuitsIntegration",
    element: "FreshSaleSuitsIntegration",
    roleId: 1,
    breadcrumb: "FreshSaleSuits Integration",
  },
  {
    path: "/Admin/Integrations/FreshTeamIntegration",
    element: "FreshTeamIntegration",
    roleId: 1,
    breadcrumb: "FreshTeam Integration",
  },
  {
    path: "/Admin/Integrations/PanDocIntegration",
    element: "PanDocIntegration",
    roleId: 1,
    breadcrumb: "PanDoc Integration",
  },
  {
    path: "/Admin/Integrations/WorkDayIntegration",
    element: "WorkDayIntegration",
    roleId: 1,
    breadcrumb: "WorkDay Integration",
  },
  {
    path: "/Admin/Integrations/FreshworkIntegration",
    element: "FreshworkIntegration",
    roleId: 1,
    breadcrumb: "Freshwork Integration",
  },
  {
    path: "/Admin/MasterLibrary/Table",
    element: "OldChildTable",
    roleId: 1,
    breadcrumb: "Add Table",
  },
  {
    path: "/reviewer/submit",
    element: "SubmitReviewe",
    roleId: 2,
    breadcrumb: "Submit",
  },
  {
    path: "approvals/Submit",
    element: "Submit",
    roleId: 3,
    breadcrumb: "Submit",
  },
  {
    path: "/Contracts/View",
    element: "DynamicFormForContract",
    roleId: 5,
    breadcrumb: "Edit",
  },
  {
    path: "/Request/View",
    element: "DynamicFormForRequest",
    roleId: 4,
    breadcrumb: "Edit",
  },
  {
    path: "/Admin/Notification",
    element: "Notifications",
    roleId: 1,
    breadcrumb: "Notifications",
  },
  {
    path: "/Admin/Notification/Edit",
    element: "NotificationEditor",
    roleId: 1,
    breadcrumb: "Edit",
  },
  {
    path: "/Admin/contractTemplates/editor/NotificationSettings",
    element: "NotificationSettings",
    roleId: 1,
    breadcrumb: "NotificationSettings",
  },
  {
    path: "/Admin/Notification",
    element: "Notifications",
    roleId: 1,
    breadcrumb: "Notifications",
  },
  {
    path: "/Admin/CompanySettings",
    element: "CompanySettings",
    roleId: 1,
    breadcrumb: "CompanySettings",
  },
  {
    path: "/Admin/Notification/Edit",
    element: "NotificationEditor",
    roleId: 1,
    breadcrumb: "Edit",
  },
  {
    path: "/Admin/userManagement/UserForm",
    element: "UserForm",
    roleId: 1,
    breadcrumb: "UserForm",
  },

  //{ path: "/negotiation", element : "Negotiation", roleId : -1, breadcrumb: "Negotiation" },
]; //This could be from API in future i con tag required for making the path visible in the sidebar

const getValue = (value) =>
  typeof value === "string" ? value.toUpperCase() : value;

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 */
function filterPlainArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array?.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores an empty filter
      if (!filters[key].length) return true;
      return filters[key].find(
        (filter) => getValue(filter) === getValue(item[key])
      );
    });
  });
}

export default function App() {
  const [testVar, setTestVar] = useState("test");
  const [rolesArrayFilter, setRolesArrayFilter] = useState([]);
  const [routesList, setRoutesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleIdle = () => {
    setShowModal(true); //show modal
    setRemainingTime(30); //set 15 seconds as time remaining
  };

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 15 });

  useEffect(() => {
    let interval;

    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
        );
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      // alert("Time out!");
      setShowModal(false);
      setLoggedIn(false);
      logout();
    }
  }, [remainingTime, showModal]);

  useEffect(() => {
    let roles = [];
    // roles.push(0);
    const role = sessionStorage.getItem("role")?.split(",");
    if (role?.length > 0) {
      role.forEach((role) => {
        roles.push(parseInt(role, 10));
      });
      // setShowModal(false);
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    setRolesArrayFilter(roles);

    setRoutesList(filterPlainArray(routingpaths, { roleId: roles }));
  }, [testVar]);

  useEffect(() => {
    if (rolesArrayFilter.length <= 0) {
      let roles = [];
      // roles.push(0);
      const role = sessionStorage.getItem("role")?.split(",");
      if (role?.length > 0) {
        role.forEach((role) => {
          roles.push(parseInt(role, 10));
        });
        // setShowModal(false);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      setRolesArrayFilter(roles);

      setRoutesList(filterPlainArray(routingpaths, { roleId: roles }));
    }
  }, []);

  const handleLogOut = () => {
    setLoggedIn(false);
    setShowModal(false);
    logout();
  };

  const handleStayLoggedIn = async () => {
    await Relogin();
    setShowModal(false);
  };
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <GlobalDndContext>
      {isIdle && showModal && loggedIn && (
        <div className="session-modal">
          <div className="session-modal-content">
            <div className="header-modal">
              <h5 className="header-title">Idle Timeout Warning</h5>
            </div>
            <p className="session-p">
              You are about to be logged out due to inactivity.
            </p>
            <br />
            <p className="session-p">
              Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
            </p>
            <br />
            <div className="footer-modal">
              <button className="close-btn" onClick={handleLogOut}>
                Logout
              </button>
              <button className="sessionModal-btn" onClick={handleStayLoggedIn}>
                Stay Logged In
              </button>
            </div>
          </div>
        </div>
      )}
      <React.Suspense>
        <RouteContext.Provider
          value={filterPlainArray(routingpaths, { roleId: rolesArrayFilter })}
        >
          <Routes>
            <Route
              path="/"
              element={<LoginNew testVar={testVar} setTestVar={setTestVar} />}
            />
            <Route
              element={
                <PrivateRoute
                  roles={rolesArrayFilter}
                  routes={filterPlainArray(routingpaths, {
                    roleId: rolesArrayFilter,
                  })}
                />
              }
            >
              <Route element={<MainPage />}>
                {/* <Route path="/Dashboard" element={<Dashboard />} /> */}
                {filterPlainArray(routingpaths, {
                  roleId: rolesArrayFilter,
                })?.map((route, index) => {
                  return (
                    <>
                      <Route
                        path={route?.path}
                        element={componentsregistry[route?.element]}
                      />
                    </>
                  );
                })}
              </Route>
            </Route>

            <Route path="*" element={<PageNotFound />} />
            <Route path="/unauthorized" element={<UnAuthorized />} />
            <Route
              name="negotiation"
              path="/negotiation"
              element={<Negotiation />}
            ></Route>
            <Route
              name="forgotpassword"
              path="/ForgotPwd"
              element={<ForgotPassword />}
            ></Route>
          </Routes>
        </RouteContext.Provider>
      </React.Suspense>
    </GlobalDndContext>
  );
}
