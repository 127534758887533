import "./editor.css";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../webServiceCalls/webServiceCall";

import PlateEditor from "../../PlateEditor/PlateEditor";
import { RecoilRoot } from "recoil";
import Loading from "../../Loading/Loading";
import { useLocation } from "react-router";
import { generateUUIDBasicVar } from "../../config/utils/SlateUtilityFunctions";
import TabForParties from "../variables/TabForParties";
import TabForVariables from "../variables/TabForVariables";
import "./editor.css";

export default function NotificationSettings(props) {
  const [value, setValue] = useState(0);
  const [mailTemplateJsonData, setMailTemplateJsonData] = useState(null);
  const [partiesJson, setPartiesJson] = useState(null);
  const [basicDetailsJson, setBasicDetailsJson] = useState(null);
  const [conditions, setConditions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [notiTempName, setNotiTempName] = useState("");
  const [isMailChecked, setIsMailChecked] = useState(false);
  const [isMsgChecked, setIsMsgChecked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [msgContent, setMsgContent] = useState("");
  const [msgFocus, setMsgFocus] = useState(0);
  const location = useLocation();
  const [globalTemplateNotificationId, setGlobalTemplateNotificationId] =
    useState(null);
  const [emailSubject, setEmailSubject] = useState("");
  const [nonTemplateVariableArray, setNonTemplateVariableArray] =
    useState(null);
  const [contractVariableJsonData, setContractVariableJsonData] =
    useState(null);
  const [notificationId, setNotificationId] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [triggerType, setTriggerType] = useState(null);
  let newBasicDetailsJson = {
    ["startDate"]: {
      uuid: generateUUIDBasicVar("_B"),
      valid: false,
      value: "",
      format: "MM-dd-yyyy",
      touched: true,
      subGroup: "",
      isRequest: true,
      sequenceNo: 1,
      validation: {
        error: "",
        required: false,
      },
      elementType: "date",
      parentGroup: "basicDetails",
      elementConfig: {
        id: "startdate",
        type: "date",
        placeholder: "Enter the Start Date",
      },
    },
  };
  newBasicDetailsJson = {
    ...newBasicDetailsJson,
    ["expiryDate"]: {
      uuid: generateUUIDBasicVar("_B", newBasicDetailsJson),
      valid: false,
      value: "",
      format: "MM-dd-yyyy",
      touched: true,
      subGroup: "",
      sequenceNo: 2,
      isRequest: true,
      validation: {
        error: "",
        required: false,
      },
      elementType: "date",
      parentGroup: "basicDetails",
      elementConfig: {
        id: "expirydate",
        type: "date",
        placeholder: "Enter the Expiry Date",
      },
    },
  };
  newBasicDetailsJson = {
    ...newBasicDetailsJson,
    ["currencyValue"]: {
      uuid: generateUUIDBasicVar("_B", newBasicDetailsJson),
      valid: false,
      value: "0",
      touched: false,
      subGroup: "",
      isRequest: true,
      validation: {
        error: "",
        required: true,
        isNumeric: false,
        maxLength: null,
        minLength: null,
      },
      currencyType: "$",
      elementType: "currency",
      parentGroup: "basicDetails",
      elementConfig: {
        id: "currencyValue",
        type: "text",
        placeholder: "Enter Currency Value",
      },
      sequenceNo: 3,
    },
  };

  const plateRef = React.useRef();
  const plateRefMsg = React.useRef();

  const handleTabChange = (newValue) => {
    setIsLoading(true);
    if (plateRef.current !== null) {
      let serializedData = plateRef.current.serailizedData();
      const conditionsLoc = plateRef.current.getConditions();

      setMailTemplateJsonData(serializedData);
      //setConditions(conditionsLoc);
    }
    setValue(newValue);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (props) {
        setGlobalTemplateNotificationId(props.id);
        if (props.id !== null) {
          await setTemplateData(props.id);
        } else if (props.notificationId !== null) {
          await setExistingTemplateData(props.notificationId);
        }
        setBasicDetailsJson({ basicDetails: { ...newBasicDetailsJson } });
        setNonTemplateVariableArray(props.nonTemplateVariableArray);
        setContractVariableJsonData(props.contractVariableJsonData);
        setNotificationId(props.notificationId);
        setTemplateId(props.templateId);
      }
    })();
  }, []);

  const setTemplateData = async (id) => {
    setIsLoading(true);
    let dataResult;
    let result = getCrudApi("globalTemplateNotification/" + id, {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      if (dataResult?.emailTemplate?.htmlTemplate) {
        const deserializedMail = JSON.parse(
          dataResult?.emailTemplate?.htmlTemplate
        );
        setMailTemplateJsonData(deserializedMail);
        setEmailSubject(dataResult?.emailTemplate?.subject);
      }
      if (dataResult?.smsTemplate?.htmlTemplate) {
        const deserializedMsg = JSON.parse(
          dataResult?.smsTemplate?.htmlTemplate
        );
        setMsgContent(deserializedMsg);
      }
      setTriggerType(dataResult.triggerType);
    }
    setIsLoading(false);
  };
  const setExistingTemplateData = async (id) => {
    setIsLoading(true);
    let dataResult;
    let result = getCrudApi("contractNotificationTemplateSettings/" + id, {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      if (dataResult?.contractEmailTemplate?.htmlTemplate) {
        const deserializedMail = JSON.parse(
          dataResult?.contractEmailTemplate?.htmlTemplate
        );
        setMailTemplateJsonData(deserializedMail);
        setEmailSubject(dataResult?.contractEmailTemplate?.subject);
      }
      if (dataResult?.contractSmsTemplate?.htmlTemplate) {
        const deserializedMsg = JSON.parse(
          dataResult?.contractSmsTemplate?.htmlTemplate
        );
        setMsgContent(deserializedMsg);
      }
      setTriggerType(dataResult.triggerType);
      setIsMailChecked(dataResult.contractEmailStatus === 1 ? true : false);
      setIsMsgChecked(dataResult.contractSmsStatus === 1 ? true : false);
      setIsActive(dataResult.isSctive === 1 ? true : false);
    }
    setIsLoading(false);
  };
  const displayUpdatedMessage = () => {
    toast.success("Updated Successfully!", {
      className: "toaststyle",
    });
  };
  const displayAddedMessage = () => {
    toast.success("Added Successfully!", {
      className: "toaststyle",
    });
  };

  const HandleSaveTemplate = async () => {
    setIsLoading(true);
    const serializedData =
      plateRef.current !== null
        ? plateRef.current.serailizedData()
        : mailTemplateJsonData;
    // const conditionsLoc =
    //   plateRef.current !== null
    //     ? plateRef.current.getConditions()
    //     : conditions;
    setMailTemplateJsonData(serializedData);
    //setConditions(conditionsLoc);
    const emailTemplate = {
      htmlTemplate: JSON.stringify(serializedData),
      subject: emailSubject,
    };
    const smsTemplate = {
      htmlTemplate: JSON.stringify(msgContent),
    };
    let globalTemplate = {
      contractEmailTemplate: emailTemplate,
      contractSmsTemplate: smsTemplate,
      contractEmailStatus: isMailChecked ? 1 : 2,
      contractSmsStatus: isMsgChecked ? 1 : 2,
      contractTemplateId: templateId,
      isSctive: isActive ? 1 : 2,
    };
    let dataResult;
    if (notificationId !== null) {
      let result = putCrudApi(
        "contractNotificationTemplateSettings/" + notificationId,
        globalTemplate
      );

      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        displayUpdatedMessage();
      }
    } else {
      globalTemplate.globalTemplateNotificationId =
        globalTemplateNotificationId;
      globalTemplate.triggerType = triggerType;

      let result = postCrudApi(
        "contractNotificationTemplateSettings/contractNotificationTemplateSettings",
        globalTemplate
      );

      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult) {
        setNotificationId(dataResult.contractNotificationTemplateSettingsId);
        displayAddedMessage();
      }
    }
    setIsLoading(false);
    props.setCloseNotification();
  };
  const deleteVariable = (editor, uuid) => {};
  const variableClick = (varID, variable, dataPath) => {
    let textBeforeCursorPosition = msgContent.substring(0, msgFocus);
    let textAfterCursorPosition = msgContent.substring(msgFocus);
    let updatedMsgContent =
      textBeforeCursorPosition +
      " {{" +
      dataPath +
      "." +
      variable +
      ".value" +
      "}} " +
      textAfterCursorPosition;
    setMsgContent(updatedMsgContent);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div>
            <div className="headerHolder">
              <div className="Tab-nav-editor ">
                <div
                  className={value === 0 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(0)}
                >
                  Email
                </div>
                <div
                  className={value === 1 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(1)}
                >
                  Message
                </div>
              </div>
              <div className="notification-settings">
                <div className="formrow">
                  <div className="form-email-label ">
                    <label className="setting-label">Enable Email</label>
                  </div>
                  <div className="form-columns ">
                    <input
                      className="EditornotificationCheckBox"
                      type="checkbox"
                      id="manualcheckbox"
                      checked={isMailChecked}
                      onChange={(e) => {
                        setIsMailChecked(!isMailChecked);
                      }}
                    />
                  </div>
                </div>
                <div className="formrow">
                  <div>
                    <label className="setting-label">Enable Message</label>
                  </div>
                  <div className="form-columns ">
                    <input
                      className="EditornotificationCheckBox"
                      type="checkbox"
                      id="manualcheckbox"
                      checked={isMsgChecked}
                      onChange={(e) => {
                        setIsMsgChecked(!isMsgChecked);
                      }}
                    />
                  </div>
                </div>
                <div className="formrow">
                  <div>
                    <label className="setting-label">Active</label>
                  </div>
                  <div className="form-columns ">
                    <input
                      className="EditornotificationCheckBox"
                      type="checkbox"
                      id="manualcheckbox"
                      checked={isActive}
                      onChange={(e) => {
                        setIsActive(!isActive);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="formHeader">
                <div className="formheader85">
                  <label className="editor-header-label">
                    {" "}
                    {notiTempName !== undefined ? notiTempName : "NAME"}
                  </label>
                </div>
                <div className="formheader20">
                  <button
                    className="editor-contracts-btn"
                    onClick={() => HandleSaveTemplate()}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
            {value === 0 ? (
              <div>
                <div className="formRow-label">
                  <div>
                    <label className=" setting-label ">Subject</label>
                  </div>
                  <div className="form-columns ">
                    <input
                      type="text"
                      className="text-form-editor"
                      value={emailSubject}
                      onChange={(e) => {
                        setEmailSubject(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <RecoilRoot>
                  <PlateEditor
                    id={"notificationEmailTemplateEditor"}
                    ref={plateRef}
                    parentGroup="Contract"
                    canAddComments={false}
                    isNegotiationDisabled={true}
                    isVariableDisabled={false}
                    variableAddDisable={true}
                    nonTempVarAddDisable={true}
                    isPartiesDisabled={false}
                    isConditionDisabled={true}
                    isClauseDisabled={true}
                    isConditionToggleDisabled={true}
                    isCommentToggleDisabled={true}
                    nonTemplateVariableArray={nonTemplateVariableArray}
                    finalJSON={
                      contractVariableJsonData ? contractVariableJsonData : {}
                    }
                    partiesJson={partiesJson ? partiesJson : {}}
                    setPartiesJSON={(partiesJson) => {
                      setPartiesJson(partiesJson);
                    }}
                    disablePartyConfigure={true}
                    basicDetailsJson={basicDetailsJson ? basicDetailsJson : {}}
                    currentUser="user"
                    isEditable={true}
                    conditions={conditions}
                    setConditions={(val) => {
                      setConditions(val);
                    }}
                    serializeCall={"serializeTemplate"}
                    allowNontemp={true}
                    allowSignature={false}
                    dataPath="templateVariables"
                    conditiondataPath="ContractConditions"
                    tabForVarId="templateVarTab"
                    contractTemplateJsonData={
                      mailTemplateJsonData ? mailTemplateJsonData : null
                    }
                    setContractTemplateJsonData={(val) => {
                      setMailTemplateJsonData(val);
                    }}
                    disableVariableEdit={true}
                  />
                </RecoilRoot>
              </div>
            ) : value === 1 ? (
              <div>
                <div className="formRow-label">
                  <div>
                    <label
                      className=" setting-label "
                      // style={{ marginTop: "12px" }}
                    >
                      Message Content
                    </label>
                  </div>

                  <div className="form-columns ">
                    <textarea
                      type="text"
                      placeholder="Message"
                      className="text-form-editor"
                      value={msgContent}
                      onChange={(e) => {
                        setMsgContent(e.target.value);
                        setMsgFocus(e.target.selectionStart);
                      }}
                      onClick={(e) => {
                        setMsgFocus(e.target.selectionStart);
                      }}
                    />
                  </div>
                </div>
                <div className="notifications-messages">
                  <div className="parties-style">
                    <TabForParties
                      parentGroup="Contract"
                      //finalJSON={props.finalJSON}
                      setPartiesJSON={(partiesJson) => {
                        setPartiesJson(partiesJson);
                      }}
                      setVarsToPopulate={(varsToPoplt) => {
                        //setVarsToPopulate(varsToPoplt);
                      }}
                      removeVariable={(uuid) => {
                        deleteVariable(null, uuid);
                      }}
                      variableClick={(varID, variable, dataPath) => {
                        variableClick(varID, variable, dataPath);
                      }}
                      //New Params
                      partiesJson={partiesJson}
                      disablePartyConfigure={true}
                      basicDetailsJson={basicDetailsJson}
                      masterLibrary={[]}
                      dataPath={"templateVariables"}
                      // checkIfNodeExists={checkIfNodeExists}
                    />
                  </div>
                  <div className="variable-Style" id={"templateVarTab"}>
                    <TabForVariables
                      parentGroup={"Contract"}
                      finalJSON={
                        contractVariableJsonData ? contractVariableJsonData : {}
                      }
                      setFinalJSON={(finJson) => {}}
                      setVarsToPopulate={(varsToPoplt) => {}}
                      nonTemplateVariableArray={nonTemplateVariableArray}
                      setNonTemplateVariableArray={(nonTempVarArr) => {}}
                      allSignatures={null}
                      setSignatureVariables={(vars) => {}}
                      updateSignature={(sigID) => {}}
                      // getOperationOptions={getOperationOptions}
                      masterLibrary={null}
                      // insertPlaceholder={insertPlaceholder}
                      // selectedVariable={selectedVariable}
                      removeVariable={(uuid) => {
                        deleteVariable(null, uuid);
                      }}
                      variableClick={(varID, variable, dataPath) => {
                        variableClick(varID, variable, "templateVariables");
                      }}
                      allowNontemp={false}
                      allowSignature={false}
                      dataPath={"templateVariables"}
                      disableVariableEdit={true}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
