import React, { useState, useEffect } from "react";
import Tab from "@material-ui/core/Tab";
import { Tabs, MuiThemeProvider, createTheme } from "@material-ui/core";
import { Link } from "react-router-dom";
import OptimisedMUIDataTable from "../manageContracts/OptimisedMUIDataTable";
import { getDecodedToken, validateToken } from "../../Helpers/validateToken";
import { getCrudApi } from "../../webServiceCalls/webServiceCall";
import "./Approveme.css";
import Loading from "../../Loading/Loading";
const Approvals = (props) => {
  const [requestTableData, setTableDataRequests] = useState([]);
  const [contractTableData, setTableDataContract] = useState([]);
  const [tabvalue, setValue] = React.useState(0);
  const [page, setPage] = useState(0);
  const [RequestApprovalcnt, setRequestApprovalCnt] = useState(0);
  const [RequestSubmitcnt, setRequestRejectCnt] = useState(0);
  const [Requestpendingcnt, setRequestpendingcnt] = useState(0);
  const [ContractApprovalcnt, setContractApprovalCnt] = useState(0);
  const [ContractSubmitcnt, setContractRejectCnt] = useState(0);
  const [Contractpendingcnt, setContractpendingcnt] = useState(0);
  const [requestTableDataforcnt, setRequestTableDataforcnt] = useState([]);
  const [contractTableDataforcnt, setContractTableDataforcnt] = useState([]);
  const [AllCntContract, setAllCntContract] = useState(0);
  const [AllCntRequest, setAllCntRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const handleTabs = (val) => {
    setValue(val);
  };

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "VIEW",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                style={{ textDecoration: "none" }}
                to="/approvals/Submit"
                state={{
                  contract_id: tableMeta.rowData[1],
                  contract_request: tabvalue,
                }}
              >
                <button onClick={() => onApprovalEdit(tableMeta, value)}>
                  <i className="fa fa-eye custom-iconformodal" />
                </button>
              </Link>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);
    columnsArray.push({
      name: "contractId",
      label: "Contract ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractRequestFlag",
      label: "Contract Request Flag",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractRequest",
      label: "contractRequest",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractTemplateVersionId",
      label: "contractTemplateVersionId",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractName",
      label: "Contract Name",
    });
    columnsArray.push({
      name: "catogeryType",
      label: "Catogery Type",
    });
    columnsArray.push({
      name: "contractType",
      label: "Contract Type",
    });
    columnsArray.push({
      name: "templateType",
      label: "Template Type",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "Contract Status",
      options: {
        customBodyRender: (dataIndex) => {
          let color;
          const setStatus = (dataIndex) => {
            switch (dataIndex) {
              case "Drafted":
                color = "#1D907Fff";
                break;
              case "Submitted":
                color = "#3F66B0ff";
                break;
              case "Approved":
                color = "#4F96D5ff";
                break;
              case "Reopened":
                color = "#FBCD08ff";
                break;
              case "Termination InProgress":
                color = "#8FC742ff";
                break;
              case "Amended":
                color = "#F05623ff";

                break;
              case "Negotiation":
                color = "#9F4098ff";
                break;
              case "Executed":
                color = "#F7901Eff";
                break;
              case "Terminated":
                color = "#76ccba";

                break;
            }
            return color;
          };
          const state = dataIndex;
          return (
            <>
              <p
                className={"table-contract-request-status"}
                style={{ background: setStatus(state) }}
              >
                {state}
              </p>
            </>
          );
        },
      },
    });

    return columnsArray;
  };

  useEffect(() => {
    //TODO - api call to get initial request info table
    setApproberData();
    const interval = setInterval(
      setApproberData,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, []);
  const setApproberData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let user = getDecodedToken();
    let result = getCrudApi("tempApproval/contractApproval/" + user.userId, {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      setTableDataContract(dataResult);
      setContractTableDataforcnt(dataResult);
    }
    result = getCrudApi("tempApproval/requestApproval/" + user.userId, {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      setTableDataRequests(dataResult);
      setRequestTableDataforcnt(dataResult);
    }
    setIsLoading(false);
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };
  const onApprovalEdit = (tableMeta, value) => {};

  useEffect(() => {
    let Approval = requestTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "approved";
    });
    setRequestApprovalCnt(Approval.length);
    Approval = contractTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "approved";
    });
    setContractApprovalCnt(Approval.length);

    let Drafted = requestTableDataforcnt.filter(function (iterator) {
      if (iterator)
        return iterator.contractStatus.toLowerCase() === "submitted";
    });
    setRequestpendingcnt(Drafted.length);
    Drafted = contractTableDataforcnt.filter(function (iterator) {
      if (iterator)
        return iterator.contractStatus.toLowerCase() === "submitted";
    });
    setContractpendingcnt(Drafted.length);

    let submitted = requestTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "reject";
    });
    setRequestRejectCnt(submitted.length);
    submitted = contractTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "reject";
    });
    setContractRejectCnt(submitted.length);

    setAllCntContract(contractTableDataforcnt.length);
    setAllCntRequest(requestTableDataforcnt.length);
  });

  const handleapprovalRequest = (e) => {
    let Approval = requestTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "approved";
    });

    setTableDataRequests(Approval);
  };

  const handleRejectRequest = (e) => {
    let submitted = requestTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "reject";
    });
    setTableDataRequests(submitted);
  };

  const handle_drafted_request = (e) => {
    let Drafted = requestTableDataforcnt.filter(function (iterator) {
      if (iterator)
        return iterator.contractStatus.toLowerCase() === "submitted";
    });
    setTableDataRequests(Drafted);
  };
  const handleapprovalContract = (e) => {
    let Approval = contractTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "approved";
    });

    setTableDataContract(Approval);
  };

  const handleRejectContract = (e) => {
    let submitted = contractTableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.contractStatus.toLowerCase() === "reject";
    });
    setTableDataContract(submitted);
  };
  const handle_drafted_contract = (e) => {
    let Drafted = contractTableDataforcnt.filter(function (iterator) {
      if (iterator)
        return iterator.contractStatus.toLowerCase() === "submitted";
    });
    setTableDataContract(Drafted);
  };
  const handle_all_Request = (e) => {
    setTableDataRequests(requestTableDataforcnt);
  };
  const handle_all_Contract = (e) => {
    setTableDataContract(contractTableDataforcnt);
  };

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          {tabvalue === 0 ? (
            <>
              <div className="approval-status-div">
                <button
                  type="button"
                  className="status-btn submitted "
                  onClick={handle_all_Request}
                >
                  <div className="status-text">All </div>
                  <div className="count-div">
                    <label className="all-approve-label">
                      {" "}
                      {AllCntRequest}
                    </label>
                  </div>
                </button>

                <button
                  type="button"
                  className="status-btn drafted"
                  onClick={handle_drafted_request}
                >
                  <div className="status-text">Pending Approval</div>
                  <div className="count-div">
                    <label className="pending-approve-label">
                      {Requestpendingcnt}
                    </label>
                  </div>
                </button>
              </div>
              <div className="Tab-nav-approval">
                <div
                  className={tabvalue === 0 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabs(0)}
                >
                  Requests
                </div>
                <div
                  className={tabvalue === 1 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabs(1)}
                >
                  Contracts
                </div>
              </div>
              <div className="Table-body">
                <div className="table-scroll">
                  <MuiThemeProvider theme={customTheme}>
                    <OptimisedMUIDataTable
                      title={"Manage Requests"}
                      data={requestTableData}
                      columns={generateColumns()}
                      options={options}
                    />
                  </MuiThemeProvider>
                </div>
              </div>
            </>
          ) : tabvalue === 1 ? (
            <>
              <div className="approval-status-div">
                <button
                  type="button"
                  className="status-btn submitted "
                  onClick={handle_all_Contract}
                >
                  <div className="status-text">All</div>
                  <div className="count-div">
                    <label className="all-approve-label">
                      {" "}
                      {AllCntContract}
                    </label>
                  </div>
                </button>

                <button
                  type="button"
                  className="status-btn drafted"
                  onClick={{ handle_drafted_contract }}
                >
                  <div className="status-text">Pending Approval</div>
                  <div className="count-div">
                    <label className="pending-approve-label">
                      {Contractpendingcnt}
                    </label>
                  </div>
                </button>
              </div>
              <div className="Tab-nav-approval">
                <div
                  className={tabvalue === 0 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabs(0)}
                >
                  Requests
                </div>
                <div
                  className={tabvalue === 1 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabs(1)}
                >
                  Contracts
                </div>
              </div>
              <>
                <div>
                  <div className="Table-body">
                    <div className=" table-scroll ">
                      <MuiThemeProvider theme={customTheme}>
                        <OptimisedMUIDataTable
                          title={"Manage Contracts"}
                          data={contractTableData}
                          columns={generateColumns()}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>
                  </div>
                </div>
              </>
            </>
          ) : null}
          {/* </TabPanel> */}
          <TabPanel value={tabvalue} index={1}></TabPanel>
        </div>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default Approvals;
