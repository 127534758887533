import "./CommentThreadPopover.css";

import { ReactEditor } from "slate-react";
import {
  activeCommentThreadIDAtom,
  commentThreadsState,
} from "../utils/CommentState";
import { useCallback, useState } from "react";
import { useRecoilStateLoadable, useSetRecoilState } from "recoil";

import CommentRow from "./CommentRow";
import NodePopover from "./NodePopover";
import { Text } from "slate";
import { getCommentThreadsOnTextNode } from "../utils/EditorCommentUtils";
import { getFirstTextNodeAtSelection } from "../utils/EditorUtils";
import Loading from "../../Loading/Loading";
import moment from "moment";

export default function CommentThreadPopover({
  editorOffsets,
  selection,
  threadID,
  ...props
}) {
  const editor = props.getEditorRef();
  //const editor = usePlateEditorRef();
  const textNode = getFirstTextNodeAtSelection(editor, selection);
  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);

  const [threadDataLoadable, setCommentThreadData] = useRecoilStateLoadable(
    commentThreadsState(threadID)
  );

  const [commentText, setCommentText] = useState("");

  const onClick = useCallback(() => {
    setCommentThreadData((threadData) => ({
      ...threadData,
      comments: [
        ...(threadData && threadData.comments ? threadData.comments : []),
        {
          text: commentText,
          author: sessionStorage.getItem("userNAme"),
          creationTime: moment.utc(new Date()).toString(),
        },
      ],
    }));

    setCommentText("");
  }, [commentText, setCommentThreadData]);

  const onToggleStatus = useCallback(() => {
    if (threadDataLoadable.contents) {
      const currentStatus = threadDataLoadable.contents.status;
      setCommentThreadData((threadData) => ({
        ...threadData,
        status: currentStatus === "open" ? "resolved" : "open",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setCommentThreadData,
    threadDataLoadable.contents ? threadDataLoadable.contents.status : null,
  ]);

  const onCommentTextChange = useCallback(
    (event) => setCommentText(event.target.value),
    [setCommentText]
  );

  const onClickOutside = useCallback(
    (event) => {
      const slateDOMNode = event.target.hasAttribute("data-slate-node")
        ? event.target
        : event.target.closest(`[data-slate-node]`);

      // The click event was somewhere outside the Slate hierarchy
      if (slateDOMNode == null) {
        setActiveCommentThreadID(null);
        return;
      }

      const slateNode = ReactEditor.toSlateNode(editor, slateDOMNode);

      // Click is on another commented text node => do nothing.
      if (
        Text.isText(slateNode) &&
        getCommentThreadsOnTextNode(slateNode).size > 0
      ) {
        return;
      }

      setActiveCommentThreadID(null);
    },
    [editor, setActiveCommentThreadID]
  );

  // useEffect(() => {
  //   //inputRef.current?.focus();
  //   return () => {
  //     setActiveCommentThreadID(null);
  //   };
  // }, [setActiveCommentThreadID]);

  const hasThreadData = threadDataLoadable.state === "hasValue";
  const threadData = threadDataLoadable.contents;

  return (
    <NodePopover
      editorOffsets={editorOffsets}
      getEditorRef={props.getEditorRef}
      isBodyFullWidth={true}
      node={textNode}
      className="comment-thread-popover"
      setActiveCommentID={(val) => {
        props.setActiveCommentID(val);
      }}
      header={
        <Header
          status={threadData && threadData.status ? threadData.status : null}
          shouldAllowStatusChange={
            threadData && hasThreadData && threadData.comments.length > 0
          }
          onToggleStatus={onToggleStatus}
        />
      }
      onClickOutside={onClickOutside}
    >
      {hasThreadData ? (
        <>
          <div className={"comment-list"}>
            {threadData && threadData.comments
              ? threadData.comments.map((comment, index) => (
                  <CommentRow key={index} comment={comment} />
                ))
              : null}
          </div>
          {props.canComment === false ? null : (
            <div className="comment-input-wrapper">
              <input
                className="comments-inputBox"
                // bsPrefix={"comment-input form-control"}
                placeholder={"Enter your comment here"}
                type="text"
                value={commentText}
                onChange={onCommentTextChange}
              />
              <button
                className="comment-button"
                disabled={commentText.length === 0}
                onClick={onClick}
              >
                <i className="far fa-comment-alt commentFont" />
              </button>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </NodePopover>
  );
}

function Header({ onToggleStatus, shouldAllowStatusChange, status }) {
  return (
    <div className="comment-thread-popover-header">
      {shouldAllowStatusChange && status != null ? (
        <button
          className="resolveButton"
          onClick={onToggleStatus}
          disabled={true}
        >
          {status === "open" ? "Resolve" : "Re-Open"}
        </button>
      ) : null}
    </div>
  );
}
