const selectOptionsMap = (data, val, label) => {
  let options = [];
  if (data)
    data?.map((obj) => {
      options.push({
        value: obj[val],
        label: obj[label],
      });
    });
  return options;
};
export default selectOptionsMap;
