/* eslint-disable default-case */
import "./editor.css";
import React, {
  Component,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SyfedTreeSelect from "syfed-tree-select";
import { setcontractTemplateData } from "../../redux/Reducers/contractTemplateReducer";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ReactSelect from "react-select";
import {
  postCrudApi,
  getCrudApi,
  putCrudApi,
  deleteCrudApi,
} from "../../webServiceCalls/webServiceCall";
import { validateToken } from "../../Helpers/validateToken";

import { connect } from "react-redux";
import { detailedDiff } from "deep-object-diff";
import { validateVariable } from "../../Helpers/validation";
import PlateEditor from "../../PlateEditor/PlateEditor";
import { RecoilRoot, useRecoilValue } from "recoil";
import Loading from "../../Loading/Loading";
import { useLocation } from "react-router";
import { generateUUIDBasicVar } from "../../config/utils/SlateUtilityFunctions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import OptimisedMUIDataTable from "../manageContracts/OptimisedMUIDataTable";
import NotificationSettings from "./notificationSettings";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import selectOptionsMapName from "../../Helpers/selectOptionsFirstNameLastName";
const CustomCheckbox = (props) => {
  let newProps = Object.assign({}, props);
  newProps.color =
    props["data-description"] === "row-select" ? "secondary" : "primary";

  if (props["data-description"] === "row-select") {
    return <Radio {...newProps} />;
  } else {
    return <Checkbox {...newProps} />;
  }
};
function EditorConvertToHTML(props) {
  const [signatureVariables, setSignatureVariables] = useState([]);
  const [isMasterLoaded, setIsMasterLoaded] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const [arrayofbuttons, setArrayofbuttons] = useState([]);
  const [json, setJson] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [selectedVariable, setSelectedVariable] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [paraCondition, setParaCondition] = useState("");
  const [paraVariable, setParaVariable] = useState("");
  const [paraOperation, setParaOperation] = useState("");
  const [paraValue, setParaValue] = useState("");
  const [paraSelectedText, setParaSelectedText] = useState("");
  const [enableAutoRenewal, setEnableAutoRenewal] = useState(false);
  const [getSelectionSelectedText1, setGetSelectionSelectedText1] =
    useState("");
  const [variableValuesOnClick, setVariableValuesOnClick] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowIndex, setRowIndex] = useState(-1);
  const [openParaEditor, setOpenParaEditor] = useState(false);
  const [openEditTemplate, setOpenEditTemplate] = useState(false);
  const [EditTemplateOpen, setEditTemplateOpen] = useState(false);
  const [textToBeInserted, setTextToBeInserted] = useState("");
  const [firstTime, setFirstTime] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedContractType, setSelectedContractType] = useState("");
  const [variablesToRender, setVariablesToRender] = useState([]);
  const [varJSON, setVarJSON] = useState({});
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const [CategoryTypeSelect, setCategoryTypeSelect] = useState([]);
  const [RenewalTypeSelect, setRenewalTypeSelect] = useState([]);
  const [contractTypeSelected, setContractTypeSelected] = useState(null);
  const [categoryTypeSelected, setCategoryTypeSelected] = useState(null);
  const [renewalTypeSelected, setRenewalTypeSelected] = useState(0);
  const [templateTypeSelected, setTemplateTypeSelected] = useState(9);
  const [contractTemplateJsonData, setContractTemplateJsonData] =
    useState(null);
  const [validSignature, setValidSig] = useState(true);
  const [contractVariableJsonData, setContractVariableJsonData] =
    useState(null);
  const [partiesJson, setPartiesJson] = useState(null);
  const [basicDetailsJson, setBasicDetailsJson] = useState(null);
  const [selectedtemplateData, setSelectedtemplateData] = useState(null);
  const [publish_enable, setPublish_enable] = useState(false);
  const [contractName, setContractName] = useState("");
  const [contractTemplateIdState, setContractTemplateIdState] = useState("");
  const [draftTemplate_id, setDraftTemplate_id] = useState(0);
  const [masterLibrary, setMasterLibrary] = useState([]);
  const [clauseLibrary, setClauseLibrary] = useState([]);
  const [conditions, setConditions] = useState({});
  const [approvers, setApprovers] = useState([]);
  const [ApproverRequired, setApproverRequired] = useState(false);
  const [RequestApproverRequired, setRequestApproverRequired] = useState(false);
  const [ContractApproverRequired, setContractApproverRequired] =
    useState(false);
  const [UserData, setUserData] = useState([]);
  const [UserDataReq, setUserDataReq] = useState([]);
  const [UserDataCon, setUserDataCon] = useState([]);
  const [RequestApprover, setRequestApprover] = useState([]);
  const [ContractApprover, setContractApprover] = useState([]);
  const [approversCount, setApproversCount] = useState([]);
  const [oldContractApprover, setOldContractApprover] = useState([]);
  const [oldRequestApprover, setOldRequestApprover] = useState([]);
  const [approval_type_select, setApproval_type_select] = useState(4);
  const [contract_template, setContract_template] = useState("");
  const [contract_variable, setContract_variable] = useState([]);
  const [vertical, setVertical] = useState("top");
  const [horizontal, setHorizontal] = useState("center");
  const [nonTemplateVariableArray, setNonTemplateVariableArray] = useState([]);
  const [commentJSON, setCommentJSON] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isManualChecked, setIsManualChecked] = useState(false);
  const [customDays, setCustomDays] = useState(0);
  const [enableAddNoti, setEnableAddNoti] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({
    value: 1,
    text: "Active",
  });
  const [selectedAutoRenewal, setSelectedAutoRenewal] = useState({
    value: 2,
    text: "False",
  });

  const [startDateFormat, setStartDateFormat] = useState({
    value: "MM-dd-yyyy",
    text: "MM-dd-yyyy",
  });
  const [expiryDateFormat, setExpiryDateFormat] = useState({
    value: "MM-dd-yyyy",
    text: "MM-dd-yyyy",
  });
  const [TemplateTypeSelect, setTemplateTypeSelect] = useState([
    { label: "With Template", value: 9 },
    { label: "Without Template", value: 10, disabled: true },
    { label: "Both", value: 11, disabled: true },
  ]);
  const [contractTemplateName, setContractTemplateName] = useState("");
  const [NewOrUpdate, setNewOrUpdate] = useState("");
  const [dis, setdis] = useState(true);
  const [globalNotificationData, setGlobalNotificationData] = useState([]);
  const [templateNotification, setTemplateNotification] = useState([]);
  const [addClicked, setAddClicked] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const [notiId, setNotificationId] = useState(null);
  const [globalTablPage, setGlobalTblPage] = useState(null);
  const [pageSize, setPageSize] = useState("A4");
  const triggertypes = [
    { id: 5, trigger: "Draft" },
    { id: 6, trigger: "Submitted" },
    { id: 7, trigger: "Approved" },
    { id: 8, trigger: "ReOpened" },
    { id: 11, trigger: "Termination InProgress" },
    { id: 13, trigger: "Amended" },
    { id: 14, trigger: "Negotiation" },
    { id: 15, trigger: "Negotiation Completed" },
    { id: 16, trigger: "Open" },
    { id: 17, trigger: "Close" },
    { id: 18, trigger: "Executed" },
    { id: 19, trigger: "Terminated" },
  ];

  let notiTempSelected = null;
  const validDateFormats = [
    { value: "MM-dd-yyyy", text: "MM-dd-yyyy" },
    { value: "dd-MM-yyyy", text: "dd-MM-yyyy" },
    { value: "dd-yyyy-MM", text: "dd-yyyy-MM" },
    { value: "yyyy-MM-dd", text: "yyyy-MM-dd" },
  ];
  const AutoRenewalSelect = [
    { value: 1, text: "True" },
    { value: 2, text: "False" },
  ];
  const statusSelectOpt = [
    { value: 1, text: "Active" },
    { value: 2, text: "Inactive" },
  ];
  const manualAvailableRenewal = [3, 4, 5, 6, 7, 8, 9];
  const [noOfDaysValid, setNoOfDaysValid] = useState(true);
  let newBasicDetailsJson = {
    ["startDate"]: {
      uuid: generateUUIDBasicVar("_B"),
      valid: false,
      value: "",
      format: "MM-dd-yyyy",
      touched: true,
      subGroup: "",
      isRequest: true,
      sequenceNo: 1,
      validation: {
        error: "",
        required: false,
      },
      elementType: "date",
      parentGroup: "basicDetails",
      elementConfig: {
        id: "startdate",
        type: "date",
        placeholder: "Enter the Start Date",
      },
    },
  };
  newBasicDetailsJson = {
    ...newBasicDetailsJson,
    ["expiryDate"]: {
      uuid: generateUUIDBasicVar("_B", newBasicDetailsJson),
      valid: false,
      value: "",
      format: "MM-dd-yyyy",
      touched: true,
      subGroup: "",
      sequenceNo: 2,
      isRequest: true,
      validation: {
        error: "",
        required: false,
      },
      elementType: "date",
      parentGroup: "basicDetails",
      elementConfig: {
        id: "expirydate",
        type: "date",
        placeholder: "Enter the Expiry Date",
      },
    },
  };
  newBasicDetailsJson = {
    ...newBasicDetailsJson,
    ["currencyValue"]: {
      uuid: generateUUIDBasicVar("_B", newBasicDetailsJson),
      valid: false,
      value: "0",
      touched: false,
      subGroup: "",
      isRequest: true,
      validation: {
        error: "",
        required: true,
        isNumeric: false,
        maxLength: null,
        minLength: null,
      },
      currencyType: "$",
      elementType: "currency",
      parentGroup: "basicDetails",
      elementConfig: {
        id: "currencyValue",
        type: "text",
        placeholder: "Enter Currency Value",
      },
      sequenceNo: 3,
    },
  };
  const plateRef = React.useRef();
  const location = useLocation();
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    // options: (styles) => ({
    //   ...styles,
    //   fontFamily: "Roboto",
    //   fontWeight: 400,
    //   fontSize: "12px",
    // }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "1px",
      display: "flex",
      borderRadius: "5px",
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    filterDataCategoryContractType();
  }, [categoryTypeSelected]);

  useEffect(() => {
    setIsLoading(true);
    if (location.state) {
      (async () => {
        let result, dataResult;
        let templateJson_contract = null;
        let template_varjson = null;
        let conditions = {};
        let signatureVariables = [];
        let templatePartiesJson = {};
        let basicDetailsJson = {};
        let renewalTypeSelectedLoc = location.state.renewalTypeSelected;
        let autoRenewal = null;
        let publishEnb = false;
        const draftId = sessionStorage.getItem("draftId");
        const verId = sessionStorage.getItem("verId");
        const tempId = sessionStorage.getItem("tempId");
        if (draftId) {
          validateToken();

          result = getCrudApi("draftTemplate/" + draftId, {});
          await result.then(function (res) {
            dataResult = res;
          });

          if (dataResult) {
            publishEnb = true;
            setPageSize(dataResult?.draft_template?.pageSize ? dataResult?.draft_template?.pageSize : "A4")
            templateJson_contract = JSON.parse(
              dataResult.draft_template.template
            );
            template_varjson = dataResult.draft_template_variable.var;
            conditions = dataResult.draft_template_conditions
              ? dataResult.draft_template_conditions.condition
              : {};
            signatureVariables = dataResult.signature_variable.signatureList;
            if (
              dataResult.draft_template_basic_contract_template &&
              dataResult.draft_template_basic_contract_template.var
            ) {
              Object.keys(
                dataResult.draft_template_basic_contract_template?.var
              ).map((obj) => {
                let objJson = {
                  [obj]:
                    dataResult.draft_template_basic_contract_template?.var[obj],
                };
                if (obj !== "basicDetails") {
                  templatePartiesJson = { ...templatePartiesJson, ...objJson };
                } else {
                  basicDetailsJson = { ...basicDetailsJson, ...objJson };
                }
              });
            }
            renewalTypeSelectedLoc =
              dataResult.draft_renewal_type &&
              dataResult.draft_renewal_type !== 0
                ? dataResult.draft_renewal_type
                : location.state.renewalTypeSelected;
            autoRenewal = dataResult.draft_auto_renewal;
            // templatePartiesJson =
            //   dataResult.draft_template_basic_contract_template?.var;
          }
        } else if (verId) {
          publishEnb = true;
          validateToken();
          result = getCrudApi("templateVersion/" + verId, {});
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult != null) {
            setPageSize(dataResult?.templateVer?.pageSize ? dataResult?.templateVer?.pageSize : "A4")

            templateJson_contract = JSON.parse(dataResult.templateVer.template);
            template_varjson = dataResult.templateVerVariable.var;
            conditions = dataResult.templateVerConditions
              ? dataResult.templateVerConditions.condition
              : {};
            signatureVariables = dataResult.signatureVariable.signatureList;
            // templatePartiesJson = dataResult.templateVerBasicContractTemplate?.var;
            if (
              dataResult.templateVerBasicContractTemplate &&
              dataResult.templateVerBasicContractTemplate.var
            ) {
              Object.keys(dataResult.templateVerBasicContractTemplate?.var).map(
                (obj) => {
                  let objJson = {
                    [obj]:
                      dataResult.templateVerBasicContractTemplate?.var[obj],
                  };
                  if (obj !== "basicDetails") {
                    templatePartiesJson = {
                      ...templatePartiesJson,
                      ...objJson,
                    };
                  } else {
                    basicDetailsJson = { ...basicDetailsJson, ...objJson };
                  }
                }
              );
            }
            renewalTypeSelectedLoc =
              dataResult.templateVerRenewalType &&
              dataResult.templateVerRenewalType !== 0
                ? dataResult.templateVerRenewalType
                : location.state.renewalTypeSelected;
            autoRenewal = dataResult.templateVerAutoRenewal;
          }
        }

        setSignatureVariables(signatureVariables ? signatureVariables : []);
        setTableData(location.state.tableData);
        setContractTypeSelected(location.state.contractTypeSelected);
        setCategoryTypeSelected(location.state.categorySelected);
        setRenewalTypeSelected(renewalTypeSelectedLoc);
        setContractTemplateName(location.state.templateName);
        setContractTypeSelect(location.state.contractType);
        setFilterContractTypeSelect(location.state.contractType);
        setCategoryTypeSelect(location.state.categoryType);
        setRenewalTypeSelect(location.state.renewalType);
        setTemplateTypeSelected(location.state.templateTypeSelected);
        //setSelectedtemplateData(location.state.templateData);
        setContractTemplateJsonData(templateJson_contract);
        setContractVariableJsonData(template_varjson);
        setPartiesJson(templatePartiesJson);
        setPublish_enable(publishEnb);
        setConditions(conditions);

        if (autoRenewal) {
          setSelectedAutoRenewal(
            autoRenewal === 1
              ? {
                  value: 1,
                  text: "True",
                }
              : {
                  value: 2,
                  text: "False",
                }
          );
        }
        if (
          basicDetailsJson === null ||
          Object.keys(basicDetailsJson).length === 0
        ) {
          setBasicDetailsJson({ basicDetails: { ...newBasicDetailsJson } });
          onChangeOfRenewalTreeSelect(renewalTypeSelectedLoc, {
            basicDetails: { ...newBasicDetailsJson },
          });
        } else {
          setBasicDetailsJson(basicDetailsJson);
          let basicDetailsC = {
            ...basicDetailsJson["basicDetails"],
          };
          setExpiryDateFormat({
            value: basicDetailsC["expiryDate"].format,
            text: basicDetailsC["expiryDate"].format,
          });
          setStartDateFormat({
            value: basicDetailsC["startDate"].format,
            text: basicDetailsC["startDate"].format,
          });

          let singleBasicObj = { ...basicDetailsC["expiryDate"] };

          if (
            manualAvailableRenewal.includes(
              parseInt(renewalTypeSelectedLoc, 10)
            )
          ) {
            if (
              singleBasicObj.disabled === false &&
              singleBasicObj.calculation === true
            ) {
              setIsManualChecked(true);
              setEnableAutoRenewal(false);
            } else {
              setIsManualChecked(false);
              setEnableAutoRenewal(true);
            }
            if (renewalTypeSelectedLoc === 9) {
              setCustomDays(singleBasicObj.expiryDuration);
            }
          } else {
            setEnableAutoRenewal(false);
          }
        }
        if (tempId) {
          await getTemplateDetailsForReduxUpdate(tempId);
        }
      })();
    }

    (async () => {
      if (location.state) {
        await GetMasterLibrary();
        if (location.state.tableData) {
          setTemplateId(location.state.tableData[1]);
          await GetApprovers(location.state.tableData[1]);
          await GetNotificationTemplateDetails(location.state.tableData[1]);
        }
      }
      await handleUserData();
    })();
    setIsLoading(false);
  }, []);

  const handleTabChange = (newValue) => {
    setIsLoading(true);
    if (plateRef.current !== null) {
      let serializedData = plateRef.current.serailizedData();
      const conditionsLoc = plateRef.current.getConditions();
      const validSig = plateRef.current.getSigValid();
      const pageType = plateRef.current.getPageSize();
      setValidSig(validSig);
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsLoc);
      setPageSize(pageType);
    }
    setValue(newValue);
    setOpenNotification(false);
    setIsLoading(false);
  };
  const onChangeOfContractTreeSelect = (value) => {
    setContractTypeSelected(value);
  };
  const onChangeOfCategoryTreeSelect = (value) => {
    setCategoryTypeSelected(value);
  };

  const onChangeOfRenewalTreeSelect = (value, basicDetailsJsonLoc) => {
    setRenewalTypeSelected(value);
    let basicDetailsC = { ...basicDetailsJsonLoc["basicDetails"] };
    let singleBasicObj = { ...basicDetailsC["expiryDate"] };
    if (manualAvailableRenewal.includes(value)) {
      setEnableAutoRenewal(true);
      singleBasicObj.disabled = true;
      singleBasicObj.calculation = true;
    }
    switch (value) {
      case 1:
        setEnableAutoRenewal(false);
        singleBasicObj.disabled = true;
        singleBasicObj.calculation = false;
        singleBasicObj.expiryDuration = 0;
        break;
      case 2:
        setEnableAutoRenewal(false);
        singleBasicObj.disabled = false;
        singleBasicObj.calculation = false;
        singleBasicObj.expiryDuration = 0;
        break;
      case 3:
        singleBasicObj.expiryDuration = 7;
        break;
      case 4:
        singleBasicObj.expiryDuration = 14;
        break;
      case 5:
        singleBasicObj.expiryDuration = 30;
        break;
      case 6:
        singleBasicObj.expiryDuration = 90;
        break;
      case 7:
        singleBasicObj.expiryDuration = 180;
        break;
      case 8:
        singleBasicObj.expiryDuration = 365;
        break;
      case 9:
        singleBasicObj.expiryDuration = parseInt(customDays, 10);
        break;
    }
    let expiryDateJson = {
      expiryDate: singleBasicObj,
    };
    basicDetailsC = { ...basicDetailsC, ...expiryDateJson };
    setBasicDetailsJson({ basicDetails: basicDetailsC });
  };
  const onChangeOfTemplateSelect = (value) => {
    setTemplateTypeSelected(value);
  };

  const GetMasterLibrary = async () => {
    setIsLoading(true);
    let result, dataResult, clauseDataResult, notificationDataResult;
    validateToken();
    result = getCrudApi("library/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    validateToken();
    result = getCrudApi("clauses/all", {});
    await result.then(function (res) {
      clauseDataResult = res;
    });
    result = getCrudApi("globalTemplateNotification/all", {});
    await result.then(function (res) {
      notificationDataResult = res;
    });
    if (dataResult) {
      setMasterLibrary(dataResult);
    }
    if (clauseDataResult) {
      setClauseLibrary(clauseDataResult);
    }
    if (notificationDataResult) {
      setGlobalNotificationData(notificationDataResult);
    }

    setIsMasterLoaded(true);
    setIsLoading(false);
  };

  async function OnAddNodeClose() {
    setdis(false);
    setAddClicked(false);
    setOpenNotification(true);
    setNotificationId(null);
  }
  const onCloseClick = () => {
    setAddClicked(false);
  };

  function filterDataCategoryContractType() {
    let category = null;
    var newArray = filterContractTypeSelect;
    if (categoryTypeSelected !== null) {
      category = newArray?.filter((con) => {
        if (con.catogeryTypeId === categoryTypeSelected) return true;
        else return false;
      });
      setContractTypeSelect(category);
    }
  }

  const GetNotificationTemplateDetails = async (contractTemplateId) => {
    setIsLoading(true);
    let notifictionData;
    let result;
    validateToken();
    result = getCrudApi(
      "contractNotificationTemplateSettings/" + contractTemplateId + "/all",
      {}
    );
    await result.then(function (res) {
      notifictionData = res;
    });
    if (notifictionData) {
      setTemplateNotification(notifictionData);
    }
    setIsLoading(false);
  };

  const GetApprovers = async (contractTemplateId) => {
    setIsLoading(true);
    let approversData;
    let result;
    validateToken();
    result = getCrudApi(
      "tempApproval/contractTemplate/" + contractTemplateId,
      {}
    );
    await result.then(function (res) {
      approversData = res;
    });
    if (approversData) {
      let request_approver_data;
      let contract_approver_data;
      request_approver_data = approversData.filter(
        (element) => element.requestContractTemplateFlag === 1
      );
      contract_approver_data = approversData.filter(
        (element) => element.requestContractTemplateFlag === 2
      );
      let [contractApproverData, oldApproverData] = HandleCreateApproverArray(
        contract_approver_data
      );
      let [RequestApproverData, oldrequestApproverData] =
        HandleCreateApproverArray(request_approver_data);
      let contract_settings;
      result = getCrudApi(
        "contractSettings/" + location.state.templateData.contractSettingsId,
        {}
      );
      await result.then(function (res) {
        contract_settings = res;
      });

      let approval_type;
      if (contract_settings) {
        if (
          contract_settings.requestRequired === 3 &&
          contract_settings.contractRequired === 3
        ) {
          approval_type = 7;
        } else if (contract_settings.requestRequired === 3) {
          approval_type = 5;
        } else if (contract_settings.contractRequired === 3) {
          approval_type = 6;
        } else {
          approval_type = 4;
        }
        setApproval_type_select(approval_type);
        setContractApproverRequired(
          contract_settings.contractRequired === 3 ? true : false
        );
        setRequestApproverRequired(
          contract_settings.requestRequired === 3 ? true : false
        );
        setContractApprover([...contractApproverData]);
        setOldContractApprover([...oldApproverData]);
        setRequestApprover([...RequestApproverData]);
        setOldRequestApprover([...oldrequestApproverData]);
        setSelectedStatus(
          contract_settings.isActive === 1
            ? {
                value: 1,
                text: "Active",
              }
            : {
                value: 2,
                text: "Inactive",
              }
        );
      }
    }
    setIsLoading(false);
  };
  const HandleCreateApproverArray = (approversData) => {
    let contractApproverData = [];
    let oldApproverData = [];
    if (approversData != null) {
      approversData.map((x) => {
        if (!contractApproverData[x.step - 1]) {
          contractApproverData[x.step - 1] = [{ id: -1, A_id: 0 }];
          oldApproverData[x.step - 1] = [{ id: -1, A_id: 0 }];
        }
        let a = {
          id: x.userId,
          A_id: x.approverId,
        };
        contractApproverData[x.step - 1].push(a);
        oldApproverData[x.step - 1].push(a);
      });
    }
    for (var i = 0; i < contractApproverData.length; i++) {
      contractApproverData[i].splice(0, 1);
      oldApproverData[i].splice(0, 1);
    }
    return [contractApproverData, oldApproverData];
  };
  const displayPublishMessage = () => {
    toast.success("Published Successfully!", {
      className: "toaststyle",
    });
  };

  const displayDraftedMessage = () => {
    toast.success("Drafted Successfully!", {
      className: "toaststyle",
    });
  };
  const displayVariablesMessage = () => {
    let variables = value[1];
    toast.error("variables" + "variables are not configured", {
      className: "toaststyle",
    });
  };

  const optionsGlobal = {
    filter: false,
    download: false,
    print: false,

    selectableRows: "single",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    // page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    page: globalTablPage,
    customToolbarSelect: () => {},
    onChangePage: (page) => {
      setGlobalTblPage(page);
    },
    onRowsDelete: (rowsDeleted) => {},
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      handleSelectedRow(currentRowsSelected, allRowsSelected, rowsSelected);
    },
    // onChangePage: (currentPage) => setPage(currentPage),
  };
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractNotificationTemplateSettingsId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {},
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
  };

  let selectedRows = [];

  const handleSelectedRow = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    if (allRowsSelected.length > 0) {
      let globalTemplateNotificationId = null;
      const filtered = getFilteredNotificationTemplates();
      const rowData = filtered[allRowsSelected[0].dataIndex];
      globalTemplateNotificationId = rowData?.globalTemplateNotificationId;
      notiTempSelected = globalTemplateNotificationId;
      setEnableAddNoti(true);
      setSelectedNotification(globalTemplateNotificationId);
    } else {
      setEnableAddNoti(false);
      notiTempSelected = null;
      setSelectedNotification(null);
    }
  };

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button onClick={() => onNotificationTemplateEdit(tableMeta)}>
                <i className="fa fa-edit custom-iconformodal" />
              </button>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);
    columnsArray.push({
      name: "contractNotificationTemplateSettingsId",
      label: "contractNotificationTemplateSettingsId",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "triggerType",
      label: "Trigger Type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const trigger = triggertypes.filter((type) => type.id === value);
          return <div>{trigger.length === 1 ? trigger[0].trigger : value}</div>;
        },
      },
    });
    columnsArray.push({
      name: "isSctive",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            className={
              value === 1 ? "table-active-cell" : "table-inactive-cell"
            }
          >
            {value === 1 ? "Active" : "InActive"}
          </div>
        ),
      },
    });

    columnsArray.push({
      name: "contractEmailStatus",
      label: "Email Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            className={
              value === 1 ? "table-active-cell" : "table-inactive-cell"
            }
          >
            {value === 1 ? "Active" : "InActive"}
          </div>
        ),
      },
    });
    columnsArray.push({
      name: "contractSmsStatus",
      label: "SMS Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            className={
              value === 1 ? "table-active-cell" : "table-inactive-cell"
            }
          >
            {value === 1 ? "Active" : "InActive"}
          </div>
        ),
      },
    });
    return columnsArray;
  };
  const getFilteredNotificationTemplates = () => {
    const selectedNotificationId = templateNotification.map(
      (value) => value.globalTemplateNotificationId
    );
    let filtered = globalNotificationData.filter(
      (val) =>
        !selectedNotificationId.includes(val.globalTemplateNotificationId)
    );
    return filtered;
  };
  const onNotificationTemplateEdit = async (tableMeta) => {
    setNotificationId(tableMeta.rowData[1]);
    setOpenNotification(true);
  };
  const generateColumnsGlobal = () => {
    let columnsArray = [];

    columnsArray.push({
      name: "globalTemplateNotificationId",
      label: "Global Template Notification ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "notificationName",
      label: "NOTIFICATION TEMPLATE NAME",
    });
    columnsArray.push({
      name: "notificationDescription",
      label: "NOTIFICATION DESCRIPTION",
    });
    return columnsArray;
  };

  const onAddNewClick = () => {
    setNewOrUpdate("New");
    setAddClicked(true);
  };

  const HandlePublishTemplate = async () => {
    setIsLoading(true);
    if (
      ((approval_type_select === 7 &&
        ContractApprover.length > 0 &&
        RequestApprover.length > 0) ||
        (approval_type_select === 5 && RequestApprover.length > 0) ||
        (approval_type_select === 6 && ContractApprover.length > 0) ||
        approval_type_select === 4) &&
      validateApprover()
    ) {
      if (
        contractTemplateName !== null &&
        contractTemplateName.trim() !== "" &&
        noOfDaysValid
      ) {
        const serializedData =
          plateRef.current !== null
            ? plateRef.current.serailizedData()
            : contractTemplateJsonData;
        const conditionsLoc =
          plateRef.current !== null
            ? plateRef.current.getConditions()
            : conditions;
        const validSig =
          plateRef.current !== null
            ? plateRef.current.getSigValid()
            : validSignature;
        const pageType =  plateRef.current !== null ? plateRef.current.getPageSize() : pageSize;
        setValidSig(validSig);
        setContractTemplateJsonData(serializedData);
        setConditions(conditionsLoc);
        setPageSize(pageType);
        const value = validateVariable(contractVariableJsonData);
        const partiesVal = validateVariable(partiesJson?.promisees[0]);
        const promiserVal = validateVariable(partiesJson?.promisers[0]);
        if (validSig === false) {
          toast.error("Remove unused signature blocks", {
            className: "toaststyle",
          });
        } else if (
          value !== null &&
          promiserVal !== null &&
          partiesVal !== null
        ) {
          let valid = value[0] && promiserVal[0] && partiesVal[0];
          let variables = value[1] + promiserVal[1] + partiesVal[1];
          if (valid === false) {
            toast.error(variables + "variables are not configured", {
              className: "toaststyle",
            });
          } else {
            let template_json = {
              template: JSON.stringify(serializedData),
              pageSize: pageType
            };
            let var_json = {
              var: contractVariableJsonData,
            };
            // let basicDetailsFinJson = {
            //   basicDetails: basicDetailsJson,
            // };
            let parties_json = {
              var: { ...partiesJson, ...basicDetailsJson },
            };
            // let parties_json = {
            //   var: partiesJson,
            // };
            let conditionJson = {
              condition: conditionsLoc,
            };
            let templateVersionJson = {
              signatureVariable: { signatureList: signatureVariables },
              templateVer: template_json,
              templateVerVariable: var_json,
              templateVerBasicContractTemplate: parties_json,
              ispublished: 1,
              versionStauts: 1,
              contractTemplateId: selectedtemplateData
                ? selectedtemplateData.contractTemplateId
                : contractTemplateIdState,
              templateVerConditions: conditionJson,
              templateVerAutoRenewal: selectedAutoRenewal.value,
              templateVerRenewalType: renewalTypeSelected,
            };
            let result;
            validateToken();
            result = postCrudApi(
              "templateVersion/templateVersion",
              templateVersionJson
            );
            let dataResult;
            await result.then(function (res) {
              dataResult = res;
            });
            if (dataResult) {
              sessionStorage.setItem("verId", dataResult.templateVersionId);
              sessionStorage.removeItem("draftId");
              let template_version_id = dataResult.templateVersionId;
              let contractTemplate = {
                publishedTemplateVersionId: template_version_id,
                contractTemplateName: contractTemplateName,
                contractTypeId: contractTypeSelected,
                catogeryTypeId: categoryTypeSelected,
                draftTemplateId: null,
                templateTypeFlag: templateTypeSelected,
              };
              validateToken();

              result = putCrudApi(
                "contractTemplate/" + templateVersionJson.contractTemplateId,
                contractTemplate
              );
              await result.then(function (res) {
                dataResult = res;
              });
              if (dataResult && dataResult === 400) {
                toast.error("Duplicate Contract Name", {
                  className: "toaststyle",
                });
                setIsLoading(false);
                return;
              }
              await Settings_Approver_update();
              const path2 =
                selectedtemplateData !== null
                  ? selectedtemplateData.draftTemplateId !== null
                    ? selectedtemplateData.draftTemplateId
                    : draftTemplate_id !== 0
                    ? draftTemplate_id
                    : null
                  : draftTemplate_id !== 0
                  ? draftTemplate_id
                  : null;
              if (path2 != null) {
                let path = "draftTemplate/" + path2;
                validateToken();
                await deleteCrudApi(path);
              }
              displayPublishMessage();
              setTemplateId(templateVersionJson.contractTemplateId);
              getTemplateDetailsForReduxUpdate(
                templateVersionJson.contractTemplateId
              );
            }
          }
        }
      } else {
        if (
          (contractTemplateName === null ||
            contractTemplateName.trim() === "") &&
          !noOfDaysValid
        ) {
          toast.error(
            "Please enter a valid Contract Template Name and Expiry Duration"
          );
        } else if (!noOfDaysValid) {
          toast.error("Please enter valid Expiry Duration");
        } else {
          toast.error("Please enter valid Contract Template Name");
        }
      }
    } else {
      toast.error("Please Assign Approvers");
    }
    setIsLoading(false);
  };
  const validateApprover = () => {
    let validApp = true;
    RequestApprover?.map((reqAp) => {
      reqAp?.map((req) => {
        if (req.id === -1) {
          validApp = false;
        }
      });
    });
    ContractApprover.map((conAp) => {
      conAp.map((con) => {
        if (con.id === -1) {
          validApp = false;
        }
      });
    });
    return validApp;
  };
  const handle_approver_post = (
    contractTemplateId,
    approver_data,
    approver_flag
  ) => {
    let approver_json = [];
    let approver = {};
    if (approver_data) {
      for (let i = 0; i < approver_data.length; i++) {
        if (approver_data[i]) {
          for (let j = 0; j < approver_data[i].length; j++) {
            approver = {
              approverId: approver_data[i][j].A_id,
              userId: approver_data[i][j].id,
              step: i + 1,
              contractTemplateTd: contractTemplateId,
              requestContractTemplateFlag: approver_flag,
            };
            approver_json.push(approver);
          }
        }
      }
    }
    return approver_json;
  };
  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  const Settings_Approver_update = async () => {
    setIsLoading(true);
    //
    let contractTemplateSettings = {
      //renewalId: renewalTypeSelected,
      contractTemplateId: selectedtemplateData.contractTemplateId,
      requestRequired:
        approval_type_select === 5 || approval_type_select === 7 ? 3 : 4,
      contractRequired:
        approval_type_select === 6 || approval_type_select === 7 ? 3 : 4,
      // templateRequired: state.approval_type_select === 13 ? 3 : 4,
      totalSteps: approval_type_select === 4 ? 0 : ContractApprover.length,
      totalStepsRequest:
        approval_type_select === 4 ? 0 : RequestApprover.length,
      isActive: selectedStatus.value,
      // totalStepsTemplate: state.approversCount.length,
    };
    validateToken();
    await putCrudApi(
      "contractSettings/" + selectedtemplateData.contractSettingsId,
      contractTemplateSettings
    );
    if (approval_type_select === 4) {
      setContractApprover([]);
      setRequestApprover([]);
      setApproversCount([]);
    }

    let old_approver_json = handle_approver_post(
      selectedtemplateData.contractTemplateId,
      oldContractApprover,
      2
    );
    let approver_json = handle_approver_post(
      selectedtemplateData.contractTemplateId,
      ContractApprover,
      2
    );

    old_approver_json.sort(GetSortOrder("approverId"));
    approver_json.sort(GetSortOrder("approverId"));
    let diff_approver = detailedDiff(old_approver_json, approver_json);
    Object.keys(diff_approver.deleted).forEach(async (element) => {
      validateToken();
      await deleteCrudApi(
        "tempApproval/" + old_approver_json[element].approverId
      );
    });
    let new_approver = [];
    Object.keys(diff_approver.added).forEach((element) => {
      new_approver.push(diff_approver.added[element]);
    });
    let update_approver = [];
    let inv_diff_contract_approver = detailedDiff(
      approver_json,
      old_approver_json
    );
    let inv_diff_contract_approver_updated = inv_diff_contract_approver.updated;
    Object.keys(diff_approver.updated).forEach((element) => {
      let updated_approver = approver_json[element];
      if (inv_diff_contract_approver_updated[element].approverId) {
        updated_approver.approverId =
          inv_diff_contract_approver_updated[element].approverId;
      }
      update_approver.push(updated_approver);
    });
    validateToken();
    if (new_approver.length > 0)
      await postCrudApi("tempApproval/tempApproval", new_approver);
    update_approver.map(async (approver) => {
      validateToken();
      await putCrudApi("tempApproval/" + approver.approverId, approver);
    });

    // for request approver update
    let old_Request_approver_json = handle_approver_post(
      selectedtemplateData.contractTemplateId,
      oldRequestApprover,
      1
    );
    let Request_approver_json = handle_approver_post(
      selectedtemplateData.contractTemplateId,
      RequestApprover,
      1
    );

    old_Request_approver_json.sort(GetSortOrder("approverId"));
    Request_approver_json.sort(GetSortOrder("approverId"));

    let diff_request_approver = detailedDiff(
      old_Request_approver_json,
      Request_approver_json
    );
    Object.keys(diff_request_approver.deleted).forEach(async (element) => {
      validateToken();
      await deleteCrudApi(
        "tempApproval/" + old_Request_approver_json[element].approverId
      );
    });
    let new_request_approver = [];
    Object.keys(diff_request_approver.added).forEach((element) => {
      new_request_approver.push(diff_request_approver.added[element]);
    });
    let update_request_approver = [];
    let inv_diff_request_approver = detailedDiff(
      Request_approver_json,
      old_Request_approver_json
    );

    let inv_diff_request_approver_updated = inv_diff_request_approver.updated;
    Object.keys(diff_request_approver.updated).forEach((element) => {
      let updated_request_approver = Request_approver_json[element];
      if (inv_diff_request_approver_updated[element].approverId) {
        updated_request_approver.approverId =
          inv_diff_request_approver_updated[element].approverId;
      }
      update_request_approver.push(updated_request_approver);
    });
    validateToken();
    if (new_request_approver.length > 0)
      await postCrudApi("tempApproval/tempApproval", new_request_approver);
    update_request_approver.map(async (approver) => {
      validateToken();
      await putCrudApi("tempApproval/" + approver.approverId, approver);
    });

    const oldR = RequestApprover.map((a) => {
      return a.map((b) => {
        return { ...b };
      });
    });
    const oldC = ContractApprover.map((a) => {
      return a.map((b) => {
        return { ...b };
      });
    });
    setOldContractApprover(oldC);
    setRequestApprover(oldR);
    setOldRequestApprover(oldR);
    setContractApprover(oldC);
    setIsLoading(false);
  };
  const handleStartDate = (e) => {
    let basicDetailsC = { ...basicDetailsJson["basicDetails"] };
    let singleBasicObj = { ...basicDetailsC["startDate"] };
    singleBasicObj.format = e.value;
    let startDateJson = {
      startDate: singleBasicObj,
    };
    basicDetailsC = { ...basicDetailsC, ...startDateJson };
    setBasicDetailsJson({ basicDetails: basicDetailsC });
  };
  const handleExpiryDate = (e) => {
    let basicDetailsC = { ...basicDetailsJson["basicDetails"] };
    let singleBasicObj = { ...basicDetailsC["expiryDate"] };
    singleBasicObj.format = e.value;
    let expiryDateJson = {
      expiryDate: singleBasicObj,
    };
    basicDetailsC = { ...basicDetailsC, ...expiryDateJson };
    setBasicDetailsJson({ basicDetails: basicDetailsC });
  };
  const HandleSaveTemplate = async () => {
    setIsLoading(true);
    if (
      contractTemplateName !== null &&
      contractTemplateName.trim() !== "" &&
      noOfDaysValid
    ) {
      const serializedData =
        plateRef.current !== null
          ? plateRef.current.serailizedData()
          : contractTemplateJsonData;
      const conditionsLoc =
        plateRef.current !== null
          ? plateRef.current.getConditions()
          : conditions;
      const pageType =  plateRef.current !== null ? plateRef.current.getPageSize() : pageSize;
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsLoc);
      setPageSize(pageType)
      const value = validateVariable(contractVariableJsonData);
      const partiesVal = validateVariable(partiesJson?.promisees[0]);
      const promiserVal = validateVariable(partiesJson?.promisers[0]);
      if (value !== null && promiserVal !== null && partiesVal !== null) {
        let valid = value[0] && promiserVal[0] && partiesVal[0];
        let variables = value[1] + promiserVal[1] + partiesVal[1];
        if (valid === false) {
          toast.error(variables + "variables are not configured", {
            className: "toaststyle",
          });
        } else {
          let template_json = {
            template: JSON.stringify(serializedData),
            pageSize:pageType
          };
          let var_json = {
            var: contractVariableJsonData,
          };
          // let basicDetailsFinJson = {
          //   basicDetails: basicDetailsJson,
          // };
          let parties_json = {
            var: { ...partiesJson, ...basicDetailsJson },
          };
          let conditionJson = {
            condition: conditionsLoc,
          };
          let templateJson = {
            signature_variable: { signatureList: signatureVariables },
            draft_template: template_json,
            draft_template_variable: var_json,
            draft_template_basic_contract_template: parties_json,
            draft_template_conditions: conditionJson, //{ condition: {} },
            draft_auto_renewal: selectedAutoRenewal.value,
            draft_renewal_type: renewalTypeSelected,
          };
          let result;
          if (
            selectedtemplateData === null ||
            selectedtemplateData.draftTemplateId === null
          ) {
            validateToken();
            result = postCrudApi("draftTemplate/draftTemplate", templateJson);
          } else {
            validateToken();
            result = putCrudApi(
              "draftTemplate/" + selectedtemplateData.draftTemplateId,
              templateJson
            );
          }
          let dataResult;
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            sessionStorage.setItem("draftId", dataResult.draft_template_id);
            sessionStorage.removeItem("verId");
            let draft_id = dataResult.draft_template_id;
            setDraftTemplate_id(draft_id);
            let contractTemplate = {
              contractTemplateName: contractTemplateName,
              contractTypeId: contractTypeSelected,
              catogeryTypeId: categoryTypeSelected,
              draftTemplateId: draft_id,
              templateTypeFlag: templateTypeSelected,
            };
            let contractTemplateId;

            if (selectedtemplateData == null) {
              validateToken();
              result = postCrudApi(
                "contractTemplate/contractTemplate",
                contractTemplate
              );
              await result.then(function (res) {
                contractTemplateId = res;
              });
              if (contractTemplateId && contractTemplateId === 400) {
                toast.error("Duplicate Contract Name", {
                  className: "toaststyle",
                });
                setIsLoading(false);
                return;
              }
              if (contractTemplateId) {
                setContractTemplateIdState(
                  contractTemplateId?.contractTemplateId
                );
                let contractTemplateSettings = {
                  //renewalId: renewalTypeSelected,
                  contractTemplateId: contractTemplateId?.contractTemplateId,
                  requestRequired:
                    approval_type_select === 5 || approval_type_select === 7
                      ? 3
                      : 4,
                  contractRequired:
                    approval_type_select === 6 || approval_type_select === 7
                      ? 3
                      : 4,
                  // templateRequired: approval_type_select === 13 ? 3 : 4,
                  totalSteps:
                    approval_type_select === 4 ? 0 : ContractApprover.length,
                  totalStepsRequest:
                    approval_type_select === 4 ? 0 : RequestApprover.length,
                  isActive: selectedStatus.value,
                  // totalStepsTemplate: approval_type_select === 4?0:approversCount.length,
                };

                validateToken();
                await postCrudApi(
                  "contractSettings/contractSettings",
                  contractTemplateSettings
                );
                if (approval_type_select === 4) {
                  setContractApprover([]);
                  setRequestApprover([]);
                  setApproversCount([]);
                }
              }
              let approver_json = handle_approver_post(
                contractTemplateId?.contractTemplateId,
                ContractApprover,
                2
              );
              let request_approver = handle_approver_post(
                contractTemplateId?.contractTemplateId,
                RequestApprover,
                1
              );
              setPublish_enable(true);
              if (approver_json.length > 0)
                await postCrudApi("tempApproval/tempApproval", approver_json);
              if (request_approver.length > 0)
                await postCrudApi(
                  "tempApproval/tempApproval",
                  request_approver
                );
              const oldR = RequestApprover.map((a) => {
                return a.map((b) => {
                  return { ...b };
                });
              });
              const oldC = ContractApprover.map((a) => {
                return a.map((b) => {
                  return { ...b };
                });
              });
              setOldContractApprover(oldC);
              setRequestApprover(oldR);
              setOldRequestApprover(oldR);
              setContractApprover(oldC);
            } else {
              validateToken();
              result = putCrudApi(
                "contractTemplate/" + selectedtemplateData.contractTemplateId,
                contractTemplate
              );
              await result.then(function (res) {
                contractTemplateId = res;
              });
              if (contractTemplateId && contractTemplateId === 400) {
                alert("Duplicate Contract Name");
                setIsLoading(false);
                return;
              }
              await Settings_Approver_update();
            }
            setTemplateId(contractTemplateId?.contractTemplateId);
            getTemplateDetailsForReduxUpdate(
              contractTemplateId.contractTemplateId
            );
            displayDraftedMessage();
          }
        }
      }
    } else {
      if (
        (contractTemplateName === null || contractTemplateName.trim() === "") &&
        !noOfDaysValid
      ) {
        toast.error(
          "Please enter a valid Contract Template Name and Expiry Duration"
        );
      } else if (!noOfDaysValid) {
        toast.error("Please enter valid Expiry Duration");
      } else {
        toast.error("Please enter valid Contract Template Name");
      }
    }
    setIsLoading(false);
  };
  const generateJSONForContractTemplate = (
    templateData,
    settingsData,
    renewalTypeData
  ) => {
    let arrayOfJSON = [];
    templateData.forEach((template) => {
      let newJSON = {};
      Object.keys(template).forEach((element) => {
        newJSON[element] = template[element];
      });
      settingsData.forEach((setting) => {
        if (
          template &&
          setting &&
          setting.contractTemplateId === template.contractTemplateId
        ) {
          Object.keys(setting).forEach((element) => {
            newJSON[element] = setting[element];
          });
          renewalTypeData.forEach((renewal) => {
            if (setting.renewalId === renewal.renewal_id) {
              newJSON.renewal_type = renewal.renewal_type;
            }
          });
        }
      });
      arrayOfJSON.push(newJSON);
    });
    return arrayOfJSON;
  };

  const getTemplateDetailsForReduxUpdate = async (templateID) => {
    setIsLoading(true);
    sessionStorage.setItem("tempId", templateID);
    let templateData, templateSettings, renewalType;
    let result;
    validateToken();
    result = getCrudApi("contractTemplate/all", {});
    await result.then(function (res) {
      templateData = res;
    });
    validateToken();
    result = getCrudApi("contractSettings/all", {});
    await result.then(function (res) {
      templateSettings = res;
    });
    validateToken();
    result = getCrudApi("renewalTypes/all", {});
    await result.then(function (res) {
      renewalType = res;
    });
    let contractTemplate = generateJSONForContractTemplate(
      templateData,
      templateSettings,
      renewalType
    );
    props.setcontractTemplateData(contractTemplate);
    setSelectedtemplateData(
      contractTemplate.filter(
        (element) => element.contractTemplateId === parseInt(templateID, 10)
      )[0]
    );
    //this.props.history.push("/Admin/contractTemplates");
    setIsLoading(false);
  };

  const handle_approver_required = (event) => {
    if (event.target.value === 13) {
      let approver_array = [];
      approver_array.push([-1]);
      setApproversCount(approver_array);
      setApproverRequired(true);
      setRequestApproverRequired(false);
      setContractApproverRequired(false);
    } else if (event.target.value === 5) {
      let approver_array = [...RequestApprover];
      let a = {
        id: -1,
        A_id: 0,
      };
      if (approver_array.length === 0) approver_array.push([a]);
      setRequestApprover(approver_array);
      setRequestApproverRequired(true);
      setApproverRequired(false);
      setContractApproverRequired(false);
    } else if (event.target.value === 6) {
      let approver_array = [...ContractApprover];
      let a = {
        id: -1,
        A_id: 0,
      };
      if (approver_array.length === 0) approver_array.push([a]);
      setContractApprover(approver_array);
      setRequestApproverRequired(false);
      setApproverRequired(false);
      setContractApproverRequired(true);
    } else if (event.target.value === 7) {
      let Request_approver_array = [...RequestApprover];
      let Contract_approver_array = [...ContractApprover];
      let a = {
        id: -1,
        A_id: 0,
      };
      if (Request_approver_array.length === 0) Request_approver_array.push([a]);
      if (Contract_approver_array.length === 0)
        Contract_approver_array.push([a]);
      setContractApprover(Contract_approver_array);
      setRequestApprover(Request_approver_array);
      setRequestApproverRequired(true);
      setApproverRequired(false);
      setContractApproverRequired(true);
    } else {
      setRequestApproverRequired(false);
      setApproverRequired(false);
      setContractApproverRequired(false);
    }
    setApproval_type_select(event.target.value);
  };
  const handleUserData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi("users/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    let userData = selectOptionsMapName(
      dataResult,
      "userId",
      "first_name",
      "last_name"
    );
    setUserData([...userData]);
    setUserDataCon([...userData]);
    setUserDataReq([...userData]);
    setIsLoading(false);
  };
  const addApprover = (event) => {
    let approvers_array = [...approversCount];
    approvers_array.push([-1]);
    setApproversCount(approvers_array);
  };
  const stepApprover = (i) => {
    let final_approver_array = [...approversCount];
    final_approver_array[i].push([-1]);
    setApproversCount(final_approver_array);
  };
  const removeApprover = (i, j) => {
    let approvers_array = [...approversCount];
    approvers_array[i].splice(j, 1);
    if (approvers_array[i].length === 0) {
      approvers_array.splice(i, 1);
    }
    setApproversCount(approvers_array);
  };
  const approvers_change = (event, i, j) => {
    let approvers_array = [...approversCount];
    approvers_array[i][j] = event.target.value;
    setApproversCount(approvers_array);
  };
  const addRequestApprover = (event) => {
    let approvers_array = [...RequestApprover];
    let a = {
      id: -1,
      A_id: 0,
    };
    approvers_array.push([a]);
    setRequestApprover(approvers_array);
  };
  const stepRequestApprover = (i) => {
    let final_approver_array = [...RequestApprover];
    let a = {
      id: -1,
      A_id: 0,
    };
    final_approver_array[i].push(a);

    setRequestApprover(final_approver_array);
  };
  const removeRequestApprover = (i, j) => {
    let approvers_array = [...RequestApprover];
    approvers_array[i].splice(j, 1);
    if (approvers_array[i].length === 0) {
      approvers_array.splice(i, 1);
    }
    setRequestApprover(approvers_array);
  };
  const Request_approvers_change = (event, i, j) => {
    let approvers_array = [...RequestApprover];

    // Restricting user name repeat

    let selectedApprovers = [];
    approvers_array.map((oneAp) => {
      oneAp.map((ap) => {
        selectedApprovers.push(ap.id);
      });
    });
    if (selectedApprovers.includes(event.target.value)) {
      return;
    }

    let a = {
      id: event.target.value,
      A_id: approvers_array[i][j].A_id,
    };
    approvers_array[i][j] = a;

    setRequestApprover(approvers_array);
  };
  const addContractApprover = (event) => {
    let approvers_array = [...ContractApprover];
    let a = {
      id: -1,
      A_id: 0,
    };
    approvers_array.push([a]);

    setContractApprover(approvers_array);
  };
  const stepContractApprover = (i) => {
    let final_approver_array = [...ContractApprover];
    let a = {
      id: -1,
      A_id: 0,
    };
    final_approver_array[i].push(a);
    setContractApprover(final_approver_array);
  };
  const removeContractApprover = (i, j) => {
    let approvers_array = [...ContractApprover];
    approvers_array[i].splice(j, 1);
    if (approvers_array[i].length === 0) {
      approvers_array.splice(i, 1);
    }
    setContractApprover(approvers_array);
  };
  const Contract_approvers_change = (event, i, j) => {
    let approvers_array = [...ContractApprover];

    let selectedApprovers = [];
    approvers_array.map((oneAp) => {
      oneAp.map((ap) => {
        selectedApprovers.push(ap.id);
      });
    });
    if (selectedApprovers.includes(event.target.value)) {
      return;
    }

    let a = {
      id: event.target.value,
      A_id: approvers_array[i][j].A_id,
    };
    approvers_array[i][j] = a;

    setContractApprover(approvers_array);
  };
  const returnClauseData = async (clauseID) => {
    setIsLoading(true);
    let result = getCrudApi(`clauses/${clauseID}`);
    let dataResult;
    await result.then(function (res) {
      dataResult = res;
    });
    let clausehtml = dataResult.clauseHtml;
    setIsLoading(false);
    return clausehtml.clauseHtml;
  };

  const treeSelectStyle = {
    width: "90%",
    margin: "10px 0 0 0",
  };

  const handleManualChecked = (manualChecked) => {
    let basicDetailsC = { ...basicDetailsJson["basicDetails"] };
    let singleBasicObj = { ...basicDetailsC["expiryDate"] };
    // if(manualAvailableRenewal.includes(renewalTypeSelected))
    singleBasicObj.disabled = !manualChecked;
    singleBasicObj.calculation = true;
    let expiryDateJson = {
      expiryDate: singleBasicObj,
    };
    basicDetailsC = { ...basicDetailsC, ...expiryDateJson };
    if (manualChecked) {
      setEnableAutoRenewal(false);
    } else {
      setEnableAutoRenewal(true);
    }
    setBasicDetailsJson({ basicDetails: basicDetailsC });
  };
  const handleCustomDays = (val) => {
    let basicDetailsC = { ...basicDetailsJson["basicDetails"] };
    let singleBasicObj = { ...basicDetailsC["expiryDate"] };
    // if(manualAvailableRenewal.includes(renewalTypeSelected))
    singleBasicObj.expiryDuration = parseInt(val, 10);
    let expiryDateJson = {
      expiryDate: singleBasicObj,
    };
    basicDetailsC = { ...basicDetailsC, ...expiryDateJson };
    setBasicDetailsJson({ basicDetails: basicDetailsC });
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
    GetNotificationTemplateDetails(templateId);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div>
            <div className="headerHolder">
              <div className="Tab-nav-editor ">
                <div
                  className={value === 0 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(0)}
                >
                  Editor
                </div>
                <div
                  className={value === 1 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(1)}
                >
                  Template Settings
                </div>
                {publish_enable ? (
                  <div
                    className={value === 2 ? "tabs-active" : "tab-div"}
                    onClick={() => handleTabChange(2)}
                  >
                    Notification Settings
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="formHeader">
                <div className="formheader85">
                  <label className="editor-header-label">
                    {" "}
                    {contractTemplateName !== undefined
                      ? contractTemplateName
                      : "NAME"}
                  </label>
                </div>
                {value !== 2 ? (
                  <div className="formheader20">
                    <button
                      className="editor-contracts-btn"
                      onClick={() => HandleSaveTemplate()}
                    >
                      Draft
                    </button>
                    <button
                      className="editor-contracts-btn"
                      onClick={() => HandlePublishTemplate()}
                      disabled={!publish_enable}
                    >
                      Publish
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {openNotification ? (
              <NotificationSettings
                id={notiId === null ? selectedNotification : null}
                nonTemplateVariableArray={nonTemplateVariableArray}
                contractVariableJsonData={contractVariableJsonData}
                notificationId={notiId}
                templateId={templateId}
                setCloseNotification={handleCloseNotification}
              />
            ) : (
              <>
                {value === 1 ? (
                  <div>
                    <div className="settingTab">
                      <div>
                        <div className="container-grid main-div-settings-div">
                          <div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label
                                  className=" setting-label "
                                  // style={{ marginTop: "12px" }}
                                >
                                  Contract Template
                                </label>
                              </div>

                              <div className="form-columns ">
                                <input
                                  type="text"
                                  placeholder="Contract Template Name"
                                  className="text-form-editor"
                                  value={contractTemplateName}
                                  onChange={(e) =>
                                    setContractTemplateName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className="setting-label">
                                  Category Type
                                </label>
                              </div>
                              <div className="form-columns ">
                                <SyfedTreeSelect
                                  style={treeSelectStyle}
                                  size="small"
                                  treeCheckedStrategy="all"
                                  dataSource={CategoryTypeSelect}
                                  onChange={onChangeOfCategoryTreeSelect}
                                  placeholder="Select Category Type"
                                  value={categoryTypeSelected}
                                />
                              </div>
                            </div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className=" setting-label ">
                                  Contract Type
                                </label>
                              </div>
                              <div className="form-columns ">
                                <SyfedTreeSelect
                                  style={treeSelectStyle}
                                  size="small"
                                  treeCheckedStrategy="all"
                                  dataSource={ContractTypeSelect}
                                  onChange={onChangeOfContractTreeSelect}
                                  placeholder="Select Contract Type"
                                  value={contractTypeSelected}
                                />
                              </div>
                            </div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className=" setting-label ">
                                  Template Type
                                </label>
                              </div>
                              <div className="form-columns ">
                                <SyfedTreeSelect
                                  style={treeSelectStyle}
                                  size="small"
                                  treeCheckedStrategy="all"
                                  dataSource={TemplateTypeSelect}
                                  onChange={onChangeOfTemplateSelect}
                                  placeholder="Select Template Type"
                                  value={templateTypeSelected}
                                />
                              </div>
                            </div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className="setting-label ">
                                  Approval
                                </label>
                              </div>
                              <div className="form-columns ">
                                <Select
                                  value={approval_type_select}
                                  onChange={handle_approver_required}
                                  className="select-approver material-select-template-settings"
                                  IconComponent={ExpandMoreIcon}
                                >
                                  <MenuItem value={5}>
                                    Request Approval
                                  </MenuItem>
                                  <MenuItem value={6}>
                                    Contract Approval
                                  </MenuItem>
                                  {/*<MenuItem value={13}>Template Approval</MenuItem>*/}
                                  <MenuItem value={7}>Both Required</MenuItem>
                                  <MenuItem value={4}>Not Required</MenuItem>
                                </Select>
                              </div>
                            </div>
                          </div>
                          <div className="second">
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className="setting-label ">
                                  Expiry Frequency
                                </label>
                              </div>
                              <div className="form-columns ">
                                <SyfedTreeSelect
                                  style={treeSelectStyle}
                                  size="small"
                                  treeCheckedStrategy="all"
                                  dataSource={RenewalTypeSelect}
                                  onChange={(val) =>
                                    onChangeOfRenewalTreeSelect(
                                      val,
                                      basicDetailsJson
                                    )
                                  }
                                  placeholder="Select Renewal Type"
                                  value={renewalTypeSelected}
                                />
                              </div>
                            </div>
                            {manualAvailableRenewal.includes(
                              renewalTypeSelected
                            ) ? (
                              <div className="formrow">
                                <div className="form-columns ">
                                  <label className="setting-label">
                                    Allow Manual Expiry
                                  </label>
                                </div>
                                <div className="form-columns ">
                                  <input
                                    className="EditorCheckBox"
                                    type="checkbox"
                                    id="manualcheckbox"
                                    checked={isManualChecked}
                                    onChange={(e) => {
                                      setIsManualChecked(!isManualChecked);
                                      handleManualChecked(!isManualChecked);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {renewalTypeSelected === 9 ? (
                              <div>
                                <div className="formrow">
                                  <div className="form-columns ">
                                    <label className=" setting-label ">
                                      Number of Days
                                    </label>
                                  </div>

                                  <div className="form-columns ">
                                    <input
                                      type="text"
                                      className="text-form-editor"
                                      value={customDays}
                                      onChange={(e) => {
                                        handleCustomDays(e.target.value);
                                        setCustomDays(e.target.value);
                                        setNoOfDaysValid(
                                          e.target.value !== "" &&
                                            e.target.value !== null &&
                                            e.target.value !== undefined &&
                                            /^\d+$/.test(e.target.value)
                                        );
                                      }}
                                    />
                                    {!noOfDaysValid ? (
                                      <div className="error-editor">
                                        {"*Required (must be a number)"}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className=" setting-label ">
                                  Auto Renewal
                                </label>
                              </div>
                              <div className="form-columns ">
                                <ReactSelect
                                  maxMenuHeight={120}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: " #E5E5E5",
                                      primary: "#B2D4FD ",
                                    },
                                  })}
                                  value={selectedAutoRenewal}
                                  styles={selectStyles}
                                  onChange={(e) => {
                                    setSelectedAutoRenewal(e);
                                  }}
                                  getOptionLabel={(option) => option.text}
                                  options={AutoRenewalSelect}
                                  isDisabled={!enableAutoRenewal}
                                ></ReactSelect>
                              </div>
                            </div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className=" setting-label ">
                                  Start Date Format
                                </label>
                              </div>
                              <div className="form-columns ">
                                <ReactSelect
                                  maxMenuHeight={120}
                                  value={startDateFormat}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: " #E5E5E5",
                                      primary: "#B2D4FD ",
                                    },
                                  })}
                                  styles={selectStyles}
                                  onChange={(e) => {
                                    handleStartDate(e);
                                    setStartDateFormat(e);
                                  }}
                                  getOptionLabel={(option) => option.text}
                                  options={validDateFormats}
                                ></ReactSelect>
                              </div>
                            </div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className=" setting-label ">
                                  Expiry Date Format
                                </label>
                              </div>
                              <div className="form-columns ">
                                <ReactSelect
                                  maxMenuHeight={120}
                                  value={expiryDateFormat}
                                  styles={selectStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: " #E5E5E5",
                                      primary: "#B2D4FD ",
                                    },
                                  })}
                                  onChange={(e) => {
                                    handleExpiryDate(e);
                                    setExpiryDateFormat(e);
                                  }}
                                  getOptionLabel={(option) => option.text}
                                  options={validDateFormats}
                                ></ReactSelect>
                              </div>
                            </div>
                            <div className="formrow">
                              <div className="form-columns ">
                                <label className=" setting-label ">
                                  Status
                                </label>
                              </div>
                              <div className="form-columns ">
                                <ReactSelect
                                  maxMenuHeight={120}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: " #E5E5E5",
                                      primary: "#B2D4FD ",
                                    },
                                  })}
                                  value={selectedStatus}
                                  styles={selectStyles}
                                  onChange={(e) => {
                                    setSelectedStatus(e);
                                  }}
                                  getOptionLabel={(option) => option.text}
                                  options={statusSelectOpt}
                                ></ReactSelect>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {ApproverRequired === true ? (
                        <>
                          <label className=" setting-label ">
                            <b>Config Template Approvers</b>
                          </label>
                          {approversCount && approversCount.length < 5 ? (
                            <button
                              className="App-btn"
                              type="button"
                              onClick={addApprover}
                            >
                              <i className="fa fa-plus" />
                            </button>
                          ) : null}

                          {approversCount
                            ? approversCount.map((el, i) => {
                                return (
                                  <div className="main-div-settings-div">
                                    <label className="label-style">
                                      Step {i + 1}
                                      {el && el.length < 5 ? (
                                        <div className=" div-step">
                                          <button
                                            className="btn-step"
                                            type="button"
                                            onClick={() => stepApprover(i)}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      ) : null}
                                    </label>
                                    <div style={{ display: "flex" }}>
                                      {el
                                        ? el.map((approver, j) => {
                                            return (
                                              <>
                                                <Select
                                                  className="select-reviewer material-select-template-settings"
                                                  id={j}
                                                  value={approversCount[i][j]}
                                                  onChange={(event) =>
                                                    approvers_change(
                                                      event,
                                                      i,
                                                      j
                                                    )
                                                  }
                                                >
                                                  {UserData.map((user) => (
                                                    <MenuItem
                                                      value={user.value}
                                                    >
                                                      {user.label}
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                                <button
                                                  style={{ marginLeft: "2px" }}
                                                  className="btn-step-same-level"
                                                  type="button"
                                                  onClick={() =>
                                                    removeApprover(i, j)
                                                  }
                                                >
                                                  <i className="fa fa-minus" />
                                                </button>
                                              </>
                                            );
                                          })
                                        : null}
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      ) : null}
                      {RequestApproverRequired === true ? (
                        <div className="main-div-settings-div">
                          <div>
                            <label className="setting-label ">
                              Request Approvers
                            </label>

                            <button
                              className="step-app-Add btn-step"
                              type="button"
                              onClick={addRequestApprover}
                              disabled={
                                RequestApprover && RequestApprover.length < 5
                                  ? false
                                  : true
                              }
                            >
                              Add
                            </button>

                            {RequestApprover
                              ? RequestApprover.map((el, i) => {
                                  return (
                                    <>
                                      <div className="Approvers-div">
                                        <label className="step-style">
                                          Step {i + 1}
                                        </label>

                                        <div className=" div-step">
                                          <button
                                            className="btn-step-same-level"
                                            type="button"
                                            onClick={() =>
                                              stepRequestApprover(i)
                                            }
                                            disabled={
                                              el && el.length < 5 ? false : true
                                            }
                                          >
                                            <i
                                              className="fa fa-plus"
                                              style={{ color: "white" }}
                                            />
                                          </button>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                          {el
                                            ? el.map((approver, j) => {
                                                return (
                                                  <>
                                                    <div className="editor-step-approval">
                                                      <i class="fas fa-user-check editor-userIcon"></i>
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          removeRequestApprover(
                                                            i,
                                                            j
                                                          )
                                                        }
                                                      >
                                                        <i class="fa fa-remove editor-removeIcon"></i>
                                                      </button>
                                                      <Select
                                                        className="select-reviewer material-select-template-settings"
                                                        id={j}
                                                        value={
                                                          RequestApprover[i][j]
                                                            .id
                                                        }
                                                        onChange={(event) =>
                                                          Request_approvers_change(
                                                            event,
                                                            i,
                                                            j
                                                          )
                                                        }
                                                      >
                                                        {UserDataReq.map(
                                                          (user) => (
                                                            <MenuItem
                                                              value={user.value}
                                                            >
                                                              {user.label}
                                                            </MenuItem>
                                                          )
                                                        )}
                                                      </Select>
                                                    </div>
                                                  </>
                                                );
                                              })
                                            : null}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      ) : null}
                      {ContractApproverRequired === true ? (
                        <>
                          <div className="main-div-settings-div">
                            <label className="setting-label">
                              Contract Approvers
                            </label>

                            <button
                              className="step-app-Add btn-step"
                              type="button"
                              onClick={addContractApprover}
                              disabled={
                                ContractApprover && ContractApprover.length < 5
                                  ? false
                                  : true
                              }
                            >
                              Add
                            </button>

                            {ContractApprover
                              ? ContractApprover.map((el, i) => {
                                  return (
                                    <>
                                      <div className="Approvers-div">
                                        <label className=" step-style">
                                          Step {i + 1}
                                        </label>

                                        <div className=" div-step">
                                          <button
                                            className="btn-step-same-level"
                                            type="button"
                                            onClick={() =>
                                              stepContractApprover(i)
                                            }
                                            disabled={
                                              el && el.length < 5 ? false : true
                                            }
                                          >
                                            <i
                                              className="fa fa-plus"
                                              style={{ color: "white" }}
                                            />
                                          </button>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          {el
                                            ? el.map((approver, j) => {
                                                return (
                                                  <>
                                                    <div className="editor-step-approval">
                                                      <i class="fas fa-user-check editor-userIcon"></i>
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          removeContractApprover(
                                                            i,
                                                            j
                                                          )
                                                        }
                                                      >
                                                        <i class="fa fa-remove editor-removeIcon"></i>
                                                      </button>
                                                      <Select
                                                        className="select-reviewer material-select-template-settings"
                                                        id={j}
                                                        value={
                                                          ContractApprover[i][j]
                                                            .id
                                                        }
                                                        onChange={(event) =>
                                                          Contract_approvers_change(
                                                            event,
                                                            i,
                                                            j
                                                          )
                                                        }
                                                      >
                                                        {UserDataCon.map(
                                                          (user) => (
                                                            <MenuItem
                                                              value={user.value}
                                                            >
                                                              {user.label}
                                                            </MenuItem>
                                                          )
                                                        )}
                                                      </Select>
                                                    </div>
                                                  </>
                                                );
                                              })
                                            : null}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : value === 0 ? (
                  <div>
                    {isMasterLoaded ? (
                      <RecoilRoot>
                        <PlateEditor
                          id={"templateEditor"}
                          ref={plateRef}
                          parentGroup="Contract"
                          canAddComments={false}
                          isNegotiationDisabled={true}
                          isVariableDisabled={false}
                          isPartiesDisabled={false}
                          isConditionDisabled={false}
                          isClauseDisabled={false}
                          isConditionToggleDisabled={false}
                          isCommentToggleDisabled={true}
                          // isEditorWithoutVariableSec={false}  //its for old comments
                          getClauseData={(clauseID) =>
                            returnClauseData(clauseID)
                          }
                          //  templateTypeSelected={templateTypeSelected} // for templatetype on hold
                          signatureVariables={signatureVariables}
                          nonTemplateVariableArray={nonTemplateVariableArray}
                          finalJSON={
                            contractVariableJsonData
                              ? contractVariableJsonData
                              : {}
                          }
                          partiesJson={partiesJson ? partiesJson : {}}
                          basicDetailsJson={
                            basicDetailsJson ? basicDetailsJson : {}
                          }
                          setFinalJSON={(finJSON) => {
                            setContractVariableJsonData(finJSON);
                          }}
                          setPartiesJSON={(partiesJson) => {
                            setPartiesJson(partiesJson);
                          }}
                          setNonTemplateVariableArray={(nonTempVarArr) => {
                            setNonTemplateVariableArray(nonTempVarArr);
                          }}
                          setSignatureVariables={(vars) => {
                            setSignatureVariables(vars);
                          }}
                          contractTemplateJsonData={
                            contractTemplateJsonData
                              ? contractTemplateJsonData
                              : null
                          }
                          setContractTemplateJsonData={(val) => {
                            setContractTemplateJsonData(val);
                          }}
                          currentUser="user"
                          clauseLibrary={clauseLibrary}
                          masterLibrary={masterLibrary}
                          isEditable={true}
                          setCommentsJson={(val) => {
                            setCommentJSON(val);
                          }}
                          commentJSON={commentJSON}
                          conditions={conditions}
                          setConditions={(val) => {
                            setConditions(val);
                          }}
                          serializeCall={"serializeTemplate"}
                          allowNontemp={true}
                          allowSignature={true}
                          dataPath="templateVariables"
                          conditiondataPath="ContractConditions"
                          tabForVarId="templateVarTab"
                          pageSize={pageSize}
                        />
                      </RecoilRoot>
                    ) : (
                      <Loading />
                    )}
                  </div>
                ) : value === 2 && publish_enable ? (
                  <>
                    <div className="notificationSettings-add-div">
                      <div>
                        <button
                          type="button"
                          className="modal-open-btn"
                          onClick={onAddNewClick}
                        >
                          Add
                        </button>
                      </div>
                      <div
                        className="Table-body"
                        style={{ overflow: "hidden" }}
                      >
                        <div className=" table-scroll">
                          <MuiThemeProvider theme={customTheme}>
                            <OptimisedMUIDataTable
                              data={templateNotification}
                              columns={generateColumns()}
                              options={options}
                              className="muitable"
                            />
                          </MuiThemeProvider>
                        </div>
                      </div>
                    </div>
                    {addClicked ? (
                      <div className="modal">
                        <div className="modal-content-notification">
                          <div
                            className="Table-body"
                            style={{ overflow: "hidden" }}
                          >
                            <div className=" table-scroll">
                              <MuiThemeProvider theme={customTheme}>
                                <OptimisedMUIDataTable
                                  data={getFilteredNotificationTemplates()}
                                  columns={generateColumnsGlobal()}
                                  options={optionsGlobal}
                                  components={{ Checkbox: CustomCheckbox }}
                                  className="muitable"
                                />
                              </MuiThemeProvider>
                            </div>
                          </div>
                          <div className="footer-modal">
                            <button
                              type="button"
                              className="close-btn"
                              onClick={onCloseClick}
                            >
                              Close
                            </button>
                            {NewOrUpdate === "New" ? (
                              <button
                                type="button"
                                className="Modal-btn noti-upd-btn"
                                onClick={OnAddNodeClose}
                                disabled={!enableAddNoti}
                              >
                                Add New
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="Modal-btn noti-upd-btn"
                                // onClick={OnUpdateNodeClose}
                                disabled={dis}
                              >
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default connect(null, { setcontractTemplateData })(EditorConvertToHTML);