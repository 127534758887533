import Handlebars from "handlebars";
import escapeHtml from "escape-html";
import { Text } from "slate";
import { numberToWords } from "amount-to-words";
import { formatDateToWords } from "./DateToWordsHelper";
//const Handlebars = require("handlebars/runtime");
const reduceOp = function (args, reducer) {
  args = Array.from(args);
  args.pop(); // => options
  var first = args.shift();
  return args.reduce(reducer, first);
};
export const executeHandlebar = (Content, FilledJSON) => {
  Handlebars.registerHelper({
    eq: function () {
      return reduceOp(arguments, (a, b) => a === b);
    },
    ne: function () {
      return reduceOp(arguments, (a, b) => a !== b);
    },
    lt: function () {
      return reduceOp(arguments, (a, b) => a < b);
    },
    gt: function () {
      return reduceOp(arguments, (a, b) => a > b);
    },
    lte: function () {
      return reduceOp(arguments, (a, b) => a <= b);
    },
    gte: function () {
      return reduceOp(arguments, (a, b) => a >= b);
    },
    and: function () {
      return reduceOp(arguments, (a, b) => a && b);
    },
    or: function () {
      return reduceOp(arguments, (a, b) => a || b);
    },
    contains: function () {
      return reduceOp(arguments, (a, b) => a.includes(b));
    },
    notcontains: function () {
      return reduceOp(arguments, (a, b) => !a.includes(b));
    },
    numberToWords: function (currency) {
      if (currency.trim() !== "") return numberToWords(parseInt(currency));
      else return "";
    },
    currencyFormatInThousand: function (currency) {
      if (currency.trim() !== "")
        return Intl.NumberFormat("en-US").format(currency);
      else return "";
    },
    currencyFormat: function (currency) {
      if (currency.trim() !== "")
        return Intl.NumberFormat("en-IN").format(currency);
      else return "";
    },
    dateInWords: function (date) {
      if(date && date.trim() !== "")
      return formatDateToWords(new Date(date), "dateFirst");
    else return "";
    },
  });
  var template = Handlebars.compile(Content);
  return template(FilledJSON);
};
export const convertToHandlebarFormat = (node, variablesJSON, conditions) => {
  let style = "";

  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    if (string === "") {
      string = `<span data-type="non-breakingspace"><br></span>`;
    }
    if (string.includes("  ")) {
      const regexPattern = /[ ]{2,}/g;
      const replacedText = string.replace(regexPattern, (match) => {
        return `<span style="white-space: pre;" data-type="whitespace">${match}</span>`;
      });
      string = replacedText;
    }
    if (string.includes("\t")) {
      const regexPattern = /[\t]{1,}/g;
      const replacedText = string.replace(regexPattern, (match) => {
        return `<span style="white-space: pre;" data-type="whitespace">${match}</span>`;
      });
      string = replacedText;
    }

    if (node.variable) {
      const templateVariable = Object.keys(
        variablesJSON[node.variable["data-path"]]
      ).filter(
        (key) =>
          variablesJSON[node.variable["data-path"]][key].uuid ===
          node.variable["data-variable-id"]
      );
      const varDetails =
        variablesJSON[node.variable["data-path"]][templateVariable[0]];
      if (varDetails.elementType === "money") {
        if (node.variable["currency-in-words"] === "true") {
          string = `{{numberToWords ${node.variable["data-path"]}.${templateVariable[0]}.value}}`;
        } else if (node.variable["currency-in-words"] === "false") {
          if (varDetails.currencyFormat === "thousand") {
            string = `{{currencyFormatInThousand ${node.variable["data-path"]}.${templateVariable[0]}.value}}`;
          } else {
            string = `{{currencyFormat ${node.variable["data-path"]}.${templateVariable[0]}.value}}`;
          }
        } else {
          string = `{{${node.variable["data-path"]}.${templateVariable[0]}.value}}`;
        }
      } else if (varDetails.elementType === "date") {
        if(node.variable["date-in-words"] === "true")
        string = `{{dateInWords ${node.variable["data-path"]}.${templateVariable[0]}.value}}`;
      else
      string = `{{${node.variable["data-path"]}.${templateVariable[0]}.value}}`;
      } else {
        string = `{{${node.variable["data-path"]}.${templateVariable[0]}.value}}`;
      }
    }
    if (node.signature) {
      string = `<span data-type="nonSignature" contentEditable='false' style='display: inline-table;'><span style='width: 50%; display: block; margin: auto; text-align: center; font-weight: bold;'>${node.signature["label"]}</span><span data-type="nonSignature" style='color: white;'>\\{{Sig_es_:signer${node.signature["id"]}:signature}}</span><span data-type2="nonSignature" style='display: block; border: 1px solid; margin: 10px 0px 0px 0px;'></span><span data-type2="nonSignature" style='display: block; margin: 10px 3px 20px 0px;'>Signature</span><span data-type2="nonSignature" style='color: white;'>\\{{N_es_:signer${node.signature["id"]}:fullname}}</span><span data-type2="nonSignature" style='display: block; border: 1px solid; margin: 10px 0px 0px 0px;'></span><span data-type2="nonSignature" style='display: block; margin: 10px 3px 20px 0px;'>Full Name</span><span data-type2="nonSignature" style='color: white;'>\\{{Dte_es_:signer${node.signature["id"]}:date}}</span><span data-type2="nonSignature" style='display: block; border: 1px solid; margin: 10px 0px 0px 0px;'></span><span data-type2="nonSignature" style='display: block; margin: 10px 3px 20px 0px;'>Date Signed</span>${string}</span>`;
      string = `<mark data-type="signature" uuid=${node.signature["id"]} data-label="${node.signature["label"]}">${string}</mark>`;
      // string = <FinalDocSignatureBlock signatureID={node.signature["id"]} />;
    }
    if (node.condition) {
      let condition;
      let conditionJSON;
      if (
        node.condition["data-path"] === "AmendmentConditions" ||
        node.condition["data-path"] === "ContractConditions"
      ) {
        condition = Object.keys(conditions["ContractConditions"]).filter(
          (key) =>
            conditions["ContractConditions"][key].uuid ===
            node.condition["data-condition-id"]
        );
        conditionJSON = conditions["ContractConditions"][condition[0]];
      } else {
        condition = Object.keys(conditions[node.condition["data-path"]]).filter(
          (key) =>
            conditions[node.condition["data-path"]][key].uuid ===
            node.condition["data-condition-id"]
        );
        conditionJSON = conditions[node.condition["data-path"]][condition[0]];
      }
      let conditionString = ``;
      let conditionlogic = ``;
      if (conditionJSON) {
        conditionJSON.logic.map((ele) => {
          let secondAttribute =
            ele.secondvariabletype === true
              ? `${ele.secondAttribute}.value`
              : `"${ele.secondAttribute}"`;
          conditionlogic =
            conditionlogic +
            `(${ele.operator} ${ele.firstvariable}.value ${secondAttribute}) `;
        });
        if (conditionJSON.multiplelogic === "Multi") {
          conditionString = `(${conditionJSON.AndOr} ` + conditionlogic + `)`;
        } else {
          conditionString = conditionlogic;
        }
      }
      conditionString = `{{#if ${conditionString} }}${string}{{/if}}`;
      string = conditionString; //`<mark data-type=${node.condition["data-type"]} uuid=${node.condition["data-condition-id"]} style={{ backgroundColor: "black" }}>${string}</mark>`;
    }
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.superscript) {
      string = `<sup>${string}</sup>`;
    }
    if (node.subscript) {
      string = `<sub>${string}</sub>`;
    }
    if (
      node.color ||
      node.backgroundColor ||
      node.fontSize ||
      node.fontFamily ||
      node.lineHeight
    ) {
      let spanstyle = "";
      if (node.color) {
        spanstyle = spanstyle + `color: ${node.color};`;
      }
      if (node.backgroundColor) {
        spanstyle = spanstyle + `background-color: ${node.backgroundColor};`;
      }
      if (node.fontSize) {
        spanstyle = spanstyle + `font-size:  ${node.fontSize};`;
      }
      if (node.fontFamily) {
        spanstyle = spanstyle + `font-family: ${node.fontFamily};`;
      }
      if (node.lineHeight) {
        spanstyle = spanstyle + `line-height: ${node.lineHeight};`;
      }
      string = `<span style="${spanstyle}">${string}</span>`;
    }
    return string;
  } else {
    if (node.fontSize) {
      style = style + `font-size: ${node.fontSize};`;
    }
    if (node.fontFamily) {
      style = style + `font-family: ${node.fontFamily};`;
    }
  }
  const children = node.children
    .map((n) => convertToHandlebarFormat(n, variablesJSON, conditions))
    .join("");
  if (node.indent) {
    style = style + `margin-left:${node.indent * 0.5}cm;`;
  }
  if (node.rightindent) {
    style = style + `margin-right:${node.rightindent * 0.5}cm;`;
  }
  if (node.lineHeight) {
    style = style + `line-height:${node.lineHeight};`;
  }
  if (node.align) {
    style = style + `text-align:${node.align};`;
  }
  if (node.listStyleType) {
    style = style + `list-style-type:${node.listStyleType};`;
  }
  if (node.listStyleType) {
    if (node.listStyleType === "disc") {
      return `<ul style="${style}"><li>${children}</li></ul>`;
    } else if (node.listStyleType === "decimal") {
      return `<ol style="${style}"><li>${children}</li></ol>`;
    }
  }
  switch (node.type) {
    case "blockquote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "p":
      if (style === "") {
        return `<p style="padding-bottom:0px;padding-top:0px;margin-bottom:0px;margin-top:0px;">${children}</p>`;
      }
      // else if (node.listStyleType) {
      //   if (node.listStyleType === "disc") {
      //     return `<ul><li style="${style}">${children}</li></ul>`;
      //   } else if (node.listStyleType === "decimal") {
      //     return `<ol><li style="${style}">${children}</li></ol>`;
      //   }
      // }
      else {
        return `<p style="${style}padding-bottom:0px;padding-top:0px;margin-bottom:0px;margin-top:0px;">${children}</p>`;
      }
    case "table":
      return `<table>${children}</table>`;
    case "tr":
      return `<tr>${children}</tr>`;
    case "td":
      return `<td>${children}</td>`;
    case "h1":
      return `<h1 style="${style}">${children}</h1>`;
    case "h2":
      return `<h2 style="${style}">${children}</h2>`;
    case "h3":
      return `<h3 style="${style}">${children}</h3>`;
    case "h4":
      return `<h4 style="${style}">${children}</h4>`;
    case "h5":
      return `<h5 style="${style}">${children}</h5>`;
    case "h6":
      return `<h6 style="${style}">${children}</h6>`;
    case "link":
      if (style === "") {
        return `<a href="${escapeHtml(node.url)}">${children}</a>`;
      } else {
        return `<a href="${escapeHtml(
          node.url
        )}" style="${style}">${children}</a>`;
      }
    case "clause":
      return `${children}`;
    case "ol":
      return `<ol style="${style}">${children}</ol>`;
    case "ul":
      return `<ul style="${style}">${children}</ul>`;
    case "li":
      return `<li style="${style}">${children}</li>`;
    case "lic":
      return `<lic style="${style}">${children}</lic>`;
    default:
      return `${children}`;
  }
};
