import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { categoryData: {} };

export const categorySlice = createSlice({
  name: "categoryState",
  initialState: { value: initialStateValue },
  reducers: {
    setCategoryData: (state, action) => {
      state.value.categoryData = action.payload;
    },
  },
});

export const { setCategoryData } = categorySlice.actions;

export default categorySlice.reducer;
