//import { format } from "date-fns";

export default function CommentRow({ comment }) {
  return (
    <div className={"comment-row"}>
      <div className="comment-author-photo">
        <i className="bi bi-person-circle comment-author-photo"></i>
      </div>
      <div>
        <span className="comment-author-name">
          {comment ? comment.author : null}
        </span>
        <span className="comment-creation-time">
          {comment && comment.creationTime
            ? new Date(comment.creationTime).toLocaleString("en-US", {
                localeMatcher: "best fit",
                timeZoneName: "short",
              })
            : null}
        </span>
        <div className="comment-text">{comment ? comment.text : null}</div>
      </div>
    </div>
  );
}
