import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
//import { CodeAlt } from "@styled-icons/material/CodeOff";
import { Highlight } from "@styled-icons/material/Highlight";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Keyboard } from "@styled-icons/material/Keyboard";

import { Check } from "@styled-icons/material/Check";
import Select from "react-select";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";

import {
  insertTableColumn,
  insertTableRow,
  BalloonToolbar,
  deleteColumn,
  deleteRow,
  deleteTable,
  ELEMENT_BLOCKQUOTE,
  getPluginType,
  indent,
  ELEMENT_UL,
  ELEMENT_OL,
  insertTable,
  MARK_BG_COLOR,
  MARK_BOLD,
  MARK_CODE,
  MARK_COLOR,
  MARK_HIGHLIGHT,
  MARK_ITALIC,
  MARK_KBD,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
  outdent,
  AlignToolbarButton,
  ToolbarButton,
  // CodeBlockToolbarButton,
  ColorPickerToolbarDropdown,
  BlockToolbarButton,
  ImageToolbarButton,
  LinkToolbarButton,
  MarkToolbarButton,
  MediaEmbedToolbarButton,
  TableToolbarButton,
  usePlateEditorRef,
  PlateProvider,
  focusEditor,
  toggleIndentList,
  setMarks,
  LineHeightToolbarDropdown,
  ListToolbarButton,
  removeMark,
} from "@udecode/plate";

import Button from "../common/Button";
import {
  isMarkActive,
  toggleVariableMark,
} from "../utils/SlateUtilityFunctions";

//import CommentBar from "./commentBar";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  initializeStateWithAllConditionThreads,
  insertCommentThread,
  shouldAllowNewCommentThreadAtSelection,
} from "../utils/EditorCommentUtils";
import {
  activeCommentThreadIDAtom,
  activeConditionThreadIDAtom,
} from "../utils/CommentState";
import useAddCommentThreadCallback from "../hooks/useAddCommentThreadCallback";
import "./ToolBars.css";
import { Editor, Text, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { FormControlLabel, Switch } from "@mui/material";
import { outdent as coutdent } from "../../PlateEditor/CustomPlugins/outdent";
import { indent as cindent } from "../../PlateEditor/CustomPlugins/indent";
import { toggleList } from "../../PlateEditor/CustomList";
//import { useListToolbarButton, useListToolbarButtonState } from "../../PlateEditor/CustomList";

export const BasicElementToolbarButtons = (props) => {
  const editor = usePlateEditorRef(props.id);
  const [value, setValue] = useState("11");
  const [FFValue, setFFValue] = useState("sans-serif");
  useEffect(() => {
    setValue(props.activeFont);
    setFFValue(props.activeFontFamily);
  }, [props.activeFont, props.activeFontFamily]);
  const handleFontSize = (e) => {
    setValue(e);
    //Editor.addMark(editor, "fontSize", `${e.target.value}pt`);
    setMarks(editor, { fontSize: `${e}pt` });
    props.setActiveFont(e);
  };
  // const onFontSizeKeydown = (e) => {
  //   debugger
  //   if(e.keyCode === 13){
  //     setValue(e.target.value);
  //    // setFontSize(e.target.value);
  //     //Editor.addMark(editor, "fontSize", `${e.target.value}pt`);
  //    // setMarks(editor, { fontSize: `${e.target.value}pt` });
  //     props.setActiveFont(e.target.value);
  //   }
  // }
  const handleFontFamily = (e) => {
    setFFValue(e.target.value);
    Editor.addMark(editor, "fontFamily", `${e.target.value}`);
    props.setActiveFontFamily(e.target.value);
  };

  const onFontSizeBlur = (e) => {
    const regex = /^[0-9]+[.]?[0-9]{0,2}$/;
    if (regex?.test(e.target.value)) {
      setValue(e.target.value);
      setMarks(editor, { fontSize: `${e.target.value}pt` });
      props.setActiveFont(e.target.value);
    } else {
      toast.error("This is not a valid number");
    }
  };

  const FontSize = () => {
    const options = [
      "8",
      "9",
      "10",
      "11",
      "12",
      "14",
      "16",
      "18",
      "20",
      "22",
      "24",
      "26",
      "28",
      "30",
      "32",
      "36",
      "48",
      "72",
    ];
    return (
      <>
        <TextInput
          options={options}
          Component="input"
          trigger=""
          spacer=""
          offsetX={310}
          offsetY={20}
          className="font-size-input"
          onSelect={handleFontSize}
          onBlur={onFontSizeBlur}
          defaultValue={value}
        />
        {/* value={fontSize} */}
      </>
    );
  };
  const FontFamily = () => {
    return (
      <select
        value={FFValue}
        onChange={(e) => handleFontFamily(e)}
        title="Font Family"
        tooltip="Font Family"
        className="toolbar-select"
      >
        <option selected value="sans-serif">
          sans-serif
        </option>
        <option value="arial">Arial</option>
        <option value="courier">courier</option>
        <option value="trebuchet ms">trebuchet ms</option>
        <option value="palatino linotype">palatino linotype</option>
        <option value="calibri">Calibri</option>
        <option value="times new roman">Times New Roman</option>
        <option value="verdana">verdana</option>
        <option value="comic sans ms">comic sans ms</option>
        <option value="impact">impact</option>
        <option value="cursive">cursive</option>
        <option value="fantasy">fantasy</option>
        <option value="monospace">monospace</option>
        <option value="tahoma">tahoma</option>
        <option value="helvetica">helvetica</option>
        <option value="garamond">garamond</option>
      </select>
    );
  };
  const handleHeading = (headingType) => {
    let node = editor.children[editor.selection.anchor.path[0]];
    if (node.type === headingType) {
      Transforms.setNodes(editor, {
        type: "p",
      });
    } else {
      Transforms.setNodes(editor, {
        type: headingType,
      });
      Transforms.unsetNodes(editor, ["fontSize"], {
        at: editor.selection.anchor.path,
      });
    }
  };
  return (
    <div className="toolBarGroup-basic-buttons">
      <div style={{ marginRight: "10px" }} className="icon-width">
        <FontSize />
        {/* <BlockToolbarButton
          type={getPluginType(editor, MARK_FONT_SIZE)}
          icon={<i className="icon honeicon"></i>}
          tooltip={{ content: "Font Size" }}
        /> */}
      </div>
      <div className="icon-width" style={{ marginRight: "10px" }}>
        <FontFamily />
      </div>
      <div className="toolBarGroup" style={{ margin: "5px 0 0 100px" }}>
        <div className="icon-width">
          <button
            className=" editor-icon-background"
            onClick={() => handleHeading("h1")}
          >
            <i className="icon honeicon" />
          </button>
        </div>
        <div className="icon-width">
          <button
            className=" editor-icon-background"
            onClick={() => handleHeading("h2")}
          >
            <i className="icon htwoicon" />
          </button>
        </div>
        <div className="icon-width">
          <button
            className=" editor-icon-background"
            onClick={() => handleHeading("h3")}
          >
            <i className="icon hthreeicon" />
          </button>
        </div>
        <div className="icon-width">
          <button
            className=" editor-icon-background"
            onClick={() => handleHeading("h4")}
          >
            <i className="icon hfouricon" />
          </button>
        </div>
        <div className="icon-width">
          <button
            className=" editor-icon-background"
            onClick={() => handleHeading("h5")}
          >
            <i className="icon hfiveicon" />
          </button>
        </div>
        {/* <div className="icon-width">
         
          <button
            className=" editor-icon-background"
            onClick={() => handleHeading("h6")}
          >
            <i className="icon hsixicon" />
          </button>
        </div> */}
      </div>

      {/* <CodeBlockToolbarButton
        type={getPluginType(editor, ELEMENT_CODE_BLOCK)}
        icon={<Code />}
      /> */}
    </div>
  );
};

export const IndentToolbarButtons = (props) => {
  const editor = usePlateEditorRef(props.id);
  return (
    <div className="toolBarGroup">
      {/* <div style={{ marginLeft: "10px" }} className="icon-width">
        <ToolbarButton
          // onMouseDown={editor && getPreventDefaultHandler(outdent, editor)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            outdent(editor);
            focusEditor(editor);
          }}
          icon={<i className="icon indentdecreaseicon"></i>}
          tooltip={{ content: "Indent Decrease" }}
        />
      </div>
      <div className="icon-width">
        <ToolbarButton
          // onMouseDown={editor && getPreventDefaultHandler(indent, editor)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            indent(editor);
            focusEditor(editor);
          }}
          icon={<i className="icon indentincreaseicon"></i>}
          tooltip={{ content: "Indent Increase" }}
        />
      </div> */}
      <div style={{ marginLeft: "10px" }} className="icon-width">
        <ToolbarButton
          // onMouseDown={editor && getPreventDefaultHandler(outdent, editor)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            coutdent(editor, { margintype: "left" });
            //coutdent(editor, { margintype: "right" });
            focusEditor(editor);
          }}
          icon={<i className="icon indentdecreaseicon"></i>}
          tooltip={{ content: "Indent Decrease" }}
        />
      </div>
      <div className="icon-width">
        <ToolbarButton
          // onMouseDown={editor && getPreventDefaultHandler(indent, editor)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            cindent(editor, { margintype: "left" });
            //cindent(editor, { margintype: "right" });
            focusEditor(editor);
          }}
          icon={<i className="icon indentincreaseicon"></i>}
          tooltip={{ content: "Indent Increase" }}
        />
      </div>
    </div>
  );
};

export const ListToolbarButtons = (props) => {
  const editor = usePlateEditorRef(props.id);
  // const state = useListToolbarButtonState({ nodeType:ELEMENT_OL });
  // const toolBarState = useListToolbarButton(state);

  return (
    <div className="margin-tool">
      <div>
        <div className="icon-width">
          {/* <ListToolbarButton
            type={getPluginType(editor, ELEMENT_UL)}
            icon={<i className="icon bulleticon"></i>}
            tooltip={{ content: "Bullet" }}
            // onMouseDown={(e) => {
            //   e.preventDefault();
            //   toggleIndentList(editor, {
            //     listStyleType: "disc",
            //   });
            // }}
          /> */}

          <ListToolbarButton
            icon={<i className="icon bulleticon"></i>}
            type={getPluginType(editor, ELEMENT_UL)}
          />

          {/* <ToolbarButton
            tooltip={{ content: "Bullet" }}
            onMouseDown={(e) => {
              e.preventDefault();
              toggleIndentList(editor, {
                listStyleType: "disc",
              });
             
              // Transforms.insertFragment(
              //   editor,
              //   [
              //     {
              //       type: "ul",
              //       //listStyleType: "decimal",
              //       children: [
              //         {
              //           text: "",
              //           li:true
              //         },
              //       ],
              //     },
              //   ],
              //   // {
              //   //   at: [editor.selection.anchor.path[0], editor.selection.anchor.path[1]],
              //   // }
              // );
              
            }}
            icon={<i className="icon bulleticon"></i>}
          /> */}
        </div>
      </div>
      <div style={{ width: "20px" }} className="icon-width">
        {/* <ListToolbarButton
          type={getPluginType(editor, ELEMENT_OL)}
          icon={<i className="icon numberbulleticon"></i>}
          tooltip={{ content: "Number Bullet" }}
          // onMouseDown={(e) => {
          //   e.preventDefault();
          //   toggleIndentList(editor, {
          //     listStyleType: "decimal",
          //   });
          // }}
        /> */}
        <ListToolbarButton
          type={getPluginType(editor, ELEMENT_OL)}
          icon={<i className="icon numberbulleticon"></i>}
          tooltip={{ content: "Number Bullet" }}
        />
        {/* <ToolbarButton
          onMouseDown={(e) => {
            e.preventDefault();
            toggleIndentList(editor, {
              listStyleType: "decimal",
            });

            // Transforms.insertFragment(
            //   editor,
            //   [
            //     {
            //       type: "ol",
            //      // listStyleType: "decimal",
            //       children: [
            //         {
            //           text: "",
            //           li:true
            //         },
            //       ],
            //     },
            //   ],
            //   // {
            //   //   at: [editor.selection.anchor.path[0], editor.selection.anchor.path[1]],
            //   // }
            // );
            
          }}

          icon={<i className="icon numberbulleticon"></i>}
          tooltip={{ content: "Number Bullet" }}
        /> */}
      </div>
    </div>
  );
};

export const AlignToolbarButtons = (props) => {
  const editor = usePlateEditorRef(props.id);
  const [lineHeightValue, setLineHeightValue] = useState("1");
  const handleLineHeight = (e) => {
    setLineHeightValue(e.target.value);
    let selection = editor.selection;
    Editor.addMark(editor, "lineHeight", `${e.target.value}`);
  };
  return (
    <div className="toolBarGroup">
      <div className="icon-width">
        <AlignToolbarButton
          value="left"
          icon={<i className="icon leftalignicon"></i>}
          tooltip={{ content: "Left Align" }}
        />
      </div>
      <div className="icon-width">
        <AlignToolbarButton
          value="center"
          icon={<i className="icon centeralignicon"></i>}
          tooltip={{ content: "Center Align" }}
        />
      </div>
      <div className="icon-width">
        <AlignToolbarButton
          value="right"
          icon={<i className="icon rightalignicon"></i>}
          tooltip={{ content: "Right Align" }}
        />
      </div>
      <div className="icon-width">
        <AlignToolbarButton
          value="justify"
          icon={<i className="icon alignjustifyicon"></i>}
          tooltip={{ content: "Justify Content" }}
        />
      </div>
      {/* <div className=" "> */}
      <LineHeightToolbarDropdown
        icon={<i className="icon lineheighticon"></i>}
        tooltip={{ content: "Line Spacing" }}
      />
      {/* <div style={{ marginRight: "8px", marginLeft: "8px" }}>
        <select
          value={lineHeightValue}
          onChange={(e) => handleLineHeight(e)}
          title="Line Height"
          tooltip="Line Height"
          className="toolbar-select"

        >
          <option selected value="1">
            1
          </option>
          <option value="1.2">1.2</option>
          <option value="1.5">1.5</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </div> */}
    </div>
  );
};

export const CustomButtons = (props) => {
  // let editorID = useEventPlateId("focus"); //usePlateId();
  // usePlateEditorState
  const editor = usePlateEditorRef(props.id);

  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);
  const addCommentThread = useAddCommentThreadCallback();

  const setActiveConditionThreadID = useSetRecoilState(
    activeConditionThreadIDAtom
  );

  const activeConditionThreadID = useRecoilValue(activeConditionThreadIDAtom);

  const [allConditionThreads, setallConditionThreads] = useState(null);

  const [finalJSON, setFinalJSON] = useState({});
  useEffect(() => {
    setFinalJSON(props.finalJSON);
  }, [props.finalJSON]);

  useEffect(() => {
    (async () => {
      if (editor) {
        let abc = await initializeStateWithAllConditionThreads(editor);
        setallConditionThreads(Array.from(abc));
      }
    })();
  }, [editor]);

  const onInsertComment = useCallback(() => {
    const newCommentThreadID = insertCommentThread(editor, addCommentThread);
    setActiveCommentThreadID(newCommentThreadID);
    props.onChangeHandler(editor.children);
  }, [editor, addCommentThread, setActiveCommentThreadID]);

  const isCurenID = (element) => element.uuid === activeConditionThreadID;

  const onPrevClickCondition = () => {
    // props.setActiveConditionID(null);

    let currentID = allConditionThreads.findIndex(isCurenID);
    let nextID = 0;
    if (currentID === 0) {
      nextID = allConditionThreads.length - 1;
    } else nextID = currentID - 1;

    const textNodeEntry = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) =>
        Text.isText(n) &&
        n.condition &&
        n.condition["data-condition-id"] === allConditionThreads[nextID].uuid,
    }).next().value;
    //setConditionNode(textNodeEntry[0]);
    setActiveConditionThreadID(allConditionThreads[nextID].uuid);
    Transforms.setSelection(editor, textNodeEntry[1]);
    ReactEditor.focus(editor);
  };
  const onNextClickCondition = () => {
    if (activeConditionThreadID !== null) {
      let currentID = allConditionThreads.findIndex(isCurenID);
      let nextID = 0;
      if (currentID === allConditionThreads.length - 1) {
        nextID = 0;
      } else nextID = currentID + 1;
      const textNodeEntry = Editor.nodes(editor, {
        at: [],
        mode: "lowest",
        match: (n) =>
          Text.isText(n) &&
          n.condition &&
          n.condition["data-condition-id"] === allConditionThreads[nextID].uuid,
      }).next().value;
      //setConditionNode(textNodeEntry[0]);
      setActiveConditionThreadID(allConditionThreads[nextID].uuid);
      Transforms.setSelection(editor, textNodeEntry[1]);
      ReactEditor.focus(editor);
    }
  };

  const VariableButton = () => {
    if (editor !== null) {
      return (
        <Button
          active={isMarkActive(editor, "variable")}
          format={"variable"}
          onMouseDown={(e) => {
            e.preventDefault();
            const selection = editor.selection;
            if (
              selection.anchor.path[selection.anchor.path.length - 1] !==
              selection.focus.path[selection.focus.path.length - 1]
            ) {
              removeMark(editor, { key: "fontSize" });
              removeMark(editor, { key: "fontFamily" });
              setMarks(editor, { fontSize: `${props.activeFont}pt` });
              Editor.addMark(editor, "fontFamily", `${props.activeFontFamily}`);
            }
            let lastChild = false,
              firstChild = false;
            let nextChild = null;
            const [varJson] = toggleVariableMark(
              editor,
              "variable",
              finalJSON,
              props.dataPath
            );
            let lastButOneNode = null;
            for (
              let index = 0;
              index < editor.selection.anchor.path?.length - 1;
              index++
            ) {
              lastButOneNode = lastButOneNode
                ? lastButOneNode.children[editor.selection.anchor.path[index]]
                : editor.children[editor.selection.anchor.path[index]];
            }
            if (
              lastButOneNode?.children?.length ===
              editor.selection.anchor.path[
                editor.selection.anchor.path.length - 1
              ] +
                1
            ) {
              lastChild = true;
            }
            if (lastChild === false) {
              nextChild =
                editor.children[editor?.selection?.anchor?.path[0]]?.children[
                  editor?.selection?.anchor?.path[1] + 1
                ];
            }
            if (editor.selection.anchor.path[1] === 0) {
              firstChild = true;
            }
            if (varJson === null) {
              toast.error(" Includes Special Characters,Space ", {
                className: "toaststyle",
              });
            } else {
              const curn = editor.children[editor.selection.anchor.path[0]];
              if (
                curn.type !== "table" &&
                curn.type !== "ol" &&
                curn.type !== "ul"
              ) {
                const prevNode =
                  editor.children[editor?.selection?.anchor?.path[0]]?.children[
                    editor?.selection?.anchor?.path[1] - 1
                  ];
                const pNS =
                  prevNode && prevNode?.text?.includes(" ") ? true : false;
                if (selection && !pNS && prevNode) {
                  //firstChild
                  Transforms.insertNodes(
                    editor,
                    {
                      text: " ",
                      fontFamily: props.activeFontFamily,
                      fontSize: props.activeFont + "pt",
                    },
                    {
                      at: [selection.anchor.path[0], selection.anchor.path[1]],
                      select: true,
                    }
                  );
                }

                if (lastChild || nextChild?.text?.charAt(0) !== " ") {
                  //lastChild
                  Transforms.insertNodes(
                    editor,
                    {
                      text: " ",
                      fontFamily: props.activeFontFamily,
                      fontSize: props.activeFont + "pt",
                    },
                    {
                      at: [
                        editor.selection.anchor.path[0],
                        editor.selection.anchor.path[1] + 1,
                      ],
                      select: true,
                    }
                  );
                }
              } else if (curn.type === "table") {
                let befPaths = selection?.anchor?.path?.map((selcPath, ind) => {
                  if (ind === selection?.anchor?.path?.length - 1) {
                    return selcPath + 1;
                  } else return selcPath;
                });
                let afPaths = editor?.selection?.anchor?.path?.map(
                  (selcPath, ind) => {
                    if (ind === editor?.selection?.anchor?.path?.length - 1) {
                      return selcPath + 1;
                    } else return selcPath;
                  }
                );
                Transforms.insertNodes(
                  editor,
                  {
                    text: " ",
                    fontFamily: props.activeFontFamily,
                    fontSize: props.activeFont + "pt",
                  },
                  {
                    at: befPaths,
                    //  [
                    //   selection.anchor.path[0],
                    //   selection.anchor.path[4] + 1,
                    // ],
                    select: true,
                  }
                );
                Transforms.insertNodes(
                  editor,
                  {
                    text: " ",
                    fontFamily: props.activeFontFamily,
                    fontSize: props.activeFont + "pt",
                  },
                  {
                    at: afPaths,
                    // [
                    //   editor.selection.anchor.path[0],
                    //   editor.selection.anchor.path[4] + 1,
                    // ],
                    select: true,
                  }
                );
              } else {
                let atBefore = [];
                let atAfter = [];
                let prevNode = null;
                let pNS = null;

                for (
                  let index = 0;
                  index < selection.anchor.path.length;
                  index++
                ) {
                  if (index === selection.anchor.path.length - 1) {
                    atBefore.push(selection.anchor.path[index] + 1);
                  } else atBefore.push(selection.anchor.path[index]);
                }
                for (
                  let index = 0;
                  index < editor.selection.anchor.path.length;
                  index++
                ) {
                  if (index === editor.selection.anchor.path.length - 1) {
                    atAfter.push(editor.selection.anchor.path[index] + 1);
                    prevNode =
                      prevNode.children[
                        editor?.selection?.anchor?.path[index] - 1
                      ];
                  } else {
                    prevNode = prevNode
                      ? prevNode.children[
                          editor?.selection?.anchor?.path[index]
                        ]
                      : editor.children[editor?.selection?.anchor?.path[index]];
                    atAfter.push(editor.selection.anchor.path[index]);
                  }
                }
                pNS = prevNode && prevNode?.text?.includes(" ") ? true : false;
                if (selection && !pNS && prevNode) {
                  Transforms.insertNodes(
                    editor,
                    {
                      text: " ",
                      fontFamily: props.activeFontFamily,
                      fontSize: props.activeFont + "pt",
                    },
                    {
                      at: selection.anchor.path,
                      select: true,
                    }
                  );
                }
                // Transforms.insertNodes(
                //   editor,
                //   {
                //     text: " ",
                //     fontFamily: props.activeFontFamily,
                //     fontSize: props.activeFont + "pt",
                //   },
                //   {
                //     at: atAfter,
                //   }
                // );
              }

              setFinalJSON(varJson);
              props.setFinalJSON(varJson);
            }
          }}
          enabled={
            props.selectedTextCondition !== "" && !props.isVariableDisabled
          }
          style={{ color: "#48336e" }}
          tooltip={{ content: "Variables" }}
        >
          <i className="icon variableicon"></i>
        </Button>
      );
    }
  };

  const ConditionButton = () => {
    if (editor !== null) {
      return (
        <Button
          format={"condition"}
          active={isMarkActive(editor, "condition")}
          onMouseDown={(e) => {
            e.preventDefault();
            props.setIsConditionOpen(true);
          }}
          enabled={
            props.selectedTextCondition !== "" && !props.isConditionDisabled
              ? true
              : false
          }
          tooltip={{ content: "Condition" }}
        >
          <i className="icon conditionicon"></i>
        </Button>
      );
    }
  };
  const ClauseButton = () => {
    if (editor !== null) {
      return (
        <Button
          className="clausebtn"
          styles={{ whiteSpace: "nowrap" }}
          format={"clause"}
          active={isMarkActive(editor, "clause")}
          onMouseDown={(e) => {
            e.preventDefault();
            props.setisClauseOpen(true);
          }}
          enabled={!props.isClauseDisabled}
          tooltip={{ content: "Clause" }}
        >
          <i className="icon clauseicon"></i>
          {/* <Icon icon={format} /> */}
        </Button>
      );
    }
  };
  const CommentBar = () => {
    return (
      <ToolBarButtonComment
        //isActive={true}
        enabled={
          !shouldAllowNewCommentThreadAtSelection(editor, props.selection) ===
            true || props.isNegotiationDisabled === true
            ? false
            : true
        }
        onMouseDown={(event) => {
          event.preventDefault();
          onInsertComment();
        }}
      />
    );
  };

  const ConditionToggle = () => {
    return (
      <FormControlLabel
        value="left"
        control={
          <Switch
            color="warning"
            size="small"
            disabled={props.isConditionToggleDisabled}
            checked={props.conditionToggle}
            onChange={() => props.setConditionToggle(!props.conditionToggle)}
          />
        }
        label="Condition"
        labelPlacement="left"
      />
    );
  };
  const CommentToggle = () => {
    return (
      <FormControlLabel
        value="left"
        control={
          <Switch
            color="warning"
            size="small"
            disabled={props.isCommentToggleDisabled}
            checked={props.commentToggle}
            onChange={() => props.setCommentToggle(!props.commentToggle)}
          />
        }
        label="Comment"
        labelPlacement="left"
      />
    );
  };

  const ConditionToggleButtons = () => {
    return (
      <>
        <button
          onClick={() => onPrevClickCondition()}
          style={{ margin: "auto" }}
          toolbutton
        >
          {"<"}
        </button>
        <button
          onClick={() => onNextClickCondition()}
          style={{ margin: "auto" }}
          toolbutton
        >
          {">"}
        </button>
      </>
    );
  };

  return (
    <>
      <div className="toolBarGroup">
        <div className="icon-width">
          <VariableButton />
        </div>
        <div className="icon-width">
          <ConditionButton />
        </div>
        <div className="icon-width">
          <ClauseButton />
        </div>
        <div className="icon-width">
          <CommentBar />
        </div>
        <div className="icon-width-toggle">
          <ConditionToggle />
        </div>
        <div className="icon-width-toggle">
          <CommentToggle />
        </div>
      </div>
    </>
  );
};
export const PageSize = (props) => {
  const [pageSize, setPageSize] = useState("A4");
  const pageTypes = [
    { value: "A3", label: "A3" },
    { value: "A4", label: "A4" },
    { value: "A5", label: "A5" },
    { value: "Legal", label: "Legal" },
    { value: "Letter", label: "Letter" },
  ];
  useEffect(() => {
    if (props?.pageSize) setPageSize(props.pageSize);
  }, []);
  const onChangeOfPageType = (val) => {
    setPageSize(val.value);
    props.setPageSize(val.value);
  };
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "2px",
      display: "flex",
      borderRadius: "5px",
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };
  return (
    <Select
      styles={selectStyles}
      menuPlacement="bottom"
      value={{
        value: pageSize,
        label: pageSize,
      }}
      onChange={(value) => {
        onChangeOfPageType(value);
      }}
      options={pageTypes}
      isMulti={false}
      tooltip="Page Size"
    />
  );
};

function ToolBarButtonComment(props) {
  const { label, ...otherProps } = props;
  return (
    <Button
      className="toolbar-btn"
      {...otherProps}
      tooltip={{ content: "Comments" }}
      format={"Comment"}
    >
      <i className="icon commentsicon" style={{ color: "#48336e" }}></i>
      {label}
    </Button>
  );
}

export const BasicMarkToolbarButtons = (props) => {
  const editor = usePlateEditorRef(props.id);
  let colorArray = [];
  colorArray.push({ name: "Red", value: "#FF0000", isBrightColor: true });
  return (
    <div className="toolBarGroup">
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_BOLD)}
          icon={<i className="icon boldicon"></i>}
          tooltip={{ content: "Bold" }}
        />
      </div>
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_ITALIC)}
          icon={<i className="icon italicsicon"></i>}
          tooltip={{ content: "Italic" }}
        />
      </div>
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_UNDERLINE)}
          icon={<i className="icon underlineicon"></i>}
          tooltip={{ content: "Underline" }}
        />
      </div>
      <div>
        <ColorPickerToolbarDropdown
          // colors={colorArray}
          pluginKey={MARK_COLOR}
          icon={<i className="icon fontcoloricon"></i>}
          selectedIcon={<Check />}
          tooltip={{ content: "Text color" }}
          {...props}
        />
      </div>
      <div>
        <ColorPickerToolbarDropdown
          pluginKey={MARK_BG_COLOR}
          icon={<i className="icon highlighticon"></i>}
          selectedIcon={<Check />}
          tooltip={{ content: "Highlight color" }}
          {...props}
        />
      </div>
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_STRIKETHROUGH)}
          icon={<i className="icon strikeouticon"></i>}
          tooltip={{ content: "Strike Out" }}
        />
      </div>
      {/* <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_CODE)}
          icon={<i className="icon codeicon"></i>}
          tooltip={{ content: "Code" }}
        />
      </div> */}
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_SUPERSCRIPT)}
          clear={getPluginType(editor, MARK_SUBSCRIPT)}
          icon={<i className="icon superscripticon"></i>}
          tooltip={{ content: "Super Script" }}
        />
      </div>
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_SUBSCRIPT)}
          clear={getPluginType(editor, MARK_SUPERSCRIPT)}
          icon={<i className="icon subscripticon"></i>}
          tooltip={{ content: "Sub Script" }}
        />
      </div>
    </div>
  );
};

export const KbdToolbarButton = (props) => {
  const editor = usePlateEditorRef(props.id);
  return (
    <div className="toolBarGroup">
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_KBD)}
          icon={<Keyboard color="black" />}
        />
      </div>
    </div>
  );
};

export const HighlightToolbarButton = (props) => {
  const editor = usePlateEditorRef(props.id);
  return (
    <div>
      <MarkToolbarButton
        type={getPluginType(editor, MARK_HIGHLIGHT)}
        icon={<Highlight color="black" />}
      />
    </div>
  );
};

export const TableToolbarButtons = () => (
  <div className="margin-tool">
    <div className="icon-width">
      <TableToolbarButton
        icon={<i className="icon allbordericon"></i>}
        transform={insertTable}
        tooltip={{ content: "Insert Table" }}
      />
    </div>
    <div className="icon-width">
      <TableToolbarButton
        icon={<i className="icon nobordericon"></i>}
        transform={deleteTable}
        tooltip={{ content: "Remove Table" }}
      />
    </div>
    <div className="icon-width">
      <TableToolbarButton
        icon={<i className="icon borderdownicon"></i>}
        transform={insertTableRow}
        tooltip={{ content: "Add Row" }}
      />
    </div>
    <div className="icon-width">
      <TableToolbarButton
        icon={<i className="icon bordertopicon"></i>}
        transform={deleteRow}
        tooltip={{ content: "Remove Row" }}
      />
    </div>
    <div className="icon-width">
      <TableToolbarButton
        icon={<i className="icon borderlefticon"></i>}
        transform={insertTableColumn}
        tooltip={{ content: "Add column" }}
      />
    </div>
    <div className="icon-width">
      <TableToolbarButton
        icon={<i className="icon borderrighticon"></i>}
        transform={deleteColumn}
        tooltip={{ content: "Remove Column" }}
      />
    </div>
  </div>
);

export const MarkBallonToolbar = (props) => {
  const editor = usePlateEditorRef(props.id);

  const arrow = false;
  const theme = "dark";
  const tooltip = {
    arrow: true,
    delay: 0,
    duration: [200, 0],
    hideOnClick: false,
    offset: [0, 17],
    placement: "top",
  };

  return (
    <BalloonToolbar
      popperOptions={{
        placement: "top",
      }}
      theme={theme}
      arrow={arrow}
    >
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_BOLD)}
          icon={<i className="icon boldicon"></i>}
          tooltip={{ content: "Bold", ...tooltip }}
        />
      </div>
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_ITALIC)}
          icon={<i className="icon italicsicon"></i>}
          tooltip={{ content: "Italic", ...tooltip }}
        />
      </div>
      <div>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_UNDERLINE)}
          icon={<i className="icon underlineicon"></i>}
          tooltip={{ content: "Underline", ...tooltip }}
        />
      </div>
    </BalloonToolbar>
  );
};

export const ToolbarButtons = (props) => {
  const editor = usePlateEditorRef(props.id);
  return (
    <>
      <BasicElementToolbarButtons {...props} />
      <BasicMarkToolbarButtons {...props} />

      <PlateProvider {...props}></PlateProvider>

      <AlignToolbarButtons {...props} />
      <ListToolbarButtons {...props} />
      <IndentToolbarButtons {...props} />

      {/* <BlockToolbarButton
            type={getPluginType(editor, ELEMENT_BLOCKQUOTE)}
            icon={<i className="icon qouteicon"></i>}
            tooltip={{ content: "Qoute" }}
          /> */}

      {/* <LinkToolbarButton
            icon={<i className="icon linkicon"></i>}
            {...props}
            tooltip={{ content: "Link" }}
          /> */}

      <ImageToolbarButton
        icon={<i className="icon imageicon"></i>}
        {...props}
        tooltip={{ content: "Image" }}
      />

      {/* <MediaEmbedToolbarButton
            icon={<i className="icon vedioicon"></i>}
            {...props}
            tooltip={{ content: "Video" }}
          /> */}

      <div>
        <TableToolbarButtons {...props} tooltip={{ content: "Table" }} />
      </div>

      {/* <KbdToolbarButton /> */}

      <CustomButtons {...props} />
      <PageSize {...props} />
    </>
  );
};
