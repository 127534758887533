import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import "./SearchField.css";

const SEARCH_BUTTON_EDGE = 35;
const searchFieldInputStyle = {};

const commonInputStyle = css`
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  color: #555;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  padding: 0px 0px 0px 10px;
  border: none;
  height: 25px;
  margin: 2px 0 0 0;
  outline: none;
  &:focus {
    ${"" /* box-shadow: 0 0 5px */}
    ${(props) =>
      props.formBorderColorOnFocus ? props.formBorderColorOnFocus : "#48336e"};
    border: none
      ${(props) =>
        props.formBorderColorOnFocus
          ? props.formBorderColorOnFocus
          : "#48336e"};
  }
  ${(props) => {
    return (
      props.invalid &&
      props.shouldValidate &&
      props.touched &&
      css`
        border: none
          ${(props) => (props.errorTextColor ? props.errorTextColor : "red")} !important;
      `
    );
  }}
`;
const Input = styled.input`
  ${commonInputStyle}
`;

const searchFieldButtonStyle = {
  width: SEARCH_BUTTON_EDGE - 2,
  outline: "none",
  backgroundColor: "white",
  cursor: "pointer",
  boxSizing: "border-box",
  appearance: "none",
  margin: "2%",
};

const SearchIcon = (props) => {
  const iconEdge = Math.ceil(SEARCH_BUTTON_EDGE * 0.6);
  const searchIconStyle = {
    fill: "#727272",
    fontSize: "20px",
  };
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={iconEdge}
      height={iconEdge}
      viewBox="0 0 635 635"
      style={searchIconStyle}
      disabled={props.disabled}
    >
      <g>
        <path
          d="M255.108,0C119.863,0,10.204,109.66,10.204,244.904c0,135.245,109.659,244.905,244.904,244.905
          c52.006,0,100.238-16.223,139.883-43.854l185.205,185.176c1.671,1.672,4.379,1.672,5.964,0.115l34.892-34.891
          c1.613-1.613,1.47-4.379-0.115-5.965L438.151,407.605c38.493-43.246,61.86-100.237,61.86-162.702
          C500.012,109.66,390.353,0,255.108,0z M255.108,460.996c-119.34,0-216.092-96.752-216.092-216.092
          c0-119.34,96.751-216.091,216.092-216.091s216.091,96.751,216.091,216.091C471.199,364.244,374.448,460.996,255.108,460.996z"
        />
      </g>
    </svg>
  );
};

export default function SearchField(props) {
  //
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = useState([]);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    setValue(props.val);
  }, [props]);

  useEffect(() => {
    setData(props.data);
    setFilteredData(props.data);
  }, []);

  const TableList = () => (
    <>
      <div className={`${isHidden ? "list-example-hidden" : "list-example"}`}>
        <div className="list-header">
          <ul className="custom-ul">
            <li className="custom-li" style={{ fontWeight: "bolder" }}>
              EMAIL
            </li>
            <li className="custom-li" style={{ fontWeight: "bolder" }}>
              FULLNAME
            </li>
          </ul>
        </div>
        <div className="list-body">
          {filteredData.map((obj, index) => (
            <ul className="custom-ul" key={index}>
              <li
                className="custom-li"
                onMouseDown={(ev) => {
                  setIsHidden(true);
                  setValue(
                    obj.label !== null
                      ? obj.label + " " + obj.last_name
                      : obj.userEmail
                  );
                  props.getSelectedUserObj(
                    obj,
                    obj.label !== null
                      ? obj.label + " " + obj.last_name
                      : obj.userEmail
                  );
                }}
              >
                {obj.userEmail}
              </li>
              <li
                className="custom-li"
                onMouseDown={(ev) => {
                  setIsHidden(true);
                  setValue(
                    obj.label !== null
                      ? obj.label + " " + obj.last_name
                      : obj.userEmail
                  );
                  props.getSelectedUserObj(obj);
                }}
              >
                {obj.label === null
                  ? obj.userEmail
                  : obj.label + " " + obj.last_name}
              </li>
            </ul>
          ))}
        </div>
      </div>
    </>
  );

  const onSearchFieldChange = (value) => {
    setValue(value);
    setIsHidden(false);
    let filterData = data.filter((e) => {
      return (e.label + " " + e.last_name)
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setFilteredData(filterData);
  };

  return (
    <div
      className="react-search-field-demo text-form"
      disabled={props.disabled}
    >
      <div>
        <div className="searchField-container">
          <Input
            id={props.id}
            style={searchFieldInputStyle}
            autoComplete="off"
            disabled={props.disabled}
            onClick={() => {
              setIsHidden(false);
            }}
            onChange={(e) => {
              onSearchFieldChange(e.target.value);
              props.onChange(e);
            }}
            onKeyDown={(e) => {
              //onSearchFieldChange(e.target.value);
            }}
            onBlur={(e) => {
              setIsHidden(true);
            }}
            placeholder={props.placeholder}
            type="text"
            value={value}
          />
          <button
            type="button"
            aria-label="search button"
            style={searchFieldButtonStyle}
            disabled={props.disabled}
            onClick={() => {
              setIsHidden(false);
            }}
            onBlur={() => setIsHidden(true)}
          >
            <SearchIcon disabled={props.disabled} />
          </button>
        </div>
        <TableList />
      </div>
    </div>
  );
}
