import React from "react";
import "./admin-submenu.css";
import DepartmentImage from "../../../assets/icons/department.png";
import { NavLink } from "react-router-dom";

export default function Design() {
  return (
    <div className="admin-sub-menu-div">
      <div className="MarginDiv">
        <div className="grid-container">
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/category">
              <div className="icon-dept-card">
                <i className="fa fa-th-large admin-icon color-1 "></i>
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Categories</label>
              </div>
              <div className="admin-text-div background-1">
                <label className="admin-h4"> Categories</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/Clause">
              <div className="icon-dept-card">
                <i className="fa fa-file-invoice admin-icon-clause color-2"></i>{" "}
              </div>

              <div className="grid-label ">
                {" "}
                <label className="admin-label-text">Clause Libraries</label>
              </div>
              <div className="admin-text-div background-2">
                <label className="admin-h4"> Clause Libraries</label>
              </div>
            </NavLink>
          </div>

          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/contractTemplates">
              <div className="icon-dept-card">
                <i className="fa fa-file-contract  admin-icon-contract-Template color-3"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Contract Templates</label>
              </div>
              <div className="admin-text-div background-3">
                <label className="admin-h4"> Contract Templates</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/contractTypes">
              <div className="icon-dept-card">
                <i className="fa fa-file-signature admin-icon color-4"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Contract Types</label>
              </div>
              <div className="admin-text-div background-4">
                <label className="admin-h4"> Contract Types</label>
              </div>
            </NavLink>
          </div>

          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/masterlibrary">
              <div className="icon-dept-card">
                <i className="fa fa-book  admin-icon color-5"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Master Library</label>
              </div>
              <div className="admin-text-div background-5">
                <label className="admin-h4">Master Library</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/Amendment">
              <div className="icon-dept-card">
                <i className="fa fa-edit  admin-icon color-6"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Amendments</label>
              </div>
              <div className="admin-text-div background-6">
                <label className="admin-h4"> Amendments</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/departments">
              <div className="icon-dept-card">
                <i className="fa fa-sitemap  admin-icon-dept color-7"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Departments</label>
              </div>
              <div className="admin-text-div background-7">
                <label className="admin-h4"> Departments</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/userManagement">
              <div className="icon-dept-card">
                <i className="fa fa-users-cog  admin-icon color-8"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">User Management</label>
              </div>
              <div className="admin-text-div background-8">
                <label className="admin-h4"> User Management</label>
              </div>
            </NavLink>
          </div>

          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/Termination">
              <div className="icon-dept-card">
                <i className="fa fa-window-close admin-icon color-9"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Terminations</label>
              </div>
              <div className="admin-text-div background-9">
                <label className="admin-h4"> Terminations</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/location">
              <div className="icon-dept-card">
                <i className="fa fa-globe-americas  admin-icon color-10"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Groups</label>
              </div>
              <div className="admin-text-div background-10">
                <label className="admin-h4">Groups</label>
              </div>
            </NavLink>
          </div>

          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/roleManagement">
              <div className="icon-dept-card">
                <i className="fa fa-user-tag admin-icon color-11"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Role Management</label>
              </div>
              <div className="admin-text-div background-11">
                <label className="admin-h4">Role Management</label>
              </div>
            </NavLink>
          </div>

          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/Integrations">
              <div className="icon-dept-card">
                <i className="fa fa-signature admin-icon color-12"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Integrations</label>
              </div>
              <div className="admin-text-div background-12">
                <label className="admin-h4"> Integrations</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/Notification">
              <div className="icon-dept-card">
                <i className="fa fa-bell admin-icon color-13"></i>{" "}
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Notifications</label>
              </div>
              <div className="admin-text-div background-13">
                <label className="admin-h4"> Notifications</label>
              </div>
            </NavLink>
          </div>
          <div className="grid-items">
            <NavLink className="class-link" to="/Admin/CompanySettings">
              <div className="icon-dept-card">
                <i className="fa fa-gear admin-icon color-14"></i>
              </div>

              <div className="grid-label ">
                <label className="admin-label-text">Company Settings</label>
              </div>
              <div className="admin-text-div background-14">
                <label className="admin-h4"> Company Settings</label>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}