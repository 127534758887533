import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getCrudApi } from "../../../webServiceCalls/webServiceCall";
import { validateToken } from "../../../Helpers/validateToken";
import Loading from "../../../Loading/Loading";
import DocuSignLogo from "../../../assets/images/DocuSign_offical_logo.png";
import AdobeSignLogo from "../../../assets/images/adobesign_offical-logo.png";
import SalesForcesLogo from "../../../assets/images/salesforces_offical_logo.png";
import FreshworksLogo from "../../../assets/images/freshworks_offical_logo.png";
import FreshteamLogo from "../../../assets/images/freshteam_offical_logo.png";
import EmudhraLogo from "../../../assets/images/emudhra_offical_logo.png";
import WorkdayLogo from "../../../assets/images/workday_offical_logo.png";
import PandocLogo from "../../../assets/images/pandoc_offical_logo.png";
import FreshSaleSuitsLogo from "../../../assets/images/freshsalessuits_offical_logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function ManageIntegrations(props) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getIntegrationsData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi("integration/all", {});
    await result.then(function (res) {
      dataResult = res;
    });

    if (dataResult) {
      setTableData(dataResult);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (location.state) {
      setTableData(location.state.tableData);
    } else {
      getIntegrationsData();
    }
  }, []);

  const getIntegration = (e, integrationType) => {
    e.preventDefault();
    if (integrationType === "DocuSign") {
      navigate("/Admin/Integrations/DocuSignIntergration", {
        state: {
          signingMethod: null,
          selectedData: tableData.filter(
            (element) => element.integrationType === integrationType
          )[0],
          type: "DocuSign",
        },
      });
    } else if (integrationType === "AdobeSign") {
      navigate("/Admin/Integrations/AdobeSignIntergration", {
        state: {
          type: "AdobeSign",
        },
      });
    } else if (integrationType === "SalesForce") {
      navigate("/Admin/Integrations/SalesForcesIntegration", {
        state: {},
      });
    } else if (integrationType === "emudhra") {
      navigate("/Admin/Integrations/emudhraIntegration", {
        state: {},
      });
    } else if (integrationType === "freshSalesSuites") {
      navigate("/Admin/Integrations/FreshSaleSuitsIntegration", {
        state: {},
      });
    } else if (integrationType === "freshWorks") {
      navigate("/Admin/Integrations/FreshworkIntegration", {
        state: {},
      });
    } else if (integrationType === "FreshTeam") {
      navigate("/Admin/Integrations/FreshTeamIntegration", {
        state: {},
      });
    } else if (integrationType === "PanDoc") {
      navigate("/Admin/Integrations/PanDocIntegration", {
        state: {},
      });
    } else if (integrationType === "WorkDay") {
      navigate("/Admin/Integrations/WorkDayIntegration", {
        state: {},
      });
    }
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="signature-grid-container">
            <NavLink
              className="sign-link"
              onClick={(e) => getIntegration(e, "DocuSign")}
            >
              <div className="signature-grid-items">
                <div className="sign-img">
                  <LazyLoadImage
                    effect="blur"
                    src={DocuSignLogo}
                    className="Docusign-logo"
                    alt="DocuSignLogo"
                  />
                </div>
              </div>
            </NavLink>
            <NavLink
              className="sign-link"
              onClick={(e) => getIntegration(e, "AdobeSign")}
            >
              <div className="signature-grid-items">
                <div className="sign-img">
                  <LazyLoadImage
                    effect="blur"
                    src={AdobeSignLogo}
                    className="adobeSign-logo"
                    alt="AdobeSignLogo"
                  />
                </div>
              </div>
            </NavLink>
            <div class="sign-tooltip">
              <span class="sign-tooltiptext ">Launching Soon</span>
              <NavLink
                className="sign-link"
                onClick={(e) => getIntegration(e, "SalesForce")}
              >
                <div className="signature-grid-items">
                  <div className="sign-img">
                    <LazyLoadImage
                      effect="blur"
                      src={SalesForcesLogo}
                      className="saleforces-logo"
                      alt="SalesForcesLogo"
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="sign-tooltip">
              <span class="sign-tooltiptext ">Launching Soon</span>
              <NavLink
                className="sign-link"
                onClick={(e) => getIntegration(e, "emudhra")}
              >
                <div className="signature-grid-items">
                  <div className="sign-img">
                    <LazyLoadImage
                      effect="blur"
                      src={EmudhraLogo}
                      className="emudhra-logo"
                      alt="EmudhraLogo"
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="sign-tooltip">
              <span class="sign-tooltiptext ">Launching Soon</span>
              <NavLink
                className="sign-link"
                onClick={(e) => getIntegration(e, "freshSalesSuites")}
              >
                <div className="signature-grid-items">
                  <div className="sign-img">
                    <LazyLoadImage
                      effect="blur"
                      src={FreshSaleSuitsLogo}
                      className="freshsalesSuites-logo"
                      alt="FreshSaleSuitsLogo"
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="sign-tooltip">
              <span class="sign-tooltiptext ">Launching Soon</span>
              <NavLink
                className="sign-link"
                onClick={(e) => getIntegration(e, "freshWorks")}
              >
                <div className="signature-grid-items">
                  <div className="sign-img">
                    <LazyLoadImage
                      effect="blur"
                      src={FreshworksLogo}
                      className="freshworks-logo"
                      alt="FreshworksLogo"
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="sign-tooltip">
              <span class="sign-tooltiptext ">Launching Soon</span>
              <NavLink
                className="sign-link"
                onClick={(e) => getIntegration(e, "FreshTeam")}
              >
                <div className="signature-grid-items">
                  <div className="sign-img">
                    <LazyLoadImage
                      effect="blur"
                      src={FreshteamLogo}
                      className="freshteam-logo"
                      alt="FreshteamLogo"
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="sign-tooltip">
              <span class="sign-tooltiptext ">Launching Soon</span>
              <NavLink
                className="sign-link"
                onClick={(e) => getIntegration(e, "PanDoc")}
              >
                <div className="signature-grid-items">
                  <div className="sign-img">
                    <LazyLoadImage
                      effect="blur"
                      src={PandocLogo}
                      className="pandoc-logo"
                      alt="PandocLogo"
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="sign-tooltip">
              <span class="sign-tooltiptext ">Launching Soon</span>
              <NavLink
                className="sign-link"
                onClick={(e) => getIntegration(e, "WorkDay")}
              >
                <div className="signature-grid-items">
                  <div className="sign-img">
                    <LazyLoadImage
                      effect="blur"
                      src={WorkdayLogo}
                      className="workday-logo"
                      alt="WorkdayLogo"
                    />
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </>
  );
}
