import { Hotkeys } from "@udecode/plate";
import { outdent } from "./outdent";
import { indent } from "./indent";
import { Transforms } from "slate";

export const onKeyDownIndent = (editor) => (e) => {
  if (e.defaultPrevented) return;
  if (Hotkeys.isTab(editor, e)) {
    e.preventDefault();
    if (editor?.selection && editor.selection.anchor?.offset === 0) {
      indent(editor, { margintype: "left" });
    } else {
      Transforms.insertNodes(editor, {
        text: "\u0009".toString(),
      });
    }
  }

  // if (Hotkeys.isUntab(editor, e)) {
  //   e.preventDefault();
  //   outdent(editor);
  // }
};
