import {
    createPluginFactory,
    ELEMENT_DEFAULT,
    getPluginType,
} from '@udecode/plate';
import { onKeyDownIndent } from './onKeyDownIndent';
import { withIndent, withRightIndent } from './withIndent';

export const KEY_INDENT = 'indent';
export const RIGHT_KEY_INDENT = 'rightindent';

export const createCustomPlugin = createPluginFactory({
    key: KEY_INDENT,
    withOverrides: withIndent,
    handlers: {
        onKeyDown: onKeyDownIndent,
    },
    options: {
        offset: 36,
        unit: 'pt',
    },
    then: (editor, { options: { offset, unit } = {} }) => ({
        inject: {
            props: {
                nodeKey: KEY_INDENT,
                styleKey: 'marginLeft',
                validTypes: [getPluginType(editor, ELEMENT_DEFAULT)],
                transformNodeValue: ({ nodeValue }) => nodeValue * offset + unit,
            },
        },
    }),
});


