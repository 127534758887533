import React from "react";
import {
 
  XAxis,
  YAxis,

  Tooltip,
  Legend,
  BarChart,
  Bar,
  Cell,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";

const colors = [
  "#1D907Fff",
  "#3F66B0ff",
  "#4F96D5ff",
  "#FBCD08ff",
  "#8FC742ff",
  "#F05623ff",
  "#9F4098ff",
  "#F7901Eff",
];

export default function BarChartComp({ data }) {
  return (
    <ResponsiveContainer width="100%" height={170}>
    <BarChart
      
      
      data={data}
      margin={{
        top: 20,
        left:5,
        right:5
      }}
    >
      <XAxis dataKey="name" />
      <CartesianGrid strokeDasharray="3 3" />
     
      <Bar
        dataKey={"value"}
        fill="black"
        label={{ position: "top" }}
        radius={[10, 10, 0, 0]}
        // shape={<TriangleBar />}
        barSize={30}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} 
          fill={colors[index]}
           />
        ))}
      </Bar>
    </BarChart>
    </ResponsiveContainer>
  );
}
