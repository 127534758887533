import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import { getCrudApi } from "../../../webServiceCalls/webServiceCall";
import { validateToken } from "../../../Helpers/validateToken";
import { mapStatus } from "../../../Helpers/hierarchyHelper";
import "./ChildTable.css";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import Loading from "../../../Loading/Loading";
import { toast } from "react-toastify";
import Select from "react-select";
import * as XLSX from "xlsx";

export default function MasterLibrary() {
  const [tableData, setTableData] = useState([]);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [childTableName, setChildTableName] = useState("");
  const [page, setPage] = useState(0);
  const [excelUploadData, setExcelUploadData] = useState(null);
  const [tableType, setTableType] = useState({ label: "Manual", value: 1 });
  const [fileName, setfileName] = useState("");
  const [dynamicColumns, setDynamicColumns] = useState([
    {
      name: "",
      dataType: "Text",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const tblCreationMethods = [
    { label: "Manual", value: 1 },
    { label: "Excel Upload", value: 2 },
  ];

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "library_table_id",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const selectStyles = {
    // menuPortalTarget={document.body}
    // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "2px",
      display: "flex",
      borderRadius: "5px",
      width: "95%",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                to="/Admin/MasterLibrary/Table"
                state={{
                  tableData: tableMeta.rowData,
                  libraryName: tableMeta.rowData[2],
                  columns: null,
                  selectedTableData: tableData.filter(
                    (element) =>
                      element.library_table_id === tableMeta.rowData[1]
                  )[0],
                  excelData: null,
                }}
              >
                <button>
                  <i className="fa fa-edit custom-iconformodal" />
                </button>
              </Link>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);
    columnsArray.push({
      name: "library_table_id",
      label: "Master Library ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "library_table_name",
      label: "NAME",
    });

    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  state === "Active"
                    ? "table-active-cell"
                    : "table-inactive-cell"
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    columnsArray.push({
      name: "status_id",
      label: "Status ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "library_table",
      label: "Library",
      options: {
        display: false,
      },
    });
    return columnsArray;
  };

  const getLibraryData = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi("library/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    setTableData(dataResult);
    setIsLoading(false);
  };

  const OnAddTableOpen = (e) => {
    setAddModalOpen(true);
  };

  const OnAddTableClose = (e) => {
    let valid = true;
    dynamicColumns.forEach((element) => {
      if (element.name.trim() === "") {
        valid = false;
      }
    });
    if (
      (childTableName.trim().length > 0 &&
        dynamicColumns.length > 0 &&
        valid === true) ||
      (childTableName.trim().length > 0 &&
        tableType.value === 2 &&
        excelUploadData !== null)
    ) {
      setAddModalOpen(false);
      navigate("/Admin/MasterLibrary/Table", {
        state: {
          libraryName: childTableName,
          tableData: null,
          columns: dynamicColumns,
          selectedTableData: null,
          excelData: tableType.value === 2 ? excelUploadData : null,
        },
      });
    } else {
      toast.error("Please enter mandatory fields");
      //setChildTableName("");
      // setDynamicColumns([
      //   {
      //     name: "",
      //     dataType: "Text",
      //   },
      // ]);
    }
  };

  // const onDataTypeChange = (value, i) => {
  //   let columns = [...dynamicColumns];
  //   columns[i] = {
  //     ...columns[i],
  //     dataType: value,
  //   };
  //   setDynamicColumns(columns);
  // };

  const onColumnNameChange = (value, i) => {
    let columns = [...dynamicColumns];
    columns[i] = {
      ...columns[i],
      name: value,
    };
    setDynamicColumns(columns);
  };

  const onAddColumnToChildTable = () => {
    let columns = [...dynamicColumns];
    let newColumn = {};
    newColumn.name = "";
    newColumn.dataType = "Text";
    columns.push(newColumn);
    setDynamicColumns(columns);
  };

  const removeColumn = (e, index) => {
    let columns = [...dynamicColumns];
    columns.splice(index, 1);
    setDynamicColumns(columns);
  };

  useEffect(() => {
    getLibraryData();
    const interval = setInterval(
      getLibraryData,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, []);

  const onChangeOfUploadType = (value) => {
    setTableType(value);
  };

  const onCloseModal = () => {
    setAddModalOpen(false);
    setChildTableName("");
    setDynamicColumns([
      {
        name: "",
        dataType: "Text",
      },
    ]);
  };

  const handleLibraryFile = (file) => {
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    if (allowedTypes.includes(file.type)) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((d) => {
        setExcelUploadData(d);
      });
      setfileName(file.name);
    } else {
      setfileName("");
      toast.error("Please select valid file type");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={(e) => OnAddTableOpen(e)}
            >
              Add
            </button>
          </div>
          <div className="Table-body">
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={mapStatus(tableData)}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}

      {AddModalOpen ? (
        <div className="modal">
          <div className="modal-content-master ">
            <div className="header-modal">
              <h5 className="header-title">Master Library</h5>
            </div>
            <div className="div-display">
              <label className="label-class">
                Master Library Name{" "}
                <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <input
                type="text"
                placeholder="Master Library Name"
                className="textbox-input_1"
                onChange={(e) => setChildTableName(e.target.value)}
                required
              />
            </div>
            <div className="div-display">
              <label className="label-class">
                Creation Method
                <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <Select
                styles={selectStyles}
                menuPlacement="bottom"
                value={tableType}
                onChange={(value) => {
                  onChangeOfUploadType(value);
                }}
                options={tblCreationMethods}
                isMulti={false}
              />
            </div>
            {tableType.value === 1 ? (
              <>
                {dynamicColumns.map((e, i) => (
                  <div className="div-display" key={i}>
                    <label className="label-class">
                      Column Name{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>
                    <div className="colArr">
                      <input
                        type="text"
                        placeholder="Column Name"
                        value={e.name}
                        className="textbox-input_1"
                        onChange={(e) => onColumnNameChange(e.target.value, i)}
                        required
                      />
                      <div>
                        <button
                          type="button"
                          className="App-btn arrClose"
                          onClick={(e) => removeColumn(e, i)}
                        >
                          <i
                            className="fa fa-close"
                            style={{ color: "white" }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div style={{ textAlign: "center", margin: "10px" }}>
                  <button
                    type="button"
                    className="add-row"
                    onClick={(e) => onAddColumnToChildTable(e)}
                  >
                    Add Column
                  </button>
                </div>
              </>
            ) : (
              <div className="div-display">
                <input
                  type="file"
                  accept=".xls, .xlsx, .csv"
                  multiple={false}
                  onChange={(e) => {
                    handleLibraryFile(e.target.files[0]);
                  }}
                  id="excelFile"
                  //value={fileName}
                />
              </div>
            )}
            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={onCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={OnAddTableClose}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
