import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import OptimisedMUIDataTable from "../manageContracts/OptimisedMUIDataTable";
import { Link } from "react-router-dom";
//import CustomToolbarSelect from './CustomToolbarSelect'
import ModalRequest from "./ModalRequest";
import { useSelector } from "react-redux";
import { convertHierarchy } from "../../Helpers/hierarchyConverter";
import { getCrudApi } from "../../webServiceCalls/webServiceCall";
import { validateToken } from "../../Helpers/validateToken";
import "./ModalRequest.css";
import Loading from "../../Loading/Loading";
import { toast } from "react-toastify";
export default function ManageRequests(props) {
  const [tableData, setTableData] = useState([]);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [Approvalcnt, setApprovalCnt] = useState(0);
  const [Submitcnt, setSubmitCnt] = useState(0);
  const [Draftedcnt, setDraftedCnt] = useState(0);
  const [tableDataforcnt, setTableDataforcnt] = useState([]);
  const [AllCnt, setAllCnt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  const contractTypeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );
  const contractTemplateData = useSelector(
    (state) => state.contractTemplateState.value.contractTemplateData
  );
  const statusData = useSelector((state) => state.statusState.value.statusData);

  const SelectMappingForContractTemplate = () => {
    let treeHierarchy = contractTemplateData.filter((ct)=> ct.isActive === 1);;
    var old = JSON.stringify(treeHierarchy).replace(
      /"contractTemplateId"/g,
      '"value"'
    );
    if (treeHierarchy == null) {
      return [];
    }
    old = old.replace(/"contractTemplateName"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };
  const SelectMappingForCategory = () => {
    let treeHierarchy = convertHierarchy(
      categoryData.filter((element) => element.status === "Active"),
      "catogeryTypeId",
      "parentCatogeryTypeId"
    );
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"catogeryTypeId"/g,
      '"value"'
    );
    old = old.replace(/"catogeryType"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };

  const SelectMappingForContractType = () => {
    let treeHierarchy = convertHierarchy(
      contractTypeData.filter((element) => element.status === "Active"),
      "contractTypeId",
      "parentContractTypeId"
    );
    if (treeHierarchy == null) {
      return [];
    }
    var old = JSON.stringify(treeHierarchy).replace(
      /"contractTypeId"/g,
      '"value"'
    );
    old = old.replace(/"contractType"/g, '"label"');
    var newArray = JSON.parse(old);
    return newArray;
  };
  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "VIEW",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                style={{ textDecoration: "none" }}
                to="/Request/View"
                state={{
                  templateSelected: null,
                  template_version: tableMeta.rowData[3],
                  tableData: tableMeta.rowData,
                  templateType: null,
                  contractData: tableData.filter(
                    (element) => element.contractId === tableMeta.rowData[1]
                  )[0],
                  requestName: tableMeta.rowData[2],
                }}
              >
                <button
                  className="view-icon"
                  onClick={() => onDisplayDynamicForm(tableMeta, value)}
                >
                  <i className="fa fa-eye custom-iconformodal" />
                </button>
              </Link>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);
    columnsArray.push({
      name: "contractId",
      label: "Contract ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractName",
      label: "CONTRACT NAME",
    });
    columnsArray.push({
      name: "contractTemplateVersionId",
      label: "Version ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractTypeId",
      label: "Contract Type ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "catogeryTypeId",
      label: "Category Type ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractFormData",
      label: "Contract Form Data",
      options: {
        display: false,
      },
    });

    columnsArray.push({
      name: "categoryType",
      label: "CATEGORY TYPE",
    });
    columnsArray.push({
      name: "contractType",
      label: "CONTRACT TYPE",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "Status ID",
      options: {
        display: false,
      },
    });

    columnsArray.push({
      name: "isApprovalRequired",
      label: "Approval Required",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractTemplate",
      label: "Contract Template",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "templateTypeFlag",
      label: "TEMPLATE TYPE",
      options: {
        display: false,
      },
    });

    columnsArray.push({
      name: "contractRequestFlagName",
      label: "SOURCE",
    });
    columnsArray.push({
      name: "formStateFlagName",
      label: "CURRENT STATE",
    });
    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          let color;
          const setStatus = (dataIndex) => {
            switch (dataIndex) {
              case "Drafted":
                color = "#1D907Fff";
                break;
              case "Submitted":
                color = "#3F66B0ff";
                break;
              case "Approved":
                color = "#4F96D5ff";
                break;
              case "Reopened":
                color = "#FBCD08ff";
                break;
              case "Termination InProgress":
                color = "#8FC742ff";
                break;
              case "Amended":
                color = "#F05623ff";

                break;
              case "Negotiation":
                color = "#9F4098ff";
                break;
              case "Executed":
                color = "#F7901Eff";
                break;
              case "Terminated":
                color = "#76ccba";

                break;
            }
            return color;
          };
          const state = dataIndex;
          return (
            <>
              <p
                className={"table-contract-request-status"}
                style={{ background: setStatus(state) }}
              >
                {state}
              </p>
            </>
          );
        },
      },
    });

    return columnsArray;
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const generateJSONForTable = (templateData) => {
    let arrayOfJSON = [];
    if (templateData != null) {
      templateData.forEach((template) => {
        let newJSON = {};
        Object.keys(template).forEach((element) => {
          newJSON[element] = template[element];
        });

        categoryData.some((category) => {
          if (category.catogeryTypeId === template.catogeryTypeId) {
            newJSON.categoryType = category.catogeryType;
            return true;
          }
          return false;
        });

        contractTypeData.some((contractType) => {
          if (contractType.contractTypeId === template.contractTypeId) {
            newJSON.contractType = contractType.contractType;
            return true;
          }
          return false;
        });

        statusData.some((status) => {
          if (status.status_id === template.contractStatus) {
            newJSON.status = status.status;
            return true;
          }
          return false;
        });

        arrayOfJSON.push(newJSON);
      });
    }
    return arrayOfJSON;
  };
  const onDisplayDynamicForm = (tableMeta, value) => {};

  const OnAddRequestOpen = async (e) => {
    let result = getCrudApi("contract/check", {});
    await result.then(function (res) {
      if (res) {
        setAddModalOpen(true);
      } else {
        toast.error(
          "Attention: Your subscription contract limit has been reached. Please contact our service provider for further assistance."
        );
      }
    });
  };

  const OnAddRequestClose = (
    requestTemplateDetails,
    templateType,
    requestName
  ) => {
    setAddModalOpen(false);
    navigate("/Request/View", {
      state: {
        templateSelected: requestTemplateDetails,
        template_version: null,
        tableData: null,
        templateType: templateType,
        contractData: null,
        requestName: requestName,
      },
    });
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
      MUIDataTableHeadCell: {
        data: {
          fontWeight: "600",
        },
      },
    },
  });

  useEffect(() => {
    let Approval = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "approved";
    });
    setApprovalCnt(Approval.length);

    let Drafted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "drafted";
    });
    setDraftedCnt(Drafted.length);

    let submitted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "submitted";
    });
    setSubmitCnt(submitted.length);

    let allCnt = tableDataforcnt.filter(
      (element) => element.contractRequestFlag === 1
    ).length;
    setAllCnt(allCnt);
  });

  const handleapproval = (e) => {
    let Approval = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "approved";
    });

    setTableData(Approval);
  };

  const handlesubmit = (e) => {
    let submitted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "submitted";
    });
    setTableData(submitted);
  };
  const handle_drafted = (e) => {
    let Drafted = tableDataforcnt.filter(function (iterator) {
      if (iterator) return iterator.status.toLowerCase() === "drafted";
    });

    setTableData(Drafted);
  };
  const handle_all = (e) => {
    setTableData(tableDataforcnt);
  };
  function filterDataByFilterName(name, data) {
    data = data.filter(function (iterator) {
      if (iterator)
        return iterator.contractStatusName.toLowerCase() === name.toLowerCase();
    });
    return data;
  }
  const getRequestDetails = async () => {
    setIsLoading(true);
    let dataResult;
    validateToken();
    let result = getCrudApi("contract/all/table", {});
    await result.then(function (res) {
      dataResult = res;
    });
    setTableData(
      location?.state?.filterName !== "" &&
        location?.state?.filterName !== null &&
        location?.state?.filterName !== undefined
        ? location?.state?.filterName === "All"
          ? generateJSONForTable(
              dataResult
                ? dataResult.filter((element) => element.formStateFlag === 1)
                : null
            )
          : filterDataByFilterName(
              location?.state?.filterName,
              generateJSONForTable(
                dataResult
                  ? dataResult.filter((element) => element.formStateFlag === 1)
                  : null
              )
            )
        : generateJSONForTable(
            dataResult
              ? dataResult.filter((element) => element.formStateFlag === 1)
              : null
          )
    );
    setTableDataforcnt(
      generateJSONForTable(
        dataResult
          ? dataResult.filter((element) => element.formStateFlag === 1)
          : null
      )
    );
    setIsLoading(false);
  };
  useEffect(() => {
    getRequestDetails();
    const interval = setInterval(
      getRequestDetails,
      process.env.REACT_APP_INTERVAL_2_REFRESH
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="status-main-request">
            <div className="status-div">
              <button
                type="button"
                className="status-btn-request All-request "
                onClick={handle_all}
              >
                <div className="status-text">All</div>
                <div className="count-div">
                  <label className="all-request-label"> {AllCnt}</label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-request approved-request"
                onClick={handleapproval}
              >
                <div className="status-text">Approved</div>
                <div className="count-div">
                  <label className="approve-request-label">
                    {" "}
                    {Approvalcnt}
                  </label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-request drafted-request"
                onClick={handle_drafted}
              >
                <div className="status-text">Drafted</div>
                <div className="count-div">
                  <label className="draft-request-label ">{Draftedcnt}</label>
                </div>
              </button>
              <button
                type="button"
                className="status-btn-request submitted-request"
                onClick={handlesubmit}
              >
                <div className="status-text">Submitted</div>
                <div className="count-div">
                  <label className="submit-request-label ">{Submitcnt}</label>
                </div>
              </button>
            </div>
            <div className="request-add-Btn">
              <button
                type="button"
                className="add-contracts-btn"
                onClick={(e) => OnAddRequestOpen(e)}
              >
                Add
              </button>
            </div>
          </div>

          <div className="Table-body">
            <div className=" table-scroll ">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={tableData}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
            {/* <div>
                <button
                  type="button"
                  className="add-contracts-btn"
                  onClick={(e) => OnAddRequestOpen(e)}
                >
                  Add new Request
                </button>
              </div> */}
          </div>
        </>
      )}

      <ModalRequest
        open={AddModalOpen}
        RequestCategory={SelectMappingForCategory()}
        contractTypeOptions={SelectMappingForContractType()}
        contractTemplateName={SelectMappingForContractTemplate()}
        ModalOpen={setAddModalOpen}
        ButtonTitle="Add"
        RequestClosed={(templateName, templateType, requestName) =>
          OnAddRequestClose(templateName, templateType.value, requestName)
        }
      />
    </>
  );
}
