import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { convertHierarchy } from "../../../Helpers/hierarchyConverter";
import SyfedTreeSelect from "syfed-tree-select";
import "./Termination.css";
import Loading from "../../../Loading/Loading";
import { toast } from "react-toastify";
export default function TerminationTemplate(props) {
  // const [tableData, setTableData] = useState([]);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  // const [EditTemplateOpen, setEditTemplateOpen] = useState(false);
  const [terminationTemplateName, setTerminationTemplateName] = useState("");
  // const [selectedContractType, setSelectedContractType] = useState("");
  // const [selectedRenewalType, setSelectedRenewalType] = useState("");
  const [tableJSON, setTableJSON] = useState([]);
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const [CategoryTypeSelect, setCategoryTypeSelect] = useState([]);
  const [contractTypeSelected, setcontractTypeSelected] = useState(null);
  const [categoryTypeSelected, setcategoryTypeSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "terminationTemplateId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  const contractTypeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );
  const terminationTemplateData = useSelector(
    (state) => state.terminateTemplateState.value.terminateTemplateData
  );

  const onChangeOfContractTreeSelect = (value) => {
    setcontractTypeSelected(value);
  };
  const onChangeOfCategoryTreeSelect = (value) => {
    setcategoryTypeSelected(value);
  };
  const GetTemplateData = async () => {
    setIsLoading(true);
    const newData = generateJSONForTable(terminationTemplateData);
    setTableJSON(newData);
    setIsLoading(false);
  };

  const generateJSONForTable = (templateData) => {
    let arrayOfJSON = [];
    templateData.forEach((template) => {
      let newJSON = {};
      Object.keys(template).forEach((element) => {
        newJSON[element] = template[element];
      });

      categoryData.some((category) => {
        if (category.catogeryTypeId === template.catogeryTypeId) {
          newJSON.categoryType = category.catogeryType;
          return true;
        }
        return false;
      });
      contractTypeData.some((contractType) => {
        if (contractType.contractTypeId === template.contractTypeId) {
          newJSON.contractType = contractType.contractType;
          return true;
        }
        return false;
      });
      arrayOfJSON.push(newJSON);
    });
    return arrayOfJSON;
  };

  const OnAddTemplateOpen = (e) => {
    setAddModalOpen(true);
  };
  const OnAddTemplateClose = (e) => {
    setAddModalOpen(false);
    if (terminationTemplateName.length > 0) {
      sessionStorage.removeItem("tdraftId");
      sessionStorage.removeItem("tverId");
      sessionStorage.removeItem("tempId");
      navigate("/Admin/Termination/Edit", {
        state: {
          tableData: null,
          templateName: terminationTemplateName,
          categorySelected: categoryTypeSelected,
          contractTypeSelected: contractTypeSelected,
          categoryType: CategoryTypeSelect,
          contractType: ContractTypeSelect,
          publish_enable: false,
          templateData: null,
        },
      });
    } else {
      toast.error("Please Enter the mandatory fields");
    }
  };

  const SelectMappingForCategory = () => {
    if (categoryData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        categoryData.filter((element) => element.status === "Active"),
        "catogeryTypeId",
        "parentCatogeryTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"catogeryTypeId"/g,
        '"value"'
      );
      old = old.replace(/"catogeryType"/g, '"label"');
      var newArray = JSON.parse(old);
      setCategoryTypeSelect(newArray);
    } else setCategoryTypeSelect([]);
  };
  const OnCloseModal = () => {
    setAddModalOpen(false);
    setTerminationTemplateName("");
    setcontractTypeSelected("");
    setcategoryTypeSelected("");
  };

  const SelectMappingForContractType = () => {
    if (contractTypeData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        contractTypeData.filter((element) => element.status === "Active"),
        "contractTypeId",
        "parentContractTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"contractTypeId"/g,
        '"value"'
      );
      old = old.replace(/"contractType"/g, '"label"');
      var newArray = JSON.parse(old);
      setContractTypeSelect(newArray);
      setFilterContractTypeSelect(newArray);
    } else {
      setContractTypeSelect([]);
      setFilterContractTypeSelect([]);
    }
  };
  function filterDataCategoryContractType() {
    let category = null;
    var newArray = filterContractTypeSelect;
    if (categoryTypeSelected !== null) {
      category = newArray?.filter((con) => {
        if (con.catogeryTypeId === categoryTypeSelected) return true;
        else return false;
      });
      setContractTypeSelect(category);
    }
  }
  useEffect(() => {
    SelectMappingForCategory();
    SelectMappingForContractType();
  }, [contractTypeData]);

  useEffect(() => {
    filterDataCategoryContractType();
  }, [categoryTypeSelected]);

  const generateColumns = () => {
    // if (tableJSON && tableJSON.length > 0) {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button onClick={() => onTemplateEditorOpen(tableMeta)}>
                <i className="fa fa-edit custom-iconformodal" />
              </button>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);

    columnsArray.push({
      name: "terminationTemplateId",
      label: "Contract Template ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "terminationTemplateName",
      label: "CONTRACT TEMPLATE NAME",
    });
    columnsArray.push({
      name: "categoryType",
      label: "CATEGORY TYPE",
    });
    columnsArray.push({
      name: "contractTypeId",
      label: "Contract Type Id",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractType",
      label: "CONTRACT TYPE",
    });
    columnsArray.push({
      name: "catogeryTypeId",
      label: "Catogery Type Id",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "draftTerminationTemplateId",
      label: "Draft Template ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "publishedTerminationTemplateVersionId",
      label: "Template Version ID",
      options: {
        display: false,
      },
    });
    return columnsArray;
    // }
    return [];
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });

  const onTemplateEditorOpen = async (tableMeta) => {
    setIsLoading(true);
    sessionStorage.removeItem("tdraftId");
    sessionStorage.removeItem("tverId");
    sessionStorage.removeItem("tempId");
    if (tableMeta.rowData[7])
      sessionStorage.setItem("tdraftId", tableMeta.rowData[7]);
    if (tableMeta.rowData[8])
      sessionStorage.setItem("tverId", tableMeta.rowData[8]);
    sessionStorage.setItem("tempId", tableMeta.rowData[1]);
    navigate("/Admin/Termination/Edit", {
      state: {
        tableData: tableMeta.rowData,
        templateName: tableMeta.rowData[2],
        categorySelected: tableMeta.rowData[6],
        contractTypeSelected: tableMeta.rowData[4],
        categoryType: CategoryTypeSelect,
        contractType: ContractTypeSelect,
        publish_enable: true,
        templateData: terminationTemplateData.filter(
          (element) => element.terminationTemplateId === tableMeta.rowData[1]
        )[0],
      },
    });

    setIsLoading(false);
  };

  useEffect(() => {
    GetTemplateData();
  }, []);

  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {" "}
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={(e) => OnAddTemplateOpen(e)}
            >
              Add
            </button>
          </div>
          <div className="Table-body">
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  title={"Contract Templates"}
                  data={tableJSON}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
            {AddModalOpen ? (
              <div className="modal">
                <div className="modal-content">
                  <div>
                    <div className="header-modal">
                      <h5 className="header-title">Termination</h5>
                    </div>
                    <div className="div-display">
                      <label className="label-class">
                        Termination Template Name{" "}
                        <i className="fa fa-asterisk aster-risk-Icon"></i>
                      </label>
                      <input
                        type="text"
                        placeholder="Termination Template Name"
                        value={terminationTemplateName}
                        onChange={(e) =>
                          setTerminationTemplateName(e.target.value)
                        }
                        className="textbox-input_1"
                      />
                    </div>
                  </div>

                  <div className="div-display">
                    <label className="label-class">Category Type</label>

                    <SyfedTreeSelect
                      style={treeSelectStyle}
                      size="small"
                      treeCheckedStrategy="all"
                      dataSource={CategoryTypeSelect}
                      onChange={onChangeOfCategoryTreeSelect}
                      placeholder="Select Category Type"
                      value={categoryTypeSelected}
                    />
                  </div>
                  <div className="div-display">
                    <label className="label-class">Contract Type</label>
                    <SyfedTreeSelect
                      style={treeSelectStyle}
                      size="small"
                      treeCheckedStrategy="all"
                      dataSource={ContractTypeSelect}
                      onChange={onChangeOfContractTreeSelect}
                      placeholder="Select Contract Type"
                      value={contractTypeSelected}
                    />
                  </div>

                  <div className="footer-modal">
                    <button
                      type="button"
                      className="close-btn"
                      onClick={OnCloseModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="Modal-btn"
                      onClick={OnAddTemplateClose}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}
