//VID - this file is created in order to fix the "Uncaught Error: Cannot have two HTML5 backends at the same time." at mui datatables pr at react-sortable-tree
import React from "react";
import propTypes from "prop-types";
//import { createDragDropManager } from 'dnd-core';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

//const DNDManager = createDragDropManager(HTML5Backend);
export const GlobalDndContext = (props) => {
  //const manager = useRef(DNDManager);
  // the following line solve the problem only with key property
  return (
    <DndProvider backend={HTML5Backend} key={1}>
      {props.children}
    </DndProvider>
  );
};

GlobalDndContext.propTypes = { children: propTypes.node };

export default GlobalDndContext;
