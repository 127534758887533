import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import { toast } from "react-toastify";
import Collapsible from "react-collapsible";
import "./TabsForVariables.css";
import {
  insertSignatureBlock,
  convertBasicCSVToArrOfObject,
  containsObject,
  getCorrespondingValueForOperators,
} from "../../PlateEditor/helper/Helper";
import {
  generateUUID,
  generateUUIDBasicVar,
  generateUUIDSig,
} from "../../config/utils/SlateUtilityFunctions";
import { v4 as uuid } from "uuid";

const selectStyles = {
  menu: (styles) => ({
    ...styles,
    zIndex: 999,
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
  }),
  control: () => ({
    borderColor: "#D9DFF2",
    borderStyle: "solid",
    borderWidth: "1px",
    display: "flex",
    borderRadius: "5px",
    textAlign: "left",
    width: "100%",
    margin: "5px 0 10px 0",
    "&:hover": {
      border: "2px solid",
      borderColor: "#48336E",
    },
  }),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabForParties(props) {
  const [value, setValue] = useState(0);
  const [propertiesDisabled, setpropertiesDisabled] = useState(true);
  const [lookupId, setLookupId] = useState("");
  const [selectedSubGroup, setSelectedSubGroup] = useState("");
  const [subGroups, setSubGroups] = useState([]);
  const [isRequestChecked, setIsRequestChecked] = useState(true);
  const [totalColumns, setTotalColumns] = useState([]);
  const [lookupTableNames, setLookupTableNames] = useState([]);
  const [selectedLookupTableName, setSelectedLookupTableName] = useState(null);
  const [dataColumns, setDataColumns] = useState([]);
  const [finalJSON, setFinalJSON] = useState({});
  const [checkedVars, setCheckedVars] = useState([]);
  const [varType, setVarType] = useState({ value: "text", text: "Text" });
  const [selectedParty, setSelectedParty] = useState("");
  const [countOfRender, setCountOfRender] = useState(0);
  const [openPromisers, setOpenPromisers] = useState("");

  const varTypeOptions = [
    { value: "text", text: "Text" },
    { value: "searchBar", text: "SearchBar" },
  ];

  const [validationType, setValidationType] = useState({
    value: "none",
    text: "None",
  });
  const validationTypeOptions = [
    { value: "text", text: "Text" },
    { value: "number", text: "Number" },
    { value: "regex", text: "Regex" },
    { value: "none", text: "None" },
  ];
  const [mandatory, setMandatory] = useState({ value: "true", text: "True" });
  const [mandatoryOptions, setMandatoryOptions] = useState([
    { value: "true", text: "True" },
    { value: "false", text: "False" },
  ]);
  const [operatorForVariables, setOperatorForVariables] = useState([]);
  const [valuesForVariables, setValuesForVariables] = useState([]);
  const [varsToPopulate, setVarsToPopulate] = useState([]);
  const [dependentVars, setDependentVars] = useState([]);
  const [selectedVars, setSelectedVars] = useState([]);
  const [conditionType, setConditionType] = useState("");
  const [placeholders, setPlaceholders] = useState({});
  const [isrequired, setIsRequired] = useState({});
  const [changedIds, setChangedIds] = useState({});
  const [elementType, setElementType] = useState("");
  const [configType, setConfigType] = useState("");
  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [csvForSelect, setCsvForSelect] = useState("");
  const [date, setDate] = useState(undefined);
  const [regex, setRegex] = useState("");
  const [isSignOpen, setIsSignOpen] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [variablesForCondition, setvariablesForCondition] = useState([]);
  const [promiseeCount, setPromiseeCount] = useState(0);

  //new variables
  const [parties, setParties] = useState({});

  // on mount
  useEffect(() => {
    setValue(0);
    let finJSON = {};
    Object.keys(props.partiesJson).map((party) => {
      props.partiesJson[party].map((singleParty, idx) => {
        Object.keys(singleParty).map((obj) => {
          if (obj.includes("firstname")) {
            let partyObj = {
              [obj.split(idx + 1)[0] + ":" + (idx + 1)]: singleParty[obj],
            };
            finJSON = { ...finJSON, ...partyObj };
          } else {
            let partyObj = {
              [obj]: singleParty[obj],
            };
            finJSON = { ...finJSON, ...partyObj };
          }
        });
      });
    });
    if (props.masterLibrary) {
      let masterLibraryActive = [];
      for (var i = 0; i < props.masterLibrary.length; i++) {
        if (props.masterLibrary[i].status_id === 1) {
          masterLibraryActive.push(props.masterLibrary[i]);
        }
      }

      setLookupTableNames(masterLibraryActive);
    }

    //prepopulate nonTemplatevariables on mount of the component
    let depVars = [];
    let varArr = [];
    let nonTempVarArr = [];
    let varsToPoplt = [];
    if (finJSON) {
      Object.keys(finJSON).forEach((e) => {
        //do not consider signature variables
        if (
          e.startsWith("signer") &&
          !isNaN(parseInt(e.replace("signer", "")))
        ) {
          //do nothing
        } else {
          if (!depVars.includes(e) && !checkedVars.includes(e)) {
            depVars.push(e);
          }

          if (!varsToPoplt.includes(e)) {
            if (finJSON[e].elementType !== null) {
              varsToPoplt.push(e);
            }
          }
        }
      });
      setFinalJSON(finJSON);
    } else setFinalJSON({});
    setDependentVars(depVars);
    setVarsToPopulate(varsToPoplt);
    var conditional = {
      value: "conditional",
      text: "Conditional",
    };
    // if (varsToPoplt.length >= 1) {
    //   if (!containsObject(mandatoryOptions)) {
    //     let mandOptions = [...mandatoryOptions];
    //     mandOptions.push(conditional);
    //     setMandatoryOptions(mandOptions);
    //   }
    // } else {
    //   setMandatoryOptions(
    //     mandatoryOptions.filter((item) => {
    //       return item.value !== "conditional";
    //     })
    //   );
    // }
    setMandatory({
      value: "true",
      text: "True",
    });
  }, [props.partiesJson]);

  useEffect(() => {
    if (Object.keys(props.partiesJson).length === 0 && countOfRender === 1) {
      let promisee = [];
      let promiser = [];

      let newPromisee = {
        ["promisee1firstname"]: {
          uuid: "promisee1firstname", //generateUUIDBasicVar("_PE"),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1firstname",
            type: "text",
            placeholder: "First Name",
          },
          sequenceNo: 1,
        },
      };
      newPromisee = {
        ...newPromisee,
        ["promisee1lastname"]: {
          uuid: "promisee1lastname", //generateUUIDBasicVar("_PE", newPromisee),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1lastname",
            type: "text",
            placeholder: "Last Name",
          },
          sequenceNo: 2,
        },
      };
      newPromisee = {
        ...newPromisee,
        ["promisee1IdNumber"]: {
          uuid: "promisee1IdNumber", //generateUUIDBasicVar("_PE", newPromisee),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1IdNumber",
            type: "text",
            placeholder: "ID No",
          },
          sequenceNo: 3,
        },
      };
      newPromisee = {
        ...newPromisee,
        ["promisee1addressline1"]: {
          uuid: "promisee1addressline1", //generateUUIDBasicVar("_PE", newPromisee),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1addressline1",
            type: "text",
            placeholder: "Address Line 1",
          },
          sequenceNo: 4,
        },
      };
      newPromisee = {
        ...newPromisee,
        ["promisee1addressline2"]: {
          uuid: "promisee1addressline2", //generateUUIDBasicVar("_PE", newPromisee),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1addressline2",
            type: "text",
            placeholder: "Address Line 2",
          },
          sequenceNo: 5,
        },
      };
      newPromisee = {
        ...newPromisee,
        ["promisee1city"]: {
          uuid: "promisee1city", //generateUUIDBasicVar("_PE", newPromisee),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1city",
            type: "text",
            placeholder: "City",
          },
          sequenceNo: 6,
        },
      };
      newPromisee = {
        ...newPromisee,
        ["promisee1state"]: {
          uuid: "promisee1state", //generateUUIDBasicVar("_PE", newPromisee),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1state",
            type: "text",
            placeholder: "State",
          },
          sequenceNo: 7,
        },
      };
      newPromisee = {
        ...newPromisee,
        ["promisee1zipcode"]: {
          uuid: "promisee1zipcode", //generateUUIDBasicVar("_PE", newPromisee),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee1",
          elementConfig: {
            id: "promisee1zipcode",
            type: "text",
            placeholder: "Zipcode",
          },
          sequenceNo: 8,
        },
      };
      promisee.push(newPromisee);

      let newPromiser = {
        ["promiser1firstname"]: {
          uuid: "promiser1firstname", //generateUUIDBasicVar("_PR"),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1firstname",
            type: "text",
            placeholder: "First Name",
          },
          sequenceNo: 1,
        },
      };
      newPromiser = {
        ...newPromiser,
        ["promiser1lastname"]: {
          uuid: "promiser1lastname", //generateUUIDBasicVar("_PR", newPromiser),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1lastname",
            type: "text",
            placeholder: "Last Name",
          },
          sequenceNo: 2,
        },
      };
      newPromiser = {
        ...newPromiser,
        ["promiser1IdNumber"]: {
          uuid: "promiser1IdNumber", //generateUUIDBasicVar("_PR", newPromiser),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1IdNumber",
            type: "text",
            placeholder: "ID No",
          },
          sequenceNo: 3,
        },
      };
      newPromiser = {
        ...newPromiser,
        ["promiser1addressline1"]: {
          uuid: "promiser1addressline1", //generateUUIDBasicVar("_PR", newPromiser),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1addressline1",
            type: "text",
            placeholder: "Address Line 1",
          },
          sequenceNo: 4,
        },
      };
      newPromiser = {
        ...newPromiser,
        ["promiser1addressline2"]: {
          uuid: "promiser1addressline2", //generateUUIDBasicVar("_PR", newPromiser),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1addressline2",
            type: "text",
            placeholder: "Address Line 2",
          },
          sequenceNo: 5,
        },
      };
      newPromiser = {
        ...newPromiser,
        ["promiser1city"]: {
          uuid: "promiser1city", //generateUUIDBasicVar("_PR", newPromiser),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1city",
            type: "text",
            placeholder: "City",
          },
          sequenceNo: 6,
        },
      };
      newPromiser = {
        ...newPromiser,
        ["promiser1state"]: {
          uuid: "promiser1state", //generateUUIDBasicVar("_PR", newPromiser),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1state",
            type: "text",
            placeholder: "State",
          },
          sequenceNo: 7,
        },
      };
      newPromiser = {
        ...newPromiser,
        ["promiser1zipcode"]: {
          uuid: "promiser1zipcode", //generateUUIDBasicVar("_PR", newPromiser),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promiser1",
          elementConfig: {
            id: "promiser1zipcode",
            type: "text",
            placeholder: "Zipcode",
          },
          sequenceNo: 8,
        },
      };

      promiser.push(newPromiser);
      setParties({
        promisees: [...promisee],
        promisers: [...promiser],
      });
      props.setPartiesJSON({
        promisees: [...promisee],
        promisers: [...promiser],
      });
    }
    setCountOfRender(countOfRender + 1);
  }, [props.partiesJson]);
  // on mount
  useEffect(() => {
    if (Object.keys(props.partiesJson).length !== 0) {
      setParties(props.partiesJson);
    }
  }, [props.partiesJson]);

  useEffect(() => {
    props.setVarsToPopulate(varsToPopulate);
  }, [varsToPopulate]);

  useEffect(() => {
    if (checkedVars.length > 0) setpropertiesDisabled(false);
    else setpropertiesDisabled(true);
  }, [checkedVars]);

  useEffect(() => {
    if (props.finalJSON && Object.keys(props.finalJSON).length === 0) {
      setValue(0);
      setpropertiesDisabled(true);
      setCheckedVars([]);
    }
  }, [props.finalJSON]);

  useEffect(() => {
    getDependentVariables();
  }, [dependentVars]);

  useEffect(() => {
    let dataCols = [];
    if (
      selectedLookupTableName !== null &&
      selectedLookupTableName.library_table &&
      selectedLookupTableName.library_table.lib_table
    ) {
      Object.keys(selectedLookupTableName.library_table.lib_table[0]).forEach(
        (e) => {
          dataCols.push(e);
        }
      );
    }
    setDataColumns(dataCols);
  }, [selectedLookupTableName]);

  useEffect(() => {
    setElementAndConfigType();
  }, [varType]);

  useEffect(() => {
    let varsToPoplt = [];
    let depVars = [];
    let finJSON = {};

    Object.keys(parties).map((party) => {
      parties[party].map((singleParty, idx) => {
        Object.keys(singleParty).map((obj) => {
          if (obj.includes("firstname")) {
            let partyObj = {
              [obj.split(idx + 1)[0] + ":" + (idx + 1)]: singleParty[obj],
            };
            finJSON = { ...finJSON, ...partyObj };
          } else {
            let partyObj = {
              [obj]: singleParty[obj],
            };
            finJSON = { ...finJSON, ...partyObj };
          }
        });
      });
    });
    Object.keys(finJSON).forEach((e) => {
      if (!depVars.includes(e) && !checkedVars.includes(e)) {
        depVars.push(e);
      }
      if (!varsToPoplt.includes(e)) {
        if (finJSON[e].elementType !== null) {
          varsToPoplt.push(e);
        }
      }
    });

    setDependentVars(depVars);
    setVarsToPopulate(varsToPoplt);

    var conditional = {
      value: "conditional",
      text: "Conditional",
    };

    //prepopulate the properties section when the activetab changes to properties
    if (value === 1) {
      //initialise all UI to initial values
      setVarType({ value: "text", text: "Text" });
      setMinLength("");
      setMaxLength("");
      setSelectedLookupTableName("");
      setTotalColumns([]);
      setIsRequestChecked(true);
      setMandatory({ value: "false", text: "False" });
      setValidationType({ value: "none", text: "None" });

      if (Object.keys(finJSON).length > 0) {
        let fLJ = finJSON;
        //if checked variables array is only 1, then directly prepopulate
        if (
          checkedVars.length === 1 &&
          checkedVars[0] in fLJ &&
          "elementType" in fLJ[checkedVars[0]] &&
          "elementConfig" in fLJ[checkedVars[0]]
        ) {
          //prepopulate that variable's configuration
          if (Object.keys(finJSON).includes(checkedVars[0])) {
            let checkedVar = checkedVars[0];
            let elType = fLJ[checkedVar].elementType;
            let elConfType = fLJ[checkedVar].elementConfig.type;
            setValidationType({ value: "none", text: "None" });
            if (elType === "input") {
              if (elConfType === "text") {
                setVarType({ value: "text", text: "Text" });
                if (
                  fLJ[checkedVar].validation.maxLength &&
                  fLJ[checkedVar].validation.minLength
                ) {
                  setMinLength(fLJ[checkedVar].validation.minLength);
                  setMaxLength(fLJ[checkedVar].validation.maxLength);
                }
                if (fLJ[checkedVar].validation.isNumeric === true) {
                  setValidationType({
                    value: "number",
                    text: "Number",
                  });
                } else if (fLJ[checkedVar].validation.isNumeric === false) {
                  setValidationType({ value: "text", text: "Text" });
                } else if (fLJ[checkedVar].validation.rejex) {
                  setValidationType({ value: "regex", text: "Regex" });
                  setRegex(fLJ[checkedVar].validation.rejex);
                } else {
                  setValidationType({ value: "none", text: "None" });
                }
              } else if (elConfType === "file") {
                setVarType({ value: "file", text: "File" });
              } else if (elConfType === "email") {
                setVarType({ value: "email", text: "Email" });
              }
            } else if (elType === "searchBar") {
              setVarType({ value: "searchBar", text: "SearchBar" });

              if (fLJ[checkedVar].elementConfig.lookupConfig) {
                lookupTableNames.some((e) => {
                  if (
                    fLJ[checkedVar].elementConfig.lookupConfig.lookupTableID ===
                    e.library_table_id
                  ) {
                    setSelectedLookupTableName(e);
                    return true;
                  }
                  return false;
                });

                //prepopulate lookupId
                setLookupId({
                  label: fLJ[checkedVar].elementConfig.lookupConfig.lookupId,
                  value: fLJ[checkedVar].elementConfig.lookupConfig.lookupId,
                });

                let totalCols = [];

                if (
                  "populateFields" in fLJ[checkedVar].elementConfig.lookupConfig
                ) {
                  fLJ[
                    checkedVar
                  ].elementConfig.lookupConfig.populateFields.forEach((e) => {
                    let obj = {};
                    let varName = Object.prototype.hasOwnProperty.call(
                      e,
                      "varName"
                    )
                      ? e.varName !== null
                        ? e.varName
                        : null
                      : null;
                    obj.varName = varName;
                    let colName = Object.prototype.hasOwnProperty.call(
                      e,
                      "masterDataCol"
                    )
                      ? e.masterDataCol
                      : null;
                    obj.colName = colName;
                    totalCols.push(obj);
                  });
                  setTotalColumns(totalCols);
                }
              }
            } else if (elType === "textarea") {
              setVarType({ value: "textarea", text: "Textarea" });
              if (
                fLJ[checkedVar].validation.maxLength &&
                fLJ[checkedVar].validation.minLength
              ) {
                setMinLength(fLJ[checkedVar].validation.minLength);
                setMaxLength(fLJ[checkedVar].validation.maxLength);
              }
              if (fLJ[checkedVar].validation.isNumeric === true) {
                setValidationType({
                  value: "number",
                  text: "Number",
                });
              } else if (fLJ[checkedVar].validation.isNumeric === false) {
                setValidationType({ value: "text", text: "Text" });
              } else if (fLJ[checkedVar].validation.rejex) {
                setValidationType({ value: "regex", text: "Regex" });
                setRegex(fLJ[checkedVar].validation.rejex);
              } else {
                setValidationType({ value: "none", text: "None" });
              }
            } else if (elType === "select") {
              setVarType({ value: "select", text: "Select" });
              setCsvForSelect(
                fLJ[checkedVar].elementConfig.options
                  .map((e) => e.value)
                  .join(",")
              );
            } else if (elType === "radio") {
              setVarType({ value: "radio", text: "Radio" });
              setCsvForSelect(
                fLJ[checkedVar].elementConfig.options
                  .map((e) => e.value)
                  .join(",")
              );
            } else if (elType === "checkbox") {
              setVarType({ value: "checkbox", text: "Checkbox" });
              setCsvForSelect(
                fLJ[checkedVar].elementConfig.options
                  .map((e) => e.value)
                  .join(",")
              );
            } else if (elType === "date") {
              setVarType({ value: "date", text: "Date" });
            } else if (elType === "password") {
              setVarType({ value: "password", text: "Password" });
            } else {
              setVarType({ value: "text", text: "Text" });
            }
            if (fLJ[checkedVar].validation.required === true) {
              setMandatory({ value: "true", text: "True" });
            } else if (fLJ[checkedVar].validation.required === false) {
              setMandatory({ value: "false", text: "False" });
            }
            if (fLJ[checkedVar].validation.isConditional) {
              setMandatory({
                value: "conditional",
                text: "Conditional",
              });

              let selVars = [];
              let opForVars = [];
              let valForVars = [];
              "conditions" in fLJ[checkedVar].validation.conditions &&
                fLJ[checkedVar].validation.conditions.conditions.forEach(
                  (e) => {
                    let obj = {};
                    obj.text = getCorrespondingValueForOperators(e.operator);
                    obj.value = e.operator;
                    selVars.push({ label: e.id, value: e.id });
                    opForVars.push(obj);
                    valForVars.push(e.value);
                  }
                );

              setSelectedVars(selVars);
              setOperatorForVariables(opForVars);
              setValuesForVariables(valForVars);

              setConditionType({
                value: fLJ[checkedVar].validation.conditions.conditionType,
                label: fLJ[checkedVar].validation.conditions.conditionType,
              });
            }
            let plcHldrs = { ...placeholders };
            let varReq = { ...isrequired };
            let partyName = checkedVar.split(":")[0] + checkedVar.split(":")[1];
            plcHldrs[partyName + "firstname"] =
              fLJ[checkedVar].elementConfig.placeholder;
            plcHldrs[partyName + "lastname"] =
              fLJ[partyName + "lastname"].elementConfig.placeholder;
            varReq[partyName + "lastname"] =
              fLJ[partyName + "lastname"].validation.required;
            plcHldrs[partyName + "addressline1"] =
              fLJ[partyName + "addressline1"].elementConfig.placeholder;
            varReq[partyName + "addressline1"] =
              fLJ[partyName + "addressline1"].validation.required;
            plcHldrs[partyName + "addressline2"] =
              fLJ[partyName + "addressline2"].elementConfig.placeholder;
            varReq[partyName + "addressline2"] =
              fLJ[partyName + "addressline2"].validation.required;
            plcHldrs[partyName + "city"] =
              fLJ[partyName + "city"].elementConfig.placeholder;
            varReq[partyName + "city"] =
              fLJ[partyName + "city"].validation.required;
            plcHldrs[partyName + "state"] =
              fLJ[partyName + "state"].elementConfig.placeholder;
            varReq[partyName + "state"] =
              fLJ[partyName + "state"].validation.required;
            plcHldrs[partyName + "zipcode"] =
              fLJ[partyName + "zipcode"].elementConfig.placeholder;
            varReq[partyName + "zipcode"] =
              fLJ[partyName + "zipcode"].validation.required;
            plcHldrs[partyName + "IdNumber"] =
              fLJ[partyName + "IdNumber"].elementConfig.placeholder;
            varReq[partyName + "IdNumber"] =
              fLJ[partyName + "IdNumber"].validation.required;
            setPlaceholders(plcHldrs);
            setIsRequired(varReq);

            if (
              Object.prototype.hasOwnProperty.call(fLJ[checkedVar], "isRequest")
            ) {
              setIsRequestChecked(fLJ[checkedVar].isRequest);
            }
          }
        }
        // else check if checked variables array length is greater than 1,
        // prepopulate only properties which are different for the variables if same config for all variables , i.e., placeholders
        else if (checkedVars.length > 1) {
          let plcHldrs = { ...placeholders };
          checkedVars.forEach((e, i) => {
            plcHldrs[i] = e;
            setPlaceholders(plcHldrs);
          });
        }
      }
    }
  }, [value]);

  const getDependentVariables = () => {
    let Svariables = [];
    dependentVars.map((Cvar) => {
      if (finalJSON[Cvar]?.elementType !== "searchBar") {
        let variable = {
          value: Cvar,
          label: Cvar,
        };
        Svariables.push(variable);
      }
    });
    setvariablesForCondition(Svariables);
  };

  const displaySubmittedMessage = () => {
    toast.success("Submitted Successfully!", {
      className: "toaststyle",
    });
  };
  const displayVariableMessage = () => {
    toast.success("Variable is used in the Template!", {
      className: "toaststyle",
    });
  };
  const displayPromieseeMessage = () => {
    toast.success("Cannot add more Promisees!", {
      className: "toaststyle",
    });
  };

  const removeVariable = (vrbl) => {
    let codeBlocks = document.getElementsByTagName("code");
    for (let i = codeBlocks.length - 1; i >= 0; i--) {
      let content = codeBlocks[i].getAttribute("data-content");
      let type = codeBlocks[i].getAttribute("data-type");
      if (type === "variable" && content === `{{${vrbl}}}`) {
        codeBlocks[i].remove();
      }
    }
  };

  const setElementAndConfigType = () => {
    switch (varType.value) {
      case "text":
        setElementType("input");
        setConfigType("text");
        break;
      case "searchBar":
        setElementType("searchBar");
        setConfigType("");
        break;
      case "file":
        setElementType("input");
        setConfigType("file");
        break;
      case "email":
        setElementType("input");
        setConfigType("email");
        break;
      case "textarea":
        setElementType("textarea");
        setConfigType("text");
        break;
      case "select":
        setElementType("select");
        setConfigType("select");
        break;
      case "radio":
        setElementType("radio");
        setConfigType("radio");
        break;
      case "checkbox":
        setElementType("checkbox");
        setConfigType("checkbox");
        break;
      case "date":
        setElementType("date");
        setConfigType("date");
        break;
      case "password":
        setElementType("password");
        setConfigType("password");
        break;
      default:
        setElementType("text");
        setConfigType("");
        break;
    }
  };

  const onRemovePromiserVariableClick = (id) => {
    //removeVariable(vrbl);
    let finJSON = parties
      ? parties.promisers[parties.promisers.length - 1]
      : {};
    let removeVar = true;
    Object.keys(finJSON).map((obj, id) => {
      if (props.checkIfNodeExists(finJSON[obj].uuid) !== true) {
        removeVar = false;
      }
    });
    if (removeVar === true) {
      Object.keys(finJSON).map((obj, id) => {
        props.removeVariable(finJSON[obj].uuid);
      });

      //TODO - call function to remove variable the draft js way
      let partiesJson = { ...parties };
      let promisersJson = [...partiesJson.promisers];
      promisersJson.splice(id, 1);
      //delete promisersJson[id];
      let updatedPartiesJson = {
        promisees: [...parties.promisees],
        promisers: [...promisersJson],
      };
      setParties(updatedPartiesJson);
      props.setPartiesJSON(updatedPartiesJson);

      let checkVars = [...checkedVars];

      if (!checkVars.includes("promiser:" + (id + 1))) {
        if (checkVars.length === 0) {
          checkVars.push("promiser:" + (id + 1));
          setSelectedParty("promisers");
        }
      } else {
        checkVars.splice(checkVars.indexOf("promiser:" + (id + 1)), 1);
        setSelectedParty("");
      }
      setCheckedVars(checkVars);
    } else {
      displayVariableMessage();
    }
  };
  const onRemovePromiseeVariableClick = (id) => {
    //removeVariable(vrbl);
    let finJSON = parties
      ? parties.promisees[parties.promisees.length - 1]
      : {};
    let removeVar = true;
    Object.keys(finJSON).map((obj, id) => {
      if (props.checkIfNodeExists(finJSON[obj].uuid) !== true) {
        removeVar = false;
      }
    });
    if (removeVar === true) {
      Object.keys(finJSON).map((obj, id) => {
        props.removeVariable(finJSON[obj].uuid);
      });

      //TODO - call function to remove variable the draft js way
      let partiesJson = { ...parties };
      let promiseesJson = [...partiesJson.promisees];
      promiseesJson.splice(id, 1);
      //delete promisersJson[id];
      let updatedPartiesJson = {
        promisees: [...promiseesJson],
        promisers: [...parties.promisers],
      };
      setParties(updatedPartiesJson);
      props.setPartiesJSON(updatedPartiesJson);
      let checkVars = [...checkedVars];

      if (!checkVars.includes("promisee:" + (id + 1))) {
        if (checkVars.length === 0) {
          checkVars.push("promisee:" + (id + 1));
          setSelectedParty("promisees");
        }
      } else {
        checkVars.splice(checkVars.indexOf("promisee:" + (id + 1)), 1);
        setSelectedParty("");
      }
      setCheckedVars(checkVars);
    } else {
      displayVariableMessage();
    }
  };

  const onVariableClick = (variable) => {
    // insertVariable(variable);
    let finJSON = props.finalJSON !== null ? props.finalJSON : {};
    const varUUID = finJSON[variable].uuid;

    props.variableClick(varUUID, variable);
    //TODO - insert variable the draft js way
  };
  const onPartiesPromiserVariableClick = (id, variable, key) => {
    let finJSON = parties ? parties.promisers[id][key] : {};
    const varUUID = finJSON.uuid;

    props.variableClick(varUUID, variable, "promiser" + (id + 1));
    //TODO - insert variable the draft js way
  };
  const onPartiesPromiseeVariableClick = (id, variable, key) => {
    let finJSON = parties ? parties.promisees[id][key] : {};
    const varUUID = finJSON.uuid;

    props.variableClick(varUUID, variable, "promisee" + (id + 1));
    //TODO - insert variable the draft js way
  };
  const onBasicDetailVariableClick = (variable) => {
    let finJSON = props.basicDetailsJson.basicDetails
      ? props.basicDetailsJson.basicDetails[variable]
      : {};
    const varUUID = finJSON.uuid;

    props.variableClick(varUUID, variable, "basicDetails");
  };
  const HandlePromiserAdd = () => {
    let partiesJson = { ...parties };
    let promiser = [...partiesJson.promisers];
    if (promiser.length < 3) {
      let promiserId = promiser.length + 1;
      let newPromiser = {
        ["promiser" + promiserId + "name"]: {
          uuid: generateUUIDBasicVar(),
          elementType: null,
          elementConfig: {
            type: null,
            id: null,
            placeholder: null,
          },
          validation: {
            required: null,
            isNumeric: null,
            error: null,
          },
          value: null,
        },
        ["promiser" + promiserId + "addressline1"]: {
          uuid: generateUUIDBasicVar(),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promisers",
          elementConfig: {
            id: "promiser" + promiserId + "addressline1",
            type: "text",
            placeholder: "Promiser " + promiserId + " Address Line 1",
          },
        },
        ["promiser" + promiserId + "addressline2"]: {
          uuid: generateUUIDBasicVar(),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promisers",
          elementConfig: {
            id: "promiser" + promiserId + "addressline2",
            type: "text",
            placeholder: "Promiser " + promiserId + " Address Line 2",
          },
        },
        ["promiser" + promiserId + "city"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisers",
          elementConfig: {
            id: "promiser" + promiserId + "city",
            type: "text",
            placeholder: "Promiser" + promiserId + " City",
          },
        },
        ["promiser" + promiserId + "state"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisers",
          elementConfig: {
            id: "promiser" + promiserId + "state",
            type: "text",
            placeholder: "Promiser" + promiserId + " State",
          },
        },
        ["promiser" + promiserId + "zipcode"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisers",
          elementConfig: {
            id: "promiser" + promiserId + "zipcode",
            type: "text",
            placeholder: "Promiser" + promiserId + " Zipcode",
          },
        },
        ["promiser" + promiserId + "IdNumber"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisers",
          elementConfig: {
            id: "promiser" + promiserId + "IdNumber",
            type: "text",
            placeholder: "Promiser" + promiserId + " ID No",
          },
        },
      };
      promiser.push(newPromiser);
      setParties({
        promisees: [...parties.promisees],
        promisers: [...promiser],
      });
      props.setPartiesJSON({
        promisees: [...parties.promisees],
        promisers: [...promiser],
      });
    } else {
      displayPromieseeMessage();
    }
  };

  const HandlePromiseeAdd = () => {
    let partiesJson = { ...parties };
    let promisee = [...partiesJson.promisees];
    if (promisee.length < 3) {
      let promiseeId = promisee.length + 1;
      let newPromisee = {
        ["promisee" + promiseeId + "name"]: {
          uuid: generateUUIDBasicVar(),
          elementType: null,
          elementConfig: {
            type: null,
            id: null,
            placeholder: null,
          },
          validation: {
            required: null,
            isNumeric: null,
            error: null,
          },
          value: null,
          parentGroup: "promisee" + promiseeId,
        },
        ["promisee" + promiseeId + "addressline1"]: {
          uuid: generateUUIDBasicVar(),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promisee" + promiseeId,
          elementConfig: {
            id: "promisee" + promiseeId + "addressline1",
            type: "text",
            placeholder: "Promisee " + promiseeId + " Address Line 1",
          },
        },
        ["promisee" + promiseeId + "addressline2"]: {
          uuid: generateUUIDBasicVar(),
          valid: true,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: false,
          },
          elementType: "textarea",
          parentGroup: "promisee" + promiseeId,
          elementConfig: {
            id: "promisee" + promiseeId + "addressline2",
            type: "text",
            placeholder: "Promisee " + promiseeId + " Address Line 2",
          },
        },
        ["promisee" + promiseeId + "city"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee" + promiseeId,
          elementConfig: {
            id: "Name",
            type: "text",
            placeholder: "Promisee " + promiseeId + " City",
          },
        },
        ["promisee" + promiseeId + "state"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee" + promiseeId,
          elementConfig: {
            id: "Name",
            type: "text",
            placeholder: "Promisee " + promiseeId + " State",
          },
        },
        ["promisee" + promiseeId + "zipcode"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee" + promiseeId,
          elementConfig: {
            id: "Name",
            type: "text",
            placeholder: "Promisee " + promiseeId + " Zipcode",
          },
        },
        ["promisee" + promiseeId + "IdNumber"]: {
          uuid: generateUUIDBasicVar(),
          valid: false,
          value: "",
          touched: false,
          subGroup: "",
          isRequest: true,
          validation: {
            error: "",
            required: true,
            isNumeric: false,
            maxLength: null,
            minLength: null,
          },
          elementType: "input",
          parentGroup: "promisee" + promiseeId,
          elementConfig: {
            id: "Name",
            type: "text",
            placeholder: "Promisee " + promiseeId + " ID No",
          },
        },
      };
      promisee.push(newPromisee);
      setParties({
        promisees: [...promisee],
        promisers: [...parties.promisers],
      });
      props.setPartiesJSON({
        promisees: [...promisee],
        promisers: [...parties.promisers],
      });
    } else {
      displayPromieseeMessage();
    }
  };

  const displayColmunMessage = () => {
    toast.error("No data columns in the library", {
      className: "toaststyle",
    });
  };

  const handleChange = (newValue) => {
    if (newValue === 0) {
      setCheckedVars([]);
    }
    setValue(newValue);
  };
  const onPlaceholdersChange = (ev, vrbl) => {
    let [name, id] = vrbl.split(":");
    let plcHldrs = { ...placeholders };
    plcHldrs[name + id + "firstname"] = ev.target.value;
    setPlaceholders(plcHldrs);
  };
  const onPlaceholdersChangeGeneral = (ev, vrbl) => {
    let plcHldrs = { ...placeholders };
    plcHldrs[vrbl] = ev.target.value;
    setPlaceholders(plcHldrs);
  };
  const onRequiredChangeGeneral = (ev, vrbl) => {
    let varReq = { ...isrequired };
    varReq[vrbl] = ev.target.checked;
    setIsRequired(varReq);
  };
  const onRemoveColumnForSearchbarClick = (selCol, idx) => {
    let totalCols = [...totalColumns];
    if (totalColumns.includes(selCol)) {
      totalCols.splice(totalCols.indexOf(selCol), 1);
      setTotalColumns(totalCols);
    }
  };
  const onAddColumnForSearchbarClick = () => {
    if (totalColumns.length <= dataColumns.length - 1) {
      let totalCols = [...totalColumns];
      totalCols.push({ varName: null, colName: null });
      setTotalColumns(totalCols);
    } else {
      displayColmunMessage();
    }
  };
  const handleLookupConfigForSearchBar = (vrbl) => {
    let lookupConfig = {};
    if (
      selectedLookupTableName !== undefined &&
      selectedLookupTableName !== null &&
      selectedLookupTableName !== ""
    ) {
      lookupConfig.lookupId = lookupId.value;
      let populateFields = [];
      totalColumns.forEach((e, i) => {
        let populateField = {};
        let obj = {
          varName: e.varName,
          masterDataCol: e.colName,
        };
        populateField = obj;
        populateFields.push(populateField);
      });
      lookupConfig.populateFields = populateFields;
      lookupConfig.data = selectedLookupTableName.library_table.lib_table;
      lookupConfig.updatedData = [];
      lookupConfig.dataColumn = totalColumns.map((e) => e.colName);
      lookupConfig.lookupTableName = selectedLookupTableName.library_table_name;
      lookupConfig.lookupTableID = selectedLookupTableName.library_table_id;
    }
    return lookupConfig;
  };
  const onVariablePropertiesFormSubmit = () => {
    let pId = checkedVars[0].split(":")[1] - 1;
    let selectPValues = [];
    let partiesJson = { ...parties };
    let singleParty = [...partiesJson[selectedParty]];
    let finJSON = { ...singleParty[pId] };
    let vrbl = checkedVars[0].split(":")[0] + (pId + 1) + "firstname";
    if (vrbl) {
      let variableProperty = {};
      variableProperty.elementType = elementType;
      variableProperty.uuid = finJSON[vrbl].uuid;
      variableProperty.sequenceNo = 1;

      if (elementType !== "searchBar") {
        variableProperty.elementConfig = {
          ...{ type: configType },
          id: vrbl,
          placeholder: placeholders[vrbl] ? placeholders[vrbl] : "",
        };
      } else {
        variableProperty.elementConfig = {
          lookupConfig: handleLookupConfigForSearchBar(vrbl),
          placeholder: placeholders[vrbl] ? placeholders[vrbl] : "",
        };
      }
      variableProperty.validation = {
        required: mandatory.value === "false" ? false : true,
        ...(configType === "text" &&
          (validationType.value === "number" ||
            validationType.value === "text" ||
            validationType.value === "none") && {
            isNumeric: validationType.value === "number" ? true : false,
            minLength: minLength !== "" ? parseInt(minLength) : null,
            maxLength: maxLength !== "" ? parseInt(maxLength) : null,
          }),
        error: "",
      };
      Object.keys(parties[selectedParty][pId]).map((party) => {
        if (party !== vrbl) {
          let otherVariableProperty = { ...parties[selectedParty][pId][party] };
          otherVariableProperty.validation = {
            ...variableProperty.validation,
            ...{ required: isrequired[party] ? isrequired[party] : "" },
          };
          otherVariableProperty.elementConfig = {
            ...otherVariableProperty.elementConfig,
            ...{ placeholder: placeholders[party] ? placeholders[party] : "" },
          };
          finJSON[party] = otherVariableProperty;
        }
      });

      variableProperty.value = "";
      variableProperty.valid =
        minLength !== "" && parseInt(minLength) > 0
          ? false
          : mandatory.value === "false"
          ? true
          : false;
      variableProperty.touched = false;
      variableProperty.isRequest = isRequestChecked;
      variableProperty.subGroup = selectedSubGroup;
      variableProperty.parentGroup = checkedVars[0].split(":")[0] + (pId + 1); //selectedParty; //props.parentGroup;
      finJSON[vrbl] = variableProperty;
    }
    singleParty[pId] = finJSON;
    partiesJson[selectedParty] = singleParty;
    setParties(partiesJson);
    props.setPartiesJSON(partiesJson);
    setCheckedVars([]);
    displaySubmittedMessage();
  };

  const getOptionsForSearchVar = () => {
    // if(selectedParty === "promiser"){
    if (checkedVars[0]) {
      let pId = checkedVars[0]?.split(":")[1] - 1;
      let selectPValues = [];
      Object.keys(parties[selectedParty][pId]).map((party) => {
        selectPValues.push({
          label: party,
          value: party,
        });
      });
      // }
      return selectPValues;
    } else return [];
  };

  return (
    <div>
      <>
        <div className="parties-main-div">
          <div className="Tab-nav-variable">
            <div
              className={
                value === 0 ? "tabs-active-variable " : "tab-div-variable"
              }
              onClick={() => handleChange(0)}
            >
              Parties
            </div>
            <div
              className={
                value === 1 ? "tabs-active-variable " : "tab-div-variable"
              }
              disabled={propertiesDisabled}
              onClick={
                !propertiesDisabled
                  ? () => handleChange(1)
                  : () => {
                      toast.error("Select a variable to configure");
                    }
              }
            >
              Config
            </div>
          </div>
          {value === 0 ? (
            <div className="main-div">
              <div className="promisers-main-div">
                {/* <div style={{ borderBottom: "2px solid #eeeeee" }}>
                  <label className="party-Promisers-label">Promisers</label>
                </div> */}
                {/* <button className="promisers-add" onClick={HandlePromiserAdd}>
                Add
                </button> */}

                <Collapsible trigger="Promisers">
                  {parties
                    ? parties.promisers
                      ? parties.promisers.map((promiser, id) => {
                          return (
                            <div>
                              {props.disablePartyConfigure !== true ? (
                                <input
                                  type="checkbox"
                                  id={"promiser:" + (id + 1)}
                                  className="parties-checkbox"
                                  checked={checkedVars.includes(
                                    "promiser:" + (id + 1)
                                  )}
                                  value={"promiser:" + (id + 1)}
                                  onChange={(e) => {
                                    let checkVars = [...checkedVars];

                                    if (
                                      !checkVars.includes(
                                        "promiser:" + (id + 1)
                                      )
                                    ) {
                                      if (checkVars.length === 0) {
                                        checkVars.push("promiser:" + (id + 1));
                                        setSelectedParty("promisers");
                                      }
                                    } else {
                                      checkVars.splice(
                                        checkVars.indexOf(
                                          "promiser:" + (id + 1)
                                        ),
                                        1
                                      );
                                      setSelectedParty("");
                                    }
                                    setCheckedVars(checkVars);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              <label className="variables-label">
                                Promiser {id + 1}
                              </label>
                              {/* <button
                              onClick={() => onRemovePromiserVariableClick(id)}
                              className="closeBtnEditor"
                            >
                              <i className="fa fa-close"></i>
                            </button> */}
                              <div key={id} className="promisers-box">
                                {Object.keys(promiser).map(
                                  (promiserKeys, kId) => {
                                    return (
                                      <button
                                        className="promisers-add"
                                        onClick={() =>
                                          onPartiesPromiserVariableClick(
                                            id,
                                            promiserKeys,
                                            promiserKeys
                                          )
                                        }
                                        style={{ float: "left" }}
                                      >
                                        {promiserKeys.split(
                                          "promiser" + (id + 1)
                                        )}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })
                      : null
                    : null}
                </Collapsible>
              </div>
              <div className="promisers-main-div">
                {/* <div style={{ borderBottom: "2px solid #eeeeee" }}>
                  <label className="party-Promisers-label">Promisees</label>
                </div> */}
                <Collapsible trigger="Promisees">
                  {parties
                    ? parties.promisees
                      ? parties.promisees.map((promisee, id) => {
                          return (
                            <div>
                              {props.disablePartyConfigure !== true ? (
                                <input
                                  type="checkbox"
                                  id={"promisee:" + (id + 1)}
                                  className="parties-checkbox"
                                  checked={checkedVars.includes(
                                    "promisee:" + (id + 1)
                                  )}
                                  value={"promisee:" + (id + 1)}
                                  onChange={(e) => {
                                    let checkVars = [...checkedVars];

                                    if (
                                      !checkVars.includes(
                                        "promisee:" + (id + 1)
                                      )
                                    ) {
                                      if (checkVars.length === 0) {
                                        checkVars.push("promisee:" + (id + 1));
                                        setSelectedParty("promisees");
                                      }
                                    } else {
                                      checkVars.splice(
                                        checkVars.indexOf(
                                          "promisee:" + (id + 1)
                                        ),
                                        1
                                      );
                                      setSelectedParty("");
                                    }
                                    setCheckedVars(checkVars);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              <label className="variables-label">
                                Promisee {id + 1}
                              </label>
                              {/* <button
                            onClick={() => onRemovePromiseeVariableClick(id)}
                            className="closeBtnEditor"
                          >
                            <i className="fa fa-close"></i>
                          </button> */}
                              <div key={id} className="promisers-box">
                                {Object.keys(promisee).map(
                                  (promiseeKeys, kId) => {
                                    return (
                                      <>
                                        <button
                                          className="promisers-add"
                                          onClick={() =>
                                            onPartiesPromiseeVariableClick(
                                              id,
                                              promiseeKeys,
                                              promiseeKeys
                                            )
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {promiseeKeys.split(
                                            "promisee" + (id + 1)
                                          )}
                                        </button>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })
                      : null
                    : null}
                </Collapsible>
              </div>
              <div className="promisers-main-div">
                {/* <div style={{ borderBottom: "2px solid #eeeeee" }}>
                  <label className="party-Promisers-label">Basic Details</label>
                </div> */}
                <Collapsible trigger="Basic Details">
                  <div>
                    <div className="promisers-box">
                      {props.basicDetailsJson &&
                      props.basicDetailsJson.basicDetails
                        ? Object.keys(props.basicDetailsJson.basicDetails).map(
                            (basicDetail) => {
                              return (
                                <button
                                  className="promisers-add"
                                  onClick={() =>
                                    onBasicDetailVariableClick(basicDetail)
                                  }
                                  style={{ float: "left" }}
                                >
                                  {basicDetail}
                                </button>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                </Collapsible>
              </div>
            </div>
          ) : value === 1 ? (
            <>
              <div className="tabForParties-div">
                <div style={{ margin: "10px" }}>
                  <label className="config-label">Configuration for</label>
                  {checkedVars.map((vrbl, idx) => (
                    <span key={idx}>
                      <label className="label-variable"> '{vrbl}'</label>
                    </span>
                  ))}
                </div>
                <div className="textbox-align">
                  <label className="variables-label">
                    Variable Type{" "}
                    <i className="fa fa-asterisk aster-risk-Icon"></i>
                  </label>
                  <Select
                    maxMenuHeight={120}
                    styles={selectStyles}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: " #E5E5E5",
                        primary: "#B2D4FD ",
                      },
                    })}
                    value={varType}
                    getOptionLabel={(option) => option.text}
                    onChange={(e) => {
                      setVarType(e);
                    }}
                    options={varTypeOptions}
                  ></Select>
                </div>
                {varType.value !== "searchBar" ? (
                  <div className="textbox-align">
                    <label className="variables-label">
                      Is Mandatory?{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>
                    <Select
                      maxMenuHeight={120}
                      value={mandatory}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: " #E5E5E5",
                          primary: "#B2D4FD ",
                        },
                      })}
                      styles={selectStyles}
                      getOptionLabel={(option) => option.text}
                      onChange={(e) => {
                        setMandatory(e);
                      }}
                      options={mandatoryOptions}
                    />
                  </div>
                ) : null}

                {checkedVars.map((vrbl, idx) => (
                  <div className="textbox-align" key={idx}>
                    <label className="variables-label">
                      Placeholder for '{vrbl}'{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>
                    <input
                      className="Textbox-editor"
                      onChange={(e) => onPlaceholdersChange(e, vrbl)}
                      value={
                        placeholders[
                          vrbl.split(":")[0] + vrbl.split(":")[1] + "firstname"
                        ]
                      }
                    />
                  </div>
                ))}
                {varType.value === "searchBar" ? (
                  <div>
                    <div className="textbox-align">
                      <label className="variables-label">
                        Select table name to populate data for search bar{" "}
                        <i className="fa fa-asterisk aster-risk-Icon"></i>
                      </label>
                      <Select
                        maxMenuHeight={120}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: " #E5E5E5",
                            primary: "#B2D4FD ",
                          },
                        })}
                        styles={selectStyles}
                        onChange={(e) => {
                          setSelectedLookupTableName(e);
                          if (e !== selectedLookupTableName) {
                            setTotalColumns([]);
                            setLookupId("");
                          }
                        }}
                        getOptionLabel={(option) => option.library_table_name}
                        getOptionValue={(option) => option.library_table_name}
                        options={lookupTableNames}
                        value={selectedLookupTableName}
                      />
                    </div>

                    {selectedLookupTableName !== null ? (
                      <>
                        <div className="textbox-align">
                          <label className="variables-label">
                            Select column name for searching{" "}
                            <i className="fa fa-asterisk aster-risk-Icon"></i>
                          </label>
                          <Select
                            maxMenuHeight={120}
                            styles={selectStyles}
                            options={dataColumns.map((e) => ({
                              label: e,
                              value: e,
                            }))}
                            onChange={(e) => {
                              setLookupId(e);
                            }}
                            value={lookupId}
                          />
                        </div>

                        {totalColumns.map((selCol, idx) => (
                          <div className="searchBar-div" key={idx}>
                            <div>
                              <div
                                style={{ textAlign: "right", margin: "10px" }}
                              >
                                <button
                                  className="TPsearchCloseBtnEditor"
                                  onClick={(e) =>
                                    onRemoveColumnForSearchbarClick(selCol)
                                  }
                                >
                                  <i className="fa fa-close"></i>
                                </button>
                              </div>
                              <div style={{ padding: "5px" }}>
                                <label className="search-select">
                                  Select master col{" "}
                                  <i className="fa fa-asterisk aster-risk-Icon"></i>
                                </label>
                                <Select
                                  maxMenuHeight={120}
                                  styles={selectStyles}
                                  options={dataColumns.map((e) => ({
                                    label: e,
                                    value: e,
                                  }))}
                                  value={{
                                    label: selCol.colName,
                                    value: selCol.colName,
                                  }}
                                  onChange={(e) => {
                                    let totalCols = [...totalColumns];
                                    totalCols[idx].colName = e.value;
                                    setTotalColumns(totalCols);
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ padding: "5px" }}>
                              <label className="search-select">
                                Select variable{" "}
                                <i className="fa fa-asterisk aster-risk-Icon"></i>
                              </label>
                              <Select
                                maxMenuHeight={120}
                                styles={selectStyles}
                                //   options={Object.keys(parties)
                                //     .filter((jObj) => {
                                //       if (!isRequestChecked) {
                                //         if (
                                //           props.finalJSON[jObj].isRequest === false
                                //         ) {
                                //           return true;
                                //         }
                                //       } else if (
                                //         props.finalJSON[jObj].isRequest === true
                                //       ) {
                                //         return true;
                                //       }
                                //     })
                                //     .map((e) => ({
                                //       label: e,
                                //       value: e,
                                //     }))}

                                options={getOptionsForSearchVar()}
                                defaultValue={selCol.varName}
                                value={{
                                  label: selCol.varName,
                                  value: selCol.varName,
                                }}
                                onChange={(e) => {
                                  let totalCols = [...totalColumns];
                                  totalCols[idx].varName = e.value;
                                  setTotalColumns(totalCols);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                        <button
                          className="variableAddColumm"
                          onClick={(e) => onAddColumnForSearchbarClick()}
                        >
                          Add Column
                        </button>
                      </>
                    ) : null}
                  </div>
                ) : null}
                {parties[selectedParty].map((party, ind) => {
                  return Object.keys(party).map((vrbl, idx) => {
                    if (!vrbl.includes("firstname"))
                      return (
                        <div className="textbox-align" key={idx}>
                          <label className="variables-label">
                            Placeholder for '{vrbl.split(ind + 1)[1]}
                            {vrbl.split(ind + 1)[1] === "addressline"
                              ? "1'"
                              : "'"}
                            <i className="fa fa-asterisk aster-risk-Icon"></i>
                          </label>
                          <input
                            className="Textbox-editor"
                            onChange={(e) =>
                              onPlaceholdersChangeGeneral(e, vrbl)
                            }
                            value={placeholders[vrbl]}
                          />
                          <div className="tabforParties-required ">
                            <label className="variables-label">
                              Required
                              <i className="fa fa-asterisk aster-risk-Icon"></i>
                            </label>
                            <input
                              type="checkbox"
                              className="tabforParties-checkbox"
                              onChange={(e) => onRequiredChangeGeneral(e, vrbl)}
                              checked={isrequired[vrbl]}
                            />
                          </div>
                        </div>
                      );
                  });
                })}
                <div style={{ textAlign: "center" }}>
                  <button
                    className="submitbtneditor"
                    onClick={onVariablePropertiesFormSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    </div>
  );
}