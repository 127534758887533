import "../../editor/editor.css";
import React, { useEffect, useState } from "react";
import SyfedTreeSelect from "syfed-tree-select";
import { setTerminateTemplateData } from "../../../redux/Reducers/terminationTemplateReducer";
import {
  postCrudApi,
  getCrudApi,
  putCrudApi,
  deleteCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { validateToken } from "../../../Helpers/validateToken";
import { validateVariable } from "../../../Helpers/validation";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "./Termination.css";
import { RecoilRoot } from "recoil";
import PlateEditor from "../../../PlateEditor/PlateEditor";
import Loading from "../../../Loading/Loading";
import { useLocation } from "react-router";
import { Height } from "@styled-icons/material";

function TerminationEditor(props) {
  const [isMasterLoaded, setIsMasterLoaded] = useState(false);
  const [signatureVariables, setSignatureVariables] = useState([]);
  const [value, setValue] = useState(0);
  const [conditions, setConditions] = useState({});
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [CategoryTypeSelect, setCategoryTypeSelect] = useState([]);
  const [nonTemplateVariableArray, setNonTemplateVariableArray] = useState([]);
  const [contractTypeSelected, setContractTypeSelected] = useState(null);
  const [categoryTypeSelected, setCategoryTypeSelected] = useState(null);
  const [contractTemplateJsonData, setContractTemplateJsonData] =
    useState(null);
  const [contractVariableJsonData, setContractVariableJsonData] =
    useState(null);
  const [selectedtemplateData, setSelectedtemplateData] = useState(null);
  const [publish_enable, setPublish_enable] = useState(true);
  const [terminationTemplateIdState, setTerminationTemplateIdState] =
    useState("");
  const [draftTemplate_id, setDraftTemplate_id] = useState(0);
  const [masterLibrary, setMasterLibrary] = useState([]);
  const [clauseLibrary, setClauseLibrary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [terminationTemplateName, setTerminationTemplateName] = useState("");
  const plateRef = React.useRef();
  const location = useLocation();
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const [validSignature, setValidSig] = useState(true);
  const [pageSize, setPageSize] = useState("A4");

  const onChangeOfContractTreeSelect = (value) => {
    setContractTypeSelected(value);
  };
  const onChangeOfCategoryTreeSelect = (value) => {
    setCategoryTypeSelected(value);
  };

  const GetMasterLibrary = async () => {
    setIsLoading(true);
    let result, dataResult, clauseDataResult;
    validateToken();
    result = getCrudApi("library/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    validateToken();
    result = getCrudApi("clauses/all", {});
    await result.then(function (res) {
      clauseDataResult = res;
    });

    setMasterLibrary(dataResult);
    setClauseLibrary(clauseDataResult);
    setIsMasterLoaded(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (location.state) {
      (async () => {
        let result, dataResult;
        let templateJson_contract = null;
        let template_varjson = null;
        let conditions = {};
        let signatureVariables = [];
        let publishEnb = false;

        const draftId = sessionStorage.getItem("tdraftId");
        const verId = sessionStorage.getItem("tverId");
        const tempId = sessionStorage.getItem("tempId");
        if (draftId != null) {
          validateToken();
          result = getCrudApi("terminationDraftTemplate/" + draftId, {});
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            setPageSize(dataResult?.draftTerminationTemplate?.pageSize ? dataResult?.draftTerminationTemplate?.pageSize : "A4")
            templateJson_contract = JSON.parse(
              dataResult.draftTerminationTemplate.template
            );
            template_varjson = dataResult.draftTerminationTemplateVariable.var;
            conditions = dataResult.draftTerminationTemplateConditions
              ? dataResult.draftTerminationTemplateConditions.condition
              : {};
            signatureVariables =
              dataResult.draftTerminationSignatureVariables.signatureList;
            publishEnb = true;
          }
        } else if (verId) {
          validateToken();
          result = getCrudApi("terminationTemplateVersion/" + verId, {});
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            setPageSize(dataResult?.terminationTemplateVer?.pageSize ? dataResult?.terminationTemplateVer?.pageSize : "A4")
            templateJson_contract = JSON.parse(
              dataResult.terminationTemplateVer.template
            );
            template_varjson = dataResult.terminationTemplateVerVariable.var;
            conditions = dataResult.terminationTemplateVerConditions
              ? dataResult.terminationTemplateVerConditions.condition
              : {};
            signatureVariables =
              dataResult.terminationTemplateSignatureVariables.signatureList;

            publishEnb = true;
          }
        }

        setSignatureVariables(signatureVariables);
        setContractTypeSelected(location.state.contractTypeSelected);
        setCategoryTypeSelected(location.state.categorySelected);
        setTerminationTemplateName(location.state.templateName);
        setContractTypeSelect(location.state.contractType);
        setCategoryTypeSelect(location.state.categoryType);
        //setSelectedtemplateData(location.state.templateData);
        setContractTemplateJsonData(templateJson_contract);
        setContractVariableJsonData(template_varjson);
        setPublish_enable(publishEnb);
        setConditions(conditions);
        GetMasterLibrary();
        setFilterContractTypeSelect(location.state.contractType);
        if (tempId) {
          await getTemplateDetailsForReduxUpdate(tempId);
        }
      })();
    }
    setIsLoading(false);
  }, []);

  const HandlePublishTemplate = async (obj) => {
    setIsLoading(true);
    if (terminationTemplateName !== null && terminationTemplateName !== "") {
      const serializedData =
        plateRef.current !== null
          ? plateRef.current.serailizedData()
          : contractTemplateJsonData;
      const conditionsloc =
        plateRef.current !== null
          ? plateRef.current.getConditions()
          : conditions;
          const validSig = plateRef.current !== null? plateRef.current.getSigValid() : validSignature;
          const pageType = 
          plateRef.current !== null
          ? plateRef.current.getPageSize()
          : pageSize;
          setPageSize(pageType);    
            setValidSig(validSig);
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsloc);
      const value = validateVariable(contractVariableJsonData);
      if(validSig === false){
        toast.error("Remove unused signature blocks", {
          className: "toaststyle",
        });
      }
     else if (value !== null) {
        let valid = value[0];
        let variables = value[1];
        if (valid === false) {
          toast.error(variables + "variables are not configured", {
            className: "toaststyle",
          });
        } else {
          let template_json = {
            template: JSON.stringify(serializedData),
            pageSize:pageType
          };
          let var_json = {
            var: contractVariableJsonData,
          };
          let conditionJson = {
            condition: conditionsloc,
          };
          let templateVersionJson = {
            terminationTemplateSignatureVariables: {
              signatureList: signatureVariables,
            },
            terminationTemplateVer: template_json,
            terminationTemplateVerVariable: var_json,
            ispublished: 1,
            versionStauts: 1,
            terminationTemplateId: selectedtemplateData
              ? selectedtemplateData.terminationTemplateId
              : terminationTemplateIdState,
            terminationTemplateVerConditions: conditionJson,
          };
          let result;
          validateToken();
          result = postCrudApi(
            "terminationTemplateVersion/terminationTemplateVersion",
            templateVersionJson
          );
          let dataResult;
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            displayPublishMessage();
            sessionStorage.removeItem("tdraftId");
            sessionStorage.setItem(
              "tverId",
              dataResult.terminationTemplateVersionId
            );
            let template_version_id = dataResult.terminationTemplateVersionId;
            let contractTemplate = {
              publishedTerminationTemplateVersionId: template_version_id,
              terminationTemplateName: terminationTemplateName,
              contractTypeId: contractTypeSelected,
              catogeryTypeId: categoryTypeSelected,
              draftTerminationTemplateId: null,
            };
            validateToken();

            result = putCrudApi(
              "terminationTemplate/" +
                templateVersionJson.terminationTemplateId,
              contractTemplate
            );
            await result.then(function (res) {
              dataResult = res;
            });
            await getTemplateDetailsForReduxUpdate(
              dataResult.terminationTemplateId
            );
            setSelectedtemplateData(dataResult);
            const path2 =
              selectedtemplateData !== null
                ? selectedtemplateData.draftTerminationTemplateId !== null
                  ? selectedtemplateData.draftTerminationTemplateId
                  : draftTemplate_id !== 0
                  ? draftTemplate_id
                  : null
                : draftTemplate_id !== 0
                ? draftTemplate_id
                : null;
            if (path2 != null) {
              let path = "terminationDraftTemplate/" + path2;
              validateToken();
              await deleteCrudApi(path);
            }
          }
        }
      }
    } else {
      toast.error("Please enter a valid Template Name");
    }
    setIsLoading(false);
  };

  const HandleSaveTemplate = async (obj) => {
    setIsLoading(true);
    if (terminationTemplateName !== "" && terminationTemplateName !== null) {
      const serializedData =
        plateRef.current !== null
          ? plateRef.current.serailizedData()
          : contractTemplateJsonData;
      const conditionsloc =
        plateRef.current !== null
          ? plateRef.current.getConditions()
          : conditions;
          const pageType = 
          plateRef.current !== null
          ? plateRef.current.getPageSize()
          : pageSize;
setPageSize(pageType)
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsloc);
      const value = validateVariable(contractVariableJsonData);
      if (value !== null) {
        let valid = value[0];
        let variables = value[1];
        if (valid === false) {
          toast.error(variables + "variables are not configured", {
            className: "toaststyle",
          });
        } else {
          let template_json = {
            template: JSON.stringify(serializedData),
            pageSize:pageType
          };
          let var_json = {
            var: contractVariableJsonData,
          };
          let conditionJson = {
            condition: conditionsloc,
          };
          let templateJson = {
            draftTerminationSignatureVariables: {
              signatureList: signatureVariables,
            },
            draftTerminationTemplate: template_json,
            draftTerminationTemplateVariable: var_json,
            draftTerminationTemplateConditions: conditionJson,
          };
          let result;
          if (
            selectedtemplateData === null ||
            selectedtemplateData.draftTerminationTemplateId === null
          ) {
            validateToken();
            result = postCrudApi(
              "terminationDraftTemplate/terminationDraftTemplate",
              templateJson
            );
          } else {
            validateToken();
            result = putCrudApi(
              "terminationDraftTemplate/" +
                selectedtemplateData.draftTerminationTemplateId,
              templateJson
            );
          }
          let dataResult;
          await result.then(function (res) {
            dataResult = res;
          });
          if (dataResult) {
            displayDraftedMessage();
            sessionStorage.removeItem("tverId");
            sessionStorage.setItem(
              "tdraftId",
              dataResult.draftTerminationTemplateId
            );
            let draft_id = dataResult.draftTerminationTemplateId;
            setDraftTemplate_id(draft_id);
            let terminationTemplate = {
              terminationTemplateName: terminationTemplateName,
              contractTypeId: contractTypeSelected,
              catogeryTypeId: categoryTypeSelected,
              draftTerminationTemplateId: draft_id,
            };
            let terminationTemplateId;

            if (selectedtemplateData == null) {
              validateToken();
              result = postCrudApi(
                "terminationTemplate/terminationTemplate",
                terminationTemplate
              );
              await result.then(function (res) {
                terminationTemplateId = res;
              });
              if (terminationTemplateId) {
                setTerminationTemplateIdState(
                  terminationTemplateId.terminationTemplateId
                );
              }
              setPublish_enable(true);
            } else {
              validateToken();
              result = putCrudApi(
                "terminationTemplate/" +
                  selectedtemplateData.terminationTemplateId,
                terminationTemplate
              );
              await result.then(function (res) {
                terminationTemplateId = res;
              });
            }
            await getTemplateDetailsForReduxUpdate(
              terminationTemplateId.terminationTemplateId
            );
            setSelectedtemplateData(terminationTemplateId);
          }
        }
      }
    } else {
      toast.error("Please enter a valid Template Name");
    }
    setIsLoading(false);
  };

  const getTemplateDetailsForReduxUpdate = async (templateID) => {
    setIsLoading(true);
    sessionStorage.setItem("tempId", templateID);
    let templateData;
    let result;
    validateToken();
    result = getCrudApi("terminationTemplate/all", {});
    await result.then(function (res) {
      templateData = res;
    });
    props.setTerminateTemplateData(templateData);
    setSelectedtemplateData(
      templateData.filter(
        (element) => element.terminationTemplateId === parseInt(templateID, 10)
      )[0]
    );
    //  this.props.history.push("/Admin/Termination");
    setIsLoading(false);
  };

  const handleTabChange = (newValue) => {
    setIsLoading(true);
    if (plateRef.current !== null) {
      let serializedData = plateRef.current.serailizedData();
      const conditionsloc = plateRef.current.getConditions();
      const validSig =  plateRef.current.getSigValid();
      setValidSig(validSig);
      setContractTemplateJsonData(serializedData);
      setConditions(conditionsloc);
    }
    setValue(newValue);
    setIsLoading(false);
  };

  const displayPublishMessage = () => {
    toast.success("Published Successfully!", {
      className: "toaststyle",
    });
  };

  const displayDraftedMessage = () => {
    toast.success("Drafted Successfully!", {
      className: "toaststyle",
    });
  };
  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
    margin: "10px 0 0px 0",
  };
  function filterDataCategoryContractType() {
    let category = null;
    var newArray = filterContractTypeSelect;
    if (categoryTypeSelected !== null) {
      category = newArray?.filter((con) => {
        if (con.catogeryTypeId === categoryTypeSelected) return true;
        else return false;
      });
      setContractTypeSelect(category);
    }
  }

  useEffect(() => {
    filterDataCategoryContractType();
  }, [categoryTypeSelected]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div>
            <div className="headerHolder">
              <div className="Tab-nav-editor">
                <div
                  className={value === 0 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(0)}
                >
                  Editor
                </div>
                <div
                  className={value === 1 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabChange(1)}
                >
                  Template Settings
                </div>
              </div>
              <div className="formHeader">
                <div className="formheader85">
                  <label className="editor-header-label">
                    {terminationTemplateName !== undefined
                      ? terminationTemplateName
                      : "NAME"}
                  </label>
                </div>
                <div className="formheader20">
                  <button
                    className="contracts-btn"
                    onClick={() => HandleSaveTemplate()}
                  >
                    Draft
                  </button>
                  <button
                    className="contracts-btn"
                    onClick={() => HandlePublishTemplate()}
                    disabled={!publish_enable}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
            {value === 1 ? (
              <>
                <div className="settingTab">
                  <div className="container-grid">
                    <div className="formrow">
                      <div className="form-columns ">
                        <label
                          className="setting-label"
                          style={{ marginTop: "12px" }}
                        >
                          Termination Template
                        </label>
                      </div>
                      <div className="form-columns ">
                        <input
                          type="text"
                          className="text-form-editor"
                          placeholder="Termination Template Name"
                          value={terminationTemplateName}
                          onChange={(e) =>
                            setTerminationTemplateName(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="formrow">
                      <div className="form-columns ">
                        <label className="setting-label">Category Type</label>
                      </div>
                      <div className="form-columns ">
                        <SyfedTreeSelect
                          style={treeSelectStyle}
                          size="small"
                          treeCheckedStrategy="all"
                          dataSource={CategoryTypeSelect}
                          onChange={onChangeOfCategoryTreeSelect}
                          placeholder="Select Category Type"
                          value={categoryTypeSelected}
                        />
                      </div>
                    </div>
                    <div className="formrow">
                      <div className="form-columns ">
                        <label className="setting-label">Contract Type</label>
                      </div>
                      <div className="form-columns ">
                        <SyfedTreeSelect
                          style={treeSelectStyle}
                          size="small"
                          treeCheckedStrategy="all"
                          dataSource={ContractTypeSelect}
                          onChange={onChangeOfContractTreeSelect}
                          placeholder="Select Contract Type"
                          value={contractTypeSelected}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : value === 0 ? (
              <>
                <div>
                  {isMasterLoaded ? (
                    <RecoilRoot>
                      <PlateEditor
                        id={"terminationEditor"}
                        ref={plateRef}
                        parentGroup="Termination"
                        isNegotiationDisabled={true}
                        isVariableDisabled={false}
                        isPartiesDisabled={true}
                        isConditionDisabled={false}
                        isClauseDisabled={true}
                        canAddComments={false}
                        isConditionToggleDisabled={false}
                        isCommentToggleDisabled={true}
                        nonTemplateVariableArray={nonTemplateVariableArray}
                        setNonTemplateVariableArray={(nonTempVarArr) => {
                          setNonTemplateVariableArray(nonTempVarArr);
                        }}
                        signatureVariables={signatureVariables}
                        setSignatureVariables={(vars) => {
                          setSignatureVariables(vars);
                        }}
                        finalJSON={
                          contractVariableJsonData
                            ? contractVariableJsonData
                            : null
                        }
                        setFinalJSON={(finJSON) => {
                          setContractVariableJsonData(finJSON);
                        }}
                        setContractTemplateJsonData={(val) => {
                          setContractTemplateJsonData(val);
                        }}
                        contractTemplateJsonData={
                          contractTemplateJsonData
                            ? contractTemplateJsonData
                            : null
                        }
                        currentUser="user"
                        clauseLibrary={clauseLibrary}
                        masterLibrary={masterLibrary}
                        isEditable={true}
                        conditions={conditions}
                        setConditions={(val) => {
                          setConditions(val);
                        }}
                        serializeCall={"serializeTemplate"}
                        allowNontemp={false}
                        allowSignature={true}
                        dataPath="TerminationVariables"
                        conditiondataPath="TerminationConditions"
                        tabForVarId="terminationVarTab"
                        pageSize={pageSize}
                      />
                    </RecoilRoot>
                  ) : (
                    <Loading />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

export default connect(null, { setTerminateTemplateData })(TerminationEditor);
