import {
  findNode,
  getCommonNode,
  getNode,
  getNodes,
  getNodeString,
  getNodeTexts,
  getPlugin,
  insertElements,
  isElement,
  PlateEditor,
  removeNodes,
  TAncestor,
  TAncestorEntry,
  TDescendant,
  TDescendantEntry,
  TElement,
  TElementEntry,
  TText,
  Value,
  WithPlatePlugin,
} from '@udecode/plate-common';
import { Path } from 'slate';

import { ELEMENT_LI } from './createListPlugin';
import {
  getListItemContentType,
  getListItemType,
  isListRoot,
} from './queries/index';

export const insertFragmentList = (editor) => {
  const { insertFragment } = editor;

  const listItemPlugin = getPlugin(editor, ELEMENT_LI);
  const listItemType = getListItemType(editor);
  const listItemContentType = getListItemContentType(editor);

  const getFirstAncestorOfType = (
    root,
    entry,
    { type }
  ) => {
    let ancestor = Path.parent(entry[1]);
    while (getNode(root, ancestor).type !== type) {
      ancestor = Path.parent(ancestor);
    }

    return [getNode(root, ancestor), ancestor];
  };

  const findListItemsWithContent = (first) => {
    let prev = null;
    let node = first;
    while (
      isListRoot(editor, node) ||
      (node.type === listItemType &&
        (node.children )[0].type !== listItemContentType)
    ) {
      prev = node;
      [node] = node.children ;
    }

    return prev ? (prev.children ) : [node];
  };

  /**
   * Removes the "empty" leading lis. Empty in this context means lis only with other lis as children.
   *
   * @returns If argument is not a list root, returns it, otherwise returns ul[] or li[].
   */
  const trimList = (listRoot) => {
    if (!isListRoot(editor, listRoot)) {
      return [listRoot ];
    }

    const _texts = getNodeTexts(listRoot);
    const textEntries = Array.from(_texts);

    const commonAncestorEntry = textEntries.reduce(
      (commonAncestor, textEntry) =>
        Path.isAncestor(commonAncestor[1], textEntry[1])
          ? commonAncestor
          : (getCommonNode(listRoot, textEntry[1], commonAncestor[1]) ),
      // any list item would do, we grab the first one
      getFirstAncestorOfType(listRoot, textEntries[0], listItemPlugin )
    );

    const [first, ...rest] = isListRoot(
      editor,
      commonAncestorEntry[0] 
    )
      ? (commonAncestorEntry[0] ).children
      : [commonAncestorEntry[0]];
    return [...findListItemsWithContent(first), ...rest];
  };

  const wrapNodeIntoListItem = (node)=> {
    return node.type === listItemType
      ? (node )
      : ({
          type: listItemType,
          children: [node],
        } );
  };

  /**
   * Checks if the fragment only consists of a single LIC in which case it is considered the user's intention was to copy a text, not a list
   */
  const isSingleLic = (fragment) => {
    const isFragmentOnlyListRoot =
      fragment.length === 1 && isListRoot(editor, fragment[0]);

    return (
      isFragmentOnlyListRoot &&
      [...getNodes({ children: fragment } )]
        .filter((entry) => isElement(entry[0]))
        .filter(([node]) => node.type === listItemContentType).length === 1
    );
  };

  const getTextAndListItemNodes = (
    fragment,
    liEntry,
    licEntry
  ) => {
    const [, liPath] = liEntry;
    const [licNode, licPath] = licEntry;
    const isEmptyNode = !getNodeString(licNode);
    const [first, ...rest] = fragment
      .flatMap(trimList)
      .map(wrapNodeIntoListItem);
    let textNode;
    let listItemNodes;
    if (isListRoot(editor, fragment[0])) {
      if (isSingleLic(fragment)) {
        textNode = first ;
        listItemNodes = rest ;
      } else if (isEmptyNode) {
        // FIXME: is there a more direct way to set this?
        const li = getNode(editor, liPath);
        const [, ...currentSublists] = li.children ;
        const [newLic, ...newSublists] = first.children ;
        insertElements(editor, newLic, {
          at: Path.next(licPath),
          select: true,
        });
        removeNodes(editor, {
          at: licPath,
        });
        if (newSublists?.length) {
          if (currentSublists?.length) {
            // TODO: any better way to compile the path where the LIs of the newly inserted element will be inserted?
            const path = [...liPath, 1, 0];
            insertElements(editor, newSublists[0].children , {
              at: path,
              select: true,
            });
          } else {
            insertElements(editor, newSublists, {
              at: Path.next(licPath),
              select: true,
            });
          }
        }

        textNode = { text: '' };
        listItemNodes = rest ;
      } else {
        textNode = { text: '' };
        listItemNodes = [first , ...(rest )];
      }
    } else {
      textNode = first ;
      listItemNodes = rest ;
    }

    return { textNode, listItemNodes };
  };

  return (fragment) => {
    let liEntry = findNode(editor, {
      match: { type: listItemType },
      mode: 'lowest',
    });
    // not inserting into a list item, delegate to other plugins
    if (!liEntry) {
      return insertFragment(
        isListRoot(editor, fragment[0]) ? [{ text: '' }, ...fragment] : fragment
      );
    }

    // delete selection (if necessary) so that it can check if needs to insert into an empty block
    insertFragment([{ text: '' }] );

    // refetch to find the currently selected LI after the deletion above is performed
    liEntry = findNode(editor, {
      match: { type: listItemType },
      mode: 'lowest',
    });

    // Check again if liEntry is undefined after the deletion above.
    // This prevents unexpected behavior when pasting while a list is highlighted
    if (!liEntry) {
      return insertFragment(
        isListRoot(editor, fragment[0]) ? [{ text: '' }, ...fragment] : fragment
      );
    }

    const licEntry = findNode(editor, {
      match: { type: listItemContentType },
      mode: 'lowest',
    });
    if (!licEntry) {
      return insertFragment(
        isListRoot(editor, fragment[0]) ? [{ text: '' }, ...fragment] : fragment
      );
    }

    const { textNode, listItemNodes } = getTextAndListItemNodes(
      fragment,
      liEntry,
      licEntry
    );

    insertFragment([textNode]); // insert text if needed

    const [, liPath] = liEntry;

    return insertElements(editor, listItemNodes, {
      at: Path.next(liPath),
      select: true,
    });
  };
};
