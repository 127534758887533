import React, { useEffect } from "react";
import { Navigate, Outlet } from 'react-router-dom';

// const roles = ["Testing 1", "Testing 2"]
const getAuth = () => {

  const user = sessionStorage.getItem('user')
  if (user !== null) {
    return true
  } else {
    return false
  }
}

const getRole = () => {
  let roles = []

  const role = sessionStorage.getItem('role')?.split(',')
  if (role?.length > 0) {
    role.forEach((role) => {
      roles.push(parseInt(role, 10))
    })
    return roles
  }
  else
    return roles

}





const PrivateRoute = ({ roles, routes }) => {

  let auth = getAuth()
  let role = getRole()
  let rolesList = roles.length !== 0 ? roles : role
  useEffect(() => {



  }, [])

  // const filters = { roleId: role };
  // let roleBasedRouting = null;
  // if (filters && filters.roleId.length > 0)
  //   roleBasedRouting = filterPlainArray(routingpaths, filters);//Remove filter if API

  return role?.find(role => rolesList?.includes(role)) ? (
    <>

      <Outlet context={{ routes: routes ? routes : [] }} />
    </>
  ) : auth ? (
    <>

      <Navigate to="/unauthorized" />
    </>)
    : (
      <>

        <Navigate to="/" />
      </>)
}


export default PrivateRoute;
