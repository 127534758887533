import React, { Component } from "react";
import styled, { css } from "styled-components";
import "./App.css";

const SEARCH_BUTTON_EDGE = 35;

const searchFieldStyle = {
  display: "inline-flex",
  border: "1px #ddd solid",
  height: SEARCH_BUTTON_EDGE,
  justifyContent: "space-between",
};

const searchFieldInputStyle = {};

const commonInputStyle = css`
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  background: #fff;
  border: 1px solid #48336e;
  border-radius: 5px 0px 0px 5px;
  padding: 1.6%;
  color: #555;
  &:focus {
    ${"" /* box-shadow: 0 0 5px */}
    ${(props) =>
    props.formBorderColorOnFocus ? props.formBorderColorOnFocus : "#48336e"};
    padding: 1.6%;
    border: 2px solid
      ${(props) =>
    props.formBorderColorOnFocus
      ? props.formBorderColorOnFocus
      : "#48336e"};
  }
  ${(props) => {
    return (
      props.invalid &&
      props.shouldValidate &&
      props.touched &&
      css`
        border: 3px solid
          ${(props) => (props.errorTextColor ? props.errorTextColor : "red")} !important;
        outline: none !important;
        box-shadow: none !important;
      `
    );
  }}
`;

const Input = styled.input`
  ${commonInputStyle}
`;

const searchFieldButtonStyle = {
  width: SEARCH_BUTTON_EDGE - 2,
  outline: "none",
  backgroundColor: "white",
  cursor: "pointer",
  boxSizing: "border-box",
  appearance: "none",
  border: "1px solid #48336e",
  borderRadius: "0px 5px 5px 0px",
  borderLeft: "none",
};
const disabledSearchFieldButtonStyle = {
  cursor: 'not-allowed',
  border: "2px solid rgba(118, 118, 118, 0.3)",
        backgroundColor: 'rgba(60, 60, 60, 0.06)',
        color: 'rgb(170, 170, 170)',
        borderColor: 'rgba(118, 118, 118, 0.3)',
        width: SEARCH_BUTTON_EDGE - 2,
        outline: "none",
        appearance: "none",
        borderRadius: "0px 5px 5px 0px",
        borderLeft: "none",
};

const SearchIcon = () => {
  const iconEdge = Math.ceil(SEARCH_BUTTON_EDGE * 0.6);
  const searchIconStyle = {
    fill: "#48336e",
    fontSize: "20px",
    margin: "5px"
  };
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={iconEdge}
      height={iconEdge}
      viewBox="0 0 635 635"
      style={searchIconStyle}
    >
      <g>
        <path
          d="M255.108,0C119.863,0,10.204,109.66,10.204,244.904c0,135.245,109.659,244.905,244.904,244.905
          c52.006,0,100.238-16.223,139.883-43.854l185.205,185.176c1.671,1.672,4.379,1.672,5.964,0.115l34.892-34.891
          c1.613-1.613,1.47-4.379-0.115-5.965L438.151,407.605c38.493-43.246,61.86-100.237,61.86-162.702
          C500.012,109.66,390.353,0,255.108,0z M255.108,460.996c-119.34,0-216.092-96.752-216.092-216.092
          c0-119.34,96.751-216.091,216.092-216.091s216.091,96.751,216.091,216.091C471.199,364.244,374.448,460.996,255.108,460.996z"
        />
      </g>
    </svg>
  );
};

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: props.elementConfig.lookupConfig.data,
      UpdatedList: props.elementConfig.lookupConfig.data,
      isHidden: true,
      value: "",
      selectedObject: {},
    };
  }

  TableList = (props) => (
    <>
      <div
        className={`${this.state.isHidden ? "list-example-hidden" : "list-example"
          }`}
      >
        <div className="list-header">
          <ul className="custom-ul">
            {props.elementConfig.lookupConfig.dataColumn.map((col, idx) =>
              props.elementConfig.lookupConfig.populateFields.map(
                (colName, i) => {
                  if (col === colName.masterDataCol) {
                    return (
                      <li
                        key={i + idx}
                        className="custom-li"
                        style={{ fontWeight: "bolder" }}
                      >
                        {colName.varName
                          ? colName.varName.toUpperCase()
                          : colName.varName}
                      </li>
                    );
                  } else return null;
                }
              )
            )}
          </ul>
        </div>
        <div className="list-body">
          {props.elementConfig.lookupConfig.updatedData &&
            props.elementConfig.lookupConfig.updatedData.map((obj, index) => (
              <ul className="custom-ul" key={index}>
                {props.elementConfig.lookupConfig.dataColumn.map((_, i) => (
                  <li
                    className="custom-li"
                    data-obj={JSON.stringify(obj)}
                    data-value={obj[props.elementConfig.lookupConfig.lookupId]}
                    onMouseDown={(ev) => {
                      this.setState({ isHidden: true });
                      props.rowClicked(ev, obj);
                    }}
                    key={index + i}
                  >
                    {obj[props.elementConfig.lookupConfig.dataColumn[i]]}
                  </li>
                ))}
              </ul>
            ))}
        </div>
      </div>
    </>
  );

  handleSearchFieldClick = () => {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  };

  render() {
    const { classNames } = this.props;
    const className = `react-search-field ${classNames}`;
    return (
      <div
        className="react-search-field-demo "
        disabled={this.props.disabled}
      >
        <div>
          <div style={{ display: "flex", marginBottom: "1.5%", height: "30px" }}>
            <Input
              id={this.props.id}
              type={this.props.type}
              style={this.props.style}
              autoComplete="off"
              disabled={this.props.disabled}
              onClick={() => this.setState({ isHidden: false })}
              onChange={(e) => {
                this.setState({ isHidden: false });
                this.props.changed(e);
              }}
              onKeyDown={(e) => this.props.searchChanged(e)}
              onBlur={(e) => {
                this.setState({ isHidden: true });
                this.props.changed(e);
              }}
              data-obj={this.state.selectedObject}
              placeholder={this.props.elementConfig.placeholder}
              value={this.props.value}
              ref={(input) => (this.myinput = input)}
              label={this.props.label}
              variant={this.props.variant}
            />
            <button
              type="button"
              disabled={this.props.disabled}
              aria-label="search button"
              style={this.props.disabled? disabledSearchFieldButtonStyle:searchFieldButtonStyle}
              onClick={this.handleSearchFieldClick}
            
            >
              <SearchIcon />
            </button>
          </div>
          <this.TableList
            data-table={this.state.updatedobj}
            data={this.props.data}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}
