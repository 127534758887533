export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (name, value, rules, fields, elementType) => {
  let isValid = true;
  let isMandatory = false;
  let isFixed = true;
  if (!rules) {
    return true;
  }
  if (rules.required && !rules.isConditional && elementType !== "date") {
    isValid = value.toString().trim() !== "" && isValid;
    isValid ? (rules.error = "") : (rules.error = `${name} is required`);
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
      ? (rules.error = `${name} is required`)
      : (rules.error = `${name} should be be atleast ${rules.minLength} characters long`);
  }

  if (rules.maxLength && isValid === true) {
    isValid = value.length <= rules.maxLength && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
      ? (rules.error = `${name} is required`)
      : (rules.error = `${name} should be be atmost ${rules.maxLength} characters long`);
  }

  if (rules.isEmail) {
    let pattern_match = true;
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (value.trim() !== "") {
      pattern_match = pattern.test(value);
      if (!pattern_match) {
        rules.error = `${name} is invalid`;
        isValid = pattern_match && isValid;
      }
    }
  }
  if (rules.isPan) {
    const pattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    isValid = pattern.test(value) && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
      ? (rules.error = `${name} is required`)
      : (rules.error = `${name} is invalid`);
  }

  if (rules.rejex) {
    const pattern = new RegExp(rules.rejex);
    let pattern_match = true;
    if (value.trim() !== "") {
      pattern_match = pattern.test(value);
      if (!pattern_match) {
        rules.error = `${name} is invalid`;
        isValid = pattern_match && isValid;
      }
    }
  }

  if (rules.isNumeric) {
    const pattern = /^((?!(0))[0-9]*)$/;
    isValid = pattern.test(value) && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
      ? (rules.error = `${name} is required`)
      : value.length > rules.maxLength && pattern.test(value)
      ? (rules.error = `${name} should be be atmost ${rules.maxLength} characters long`)
      : value.length < rules.minLength && pattern.test(value)
      ? (rules.error = `${name} should be be atleast ${rules.minLength} characters long`)
      : (rules.error = `${name} should be only numeric and first character can not be zero`);
  }
  if (rules.isConditional && rules.conditions) {
    //TODO: Currently supports only one field however we might need to add the option for multiple fields
    // Even in the multiple fields AND and OR condition needs to be added.
    if (rules.conditions.conditionType === "OR") {
      isFixed = false;
      rules.conditions.conditions.map((c) => {
        if (isFixed === false && fields[c.id]) {
          if (c.id && c.value && c.operator) {
            if (
              fields[c.id].validation.isNumeric === true &&
              !Array.isArray(c.value)
            ) {
              switch (c.operator) {
                case "equal":
                  isFixed = parseInt(fields[c.id].value) === c.value; //fields[c.parent][c.id].value
                  break;
                case "greaterthan":
                  isFixed = parseInt(fields[c.id].value) > c.value;
                  break;
                case "lesserthan":
                  isFixed = parseInt(fields[c.id].value) < c.value;
                  break;
                case "greaterthanorequal":
                  isFixed = parseInt(fields[c.id].value) >= c.value;
                  break;
                case "lesserthanorequal":
                  isFixed = parseInt(fields[c.id].value) <= c.value;
                  break;
                case "notequal":
                  isFixed = fields[c.id].value !== c.value;
                  break;
                case "default":
                  isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                  break;
                default:
                  break;
              }
            } else if (
              fields[c.id].validation.isNumeric === false &&
              !Array.isArray(c.value)
            ) {
              switch (c.operator) {
                case "equal":
                  isFixed =
                    fields[c.id].value.localeCompare(c.value) === 0
                      ? true
                      : false;
                  break;
                case "notequal":
                  isFixed = fields[c.id].value !== c.value;
                  break;
                case "contains":
                  isFixed = fields[c.id].value.includes(c.value);
                  break;
                case "default":
                  isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                  break;
                default:
                  break;
              }
            } else {
              switch (c.operator) {
                case "includes":
                  isFixed = c.value.includes(fields[c.id].value);
                  break;
                case "equal":
                  isFixed =
                    fields[c.id].value.localeCompare(c.value) === 0
                      ? true
                      : false;
                  break;
                case "notequal":
                  isFixed = fields[c.id].value !== c.value;
                  break;
                case "contains":
                  isFixed = fields[c.id].value.includes(c.value);
                  break;
                case "default":
                  isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                  break;
                default:
                  break;
              }
            }
          }
        }
      });
    } else if (rules.conditions.conditionType === "AND") {
      rules.conditions.conditions.map((c) => {
        if (isFixed && fields[c.id]) {
          if (c.id && c.value && c.operator) {
            if (
              fields[c.id].validation.isNumeric === true &&
              !Array.isArray(c.value)
            ) {
              switch (c.operator) {
                case "equal":
                  isFixed = parseInt(fields[c.id].value) === c.value;
                  break;
                case "greaterthan":
                  isFixed = parseInt(fields[c.id].value) > c.value;
                  break;
                case "lesserthan":
                  isFixed = parseInt(fields[c.id].value) < c.value;
                  break;
                case "greaterthanorequal":
                  isFixed = parseInt(fields[c.id].value) >= c.value;
                  break;
                case "lesserthanorequal":
                  isFixed = parseInt(fields[c.id].value) <= c.value;
                  break;
                case "notequal":
                  isFixed = fields[c.id].value !== c.value;
                  break;
                case "default":
                  isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                  break;
                default:
                  break;
              }
            } else if (
              fields[c.id].validation.isNumeric === false &&
              !Array.isArray(c.value)
            ) {
              switch (c.operator) {
                case "equal":
                  isFixed =
                    fields[c.id].value.localeCompare(c.value) === 0
                      ? true
                      : false;
                  break;
                case "notequal":
                  isFixed = fields[c.id].value !== c.value;
                  break;
                case "contains":
                  isFixed = fields[c.id].value.includes(c.value);
                  break;
                case "default":
                  isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                  break;
                default:
                  break;
              }
            } else {
              switch (c.operator) {
                case "includes":
                  isFixed = c.value.includes(fields[c.id].value);
                  break;
                case "equal":
                  isFixed =
                    fields[c.id].value.localeCompare(c.value) === 0
                      ? true
                      : false;
                  break;
                case "notequal":
                  isFixed = fields[c.id].value !== c.value;
                  break;
                case "contains":
                  isFixed = fields[c.id].value.includes(c.value);
                  break;
                case "default":
                  isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                  break;
                default:
                  break;
              }
            }
          }
        }
      });
    } else {
      if (rules.conditions.conditions) {
        rules.conditions.conditions.map((c) => {
          if (isFixed && fields[c.id]) {
            if (c.id && c.value && c.operator) {
              if (
                fields[c.id].validation.isNumeric === true &&
                !Array.isArray(c.value)
              ) {
                switch (c.operator) {
                  case "equal":
                    isFixed = parseInt(fields[c.id].value) === c.value;
                    break;
                  case "greaterthan":
                    isFixed = parseInt(fields[c.id].value) > c.value;
                    break;
                  case "lesserthan":
                    isFixed = parseInt(fields[c.id].value) < c.value;
                    break;
                  case "greaterthanorequal":
                    isFixed = parseInt(fields[c.id].value) >= c.value;
                    break;
                  case "lesserthanorequal":
                    isFixed = parseInt(fields[c.id].value) <= c.value;
                    break;
                  case "notequal":
                    isFixed = fields[c.id].value !== c.value;
                    break;
                  case "default":
                    isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                    break;
                  default:
                    break;
                }
              } else if (
                fields[c.id].validation.isNumeric === false &&
                !Array.isArray(c.value)
              ) {
                switch (c.operator) {
                  case "equal":
                    isFixed =
                      fields[c.id].value.localeCompare(c.value) === 0
                        ? true
                        : false;
                    break;
                  case "notequal":
                    isFixed = fields[c.id].value !== c.value;
                    break;
                  case "contains":
                    isFixed = fields[c.id].value.includes(c.value);
                    break;
                  case "default":
                    isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                    break;
                  default:
                    break;
                }
              }
              // if (
              //   Array.isArray(c.value)
              //   &&
              //   (fields[c.id].validation.isNumeric === true ||
              //     fields[c.id].validation.isNumeric === false)
              // )
              else {
                switch (c.operator) {
                  case "includes":
                    isFixed = c.value.includes(fields[c.id].value);
                    break;
                  case "equal":
                    isFixed =
                      fields[c.id].value.localeCompare(c.value) === 0
                        ? true
                        : false;
                    break;
                  case "notequal":
                    isFixed = fields[c.id].value !== c.value;
                    break;
                  case "contains":
                    isFixed = fields[c.id].value.includes(c.value);
                    break;
                  case "default":
                    isFixed = true; //TODO: Need to verify whichvalue would be the default..true or false
                    break;
                  default:
                    break;
                }
              }
            }
          }
        });
      }
    }
    isMandatory = isFixed;
    if (isMandatory) {
      isValid = value.trim() !== "" && isValid;
      isValid ? (rules.error = "") : (rules.error = `${name} is required`);
    }
  }

  return isValid;
};

export const calculateExpiry = (startDate, days) => {
  let sDate = new Date(startDate);
  let eDate = new Date(sDate.setDate(sDate.getDate() + days));
  return eDate.toLocaleDateString("en-us");
};

export const sortObject = (unsortedObj) => {};
export const quickSort = (arr, obj, length = arr.length - 1, start = 0) => {
  if (arr.length < 2) return arr; // base case

  const pivot = arr[arr.length - 1]; //pivot value
  const left = []; // left handside array
  const right = []; // right handside array

  while (start < length) {
    // comparing and pushing
    if (obj[arr[start]].sequenceNo < obj[arr[arr.length - 1]].sequenceNo) {
      left.push(arr[start]);
    } else {
      right.push(arr[start]);
    }
    start++; //  incrementing start value
  }
  // calling quick sort recursively
  return [...quickSort(left, obj), pivot, ...quickSort(right, obj)];
};
