import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";

import {
  getCrudApiNego,
  putCrudApiNego,
} from "../../webServiceCalls/webServiceCall";
import "./Negotiation.css";
import { RecoilRoot } from "recoil";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import PlateEditor from "../PlateEditor/components/editorSlate";
import PlateEditor from "../../PlateEditor/PlateEditor";
import Footer from "../shared/Footer";
import HtmlDiff from "htmldiff-js";
import applogo from "../../assets/images/Contrace_New.svg";
import Loading from "../../Loading/Loading";
import TimeExpired from "../../assets/images/time_expired.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Negotiation = () => {
  const [currntemplateData, setcurrnTemplateData] = useState("");
  const [prevtemplateData, setprevTemplateData] = useState("");
  const [NegotiationCode, setNegotiationCode] = useState("");
  const [curncomments, setcurncomments] = useState(null);
  const [prevcomments, setprevcomments] = useState([]);
  const [messageForNego, setMessageForNego] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [formData, setFormData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageSize, setPageSize] = useState("A4");

  const plateRef = React.useRef();
  const prevplateRef = React.useRef();

  useEffect(() => {
    //TODO - api call to get initial request info table
    setcontractdata();
    //TODO - set current username from redux
    //setCurrUserName();
  }, []);

  const displayDraftedMessage = () => {
    toast.success("Drafted Successfully!", {
      className: "toaststyle",
    });
  };

  const displaySentNegoMessage = () => {
    toast.success("Successfully Sent to Negotiate!", {
      className: "toaststyle",
    });
  };

  const displayCompleteNegoMessage = () => {
    toast.success("Successfully completed Negotiation!", {
      className: "toaststyle",
    });
  };

  const setcontractdata = async () => {
    setIsLoading(true);
    let query = new URLSearchParams(window.location.search);
    const Code = query.get("negotiation");
    setNegotiationCode(Code);
    if (Code !== null) {
      var decoded = jwt_decode(Code);

      let dataResult;
      let result = getCrudApiNego("auth/contractByUuid/" + Code.toString(), {});
      await result.then(function (res) {
        dataResult = res;
      });
      if (dataResult.length !== 0) {
        setMessageForNego(false);
        setcurrnTemplateData(dataResult.currentVersion);
        if (dataResult.prevVersion !== null) {
          setprevTemplateData(
            HtmlDiff.execute(dataResult.prevVersion, dataResult.currentVersion)
          );
        } else {
          setprevTemplateData("");
        }
        if (dataResult.negotiationComments) {
          setprevcomments(JSON.parse(dataResult.negotiationComments));
        }
        if (dataResult.currentNegotiationComments) {
          setcurncomments(JSON.parse(dataResult.currentNegotiationComments));
        }

        // setTemplateData(JSON.parse(dataResult.finalDocument.finalDocument));
        // setFormData(dataResult.contractFormData.var);
      } else {
        setMessageForNego(true);
      }
    }

    setIsLoaded(true);
    setIsLoading(false);
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const NegotiationClick = async () => {
    setIsLoading(true);
    // has to be changed for execute handlebar
    let curnTemplateData = plateRef.current.serailizedContractData();
    let curnCommentsData = plateRef.current.getComments();

    setcurrnTemplateData(curnTemplateData);
    setcurncomments(curnCommentsData);
    let currnTemplateJson = {
      finalDocument: curnTemplateData,
    };

    let negoComments = {
      negotiationComments: curnCommentsData,
    };
    let negotiationJson = {
      negotiationDoc: currnTemplateJson,
      contractStatus: 8,
      negotiationComments: negoComments,
      isActive: 2,
    };
    let dataResult;
    let result = putCrudApiNego(
      "auth/negotiation/" + NegotiationCode.toString(),
      negotiationJson
    );
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      displaySentNegoMessage();
    }
    await delay(2000);
    setIsLoading(false);
    window.location.reload();
  };
  const NegotiationDraft = async () => {
    setIsLoading(true);
    let curnTemplateData = plateRef.current.serailizedContractData();
    let curnCommentsData = plateRef.current.getComments();
    setcurrnTemplateData(curnTemplateData);
    setcurncomments(curnCommentsData);

    let currnTemplateJson = {
      finalDocument: curnTemplateData,
    };
    let negoComments = {
      negotiationComments: curnCommentsData,
    };
    let negotiationJson = {
      negotiationDoc: currnTemplateJson,
      negotiationComments: negoComments,
    };
    let dataResult;
    let result = putCrudApiNego(
      "auth/negotiation/" + NegotiationCode.toString(),
      negotiationJson
    );
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      displayDraftedMessage();
    }
    setIsLoading(false);
  };
  const NegotiationCompleted = async () => {
    setIsLoading(true);
    let curnTemplateData = plateRef.current.serailizedContractData();
    let curnCommentsData = plateRef.current.getComments();
    setcurrnTemplateData(curnTemplateData);
    setcurncomments(curnCommentsData);

    let currnTemplateJson = {
      finalDocument: curnTemplateData,
    };
    let negoComments = {
      negotiationComments: curnCommentsData,
    };
    let negotiationJson = {
      negotiationDoc: currnTemplateJson,
      contractStatus: 6,
      negotiationComments: negoComments,
      isActive: 2,
    };
    let dataResult;
    let result = putCrudApiNego(
      "auth/negotiation/" + NegotiationCode.toString(),
      negotiationJson
    );
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      displayCompleteNegoMessage();
    }
    await delay(2000);
    setIsLoading(false);
    window.location.reload();
  };
  const setNewContent = (content) => {
    setcurrnTemplateData(content.join());
  };

  // setCommentsJson={(val) => {
  //   this.setState({
  //     commentJSON: val,
  //   });
  // }}
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "https://www.contracedev.cipheracetech.com/Logo_Small.png";
  }, []);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="container-scroller">
            <div
              className=" page-body-wrapper-nego"
              style={{ display: "initial" }}
            >
              <div className="nego-header-div">
                <LazyLoadImage
                  effect="blur"
                  className="nego-logo"
                  src={applogo}
                  alt="Contrace"
                />

                <div className="nego-header">
                  <label>Contract Management</label>
                </div>
              </div>

              <div className="main-panel" style={{ paddingTop: "unset" }}>
                <div className="content-wrapper">
                  <div className="nego-Body">
                    {!messageForNego ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <div style={{ margin: " 15px auto" }}>
                            <button
                              className="NegoButton"
                              onClick={() => NegotiationDraft()}
                            >
                              Draft
                            </button>
                            <button
                              className="NegoButton"
                              onClick={() => NegotiationClick()}
                            >
                              Negotiate
                            </button>
                            <button
                              className="NegoButton"
                              onClick={() => NegotiationCompleted()}
                            >
                              Negotiation Completed
                            </button>
                          </div>
                        </div>
                        <div style={{ backgroud: "white" }}>
                          {isLoaded ? (
                            <div className="nego-editor-div">
                              <div className="editor-negotiation">
                                <RecoilRoot>
                                  <PlateEditor
                                    id={
                                      prevtemplateData !== ""
                                        ? "negotiationEditor"
                                        : "singlenegotiationEditor"
                                    }
                                    ref={plateRef}
                                    commentJSON={curncomments}
                                    isNegotiationDisabled={false}
                                    isVariableDisabled={true}
                                    isPartiesDisabled={true}
                                    isConditionDisabled={true}
                                    isConditionToggleDisabled={true}
                                    isCommentToggleDisabled={false}
                                    isClauseDisabled={true}
                                    contractTemplateJsonData={
                                      currntemplateData
                                        ? currntemplateData
                                        : null
                                    }
                                    isEditable={false}
                                    setCommentsJson={(val) => {
                                      setcurncomments(val);
                                    }}
                                    canAddComments={true}
                                    serializeCall={"serializeContract"}
                                    pageSize = {pageSize}
                                  />
                                </RecoilRoot>
                              </div>
                              {prevtemplateData !== "" ? (
                                <div className="editor-negotiation">
                                  <RecoilRoot>
                                    <PlateEditor
                                      id={"negotiationDiffEditor"}
                                      ref={prevplateRef}
                                      style={{ marginLeft: "auto" }}
                                      commentJSON={prevcomments}
                                      isNegotiationDisabled={true}
                                      isVariableDisabled={true}
                                      isPartiesDisabled={true}
                                      isConditionDisabled={true}
                                      isConditionToggleDisabled={true}
                                      isCommentToggleDisabled={false}
                                      isClauseDisabled={true}
                                      contractTemplateJsonData={
                                        prevtemplateData
                                          ? prevtemplateData
                                          : null
                                      }
                                      isEditable={false}
                                      canAddComments={true}
                                      canComment={false}
                                      serializeCall={"serializeContract"}
                                      pageSize = {pageSize}
                                    />
                                  </RecoilRoot>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <p className="nego-expired">
                          This link has expired please contact the person who
                          sent the link
                        </p>
                        <div>
                          {" "}
                          <LazyLoadImage
                            effect="blur"
                            src={TimeExpired}
                            alt="Contrace"
                            height={500}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
          <div>
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              theme="colored"
            />
          </div>
        </>
      )}
    </>
  );
};
export default Negotiation;
