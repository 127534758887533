function getCurrentDateAndTime() {
  let today = new Date();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  let AMOrPM = hours > 12 ? "PM" : "AM";
  hours = hours > 12 ? hours - 12 : hours;
  let currentDate =
    today.toDateString() +
    " - " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    " " +
    AMOrPM;
  return currentDate;
}
function insertSignatureBlock(signature) {
  //insert the signature component to the editor at the caret position
  let div = document.createElement("div");
  div.setAttribute("id", `signBlock${signature.id}`);
  div.setAttribute(
    "class",
    `signBlockClass${signature.id} signature-block-styling`
  );
  div.setAttribute("contenteditable", false);
  let sign = `<div className="sig-identifier-container">caSignerSign${signature.id}</div><hr><div className="sig-heading-container">Signature</div>`;
  let fullName = `<div className="sig-identifier-container">caSignerName${signature.id}</div><hr><div className="sig-heading-container">Full Name</div>`;
  let date = `<div className="sig-identifier-container">caSignerDate${signature.id}</div><hr><div className="sig-heading-container">Date Signed</div>`;

  div.innerHTML = sign + fullName + date;
  let sel = window.getSelection();
  if (sel.rangeCount > 0) {
    let seltn = sel.getRangeAt(0);
    seltn.insertNode(div);
  }
}
// function insertSignatureBlockSlate(signature) {
//   //insert the signature component to the editor at the caret position
//   let div = document.createElement("div");
//   div.setAttribute("id", `signBlock${signature.id}`);
//   div.setAttribute(
//     "class",
//     `signBlockClass${signature.id} signature-block-styling`
//   );
//   div.setAttribute("contenteditable", false);
//   let sign = `<div className="sig-identifier-container">caSignerSign${signature.id}</div><hr><div className="sig-heading-container">Signature</div>`;
//   let fullName = `<div className="sig-identifier-container">caSignerName${signature.id}</div><hr><div className="sig-heading-container">Full Name</div>`;
//   let date = `<div className="sig-identifier-container">caSignerDate${signature.id}</div><hr><div className="sig-heading-container">Date Signed</div>`;

//   div.innerHTML = sign + fullName + date;
//   let sel = window.getSelection();
//   if (sel.rangeCount > 0) {
//     let seltn = sel.getRangeAt(0);
//     seltn.insertNode(div);
//   }
// }

function cleanWordPaste(in_word_text) {
  // this piece converts line breaks into break tags
  // and removes the seemingly endless crap code
  var newString = in_word_text.replace(/\n\n/g, "<br />");
  newString = newString.replace(/<head[^>]*>([^]*)<\/head>/g, "");
  newString = newString
    .replace(/<script[^>]*>([^]*)<\/script>/g, "")
    .replace(/.*<!--.*-->/g, "");
  // this next piece removes any break tags (up to 10) at beginning
  for (let i = 0; i < 10; i++) {
    if (newString.substr(0, 6) === "<br />") {
      newString = newString.replace("<br />", "");
    }
  }
  return newString;
}

function getCorrespondingValueForOperators(text) {
  switch (text) {
    case "greaterthan":
      return "Greater Than";
    case "lesserthan":
      return "Lesser Than";
    case "greaterthanorequal":
      return "Greater Than or Equal";
    case "lessthanorequal":
      return "Lesser Than or Equal";
    case "notequal":
      return "Not Equal";
    case "equal":
      return "Equal";
    case "notcontains":
      return "Not Contains";
    case "contains":
      return "Contains";
    case "or":
      return "OR";
    case "and":
      return "AND";
    default:
      return null;
  }
}

function containsObject(list) {
  var i;
  if (list != null)
    for (i = 0; i < list.length; i++) {
      if (list[i].value === "conditional") {
        return true;
      }
    }
  return false;
}

function convertBasicCSVToArrOfObject(a) {
  let arrayOfOptions = [];
  var options = a.replace(/ /g, "").split(",");
  options.forEach((option) => {
    let optionsObject = {};
    optionsObject.value = option;
    optionsObject.displayValue = option;
    arrayOfOptions.push(optionsObject);
  });
  return arrayOfOptions;
}

function isSelectionCodeBlock() {
  var sel;
  if (window.getSelection) {
    sel = window.getSelection();
  } else if (document.getSelection) {
    sel = document.getSelection();
  }

  var raw_html = getSelectionAsHtml();

  // return false if nothing is selected
  if (raw_html === "") return false;

  var tempDiv = document.createElement("div");
  tempDiv.innerHTML = raw_html;

  var is_code_nodes = [];
  for (var node of tempDiv.childNodes) {
    var tags = [node.nodeName.toLowerCase()];

    // This covers selection that are inside bolded characters
    while (tags.includes("#text")) {
      var start_tag = sel.anchorNode.parentNode.nodeName.toLowerCase();
      var end_tag = sel.focusNode.parentNode.nodeName.toLowerCase();

      tags = [start_tag, end_tag];
    }

    is_code_nodes.push(containsOnly(["code"], tags));
  }

  return !is_code_nodes.includes(false);
}

function getSelectionAsHtml() {
  var html = "";
  if (typeof window.getSelection != "undefined") {
    var sel = window.getSelection();
    if (sel.rangeCount) {
      var container = document.createElement("div");
      for (var i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML;
    }
  } else if (typeof document.selection != "undefined") {
    if (document.selection.type === "Text") {
      html = document.selection.createRange().htmlText;
    }
  }
  return html;
}

function containsOnly(array1, array2) {
  return !array2.some((elem) => !array1.includes(elem));
}

function pasteHtmlAtCaret(html, selectPastedContent) {
  var sel, range;
  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();

      // Range.createContextualFragment() would be useful here but is
      // only relatively recently standardized and is not supported in
      // some browsers (IE9, for one)
      var el = document.createElement("div");
      el.innerHTML = html;
      var frag = document.createDocumentFragment(),
        node,
        lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      var firstNode = frag.firstChild;
      range.insertNode(frag);

      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        if (selectPastedContent) {
          range.setStartBefore(firstNode);
        } else {
          range.collapse(true);
        }
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  } else if ((sel = document.selection) && sel.type !== "Control") {
    // IE < 9
    var originalRange = sel.createRange();
    originalRange.collapse(true);
    sel.createRange().pasteHTML(html);
    if (selectPastedContent) {
      range = sel.createRange();
      range.setEndPoint("StartToStart", originalRange);
      range.select();
    }
  }
}

function saveSelection() {
  if (window.getSelection) {
    var sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0);
    }
  } else if (document.selection && document.selection.createRange) {
    return document.selection.createRange();
  }
  return null;
}

function restoreSelection(range) {
  if (range) {
    if (window.getSelection) {
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.selection && range.select) {
      range.select();
    }
  }
}

function extractContent(s, space) {
  var span = document.createElement("span");
  span.innerHTML = s;
  if (space) {
    var children = span.querySelectorAll("*");
    for (var i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += " ";
      else children[i].innerText += " ";
    }
  }
  return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
}

export {
  insertSignatureBlock,
  extractContent,
  saveSelection,
  pasteHtmlAtCaret,
  isSelectionCodeBlock,
  convertBasicCSVToArrOfObject,
  containsObject,
  getCorrespondingValueForOperators,
  cleanWordPaste,
  restoreSelection,
  getCurrentDateAndTime,
};
