import React from "react";
import "./Report.css";
import ComingSoon from "../../assets/images/ComingSoon.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Report() {
  return (
    <section className="page_report">
      <div className="page_report-container">
        <div className="four_zero_four_bg">
          <LazyLoadImage
            effect="blur"
            src={ComingSoon}
            alt="Error"
            className="page_report-img"
          />
        </div>
      </div>
    </section>
  );
}
