import React, { useEffect, useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { setcontractTypeData } from "../../../redux/Reducers/contractTypeReducer";
import { useSelector } from "react-redux";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import { validateToken } from "../../../Helpers/validateToken";
import { convertHierarchy } from "../../../Helpers/hierarchyConverter";
import { mapParent } from "../../../Helpers/hierarchyHelper";
import SyfedTreeSelect from "syfed-tree-select";
import "../contractTypes/ContractTypes.css";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import Loading from "../../../Loading/Loading";
export default function ContractTypes() {
  const [addClicked, setAddClicked] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");
  const treeData = useSelector(
    (state) => state.contractTypeState.value.contractTypeData
  );
  const [page, setPage] = useState(0);
  const [NewOrUpdate, setNewOrUpdate] = useState("");
  const [SelectedRow, setSelectedRow] = useState({});
  const [selectedParent, setselectedParent] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(1);
  const [dis, setdis] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [catError, setCatError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [disCat, setDisCat] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const displayUpdatedMessage = () => {
    toast.success("Updated Successfully!", {
      className: "toaststyle",
    });
  };
  const displayAddedMessage = () => {
    toast.success("Added Successfully!", {
      className: "toaststyle",
    });
  };

  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };

  const StatusData = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "InActive",
      value: 2,
    },
  ];
  const dispatch = useDispatch();
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "contractTypeId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  const categoryData = useSelector(
    (state) => state.categoryState.value.categoryData
  );

  async function OnAddNodeClose() {
    setIsLoading(true);
    setdis(true);
    setDisCat(true);
    let contractJson = {
      contractType: nodeTitle,
      parentContractTypeId: selectedParent,
      statusId: selectedStatus,
      catogeryTypeId: selectedCategory,
    };
    validateToken();
    await postCrudApi("contractTypes/contractTypes", contractJson);
    let dataResult;
    validateToken();
    let result = getCrudApi("contractTypes/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      let contractTypeArr = mapParent(
        dataResult,
        "parentContractTypeId",
        "contractTypeId",
        "contractType"
      );
      let updatedContract = [...contractTypeArr];
      let contractTypearr = [];

      updatedContract.map((con, id) => {
        let filteredCategoryType = categoryData.filter((cat) => {
          if (cat.catogeryTypeId === con.catogeryTypeId) return true;
          else return false;
        });
        let localCon = { ...con };
        localCon.catogeryType =
          filteredCategoryType.length !== 0
            ? filteredCategoryType[0].catogeryType
            : "";
        contractTypearr.push(localCon);
      });
      dispatch(setcontractTypeData(contractTypearr));
    }
    setdis(false);
    setDisCat(false);
    setAddClicked(false);
    displayAddedMessage();
    setIsLoading(false);
  }
  async function OnUpdateNodeClose() {
    setIsLoading(true);
    setdis(true);
    setDisCat(true);
    let contractJson = {
      contractType: nodeTitle,
      parentContractTypeId:
        selectedParent === SelectedRow.rowData[1]
          ? SelectedRow.rowData[5]
          : selectedParent,
      statusId: selectedStatus,
      catogeryTypeId: selectedCategory,
    };
    validateToken();
    await putCrudApi("contractTypes/" + SelectedRow.rowData[1], contractJson);
    let dataResult;
    validateToken();
    let result = getCrudApi("contractTypes/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      let contractTypeArr = mapParent(
        dataResult,
        "parentContractTypeId",
        "contractTypeId",
        "contractType"
      );
      let updatedContract = [...contractTypeArr];
      let contractTypearr = [];

      updatedContract.map((con, id) => {
        let filteredCategoryType = categoryData.filter((cat) => {
          if (cat.catogeryTypeId === con.catogeryTypeId) return true;
          else return false;
        });
        let localCon = { ...con };
        localCon.catogeryType =
          filteredCategoryType.length !== 0
            ? filteredCategoryType[0].catogeryType
            : "";
        contractTypearr.push(localCon);
      });
      dispatch(setcontractTypeData(contractTypearr));
    }
    setdis(false);
    setDisCat(false);
    setAddClicked(false);
    displayUpdatedMessage();
    setIsLoading(false);
  }
  const categorytreeMapping = () => {
    if (categoryData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        categoryData.filter((element) => element.status === "Active"),
        "catogeryTypeId",
        "parentCatogeryTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"catogeryTypeId"/g,
        '"value"'
      );
      old = old.replace(/"catogeryType"/g, '"label"');
      var newArray = JSON.parse(old);
      return newArray;
    }
    return [];
  };
  const treeMapping = () => {
    if (treeData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        treeData.filter((element) => element.status === "Active"),
        "contractTypeId",
        "parentContractTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"contractTypeId"/g,
        '"value"'
      );
      old = old.replace(/"contractType"/g, '"label"');
      var newArray = JSON.parse(old);

      return newArray;
    } else return [];
  };
  const onTemplateEditorOpen = (tableMeta) => {
    setSelectedRow(tableMeta);
    setNewOrUpdate("Update");
    setSelectedCategory(tableMeta.rowData[8]);
    setNodeTitle(tableMeta.rowData[2]);
    setselectedParent(tableMeta.rowData[4]);
    setselectedStatus(tableMeta.rowData[6]);
    setAddClicked(true);
  };

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button
                onClick={() =>
                  tableMeta !== null ? onTemplateEditorOpen(tableMeta) : null
                }
              >
                <i className="fa fa-edit custom-iconformodal" />
              </button>
            </>
          );
        },
      },
    };

    columnsArray.push(firstCol);
    columnsArray.push({
      name: "contractTypeId",
      label: "Contract ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "contractType",
      label: "NAME",
    });

    columnsArray.push({
      name: "parent_name",
      label: "PARENT CONTRACT",
    });
    columnsArray.push({
      name: "parentContractTypeId",
      label: "Parent Contract Type ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "catogeryType",
      label: "CATEGORY TYPE",
    });
    columnsArray.push({
      name: "statusId",
      label: "Status ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  state === "Active"
                    ? "table-active-cell"
                    : "table-inactive-cell"
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    columnsArray.push({
      name: "catogeryTypeId",
      label: "Category Type Id",
      options: {
        display: false,
      },
    });
    return columnsArray;
  };
  const onChangeOfTreeSelect = (value) => {
    setselectedParent(value);
  };

  const onChangeOfTreeSelectOfCategory = (value) => {
    setSelectedCategory(value);
    // if (value === null) {
    //   setDisCat(true);
    // } else {
    //   setDisCat(false);
    // }
  };
  const onChangeOfStatus = (value) => {
    setselectedStatus(value);
  };

  const onCloseClick = () => {
    setAddClicked(false);
  };
  const onAddNewClick = () => {
    setdis(true);
    setDisCat(true);
    setNewOrUpdate("New");
    setNodeTitle("");
    setselectedParent(null);
    setAddClicked(true);
    setSelectedCategory(null);
    setselectedStatus(1);
  };

  const handleError = (e) => {
    setNodeTitle(e.target.value);
  };
  useEffect(() => {
    if (
      selectedCategory === undefined ||
      selectedCategory === null ||
      selectedCategory === ""
    ) {
      setDisCat(true);
      setCatError("Please select a category type");
    } else {
      setCatError("");
      setDisCat(false);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const regex = /^[A-Za-z][a-zA-Z0-9_()\-[\] ]*$/;
    if (nodeTitle?.trim() !== "" && !regex?.test(nodeTitle)) {
      setTitleError(
        "Name must begin with an alphabet, rest should be alphanumeric or contain only _, -, (), [] as its special character."
      );
      setdis(true);
    } else if (
      nodeTitle?.trim() === "" ||
      nodeTitle === undefined ||
      nodeTitle === null
    ) {
      setTitleError("Name Required");
      setdis(true);
    } else {
      setTitleError("");
      setdis(false);
    }
  }, [nodeTitle]);
  const checkName = (val) => {
    if (val.trim() === "") {
      setdis(true);
    }
  };

  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const onRemoveParent = () => {
    if (selectedParent !== null) {
      setselectedParent(null);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={onAddNewClick}
            >
              Add
            </button>
          </div>
          {addClicked ? (
            <div className="modal">
              <div className="modal-content">
                <div className=" header-modal ">
                  <h5 className="header-title"> Contract Type</h5>
                </div>
                <div className="div-display">
                  <label className="label-class">
                    Contract Type Name{" "}
                    <i className="fa fa-asterisk aster-risk-Icon"></i>
                  </label>

                  <input
                    type="text"
                    onChange={handleError}
                    value={nodeTitle}
                    placeholder="Contract Type Name"
                    className="textbox-input_1"
                  />
                  {titleError !== "" ? (
                    <div className="alert-danger">{titleError}</div>
                  ) : null}
                </div>
                <div className="div-display">
                  <label className="label-class">Parent Contract</label>
                  <div className="parentSelectDiv">
                    <SyfedTreeSelect
                      style={treeSelectStyle}
                      size="small"
                      treeCheckedStrategy="all"
                      dataSource={treeMapping()}
                      onChange={onChangeOfTreeSelect}
                      placeholder="Select"
                      value={selectedParent}
                    />
                    <button
                      onClick={() => onRemoveParent()}
                      className="removeParent"
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </div>
                </div>
                <div className="div-display">
                  <label className="label-class">
                    Category Type{" "}
                    <i className="fa fa-asterisk aster-risk-Icon"></i>
                  </label>

                  <SyfedTreeSelect
                    style={treeSelectStyle}
                    size="small"
                    treeCheckedStrategy="all"
                    dataSource={categorytreeMapping()}
                    onChange={onChangeOfTreeSelectOfCategory}
                    placeholder="Select"
                    value={selectedCategory}
                  />
                  {catError !== "" ? (
                    <div className="alert-danger">{catError}</div>
                  ) : null}
                </div>
                <div className="div-display">
                  <label className="label-class">Status</label>

                  <SyfedTreeSelect
                    style={treeSelectStyle}
                    size="small"
                    treeCheckedStrategy="all"
                    dataSource={StatusData}
                    onChange={onChangeOfStatus}
                    placeholder="Select Status"
                    value={selectedStatus}
                  />
                </div>

                <div className="footer-modal">
                  <button
                    type="button"
                    className="close-btn"
                    onClick={onCloseClick}
                  >
                    Close
                  </button>
                  {NewOrUpdate === "New" ? (
                    <button
                      type="button"
                      className="Modal-btn"
                      onClick={OnAddNodeClose}
                      disabled={dis || disCat ? true : false}
                    >
                      Add New
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="Modal-btn"
                      onClick={OnUpdateNodeClose}
                      disabled={dis || disCat ? true : false}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div>
            <div className="Table-body" style={{ overflow: "hidden" }}>
              <div className=" table-scroll">
                <MuiThemeProvider theme={customTheme}>
                  <OptimisedMUIDataTable
                    //title={"Departments"}
                    data={treeData}
                    columns={generateColumns()}
                    options={options}
                  />
                </MuiThemeProvider>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
