import { Editor, Transforms, Element as SlateElement } from "slate";
import { ReactEditor } from "slate-react";
import { v4 as uuid } from "uuid";

const alignment = ["alignLeft", "alignRight", "alignCenter"];
const list_types = ["orderedList", "unorderedList"];
export const sizeMap = {
  small: "0.75em",
  normal: "1em",
  medium: "1.75em",
  huge: "2.5em",
};
export const fontFamilyMap = {
  sans: "Helvetica,Arial, sans serif",
  serif: "Georgia, Times New Roaman,serif",
  monospace: "Monaco, Courier New,monospace",
};
export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = list_types.includes(format);
  const isIndent = alignment.includes(format);
  const isAligned = alignment.some((alignmentType) =>
    isBlockActive(editor, alignmentType)
  );

  /*If the node is already aligned and change in indent is called we should unwrap it first and split the node to prevent
    messy, nested DOM structure and bugs due to that.*/
  if (isAligned && isIndent) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        alignment.includes(
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
        ),
      split: true,
    });
  }

  /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations*/
  if (isIndent) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
    return;
  }
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      list_types.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });
  if (isList && !isActive) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
  }
};
export const addMarkData = (editor, data) => {
  Editor.addMark(editor, data.format, data.value);
};
export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
  ReactEditor.focus(editor);
};

export const toggleMarkCustom = (editor, format, value) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value);
  }
  ReactEditor.focus(editor);
};

export const toggleMarkClause = (editor, format, clauseID) => {
  const isActive = isMarkActive(editor, format);
  let clauseData = {
    clauseMark: true,
    clauseID: clauseID,
    "data-type": "clause",
  };
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, clauseData);
  }
  ReactEditor.focus(editor);
};
export const toggleVariableMark = (editor, format, finalJSON, dataPath) => {
  const isActive = isMarkActive(editor, format);
  var variable = window.getSelection().toString().toLowerCase();
  let status = checkVariableExists(finalJSON, variable);
  if (status === "invalid") {
    ReactEditor.focus(editor);
    return [null, null];
  } else {
    let varJson = { ...finalJSON };
    let variableID;
    if (!(variable in varJson) && status !== "exists") {
      variableID = generateUUID(varJson);
      varJson[variable] = {
        uuid: variableID,
        elementType: null,
        elementConfig: {
          type: null,
          id: null,
          placeholder: null,
        },
        validation: {
          required: null,
          isNumeric: null,
          error: null,
        },
        value: null,
        sequenceNo: Object.keys(varJson).length + 1,
      };
    } else {
      variableID = varJson[variable].uuid;
    }

    let variableData = {
      variable: true,
      "data-variable-id": variableID,
      "data-type": "variable",
      dataContent: "{{" + variable + "}}",
      "data-path": dataPath,
    };

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, variableData);
      // Transforms.move(editor, { distance: 1, unit: "word", edge: "focus" });
    }
    ReactEditor.focus(editor);
    return [varJson];
  }
};
export const generateUUID = (variableJson) => {
  const variableID = uuid();
  let unique = true;
  Object.keys(variableJson).forEach((element) => {
    if (variableJson[element].uuid === variableID) {
      unique = false;
    }
  });
  if (unique === false) {
    generateUUID(variableJson);
  } else return variableID;
};
export const generateUUIDAmendment = (variableJson) => {
  let variableID = uuid();
  variableID = variableID + "_A";
  let unique = true;
  Object.keys(variableJson).forEach((element) => {
    if (variableJson[element].uuid === variableID) {
      unique = false;
    }
  });
  if (unique === false) {
    generateUUID(variableJson);
  } else return variableID;
};
export const generateUUIDBasicVar = (suffix, variableJson) => {
  let variableID = uuid();
  variableID = variableID + suffix;
  if (variableJson) {
    let unique = true;
    Object.keys(variableJson).forEach((element) => {
      if (variableJson[element].uuid === variableID) {
        unique = false;
      }
    });
    if (unique === false) {
      generateUUIDBasicVar(variableJson);
    } else return variableID;
  } else {
    return variableID;
  }
};

export const generateUUIDSig = (jsonData) => {
  return jsonData.length + 1;
};
const checkVariableExists = (finalJSON, variable) => {
  let varArray = finalJSON;
  var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  if (!format.test(variable)) {
    if (!variable.includes(" ")) {
      if (!Object.keys(varArray).map((ek)=> {return ek.toLowerCase()} ).includes(variable)) {
        return "notExists";
      } else return "exists";
    } else return "invalid";
  } else return "invalid";
};
export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

export const activeMark = (editor, format) => {
  const defaultMarkData = {
    color: "black",
    bgColor: "black",
    fontSize: "normal",
    fontFamily: "sans",
  };
  const marks = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ?? defaultValue;
};
export const insertSignature = (editor, format) => {
  //const selection = editor.selection;
  Transforms.insertFragment(
    editor,
    [
      {
        children: [
          {
            text: " ",
          },
          {
            text: " ",
            signature: true,
          },
          {
            text: " ",
          },
        ],
      },
    ]
    // { hanging: true, voids: true, select: true }
  );
};
export const withCollapsible = (editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    return element["signature"] ? true : isVoid(element);
  };
};
