import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { contractTypeData: {} };

export const contractTypeSlice = createSlice({
  name: "contractTypeState",
  initialState: { value: initialStateValue },
  reducers: {
    setcontractTypeData: (state, action) => {
      state.value.contractTypeData = action.payload;
    },
  },
});

export const { setcontractTypeData } = contractTypeSlice.actions;

export default contractTypeSlice.reducer;
