import { ReactEditor } from "slate-react";
import { useCallback, useEffect, useRef, useState } from "react";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeCommentThreadIDAtom } from "../utils/CommentState";
import { initializeStateWithAllCommentThreads } from "../utils/EditorCommentUtils";
import useAddCommentThreadCallback from "../hooks/useAddCommentThreadCallback";
import { Editor, Text } from "slate";

export default function NodePopover({
  header,
  node,
  children,
  editorOffsets,
  className,
  isBodyFullWidth,
  onClickOutside,
  ...props
}) {
  const popoverRef = useRef(null);
  const editor = props.getEditorRef(); //useEditor();
  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);
  const addCommentThread = useAddCommentThreadCallback();
  const [allCommentThreads, setallCommentThreads] = useState(null);
  const activeCommentThreadID = useRecoilValue(activeCommentThreadIDAtom);
  const [commentNode, setCommentNode] = useState(node);

  useEffect(() => {
    setCommentNode(node);
  }, [node]);

  useEffect(() => {
    const editorEl = popoverRef.current;
    if (editorEl == null) {
      return;
    }

    if (editor && commentNode) {
      const domNode = ReactEditor.toDOMNode(editor, commentNode);
      if (domNode) {
        const {
          x: nodeX,
          height: nodeHeight,
          y: nodeY,
        } = domNode.getBoundingClientRect();
        editorEl.style.position = "absolute";
        editorEl.style.display = "block";
        editorEl.style.top = `${nodeY + nodeHeight - editorOffsets.y}px`;
        editorEl.style.left = `${nodeX - editorOffsets.x}px`;
        editorEl.scrollIntoView(false);
      }
    }
    (async () => {
      let abc = await initializeStateWithAllCommentThreads(
        editor,
        addCommentThread,
        null
      );
      setallCommentThreads(Array.from(abc));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, editorOffsets.x, editorOffsets.y, commentNode]);

  const onMouseDown = useCallback(
    (event) => {
      if (
        popoverRef.current != null &&
        !popoverRef.current.contains(event.target) &&
        onClickOutside != null
      ) {
        onClickOutside(event);
      }
    },
    [onClickOutside]
  );
  const isCurenID = (element) => element.threadID === activeCommentThreadID;

  const onPrevClick = () => {
    // props.setActiveCommentID(null);
    let currentID = allCommentThreads.findIndex(isCurenID);
    let nextID = 0;
    if (currentID === 0) {
      nextID = allCommentThreads.length - 1;
    } else nextID = currentID - 1;

    const textNodeEntry = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) =>
        Text.isText(n) &&
        n["commentThread_" + allCommentThreads[nextID].threadID],
    }).next().value;
    setCommentNode(textNodeEntry[0]);
    setActiveCommentThreadID(allCommentThreads[nextID].threadID);
  };
  const onNextClick = () => {
    let currentID = allCommentThreads.findIndex(isCurenID);
    let nextID = 0;
    if (currentID === allCommentThreads.length - 1) {
      nextID = 0;
    } else nextID = currentID + 1;
    const textNodeEntry = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) =>
        Text.isText(n) &&
        n["commentThread_" + allCommentThreads[nextID].threadID],
    }).next().value;
    setCommentNode(textNodeEntry[0]);
    setActiveCommentThreadID(allCommentThreads[nextID].threadID);
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
    };
  }, [onMouseDown]);

  if (editorOffsets == null) {
    return null;
  }

  return (
    <>
      <div
        className="comment-card"
        ref={popoverRef}
        // className={className}
      >
        {header != null ? <div>{header}</div> : null}
        <div
          className="comment-body"
          style={isBodyFullWidth ? { padding: 0 } : undefined}
        >
          {children}
        </div>
        <div className="comment-footer">
          <button onClick={() => onPrevClick()}>
            <i className="fa fa-chevron-circle-left prevnextComment" />
          </button>
          <button onClick={() => onNextClick()} style={{ marginLeft: "5%" }}>
            <i className="fa fa-chevron-circle-right prevnextComment" />
          </button>
        </div>
      </div>
    </>
  );
}
