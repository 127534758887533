export const isPassword = (input) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(input);
};
export const isEmail = (input) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(input);
};
export const isAlphanumeric = (input) => {
  const regex = /^[a-zA-Z0-9_-\s]+$/;
  return regex.test(input);
};

export const checkNotNull = (input) => {
  if (typeof input === "string") {
    if (input?.trim() === null || input?.trim() === "") {
      return false;
    }
  } else {
    if (input === null || input === "") {
      return false;
    }
  }
  return true;
};
export const isAlpha = (input) => {
  const regex = /^[a-zA-Z\s]+$/;
  return regex.test(input);
};
