import { React, useState, useEffect } from "react";
import "./loginNew.css";
import { useDispatch } from "react-redux";
import { setDeptData } from "../../redux/Reducers/deptReducer";
import { setLocationData } from "../../redux/Reducers/locationReducer";
import { setcontractTypeData } from "../../redux/Reducers/contractTypeReducer";
import { setCategoryData } from "../../redux/Reducers/categoryReducer";
import { setTokenData } from "../../redux/Reducers/tokenReducer";
import { toast } from "react-toastify";
import { checkNotNull } from "../../Helpers/InputValidationsHelpher";
// import { setLoginData } from "../../redux/Reducers/loginReducer";
import { setcontractTemplateData } from "../../redux/Reducers/contractTemplateReducer";
import { setamendmentTemplateData } from "../../redux/Reducers/amendmentTemplateReducer";
import { setTerminateTemplateData } from "../../redux/Reducers/terminationTemplateReducer";
import { Navigate, useNavigate } from "react-router-dom";
import { getCrudApi, Login } from "../../webServiceCalls/webServiceCall";
import { validateToken, getDecodedToken } from "../../Helpers/validateToken";
import MainPage from "../../app/MainPage";
import { mapParent, mapParentTemp } from "../../Helpers/hierarchyHelper";
import { setrenewalTypeData } from "../../redux/Reducers/renewalTypeReducer";
import { setStatusData } from "../../redux/Reducers/statusReducer";
import login from "../../assets/images/image.png";
import applogo from "../../assets/images/Contrace_New.svg";
import Loading from "../../Loading/Loading";
import logo from "../../assets/images/New_Logo.png";
import showPwdImg from "../../assets/icons/closedeye.png";
import hidePwdImg from "../../assets/icons/openeye.png";
import logoApp from "../../assets/images/logoApp.png";
import loginBackground from "../../assets/images/background.png";
import LaptopImage from "../../assets/images/laptop.png";
import { Span } from "slate";
import { ToastHelper } from "../../app/ToastHelpher";
import { LazyLoadImage } from "react-lazy-load-image-component";
//import background from "../../assets/images/background.png";
var CryptoJS = require("crypto-js");

export default function Pagelogin(props) {
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [schema, setSchema] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [userNameError, setUserNameError] = useState(true);
  const [tenantError, setTenantError] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var dataResult;
  async function handleSubmit(e) {
    sessionStorage.setItem("userNAme", userName);
    setIsLoading(true);
    e.preventDefault();
    if (
      schema !== "" &&
      userName !== "" &&
      password !== "" &&
      schema !== null &&
      userName !== null &&
      password !== null
    ) {
      let encryptdPassword = CryptoJS.AES.encrypt(
        password,
        "cipherAce"
      ).toString();

      //
      // var bytes = CryptoJS.AES.decrypt(encryptdPassword, "cipherAce");
      // var decryptdPassword = bytes.toString(CryptoJS.enc.Utf8);
      //
      const userdetails = {
        schema: schema,
        userName: userName,
        password: encryptdPassword,
      };

      //  return <Redirect to="/dashboard" />;

      // dispatch(setLoginData(userdetails));
      let result = Login(userdetails, "auth/login");
      if (result != null) {
        await result.then(function (res) {
          dataResult = res;
        });
        if (dataResult) {
          dispatch(setTokenData(dataResult));
          const str = JSON.stringify(userdetails);
          const encLoginDetails = CryptoJS.AES.encrypt(
            str,
            "cipherAce"
          ).toString();
          sessionStorage.setItem("ehju", encLoginDetails);
        }
        validateToken();
        var decodedToken = getDecodedToken();

        var roles = [];
        roles.push(-1);
        roles.push(
          decodedToken?.rolesPermissions?.map(function (val) {
            return val?.roleTypeId;
          })
        );
        roles = roles.join(",");

        sessionStorage.setItem("role", roles);
        var token = sessionStorage.getItem("user");
        if (token !== null) {
          result = getCrudApi("departments/all", {});
          await result.then(function (res) {
            dataResult = res;
          });
          dispatch(
            setDeptData(
              mapParentTemp(
                dataResult,
                "parent_dept_id",
                "dept_id",
                "dept_name"
              )
            )
          );
          validateToken();
          result = getCrudApi("locations/all", {});
          await result.then(function (res) {
            dataResult = res;
          });
          dispatch(
            setLocationData(
              mapParentTemp(
                dataResult,
                "parent_location_id",
                "location_id",
                "location_name"
              )
            )
          );
          validateToken();
          let categoryType;
          result = getCrudApi("categoryTypes/all", {});
          await result.then(function (res) {
            dataResult = res;
            categoryType = res;
          });
          dispatch(
            setCategoryData(
              mapParent(
                dataResult,
                "parentCatogeryTypeId",
                "catogeryTypeId",
                "catogeryType"
              )
            )
          );
          validateToken();
          result = getCrudApi("contractTypes/all", {});
          await result.then(function (res) {
            dataResult = res;
          });
          let contractTypeArr = mapParent(
            dataResult,
            "parentContractTypeId",
            "contractTypeId",
            "contractType"
          );
          let updatedContract = [...contractTypeArr];
          let contractTypearr = [];
          updatedContract.forEach((con, id) => {
            let filteredCategoryType = categoryType.filter((cat) => {
              if (cat.catogeryTypeId === con.catogeryTypeId) return true;
              else return false;
            });
            let localCon = { ...con };
            localCon.catogeryType =
              filteredCategoryType.length !== 0
                ? filteredCategoryType[0].catogeryType
                : "";
            contractTypearr.push(localCon);
          });
          dispatch(setcontractTypeData(contractTypearr));
          validateToken();

          result = getCrudApi("status/all", {});
          await result.then(function (res) {
            dataResult = res;
          });
          dispatch(setStatusData(dataResult));

          let templateData, templateSettings, renewalType;
          validateToken();
          result = getCrudApi("contractTemplate/all", {});
          await result.then(function (res) {
            templateData = res;
          });
          validateToken();
          result = getCrudApi("contractSettings/all", {});
          await result.then(function (res) {
            templateSettings = res;
          });
          validateToken();
          result = getCrudApi("renewalTypes/all", {});
          await result.then(function (res) {
            renewalType = res;
          });
          dispatch(setrenewalTypeData(renewalType));
          let contractTemplate = generateJSONForContractTemplate(
            templateData,
            templateSettings,
            renewalType
          );
          dispatch(setcontractTemplateData(contractTemplate));

          // for amendmentTemplate
          validateToken();
          result = getCrudApi("amendmentTemplate/all", {});
          await result.then(function (res) {
            templateData = res;
          });
          dispatch(setamendmentTemplateData(templateData));

          // for terminateTemplate
          validateToken();
          result = getCrudApi("terminationTemplate/all", {});
          await result.then(function (res) {
            templateData = res;
          });
          dispatch(setTerminateTemplateData(templateData));
          //window.location.reload()
          props?.setTestVar(roles);

          navigate("/Dashboard");
        } else {
          toast.error("Invalid Credentials");
        }
      }
    } else {
      toast.error("Please enter the details");
    }
    setIsLoading(false);
  }
  const generateJSONForContractTemplate = (
    templateData,
    settingsData,
    renewalTypeData
  ) => {
    let arrayOfJSON = [];
    if (templateData != null) {
      templateData.forEach((template) => {
        let newJSON = {};
        Object.keys(template).forEach((element) => {
          newJSON[element] = template[element];
        });
        if (settingsData != null) {
          settingsData.forEach((setting) => {
            if (
              template &&
              setting &&
              setting.contractTemplateId === template.contractTemplateId
            ) {
              Object.keys(setting).forEach((element) => {
                newJSON[element] = setting[element];
              });
              if (renewalTypeData != null) {
                renewalTypeData.forEach((renewal) => {
                  if (setting.renewalId === renewal.renewal_id) {
                    newJSON.renewal_type = renewal.renewal_type;
                  }
                });
              }
            }
          });
        }
        arrayOfJSON.push(newJSON);
      });
    }
    return arrayOfJSON;
  };

  const forgotpassword = () => {
    navigate("/ForgotPwd", { state: { schema, userName } });
  };
  return (
    <div>
      {sessionStorage.getItem("user") !== null &&
      sessionStorage.getItem("user").length > 10 ? (
        <>
          <Navigate to={"/Dashboard"} />
        </>
      ) : (
        <>
          <div className="login-background-div">
            <div className="login-sub-Main1">
              <div className="login-sub-main-img">
                <img
                  width={"100%"}
                  loading="lazy"
                  src={loginBackground}
                  alt="background"
                />

                <div className="login-sub-main-imgdiv">
                  <LazyLoadImage
                    effect="blur"
                    src={LaptopImage}
                    height={380}
                    alt="laptop"
                  />
                </div>
              </div>
              <div className="login-content-text">
                {" "}
                <p className="login-content-p">
                  {" "}
                  ContrACE is a comprehensive contract management tool that is
                  specifically designed to handle the entire contract lifecycle,
                  encompassing everything from initial creation to execution and
                  renewal. By streamlining your contract management processes,
                  ContrACE empowers your organization to efficiently create,
                  track, and manage contracts. With its user-friendly interface
                  and powerful features, ContrACE ensures that you have full
                  control and visibility over your contracts, enabling you to
                  optimize your operations and achieve better contract outcomes.
                </p>
              </div>
            </div>
            <div className="login-sub-Main">
              <div className="login-screen-2">
                <div className="login-logo-div">
                  <LazyLoadImage
                    effect="blur"
                    className="login-logo"
                    src={applogo}
                    alt="Contrace"
                  />
                </div>
                <div className="screen__content">
                  <form className="login" onSubmit={handleSubmit}>
                    <div className="login__field">
                      {/* <i class="login__icon fa fa-home"></i> */}
                      <input
                        class="login__input"
                        name="tenant"
                        type="text"
                        value={schema}
                        placeholder="Tenant Name"
                        onChange={(e) => {
                          setSchema(e.target.value);
                          setTenantError(e.target.value);
                        }}
                      />
                      {!tenantError}
                    </div>

                    <div className="login__field">
                      {/* <i class="login__icon fa fa-user-alt"></i> */}
                      <input
                        class="login__input"
                        name="username"
                        type="username"
                        value={userName}
                        placeholder="Username"
                        onChange={(e) => {
                          setuserName(e.target.value);
                          setUserNameError(checkNotNull(e.target.value));
                        }}
                      />
                      {!userNameError}
                    </div>
                    <div className="login__field">
                      {/* <i class="login__icon fa fa-key"></i> */}
                      <input
                        class="login__input"
                        type={isRevealPwd ? "text" : "password"}
                        name="password"
                        value={password}
                        placeholder="Password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setTenantError(checkNotNull(e.target.value));
                        }}
                      />
                      <LazyLoadImage
                        effect="blur"
                        className="pwd-container"
                        title={isRevealPwd ? "Hide password" : "Show password"}
                        src={isRevealPwd ? showPwdImg : hidePwdImg}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                        alt="Eye"
                      />
                    </div>
                    <div className="login-password-check">
                      <div className="login-text-password">
                        <span
                          onClick={forgotpassword}
                          className="login-link-text"
                        >
                          Forgot Password?
                        </span>
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="login__submit"
                      // onClick={handleSubmit}
                    >
                      Login
                    </button>
                  </form>

                  <div>
                    <LazyLoadImage
                      effect="blur"
                      className="login-company-logo"
                      src={logo}
                      alt="Contrace"
                    />
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? <Loading /> : null}
          </div>
        </>
      )}
      <ToastHelper />
    </div>
  );
}
