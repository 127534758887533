import React, { useEffect, useState } from "react";
import Select from "react-select";
import SyfedTreeSelect from "syfed-tree-select";
import "./ModalRequest.css";

export default function RequestModal(props) {
  const [contractTemplateName, setcontractTemplateName] = useState("");
  const [contractTypeSelected, setcontractTypeSelected] = useState(0);
  const [categoryTypeSelected, setcategoryTypeSelected] = useState(0);
  const [selectedTemplateType, setSelectedTemplateType] = useState(0);
  const [requestName, setRequestName] = useState("");
  const [contractTemplateNamesList, setcontractTemplateNamesList] = useState(
    []
  );
  const [ContractTypeSelect, setContractTypeSelect] = useState([]);
  const [filterContractTypeSelect, setFilterContractTypeSelect] = useState([]);
  const [dis, setdis] = useState(true);
  function filterDataCategoryContractType() {
    let category = null;
    var newArray = filterContractTypeSelect;
    if (categoryTypeSelected !== null) {
      category = newArray?.filter((con) => {
        if (con.catogeryTypeId === categoryTypeSelected) return true;
        else return false;
      });
      setContractTypeSelect(category);
    }
  }
  const updateContractTemplateNames = () => {
    let templateNames = props.contractTemplateName
      ? contractTypeSelected !== 0
        ? props.contractTemplateName.filter(
            (element) => element.contractTypeId === contractTypeSelected
          )
        : props.contractTemplateName
      : null;
    templateNames = templateNames
      ? categoryTypeSelected !== 0
        ? templateNames.filter(
            (element) => element.catogeryTypeId === categoryTypeSelected
          )
        : templateNames
      : null;
    templateNames = templateNames
      ? templateNames.filter(
          (element) => element.publishedTemplateVersionId !== null
        )
      : null;
    setcontractTemplateNamesList(templateNames);

    // return templateNames;
  };
  const contractTemplateChange = (selectedOption) => {
    setcontractTemplateName(selectedOption);
    if (requestName !== "" && selectedOption) {
      setdis(false);
    } else {
      setdis(true);
    }
  };
  const onChangeOfContractTreeSelect = (value) => {
    setcontractTypeSelected(value);
  };
  const TemplateName = (val) => {
    setRequestName(val);
    if (contractTemplateName !== "" && val !== "") {
      setdis(false);
    } else {
      setdis(true);
    }
  };

  const clearSelectedVal = () => {
    setcontractTemplateName({});
    setcontractTypeSelected(0);
    setcategoryTypeSelected(0);
    setRequestName("");
    setSelectedTemplateType(0);
  };

  const onChangeOfCategoryTreeSelect = (value) => {
    setcategoryTypeSelected(value);
  };

  useEffect(() => {
    updateContractTemplateNames();
  }, [contractTypeSelected, categoryTypeSelected]);

  useEffect(() => {
    if (
      contractTemplateNamesList == null ||
      contractTemplateNamesList.length === 0
    ) {
      setcontractTemplateName("");
    }
  }, [contractTemplateNamesList]);

  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "1px",
      display: "flex",
      borderRadius: "5px",
      width: "95%",
    }),
  };

  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };
  useEffect(() => {
    setContractTypeSelect(props.contractTypeOptions);
    setFilterContractTypeSelect(props.contractTypeOptions);
  }, []);

  useEffect(() => {
    filterDataCategoryContractType();
  }, [categoryTypeSelected]);
  return (
    <>
      {props.open ? (
        <div className="modal">
          <div className="modal-content">
            <div className="header-modal">
              <h5 className="header-title">Requests</h5>
            </div>
            <div className="div-display">
              <label className="label-class">Category Type</label>
              <SyfedTreeSelect
                style={treeSelectStyle}
                size="small"
                treeCheckedStrategy="all"
                dataSource={props.RequestCategory}
                onChange={onChangeOfCategoryTreeSelect}
                placeholder="Select Category Type"
                value={categoryTypeSelected}
              />
            </div>
            <div className="div-display">
              <label className=" label-class">Contract Type</label>
              <SyfedTreeSelect
                style={treeSelectStyle}
                size="small"
                treeCheckedStrategy="all"
                dataSource={ContractTypeSelect}
                onChange={onChangeOfContractTreeSelect}
                placeholder="Select Contract Type"
                value={contractTypeSelected}
              />
            </div>

            <div className="div-display">
              <label className="label-class">
                Request Name <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <input
                type="text"
                placeholder="Request Name"
                className="textbox-input_1"
                value={requestName}
                onChange={(e) => TemplateName(e.target.value)}
              />
            </div>
            <div className="div-display">
              <label className="label-class">
                Template Name <i className="fa fa-asterisk aster-risk-Icon"></i>
              </label>
              <Select
                maxMenuHeight={120}
                menuPlacement="bottom"
                styles={selectStyles}
                value={contractTemplateName}
                onChange={contractTemplateChange}
                options={contractTemplateNamesList}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: " #E5E5E5",
                    primary: "#B2D4FD ",
                  },
                })}
              />
            </div>
            {contractTemplateName.templateTypeFlag === 11 ? (
              <div className="div-display">
                <label className="label-class">
                  Select with or without template
                </label>
                <Select
                  value={selectedTemplateType}
                  styles={selectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: " #E5E5E5",
                      primary: "#B2D4FD ",
                    },
                  })}
                  onChange={(value) => {
                    setSelectedTemplateType(value);
                  }}
                  options={[
                    { value: 9, label: "With Template" },
                    { value: 10, label: "Without Template", isDisabled: true },
                  ]}
                />
              </div>
            ) : null}

            <div className="footer-modal">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  props.ModalOpen(false);
                  clearSelectedVal();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="Modal-btn"
                onClick={() =>
                  props.RequestClosed(
                    contractTemplateName,
                    selectedTemplateType,
                    requestName
                  )
                }
                disabled={dis}
              >
                {props.ButtonTitle}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
