import { getPluginType, PlateEditor, Value } from '@udecode/plate-common';

import {
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_OL,
  ELEMENT_UL,
} from '../createListPlugin';

export const getUnorderedListType = (
  editor
) => {
  return getPluginType(editor, ELEMENT_UL);
};

export const getOrderedListType = (editor) => {
  return getPluginType(editor, ELEMENT_OL);
};

export const getListTypes = (editor) => {
  return [getOrderedListType(editor), getUnorderedListType(editor)];
};

export const getListItemType = (editor) => {
  return getPluginType(editor, ELEMENT_LI);
};

export const getListItemContentType = (
  editor
) => {
  return getPluginType(editor, ELEMENT_LIC);
};
