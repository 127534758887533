export const mapParent = (array, parent_field, field_id, field_name) => {
  var map = [];

  var b = {};
  var newarray = JSON.parse(JSON.stringify(array));
  if (array) {
    array.map((obj) => {
      let var2 =
        obj[parent_field] != null
          ? newarray.filter(
              (element) => element[field_id] === obj[parent_field]
            )
          : null;
      b = obj;
      b["parent_name"] = var2 != null ? var2[0][field_name] : null;
      b["status"] = obj.statusId === 2 ? "InActive" : "Active";
      map.push(JSON.parse(JSON.stringify(b)));
    });
  }
  return map;
};
export const mapParentTemp = (array, parent_field, field_id, field_name) => {
  var map = [];

  var b = {};
  var newarray = JSON.parse(JSON.stringify(array));
  if (array) {
    array.map((obj) => {
      let var2 =
        obj[parent_field] != null
          ? newarray.filter(
              (element) => element[field_id] === obj[parent_field]
            )
          : null;
      b = obj;
      b["parent_name"] = var2 != null ? var2[0][field_name] : null;
      b["status"] = obj.status_id === 2 ? "InActive" : "Active";
      map.push(JSON.parse(JSON.stringify(b)));
    });
  }
  return map;
};
export const mapStatus = (array) => {
  var map = [];
  var b = {};
  if (array) {
    array.map((obj) => {
      b = obj;
      b["status"] = obj.status_id === 2 ? "InActive" : "Active";
      map.push(JSON.parse(JSON.stringify(b)));
    });
  }

  return map;
};

export const mapStatusWithoutUderscore = (array) => {
  var map = [];
  var b = {};
  if (array) {
    array.map((obj) => {
      b = obj;
      b["status"] = obj.statusId === 2 ? "InActive" : "Active";
      map.push(JSON.parse(JSON.stringify(b)));
    });
  }

  return map;
};
