import "./ConditionText.css";

import {
  activeConditionThreadIDAtom,
  prevSelectedConditionThreadIDAtom,
} from "../utils/CommentState";
import classNames from "classnames";
import { getSmallestConditionThreadAtTextNode } from "../utils/EditorCommentUtils";
import { useEditor } from "slate-react";
import { useRecoilState, useSetRecoilState } from "recoil";

export default function CommentedText(props) {
  const editor = useEditor();
  const { textNode, condition } = props;
  const [activeConditionThreadID, setActiveConditionThreadID] = useRecoilState(
    activeConditionThreadIDAtom
  );
  const setPrevSelectedConditionThreadID = useSetRecoilState(
    prevSelectedConditionThreadIDAtom
  );

  const onClick = () => {
    if (props.isConditionDisabled === false) {
      let activeID = getSmallestConditionThreadAtTextNode(editor, textNode);
      setActiveConditionThreadID(activeID);
      setPrevSelectedConditionThreadID(activeID);
    }
  };

  return (
    <mark
      data-type={condition["data-type"]}
      //contentEditable="false"
      uuid={condition["data-condition-id"]}
      readOnly={true}
      style={{ color: "black" }}
      data-path={condition["data-path"]}
      className={classNames({
        condition: true,
        "is-active":
          activeConditionThreadID === condition["data-condition-id"]
            ? true
            : false,
      })}
      onClick={onClick}
    >
      {props.children}
    </mark>
  );
}
