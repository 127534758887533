import React, { useState, useEffect } from "react";
import { Box, createTheme, MuiThemeProvider } from "@material-ui/core";
import { getCrudApi, postCrudApi } from "../../webServiceCalls/webServiceCall";
import { getDecodedToken, validateToken } from "../../Helpers/validateToken";
import OptimisedMUIDataTable from "../manageContracts/OptimisedMUIDataTable";
import { Form, TextArea } from "semantic-ui-react";
import "./Approveme.css";
import { toast } from "react-toastify";
import Tab from "@material-ui/core/Tab";
import { Tabs } from "@material-ui/core";
//import diff from "htmldiff";
import HtmlDiff from "htmldiff-js";
import "./submit.css";
import { RecoilRoot } from "recoil";
import Select from "react-select";
import PlateEditor from "../../PlateEditor/PlateEditor";
import DynamicForm from "../dynamicForm/index";
import Loading from "../../Loading/Loading";
import { useLocation } from "react-router-dom";

const Submit = (props) => {
  const [templateData, setTemplateData] = useState([]);
  const [contractID, setContractID] = useState();
  const [contractData, setContractData] = useState();
  const [formData, setFormData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [comments, setComments] = useState("");
  const [commentsJson, setcommentsJson] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedVersionDocument, setSelectedVersionDocument] = useState(null);
  const [versions, setVersions] = useState([]);
  const [tabvalue, setValue] = React.useState(0);
  const [diffdata, setdiffdata] = useState();
  const [ContractDiffData, setContractDiffData] = useState(false);
  const plateRef = React.useRef();
  const [contract_request, setcontract_request] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVersion, setselectedVersion] = useState();
  const [contractName, setContractNmae] = useState("");
  const [disableApprover, setDisableApprover] = useState(false);
  const [disableReject, setDisableReject] = useState(false);
  const [pageSize, setPageSize] = useState("A4");
  const location = useLocation();

  useEffect(() => {
    //TODO - api call to get initial request info table
    setcontractdata();
  }, []);
  const onCommentsChange = (name, value) => {
    setComments(value);
  };
  const handleTabs = (val) => {
    setValue(val);
  };

  const displayRejectedMessage = () => {
    toast.success("Rejected Successfully!");
  };

  const displayApprovedMessage = () => {
    toast.success("Approved Successfully!");
  };

  const displayInputMessage = () => {
    toast.error("Please Input Comments!");
  };
  const filterRequests = (data) => {
    let array = {};
    let Requests = {};
    array = data;

    if (array)
      Object.keys(array).forEach((e) => {
        let req = {};
        if (array[e].isRequest === true) {
          req = array[e];
          Requests[e] = req;
        }
      });
    return Requests;
  };

  const setcontractdata = async () => {
    setIsLoading(true);
    setcontract_request(location.state.contract_request);
    let dataResult;
    let result = getCrudApi(
      "contractVersion/allContractVersionID/" + location.state.contract_id,
      {}
    );
    await result.then(function (res) {
      dataResult = res;
    });

    let Allversions = dataResult.filter(
      (element) => element.versionId !== dataResult.length
    );
    let AllVersionsList = [];
    for (let version in Allversions) {
      let versionList = {
        label: Allversions[version].versionId,
        value: Allversions[version].contractVersionId,
      };
      AllVersionsList.push(versionList);
    }
    setVersions(AllVersionsList);
    validateToken();
    let user = getDecodedToken();
    setContractID(location.state.contract_id);
    result = getCrudApi("contract/" + location.state.contract_id, {});
    await result.then(function (res) {
      dataResult = res;
    });

    if (dataResult != null) {
      setContractData(dataResult);
      setContractNmae(dataResult.contractName);
      if (location.state.contract_request === 1) {
        setPageSize(dataResult?.contractTemplate?.pageSize ? dataResult?.contractTemplate?.pageSize : "A4")
        setTemplateData(dataResult.finalDocument.finalDocument);
        setContractDiffData(true);
        if (Allversions.length > 0) {
          let latestVer = Allversions.filter(
            (element) => element.versionId === Allversions.length
          );
          if (latestVer) {
            GetVersionData(
              latestVer[0].contractVersionId,
              dataResult.finalDocument.finalDocument
            );
          }
        }
      }

      let variableJson = dataResult.contractFormData.var;
      let filteredVariables = {};
      Object.keys(variableJson).map((varGrp, id) => {
        if (varGrp === "templateVariables" || varGrp === "clauseVariables") {
          filteredVariables = {
            ...filteredVariables,
            ...{ [varGrp]: filterRequests(variableJson[varGrp]) },
          };
        } else {
          filteredVariables = {
            ...filteredVariables,
            ...{ [varGrp]: variableJson[varGrp] },
          };
        }
      });
      let filtered = filteredVariables;

      setFormData(filtered);
      let contract_UUID = dataResult.contractUUId;
      result = getCrudApi(
        "contractHistoryComment/contract/" + dataResult.contractId,
        {}
      );
      await result.then(function (res) {
        dataResult = res;
      });

      dataResult.sort(GetSortOrder("contractHistoryCommentId"));
      setcommentsJson(dataResult);
      setComments("");

      // result = getCrudApi("contract/contractByUuid/" + contract_UUID, {});
      // await result.then(function (res) {
      //   dataResult = res;
      // });

      // if (
      //   dataResult &&
      //   dataResult.currentVersion !== null &&
      //   dataResult.prevVersion
      // ) {
      //   setdiffdata(
      //     HtmlDiff.execute(dataResult.currentVersion, dataResult.prevVersion)
      //   );
      //   setContractDiffData(true);
      // }
    }
    setIsLoaded(true);
    setIsLoading(false);
  };
  const ApproveClick = async () => {
    setIsLoading(true);
    if (contract_request === 0) {
      await getCrudApi("tempApproval/request/approval/" + contractID, {});
    } else if (contract_request === 1) {
      await getCrudApi("tempApproval/contract/approval/" + contractID, {});
    }
    if (comments.trim() !== "") {
      let commentHistory = {
        contractHistoryComment: comments,
        contractId: contractData.contractId,
        contractStatus: contractData.contractStatus,
        commentHistoryFlag: 2,
      };
      await postCrudApi(
        "contractHistoryComment/contractHistoryComment",
        commentHistory
      );
    }

    //getcomments
    let dataResult;
    let result = getCrudApi(
      "contractHistoryComment/contract/" + contractData.contractId,
      {}
    );
    await result.then(function (res) {
      dataResult = res;
    });

    dataResult.sort(GetSortOrder("contractHistoryCommentId"));
    setDisableApprover(true);
    setDisableReject(true);
    setcommentsJson(dataResult);
    setComments("");
    setIsLoading(false);

    displayApprovedMessage();
  };
  const RejectClick = async () => {
    setIsLoading(true);
    if (comments.trim() !== "") {
      if (contract_request === 0) {
        await getCrudApi(
          "tempApproval/request/approval/deney/" + contractID,
          {}
        );
      } else if (contract_request === 1) {
        await getCrudApi(
          "tempApproval/contract/approval/deney/" + contractID,
          {}
        );
      }
      if (comments.trim() !== "") {
        let commentHistory = {
          contractHistoryComment: comments,
          contractId: contractData.contractId,
          contractStatus: contractData.contractStatus,
          commentHistoryFlag: 2,
        };
        await postCrudApi(
          "contractHistoryComment/contractHistoryComment",
          commentHistory
        );
      }
      //getcomments
      let dataResult;
      let result = getCrudApi(
        "contractHistoryComment/contract/" + contractData.contractId,
        {}
      );
      await result.then(function (res) {
        dataResult = res;
      });
      dataResult.sort(GetSortOrder("contractHistoryCommentId"));
      setDisableApprover(true);
      setDisableReject(true);
      setcommentsJson(dataResult);
      setComments("");
      displayRejectedMessage();
    } else {
      // alert("Please Input Comments");
      displayInputMessage();
    }
    setIsLoading(false);
  };
  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    filterType: "dropdown",
    search: false,
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "lastUpdatedDateTime",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to update new data after deleting rows
    },
    onColumnSortChange: (changedColumn, direction) => {},

    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {},
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const generateColumns = () => {
    let columnsArray = [];

    columnsArray.push({
      name: "contractHistoryComment",
      label: "Comment",
    });
    columnsArray.push({
      name: "lastUpdatedBy",
      label: "Commented By",
    });
    columnsArray.push({
      name: "lastUpdatedDateTime",
      label: "Commented On",
    });
    columnsArray.push({
      name: "contractStatus",
      label: "Contract Status",
      options: {
        customBodyRender: (dataIndex) => {
          let color;
          const setStatus = (dataIndex) => {
            switch (dataIndex) {
              case "Drafted":
                color = "#1D907Fff";
                break;
              case "Submitted":
                color = "#3F66B0ff";
                break;
              case "Approved":
                color = "#4F96D5ff";
                break;
              case "Reopened":
                color = "#FBCD08ff";
                break;
              case "Termination InProgress":
                color = "#8FC742ff";
                break;
              case "Amended":
                color = "#F05623ff";

                break;
              case "Negotiation":
                color = "#9F4098ff";
                break;
              case "Executed":
                color = "#F7901Eff";
                break;
              case "Terminated":
                color = "#76ccba";

                break;
            }
            return color;
          };
          const state = dataIndex;
          return (
            <>
              <p
                className={"table-contract-request-status"}
                style={{ background: setStatus(state) }}
              >
                {state}
              </p>
            </>
          );
        },
      },
    });

    return columnsArray;
  };

  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "95%",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "2px",
      display: "flex",
      borderRadius: "5px",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };

  const GetVersionData = async (value, template) => {
    setIsLoading(true);
    let dataResult;

    let result = getCrudApi("contractVersion/" + value, {});
    await result.then(function (res) {
      dataResult = res;
    });

    setSelectedVersionDocument(dataResult);

    let differenceData = HtmlDiff.execute(
      dataResult.finalDoc.finalDocument,
      template
    );
    setdiffdata(differenceData);
    setIsLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div style={{ display: "grid" }}>
            <div>
              {!disableApprover ? (
                <button
                  className="approveButton "
                  onClick={() => ApproveClick()}
                >
                  Approve
                </button>
              ) : null}
              {!disableReject ? (
                <button
                  className="approveButton-reject "
                  onClick={() => RejectClick()}
                >
                  Reject
                </button>
              ) : null}
            </div>
            <div>
              <div
                className={
                  ContractDiffData === true ? "Tab-nav" : "tab-nav-request"
                }
              >
                <div
                  className={tabvalue === 0 ? "tabs-active" : "tab-div"}
                  onClick={() => handleTabs(0)}
                >
                  {contract_request === 1 ? "Contract" : "Intelli-form"}
                </div>
                {ContractDiffData === true ? (
                  <div
                    className={tabvalue === 1 ? "tabs-active" : "tab-div"}
                    onClick={() => handleTabs(1)}
                  >
                    Comparision
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {tabvalue === 0 ? (
            <div>
              {isLoaded ? (
                contract_request === 1 ? (
                  <>
                    {contractName ? (
                      <div className="approval-submit">
                        <label className="header-div">{contractName}</label>
                      </div>
                    ) : null}
                    <div className="approve-editor">
                      <RecoilRoot>
                        <PlateEditor
                          id={"approverEditor"}
                          ref={plateRef}
                          //commentJSON={negotiationComments}
                          isNegotiationDisabled={true}
                          isVariableDisabled={true}
                          isPartiesDisabled={true}
                          isConditionDisabled={true}
                          isClauseDisabled={true}
                          contractTemplateJsonData={
                            templateData ? templateData : null
                          }
                          isEditable={false}
                          isConditionToggleDisabled={true}
                          isCommentToggleDisabled={true}
                          serializeCall={"serializeContract"}
                          tabForVarId="approverVarTab"
                          pageSize={pageSize}
                        />
                      </RecoilRoot>
                    </div>
                  </>
                ) : formData ? (
                  <div className="formCard">
                    <DynamicForm
                      formFields={formData}
                      buttonBackgroundColor="#0000ff"
                      buttonColor="#fff"
                      formBorderColorOnFocus="#0000ff"
                      errorTextColor="#dc143c"
                      disableContractVariable={true}
                      disableTerminationVariable={true}
                    />
                  </div>
                ) : null
              ) : null}
            </div>
          ) : tabvalue === 1 && ContractDiffData === true ? (
            <div value={tabvalue} index={1}>
              <div>
                {contract_request === 1 && versions.length !== 0 ? (
                  <>
                    <div className="version-div">
                      <label className="version-label">Versions</label>
                      <div style={{ margin: "0px 0px 0px 10px" }}>
                        <Select
                          styles={selectStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: " #E5E5E5",
                              primary: "#B2D4FD ",
                            },
                          })}
                          value={selectedVersion}
                          onChange={(value) => {
                            setselectedVersion(value);
                            GetVersionData(value.value, templateData);
                          }}
                          options={versions}
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                <RecoilRoot>
                  <div className="approve-editor">
                    <PlateEditor
                      id={"approverDiffEditor"}
                      //commentJSON={negotiationComments}
                      isNegotiationDisabled={true}
                      isVariableDisabled={true}
                      isPartiesDisabled={true}
                      isConditionDisabled={true}
                      isClauseDisabled={true}
                      contractTemplateJsonData={diffdata ? diffdata : null}
                      isEditable={false}
                      serializeCall={"serializeContract"}
                      tabForVarId="approverDiffVarTab"
                      pageSize={pageSize}
                    />
                  </div>
                </RecoilRoot>
              </div>
            </div>
          ) : null}

          <div className="comment-approval-label">
            <label className="commentLabel">Comment</label>
            <div>
              <TextArea
                className="TextAreaStyle"
                value={comments}
                onChange={(e) =>
                  onCommentsChange(e.target.name, e.target.value)
                }
              ></TextArea>
            </div>
          </div>
          <div className="comment-approval-label">
            <div>
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  data={commentsJson}
                  columns={generateColumns()}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Submit;
