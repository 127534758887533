import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = { amendmentTemplateData: {} };

export const amendmentTemplateSlice = createSlice({
  name: "amendmentTemplateState",
  initialState: { value: initialStateValue },
  reducers: {
    setamendmentTemplateData: (state, action) => {
      state.value.amendmentTemplateData = action.payload;
    },
  },
});

export const { setamendmentTemplateData } = amendmentTemplateSlice.actions;

export default amendmentTemplateSlice.reducer;
