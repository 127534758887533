/* eslint-disable default-case */
import { jsx } from "slate-hyperscript";

export const deserialize = (
  el,
  markAttributes = {},
  clauseLibrary,
  conditionV
) => {
  if (el.nodeType === Node.TEXT_NODE) {
    // if(el.textContent == " "){
    //   return jsx("text", markAttributes, "");
    // }
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };
  // define attributes for text nodes
  switch (el.nodeName) {
    // eslint-disable-next-line no-fallthrough
    case "MARK":
      if (el.getAttribute("data-type") === "comments") {
        let uuid = "commentThread_" + el.getAttribute("uuid");
        nodeAttributes[uuid] = true;
      } else if (el.getAttribute("data-type") === "variable") {
        nodeAttributes.variable = {
          "data-type": el.getAttribute("data-type"),
          "data-variable-id": el.getAttribute("uuid"),
          "data-path": el.getAttribute("data-path"),
          "currency-in-words": el.getAttribute("currency-in-words"),
          "date-in-words": el.getAttribute("date-in-words"),
        };
      } else if (el.getAttribute("data-type") === "condition") {
        nodeAttributes.condition = {
          "data-type": el.getAttribute("data-type"),
          "data-condition-id": el.getAttribute("uuid"),
          "data-path": el.getAttribute("data-path"),
        };
      } else if (el.getAttribute("data-type") === "signature") {
        nodeAttributes.signature = {
          id: Number(el.getAttribute("uuid")),
          label: el.getAttribute("data-label"),
        };
      }
      break;
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
    case "SUP":
      nodeAttributes.superscript = true;
      break;
    case "SUB":
      nodeAttributes.subscript = true;
      break;
    case "SPAN":
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0].trim() === "color") {
            nodeAttributes.color = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "background-color") {
            nodeAttributes.backgroundColor = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "font-size") {
            nodeAttributes.fontSize = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "font-family") {
            nodeAttributes.fontFamily = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "line-height") {
            nodeAttributes.lineHeight = eachstyle[1].trim();
          }
        });
      }
      break;
  }
  if (conditionV) {
    nodeAttributes.condition = {
      "data-type": conditionV["data-type"],
      "data-condition-id": conditionV["data-condition-id"],
      "data-path": conditionV["data-path"],
    };
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes, clauseLibrary, conditionV))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }
  let styleJson = {};
  if (el.getAttribute("style")) {
    let style = el.getAttribute("style").split(";");
    style.map((sty) => {
      let eachstyle = sty.split(":");
      if (eachstyle[0] === "margin-left") {
        styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
      } else if (eachstyle[0] === "margin-right") {
        styleJson["rightindent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
      } else if (eachstyle[0] === "line-height") {
        styleJson["lineHeight"] = Number(eachstyle[1]);
      } else if (eachstyle[0] === "text-align") {
        styleJson["align"] = eachstyle[1];
      } else if (eachstyle[0] === "list-style-type") {
        styleJson["listStyleType"] = eachstyle[1];
      } else if (eachstyle[0] === "list-start") {
        styleJson["listStart"] = eachstyle[1];
      }
    });
  }
  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx("element", { type: "blockquote" }, children);
    case "TABLE":
      return jsx("element", { type: "table" }, children);
    case "TR":
      return jsx("element", { type: "tr" }, children);
    case "TD":
      return jsx("element", { type: "td" }, children);
    case "H1":
      return jsx("element", { type: "h1", ...styleJson }, children);
    case "H2":
      return jsx("element", { type: "h2", ...styleJson }, children);
    case "H3":
      return jsx("element", { type: "h3", ...styleJson }, children);
    case "H4":
      return jsx("element", { type: "h4", ...styleJson }, children);
    case "H5":
      return jsx("element", { type: "h5", ...styleJson }, children);
    case "H6":
      return jsx("element", { type: "h6", ...styleJson }, children);
    case "UL":
      styleJson = {};
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0] === "margin-left") {
            styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "margin-right") {
            styleJson["rightindent"] =
              Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "line-height") {
            styleJson["lineHeight"] = Number(eachstyle[1]);
          } else if (eachstyle[0] === "text-align") {
            styleJson["align"] = eachstyle[1];
          } else if (eachstyle[0] === "list-style-type") {
            styleJson["listStyleType"] = eachstyle[1];
          } else if (eachstyle[0] === "font-size") {
            styleJson.fontSize = eachstyle[1].trim();
          } else if (eachstyle[0] === "font-family") {
            styleJson.fontFamily = eachstyle[1].trim();
          }
        });
      }
      return jsx("element", { type: "ul", ...styleJson }, children);
    case "OL":
      styleJson = {};
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0] === "margin-left") {
            styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "margin-right") {
            styleJson["rightindent"] =
              Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "line-height") {
            styleJson["lineHeight"] = Number(eachstyle[1]);
          } else if (eachstyle[0] === "text-align") {
            styleJson["align"] = eachstyle[1];
          } else if (eachstyle[0] === "list-style-type") {
            styleJson["listStyleType"] = eachstyle[1];
          } else if (eachstyle[0] === "font-size") {
            styleJson.fontSize = eachstyle[1].trim();
          } else if (eachstyle[0] === "font-family") {
            styleJson.fontFamily = eachstyle[1].trim();
          }
        });
      }
      return jsx("element", { type: "ol", ...styleJson }, children);
    case "LI":
      return jsx("element", { type: "li", ...styleJson }, children);
    case "LIC":
      return jsx("element", { type: "lic" }, children);
    case "P":
      styleJson = {};
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0] === "margin-left") {
            styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "margin-right") {
            styleJson["rightindent"] =
              Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "line-height") {
            styleJson["lineHeight"] = Number(eachstyle[1]);
          } else if (eachstyle[0] === "text-align") {
            styleJson["align"] = eachstyle[1];
          } else if (eachstyle[0] === "list-style-type") {
            styleJson["listStyleType"] = eachstyle[1];
          } else if (eachstyle[0] === "list-start") {
            styleJson["listStart"] = eachstyle[1];
          }
        });
      }
      return jsx("element", { type: "p", ...styleJson }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    case "CLAUSE":
      let selectedClause = clauseLibrary.filter(
        (item) => item["clauseId"] === Number(el.getAttribute("clauseID"))
      );
      const ClauseDocument = new DOMParser().parseFromString(
        selectedClause[0].clauseHtml.clauseHtml,
        "text/html"
      );
      let cond = null;
      if (el.getAttribute("cond-data-type")) {
        cond = {};
        cond["data-type"] = el.getAttribute("cond-data-type");
        cond["data-condition-id"] = el.getAttribute("cond-uuid");
        cond["data-path"] = el.getAttribute("cond-data-path");
      }
      const deserialized = deserialize(ClauseDocument.body, {}, null, cond);
      let clause = {
        "data-type": el.getAttribute("data-type"),
        clauseID: el.getAttribute("clauseID"),
      };
      return jsx(
        "element",
        { type: "clause", data: { clause: clause } },
        deserialized
      );
    default:
      return children;
  }
};

export const deserializeContract = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    // eslint-disable-next-line no-fallthrough
    case "MARK":
      if (el.getAttribute("data-type") === "comments") {
        let uuid = "commentThread_" + el.getAttribute("uuid");
        nodeAttributes[uuid] = true;
        break;
      } else if (el.getAttribute("data-type") === "variable") {
        nodeAttributes.variable = {
          "data-type": el.getAttribute("data-type"),
          "data-variable-id": el.getAttribute("uuid"),
          "data-path": el.getAttribute("data-path"),
          "currency-in-words": el.getAttribute("currency-in-words"),
          "date-in-words": el.getAttribute("date-in-words"),
        };
        break;
      } else if (el.getAttribute("data-type") === "condition") {
        nodeAttributes.condition = {
          "data-type": el.getAttribute("data-type"),
          "data-condition-id": el.getAttribute("uuid"),
          "data-path": el.getAttribute("data-path"),
        };
        break;
      } else if (el.getAttribute("data-type") === "signature") {
        nodeAttributes.signature = {
          id: Number(el.getAttribute("uuid")),
          label: el.getAttribute("data-label"),
        };
        break;
      }
    case "SPAN":
      if (el.getAttribute("data-type2") === "nonSignature") {
        if (nodeAttributes.signature) {
          nodeAttributes.signature = {
            ...nodeAttributes.signature,
            signature: false,
          };
        }
        //nodeAttributes.signature = false;
        // el.innerHTML = "";
      } else if (el.getAttribute("data-type") === "nonSignature") {
        if (nodeAttributes.signature) {
          nodeAttributes.signature = {
            ...nodeAttributes.signature,
            signature: true,
          };
        }
        // nodeAttributes.signature = true;
        el.innerHTML = " ";
      }
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0].trim() === "color") {
            nodeAttributes.color = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "background-color") {
            nodeAttributes.backgroundColor = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "font-size") {
            nodeAttributes.fontSize = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "font-family") {
            nodeAttributes.fontFamily = eachstyle[1].trim();
          } else if (eachstyle[0].trim() === "line-height") {
            nodeAttributes.lineHeight = eachstyle[1].trim();
          }
        });
      }

      break;
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
    case "INS":
      nodeAttributes.ins = true;
      break;
    case "DEL":
      nodeAttributes.del = true;
      break;
    case "SUP":
      nodeAttributes.superscript = true;
      break;
    case "SUB":
      nodeAttributes.subscript = true;
      break;
  }
  const children = Array.from(el.childNodes)
    .map((node) => deserializeContract(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }
  let styleJson = {};
  if (el.getAttribute("style")) {
    let style = el.getAttribute("style").split(";");
    style.map((sty) => {
      let eachstyle = sty.split(":");
      if (eachstyle[0] === "margin-left") {
        styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
      } else if (eachstyle[0] === "margin-right") {
        styleJson["rightindent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
      } else if (eachstyle[0] === "line-height") {
        styleJson["lineHeight"] = Number(eachstyle[1]);
      } else if (eachstyle[0] === "text-align") {
        styleJson["align"] = eachstyle[1];
      } else if (eachstyle[0] === "list-style-type") {
        styleJson["listStyleType"] = eachstyle[1];
      } else if (eachstyle[0] === "list-start") {
        styleJson["listStart"] = eachstyle[1];
      }
    });
  }
  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx("element", { type: "blockquote" }, children);
    case "TABLE":
      return jsx("element", { type: "table" }, children);
    case "TR":
      return jsx("element", { type: "tr" }, children);
    case "TD":
      return jsx("element", { type: "td" }, children);
    case "H1":
      return jsx("element", { type: "h1", ...styleJson }, children);
    case "H2":
      return jsx("element", { type: "h2", ...styleJson }, children);
    case "H3":
      return jsx("element", { type: "h3", ...styleJson }, children);
    case "H4":
      return jsx("element", { type: "h4", ...styleJson }, children);
    case "H5":
      return jsx("element", { type: "h5", ...styleJson }, children);
    case "H6":
      return jsx("element", { type: "h6", ...styleJson }, children);
    case "UL":
      styleJson = {};
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0] === "margin-left") {
            styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "margin-right") {
            styleJson["rightindent"] =
              Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "line-height") {
            styleJson["lineHeight"] = Number(eachstyle[1]);
          } else if (eachstyle[0] === "text-align") {
            styleJson["align"] = eachstyle[1];
          } else if (eachstyle[0] === "list-style-type") {
            styleJson["listStyleType"] = eachstyle[1];
          } else if (eachstyle[0] === "font-size") {
            styleJson.fontSize = eachstyle[1].trim();
          } else if (eachstyle[0] === "font-family") {
            styleJson.fontFamily = eachstyle[1].trim();
          }
        });
      }
      return jsx("element", { type: "ul", ...styleJson }, children);
    case "OL":
      styleJson = {};
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0] === "margin-left") {
            styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "margin-right") {
            styleJson["rightindent"] =
              Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "line-height") {
            styleJson["lineHeight"] = Number(eachstyle[1]);
          } else if (eachstyle[0] === "text-align") {
            styleJson["align"] = eachstyle[1];
          } else if (eachstyle[0] === "list-style-type") {
            styleJson["listStyleType"] = eachstyle[1];
          } else if (eachstyle[0] === "font-size") {
            styleJson.fontSize = eachstyle[1].trim();
          } else if (eachstyle[0] === "font-family") {
            styleJson.fontFamily = eachstyle[1].trim();
          }
        });
      }
      return jsx("element", { type: "ol", ...styleJson }, children);
    case "LI":
      return jsx("element", { type: "li" }, children);
    case "LIC":
      return jsx("element", { type: "lic" }, children);
    case "P":
      styleJson = {};
      if (el.getAttribute("style")) {
        let style = el.getAttribute("style").split(";");
        style.map((sty) => {
          let eachstyle = sty.split(":");
          if (eachstyle[0] === "margin-left") {
            styleJson["indent"] = Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "margin-right") {
            styleJson["rightindent"] =
              Number(eachstyle[1].replace(/pt/g, "")) / 36;
          } else if (eachstyle[0] === "line-height") {
            styleJson["lineHeight"] = Number(eachstyle[1]);
          } else if (eachstyle[0] === "text-align") {
            styleJson["align"] = eachstyle[1];
          } else if (eachstyle[0] === "list-style-type") {
            styleJson["listStyleType"] = eachstyle[1];
          } else if (eachstyle[0] === "list-start") {
            styleJson["listStart"] = eachstyle[1];
          }
        });
      }
      return jsx("element", { type: "p", ...styleJson }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    case "CLAUSE":
      let clause = {
        "data-type": el.getAttribute("data-type"),
        clauseID: el.getAttribute("clauseID"),
      };
      return jsx(
        "element",
        { type: "clause", data: { clause: clause } },
        children
      );
    default:
      return children;
  }
};

//let clauseIDArray = [];
export const deserializeClause = (el, clauseIDArray) => {
  let clauseIDArrayloc = clauseIDArray;
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }

  let children = Array.from(el.childNodes).map((childnodes) =>
    deserializeClause(childnodes, clauseIDArrayloc)
  );

  switch (el.nodeName) {
    case "CLAUSE":
      clauseIDArrayloc.push(Number(el.getAttribute("clauseID")));
  }
  return clauseIDArrayloc;
};
