import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDeptData } from "../../../redux/Reducers/deptReducer";
import { useSelector } from "react-redux";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import { validateToken } from "../../../Helpers/validateToken";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { convertHierarchy } from "../../../Helpers/hierarchyConverter";
import "../departments/Departments.css";
import SyfedTreeSelect from "syfed-tree-select";
import { mapParent, mapParentTemp } from "../../../Helpers/hierarchyHelper";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import Loading from "../../../Loading/Loading";
export default function Departments() {
  const [classnameforTableDiv, setclassnameforTableDiv] =
    useState("col-12 grid-margin");

  const [addClicked, setAddClicked] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");
  const [deptDesc, setDeptDesc] = useState("");
  const [SelectedRow, setSelectedRow] = useState({});
  const [selectedParent, setselectedParent] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(1);
  const [dis, setdis] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [message, setmessage] = useState("");

  const treeData = useSelector((state) => state.deptState.value.deptData);
  //const treeData = TreeData;
  const [page, setPage] = useState(0);
  const [NewOrUpdate, setNewOrUpdate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const StatusData = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "InActive",
      value: 2,
    },
  ];

  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };

  const dispatch = useDispatch();
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "dept_id",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };

  async function OnAddNodeClose() {
    setIsLoading(true);
    setdis(true);
    let deptJson = {
      dept_name: nodeTitle,
      description: deptDesc,
      parent_dept_id: selectedParent,
      status_id: selectedStatus,
    };
    validateToken();
    await postCrudApi("departments/departments", deptJson);
    let dataResult;
    validateToken();
    let result = getCrudApi("departments/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      dispatch(
        setDeptData(
          mapParentTemp(dataResult, "parent_dept_id", "dept_id", "dept_name")
        )
      );
    }
    setclassnameforTableDiv("col-12 grid-margin");
    // setclassnameforFormDiv("col-2 grid-margin");
    setdis(false);
    setAddClicked(false);
    setmessage(" Added Successfully !");
    displayAddedMessage();
    setIsLoading(false);
  }

  async function OnUpdateNodeClose() {
    setIsLoading(true);
    setdis(true);
    let deptJson = {
      dept_name: nodeTitle,
      description: deptDesc,
      parent_dept_id:
        selectedParent === SelectedRow.rowData[1]
          ? SelectedRow.rowData[5]
          : selectedParent,
      status_id: selectedStatus,
    };
    validateToken();
    await putCrudApi("departments/" + SelectedRow.rowData[1], deptJson);
    let dataResult;
    validateToken();
    let result = getCrudApi("departments/all", {});
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult != null) {
      dispatch(
        setDeptData(
          mapParentTemp(dataResult, "parent_dept_id", "dept_id", "dept_name")
        )
      );
    }
    setclassnameforTableDiv("col-12 grid-margin");
    // setclassnameforFormDiv("col-2 grid-margin");
    setdis(false);
    setAddClicked(false);
    setmessage(" Updated Successfully !");
    displayUpdatedMessage();
    setIsLoading(false);
  }

  const displayUpdatedMessage = () => {
    toast.success("Updated Successfully!");
  };
  const displayAddedMessage = () => {
    toast.success("Added Successfully!");
  };

  const treeMapping = () => {
    if (treeData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        treeData.filter((element) => element.status === "Active"),
        "dept_id",
        "parent_dept_id"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(/"dept_id"/g, '"value"');
      old = old.replace(/"dept_name"/g, '"label"');
      var newArray = JSON.parse(old);
      return newArray;
    } else return [];
  };

  const onTemplateEditorOpen = (tableMeta) => {
    setclassnameforTableDiv("col-12 grid-margin");
    // setclassnameforFormDiv("col-4 grid-margin");
    setSelectedRow(tableMeta);
    setNewOrUpdate("Update");
    setNodeTitle(tableMeta.rowData[2]);
    setDeptDesc(tableMeta.rowData[3]);
    setselectedParent(tableMeta.rowData[5]);
    setselectedStatus(tableMeta.rowData[7]);
    setAddClicked(true);
  };

  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button
                onClick={() =>
                  tableMeta !== null ? onTemplateEditorOpen(tableMeta) : null
                }
              >
                <i className="fa fa-edit custom-iconformodal" />
              </button>
            </>
          );
        },
      },
    };
    columnsArray.push(firstCol);
    columnsArray.push({
      name: "dept_id",
      label: "Department ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "dept_name",
      label: "NAME",
    });
    columnsArray.push({
      name: "description",
      label: "DESCRIPTION",
      options: {
        display: false,
      },
    });

    columnsArray.push({
      name: "parent_name",
      label: "PARENT DEPARTMENT",
    });
    columnsArray.push({
      name: "parent_dept_id",
      label: "Parent Department ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  state === "Active"
                    ? "table-active-cell"
                    : "table-inactive-cell"
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    columnsArray.push({
      name: "status_id",
      label: "Status ID",
      options: {
        display: false,
      },
    });

    return columnsArray;
  };
  const onChangeOfTreeSelect = (value) => {
    setselectedParent(value);
  };
  const onCloseClick = () => {
    setclassnameforTableDiv("col-12 grid-margin");
    // setclassnameforFormDiv("col-2 grid-margin");
    setAddClicked(false);
  };
  const onChangeOfStatus = (value) => {
    setselectedStatus(value);
  };
  const onAddNewClick = () => {
    setdis(true);
    setclassnameforTableDiv("col-12 grid-margin");
    // setclassnameforFormDiv("col-4 grid-margin");
    setNewOrUpdate("New");
    setNodeTitle("");
    setDeptDesc("");
    setselectedParent(null);
    setAddClicked(true);
  };
  useEffect(() => {
    const regx = /^[A-Za-z][A-Za-z0-9_ ]*$/;
    if (nodeTitle?.trim() !== "" && !regx?.test(nodeTitle)) {
      setTitleError(
        "Name must begin with an alphabet, rest should be alphanumeric or contain only _ as its special character."
      );
      setdis(true);
    } else if (
      nodeTitle?.trim() === "" ||
      nodeTitle === undefined ||
      nodeTitle === null
    ) {
      setTitleError("Name Required");
      setdis(true);
    } else {
      setTitleError("");
      setdis(false);
    }
  }, [nodeTitle]);
  const handleError = (e) => {
    setNodeTitle(e.target.value);
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const onRemoveParent = () => {
    if (selectedParent !== null) {
      setselectedParent(null);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={onAddNewClick}
            >
              Add
            </button>
          </div>
          <div>
            {addClicked ? (
              <div className="modal">
                <div className="modal-content">
                  <div className="header-modal">
                    <h5 className="header-title">Department</h5>
                  </div>
                  <div className="div-display">
                    <label className="label-class">
                      Department Name{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>

                    <input
                      type="text"
                      onChange={handleError}
                      placeholder="Department Name"
                      className="textbox-input_1"
                      value={nodeTitle}
                    />
                    {titleError !== "" ? (
                      <div className="alert-danger">{titleError}</div>
                    ) : null}
                  </div>
                  <div className="div-display">
                    <label className="label-class">
                      Department Description
                    </label>

                    <input
                      type="text"
                      placeholder="Department Description"
                      className="textbox-input_1"
                      onChange={(e) => {
                        setDeptDesc(e.target.value);
                      }}
                      value={deptDesc}
                    />
                  </div>
                  <div className="div-display">
                    <label className="label-class">Parent Department</label>
                    <div className="parentSelectDiv">
                      <SyfedTreeSelect
                        style={treeSelectStyle}
                        size="small"
                        treeCheckedStrategy="all"
                        dataSource={treeMapping()}
                        onChange={onChangeOfTreeSelect}
                        placeholder="Select"
                        value={selectedParent}
                      />
                      <button
                        onClick={() => onRemoveParent()}
                        className="removeParent"
                      >
                        <i className="fa fa-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="div-display">
                    <label className="label-class">Status</label>

                    <SyfedTreeSelect
                      style={treeSelectStyle}
                      size="small"
                      treeCheckedStrategy="all"
                      dataSource={StatusData}
                      onChange={onChangeOfStatus}
                      placeholder="Select Status"
                      value={selectedStatus}
                    />
                  </div>

                  <div className="footer-modal">
                    <button
                      type="button"
                      className="close-btn"
                      onClick={onCloseClick}
                    >
                      Close
                    </button>
                    {NewOrUpdate === "New" ? (
                      <button
                        type="button"
                        className="Modal-btn"
                        onClick={OnAddNodeClose}
                        disabled={dis}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="Modal-btn"
                        onClick={OnUpdateNodeClose}
                        disabled={dis}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="Table-body" style={{ overflow: "hidden" }}>
            <div className="table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  //title={"Departments"}
                  data={treeData}
                  columns={generateColumns()}
                  options={options}
                  className="muitable"
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
}
