import React, { Suspense } from "react";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import Breadcrumbs from "./Breadcrumbs";
import { Outlet } from "react-router-dom";
import Loading from "../Loading/Loading";
import { ToastHelper } from "./ToastHelpher";
import { useState } from "react";
function MainPage(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [masterSidebarOpen, setMasterSidebarOpen] = useState(false);
  const loading = () => <Loading />;

  let navbarComponent = <Navbar sidebarOpen={sidebarOpen} />;
  let sidebarComponent = (
    <Sidebar routing={props?.routpaths} setSidebarOpen={setSidebarOpen} />
  );
  let footerComponent = <Footer />;
  return (
    <div className="container-scroller">
      {sidebarComponent}
      <div
        className={sidebarOpen ? "page-body-wrapper-open" : "page-body-wrapper"}
      >
        {navbarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="body-wrapper">
              <Breadcrumbs routing={props?.routpaths} />
              <Suspense fallback={loading}>
                {/* <Switch> */}
                <Outlet />
                {/* </Switch> */}
              </Suspense>
              <ToastHelper />
            </div>
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
}

export default MainPage;
