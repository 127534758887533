import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./ClauseModal.css";
import ClauseEditor from "../../PlateEditor/ClauseEditor";
import { SettingsBackupRestore } from "@styled-icons/material";
export default function ClausesModel(props) {
  const [selectedClause, setSelectedClause] = useState(null);
  const [activeClauses, setActiveClauses] = useState(null);
  const [enableAdd, setEnableAdd] = useState(false);
  const [reRender, setReRender] = useState(true);

  const clauseRef = React.createRef();
  useEffect(() => {
    if (props.clauseLibrary) {
      let clauseLibraryActive = [];
      for (var i = 0; i < props.clauseLibrary.length; i++) {
        if (props.clauseLibrary[i].statusId === 1) {
          clauseLibraryActive.push(props.clauseLibrary[i]);
        }
      }

      setActiveClauses(clauseLibraryActive);
    }
  }, [props.clauseLibrary]);
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "12px",
    }),
    control: () => ({
      borderColor: "#D9DFF2",
      borderStyle: "solid",
      borderWidth: "2px",
      display: "flex",
      borderRadius: "5px",
      "&:hover": {
        border: "2px solid",
        borderColor: "#48336E",
      },
    }),
  };
  useEffect(() => {
    if (reRender === false) setReRender(!reRender);
  }, [reRender]);
  return (
    <div>
      {props.isClauseOpen ? (
        <div className="modal">
          <div className="clauseModal">
            <div className="header-modal">
              <h5 className="header-title">Clauses</h5>
            </div>
            <div className="clauseButton">
              <div className="clauseClose">
                <button
                  className="close-btn"
                  type="button"
                  onClick={() => {
                    props.setisClauseOpen(false);
                    setSelectedClause(null);
                  }}
                >
                  Close
                </button>
              </div>
              <div className="clauseAdd">
                <button
                  className="Modal-btn"
                  type="button"
                  onClick={() => {
                    props.onClauseOK();
                    setSelectedClause(null);
                  }}
                  disabled={!enableAdd}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="clause-div">
              <label className="label-class">Select Clause</label>
              <div style={{ padding: "5px" }}>
                <Select
                  styles={selectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: " #E5E5E5",
                      primary: "#B2D4FD ",
                    },
                  })}
                  options={activeClauses}
                  getOptionLabel={(option) => option.clauseName}
                  getOptionValue={(option) => option.clauseName}
                  onChange={(e) => {
                    setReRender(false);
                    props.setSelectedClause(e);
                    setEnableAdd(true);
                    setSelectedClause(e);
                  }}
                  value={selectedClause ? selectedClause : null}
                />
              </div>
            </div>

            {selectedClause !== null && reRender ? (
              <div className="Template-clause-label">
                <label className="label-clauseModal">Clause:</label>
                {selectedClause.clauseHtml !== null ? (
                  <ClauseEditor
                    id={"clauseEditorModalPopup"}
                    ref={clauseRef}
                    clauseData={selectedClause.clauseHtml.clauseHtml}
                    isEditable={false}
                  />
                ) : (
                  "Clause not available"
                )}
              </div>
            ) : null}
            {/* <div className="footer-modal"></div> */}
          </div>
        </div>
      ) : null}
    </div>
  );
}
