export const convertHierarchy = (array, field_id, parent_field) => {
  var map = {};
  if (array && array.length !== 0) {
    for (var i = 0; i < array.length; i++) {
      var obj = JSON.parse(JSON.stringify(array[i]));
      obj.children = [];
      obj.parentStatus = obj[parent_field]
        ? array.filter((arr) => {
            if (arr[field_id] === obj[parent_field]) return true;
          })[0].status
        : null;
      map[obj[field_id]] = obj;
      var parent = obj[parent_field] || "-";
      if (!map[parent]) {
        map[parent] = {
          children: [],
        };
      }
      map[parent].children.push(obj);
    }
    if (map["-"] !== undefined) return map["-"].children;
    else return null;
  } else {
    return null;
  }
};
