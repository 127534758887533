import React, { useEffect, useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { setCategoryData } from "../../../redux/Reducers/categoryReducer";
import { useSelector } from "react-redux";
import {
  getCrudApi,
  postCrudApi,
  putCrudApi,
} from "../../../webServiceCalls/webServiceCall";
import { toast } from "react-toastify";
import { convertHierarchy } from "../../../Helpers/hierarchyConverter";
import { validateToken } from "../../../Helpers/validateToken";
import { mapParent } from "../../../Helpers/hierarchyHelper";
import SyfedTreeSelect from "syfed-tree-select";
import "../category/Category.css";
import OptimisedMUIDataTable from "../../manageContracts/OptimisedMUIDataTable";
import Loading from "../../../Loading/Loading";
export default function Category() {
  const [addClicked, setAddClicked] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");
  // const [classnameforFormDiv, setclassnameforFormDiv] =
  //   useState();
  // const [classnameforTableDiv, setclassnameforTableDiv] =
  //   useState("col-12 grid-margin");
  const [SelectedRow, setSelectedRow] = useState({});
  const [dis, setdis] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [selectedParent, setselectedParent] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const treeData = useSelector(
    (state) => state.categoryState.value.categoryData
  );
  //const treeData = TreeData;
  const [page, setPage] = useState(0);
  const [NewOrUpdate, setNewOrUpdate] = useState("");

  const displayUpdatedMessage = () => {
    toast.success("Updated Successfully!");
  };
  const displayAddedMessage = () => {
    toast.success("Added Successfully!");
  };

  const treeSelectStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
  };

  const StatusData = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "InActive",
      value: 2,
    },
  ];
  const dispatch = useDispatch();
  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    viewColumns: false,
    search: true,
    filterType: "dropdown",
    page: page,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    sortOrder: {
      name: "catogeryTypeId",
      direction: "desc",
    },
    onRowsDelete: (rowsDeleted) => {
      //TODO - API call to delete rows
    },
    onColumnSortChange: (changedColumn, direction) => {},
    onChangeRowsPerPage: (numberOfRows) => {},
    onChangePage: (currentPage) => setPage(currentPage),
  };
  async function OnAddNodeClose() {
    setIsLoading(true);
    setdis(true);
    let contractJson = {
      catogeryType: nodeTitle,
      parentCatogeryTypeId: selectedParent,
      statusId: selectedStatus,
    };
    validateToken();
    let dataResult;
    let result = postCrudApi("categoryTypes/categoryTypes", contractJson);
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      validateToken();
      result = getCrudApi("categoryTypes/all", {});
      await result.then(function (res) {
        dataResult = res;
      });

      if (dataResult != null) {
        dispatch(
          setCategoryData(
            mapParent(
              dataResult,
              "parentCatogeryTypeId",
              "catogeryTypeId",
              "catogeryType"
            )
          )
        );
      }
      // setclassnameforTableDiv();
      // setclassnameforFormDiv("col-2 grid-margin");
      setdis(false);
      setAddClicked(false);
      displayAddedMessage();
    }
    setIsLoading(false);
  }
  async function OnUpdateNodeClose() {
    setIsLoading(true);
    setdis(true);
    let contractJson = {
      catogeryType: nodeTitle,
      parentCatogeryTypeId:
        selectedParent === SelectedRow.rowData[1]
          ? SelectedRow.rowData[4]
          : selectedParent,
      statusId: selectedStatus,
    };
    validateToken();
    let dataResult;
    let result = putCrudApi(
      "categoryTypes/" + SelectedRow.rowData[1],
      contractJson
    );
    await result.then(function (res) {
      dataResult = res;
    });
    if (dataResult) {
      validateToken();
      result = getCrudApi("categoryTypes/all", {});
      await result.then(function (res) {
        dataResult = res;
      });

      if (dataResult != null) {
        dispatch(
          setCategoryData(
            mapParent(
              dataResult,
              "parentCatogeryTypeId",
              "catogeryTypeId",
              "catogeryType"
            )
          )
        );
      }
      // setclassnameforTableDiv();
      // setclassnameforFormDiv("col-2 grid-margin");
      setdis(false);
      setAddClicked(false);
      displayUpdatedMessage();
    }
    setIsLoading(false);
  }

  const treeMapping = () => {
    if (treeData.length !== 0) {
      let treeHierarchy = convertHierarchy(
        treeData.filter((element) => element.status === "Active"),
        "catogeryTypeId",
        "parentCatogeryTypeId"
      );
      if (treeHierarchy == null) {
        return [];
      }
      var old = JSON.stringify(treeHierarchy).replace(
        /"catogeryTypeId"/g,
        '"value"'
      );
      old = old.replace(/"catogeryType"/g, '"label"');
      var newArray = JSON.parse(old);
      return newArray;
    }
    return [];
  };
  const onTemplateEditorOpen = (tableMeta) => {
    // setclassnameforTableDiv();
    // setclassnameforFormDiv("col-4 grid-margin");
    setdis(false);
    setSelectedRow(tableMeta);
    setNewOrUpdate("Update");
    setNodeTitle(tableMeta.rowData[2]);
    setselectedParent(tableMeta.rowData[4]);
    setselectedStatus(tableMeta.rowData[6]);
    setAddClicked(true);
  };
  const generateColumns = () => {
    let columnsArray = [];
    let firstCol = {
      name: "EDIT",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button
                onClick={() =>
                  tableMeta !== null ? onTemplateEditorOpen(tableMeta) : null
                }
              >
                <i className="fa fa-edit custom-iconformodal" />
              </button>
            </>
          );
        },
      },
    };

    columnsArray.push(firstCol);
    columnsArray.push({
      name: "catogeryTypeId",
      label: "Category ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "catogeryType",
      label: "NAME",
    });

    columnsArray.push({
      name: "parent_name",
      label: "PARENT CATEGORY",
    });
    columnsArray.push({
      name: "parentCatogeryTypeId",
      label: "Parent Category ID",
      options: {
        display: false,
      },
    });
    columnsArray.push({
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (dataIndex) => {
          const state = dataIndex;
          return (
            <>
              <p
                className={
                  state === "Active"
                    ? "table-active-cell"
                    : "table-inactive-cell"
                }
              >
                {state}
              </p>
            </>
          );
        },
      },
    });
    columnsArray.push({
      name: "statusId",
      label: "Status ID",
      options: {
        display: false,
      },
    });

    return columnsArray;
  };
  const onChangeOfTreeSelect = (value) => {
    setselectedParent(value);
  };
  const onChangeOfStatus = (value) => {
    setselectedStatus(value);
  };
  const onCloseClick = () => {
    // setclassnameforTableDiv();
    // setclassnameforFormDiv("col-2 grid-margin");
    setAddClicked(false);
  };

  const onAddNewClick = () => {
    // setclassnameforTableDiv();
    // setclassnameforFormDiv("col-4 grid-margin");
    setdis(true);
    setNewOrUpdate("New");
    setNodeTitle("");
    setselectedParent(null);
    setAddClicked(true);
  };

  useEffect(() => {
    const regx = /^[A-Za-z][a-zA-Z0-9_()\-[\] ]*$/;
    if (nodeTitle?.trim() !== "" && !regx?.test(nodeTitle)) {
      setTitleError(
        "Name must begin with an alphabet, rest should be alphanumeric or contain only _, -, (), [] as its special character."
      );
      setdis(true);
    } else if (
      nodeTitle?.trim() === "" ||
      nodeTitle === undefined ||
      nodeTitle === null
    ) {
      setTitleError("Name Required");
      setdis(true);
    } else {
      setTitleError("");
      setdis(false);
    }
  }, [nodeTitle]);

  const handleError = (e) => {
    setNodeTitle(e.target.value);
  };
  const customTheme = createTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#48336e",
        },
      },
    },
  });
  const onRemoveParent = () => {
    if (selectedParent !== null) {
      setselectedParent(null);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="modal-open-btn"
              onClick={onAddNewClick}
            >
              Add
            </button>
          </div>
          {addClicked ? (
            <div className="modal">
              <div className="modal-content">
                <div>
                  <div className="header-modal">
                    <h5 className="header-title">Category</h5>
                  </div>
                  <div className="div-display">
                    <label className="label-class">
                      Category Name{" "}
                      <i className="fa fa-asterisk aster-risk-Icon"></i>
                    </label>

                    <input
                      type="text"
                      placeholder="Category Name"
                      onChange={handleError}
                      className="textbox-input_1"
                      value={nodeTitle}
                    />
                    {titleError !== "" ? (
                      <div className=" alert-danger">{titleError}</div>
                    ) : null}
                  </div>

                  <div className="div-display">
                    <label className=" label-class">Parent Category</label>
                    <div className="parentSelectDiv">
                      <SyfedTreeSelect
                        style={treeSelectStyle}
                        size="small"
                        treeCheckedStrategy="all"
                        dataSource={treeMapping()}
                        onChange={onChangeOfTreeSelect}
                        placeholder="Select"
                        value={selectedParent}
                      />
                      <button
                        onClick={() => onRemoveParent()}
                        className=" removeParent"
                      >
                        <i className="fa fa-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="div-display">
                    <label className="label-class">Status</label>

                    <SyfedTreeSelect
                      style={treeSelectStyle}
                      size="small"
                      treeCheckedStrategy="all"
                      dataSource={StatusData}
                      onChange={onChangeOfStatus}
                      placeholder="Select Status"
                      value={selectedStatus}
                    />
                  </div>
                </div>

                <div className="footer-modal">
                  <button
                    type="button"
                    className="close-btn"
                    onClick={onCloseClick}
                  >
                    Close
                  </button>
                  {NewOrUpdate === "New" ? (
                    <button
                      type="button"
                      className="Modal-btn"
                      onClick={OnAddNodeClose}
                      disabled={dis}
                    >
                      Add New
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="Modal-btn"
                      onClick={OnUpdateNodeClose}
                      disabled={dis}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <div className="Table-body" style={{ overflow: "hidden" }}>
            <div className=" table-scroll">
              <MuiThemeProvider theme={customTheme}>
                <OptimisedMUIDataTable
                  //title={"Departments"}
                  data={treeData}
                  columns={generateColumns()}
                  options={options}
                  className="muitable"
                />
              </MuiThemeProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
}
